import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { renderRequestLink } from "helpers/RequestHelper";

const ManufSiteParentLink = ({ request }) => {
  if (!request || !request.parent) return null;

  const { id, formatedtransactionnumber: tn } = request.parent;
  const reqLink = renderRequestLink(id, tn, true, "sar");
  
  return (
    <FormElement id="manufSiteParent" label="Parent Request" value={reqLink} />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(ManufSiteParentLink);
