import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import BatteryModelAutocomplete from "containers/request/battery/BatteryModelAutocomplete";
import RequestVoltage from "components/request/RequestVoltage";
import RequestRevision from "components/request/RequestRevision";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import ManufSitesCRR from "containers/request/CRR/ManufSitesCRR";
import ManufSitesReadonlyCRR from "containers/request/CRR/ManufSitesReadonlyCRR";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import {
  initCRR,
  mapManufSitesCRR,
  updateDeviceInfoCRR
} from "actions/battery/crrRequest";
import { ifModelExists } from "actions/model";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { validateVoltage, validateCapacity } from "helpers/ValidatorHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestContainerCRR extends CyberComponent {
  componentDidMount() {
    const { request, user, initCRR } = this.props;
    const ownerId = getRequestOwner(request, user);

    // fetch only for the first time on submissions
    if (!this.isValidToSubmit() && !request.id) {
      initCRR(request.ieee, ownerId);
    }

    // fetch for existed requests
    if (request.id) {
      initCRR(request.ieee, ownerId).then(() =>
        this.props.mapManufSitesCRR(request.linkedManufsites)
      );
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.togglerEnabled !== this.props.user.togglerEnabled) {
      return this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    }
  }

  onSaveClick = () => {
    this.props.updateDeviceInfoCRR(this.props.request, this.props.sites.list);
  };

  onContinueClick = () => {
    const { request, requests, user, setValue, ifModelExists } = this.props;

    // check if entered model name exists
    const existedModel = findExistedModelByName(
      request.existedModel,
      requests.vendorBatteryModels
    );

    // if so - use existed model id
    const existedModelId = existedModel ? existedModel.value : null;

    // update request field in redux
    setValue("existedModelId", existedModelId);

    // check modelname uniqueness
    if (!existedModel) {
      ifModelExists(request.existedModel, user.companyid).then(isUnique => {
        if (isUnique) {
          return this.props.submitWizardStep();
        }
      });
    } else {
      return this.props.submitWizardStep();
    }
  };

  validateModel() {
    const { request, requests } = this.props;
    if (!request || !requests) return false;

    const fieldName = request.id ? "modelName" : "existedModel";

    return hasStringValue(request, fieldName);
  }

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateManufSites() {
    const { sites } = this.props;

    return sites && sites.list && _.some(sites.list, "checked");
  }

  isValidToSubmit() {
    const { request, user } = this.props;

    // don't need to validate if form is non-editable
    if (user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateRevision() &&
      validateVoltage(request) &&
      validateCapacity(request) &&
      this.validateManufSites()
    );
  }

  render() {
    const { request, user } = this.props;
    const {
      canEditDeviceInfo,
      canEditVoltageCapacity,
      displayCapacity,
      displayVoltage,
      isLab
    } = user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;
    const isEditable = { editable: canEditDeviceInfo };
    const isValid = this.isValidToSubmit();
    const canEditDaPower = !isLab && canEditVoltageCapacity;

    return (
      <div>
        <Instructions />
        <Form horizontal>
          {request.isEco && (
            <>
              <RequestParentLink certPrefix="crr" />
              <BatteryParentDetails />
              <hr />
            </>
          )}
          <RequestManufacturer />
          <BatteryModelAutocomplete />
          <RequestRevision fieldId="revision" {...isEditable} />
          {displayVoltage && (
            <RequestVoltage
              fieldId="voltage"
              fieldLabel="Voltage (V)"
              shouldBeNumber={!request.isLegacy}
              editable={canEditDaPower}
            />
          )}
          {displayCapacity && (
            <RequestVoltage
              fieldId="capacity"
              fieldLabel="Capacity (mAh)"
              shouldBeNumber={!request.isLegacy}
              editable={canEditDaPower}
            />
          )}
          <SubmissionDetails />
          <hr />
          {canEditDeviceInfo ? <ManufSitesCRR /> : <ManufSitesReadonlyCRR />}
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, requests, user, sites }) {
  return { request, requests, user, sites };
}

export default connect(mapStateToProps, {
  initCRR,
  submitWizardStep,
  updateDeviceInfoCRR,
  setValue,
  setBatteryRequestPermissions,
  mapManufSitesCRR,
  ifModelExists
})(RequestContainerCRR);
