import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import FormElement from "components/FormElement";
import { formatCurrency } from "helpers/StringHelper";
import { formatDate } from "helpers/DateHelper";
import { testStatuses } from "helpers/RequestTestsHelper";
import { getInvoiceNumber } from "helpers/BillingHelper";
import PaymentFormStaff from "containers/request/Payments/PaymentFormStaff";
import PaymentActions from "containers/request/Payments/PaymentActions";

const PaymentInfo = ({ request, user }) => {
  // check if request tests have been accepted by lab
  const noInvoice = _.isEmpty(request.invoice);
  const testsAccepted =
    request.testStatus !== testStatuses.rejected &&
    request.testStatus !== testStatuses.pending;

  if (noInvoice && !testsAccepted) {
    return (
      <Bubble show={true}>
        The CTIA invoice will be generated after the lab accepts your test
        request.
      </Bubble>
    );
  }

  const {
    createdat,
    totalamount,
    paymentmethod,
    paidat,
    checknumber,
    paymentnote
  } = request.invoice;

  const showField = fieldName => {
    if (user.isStaff && user.canEditRequest) return false;
    return !_.isEmpty(String(request.invoice[fieldName]));
  };

  const showIvcDate = fieldName => {
    if (user.isStaff) {
      return user.canEditRequest ? false : true;
    }

    return !_.isEmpty(String(request.invoice[fieldName]));
  };

  const fields = [
    {
      id: "invoiceNumber",
      label: "Invoice Number",
      value: getInvoiceNumber(request),
      show: true
    },
    {
      id: "invoiceAmount",
      label: "Invoice Amount",
      value: formatCurrency(totalamount),
      show: true
    },
    {
      id: "invoiceDate",
      label: "Invoice Date",
      value: formatDate(createdat),
      show: showIvcDate("createdat")
    },
    {
      id: "payDate",
      label: "Payment Date",
      value: formatDate(paidat),
      show: showField("paidat")
    },
    {
      id: "payMethod",
      label: "Payment Method",
      value: paymentmethod,
      show: showField("paymentmethod")
    },
    {
      id: "payCheckNumber",
      label: "Check #",
      value: checknumber,
      show: showField("checknumber")
    },
    {
      id: "payNote",
      label: "Payment Note",
      value: paymentnote,
      show: showField("paymentnote")
    }
  ];

  const renderFields = () => {
    return fields.map(field => {
      return <FormElement {...field} key={field.id} />;
    });
  };

  return (
    <div>
      <Bubble show={true}>The CTIA invoice details are shown below.</Bubble>
      <Form horizontal>
        {renderFields()}
        <PaymentFormStaff />
        <PaymentActions />
      </Form>
    </div>
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(PaymentInfo);
