import React, { Component } from "react";
import { connect } from "react-redux";
import { Label } from "react-bootstrap";
import InfoBar from "containers/Devices/DeviceInfoBar";
import ModelVersionsCyber from "containers/Devices/Cyber/ModelVersionsCyber";
import ModelVersionsOta from "containers/Devices/Ota/ModelVersionsOta";
import ModelVersionsDHR from "containers/Devices/DHR/ModelVersionsDHR";
import ModelVersionsBLC from "containers/Devices/BLC/ModelVersionsBLC";
import GoBackButton from "components/form/buttons/GoBackButton";
import { keepDeviceList, clearDeviceList } from "actions/devices";
import { startPreloader, finishPreloader } from "actions/preloader";
import { fetchModelCertifiedVersions, clearModel } from "actions/model";
import { getDeviceRedirectUrl } from "helpers/UrlHelper";
import CyberCertificateButton from "components/CyberCertificateButton"

class CertifiedDevice extends Component {
  fetchDevices = () => {
    const {
      startPreloader,
      fetchModelCertifiedVersions,
      finishPreloader,
      match,
      devices,
      app,
      history
    } = this.props;

    if (devices === null || devices.list === null) {
      const redirectUrl = getDeviceRedirectUrl(app);
      return setTimeout(() => history.push(redirectUrl), 100);
    }

    startPreloader();
    fetchModelCertifiedVersions(match.params.id).then(finishPreloader);
  };

  componentDidMount() {
    this.fetchDevices();
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    const {
      match,
      startPreloader,
      fetchModelCertifiedVersions,
      finishPreloader
    } = this.props;

    if (newId !== match.params.id) {
      startPreloader().then(
        fetchModelCertifiedVersions(newId).then(finishPreloader)
      );
    }
  }

  componentWillUnmount() {
    // this.props.clearDeviceList();
    this.props.clearModel();
  }

  backToList = () => {
    const { app, history, keepDeviceList, clearModel } = this.props;

    const redirectUrl = getDeviceRedirectUrl(app);

    // clear redux data:
    clearModel();
    
    // don't clear device list
    keepDeviceList(true);

    // force redirection:
    history.push(redirectUrl);
  };

  render() {
    const { app, model, user, devices, match } = this.props;

    if (!model || !devices) return null;
    if (model && !model.versions) return null;

    const showLink = !user.isLab;
    const modelId = match.params.id;
    const modelInfo = devices.list[modelId];
    var route = "";
    var show = false;
    if(app.isCyberCert) {
      route = '/cyber/certificate/' + model.versions[0].id;
      show = true;
    }
    return (
      <div>
        <div class="space-between">
          <h1>
            Certified Device <Label>{modelInfo.modelnumber}</Label>
          </h1>
          <CyberCertificateButton show={show} to={route} request={model.versions[0]}/>
        </div>
        <div className="block-top-offset">
          <GoBackButton
            onClickHandler={this.backToList}
            label="Return to List"
          />
          <InfoBar modelId={modelId} />
          <ModelVersionsCyber
            tableData={model.versions}
            showLink={showLink}
            show={app.isCyberCert}
            urlPrefix={app.urlPrefix}
          />
          <ModelVersionsOta
            tableData={model.versions}
            showLink={showLink}
            show={app.isOTACert}
            urlPrefix={app.urlPrefix}
          />
          <ModelVersionsDHR
            tableData={model.versions}
            showLink={showLink}
            show={app.isDHRCert}
            urlPrefix={app.urlPrefix}
          />
          <ModelVersionsBLC
            tableData={model.versions}
            showLink={showLink}
            show={app.isBLCCert}
            urlPrefix={app.urlPrefix}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ app, model, user, devices }) {
  return { app, model, user, devices };
}

export default connect(mapStateToProps, {
  keepDeviceList,
  clearDeviceList,
  startPreloader,
  finishPreloader,
  fetchModelCertifiedVersions,
  clearModel
})(CertifiedDevice);
