import React from 'react';
import { connect } from 'react-redux';
import RenewalSelector from 'containers/request/battery/ManufSite/RenewalSelector';
import AddressDetails from 'containers/request/battery/ManufSite/SiteAddressDetails';

const RenewalContainer = ({ sites }) => {
  if (!sites || !sites.list) return null;

  return (
    <div>
      <RenewalSelector />
      <AddressDetails />
    </div>
  );
};

function mapStateToProps({ sites }) {
  return { sites };
}

export default connect(mapStateToProps)(RenewalContainer);
