import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Card, Table } from "ctia-react-bootstrap-v4";
import SupplierRow from "./BatterySupplierListRow";

const BatterySupplierList = ({ request }) => {
  if (
    !request ||
    !request.batterySuppliers ||
    _.isEmpty(request.batterySuppliers)
  )
    return null;

  return (
    <div className="block-bottom-offset">
      <Card border="light">
        <Card.Body>
          <h3>Selected Batteries ({request.batterySuppliers.length})</h3>
          <Table responsive striped hover size="sm" className="ctia-table">
            <thead>
              <tr>
                <th>Battery Supplier</th>
                <th>Battery Model Name/Number</th>
                <th>Battery Revision #</th>
                <th>Battery Capacity</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {request.batterySuppliers.map((supplier, i) => {
                return <SupplierRow key={i} supplier={supplier} />;
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(BatterySupplierList);
