import React from "react";
import { connect } from "react-redux";
import { InlinePreloader, TooltipIcon, DownloadButton } from "ctia-ui";
import { Table, Col, Row } from "react-bootstrap";
import FileStatusInfo from "components/FileStatusInfo";
import { hasReportDocuments } from "helpers/RequestTestsHelper";
import { getDocStatus } from "helpers/DocumentStatus";

const colStyle = { width: 150 };

const UploadedResults = ({ reports, downloadHandler, request }) => {
  if (!reports.length) return null;
  if (!hasReportDocuments(reports)) return <InlinePreloader />;

  return (
    <div>
      <h3>Uploaded Results</h3>
      <Row>
        <FileStatusInfo />
        <Col xs={10}>
          <Table responsive striped className="ctia-table">
            <thead>
              <tr>
                <th>Status</th>
                <th>Document Title</th>
                <th style={colStyle} />
              </tr>
            </thead>
            <tbody>
              {reports.map((elem, i) => {
                if (!elem.document) return null;

                const status = elem.document.documentstatusid;
                const statusInfo = getDocStatus(status);
                return (
                  <tr key={i}>
                    <td>
                      <TooltipIcon
                        glyph={statusInfo.icon}
                        tipText={statusInfo.title}
                      />
                    </td>
                    <td>{elem.document.title}</td>
                    <td>
                      <DownloadButton
                        size="small"
                        action={() => downloadHandler(elem, request)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(UploadedResults);
