import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import CyberComponent from "containers/CyberComponent";
import { handleInputChange, cleanBillingPocInfo } from "actions/request";
import {
  formatPocList,
  renderSelectedPocInfo,
  onSelectPoc
} from "helpers/BillingHelper";

class BillingPocSelector extends CyberComponent {
  render() {
    const {
      show,
      request,
      toggleSelector,
      handleInputChange,
      cleanBillingPocInfo,
      isEditable
    } = this.props;

    if (!show) return null;

    if (request.pocs) {
      return (
        <div>
          <FormElement
            show={isEditable}
            type="select"
            id="billingPocId"
            label="Billing PoC"
            value={request.billingPocId}
            onChange={onSelectPoc.bind(
              this,
              toggleSelector,
              handleInputChange,
              cleanBillingPocInfo
            )}
            options={formatPocList(request.pocs, request.id)}
            editable={isEditable}
          />
          {renderSelectedPocInfo(request)}
        </div>
      );
    }

    return null;
  }
}

// keep user for component re-render logic
function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleInputChange,
  cleanBillingPocInfo
})(BillingPocSelector);
