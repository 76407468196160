import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Transfer } from "antd";
import { LoaderButton } from "ctia-ui";
import {
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  updateAssignedUserLabs
} from "actions/users2labs";
import "antd/dist/antd.css";

const TransferLabsForm = ({
  users2labs,
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  updateAssignedUserLabs
}) => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [transferData, setTransferData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [avoidDuplicates, setAvoidDuplicates] = useState(false);

  useEffect(() => {
    if (users2labs && users2labs.activeLabs && users2labs.userLabs) {
      setLoading(true);

      const { activeLabs, userLabs, selectedUserData } = users2labs;

      // filter out selected user's lab from the list
      const labs = _.filter(
        activeLabs,
        lab => lab.id !== selectedUserData.companyid
      );

      const transferData = labs.map(lab => {
        return {
          key: lab.ctiaid,
          title: lab.name,
          description: lab.countrydisplay
        };
      });

      // Grab users home lab
      var activeLab = _.filter(
        activeLabs,
        lab => lab.id === selectedUserData.companyid
      );

      // if user isn't properly assigned to their home lab, add it to to-be-assigned list
      if(activeLab.length && !userLabs.find(lab => lab.labid === activeLab[0].ctiaid) && !avoidDuplicates) {
        userLabs.push( {
          labid: activeLab[0].ctiaid,
          userid: selectedUserData.ctiaid
        });

        var testlabs = userLabs;
        setAvoidDuplicates(true);

      } else {
        var activeLab = _.filter(
          activeLabs,
          lab => lab.id === selectedUserData.companyid
        );

          var testlabs = _.filter(
            userLabs,
            lab => lab.labid !== activeLab[0].ctiaid
          )
      }

      // find pre-assigned labs, prevent adding duplicates of home lab
      const targetKeys = testlabs.map(lab => lab.labid).filter((id) => {return id !== selectedUserData.companyid});
      console.log(targetKeys)
      // move pre-assigned labs as targetKeys
      setTargetKeys(targetKeys);

      // set transfer data as the rest
      setTransferData(transferData);

      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [users2labs, setLoading]);

  const filterOption = (inputValue, option) => {
    const optionStr = String(option.title).toLowerCase();
    const inputStr = String(inputValue).toLowerCase();
    return optionStr.indexOf(inputStr) > -1;
  };

  const onSubmit = () => {
    const { activeLabs, userLabs, selectedUserData } = users2labs;

    setSubmitting(true);
    if(avoidDuplicates) {
      var activeLab = _.filter(
        activeLabs,
        lab => lab.id === selectedUserData.companyid
      );

      let tempKeys = _.filter(
        targetKeys,
        id => id !== activeLab[0].ctiaid
      );

      setTargetKeys(tempKeys);
    }
    updateAssignedUserLabs(
      users2labs.selectedUserData.ctiaid,
      targetKeys
    ).then(() => setSubmitting(false));
  };

  if (loading) return "Loading...";

  return (
    <>
      <h4>Select Lab:</h4>
      <Transfer
        dataSource={transferData}
        showSearch
        filterOption={filterOption}
        targetKeys={targetKeys}
        onChange={keys => setTargetKeys(keys)}
        render={item => item.title}
        listStyle={{
          width: 300,
          height: 350
        }}
        operations={["add", "remove"]}
      />
      <LoaderButton
        bsStyle="success"
        text="Submit"
        onClick={onSubmit}
        isLoading={submitting}
        loadingText="Submitting..."
        className="block-top-offset"
      />
    </>
  );
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps, {
  getActiveLabUsers,
  fetchActiveLabs,
  selectLabUser,
  updateAssignedUserLabs
})(TransferLabsForm);
