import _ from "lodash";
import SiteAuditInfoBar from "containers/request/InfoBar/battery/SiteAuditInfoBar";
import InfoBarCRR from "containers/request/InfoBar/battery/InfoBarCRR";
import InfoBarBSC from "containers/request/InfoBar/battery/InfoBarBSC";
import * as agreements from "dictionaries/BatteryAgreementTypes";
import { batteryTabs } from "helpers/WizardHelper.js";
import reqTypes from "dictionaries/ReqTypes";
import batteryRequestTypes from "dictionaries/BatteryRequestTypes";

const { initial, eco, renewal } = reqTypes;
const submissionTypes = [initial, eco];
const ieeeTypes = [1725, 1625];

// tiny helper with short names
export const types = {
  BSC: batteryRequestTypes.batterySystem,
  EPRR: batteryRequestTypes.embeddedPackRecognition,
  CRR: batteryRequestTypes.cellRecognitionRequest,
  PRR: batteryRequestTypes.packRecognitionRequest,
  ARR: batteryRequestTypes.adapterRecognitionRequest,
  ACR: batteryRequestTypes.adapterCertificationRequest,
  HRR: batteryRequestTypes.hybridRecognitionRequest,
  SAR: batteryRequestTypes.siteAuditRequest
};

export const typesHs = _.keys(types);

export const batteryVendor = {
  [types.BSC]: {
    ieeeTypes,
    submissionTypes,
    title: "Battery System Certification",
    wizardSteps: batteryTabs[types.BSC],
    requiredAgreements: agreements.BSC_AGREEMENTS,
    clientTypeId: types.BSC,
    infoBar: InfoBarBSC,
    urlPrefix: "bsc"
  },
  [types.EPRR]: {
    ieeeTypes,
    submissionTypes,
    title: "Embedded Pack Recognition Certification",
    wizardSteps: batteryTabs[types.EPRR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.EPRR,
    infoBar: InfoBarCRR,
    urlPrefix: "eprr"
  }
};

export const batterySupplier = {
  [types.SAR]: {
    ieeeTypes,
    submissionTypes: [initial, renewal],
    title: "Site Audit Request",
    wizardSteps: batteryTabs[types.SAR],
    requiredAgreements: null,
    clientTypeId: types.SAR,
    infoBar: SiteAuditInfoBar,
    urlPrefix: "sar"
  },
  [types.CRR]: {
    ieeeTypes,
    submissionTypes,
    title: "Cell Recognition Request",
    wizardSteps: batteryTabs[types.CRR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.CRR,
    infoBar: InfoBarCRR,
    urlPrefix: "crr"
  },
  [types.HRR]: {
    ieeeTypes,
    submissionTypes,
    title: "Hybrid Recognition Request",
    wizardSteps: batteryTabs[types.HRR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.HRR,
    infoBar: InfoBarCRR,
    urlPrefix: "hrr"
  },
  [types.PRR]: {
    ieeeTypes,
    submissionTypes,
    title: "Pack Recognition Request",
    wizardSteps: batteryTabs[types.PRR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.PRR,
    infoBar: InfoBarCRR,
    urlPrefix: "prr"
  },
  [types.EPRR]: {
    ieeeTypes,
    submissionTypes,
    title: "Embedded Pack Recognition Certification",
    wizardSteps: batteryTabs[types.EPRR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.EPRR,
    infoBar: InfoBarCRR,
    urlPrefix: "eprr"
  },
  [types.ARR]: {
    ieeeTypes,
    submissionTypes,
    title: "Adapter Recognition Request",
    wizardSteps: batteryTabs[types.ARR],
    requiredAgreements: agreements.EPR_AGREEMENTS,
    clientTypeId: types.ARR,
    infoBar: InfoBarCRR,
    urlPrefix: "arr"
  },
  [types.ACR]: {
    ieeeTypes: [1725],
    submissionTypes,
    title: "Adapter Certification Request",
    wizardSteps: batteryTabs[types.ACR],
    requiredAgreements: agreements.BSC_AGREEMENTS,
    clientTypeId: types.ACR,
    infoBar: InfoBarCRR,
    urlPrefix: "acr"
  }
};

export const canRequestBeSaved = wizard => {
  const { certInfo, type } = wizard;

  // Describe types and submission types here:
  const toBeSaved = [
    {
      clientTypeId: types.CRR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.HRR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.PRR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.EPRR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.ARR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.ACR,
      submissionTypeId: initial
    },
    {
      clientTypeId: types.BSC,
      submissionTypeId: initial
    }
  ];

  const findMatch = _.find(toBeSaved, {
    clientTypeId: certInfo.clientTypeId,
    submissionTypeId: type
  });

  // can be saved if found in array of described elements
  return _.isUndefined(findMatch) === false;
};

export const isSAR = request => request && request.manufsiteid > 0;

export const getRequestCertType = request => {
  if (request.isCRR) return "crr";
  if (request.isHRR) return "hrr";
  if (request.isPRR) return "prr";
  if (request.isEPRR) return "eprr";
  if (request.isARR) return "arr";
  if (request.isACR) return "acr";
  if (request.isBLC) return "blc";
  if (request.isBSC) return "bsc";

  return "";
};

export const getTypeCalcPropName = typeId => {
  const key = _.findKey(types, v => v === typeId);

  if (key) return `is${key}`;

  console.warn(
    "WARNING: Passed unknown type ID. Check `getTypeCalcPropName()` method for details"
  );

  return null;
};

export const getOppositeIEEE = ieeeType => {
  if (ieeeType === 1725 || ieeeType === "1725") return 1625;

  return 1725;
};

export const mapBatteryRequestTypesToCertType = certTypes => {
  const allRequestTypes = { ...batterySupplier, ...batteryVendor };
  let dictionaryBatteryRequestIdToCertType = new Map();

  _.entries(allRequestTypes).forEach(([requestId, requestType]) => {
    const chosenCertTypes = _.pickBy(
      certTypes,
      certType => requestType.urlPrefix === certType.name.toLowerCase()
    );
    dictionaryBatteryRequestIdToCertType[requestId] = _.values(
      chosenCertTypes
    )[0];
  });

  return dictionaryBatteryRequestIdToCertType;
};

export const getCertTypesFromBatteryRequestIds = (
  certTypes,
  requestTypeIds
) => {
  let requestToCertMapping = mapBatteryRequestTypesToCertType(certTypes);
  let filteredMap = new Map();
  requestTypeIds.forEach(key => {
    filteredMap[key] = requestToCertMapping[key];
  });

  return filteredMap;
};

export const getCertTypeFromCertTypeId = ({ certTypeId, certTypeIds = {} }) => {
  if (!_.isNumber(certTypeId)) return null;

  const certType = _.find(
    certTypeIds,
    currentCert => currentCert.id === certTypeId
  );

  if (certType) return certType;

  console.warn(
    "WARNING: Passed unknown type ID. Check `getCertTypeFromCertTypeId` method for details"
  );

  return null;
};
