import React from "react";

const ActionButton = ({
  buttonComponent,
  buttonLabel,
  onClick,
  editable,
  customClassName = ""
}) => {
  const ActionButtonComponent = buttonComponent;
  return ActionButtonComponent ? (
    <ActionButtonComponent
      label={buttonLabel}
      onClick={onClick}
      disabled={!editable}
      action={onClick} // for ctia-ui action buttons
      customClassName={customClassName} // ctia-ui action buttons styling extension
    />
  ) : null;
};

export default ActionButton;
