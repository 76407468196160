import React from "react";
import PropTypes from "prop-types";
import { IconLabel } from "ctia-ui";
import { Button } from "react-bootstrap";

const EditButton = ({
  size,
  label = "Edit",
  action,
  disabled = false,
  ...rest
}) => {
  return (
    <Button
      disabled={disabled}
      bsSize={size}
      bsStyle="primary"
      onClick={action}
      {...rest}
    >
      <IconLabel label={label} awesomeIcon="pencil-alt" />
    </Button>
  );
};

EditButton.propTypes = {
  size: PropTypes.string,
  label: PropTypes.string,
  action: PropTypes.func
};

// EditButton.defaultProps = {
//   size: 'small',
//   label: "Edit"
// };

export default EditButton;
