import React from "react";
import { connect } from "react-redux";
import ContinueButton from "components/form/buttons/ContinueButton";
import { submitWizardStep } from "actions/wizard";

const WizardContinueButton = ({
  request,
  wizard,
  submitWizardStep,
  isValid,
  onContinue
}) => {
  const onClick = () => {
    // if handler is defined:
    if (onContinue) {
      onContinue();
      return; // stop here
    }

    // otherwise just call submit wizard step action:
    submitWizardStep();
  };

  if (wizard && !wizard.isFinalStep) {
    return (
      <ContinueButton
        onClick={onClick}
        disabled={!isValid}
        isLoading={request.isLoading}
      />
    );
  }

  return null;
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps, { submitWizardStep })(
  WizardContinueButton
);
