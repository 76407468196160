import React from 'react';
import PropTypes from 'prop-types';
import { ContinueLabel } from 'ctia-ui';
import Button from './FormButton';

const ContinueButton = ({ label, ...props }) => {
  return <Button bsStyle="info" text={label} {...props} />;
};

ContinueButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.any
};

ContinueButton.defaultProps = {
  show: true,
  label: <ContinueLabel />
};

export default ContinueButton;
