import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Bubble, CounterLabel } from "ctia-ui";
import { requestStatuses } from "helpers/RequestHelper";
import DeviceTableCyber from "containers/Devices/Cyber/DeviceTableCyber";
import DeviceTableOta from "containers/Devices/Ota/DeviceTableOta";
import DeviceTableBattery from "containers/Devices/Battery/BatteryDevices/BatteryDeviceTable/BatteryDeviceTable";
import DeviceTableDHR from "containers/Devices/DHR/DeviceTableDHR";
import DeviceTableBLC from "containers/Devices/BLC/DeviceTableBLC";
import GoBackButton from "components/form/buttons/GoBackButton";
import {
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import { clearDeviceList } from "actions/devices";
import { startPreloader, finishPreloader } from "actions/preloader";

const inputStyle = { width: 250, marginLeft: 15 };

class DeviceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterTerm: "",
      status: requestStatuses.approved
    };
  }

  componentDidUpdate(prevProps) {
    const { devices } = this.props;
    if (
      _.has(devices, "term") &&
      _.has(prevProps.devices, "term") &&
      devices.term !== prevProps.devices.term
    ) {
      // this.setState({ searchTerm: devices.term });
      // reset optional search term
      this.setState({ filterTerm: "" });
    }
  }

  changeHandler = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  startSearchResult = (list, term) => {
    return _.filter(list, device => {
      // need to convert any UPPER CASE to lower case first
      const modelName = String(device.modelnumber).toLowerCase();
      const manuName = String(device.name).toLowerCase();
      return (
        modelName.indexOf(term.toLowerCase()) !== -1 ||
        manuName.indexOf(term.toLowerCase()) !== -1
      );
    });
  };

  preventFormSubmit = event => event.preventDefault();

  render() {
    const { app, devices, user } = this.props;

    if (!devices || !devices.list) {
      return null;
    }

    const { list } = devices;
    const { filterTerm } = this.state;
    const deviceList = this.startSearchResult(list, filterTerm);

    return (
      <div>
        <GoBackButton
          label="Return to Search"
          onClickHandler={this.props.clearDeviceList}
        />
        <Bubble>
          Below is a list of the approved requests. Select a device and click on
          the <strong>Model Name/Number</strong> to see all Hardware/Software
          versions.
          <br />
          Search within the list of approved requests by entering the search
          terms.
        </Bubble>
        <Row>
          <Col xs={8} sm={6} md={9}>
            <Form inline bsSize="small" onSubmit={this.preventFormSubmit}>
              <FormGroup controlId="filterTerm" bsSize="small">
                <ControlLabel bsSize="small">
                  Enter Any Optional Search Terms:
                </ControlLabel>{" "}
                <FormControl
                  type="text"
                  value={filterTerm}
                  onChange={this.changeHandler}
                  style={inputStyle}
                />
              </FormGroup>
            </Form>
          </Col>
          <Col xs={4} sm={6} md={3} className="text-right">
            <CounterLabel counter={deviceList.length} title="Total records" />
          </Col>
        </Row>
        <hr />
        <DeviceTableCyber
          list={deviceList}
          hideVendors={user.isVendor}
          show={app.isCyberCert}
        />
        <DeviceTableOta
          list={deviceList}
          hideVendors={user.isVendor}
          show={app.isOTACert}
        />
        <DeviceTableBattery
          list={deviceList}
          hideVendors={user.isVendor || user.isSupplier}
          show={app.isBatteryCert}
        />
        <DeviceTableDHR
          list={deviceList}
          hideVendors={user.isVendor}
          show={app.isDHRCert}
        />
        <DeviceTableBLC
          list={deviceList}
          hideVendors={user.isVendor}
          show={app.isBLCCert}
        />
      </div>
    );
  }
}

function mapStateToProps({ app, devices, user, preloader }) {
  return { app, devices, user, preloader };
}

export default connect(mapStateToProps, {
  clearDeviceList,
  startPreloader,
  finishPreloader
})(DeviceList);
