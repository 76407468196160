import React from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";

const AuditResultsInfoBar = ({ request }) => {
  const reqInfo = [
    {
      key: "Supplier",
      value: request.manufSite.company.companyname
    },
    {
      key: "Manufacturing Site",
      value: request.manufSite.name
    },
    {
      key: "Test",
      value: "Battery Compliance"
    }
  ];

  return <InfoBar show={true} data={reqInfo} />;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(AuditResultsInfoBar);
