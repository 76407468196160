import React from "react";
import { FormControl } from "react-bootstrap";

const CompanyStaticReadonly = ({ asBlock = true, isStaff, isAccounting }) => {
  const css = "static-form-text bold";
  let companyName = "CTIA";

  if (isAccounting) companyName = "CTIA Accounting";
  if (asBlock) return <div className={css}>{companyName}</div>;

  return <FormControl.Static className={css}>{companyName}</FormControl.Static>;
};

export default CompanyStaticReadonly;
