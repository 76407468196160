import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import ModelOptions from "containers/request/battery/PackCellsWithRecognition/ModelOptions";
import { requestFields } from "helpers/BatterySupplierHelper";
import { formatModelsList, getPackCellById } from "helpers/SupplierHelper";
import { setValue } from "actions/request";

const SelectRecognitionModel = ({ request, suppliers, setValue }) => {
  const [show, setShow] = useState(false);
  const requestField = requestFields.model;
  const supplier = request[requestFields.supplier];
  const { cellModels } = suppliers;

  useEffect(() => {
    if (supplier && supplier > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }
  }, [supplier, requestField, setValue]);

  const onChange = event => {
    const modelId = Number(event.target.value);

    setValue(requestField, modelId);

    const cell = getPackCellById(modelId, cellModels);

    if (cell) {
      setValue(requestFields.modelId, modelId);
      setValue(requestFields.modelName, cell.title);
      setValue(requestFields.linkedRequestId, cell.reqId);
    } else {
      setValue(requestFields.modelId, null);
      setValue(requestFields.modelName, null);
      setValue(requestFields.linkedRequestId, null);
    }
  };

  if (!show || !cellModels || !cellModels.length) return null;

  return (
    <div>
      <ModelOptions />
      <FormElement
        type="select"
        id={requestField}
        show={Number(request[requestFields.modelOption]) === 1}
        value={request[requestField] || 0}
        onChange={onChange}
        disabledOptionLabel="in use"
        options={formatModelsList(cellModels, request.cellSuppliers)}
      />
    </div>
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, { setValue })(SelectRecognitionModel);
