import React from "react";
import { connect } from "react-redux";
import { IconLabel } from "ctia-ui";

const SelectedUserLab = ({ users2labs }) => {
  if (users2labs && users2labs.transferFormVisible) {
    return (
      <h6>
        <IconLabel
          awesomeIcon="flask"
          label={users2labs.selectedUserData.companyname}
        />
      </h6>
    );
  }

  return null;
};

function mapStateToProps({ users2labs }) {
  return { users2labs };
}

export default connect(mapStateToProps)(SelectedUserLab);
