import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import ModelOptions from "containers/request/battery/AdapterSupplier/AdapterModelOptions";
import { formatPackModels } from "helpers/SupplierHelper";
import {
  hasUnrecognizedAdapter,
  getAdapterModelById,
  requestFields
} from "helpers/AdapterSupplierHelper";
import {
  fetchCertifiedAdapterRequests,
  fetchRecognizedAdapterRequests
} from "actions/suppliers";
import { setValue } from "actions/request";

const SelectAdapterModel = ({
  app,
  request,
  suppliers,
  setValue,
  fetchCertifiedAdapterRequests,
  fetchRecognizedAdapterRequests
}) => {
  const [show, setShow] = useState(false);
  const requestField = requestFields.model;
  const supplier = request[requestFields.supplier];
  const { certifiedAdapters: crtList, recognizedAdapters: recList } = suppliers;
  const adapterType = Number(request[requestFields.type]);
  const isRecognition = adapterType === 1;
  const models = isRecognition ? recList : crtList;

  useEffect(() => {
    if (supplier && supplier > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }
  }, [supplier, requestField, setValue]);

  const onChange = event => {
    const modelId = Number(event.target.value);

    // reset batteryRevision value
    setValue(requestFields.revision, "");

    // set batteryModel value
    setValue(requestField, modelId);

    const model = getAdapterModelById(modelId, models);

    if (model) {
      setValue(requestFields.modelId, modelId);
      setValue(requestFields.modelName, model.title);

      // fetch related requests per selected model
      return isRecognition
        ? fetchRecognizedAdapterRequests(modelId, request.ieee)
        : fetchCertifiedAdapterRequests(modelId, request.ieee);
    } else {
      setValue(requestFields.modelId, null);
      setValue(requestFields.modelName, null);
    }
  };

  if (!show) return null;

  // don't render for unrecognized adapters
  if (hasUnrecognizedAdapter(request)) return null;

  if (!crtList && !recList) return null;

  return (
    <div>
      <ModelOptions />
      <FormElement
        type="select"
        id={requestField}
        show={Number(request[requestFields.modelOption]) === 1}
        value={request[requestField] || 0}
        onChange={onChange}
        options={formatPackModels(models)}
      />
    </div>
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  setValue,
  fetchCertifiedAdapterRequests,
  fetchRecognizedAdapterRequests
})(SelectAdapterModel);
