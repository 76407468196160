import React from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  Label,
  Button,
  Form,
  Col
} from "react-bootstrap";
import SearchResult from "./SearchResult";
import NoData from "./NoData";
import { IconLabel } from "ctia-ui";
import { searchUser, clearUserList } from "actions/user";
import CyberTable from "containers/CyberTable";
import GoBackButton from "components/form/buttons/GoBackButton";

class UserSearch extends CyberTable {
  state = { term: "", noData: false };

  componentWillUnmount() {
    this.props.clearUserList();
  }

  onChange = e => {
    this.setState({ term: e.target.value, noData: false });
  };

  onClick = () => {
    this.props.searchUser(this.state.term).then(() => {
      const noData = this.setTableData(this.props.users).length === 0;
      this.setState({ noData });
    });
  };

  onFormSubmit = event => {
    // prevent default form submission
    event.preventDefault();

    // emulate button click
    this.onClick();
  };

  render() {
    const { users } = this.props;
    const { term, noData } = this.state;
    const tableData = this.setTableData(users);

    return (
      <div>
        <h1 className="block-bottom-offset">
          User Administration <Label>Search User</Label>
        </h1>
        <GoBackButton
          dest="/admin/cp/users/"
          label="Return to User Admin"
        />
        <div className="text-center block-top-offset">
          <Form horizontal onSubmit={this.onFormSubmit}>
            <FormGroup controlId="quickSearchForm">
              <Col
                componentClass={ControlLabel}
                xs={12}
                sm={2}
                smOffset={2}
                md={2}
                mdOffset={2}
                className="text-left"
              >
                Search User:
              </Col>
              <Col xs={12} sm={6} md={5} id="ctia-textinput">
                <InputGroup>
                  <FormControl
                    autoFocus
                    type="text"
                    value={term}
                    onChange={this.onChange}
                  />
                  <InputGroup.Button>
                    <Button onClick={this.onClick} disabled={term.length < 2}>
                      <IconLabel awesomeIcon="search" label="Search" />
                    </Button>
                  </InputGroup.Button>
                </InputGroup>
                <FormControl.Feedback />
                <NoData show={noData} />
              </Col>
              <Col xs={12} sm={2} md={3} />
            </FormGroup>
          </Form>
        </div>
        <SearchResult data={tableData} />
      </div>
    );
  }
}

function mapStateToProps({ users }) {
  return { users };
}

export default connect(mapStateToProps, {
  searchUser,
  clearUserList
})(UserSearch);
