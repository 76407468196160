import _ from "lodash";
import React from "react";
import { FormControl } from "react-bootstrap";

const StaticText = ({ value }) => {
  const val = _.size(_.toString(value)) > 0 ? value : "n/a";
  const lines = _.isString(val) ? val.split(/<br>|\n|\r/) : null;

  if (lines && lines.length > 1)
    return (
      <>
        {lines.map(item => (
          <FormControl.Static className="dark-teal bold">
            {item}
          </FormControl.Static>
        ))}
      </>
    );

  return (
    <FormControl.Static className="dark-teal bold">{val}</FormControl.Static>
  );
};

export default StaticText;
