import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Bubble,
  NoData,
  InlineDatepicker,
  LoaderButton,
  IconLabel
} from "ctia-ui";
import ErrorMsg from "components/ErrorMsg";
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
  // Checkbox
} from "react-bootstrap";
import {
  fetchDeviceList,
  setSearchTerm,
  clearDeviceList
} from "actions/devices";
import FormElement from "components/FormElement";

class DevicesSearch extends Component {
  constructor(props) {
    super(props);

    this.minDate = props.app.isBLCCert
      ? moment(props.app.certMinDateBLC)
      : moment(props.app.certMinDate);

    this.state = {
      isLoading: false,
      searchTerm: "",
      devicetype: "",
      dateFrom: this.minDate,
      dateTo: moment(),
      showFiltered: true // must be `false` by default if checkbox functionality will be returned in future
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  changeDate = (id, date) => {
    this.setState({
      [id]: date
    });
  };

  checkboxHandler = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  onSubmit = () => {
    const { app } = this.props;
    const program = app.programPrefix ? app.programPrefix.toUpperCase() : null;
    const certId = program ? app[`id${program}`] : null;

    // enable loading process
    this.toggleLoaderState();

    // set searech term to use then in list component as a default value and filter
    this.props.setSearchTerm(this.state.searchTerm);

    // fetch device list, also use callback to stop loading after fetching
    this.props.fetchDeviceList(this.state, certId).then(this.toggleLoaderState);
  };

  toggleLoaderState = () => {
    const loaderState = this.state.isLoading;
    this.setState({ isLoading: !loaderState });
  };

  getFilterLabel = () => {
    const { user } = this.props;
    const { company } = user;
    if (user.isVendor) return `${company} IoT Cybersecurity certified Devices`;
    if (user.isLab)
      return `${company}-Tested IoT Cybersecurity certified Devices`;
    return null;
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.onSubmit();
  };

  render() {
    const { devices, history, app /*user*/ } = this.props;

    // if request had finished with error
    if (devices && devices.searchError) {
      return <ErrorMsg error={devices.searchError} history={history} />;
    }

    // don't render component if device list is non-empty
    if (devices && devices.list) {
      return null;
    }

    const {
      isLoading,
      dateFrom,
      dateTo,
      // showFiltered,
      searchTerm,
      devicetype
    } = this.state;

    const deviceTypeList = [
      {
          linkTitle: "Select Device Type ↴",
          value: ""
      },
      {
          linkTitle: "IoT Device",
          value: "IoT Device",
          certType: "blc"
      },
      {
          linkTitle: "Smartphone",
          value: "Smartphone",
          certType: "blc"
      },
      {
          linkTitle: "Smartwatch",
          value: "Smartwatch",
          certType: "blc"
      }
    ];

    const dateLabel = app.isOTACert ? "Approval" : "Certification";

    return (
      <div>
        <Bubble>
          Please enter any optional search terms or dates, then click{" "}
          <strong>Search</strong>.
        </Bubble>
        <NoData show={devices && devices.noData === true} />
        <Form horizontal onSubmit={this.onFormSubmit}>
          <FormGroup controlId="searchTerm">
            <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
              Search Terms:
            </Col>
            <Col xs={12} sm={5} md={5}>
              <FormControl
                type="text"
                value={searchTerm}
                onChange={this.changeHandler}
              />
            </Col>
          </FormGroup>
          {app.isBLCCert ?
            <FormGroup controlId="devicetype" show={app.isBLCCert}>
              <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
                Device Type:
              </Col>
              <Col xs={12} sm={5} md={5}>
              <FormElement
                type="select"
                id="devicetype"
                label=""
                value={devicetype}
                onChange={this.changeHandler}
                options={deviceTypeList}
              />
              </Col>
            </FormGroup>
            :
            <></>
            }

          <FormGroup>
            <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
              {dateLabel} Date:
            </Col>
            <Col className="datepicker-block" xs={12} sm={1} md={1}>
              <DatePicker
                value={dateFrom}
                selected={dateFrom}
                customInput={<InlineDatepicker label="From: " />}
                onChange={this.changeDate.bind(this, "dateFrom")}
                minDate={this.minDate}
                maxDate={dateTo}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Col>
            <Col className="datepicker-block" xs={12} sm={1} md={1}>
              <DatePicker
                value={dateTo}
                selected={dateTo}
                customInput={<InlineDatepicker label="To: " />}
                onChange={this.changeDate.bind(this, "dateTo")}
                minDate={dateFrom}
                maxDate={moment()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Col>
          </FormGroup>
          {/* Show for Vendors and Labs only */}
          {/* {!user.isStaff &&
            !user.isOperator && (
              <FormGroup controlId="searchFilterOption">
                <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
                  Select Devices to View:
                </Col>
                <Col xs={12} sm={9} md={9}>
                  <Checkbox
                    id="showFiltered"
                    onChange={this.checkboxHandler}
                    value={showFiltered}
                    checked={showFiltered}
                  >
                    {this.getFilterLabel()}
                  </Checkbox>
                </Col>
              </FormGroup>
            )} */}
          <FormGroup>
            <Col xs={12} sm={9} md={9} mdOffset={3} smOffset={3}>
              <LoaderButton
                bsStyle="info"
                text={<IconLabel label="Search" awesomeIcon="search" />}
                onClick={this.onSubmit}
                isLoading={isLoading}
                className="btn-shadow"
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ devices, user, app }) {
  return { devices, user, app };
}

export default connect(mapStateToProps, {
  fetchDeviceList,
  setSearchTerm,
  clearDeviceList
})(DevicesSearch);
