import _ from "lodash";
import React, { useState, useRef } from "react";
import { Form } from "ctia-react-bootstrap-v4";

const LabInterfaceSelectors = ({
  checkedIdsOnInit = [],
  onChange = () => {},
}) => {

  // CTIA and the PTCRB Cert's use the CTIAID value for their ID below, but IoT instead uses its ID value from the CTIA certtypes table
  // This is because the submission process assumes the ID values in CTIA's certtypes table and tblCertType in CTIACertTracker are the same
  // This is true for CTIA and PTCRB Primary, but PTCRB Associate's ID is different. It's CTIAID of 27 is used to pull the entry with ID = 27 from certtypes,
  // which only work because there's an entry with ID = 27, OTA. IoT's CTIAID is 28, but there's no ID = 28 in certtypes, which causes submission to fail.
  // Having its ID of 41 works though.
  const primaryRef = useRef(null);
  const associateRef = useRef(null);

  const ctiaCert = {type: 'certification', id: 1, value: "CTIA", isSelectable: true};
  const [ptcrbPrimary] = useState({
    type: 'certification',
    id: 2,
    value: "PTCRB Primary"
  });
  const [ptcrbAssoc] = useState({
    type: 'certification',
    id: 3,
    value: "PTCRB Associate"
  });
  const [IoT] = useState({
    type: 'certification',
    id: 41,
    value: "IoT Network Certified"
  });
  
  const localChange = event => {
    const { value } = event.target;

    if (value === "2") {
      associateRef.current.checked = false;
    }
    else if (value === "3") {
      primaryRef.current.checked = false;
    }

    onChange(event);
  };
  
  return [ctiaCert, ptcrbPrimary, ptcrbAssoc, IoT].map((testingCapability, index) => {
    const {id, type, value} = testingCapability;

    return (
      <Form.Check
        inline
        id={`${type}${index}`}
        defaultChecked={_.includes(checkedIdsOnInit, id)}
        key={index}
        label={value}
        onChange={localChange}
        type="checkbox"
        value={id}
        ref={id === 2 ? primaryRef : id === 3 ? associateRef : null}
      />);
  });
};

export default LabInterfaceSelectors;