import React from "react";
import { Link } from "react-router-dom";
import "containers/Logo/style.css";

const LinkWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

const LogoPng = ({
  width = 125,
  height = 141,
  cssClass = "",
  linkTo = "",
  inverted = false
}) => {
  const color = inverted ? "_Inverted" : "";
  const src = `/CTIA-Certification_Logo${color}.png`;
  return (
    <div className="logo">
      <LinkWrapper
        condition={linkTo}
        wrapper={children => <Link to={linkTo}>{children}</Link>}
      >
        <img
          src={src}
          width={width}
          height={height}
          alt="CTIA Certification Database"
          className={cssClass}
        />
      </LinkWrapper>
    </div>
  );
};

export default LogoPng;
