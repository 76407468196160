import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { toMomentDate } from "helpers/DateHelper";
import { handleInputChange } from "actions/request";
import MethodSelector from "containers/request/Payments/PaymentMethodSelector";
import CheckNumber from "containers/request/Payments/PaymentCheckNumber";
import PaymentFormIvcDate from "containers/request/Payments/PaymentFormIvcDate";

const PaymentFormFields = ({ request, handleInputChange }) => {
  const onDateChange = momentDate => {
    handleInputChange({
      target: { id: "payDate", value: momentDate }
    });
  };

  const payDate = toMomentDate(request.payDate);

  return (
    <div>
      <PaymentFormIvcDate />
      <MethodSelector />
      <CheckNumber />
      <FormElement
        type="date"
        id="payDate"
        label="Payment Date"
        value={payDate}
        onChange={onDateChange}
      />
      <FormElement
        type="textarea"
        id="payNote"
        label="Payment Note"
        placeholder="Optional"
        value={request.payNote}
        onChange={handleInputChange}
      />
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange
})(PaymentFormFields);
