import React, { useState } from "react";
import { connect } from "react-redux";
import { DeleteButton, Drawer } from "ctia-ui";
import { removeCellSupplier } from "actions/suppliers";
import RecognizedSubsystem from "containers/request/battery/PackCells/RecognizedSubsystem";
import Badge from "components/Badges/RecognitionBadge";

const PackCellsListRow = ({
  request,
  supplier,
  suppliers,
  removeCellSupplier
}) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  if (!supplier) return null;

  if (request.cellSuppliers.length > 0) {
    const {
      hasPendingRecognition,
      linkedrequestdevicemodelid: cellModelId,
      linkedrequestvendorname: supplierName,
      linkedrequestvendorproductname: cellModelName,
      linkedrequestvendorid: vendorId,
      linkedrequestid: reqId
    } = supplier;

    const idToBeRemoved = {
      requestId: reqId || null,
      vendorId: reqId ? null : vendorId
    };

    return (
      <tr>
        <td>{supplierName}</td>
        <td>
          {hasPendingRecognition ? (
            <Badge />
          ) : (
            <>
              <span
                onClick={toggleDrawer}
                className="agr-link cursor-pointer glow-link"
              >
                {cellModelName}
              </span>
              <Drawer
                position="bottom"
                show={showDrawer}
                title="Recognized Subsystem Detail"
                toggleDrawer={toggleDrawer}
                content={
                  <RecognizedSubsystem modelId={cellModelId} certPrefix="crr" />
                }
                height="auto"
              />
            </>
          )}
        </td>
        <td>
          <DeleteButton
            size="xs"
            label="Remove Cell"
            action={() => removeCellSupplier(idToBeRemoved)}
          />
        </td>
      </tr>
    );
  }

  return null;
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  removeCellSupplier
})(PackCellsListRow);
