import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Bubble } from "ctia-ui";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateUserLocation } from "actions/user";
import { handleCheckboxGroupChange } from "actions/request";
import FormElement from "components/FormElement";

const introMsg = "You must select at least one manufacturing site.";

// Note: need to keep request as prop here, w/o it component won't update linked sites
const ManufSitesCRR = ({ request, sites, updateUserLocation, handleCheckboxGroupChange }) => {
    let history = useHistory();
    let [someChecked, setSomeChecked] = useState(false);

    useEffect(() => {
        setSomeChecked(_.some(sites.list, "checked"));
    }, [sites.list]);

    if (!sites) return "Loading...";

    if (!sites.list || _.isEmpty(sites.list)) {
        const redirectSubmit = () => {
            const url = "/battery/supplier/request/new";
            updateUserLocation(url);
            history.push(url);
        };

        return (
            <Bubble type="danger">
                {introMsg} If the site does not appear on the page, then you must first
                submit a site audit request. Click{" "}
                <span
                    className="cursor-pointer glow-link"
                    onClick={() => redirectSubmit()}
                >
                    here
                </span>{" "}
                to submit a site audit request.
            </Bubble>
        );
    }

    return (
        <>
            {!someChecked && (
                <Bubble icon="alert" type="warning">
                    {introMsg}
                </Bubble>
            )}
            <FormElement
                type="checkboxList"
                id="linkedManufsites"
                label="Manufacturing Site(s)"
                onChange={handleCheckboxGroupChange.bind(this, sites.list)}
                list={sites.list}
                editable={true}
                inline={false}
            />
        </>
    );
};

function mapStateToProps({ request, sites, user }) {
    return { request, sites, user };
}

export default connect(mapStateToProps, {
    handleCheckboxGroupChange,
    updateUserLocation
})(ManufSitesCRR);
