import React from "react";
import { connect } from "react-redux";
import { WithModal, IconLabel } from "ctia-ui";
import EditInvoiceModalATL from "containers/ControlPanel/Payments/EditInvoice/EditInvoiceModalATL";
import { clearInvoiceATL } from "actions/catl";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

class EditInvoiceATL extends WithModal {
  modalHandler = () => {
    const { catl, showNotice } = this.props;

    if (catl.isBackgroundJobRunning) {
      return showNotice(notifyMessages.backgroundJobInActionWarning);
    }

    this.props.clearInvoiceATL();
    this.handleModal();
  };

  render() {
    return this.props.showLink ? (
      <div>
        <IconLabel awesomeIcon="edit" />{" "}
        <span className="glow-link cursor-pointer" onClick={this.modalHandler}>
          Edit Invoice
        </span>
        <EditInvoiceModalATL
          show={this.state.showModal}
          modalHandler={this.modalHandler}
          {...this.props}
        />
      </div>
    ) : null;
  }
}

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, { clearInvoiceATL, showNotice })(
  EditInvoiceATL
);
