import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, Drawer } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import EditInvoiceASC from "containers/ControlPanel/Payments/ASC/EditInvoice/EditInvoiceASC";
import ConfirmPaymentASC from "containers/ControlPanel/Payments/ASC/ConfirmPaymentASC/ConfirmPaymentASC";
import RemovePaymentASC from "containers/ControlPanel/Payments/ASC/RemovePaymentASC";
import DownloadInvoiceASC from "containers/ControlPanel/Payments/ASC/DownloadInvoiceASC";
import CenterUsers from "containers/ControlPanel/CompanyUsers";
import ShowCommentsASC from "containers/ControlPanel/Payments/ASC/ShowCommentsASC";
import DownloadSignedAgrASC from "containers/ControlPanel/Payments/ASC/DownloadSignedAgrASC";
import * as rh from "containers/ControlPanel/Payments/RenderHelperATL";
import { mapYearBasedProps } from "helpers/AscHelper";
import { clearComments } from "actions/comments";
import "containers/ControlPanel/Payments/payments.css";

const CenterRowASC = ({ center, asc, clearComments, togglecenterFee }) => {
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const toggleBottomDrawer = () => setShowBottomDrawer(!showBottomDrawer);

  const yProps = mapYearBasedProps(asc.invoicingYear, center);

  const showLink = center => {
    // not show for all prev years
    if (asc.readOnly) return false;

    return !yProps.invoicePaid && yProps.invoiceId;
  };

  const cityCountry = center.citydisplay
    ? `${center.citydisplay}, ${center.countrydisplay}`
    : center.countrydisplay;

  return (
    <tr>
      <td>
        <Col className="payments-center-link">
          <h4 className="bold">{center.name}</h4>
        </Col>
        <Col style={{ paddingBottom: 15 }}>
          <small>{rh.renderNameWithFlag(cityCountry, center.country)}</small>
          <small className="block-left-offset">
            <IconLabel awesomeIcon="user" label={center.contactname || "n/a"} />
          </small>
          <small className="block-left-offset">
            <span
              className="glow-link cursor-pointer"
              onClick={toggleBottomDrawer}
            >
              <IconLabel awesomeIcon="users" label="View All Users" />
            </span>
          </small>
        </Col>
        <Row>
          <Col xs={6}>
            Agreement: {rh.renderAgreementBagde(yProps.agreementSigned)}
            <DownloadSignedAgrASC
              show={yProps.agreementSigned}
              companyId={center.id}
              ivcNum={yProps.invoiceNumber}
              year={asc.invoicingYear}
            />
          </Col>
          <Col xs={6}>
            {rh.renderInvoiceBagde(
              yProps.invoicePaid,
              yProps.invoicePaidDate,
              yProps.invoiceNumber
            )}
          </Col>
        </Row>
      </td>
      <td>
        <Drawer
          position="bottom"
          show={showBottomDrawer}
          title="View Users"
          toggleDrawer={toggleBottomDrawer}
          content={
            <CenterUsers
              companyId={center.id}
              companyName={center.name}
              flagCode={center.country}
            />
          }
          height="400"
        />
        <EditInvoiceASC
          id={yProps.invoiceId}
          ivcNum={yProps.invoiceNumber}
          showLink={showLink(center)}
        />
        {yProps.invoiceId ? (
          <DownloadInvoiceASC
            ivcId={yProps.invoiceId}
            ivcNum={yProps.invoiceNumber}
          />
        ) : null}
        <ConfirmPaymentASC
          id={yProps.invoiceId}
          centerName={center.name}
          flagCode={center.country}
          ivcNum={yProps.invoiceNumber}
          showLink={showLink(center)}
        />
        <RemovePaymentASC
          ivcId={yProps.invoiceId}
          showLink={yProps.invoicePaid}
        />
        <ShowCommentsASC center={center} />
      </td>
    </tr>
  );
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, {
  clearComments
})(CenterRowASC);
