import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, LoaderButton, TooltipBase } from "ctia-ui";
import { saveCatlInvoices, fetchAtlList, fetchAtlInvoices } from "actions/catl";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

const SaveInvoices = ({
  catl,
  saveCatlInvoices,
  fetchAtlList,
  fetchAtlInvoices,
  showNotice
}) => {
  const [loading, setLoading] = useState(false);

  if (catl.readOnly) return null;

  const tipMsg =
    "Background job is running. Button will be enabled in a moment";

  const renderBtn = () => (
    <LoaderButton
      bsStyle="primary"
      bsSize="large"
      text={<IconLabel awesomeIcon="save" label="Generate Invoices" />}
      onClick={() => {
        setLoading(true);
        saveCatlInvoices(catl.labs, catl.invoicingYear)
          .then(() => fetchAtlList(catl.invoicingYear))
          .then(() => {
            setLoading(false);
            showNotice(notifyMessages.atlBulkingSuccessful);
            fetchAtlInvoices(catl.invoicingYear);
          })
          .catch(() => setLoading(false));
      }}
      isLoading={loading}
      loadingText="Working..."
      className="button-top-offset"
      disabled={catl.isBackgroundJobRunning}
    />
  );

  if (catl.isBackgroundJobRunning) {
    return (
      <TooltipBase tipText={tipMsg} tipPlacement="left">
        <div>{renderBtn()}</div>
      </TooltipBase>
    );
  }

  return renderBtn();
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, {
  saveCatlInvoices,
  fetchAtlList,
  fetchAtlInvoices,
  showNotice
})(SaveInvoices);
