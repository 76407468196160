import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "components/form/Select";
import { FormControl } from "react-bootstrap";
import StaticText from "components/form/StaticText";
import voltage from "helpers/dictionaries/voltage";
import { makeEvent } from "libs/form";

export const getVoltageTypeById = (id) =>
  voltage.find((v) => v.value === id).title;

const Voltage = ({
  id,
  onChange,
  values = [1, 0],
  editable = true,
  request,
}) => {
  if (!Number.isInteger(values[0])) values[0] = 1;

  const [selectorValue, inputValue] = values;

  if (!editable) {
    if (inputValue === "" || selectorValue === "") {
      return <StaticText value="Legacy" />;
    }

    const val = inputValue || "n/a";
    const text = selectorValue
      ? `${getVoltageTypeById(selectorValue)} — ${val}`
      : val;

    return <StaticText value={text} />;
  }

  const onSelectorChange = (event) => {
    const value = parseInt(event.target.value);
    const updatedValues = [value, values[1]];
    const syntEvent = makeEvent(id, updatedValues);
    return onChange(syntEvent);
  };

  const onInputChange = (event) => {
    const { value } = event.target;
    const updatedValues = [values[0], value];
    const syntEvent = makeEvent(id, updatedValues);
    return onChange(syntEvent);
  };

  return (
    <div className="form-inline">
      <Select
        editable={true}
        options={voltage}
        value={selectorValue}
        onChange={onSelectorChange}
      />
      <div className="voltage-divider">&nbsp;</div>
      <FormControl type="text" value={inputValue} onChange={onInputChange} />
    </div>
  );
};

Voltage.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(Voltage);
