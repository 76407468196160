import _ from "lodash";
import validation from "dictionaries/Validation";
import { mergeExtPhone } from "helpers/UsersHelper";

export const mapComponentStateToApiParams = state => {
  const params = {
    firstname: state.firstName,
    lastname: state.lastName,
    title: state.title,
    email: state.email,
    address1: state.address1,
    address2: state.address2,
    address3: state.address3,
    workphone: mergeExtPhone(state.workPhone, state.workPhoneExt),
    mobilephone: state.mobilePhone,
    city: state.city,
    countryid: state.country,
    zipcode: state.zipCode,
    stateid: state.state,
    website: state.website
  };

  const { newPassword, confirmNewPassword } = state;
  if (!_.isEmpty(newPassword) && !_.isEmpty(confirmNewPassword)) {
    params["password"] = newPassword;
    params["confirmpassword"] = confirmNewPassword;
  }

  return params;
};

// TODO: this code sucks
// need to refactor it
export const getUpdatedState = ({ eventId, eventValue, formFieldValues }) => {
  switch (eventId) {
    case "firstName":
      return { ...formFieldValues, firstName: eventValue };
    case "lastName":
      return { ...formFieldValues, lastName: eventValue };
    case "userName":
      return { ...formFieldValues, userName: eventValue };
    case "company":
      return { ...formFieldValues, company: eventValue };
    case "title":
      return { ...formFieldValues, title: eventValue };
    case "address1":
      return { ...formFieldValues, address1: eventValue };
    case "address2":
      return { ...formFieldValues, address2: eventValue };
    case "address3":
      return { ...formFieldValues, address3: eventValue };
    case "city":
      return { ...formFieldValues, city: eventValue };
    case "state":
      return { ...formFieldValues, state: eventValue };
    case "zipCode":
      return { ...formFieldValues, zipCode: eventValue };
    case "country":
      return { ...formFieldValues, country: eventValue };
    case "website":
      return { ...formFieldValues, website: eventValue };
    case "email":
      return { ...formFieldValues, email: eventValue };
    case "workPhone":
      return { ...formFieldValues, workPhone: eventValue };
    case "workPhoneExt":
        return { ...formFieldValues, workPhoneExt: eventValue };
    case "mobilePhone":
      return { ...formFieldValues, mobilePhone: eventValue };
    case "newPassword":
      return { ...formFieldValues, newPassword: eventValue };
    case "confirmNewPassword":
      return { ...formFieldValues, confirmNewPassword: eventValue };
    default:
      return formFieldValues;
  }
};

export const getFormFieldValues = state => {
  return {
    firstName: state.firstName,
    lastName: state.lastName,
    userName: state.userName,
    company: state.company,
    website: state.website,
    title: state.title,
    address1: state.address1,
    address2: state.address2,
    address3: state.address3,
    city: state.city,
    state: state.state,
    zipCode: state.zipCode,
    country: state.country,
    email: state.email,
    workPhone: state.workPhone ? String(state.workPhone) : null,
    workPhoneExt: state.workPhoneExt,
    mobilePhone: state.mobilePhone,
    newPassword: state.newPassword,
    confirmNewPassword: state.confirmNewPassword
  };
};

export const selectedCountryRequiredState = country => {
  if (!country) {
    return false;
  }

  return Boolean(country === "US" || country === "CA");
};

export const setFormFieldValuesFromUser = user => {
  return {
    firstName: user.firstname,
    lastName: user.lastname,
    userName: user.username,
    company: user.company,
    website: user.website,
    title: user.title,
    address1: user.address1,
    address2: user.address2,
    address3: user.address3,
    city: user.city,
    state: user.stateid,
    zipCode: user.zipcode,
    country: user.countryid,
    email: user.email,
    workPhone: String(user.workphone),
    workPhoneExt: String(user.workphoneext),
    mobilePhone: user.mobilephone
  };
};

export const validatePassword = (newPassword, confirmPassword) => {
  // CASE: Error - this shouldn't happen, these values should always be at least empty strings.
  if (newPassword === null || confirmPassword === null) {
    return {
      isValid: false,
      message: ""
    };
  }

  if (_.isEmpty(newPassword) && _.isEmpty(confirmPassword)) {
    return {
      isValid: true,
      message: ""
    };
  }

  if (newPassword !== confirmPassword) {
    return {
      isValid: false,
      message: validation.passwordsDontMatch
    };
  }

  if (newPassword.length < 8) {
    return {
      isValid: false,
      message: validation.passwordTooShort
    };
  }

  const hasLetters = newPassword.match("[a-zA-Z]+");
  const hasDigits = newPassword.match("[0-9]+");
  const hasSpecChars = newPassword.match("[^0-9a-zA-Z]+");

  // CASE: Password does not meet enough criteria
  if (_.some([hasLetters, hasDigits, hasSpecChars], rule => rule === null)) {
    return {
      isValid: false,
      message: validation.invalidPasswordFormat
    };
  }

  return {
    isValid: true,
    message: ""
  };
};
