import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import FormElement from "components/FormElement";
import { handleInputChange, setValue } from "actions/request";
import { hasStringValue } from "helpers/RequestHelper";
import * as formHelper from "helpers/FormHelper";
import * as modelHelper from "helpers/ModelHelper";

const CyberModelAutocomplete = ({
  model,
  request,
  user,
  handleInputChange,
  setValue
}) => {
  const [touched, setTouched] = useState(false);
  const [{ validationState, validationMessage }, setValidationError] = useState(
    {
      validationState: null,
      validationMessage: ""
    }
  );

  const fieldName = formHelper.defineModelFieldId(request);
  const isEditable = modelHelper.isAutocompleteModelEditable(request, user);

  const onFieldChanged = event => {
    if (!touched) {
      setTouched(true);
    }

    handleInputChange(event);
  };

  const inputType = formHelper.defineEcoModelFieldType(isEditable, request);
  const modelName = formHelper.defineBatteryModelName(isEditable, request);
  const vendorModels = model && model.ptcrbModels ? model.ptcrbModels : [];

  // TODO: prob get rid of it
  useEffect(() => {
    let validationState = null;
    let validationMessage = "";

    if (isEditable && touched) {
      if (!hasStringValue(request, fieldName)) {
        validationState = "error";
        validationMessage = formHelper.emptyMsg;
      } else if (request.modelNameAlreadyInUse) {
        validationState = "error";
        validationMessage = formHelper.modelNameExists;
      }
    }

    setValidationError({
      validationState,
      validationMessage
    });
  }, [modelName, fieldName, request, touched, isEditable]);

  return (
    <FormElement
      id={fieldName}
      type={inputType}
      label="Model Name/Number"
      value={modelName}
      options={vendorModels}
      editable={isEditable}
      onChange={onFieldChanged}
      validationState={validationState}
      validationMessage={validationMessage}
    />
  );
};

function mapStateToProps({ model, request, user }) {
  return { model, request, user };
}

export default connect(mapStateToProps, {
  handleInputChange,
  setValue
})(CyberModelAutocomplete);
