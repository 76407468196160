import _ from "lodash";
import { restClientGeneral, restClientAdmin } from "libs/restClient";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { prepareOperatorOrgArgs } from "helpers/ArgsOrgAdminHelper";

export const clearAllCompanies = () => dispatch => {
  dispatch({
    type: actions.CLEAR_ALL_COMPANIES
  });
};

export const appendCompanyToStore =
  (companyObject, companyType) => dispatch => {
    if (_.isEmpty(companyObject) || _.isEmpty(companyType)) return false;

    const payload = { companyObject, companyType };
    dispatch({
      type: actions.APPEND_COMPANY_TO_STORE,
      payload
    });

    return true;
  };

export const fetchLabs = () => async dispatch => {
  try {
    const format = `&returnAs=query`; // Remove this if updating and adding include clause
    const url = `/companies/labs?select=ctiaid,id,name&page=0&where=isActiveLab = 1 AND isActiveCATL = 1&orderby=name${format}`;
    const response = await restClientGeneral.get(url);
    const payload = response.data.data;

    dispatch({
      type: actions.FETCH_AUTHORIZED_LABS,
      payload
    });

    return payload;
  } catch (error) {
    return error;
  }
};

export const fetchPtcrbLabs = () => async dispatch => {
  try {
    const select = `?select=id,name`;
    const where = `&where=active=1`;
    const getAll = `&page=0`;
    const orderBy = `&orderby=name`;
    const format = `&returnAs=query`; // Remove this if updating and adding include clause
    const url = `/ptcrb/legacy/labs${select}${where}${getAll}${orderBy}${format}`;

    const response = await restClientGeneral.get(url);
    const payload = response.data.data;

    dispatch({
      type: actions.FETCH_AUTHORIZED_PTCRB_LABS,
      payload
    });

    return payload;
  } catch (error) {
    return error;
  }
};

export const fetchManufacturers =
  (onlyNamesAndIds = true) =>
  async dispatch => {
    try {
      const select = onlyNamesAndIds ? "&select=ctiaid,id,name" : "";
      const where = `where = casid is null AND roleid=2`;
      const otherFlags = `&isSupplier=false&page=0`;
      const format = `&returnAs=query`; // Remove this if updating and adding include clause
      const url = `/admin/companies/vendors?${where}${select}${otherFlags}${format}`;
      const response = await restClientGeneral.get(url);
      const payload = response.data.data;

      dispatch({
        type: actions.FETCH_AUTHORIZED_MANUFACTURERS,
        payload
      });

      return payload;
    } catch (error) {
      throw error;
    }
  };

export const fetchOperators =
  (onlyNamesAndIds = true) =>
  async dispatch => {
    try {
      const select = onlyNamesAndIds ? "&select=ctiaid,id,name" : "";
      const format = `&returnAs=query`; // Remove this if updating and adding include clause
      const url = `/admin/companies/operators?page=0&orderby=name${select}${format}`;
      const response = await restClientGeneral.get(url);
      const payload = response.data.data;

      dispatch({
        type: actions.FETCH_AUTHORIZED_OPERATORS,
        payload
      });

      return payload;
    } catch (error) {
      throw error;
    }
  };

export const fetchSuppliers =
  (onlyNamesAndIds = true) =>
  async dispatch => {
    try {
      const select = onlyNamesAndIds ? "&select=ctiaid,id,name" : "";
      const where = `where = casid is null AND roleid=2`;
      const otherFlags = `&isSupplier=true&page=0`;
      const format = `&returnAs=query`; // Remove this if updating and adding include clause
      const url = `/admin/companies/vendors?${where}${select}${otherFlags}${format}`;
      const response = await restClientGeneral.get(url);
      const payload = response.data.data;

      dispatch({
        type: actions.FETCH_AUTHORIZED_SUPPLIERS,
        payload
      });

      return payload;
    } catch (error) {
      throw error;
    }
  };

export const addNewCompany =
  ({ params = {} }) =>
  async dispatch => {
    try {
      const url = `/admin/companies`;
      const response = await restClientGeneral.post(
        url,
        JSON.stringify(params)
      );
      const payload = response.data.data;

      dispatch({
        type: actions.POST_NEW_COMPANY,
        payload
      });

      return payload;
    } catch (error) {
      const { data } = error.response;

      if (data && data.errors && !_.isEmpty(data.errors)) {
        throw data.errors;
      } else {
        throw data.exception;
      }
    }
  };

export const updateCompanyOperator = (formId, id) => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareOperatorOrgArgs(form);
    const response = await restClientAdmin.put(
      `companies/${id}`,
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.UPDATE_OPERATOR_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyUpdatedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};
