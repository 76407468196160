import _ from "lodash";

// React/Redux imports
import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  fetchLabs,
  fetchManufacturers, fetchOperators,
  fetchPtcrbLabs, fetchSuppliers,
} from "actions/companies";

import { setPageSize } from "actions/app";
import {
  clearNewUserRequestStore,
  fetchNewUserRequestsList,
} from "actions/newUserRequest";

// Helper component imports
import UserRequestDispositions from "dictionaries/UserRequestDispositions";
import {
  CounterLabel,
  PagerStatusFilter,
} from "ctia-ui";
import CyberTable from "containers/CyberTable";

// NewUserRequestTable imports
import RenderOptions from "containers/users/UserRequest/UserRequestMenu/RenderOptions";
import NewUserRequestTable from "containers/users/UserRequest/UserRequestMenu/NewUserRequestTable/Table";

class NewUserRequestsMenu extends CyberTable {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserRoleFilter: UserRequestDispositions.pending
    };

    this.props.fetchLabs();
    this.props.fetchPtcrbLabs();
    this.props.fetchManufacturers();
    this.props.fetchOperators();
    this.props.fetchSuppliers();
  }

  fetchTableData = (
    selectedUserRole = this.state.selectedUserRoleFilter
  ) => {
    this.props.fetchNewUserRequestsList({
      userRoleStatusIds: this.userStatusValue(selectedUserRole)
    });
  };

  componentWillUnmount() {
    this.props.clearNewUserRequestStore();
  }

  onUserStatusFilterChange = (event) => {
    const status = Number(event.target.value);
    this.setState({ selectedUserRoleFilter: status });
    this.fetchTableData(status);
  };

  setPagerSize = (size) => this.props.setPageSize(size);

  userStatusValue = (userRole) => {
    const { pending, moreInfo } = UserRequestDispositions;
    switch(Number(userRole)) {
    case pending:
      return `(${pending}, ${moreInfo})`;
    default:
      return userRole;
    }
  }

  render() {
    const { newUserRequest } = this.props;
    const tableData = this.setTableData(newUserRequest);
    const counter = this.setCounter(tableData, _.size(tableData));
    const selectedUserRole = this.state.selectedUserRoleFilter;

    return (
      <div>
        <Row>
          <Col xs={6} md={8}>
            <h1>New User Requests</h1>
          </Col>
        </Row>
        <div id="request-filter block-bottom-offset">
          <Row>
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} title="Total Users" />
            </Col>
            <Col xs={4} md={4}/>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={this.state.selectedUserRoleFilter}
                statusOptions={<RenderOptions />}
                onStatusChange={this.onUserStatusFilterChange}
                title="Status"
              />
            </Col>
          </Row>
        </div>
        <NewUserRequestTable
          selectedUserRole={selectedUserRole}
          tableData={tableData}
        />
      </div>
    );
  };
};

function mapStateToProps({ app, newUserRequest }) {
  return { app, newUserRequest };
};

export default connect(mapStateToProps, {
  setPageSize,
  clearNewUserRequestStore,
  fetchNewUserRequestsList,
  fetchLabs,
  fetchPtcrbLabs,
  fetchManufacturers,
  fetchOperators,
  fetchSuppliers
})(NewUserRequestsMenu);
