import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import {
  getStatusTitle,
  getRequestSupplier,
  getRequestVoltage,
  isExisted
} from "helpers/RequestHelper";
import { getRequestTypeWithIeeeTitle } from "helpers/AuditHelper";

const InfoBarCRR = ({ request, sites, user }) => {
  // const _status = isExisted(request) ? getStatusTitle(request.status) : null;
  // const _vendor = getRequestSupplier(request, user);
  // const _modelName = request.modelName;
  // const _reqType = getRequestTypeWithIeeeTitle(request);

  const [
    { status, vendor, modelName, reqType, revision, voltage, capacity },
    setBarValues
  ] = useState({
    status: isExisted(request) ? getStatusTitle(request.status) : null,
    vendor: getRequestSupplier(request, user),
    modelName: request.modelName || request.existedModel,
    reqType: getRequestTypeWithIeeeTitle(request),
    revision: user.isStaff ? request.revision : null,
    voltage:
      user.isStaff && !request.isHRR
        ? getRequestVoltage(request.voltage)
        : null,
    capacity: user.isStaff ? getRequestVoltage(request.capacity) : null
  });

  // useEffect(() => {
  //   const status = isExisted(request) ? getStatusTitle(request.status) : null;
  //   const vendor = getRequestSupplier(request, user);
  //   const modelName = request.modelName;
  //   const reqType = getRequestTypeWithIeeeTitle(request);

  //   setBarValues({ status, vendor, modelName, reqType });
  // });

  useEffect(() => {
    const _status = isExisted(request) ? getStatusTitle(request.status) : null;
    const _vendor = getRequestSupplier(request, user);
    const _modelName = request.modelName || request.existedModel;
    const _reqType = getRequestTypeWithIeeeTitle(request);
    const _revision = user.isStaff ? request.revision : null;
    const _voltage =
      user.isStaff && !request.isHRR
        ? getRequestVoltage(request.voltage)
        : null;
    const _capacity = user.isStaff ? getRequestVoltage(request.capacity) : null;

    setBarValues({
      status: _status,
      vendor: _vendor,
      modelName: _modelName,
      reqType: _reqType,
      revision: _revision,
      voltage: _voltage,
      capacity: _capacity
    });
  }, [request, user]);

  const vendorLabel = request.isEPRR ? "Vendor/Supplier" : "Supplier";

  const getValues = () => {
    const reqDataInfo = [
      {
        key: "Status",
        value: status
      },
      {
        key: "Request Type",
        value: reqType
      },
      {
        key: vendorLabel,
        value: vendor
      },
      {
        key: "Model Name / Number",
        value: modelName
      },
      {
        key: "Revision Number",
        value: revision
      },
      {
        key: "Voltage (V)",
        value: voltage
      },
      {
        key: "Capacity (mAh)",
        value: capacity
      }
    ];

    // remove fields with no values:
    return _.filter(reqDataInfo, field => !_.isEmpty(field.value));
  };

  return <InfoBar show={true} data={getValues()} />;
};

function mapStateToProps({ request, sites, user }) {
  return { request, sites, user };
}

export default connect(mapStateToProps)(InfoBarCRR);
