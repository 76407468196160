// es6 and js extension framework imports
import moment from "moment";

import _ from "lodash";

// React/Redux imports
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import { clearCertifiedPendingReport } from "actions/reports/certifiedPendingReport";

// Helper imports
import { getCategoryByAbbreviation } from "helpers/CertCategories";
import { requestStatuses } from "helpers/RequestHelper";

// Component imports
import AuthorizationTypeSelector from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/AuthorizationTypeSelector";
import CertificationTypeSelector from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/CertificationTypeSelector";
import DateRangeSelector from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/DateRangeSelector";
import RequestStatusSelector from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/RequestStatusSelector";
import RequestTypeSelector from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/RequestTypeSelector";
import SubmitButton from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/Components/SubmitButton";

const CertifiedPendingReportForm = ({
  onDidSubmitForm,
  app,
  clearCertifiedPendingReport,
  getCertifiedPendingReport
}) => {
  const [formState, setFormState] = useState({
    isLoading: false
  });
  const [formValues, setFormValues] = useState({
    authorizationType: 0,
    certificationType: "ACR",
    dateFrom: moment().startOf("year"),
    dateTo: moment(),
    requestStatus: requestStatuses.approved,
    requestType: 0
  });
  const useAuthorizationType = !_.includes(
    ["ACR", "BLC", "CYBER", "DHR", "OTA"],
    formValues.certificationType.toUpperCase()
  );
  const useSelectedDates =
    formValues.requestStatus === requestStatuses.approved;

  const onAuthorizationTypeChange = value => {
    setFormValues({ ...formValues, authorizationType: value });
    clearCertifiedPendingReport();
  };

  const onCertificationTypeChange = event => {
    const { value } = event.target;
    setFormValues({ ...formValues, certificationType: value });
    clearCertifiedPendingReport();
  };

  const onDateFromChange = value => {
    setFormValues({ ...formValues, dateFrom: value });
    clearCertifiedPendingReport();
  };

  const onDateToChange = value => {
    setFormValues({ ...formValues, dateTo: value });
    clearCertifiedPendingReport();
  };

  const onRequestStatusChange = value => {
    setFormValues({ ...formValues, requestStatus: value });
    clearCertifiedPendingReport();
  };

  const onRequestTypeChange = value => {
    setFormValues({ ...formValues, requestType: value });
    clearCertifiedPendingReport();
  };

  const onSubmit = event => {
    setFormState({ ...formState });

    const selectedCert = getCategoryByAbbreviation(
      formValues.certificationType,
      app.certTypes
    );
    const selectedCertId = selectedCert ? selectedCert.id : 0;

    onDidSubmitForm({
      authorizationType: useAuthorizationType
        ? formValues.authorizationType
        : null,
      certificationType: formValues.certificationType,
      certificationTypeId: selectedCertId,
      dateFrom:
        formValues.requestStatus === requestStatuses.approved
          ? formValues.dateFrom
          : null,
      dateTo: useSelectedDates ? formValues.dateTo : null,
      requestStatusId: formValues.requestStatus,
      requestTypeId: formValues.requestType,
      showDate: formValues.requestStatus === requestStatuses.approved
    });
  };

  return (
    <Form horizontal>
      <RequestStatusSelector
        id="requestStatus"
        onChange={onRequestStatusChange}
        value={formValues.requestStatus}
      />
      <CertificationTypeSelector
        id="deviceType"
        onChange={onCertificationTypeChange}
        value={formValues.certificationType}
      />
      {useAuthorizationType && (
        <AuthorizationTypeSelector
          id="authorizationType"
          onChange={onAuthorizationTypeChange}
          value={formValues.authorizationType}
        />
      )}
      <RequestTypeSelector
        id="requestType"
        onChange={onRequestTypeChange}
        value={formValues.requestType}
      />
      {useSelectedDates && (
        <DateRangeSelector
          id="dateRange"
          dateFrom={formValues.dateFrom}
          dateTo={formValues.dateTo}
          onDateFromChange={onDateFromChange}
          onDateToChange={onDateToChange}
        />
      )}
      <SubmitButton onSubmit={onSubmit} />
    </Form>
  );
};

function mapPropsToState({ app }) {
  return { app };
}

export default connect(mapPropsToState, {
  clearCertifiedPendingReport
})(CertifiedPendingReportForm);
