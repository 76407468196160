import * as actions from "actions/types";
import _ from "lodash";

const emptyUserRequestObject = {
  formValues: {},
  userNameIsValid: null
};

const emptyStoreState = {
  list: [],
  selectedUserRequest: emptyUserRequestObject
}

const clearNewUserRequestStore = () => {
  return emptyStoreState;
};

const clearSelectedUserRequest = state => {
  return {
    ...state,
    selectedUserRequest: emptyUserRequestObject
  };
};

const setUserRequestFormValues = (state, action) => {
  var updatedState = {...state};

  updatedState.selectedUserRequest.formValues = {
    ...state.selectedUserRequest.formValues,
    ...action.payload.formValues
  };
  
  return updatedState;
};

const setUserRequestUserNameIsValid = (state, action) => {
  const userNameIsValid = !_.isUndefined(action.payload.userNameIsValid)
    ? action.payload.userNameIsValid
    : state.selectedUserRequest.userNameIsValid;

  return {
    ...state,
    selectedUserRequest: {
      ...state.selectedUserRequest,
      userNameIsValid
    }
  };
};

export default function (state = null, action) {
  const { payload } = action;
  const { data, meta } =
    !_.isUndefined(payload) && !_.isNull(payload)
      ? payload
      : { data: [], meta: {} };

  switch (action.type) {
  case actions.CLEAR_NEW_USER_REQUEST_STORE:
    return clearNewUserRequestStore(state);
  case actions.CLEAR_SELECTED_USER_REQUEST:
    return clearSelectedUserRequest(state);
  case actions.CREATE_LEGACY_USER:
    return { ...state, ...payload };
  case actions.FETCH_NEW_USERS_REQUESTS_LISTS:
    return {
      ...state,
      list: data,
      pages: meta
    };
  case actions.SET_USER_REQUEST_FORM_VALUES:
    return setUserRequestFormValues(state, action);
  case actions.SET_USER_REQUEST_USER_NAME_VALID:
    return setUserRequestUserNameIsValid(state, action);
  case actions.UPDATE_NEW_USER_REQUEST:
    return { ...state, ...payload };
  default:
    return state;
  }
}
