import _ from "lodash";
import * as actions from "actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case actions.GET_ACTIVE_LAB_USERS:
      const activeUsers = action.payload;
      return {
        ...state,
        activeUsers
      };
    case actions.FETCH_ACTIVE_LABS:
      const activeLabs = action.payload;
      return {
        ...state,
        activeLabs
      };
    case actions.SELECT_LAB_USER:
      if (state.activeUsers) {
        // find and set user data by passed id
        const selectedUserData = _.find(state.activeUsers, [
          "ctiaid",
          action.ctiaid
        ]);

        if (selectedUserData) {
          return { ...state, selectedUserData };
        }
      }

      return { ...state, selectedUserData: null };
    case actions.FETCH_USER_LABS:
      const userLabs = action.payload;
      return {
        ...state,
        userLabs
      };
    case actions.SET_TRANSFER_FORM_VISIBILITY:
      const transferFormVisible = action.visible;
      return { ...state, transferFormVisible };
    case actions.FETCH_COMPANY_USERS:
      const labUsers = action.payload.data || [];
      return {
        ...state,
        labUsers
      };
    case actions.UNMOUNT_USERS_TO_LABS:
      return {
        ...state,
        activeUsers: null,
        selectedUserData: null,
        transferFormVisible: false
      };
    default:
      return state;
  }
}
