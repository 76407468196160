import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { TooltipBase, LoaderButton, IconLabel } from "ctia-ui";
import ReqLink from "components/RequestsLink";
import { isRequestWithdrawn } from "helpers/RequestHelper";
import { formatDate } from "helpers/DateHelper";

const ListRowOta = ({
  tableData,
  isStaffUser,
  isVendorUser,
  openWithdrawConfirm,
  openUnwithdrawConfirm
}) => {
  if (!tableData) return null;

  const renderWithdrawLink = row => {
    if (!isStaffUser && !isVendorUser) return null;

    const withdrawned = isRequestWithdrawn(row.requeststatusid);

    if (withdrawned) return null;

    return (
      <LoaderButton
        bsStyle="danger"
        bsSize="xsmall"
        text={<IconLabel label="Withdraw" awesomeIcon="ban" />}
        onClick={() => openWithdrawConfirm(row)}
      />
    );
  };

  const renderWithdrawInfo = row => {
    const withdrawned = isRequestWithdrawn(row.requeststatusid);

    if (!withdrawned) return null;

    return (
      <div>
        <div>Request Withdrawn on {formatDate(row.withdrawnat)}</div>
        <div className="button-top-offset">
          <TooltipBase tipText={row.withdrawreason} tipPlacement="left">
            <LoaderButton
              bsStyle="info"
              bsSize="xsmall"
              text={
                <IconLabel label="Withdrawal Reason" awesomeIcon="question" />
              }
              onClick={() => null}
              className="icon-right-offset"
            />
          </TooltipBase>
          {isStaffUser && (
            <LoaderButton
              bsStyle="success"
              bsSize="xsmall"
              text={<IconLabel label="Unwithdraw" awesomeIcon="undo" />}
              onClick={() => openUnwithdrawConfirm(row)}
            />
          )}
        </div>
      </div>
    );
  };

  return _.map(tableData, row => {
    const tn = row.formatedtransactionnumber;

    return (
      <tr key={row.id}>
        <td>
          <ReqLink linkTitle={tn} requestId={row.id} />
        </td>
        {isStaffUser && <td>{row.name}</td>}
        <td>{row.modelnumber}</td>
        <td>
          {renderWithdrawLink(row)}
          {renderWithdrawInfo(row)}
        </td>
      </tr>
    );
  });
};

ListRowOta.propTypes = {
  tableData: PropTypes.array,
  isStaffUser: PropTypes.bool,
  isVendorUser: PropTypes.bool,
  openWithdrawConfirm: PropTypes.func,
  openUnwithdrawConfirm: PropTypes.func
};

export default ListRowOta;
