import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import FormElement from "components/FormElement";
import { getCategories, getCategoryTitle } from "helpers/CertCategories";
import { emptyMsg } from "helpers/FormHelper";
import { handleInputChange } from "actions/request";
import { fetchCertCategories } from "actions/requestCyber";

class CertCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [
        {
          id: 0,
          name: "--- Select one ---"
        }
      ]
    };
  }

  componentDidMount() {
    const { activeonly } = this.props;

    this.props.fetchCertCategories(activeonly).then(res => {
      const certList = res.data.data;
      this.setCategoriesList(certList);
    });
  }

  setCategoriesList(certList) {
    this.setState({
      categories: [...this.state.categories, ...certList]
    });
  }

  render() {
    const {
      request,
      readonly,
      editable,
      handleInputChange,
      validate
    } = this.props;
    const { certCategory, certCategoryName } = request;
    const categories = getCategories(this.state.categories);
    const categoryTitle = readonly ? certCategoryName : certCategory;

    return (
      <FormElement
        type={readonly ? "" : "select"}
        id="certCategory"
        label="Certification Level"
        value={categoryTitle}
        onChange={handleInputChange}
        options={categories}
        editable={editable}
        validationState={validate()}
        validationMessage={emptyMsg}
      />
    );
  }
}

CertCategories.propTypes = {
  activeonly: PropTypes.bool,
  readonly: PropTypes.bool,
  editable: PropTypes.bool
};

CertCategories.defaultProps = {
  activeonly: true,
  readonly: true,
  editable: false
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange,
  fetchCertCategories
})(CertCategories);
