import React, { Component } from "react";
import { connect } from "react-redux";
import { LoaderButton, TooltipIcon } from "ctia-ui";
import { formatDate } from "helpers/DateHelper";
import { getFullName } from "helpers/UsersHelper";
import { fetchRequestComments, postComment } from "actions/comments";
import { FormControl } from "react-bootstrap";

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = { text: "", isLoading: false };
  }

  componentDidMount() {
    if (this.props.user.isStaff) {
      this.fetchComments();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.request.id !== this.props.request.id) this.fetchComments();
  }

  fetchComments = () => {
    this.props.fetchRequestComments(this.props.request.id);
  };

  onTextChange = event => {
    this.setState({ text: event.target.value });
  };

  onSubmit = () => {
    this.setState({ isLoading: true });
    const { request, postComment } = this.props;
    postComment(request.id, this.state.text)
      .then(this.fetchComments)
      .then(() => {
        this.setState({ isLoading: false, text: "" });
      });
  };

  renderComments = () => {
    const { comments } = this.props;
    if (comments.data.length) {
      return comments.data.map((item, i) => {
        const postDate = formatDate(item.createdat);
        const submitter = getFullName(item.submitter);
        return (
          <div key={i}>
            <b>{submitter}</b> <i>({postDate})</i>:<p>{item.comment}</p>
          </div>
        );
      });
    }
    return null;
  };

  render() {
    const { user, comments } = this.props;

    if (!user.isStaff || !comments) return null;

    const count = comments.pager.TOTALRECORDS || 0;
    const { text, isLoading } = this.state;

    return (
      <div>
        <h4>
          Comments ({count}){" "}
          <TooltipIcon
            glyph="question-sign"
            tipText="You can leave comment about the request here"
            iconColor="#5c6670"
          />
        </h4>
        {this.renderComments()}
        <FormControl
          componentClass="textarea"
          value={text}
          onChange={this.onTextChange}
          disabled={isLoading}
        />
        <LoaderButton
          bsStyle="primary"
          bsSize="small"
          text="Post comment"
          onClick={this.onSubmit}
          isLoading={isLoading}
          loadingText="Posting..."
          className="button-top-offset"
          disabled={text.length < 3}
        />
      </div>
    );
  }
}

function mapStateToProps({ user, request, comments }) {
  return { user, request, comments };
}

export default connect(mapStateToProps, { fetchRequestComments, postComment })(
  Comments
);
