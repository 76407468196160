import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "ctia-react-bootstrap-v4";
import CatlLogo from "components/CatlLogo";
import PTCRBLogo from "components/PTCRBLogo";
import PTCRBaLogo from "components/PTCRBaLogo";
import IoTLogo from "components/IoTLogo";
import CatlInvoice from "components/ProgramSelector/CatlInvoice";
import CatlLogoPreview from "components/ProgramSelector/CatlLogoPreview";
import CatlAgreement from "components/ProgramSelector/CatlAgreement";

const CatlContainer = ({ user, downloadAgreementATL }) => {
  if (!user.isCATL) return null;

  return (
    <Row>
      <Col md={6}>
        <h3>Downloadable Files</h3>
        <ul className="ctia-list">
          <CatlLogoPreview />
          <CatlAgreement />
          <CatlInvoice />
        </ul>
      </Col>
      <Col md={6}>
        <h3>&nbsp;</h3>
        {_.includes(user.companyCertTypes, 1) &&
            <>
                <h4>&nbsp;</h4>
                <CatlLogo />
            </>
        }
        {_.includes(user.companyCertTypes, 41) &&
            <>
                <h4>&nbsp;</h4>
                <IoTLogo />
            </>
        }
        {_.includes(user.companyCertTypes, 2) &&
            <>
                <h4>&nbsp;</h4>
                <PTCRBLogo />
            </>
        }
        {_.includes(user.companyCertTypes, 27) &&
            <>
                <h4>&nbsp;</h4>
                <PTCRBaLogo />
            </>
        }
      </Col>
    </Row>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(CatlContainer);
