import React, { Component } from "react";
import { connect } from "react-redux";
import { Bubble } from "ctia-ui";
import LogoPng from "containers/Logo/LogoPng";
import { maintenanceTimeout } from "dictionaries/Env";
import { getSystemInfo } from "actions/system";

import "containers/Maintenance.css";

class Maintenance extends Component {
  componentDidMount() {
    this.checkStatus = setInterval(
      () => this.props.getSystemInfo(),
      maintenanceTimeout
    );
  }

  componentWillUnmount() {
    clearInterval(this.checkStatus);
  }

  render() {
    return (
      <div>
        <div id="maint-logo-wrapper">
          <div id="maint-logo-container">
            <LogoPng inverted={true} />
          </div>
        </div>
        <div id="maint-bubble-wrapper">
          <div id="maint-bubble-container">
            <Bubble>
              <b>CTIA Certification Database will be back soon.</b>
              <p>&nbsp;</p>
              <p>
                The website is temporarily down for scheduled updates and
                maintenance.
              </p>
              <p>
                We apologize for any inconvenience. Please check back later.
              </p>
            </Bubble>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { getSystemInfo })(Maintenance);
