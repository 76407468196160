export default {
  batterySystem: 1,
  embeddedPackRecognition: 2,
  batteryLifeCertification: 3,
  cellRecognitionRequest: 4,
  packRecognitionRequest: 5,
  adapterRecognitionRequest: 6,
  adapterCertificationRequest: 7,
  hybridRecognitionRequest: 8,
  siteAuditRequest: 9
};
