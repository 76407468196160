import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Badge } from "react-bootstrap";
import { getOtaLabs } from "actions/labs";
import { concatStr } from "helpers/StringHelper";
import { mapLabContactInfo } from "helpers/RequestTestsHelper";
import OneLineText from "components/form/OneLineText";

const style = { marginLeft: 20 };

class TestLabs extends Component {
  componentDidMount() {
    this.props.getOtaLabs();
  }

  render() {
    const { labs } = this.props;

    if (!labs) return null;

    const sortedLabs = _.sortBy(labs, ["name"]);

    return (
      <div>
        <h1>
          Authorized Test Labs <Badge>{_.size(sortedLabs)}</Badge>
        </h1>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Lab Name</th>
              <th>Country</th>
              <th>Test Plans</th>
            </tr>
          </thead>
          <tbody>
            {_.map(sortedLabs, (lab, i) => {
              const { name, countrydisplay, assignedOTAInterfaces } = lab;
              const labInfo = mapLabContactInfo(lab);

              return (
                <tr key={i}>
                  <td>
                    <OneLineText
                      value={concatStr(name, 30)}
                      style={style}
                      content={labInfo}
                      title={name}
                      titleId={`lab-${i}`}
                    />
                  </td>
                  <td>{countrydisplay}</td>
                  <td>{assignedOTAInterfaces}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps({ labs }) {
  return { labs };
}

export default connect(mapStateToProps, {
  getOtaLabs
})(TestLabs);
