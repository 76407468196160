import React from "react";
import { connect } from "react-redux";
import FilterOptions from "containers/request/TestRequestList/FilterOptions";
// import BatteryAllTable from "containers/request/RequestList/battery/BatteryAllTable";
import {
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel
} from "ctia-ui";
import { Col, Label, Table, Row } from "react-bootstrap";
import { clearRequests } from "actions/requests";
import { getBatteryRequestsForTesting } from "actions/battery/batteryRequests";
import { setPageSize, changeBatteryRequestTestStatus } from "actions/app";
import CyberTable from "containers/CyberTable";
import ListRow from "components/labRequest/LabListRowBattery";
import NoTableData from "components/NoTableData";

class BatteryTests extends CyberTable {
  componentWillUnmount() {
    this.props.clearRequests();
  }

  onStatusChange = event => {
    const newStatus = event.target.value;

    // update app filter settings
    this.props.changeBatteryRequestTestStatus(newStatus);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = (
    pageNum = this.startPage,
    statusFilter = this.props.app.batteryRequestTestStatus.filter,
    pageSize = this.props.app.pageSize,
    labid = this.props.user.companyid
  ) =>
    this.props.getBatteryRequestsForTesting(
      pageNum,
      pageSize,
      statusFilter,
      labid
    );

  setPagerSize = size => this.props.setPageSize(size);

  render() {
    const { requests, app } = this.props;
    const { batteryRequestTestStatus } = app;
    const tableData = this.setTableData(requests);
    const { page, pages, records } = this.setPagerData(requests);
    const counter = this.setCounter(tableData, records);
    const renderTableBody = () => {
      if (tableData.length > 0) {
        return <ListRow tableRows={tableData} />;
      }

      return <NoTableData colSpan={6} />;
    };
    const renderTable = () => {
      return (
        <div>
          <Table striped condensed hover className="ctia-table">
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Vendor/Supplier</th>
                <th>Model/Site Name</th>
                <th>Authorization Type</th>
                <th>Request Type</th>
                <th>Testing Status</th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
          <Divider />
          <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
        </div>
      );
    };

    return (
      <div>
        <h1>
          Certification Requests <Label>{batteryRequestTestStatus.title}</Label>
        </h1>
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={batteryRequestTestStatus.title}
                statusOptions={<FilterOptions />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        {/* <BatteryAllTable data={tableData} fetchHandler={this.fetchTableData} /> */}
        {renderTable()}
        {/* <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} /> */}
      </div>
    );
  }
}

function mapStateToProps({ requests, app, user }) {
  return { requests, app, user };
}

export default connect(mapStateToProps, {
  setPageSize,
  changeBatteryRequestTestStatus,
  clearRequests,
  getBatteryRequestsForTesting
})(BatteryTests);
