export default {
  CTIASTAFF: { id: 1, title: "CTIA Staff" },
  ADMIN: { id: 1, title: "CTIA Staff" },
  VENDOR: { id: 3, title: "Vendor" },
  LAB: { id: 4, title: "Laboratory" },
  CTIAACCOUNTING: { id: 8, title: "CTIA Accounting" },
  ACCOUNTING: { id: 8, title: "CTIA Accounting" },
  CCFACCOUNTING: { id: 9, title: "CCF Accounting" },
  OPERATOR: { id: 10, title: "Operator" },
  UNASSIGNED: { id: 12, title: "Unassigned" },
  SUPPLIER: { id: 14, title: "Supplier" },
  CCFSTAFF: { id: 17, title: "CCF Staff" },
  BLUETOOTHADMIN: { id: 18, title: "Bluetooth Admin" },
  SERVICECENTER: { id: 20, title: "Service Center" }
};
