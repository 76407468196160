import React from "react";
import { connect } from "react-redux";
import AddAddress from "containers/request/battery/ManufSite/SiteAddressAdd";
import AddressDetails from "containers/request/battery/ManufSite/SiteAddressDetails";
import ManufSiteName from "containers/request/battery/ManufSite/ManufSiteName";

const ManufSiteEdit = ({ user }) => {
  return user.canEditManufsite && user.canEditRequest ? (
    <AddAddress show={true} />
  ) : (
    <>
      <ManufSiteName />
      <AddressDetails />
    </>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(ManufSiteEdit);
