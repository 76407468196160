import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import BatteryTestResultsInfoBar from "containers/request/battery/TestResults/BatteryTestResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import PageTitle from "components/RequestPageTitle";
import LabResultFormCRR from "containers/testResult/LabResultFormCRR";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewView from "containers/testResult/UnderReviewViewCommon";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import { clearRequest } from "actions/request";
import {
  submitTestResultsCRR,
  getDetailsCRR,
  downloadReportCRR
} from "actions/battery/crrRequest";
import { validateVoltage, validateCapacity } from "helpers/ValidatorHelper";

const backURL = "/battery/lab/results";

class TestResultsItemCRR extends Component {
  state = { showContent: false };

  fetchAll(requestId) {
    const { getDetailsCRR } = this.props;

    getDetailsCRR(requestId).then(this.activateRender);
  }

  componentDidMount() {
    const { match } = this.props;
    this.resetRender();
    this.fetchAll(match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const { match } = this.props;
    const newId = nextProps.match.params.id;
    if (newId !== match.params.id) {
      this.fetchAll(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, revision, crslversion } = request;
    const { reports, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 &&
      revision !== "" &&
      crslversion !== "" &&
      validateVoltage(request) &&
      validateCapacity(request);

    if (!commonFieldsValidate) return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { request, tests, submitTestResultsCRR } = this.props;
    return submitTestResultsCRR(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportCRR } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBattery clientType="general" certType="crr" />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={backURL} label="Return to list" />
        <BatteryTestResultsInfoBar />
        <UnderReviewView show={completed} testModule={testModule} />
        <LabResultFormCRR show={!completed} testModule={testModule} />
        <LabResultUpload
          show={!completed}
          downloadHandler={downloadReportCRR}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  getDetailsCRR,
  submitTestResultsCRR,
  downloadReportCRR
})(TestResultsItemCRR);
