import React from "react";
import { connect } from "react-redux";
import PTCRBaLogoCanvas from "components/Canvas/PTCRBaLogoCanvas";

const PTCRBaLogo = ({ user, atlCode }) => {
  const facilityCode = user && user.isLab && user.CATLCode ? user.CATLCode : atlCode;

  if (facilityCode) {
    return <PTCRBaLogoCanvas labCode={facilityCode} />;
  }

  return null;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(PTCRBaLogo);