import React from "react";
import PropTypes from "prop-types";
import SvgArrow from "./CtiaButtonArrow";
import "./buttons.css";

const CtiaButton = ({
  label,
  color,
  disabled,
  onClick,
  arrowDirection,
  wrapperClass
}) => {
  const btnClass = color === "green" ? "" : `has-${color}-background-color`;

  const renderLabelAndArrow = () => {
    const renderedLabel = <span className="arrow-button__text">{label}</span>;
    const renderedArrow = <SvgArrow direction={arrowDirection} />;

    if (arrowDirection === "left") {
      return (
        <>
          {renderedArrow}
          {renderedLabel}
        </>
      );
    }

    return (
      <>
        {renderedLabel}
        {renderedArrow}
      </>
    );
  };

  return (
    <span className={`arrow-button ${wrapperClass}`}>
      <button
        className={`arrow-button__link ${btnClass}`}
        onClick={onClick}
        disabled={disabled}
      >
        {/* <span className="arrow-button__text">{label}</span>
        <SvgArrow direction={arrowDirection} /> */}
        {renderLabelAndArrow()}
      </button>
    </span>
  );
};

CtiaButton.propTypes = {
  label: PropTypes.any,
  color: PropTypes.oneOf(["green", "light-blue", "white"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  arrowDirection: PropTypes.oneOf(["left", "right"]),
  wrapperClass: PropTypes.string
};

CtiaButton.defaultProps = {
  label: "",
  color: "green",
  disabled: false,
  onClick: null,
  arrowDirection: "right",
  wrapperClass: ""
};

export default CtiaButton;
