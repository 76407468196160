export default [
  {
    icon: "coins",
    title: "Budget Report",
    route: "/accountant/reports/budget"
  },
  {
    icon: "hourglass-half",
    title: "Certified/Pending Report",
    route: "/accountant/reports/certifiedpending"
  },
  {
    icon: "file-invoice-dollar",
    title: "Invoices Report",
    route: "/accountant/reports/invoice"
  }
];
