import React, { Component } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import payMethods from "dictionaries/Payment";
import {
  isCustomPayMethod,
  initSelectorValue,
  initTextInputValue
} from "helpers/Payments";
import { makeEvent } from "libs/form";

class PaymentMethodSelector extends Component {
  state = {
    showTextInput: isCustomPayMethod(this.props.request),
    selectorValue: initSelectorValue(this.props.request),
    textInputValue: initTextInputValue(this.props.request)
  };

  componentDidMount() {
    // init request store with payMethod value by default
    if (!this.props.request.payMethod) {
      const syntEvent = makeEvent("payMethod", "");
      this.props.handleInputChange(syntEvent);
    }
  }

  onMethodSelect = event => {
    const { value } = event.target;
    const showTextInput = value === "Other";
    const selectorValue = value;
    const textInputValue = "";

    this.setState({ showTextInput, selectorValue, textInputValue });
    this.props.handleInputChange(event);

    // need to clear custom value inside redux-store
    const syntEvent = makeEvent("customPayMethod", "");
    this.props.handleInputChange(syntEvent);
  };

  onTextInputChange = event => {
    this.setState({ textInputValue: event.target.value });
    this.props.handleInputChange(event);
  };

  render() {
    const { selectorValue, textInputValue } = this.state;
    return (
      <div>
        <FormElement
          type="select"
          id="payMethod"
          label="Payment Method"
          value={selectorValue}
          onChange={this.onMethodSelect}
          options={payMethods}
        />
        <FormElement
          show={this.state.showTextInput}
          type="text"
          id="customPayMethod"
          label="Custom Payment Method"
          value={textInputValue}
          onChange={this.onTextInputChange}
        />
      </div>
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange
})(PaymentMethodSelector);
