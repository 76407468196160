import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryPacks from "containers/request/battery/BatterySupplier/BatteryPacks";
import Adapters from "containers/request/battery/AdapterSupplier/Adapters";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestDescChanges from "components/request/RequestDescChanges";

const DeviceInfoLabBSC = ({ request, preloader, user }) => {
  if (preloader) return null;

  return (
    <div>
      <Instructions />
      <Form horizontal>
        {request.isEco && <RequestParentLink certPrefix="bsc" />}
        <RequestManufacturer />
        <RequestModelName />
        <hr />
        <RequestHWVersion />
        <RequestSWVersion />
        {request.isEco && <RequestDescChanges />}
        <SubmissionDetails />
        <hr />
        <BatteryPacks />
        <hr />
        <Adapters />
      </Form>
    </div>
  );
};

function mapStateToProps({ request, sites, preloader, user }) {
  return { request, sites, preloader, user };
}

export default connect(mapStateToProps)(DeviceInfoLabBSC);
