import React from "react";
import { connect } from "react-redux";
import VendorContent from "containers/request/RequestContent/VendorContent";
import reqTypes from "dictionaries/ReqTypes";
import { batteryTabsEPR, batteryTabsEcoEPR } from "helpers/RequestTabsHelper";
import {
  getChecklistInitialEPR,
  getChecklistEcoEPR
} from "helpers/ChecklistBatteryHelper";

const RequestContentEPR = ({ request, focusLab }) => {
  const checkListInitial = getChecklistInitialEPR(request);
  const checkListECO = getChecklistEcoEPR(request);

  switch (request.type) {
    case reqTypes.initial:
      return (
        <VendorContent
          tabs={batteryTabsEPR}
          checkList={checkListInitial}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    case reqTypes.eco:
      return (
        <VendorContent
          tabs={batteryTabsEcoEPR}
          checkList={checkListECO}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    default:
      return null;
  }
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestContentEPR);
