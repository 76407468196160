import _ from "lodash";
import { testStatusIds } from "helpers/RequestTestsHelper";

/**
 * Returns checklist for initial request type.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getInitialChecklist = request => {
  if (!request) return null;
  return [
    {
      title: "Product Description Approved",
      finished: request.hasUsersManualApproved
    },
    {
      title: "License Agreement Accepted",
      finished: !request.isSaved
    },
    {
      title: "Test Results Approved",
      finished: request.hasTestReportsApproved
    },
    {
      title: "CTIA Invoice Paid",
      finished: request.isPaid
    }
  ];
};

/**
 * Returns checklist for ECO request type.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getEcoChecklist = request => {
  if (!request) return null;
  return [
    {
      title: "Test Results Approved",
      finished: request.hasTestReportsApproved
    },
    {
      title: "Parent Device Certified",
      finished: request.hasParentApproved
    },
    {
      title: "CTIA Invoice Paid",
      finished: request.isPaid
    }
  ];
};

/**
 * Define correct method to get request's checklist.
 *
 * @param {object} request Request object
 * @returns array|null
 */
export const getChecklist = request => {
  if (request.isInitial) return getInitialChecklist(request);
  if (request.isEco) return getEcoChecklist(request);
  return null;
};

export const otaTestsApproved = tests => {
  return _.every(
    tests,
    testModule => testModule.requestteststatusid === testStatusIds.complete
  );
};

/**
 * Returns checklist for OTAs.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getOtaChecklist = (request, tests) => {
  if (!request) return null;

  return [
    {
      title: "Test Results Uploaded",
      finished: otaTestsApproved(tests)
    },
    {
      title: "CTIA Invoice Paid",
      finished: request.isPaid
    }
  ];
};

export const getChecklistDHR = request => {
  if (request.isInitial) return getInitialChecklistDHR(request);
  if (request.isEco) return getEcoChecklistDHR(request);
  return null;
};

/**
 * Returns checklist for initial request type.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getInitialChecklistDHR = request => {
  if (!request) return null;
  return [
    {
      title: "License Agreement Accepted",
      finished: !request.isSaved
    },
    {
      title: "Test Results Approved",
      finished: request.hasTestReportsApproved
    },
    {
      title: "CTIA Invoice Paid",
      finished: request.isPaid
    }
  ];
};

/**
 * Returns checklist for ECO request type.
 *
 * @param {object} request Request object
 * @returns array Checklist
 */
export const getEcoChecklistDHR = request => {
  if (!request) return null;
  return [
    {
      title: "Test Results Approved",
      finished: request.hasTestReportsApproved
    },
    {
      title: "Parent Device Certified",
      finished: request.hasParentApproved
    },
    {
      title: "CTIA Invoice Paid",
      finished: request.isPaid
    }
  ];
};
