import React from "react";
import PropTypes from "prop-types";
import { DownloadButton } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import "containers/request/LicenseAgreement/style.css";

const BatteryAgreementModal = ({
  show,
  modalTitle,
  modalHandler,
  downloadHandler,
  modalContent,
  isBLC = false,
  version
}) => {
  const renderDownloadButton = () => (
    <span className="icon-left-offset">
      <DownloadButton size="small" action={downloadHandler} />
    </span>
  );

  const AgreementContent = modalContent;
  const subTitle = `Battery ${isBLC ? "Life" : ""} Certification Program`;

  return (
    <Modal show={show} onHide={modalHandler} bsSize="large">
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            {modalTitle} {renderDownloadButton()}
          </div>
          <div>
            <small>{subTitle}</small>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AgreementContent
          version={version}/>
      </Modal.Body>
      <Modal.Footer>
        {renderDownloadButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

BatteryAgreementModal.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func,
  downloadHandler: PropTypes.func,
  modalContent: PropTypes.func,
  isBLC: PropTypes.bool
};

export default BatteryAgreementModal;
