import _ from "lodash";

export const getRecognitionOnly = requestList => {
  if (requestList && requestList.length > 0) {
    const filtered = _.filter(requestList, "hasPendingRecognition");

    if (_.isEmpty(filtered)) return [];

    return filtered.map(res => {
      return {
        companyid: res.linkedrequestvendorid
      };
    });
  }

  return [];
};

export const getNonRecognitionOnly = requestList => {
  if (requestList && requestList.length > 0) {
    const filtered = _.filter(requestList, req => !req.hasPendingRecognition);

    if (_.isEmpty(filtered)) return [];

    return filtered.map(res => {
      return {
        linkedrequestid: res.linkedrequestid
      };
    });
  }

  return [];
};
