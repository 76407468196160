import { restClient, pickRestClient } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as args from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";
import * as form from "libs/form";
import { updateRequest } from "actions/request";
import { fetchRequest } from "actions/requestCyber";
import { updateDeviceModel } from "actions/model";
import { fileDownload } from "actions/uploads";
import {
  getRequestReportFields,
  getModelReportFields,
  getOtaPlanFilePrefix
} from "helpers/RequestTestsHelper";

export const fetchRequestTests = requestId => async dispatch => {
  try {
    const url = `/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchOtaTests = (requestId, labId) => async dispatch => {
  try {
    const url = `/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId} AND labid=${labId}`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const sendTestResult = testModule => async dispatch => {
  try {
    const body = args.prepareTestResultArgs(testModule);
    const payload = await restClient.put(
      `/requesttests/${testModule.id}`,
      JSON.stringify(body)
    );

    dispatch({
      type: actions.SEND_TEST_RESULT,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const sendOtaTestResult = testModule => async dispatch => {
  try {
    const body = args.prepareOtaTestResultArgs(testModule);
    const payload = await restClient.put(
      `/requesttests/${testModule.id}`,
      JSON.stringify(body)
    );

    dispatch({
      type: actions.SEND_TEST_RESULT,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const sendDhrTestResult = testModule => async dispatch => {
  try {
    const body = args.prepareDhrTestResultArgs(testModule);
    const payload = await restClient.put(
      `/requesttests/${testModule.id}`,
      JSON.stringify(body)
    );

    dispatch({
      type: actions.SEND_TEST_RESULT,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

// Battery SAR audit results
export const sendAuditResults = testModule => async dispatch => {
  try {
    const body = args.prepareAuditResultArgs(testModule);
    const payload = await restClient.put(
      `/requesttests/${testModule.id}`,
      JSON.stringify(body)
    );

    dispatch({
      type: actions.SEND_TEST_RESULT,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const changeReportStatus = (
  testId,
  reportSlot,
  reportStatus,
  certType = null
) => async dispatch => {
  try {
    const prefix = certType ? `${certType}` : '';
    const url = `${prefix}/requesttests/${testId}/reports/${reportSlot}`;
    const body = args.prepareDocStatusArgs(reportStatus);
    const apiClient = pickRestClient(certType ? "general" : "");

    return await apiClient.put(url, body);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function testReportDownload(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: CYBER<000000>_<TestModule>_<Slot>.<extension>
  // e.g. CYBER000345_CYBERSECURITY_3.pdf
  const fileName = `${request.tn}_CYBERSECURITY_${slot}.${document.document.extension}`;
  const url = `/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url);
}

export function testReportsDownloadAll(request) {
  // file name: CYBER<000000>_<TestModule>.zip
  // e.g. CYBER000345_CYBERSECURITY.zip
  const fileName = `${request.tn}_CYBERSECURITY.zip`;
  const url = `/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url);
}

export function changeTestField(testId, event) {
  const { id, name, value } = event.target;
  const field = id || name;

  return {
    type: actions.CHANGE_TEST_FIELD,
    payload: {
      field,
      testId,
      value: form.formatValue(field, value)
    }
  };
}

export function changeTestDate(testId, fieldName, value) {
  return {
    type: actions.CHANGE_TEST_FIELD,
    payload: {
      field: fieldName,
      testId,
      value
    }
  };
}

export function changeTestMultiField(testId, event) {
  const { id, options } = event.target;
  const value = form.getHtmlCollectionSelectedValues(options);

  return {
    type: actions.CHANGE_TEST_FIELD,
    payload: {
      field: id,
      testId,
      value
    }
  };
}

export const fetchTestReports = testModule => async dispatch => {
  try {
    const url = `/requesttests/${testModule.id}/reports`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsOta = testModule => async dispatch => {
  try {
    const url = `/requesttests/${testModule.id}/reports`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS_OTA,
      payload,
      testModule
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function clearTests() {
  return { type: actions.CLEAR_TESTS };
}

export const updateTestReportStatus = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(changeReportStatus(testId, reportSlot, reportStatus))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    // we also need to check test statuses in case changing status of report affected test status
    // .then(() => dispatch(fetchRequestTests(requestId)))
    // as well as reports
    // .then(res =>
    //   dispatch(call.makeMultiAsyncAction(res.data.data, fetchTestReportsOta))
    // )
    // check updated request status in case request goes to under review or ready to approve
    .then(() => dispatch(fetchRequest(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function deleteTestReport(testId, slot) {
  const url = `/requesttests/${testId}/reports/${slot}`;
  const payload = restClient
    .delete(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.DELETE_TEST_REPORT,
    payload
  };
}

export function loadTestResults(reqId) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch({ type: actions.CLEAR_REQUEST });
    dispatch(fetchRequest(reqId))
      .then(() => dispatch(fetchRequestTests(reqId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReports({ id: testId }));
      })
      .then(res => {
        dispatch(call.stopPreloader());
        return res;
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });

    return Promise.resolve("loadTestResults - DONE");
  };
}

export function fetchOtaTestResults(reqId, labId) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch({ type: actions.CLEAR_REQUEST });
    dispatch(fetchRequest(reqId))
      .then(() => dispatch(fetchOtaTests(reqId, labId)))
      .then(res =>
        dispatch(call.makeMultiAsyncAction(res.data.data, fetchTestReportsOta))
      )
      .then(res => {
        dispatch(call.stopPreloader());
        return res;
      })
      .catch(err => {
        console.error(err);
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });

    return Promise.resolve("fetchOtaTestResults - DONE");
  };
}

export function submitTestResults(request, testModule) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResult(testModule))
      .then(() => {
        const requestFields = getRequestReportFields(request);

        return dispatch(updateRequest(requestFields));
      })
      .then(() => {
        // this step should be executed for Initials only
        if (request.isEco)
          return Promise.resolve("Skip updating model step for Initials");

        const deviceModel = getModelReportFields(request);

        return dispatch(updateDeviceModel(deviceModel));
      })
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });

    return Promise.resolve("submitTestResults - DONE");
  };
}

export function onTestReportTitleChange(index, testModule, event) {
  const { id, name, value } = event.target;
  const field = id || name;

  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field,
      value: form.updateDocumentTitle(index, value, testModule.reports)
    }
  };
}

export function onTestReportSelect(index, testModule, event) {
  const { id, name, files } = event.target;
  const field = id || name;
  const fileContent = files[0];

  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field,
      value: form.updateDocumentFile(index, fileContent, testModule.reports)
    }
  };
}

export function submitOtaTestResults(request, tests) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(call.makeMultiAsyncAction(tests, sendOtaTestResult))
      .then(() => {
        const requestFields = getRequestReportFields(request);

        return dispatch(updateRequest(requestFields));
      })
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });

    return Promise.resolve("submitTestResults - DONE");
  };
}

export function downloadOtaReport(document, request, testModule) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this
  const prefix = getOtaPlanFilePrefix(testModule);

  // file name pattern: OTA<000000>_<TestModule>_<Slot>.<extension>
  // e.g. OTA000345_MIMO_3.pdf
  const fileName = `${request.tn}_${prefix}_${slot}.${document.document.extension}`;
  const url = `/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url);
}

// get all reports as ZIP-archive by selected test interface
export function downloadAllOtaReports(request, testModule) {
  // file name: OTA<000000>_<TestModule>.zip
  // e.g. OTA000345_MIMO_OTA_Performance.zip
  const testInterface = getOtaPlanFilePrefix(testModule);
  const fileName = `${request.tn}_${testInterface}.zip`;
  const url = `/requesttests/${testModule.id}/reports?download=true`;

  return fileDownload(fileName, url);
}

export const changeOtaReportStatus = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(changeReportStatus(testId, reportSlot, reportStatus))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid
        }
      });

      return res;
    })
    .then(() => dispatch(fetchRequest(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function submitTestResultsDHR(request, testModule) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendDhrTestResult(testModule))
      .then(() => {
        const requestFields = getRequestReportFields(request);

        return dispatch(updateRequest(requestFields));
      })
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });

    return Promise.resolve("submitTestResults - DONE");
  };
}
