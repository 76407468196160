import React from "react";
import FormElement from "components/FormElement";

const AuthorizationTypeSelector = ({ onChange = null, value = null }) => {
  const buttonValues = [
    {
      label: "IEEE 1625",
      id: 1625
    },
    {
      label: "IEEE 1725",
      id: 1725
    },
    {
      label: "All",
      id: 0
    }
  ];

  return (
    <FormElement
      id="authorizationType"
      label="AuthorizationType"
      list={buttonValues}
      onChange={onChange}
      type="toggleRadioGroup"
      value={value}
    />
  );
};

export default AuthorizationTypeSelector;
