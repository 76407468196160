import _ from "lodash";
import * as actions from "actions/types";
import { getCurrATLYear } from "helpers/DateHelper";

const updateASCPayment = (action, state, positive = true) => {
  const data = action.payload;
  const centerIdx = _.findIndex(state.centers, { id: data.companyid });
  _.set(
    state,
    `centers[${centerIdx}].ASCAnualLicenseFeePaid${data.targetyear}`,
    data.paid
  );
  _.set(
    state,
    `centers[${centerIdx}].ASCAnualLicenseFeePaidAt${data.targetyear}`,
    data.paidat
  );

  // also need to increment/decrement counter in meta to avoid extra API call
  if (positive) {
    state.meta.TOTALINVOICESPAID++;
  } else {
    state.meta.TOTALINVOICESPAID--;
  }

  return { ...state };
};

const mapCosts = (action, state) => {
  const { data } = action.payload.data;

  data.forEach(ivc => {
    const center = _.find(state.centers, ["id", ivc.companyid]);

    if (center) {
      center.invoiceCosts = [...ivc.ascinvoicecosts];
    } else {
      console.error("Can't find center with passed id: ", ivc.companyid);
    }
  });
  return { ...state };
};

const updateAscInvoice = (action, state) => {
  const { id, costs } = action.payload;
  const ivc = _.find(state.centers, [
    `ASCAnualLicenseFeeKey${state.invoicingYear}`,
    id
  ]);

  ivc.invoiceCosts = ivc.invoiceCosts.map(ivcCost => {
    const costToCopy = _.find(costs, ["costid", ivcCost.costid]);
    return { ...ivcCost, ...costToCopy };
  });

  return { ...state };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.SET_INVOICING_YEAR:
      return { ...state, invoicingYear: action.payload };
    case actions.FETCH_ASC_LIST:
      const { data, meta } = action.payload.data;
      const year =
        state && state.invoicingYear ? state.invoicingYear : getCurrATLYear();
      const readOnly = false;
      // const bulkExtended = extendWithBulk(data, year);

      return { ...state, centers: data, meta, readOnly };
    case actions.CONFIRM_ASC_PAYMENT:
      return updateASCPayment(action, state);
    case actions.FETCH_ASC_INVOICES_COSTS:
      return mapCosts(action, state);
    case actions.UPDATE_INVOICE_ASC:
      return updateAscInvoice(action, state);
    case actions.TOGGLE_ASC_BACKGROUND_JOB_STATUS:
      if (_.has(state, "isBackgroundJobRunning")) {
        return {
          ...state,
          isBackgroundJobRunning: !state.isBackgroundJobRunning
        };
      }

      return { ...state, isBackgroundJobRunning: true };
    case actions.REMOVE_ASC_PAYMENT:
      return updateASCPayment(action, state, false);
    case actions.POST_ASC_COMMENT:
      // get ASC by ID
      const ascCompany = _.find(state.centers, ["id", action.companyId]);

      if (ascCompany) {
        // increment counter value
        ascCompany[`ASCCommentsCount${action.year}`]++;
      }

      return state;
    default:
      return state;
  }
}
