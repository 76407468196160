import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import NoTableData from "components/NoTableData";
import { formatDate } from "helpers/DateHelper";

const DeviceRowBLC = ({ tableData, hideVendors }) => {
  if (tableData.length) {
    return tableData.map((row, i) => {
      const { id, modelnumber, name, devicetype, completedat } = row;
      return (
        <tr key={i}>
          {hideVendors ? null : <td>{name}</td>}
          <td>
            <Link to={`/blc/devices/${id}`} className="glow-link">
              {modelnumber}
            </Link>
          </td>
          <td>{devicetype}</td>
          <td>{formatDate(completedat)}</td>
        </tr>
      );
    });
  }

  return <NoTableData colSpan={hideVendors ? 2 : 3} />;
};

DeviceRowBLC.propTypes = {
  tableData: PropTypes.array
};

export default DeviceRowBLC;
