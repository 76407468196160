import React from "react";
import Badge from "components/Badges/RecognitionBadge";

const PendingRecognitionBadge = ({ row }) => {
  if (!row) return null;
  if (row.hasPendingRecognition) {
    return <Badge />;
  }
  return row.linkedrequestvendorproductname;
};

export default PendingRecognitionBadge;
