import React from "react";

const DisableOrgLink = ({ show, toggleModal }) => {
  // only active orgs will be managed by this component
  if (!show) return null;

  return (
    <span className="cursor-pointer glow-link" onClick={toggleModal}>
      Disable
    </span>
  );
};

export default DisableOrgLink;
