import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button, Col, Row } from "react-bootstrap";
import EditUserForm from "containers/ControlPanel/Users/EditUser/EditUserForm";
import UserLockedLabel from "containers/ControlPanel/Users/EditUser/UserLockedLabel";
import ErrorForm from "components/form/ErrorForm";
import { clearCountryState, fetchCountryStates } from "actions/countries";
import { editLegacyUser, getUser, lockUser, unlockUser } from "actions/user";
import { clearUserOrgList } from "actions/org";
import { validateUserMainFormFields } from "helpers/UsersHelper";
import { scrollTo } from "helpers/AppHelper";
import { setFormFieldValuesFromUser } from "containers/UserProfile/UserProfileHelper";

const modalHead = "modal-head";

const EditUserModal = ({
  userData,
  show,
  modalHandler,
  clearCountryState,
  editLegacyUser,
  getUser,
  lockUser,
  unlockUser,
  fetchCountryStates,
  clearUserOrgList
}) => {
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    if (show) {
      getUser(userData.ctiaid).then(res => {
        if (res && res.data)
          setData(res.data.data);
        else
          throw new Error("Server responded with an error");
      });
    }

    return () => {
      clearCountryState();
      clearUserOrgList();
      setData(null);
      setFormId(null);
    };
  }, [userData, show, getUser, clearCountryState, clearUserOrgList, setData, setFormId]);

  useEffect(() => {
    if(data) {
        setFormId("edit-user-form");
        validateForm();
    };
  }, [data, setData]);

  const onUserEdit = useCallback(() => {
    setLoading(true);
    editLegacyUser(userData)
      .then(() => {
        setLoading(false);
        modalHandler();
        setValidated(false)
        clearCountryState();
      })
      .catch(err => {
        setLoading(false);
        setErrorMessage(err);
        scrollTo(formId);
      });
  }, [
    setLoading,
    editLegacyUser,
    clearCountryState,
    setErrorMessage,
    setValidated,
    modalHandler,
    userData
  ]);

  const renderSubmitButton = () => {
    return (
      <span className="icon-left-offset">
        <LoaderButton
          bsStyle="success"
          bsSize="small"
          text={<IconLabel label="Save" awesomeIcon="check" />}
          onClick={() => { data && validated ? onUserEdit() : modalHandler() }}
          disabled={!validated || !data}
          isLoading={loading}
        />
      </span>
    );
  };

  const onUnlock = useCallback(
    user => {
      setLoading(true);
      unlockUser(userData.ctiaid)
        .then(() => {
          setLoading(false);
          scrollTo(modalHead);
          setData({ ...user, locked: 0 });
          modalHandler();
        })
        .catch(err => {
          setErrorMessage(err);
          setLoading(false);
          scrollTo(modalHead);
        });
    },
    [userData.ctiaid, setLoading, setErrorMessage, unlockUser, modalHandler]
  );

  const onLock = useCallback(
    user => {
      setLoading(true);
      lockUser(userData.ctiaid)
        .then(() => {
          setLoading(false);
          scrollTo(modalHead);
          setData({ ...user, locked: 1 });
          modalHandler();
        })
        .catch(err => {
          setErrorMessage(err);
          setLoading(false);
          scrollTo(modalHead);
        });
    },
    [userData.ctiaid, setLoading, setErrorMessage, lockUser, modalHandler]
  );

  const renderLockUnlock = () => {
    if (!data) return null;
    if (data.locked === 1) {
      return (
        <Button onClick={() => onUnlock(data)} disabled={loading}>
          <IconLabel label="Unlock Account" awesomeIcon="unlock" />
        </Button>
      );
    }

    return (
      <Button bsStyle="danger" onClick={() => onLock(data)} disabled={loading}>
        <IconLabel label="Lock Account" awesomeIcon="lock" />
      </Button>
    );
  };

  const validateForm = () => {
    setErrorMessage(null);

    const form = document.getElementById(formId);

    if (form && ( !form.checkValidity() || !validateUserMainFormFields(form) )) {
      return setValidated(false);
    };

    setValidated(true);
  };

  return (
    <Modal show={show} className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler} id={modalHead}>
        <Modal.Title>Edit User</Modal.Title>
        <UserLockedLabel userData={data} />
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          id={formId}
          className="ctia-form"
          onChange={validateForm}
        >
          <ErrorForm errors={errorMessage} />
          <EditUserForm
            data={data}
            role={userData.role}
            modernId={userData.id}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Row>
          <Col xs={6} md={6} style={{ textAlign: "left" }}>
            {renderLockUnlock()}
          </Col>
          <Col md={6} xs={6}>
            {renderSubmitButton()}
            <Button onClick={modalHandler} className="agr-close-btn">
              Cancel
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default connect(null, {
  clearCountryState,
  editLegacyUser,
  getUser,
  lockUser,
  unlockUser,
  fetchCountryStates,
  clearUserOrgList
})(EditUserModal);
