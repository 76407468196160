import _ from "lodash";
import * as actions from "actions/types";
import * as supHelper from "helpers/BatterySupplierHelper";

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_BATTERY_SUPPLIERS:
      const { payload } = action;
      return payload ? { ...state, list: payload } : state;
    case actions.FETCH_ADAPTER_MODELS:
      const models = _.isNull(action.payload) ? null : action.payload;
      return { ...state, models };
    case actions.FETCH_VERSIONS_MODEL:
      const devicemodel = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, model: devicemodel };
    case actions.FETCH_RECOGNIZED_VERSIONS:
      const requestList = action.payload || [];
      const recognizedVersions = supHelper.formatRecognizedVersionsList(
        requestList
      );
      return { ...state, recognizedVersions };
    case actions.FETCH_CELL_SUPPLIERS:
      const supplierList = action.payload || [];
      const cells = supHelper.formatSupplierList(supplierList);
      return { ...state, cells };
    case actions.FETCH_CELL_MODELS:
      const cellModels = _.isNull(action.payload)
        ? null
        : supHelper.formatCellModelsList(action.payload);
      return { ...state, cellModels };
    case actions.FETCH_APPROVED_ADAPTER_SUPPLIERS:
      const approvededAdapterSuppliers = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, approvededAdapterSuppliers };
    case actions.FETCH_CERTIFIED_ADAPTERS:
      const certifiedAdapters = _.isNull(action.payload)
        ? null
        : supHelper.formatCellModelsList(action.payload);
      return { ...state, certifiedAdapters };
    case actions.FETCH_CERTIFIED_ADAPTER_REQUESTS:
      const certifiedAdapterRequests = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, certifiedAdapterRequests };
    case actions.FETCH_APPROVED_RECOGNITION_SUPPLIERS:
      const approvededRecognitionSuppliers = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, approvededRecognitionSuppliers };
    case actions.FETCH_RECOGNIZED_ADAPTERS:
      const recognizedAdapters = _.isNull(action.payload)
        ? null
        : supHelper.formatCellModelsList(action.payload);
      return { ...state, recognizedAdapters };
    case actions.FETCH_RECOGNIZED_ADAPTER_REQUESTS:
      const recognizedAdapterRequests = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, recognizedAdapterRequests };
    case actions.FETCH_APPROVED_PACK_SUPPLIERS:
      const approvedPackSuppliers = _.isNull(action.payload)
        ? null
        : supHelper.formatSupplierList(action.payload);
      return { ...state, approvedPackSuppliers };
    case actions.FETCH_CERTIFIED_PACKS:
      const certifiedPacks = _.isNull(action.payload)
        ? null
        : supHelper.formatCellModelsList(action.payload);
      return { ...state, certifiedPacks };
    case actions.FETCH_CERTIFIED_PACK_REQUESTS:
      const certifiedPackRequests = _.isNull(action.payload)
        ? null
        : action.payload;
      return { ...state, certifiedPackRequests };
    case actions.FETCH_CERTIFIED_BSC_PACK_REQUESTS:
      const certifiedBSCPackRequests = _.isNull(action.payload)
        ? null
        : supHelper.mapBSCBatteryList(action.payload);
      return { ...state, certifiedBSCPackRequests };
    default:
      return state;
  }
}
