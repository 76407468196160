import _ from 'lodash';
import { sortByFieldname } from 'helpers/SortHelper';

/**
 * Formats passed array for html selector rendering.
 *
 * @param {array} countries Countries list from server
 * @returns array Formatted countries list (ready for rendering)
 */
export const getCountriesList = countries => {
  if (!countries) return [];

  const list = _.map(countries, country => {
    return {
      title: `${country.name} (${country.code})`,
      value: country.code
    };
  });

  list.unshift({
    title: '--- Select one ---',
    value: 0
  });

  return list;
};

/**
 * Formats passed array for html selector rendering.
 *
 * @param {array} states States list for selected country from server
 * @returns array Formatted states list (ready for rendering)
 */
export const getCountryStatesList = states => {
  if (!states) return [];

  const namefield = _.head(states).english ? 'english' : 'name';
  const stateList = sortByFieldname(states, namefield);

  const list = _.map(stateList, state => {
    const name = state[namefield];
    return {
      title: name,
      value: name
    };
  });

  list.unshift({
    title: '--- Select one ---',
    value: 0
  });

  return list;
};

export const selectedCountryShouldRequireStateOrProvince = selectedCountry => {
  if (!_.isString(selectedCountry)) {
    return false;
  }

  const c = selectedCountry.toLowerCase();
  return c === "ca" || c === "us";
};