import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "containers/Login/index";
import ProgramMenu from "components/ProgramSelector/ProgramMenu";
import HomeASC from "components/ProgramSelector/HomeASC";
import { resetCertProgramStatus } from "actions/app";

class Home extends Component {
  componentWillMount() {
    this.props.resetCertProgramStatus();
  }

  render() {
    const { user } = this.props;

    if (!user.token || user.accessError) return <Login />;

    return user.isServiceCenter ? <HomeASC /> : <ProgramMenu />;
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { resetCertProgramStatus })(Home);
