// Role Titles
export const VENDOR = "Vendor";
export const SUPPLIER = "Supplier";
export const LAB = "Laboratory";
export const OPERATOR = "Operator";
export const ASC = "Service Center";
export const STAFF = "CTIA Staff";
export const ACCOUNTING = "CTIA Accounting";
export const UNASSIGNED = "Unassigned";

// Role Keys in DB
export const DB_VENDOR = "VENDOR";
export const DB_SUPPLIER = "SUPPLIER";
export const DB_LAB = "LAB";
export const DB_OPERATOR = "OPERATOR";
export const DB_ASC = "SERVICECENTER";
export const DB_STAFF = "ADMIN";
export const DB_ACCOUNTING = "ACCOUNTING";
export const DB_UNASSIGNED = "UNASSIGNED";

// User Role Selector
export const userTypeOptions = [
  [ACCOUNTING, DB_ACCOUNTING],
  [STAFF, DB_STAFF],
  [LAB, DB_LAB],
  [OPERATOR, DB_OPERATOR],
  [ASC, DB_ASC],
  [SUPPLIER, DB_SUPPLIER],
  [VENDOR, DB_VENDOR],
  [UNASSIGNED, DB_UNASSIGNED]
];
