import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { LoaderButton, TooltipIcon } from "ctia-ui";
import { formatDate } from "helpers/DateHelper";
import { getFullName } from "helpers/UsersHelper";
import { getTransPrefix } from "helpers/RequestHelper";
import { fetchRequestComments, postComment } from "actions/comments";
import { FormControl } from "react-bootstrap";

const Comments = ({
  comments,
  request,
  user,
  fetchRequestComments,
  postComment,
}) => {
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const certType = getTransPrefix(request.tn);

  const fetchComments = useCallback(() => {
    fetchRequestComments(request.id, certType);
  }, [request.id, certType, fetchRequestComments]);

  useEffect(() => {
    if (user.isStaff) {
      fetchComments();
    }
  }, [fetchComments, user.isStaff]);

  const onTextChange = (event) => {
    setText(event.target.value);
  };

  const onSubmit = () => {
    setLoading(true);
    postComment(request.id, text, certType)
      .then(fetchComments)
      .then(() => {
        setLoading(false);
        setText("");
      });
  };

  const renderComments = () => {
    if (comments.data.length) {
      return comments.data.map((item, i) => {
        const postDate = formatDate(item.createdat);
        const submitter = getFullName(item.submitter);
        return (
          <div key={i}>
            <b>{submitter}</b> <i>({postDate})</i>:<p>{item.comment}</p>
          </div>
        );
      });
    }
    return null;
  };

  if (!user.isStaff || !comments) return null;

  const count = comments.pager.TOTALRECORDS || 0;

  return (
    <div>
      <h4>
        Comments ({count}){" "}
        <TooltipIcon
          glyph="question-sign"
          tipText="You can leave comment about the request here"
          iconColor="#5c6670"
        />
      </h4>
      {renderComments()}
      <FormControl
        componentClass="textarea"
        value={text}
        onChange={onTextChange}
        disabled={loading}
      />
      <LoaderButton
        bsStyle="primary"
        bsSize="small"
        text="Post comment"
        onClick={onSubmit}
        isLoading={loading}
        loadingText="Posting..."
        className="button-top-offset"
        disabled={text.length < 3}
      />
    </div>
  );
};

function mapStateToProps({ user, request, comments }) {
  return { user, request, comments };
}

export default connect(mapStateToProps, { fetchRequestComments, postComment })(
  Comments
);
