import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { Bubble, LoaderButton } from "ctia-ui";
import LogoPng from "containers/Logo/LogoPng";
import LoginLinks from "containers/Login/LoginLinks";
import { signin, getUserInfo } from "actions/user";
import { getCertTypes } from "actions/app";
import { renderField as FormField } from "helpers/FormHelper";

import "containers/Login/style.css";

const validate = values => {
  const errors = {};

  if (!values) return errors;
  if (!values.username) errors.username = "Required";
  if (!values.password) errors.password = "Required";

  return errors;
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = { isLoading: false };
  }

  onSubmit = formProps => {
    this.setState({ isLoading: true });
    this.props.signin(
      formProps,
      () => {
        this.props.getUserInfo();
        this.props.getCertTypes();
      },
      () => this.setState({ isLoading: false })
    );
  };

  render() {
    const { handleSubmit, errorMessage = "" } = this.props;
    return (
      <div className="Login">
        <div className="text-center">
          <LogoPng />
        </div>
        <h2 className="text-center">
          Welcome to the CTIA Certification Database
        </h2>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <Bubble type="danger" show={!_.isEmpty(errorMessage)}>
            {errorMessage}
          </Bubble>
          <Field
            name="username"
            type="text"
            label="Username"
            component={FormField}
            autoComplete="none"
            autoFocus={true}
          />
          <Field
            name="password"
            type="password"
            label="Password"
            component={FormField}
            autoComplete="none"
          />
          <LoaderButton
            block
            bsSize="large"
            bsStyle="info"
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
            className="btn-shadow"
          />
        </form>
        <LoginLinks />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { errorMessage: state.user.errorMessage };
}

export default compose(
  connect(mapStateToProps, { signin, getUserInfo, getCertTypes }),
  reduxForm({ form: "signin", validate })
)(Login);
