// React / Redux
import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { connect } from "react-redux";

// Redux actions
import { clearRequests } from "actions/requests";
import { withdrawRequest, unwithdrawRequest } from "actions/requestCyber";

// Components
import ModalConfirm from "components/ModalConfirm";
import BatteryManufSiteRows from "containers/Devices/Battery/BatteryDevices/BatteryManufSiteTable/BatteryManufSiteRows";

class BatteryManufsiteTable extends Component {
  state = {
    showWithdrawConfirm: false,
    showUnwithdrawConfirm: false,
    requestData: null,
    isLoading: false,
    isFormValid: true
  };

  render() {
    const {
      requestReloadTable,
      tableData,
      user
    } = this.props;
    if (!tableData) return null;

    const showWithdrawColumn = user.isStaff;

    return (
      <div>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Supplier</th>
              <th>Site Name</th>
              <th>Site Address</th>
              <th>Authorization Type</th>
              {showWithdrawColumn && <th />}
            </tr>
          </thead>
          <tbody>
            <BatteryManufSiteRows
              requestReloadTable={requestReloadTable}
              showWithdrawColumn={showWithdrawColumn}
              tableData={tableData}
            />
          </tbody>
        </Table>
        <ModalConfirm
          type={this.state.withdrawalType}
          show={this.state.showWithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onWithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
          isFormValid={this.state.isFormValid}
          onValidate={this.onValidate}
        />
        <ModalConfirm
          type={this.state.unwithdrawalType}
          show={this.state.showUnwithdrawConfirm}
          onHide={this.onCloseConfirm}
          onHandle={this.onUnwithdraw}
          requestData={this.state.requestData}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

function mapPropsToState({ user }) {
  return { user };
}

export default connect(mapPropsToState, {
  clearRequests,
  withdrawRequest,
  unwithdrawRequest
})(BatteryManufsiteTable);
