import React from "react";
import { connect } from "react-redux";
import { Navbar } from "ctia-react-bootstrap-v4";
import { updateUserLocation } from "actions/user";
import ProgramMenu from "components/BrandNav/ProgramMenu";
import LogoPng from "containers/Logo/LogoPng";
import UserInfo from "components/BrandNav/UserInfo";
import "components/BrandNav/style.css";

const BrandNav = props => {
  const { name, role } = props.user;
  const isAuth = name !== null && role > 0;
  const expand = !props.app.sm;

  if (!isAuth) return null;

  return (
    <Navbar
      collapseOnSelect
      expand={expand}
      bg="dark"
      variant="dark"
      bsPrefix="certnav"
      className="justify-content-between"
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        bsPrefix="certnav-toggler"
      />
      <Navbar.Collapse id="responsive-navbar-nav" bsPrefix="certnav-collapse">
        <LogoPng
          cssClass="navbar__logo--inverted button-right-offset"
          inverted={true}
        />
        <ProgramMenu history={props.history} />
        <UserInfo />
      </Navbar.Collapse>
    </Navbar>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, { updateUserLocation })(BrandNav);
