import React from "react";
import { connect } from "react-redux";
import Toggler from "components/form/Toggler";
import { toggleUserStatus } from "actions/app";

const UserStatusToggler = ({ toggleUserStatus, app, users }) => {
  return (
    <Toggler
      show={true}
      labelText="Show Active only"
      onChange={toggleUserStatus}
      checked={app.showOnlyActiveUsers}
    />
  );
};

function mapStateToProps({ app, users }) {
  return { app, users };
}

export default connect(mapStateToProps, {
  toggleUserStatus
})(UserStatusToggler);
