import React, { useState } from "react";
import { IconLabel } from "ctia-ui";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Copy = ({ text, children }) => {
  const [copied, setCopied] = useState(false);

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const renderChild = () => {
    return children || <IconLabel awesomeIcon="copy" />;
  };

  return (
    <CopyToClipboard text={text} onCopy={() => onCopy()}>
      <span className="cursor-pointer">
        {renderChild()} {copied ? <strong>Copied!</strong> : ""}
      </span>
    </CopyToClipboard>
  );
};

export default Copy;
