import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel, LoaderButton, TooltipBase } from "ctia-ui";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";
import { generateASC, fetchAscList, fetchAscInvoices } from "actions/asc";

const GenerateInvoicesASC = ({
  asc,
  generateASC,
  fetchAscList,
  fetchAscInvoices,
  showNotice
}) => {
  const [loading, setLoading] = useState(false);

  if (asc.readOnly) return null;

  const tipMsg =
    "Background job is running. Button will be enabled in a moment";

  const renderBtn = () => (
    <LoaderButton
      bsStyle="primary"
      bsSize="large"
      text={<IconLabel awesomeIcon="save" label="Generate Invoices" />}
      onClick={() => {
        setLoading(true);
        generateASC(asc.invoicingYear)
          .then(() => fetchAscList(asc.invoicingYear))
          .then(() => {
            setLoading(false);
            showNotice(notifyMessages.ascBulkingSuccessful);
            fetchAscInvoices(asc.invoicingYear);
          })
          .catch(() => setLoading(false));
      }}
      isLoading={loading}
      loadingText="Working..."
      className="button-top-offset"
      disabled={asc.isBackgroundJobRunning}
    />
  );

  if (asc.isBackgroundJobRunning) {
    return (
      <TooltipBase tipText={tipMsg} tipPlacement="left">
        <div>{renderBtn()}</div>
      </TooltipBase>
    );
  }

  return renderBtn();
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, {
  generateASC,
  fetchAscList,
  fetchAscInvoices,
  showNotice
})(GenerateInvoicesASC);
