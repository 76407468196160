import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchCountries } from "../../../actions/countries";
import _ from "lodash";

const CountrySelector = ({ fieldValue, changeHandler, countries, fetchCountries }) => {
  const [ componentParams, setParams ] = useState({
    countryList: []
  });

  useEffect(() => {
    fetchCountries()
  }, [fetchCountries] )

  useEffect(() => {
    if (countries !== null && countries.list !== null && _.isEmpty(componentParams.countryList)) {
      setParams({ countryList: countries.list })
    }
  }, [countries, componentParams.countryList])

  const renderOptions = () => {
    if(!_.isEmpty(componentParams.countryList)) {
      const formOptions = componentParams.countryList.map((currentOption, currentIndex) => {
        const optionLabel = currentOption.name;
        const optionValue = currentOption.code;

        return (
          <option value={optionValue} key={currentIndex}>
            { optionLabel }
          </option>
        );
      })

      return formOptions
    }

    return null;
  }

  return (
    <FormControl componentClass="select" value={fieldValue} onChange={changeHandler}>
      <option value="">--- Select One ---</option>
      { renderOptions() }
    </FormControl>
  );
}

CountrySelector.propTypes = {
  field: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps, { fetchCountries })(CountrySelector)