import { connect } from "react-redux";
import React from "react";
import FormElement from "components/FormElement";
import { formatDate } from "helpers/DateHelper";

const RequestSubmissionDate = ({ request }) => {
  if (request && request.isSaved) {
    return (
      <FormElement
        id="reqSaveDate"
        label="Saved Date"
        value={formatDate(request.saveDate)}
      />
    );
  }

  const submissionDate =
    request && request.submissionDate
      ? formatDate(request.submissionDate)
      : "N/A";

  return (
    <FormElement
      id="reqSubmissionDate"
      label="Submission Date"
      value={submissionDate}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestSubmissionDate);
