import React from "react";
import { LoaderButton } from "ctia-ui";

const FormButton = ({ show = true, ...rest }) => {
  const className = rest.className || "";
  return (
    <LoaderButton show={show} {...rest} className={`btn-shadow ${className}`} />
  );
};

export default FormButton;
