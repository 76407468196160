import * as actions from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case actions.ADD_TO_QUEUE:
    case actions.REMOVE_FROM_QUEUE:
      return { ...state, ...action.payload };
    case actions.CLEAR_QUEUE:
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
