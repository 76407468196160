import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { formatDate } from "helpers/DateHelper";
import NoTableData from "components/NoTableData";

const DeviceRowCyber = ({ tableData, hideVendors }) => {
  if (tableData.length) {
    return tableData.map((row, i) => {
      const { id, modelnumber, name, completedat, certcategoryid} = row;
      var certcategoryname = "";
      switch(certcategoryid) {
        case 1:
          certcategoryname = "Level 1";
          break;
        case 2:
          certcategoryname = "Level 2";
          break;
        case 3:
          certcategoryname = "Level 3";
          break;
        case 4:
          certcategoryname = "Level 1 (Consumer and Enterprise Devices)";
          break;
        case 5:
          certcategoryname = "Level 2 (Enterprise Devices)";
          break;
        default:
          certcategoryname = "";
      }
      return (
        <tr key={i}>
          {hideVendors ? null : <td>{name}</td>}
          <td>
            <Link to={`/cyber/devices/${id}`} className="glow-link">
              {modelnumber}
            </Link>
          </td>
          <td>{certcategoryname}</td>
          <td>{formatDate(completedat)}</td>
        </tr>
      );
    });
  }

  return <NoTableData colSpan={hideVendors ? 3 : 4} />;
};

DeviceRowCyber.propTypes = {
  tableData: PropTypes.array
};

export default DeviceRowCyber;
