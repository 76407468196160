import * as actions from "actions/types";
import { restClientAdmin } from "libs/restClient";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { prepareVendorOrgArgs } from "helpers/ArgsOrgAdminHelper";

export const addCompanyVendor = formId => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareVendorOrgArgs(form);
    const response = await restClientAdmin.post(
      "companies",
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.ADD_VENDOR_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyAddedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};

export const updateCompanyVendor = (formId, id) => async dispatch => {
  try {
    const form = document.getElementById(formId);
    const reqBody = prepareVendorOrgArgs(form);
    const response = await restClientAdmin.put(
      `companies/${id}`,
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.UPDATE_VENDOR_COMPANY,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.companyUpdatedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};
