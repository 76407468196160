import FormElement from "components/FormElement";
import React from "react";
import reqTypes from "dictionaries/ReqTypes";

const RequestTypeSelector = ({ onChange, value }) => {
  const buttonValues = [
    {
      label: "Initial",
      id: reqTypes.initial
    },
    {
      label: "ECO",
      id: reqTypes.eco
    },
    {
      label: "All",
      id: 0
    }
  ];

  return (
    <FormElement
      id="requestTypeSelector"
      label="Request Type"
      list={buttonValues}
      onChange={onChange}
      type="toggleRadioGroup"
      value={value}
    />
  );
};

export default RequestTypeSelector;
