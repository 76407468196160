import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { requestStatuses } from "helpers/RequestHelper";
import { fetchBSCPackRequests } from "actions/suppliers";
import * as bb from "helpers/RequestBackbone";
import { blcTabs } from "helpers/WizardHelper";
import * as call from "helpers/ActionHelper";
import * as perm from "helpers/PermissionHelper";
import * as args from "helpers/ArgsHelperBLC";
import * as th from "helpers/RequestTestsHelper";
import * as actions from "actions/types";
import * as wizard from "actions/wizard";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as ls from "libs/localStorage";
import reqTypes from "dictionaries/ReqTypes";

// import * as breq from "actions/battery/batteryRequests";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";

export function initNewInitialBLC(vendor = "", requestBackbone) {
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      model: { vendor: { name: vendor } },
      type: reqTypes.initial,
      canBeSaved: true,
      vendor,
      isInitial: true
    }
  };
}

export function initNewEcoBLC(vendor = "", requestBackbone) {
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      type: reqTypes.eco,
      vendor,
      isEco: true
    }
  };
}

export function setRequestPermissions(request, user) {
  const payload = {
    canEditDeviceInfo: perm.checkDeviceInfoPermission(request, user),
    canEditRequest: perm.checkDeviceInfoPermission(request, user),
    canEditPoc: perm.checkPocPermission(request, user),
    canEditBilling: perm.checkBillingPermission(request, user),
    canEditTests: perm.checkTestsPermission(request, user),
    canEditPaymentInfo: perm.checkPaymentPermission(request, user),
    canEditModelname: (!request.id || user.isStaff) ? true : false,
    canEditLabFields: perm.checkLabFieldsPermission(request, user),
    canEditDeviceType: (request.isBLC)
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function runInitialWizardBLC(user) {
  return dispatch => {
    const requestBackbone = bb.getBLCRequestBackbone();

    dispatch(initNewInitialBLC(user.company, requestBackbone));
    dispatch(
      wizard.initWizard({
        steps: blcTabs.initial,
        totalSteps: _.size(blcTabs.initial)
      })
    );
    dispatch(setRequestPermissions({ ...requestBackbone }, user));
  };
}

export function runEcoWizardBLC(user) {
  return dispatch => {
    const requestBackbone = bb.getBLCRequestBackbone();

    dispatch(initNewEcoBLC(user.company, requestBackbone));
    dispatch(
      wizard.initWizard({
        steps: blcTabs.eco,
        totalSteps: _.size(blcTabs.eco)
      })
    );
    dispatch(setRequestPermissions({ ...requestBackbone }, user));
  };
}

export const fetchInitialModelsBLC = () => async dispatch => {
  try {
    const url = `/bsc/requests?where=requeststatusid <> ${requestStatuses.requestWithdrawn} AND requesttypeid=${reqTypes.initial} AND ieeetypeid=1725 AND blcverified=0&include=devicemodel&select=vendorproductname,devicemodelid,devicetype,agreementVersion&page=0&orderBY=vendorproductname ASC`;
    
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_BLC_INITIAL_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const getBSCPackRequests = modelId => async dispatch => {
  const idBSC = ls.getValue("idBSC");
  const idPRR = ls.getValue("idPRR");
  const idEPRR = ls.getValue("idEPRR");

  return dispatch(
    fetchBSCPackRequests(
      modelId,
      idBSC,
      idPRR,
      idEPRR,
      requestStatuses.requestWithdrawn
    )
  );
};

export const initBLC = (modelId = null) => async dispatch => {
  dispatch(call.startPreloader());
  if (modelId) dispatch(getBSCPackRequests(modelId));
  dispatch(getOperatingSystems());

  return dispatch(fetchInitialModelsBLC())
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const fetchEcoModelsBLC = () => async dispatch => {
  try {
    const url = `/blc/requests?where=requeststatusid NOT IN (${requestStatuses.requestWithdrawn},${requestStatuses.saved})&select=vendorproductname, devicemodelid&page=0&orderBy=vendorproductname ASC`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_BLC_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoBLC = (modelId = null) => async dispatch => {
  dispatch(call.startPreloader());
  if (modelId) dispatch(getBSCPackRequests(modelId));
  dispatch(getOperatingSystems());

  return dispatch(fetchEcoModelsBLC())
    .then(() => dispatch(getOperatingSystems()))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const createRequestBLC = request => async dispatch => {
  try {
    const reqBody = args.prepareInitialArgsBLC(request);
    const response = await restClientGeneral.post(
      "blc/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid, devicetype, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;
    const type = _.isArray(requests) ? requests[0].devicetype : devicetype;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        type
      }
    });

    if (request.licenseAgreementToggler) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }
    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialBLC(request) {
    return dispatch => {
        const quit = () => dispatch(call.stopPreloader());

        dispatch(call.startPreloader());

        return dispatch(createRequestBLC(request))
            .then(res => {
                const requestId = res.data.data.id;
                
                if (request.licenseAgreementToggler) {
                    return dispatch(sign.signLicenseAgreementBLC(requestId, "blc"));
                }
            
                return res;
            })
            .then(() => 
                dispatch(
                    updateDeviceModel({ 
                        id: request.devicemodelid, 
                        modelnumber: request.modelName, 
                        devicetype: request.deviceType 
                    })
                )
            )
            .then(quit)
            .catch(quit);
    };
}

export const createEcoRequestBLC = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsBLC(request);
    const response = await restClientGeneral.post(
      "blc/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        newId,
        deviceModelId
      }
    })

    if(request.deviceType !== 0) {
      dispatch(updateDeviceModel({
        id: request.devicemodelid,
        modelnumber: request.modelName,
        devicetype: request.deviceType
      }));
    }

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoBLC(request) {
  return call.dispatchWithPreloader(createEcoRequestBLC, request);
}

export const getRequestDetailsBLC = id => async dispatch => {
  try {
    const url = `/blc/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_BLC, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const getOperatingSystems = () => async dispatch => {
  try {
    const res = await restClientGeneral.get("operatingsystems");
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_OPERATING_SYSTEMS, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};
// export const getParentRequestDetailsCRR = id => async dispatch => {
//   try {
//     const url = `/crr/requests/${id}/details`;
//     const response = await restClientGeneral.get(url);
//     const payload = response.data.data;

//     dispatch({ type: actions.FETCH_PARENT_REQUEST_CRR, payload });

//     return response;
//   } catch (e) {
//     dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

//     return Promise.reject(e);
//   }
// };

export const fetchRequestTestsBLC = requestId => async dispatch => {
  try {
    const url = `/blc/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsBLC = testModule => async dispatch => {
  try {
    const url = `/blc/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS_BLC,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsBLC(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsBLC(requestId))
      .then(res => {
        return dispatch(getModelDetailsBLC(res.data.data.devicemodelid));
      })
      .then(() => dispatch(fetchRequestTestsBLC(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsBLC({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function submitTestResultsBLC(request, testModule, certTypes) {
  const testResArgs = args.prepareTestResultsArgs(
    request,
    testModule,
    certTypes
  );

  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsBLC(testResArgs, request.id))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch(updateDeviceModel({
          id: request.model.id,
          modelnumber: request.modelName,
          devicetype: request.deviceType
        }))
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsBLC = (args, reqId) => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/blc/requests/${reqId}`,
      JSON.stringify(args)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateRequestByArgs = reqArgs => async dispatch => {
  try {
    const url = `/blc/requests/${reqArgs.args.requestId}`;

    return await restClientGeneral.put(url, JSON.stringify(reqArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export const updateModelBLC = modelArgs => async dispatch => {
  try {
    const url = `/blc/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialBLC(requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signLicenseAgreementBLC(requestId, "blc"))
      .then(() => dispatch(getRequestDetailsBLC(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export const getModelDetailsBLC = modelId => async dispatch => {
  try {
    const url = `/blc/requests?where=devicemodelid=${modelId} AND requeststatusid <> ${requestStatuses.requestWithdrawn} AND requesttypeid=${reqTypes.initial}&orderBY=requestid DESC&perPage=1&include=linkedrequests`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data[0];

    dispatch({
      type: actions.FETCH_BLC_MODEL,
      payload
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelBLC(modelId) {
  return call.dispatchWithPreloader(getModelDetailsBLC, modelId);
}

export const updateDeviceModel = deviceModel => async dispatch => {
    const body = {
      args: { ...deviceModel }
    };

    try {
      return await restClientGeneral.put(`blc/devicemodels/${deviceModel.id}`,
        JSON.stringify(body)
      );
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.modelnameAlreadyExists));

      return Promise.reject(e);
    }
  };

export function updateDeviceInfoBLC(request) {
  const reqArgs = args.prepareRequestArgs(request);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());

    dispatch(req.updateRequest(reqArgs))
      .then(() => {
        dispatch(updateDeviceModel({
            id: request.model.id,
            modelnumber: request.modelName,
            devicetype: request.deviceType
        }))
      })
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function updateECODeviceInfoBLC(request) {
  const reqArgs = args.prepareRequestArgs(request);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(req.updateRequest(reqArgs))
      .then(() => {
        dispatch(updateDeviceModel({
            id: request.model.id,
            modelnumber: request.modelName,
            devicetype: request.deviceType
        }))
      })
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function makeLabDecisionBLC(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `blc/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export function resetRequestPermissionsForLabBLC() {
  const payload = {
    canEditRequest: false,
    canEditDeviceInfo: false
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function setLabTestPermissionsBLC() {
  const payload = {
    canEditRequest: true,
    canEditDeviceInfo: true
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export const updateTestReportStatusBLC = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "blc"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsBLC(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportBLC(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: BLC<000000>_REPORT_<Slot>.<extension>
  // e.g. BLC000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/blc/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsBLC(request) {
  // file name: BLC<000000>_REPORTS.zip
  // e.g. BLC000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/blc/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
