/*
  Notes:
    * Removed pagination until a bug in wheels can be fixed that is causing
      errors when sorting by a calc prop. In this case, that is vendor name
      and invoice amount.
*/

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
// import FormElement from "components/FormElement";
import FormElementSelector from "components/FormElementSelector";
import { Row, Col, Table, InputGroup } from "react-bootstrap";
import {
  Divider,
  SpinContainer,
  DownloadButton,
  CounterLabel,
  IconLabel
  // Pager,
  // PagerPageSize,
} from "ctia-ui";
import { getFirstDayOfYear } from "helpers/DateHelper";
import { fetchDetails, downloadReport } from "actions/reports/invoiceReport";
import { formatCurrency } from "helpers/StringHelper";
import { formatDate } from "helpers/DateHelper";
import { toggleOrder } from "helpers/SortHelper";

export const datePickerOptions = {
  labelColSizeSm: 2,
  labelColSizeMd: 2,
  inputColSizeSm: 3,
  inputColSizeMd: 3,
  inputComponentClass: InputGroup
};

const InvoiceReportContainer = ({ fetchDetails, downloadReport }) => {
  const [payload, setPayload] = useState({
    meta: null,
    data: null,
    loading: true
  });

  const [dateRange, setDateRange] = useState({
    from: getFirstDayOfYear(),
    to: new Date()
  });

  const [sort, setSort] = useState({
    field: "id",
    order: "ASC"
  });

  const [pagination] = useState({
    page: 0, // 0 gets everything, no pagination
    perPage: 50
  });

  useEffect(() => {
    const { loading } = payload;

    if (loading) {
      const { field, order } = sort;
      const { from, to } = dateRange;
      const { page, perPage } = pagination;
      let endDate = moment(to);
      endDate = endDate.add(1,'d').startOf('day');
      fetchDetails(from, endDate, `${field} ${order}`, perPage, page).then(res => {
        setPayload({ meta: res.meta, data: res.data, loading: false });

        // const { CURRENTPAGE, TOTALPAGES, PERPAGE } = payload.meta;

        // setPagination({ page: CURRENTPAGE, pages: TOTALPAGES, perPage: PERPAGE});
      });
    }
  });

  // ==========================================================================

  const reload = () =>
    setPayload({ meta: null && {}, data: null, loading: true });

  const handleSort = field => {
    if (payload.loading) return;

    setSort({
      field: field,
      order: sort.field === field ? toggleOrder(sort.order) : sort.order
    });
    reload();
  };

  const handleDates = ({ from = dateRange.from, to = dateRange.to }) => {
    setDateRange({ to: to, from: from });
    reload();
  };

  // const handlePageSize = (size) => {
  //   const pageSize = Number(size);

  //   // use pagination
  //   if (pageSize > 0) {
  //     setPagination({ page: 1, perPage: pageSize });
  //   } else {
  //     setPagination({ page: 1, perPage: 100000 }); // 'All' option is activated
  //   }

  //   reload();
  // };

  // const handlePageMove = (page) => {
  //   const pageNum = Number(page);
  //   const { perPage } = pagination;
  //   const { TOTALPAGES } = payload.meta;

  //   if (pageNum > TOTALPAGES) {
  //     setPagination({ page: TOTALPAGES, perPage: perPage });
  //   } else {
  //     setPagination({ page: pageNum, perPage: perPage });
  //   }

  //   reload();
  // };

  const renderSortButton = (title, fieldname) => (
    <button className="button-no-border" onClick={() => handleSort(fieldname)}>
      {sort.field === fieldname ? (
        <IconLabel
          awesomeIcon={
            sort.order.toUpperCase() === "ASC" ? "caret-up" : "caret-down"
          }
          label={title}
          iconFirst={false}
        />
      ) : (
        title
      )}
    </button>
  );

  // ==========================================================================

  const { data, meta } = payload;
  const { TOTALRECORDS, TOTALINVOICED, TOTALPAID } = meta || [0];

  return (
    <div>
      <Row>
        <Col xs={12} md={3}>
          <h1>Invoice Report</h1>
        </Col>
        <Col xs={6} md={3}>
          <CounterLabel counter={TOTALRECORDS || 0} />
          <CounterLabel
            counter={formatCurrency(TOTALINVOICED)}
            title="Total invoiced"
          />
          <CounterLabel
            counter={formatCurrency(TOTALPAID)}
            title="Total paid"
          />
        </Col>
        <Col xs={6} md={4}>
          <FormElementSelector
            type="date"
            id="dateFrom"
            editable={true}
            // label="Date From"
            value={moment(dateRange.from)}
            onChange={e => handleDates({ from: e })}
            {...datePickerOptions}
          />
          <FormElementSelector
            type="date"
            id="dateTo"
            editable={true}
            // label="Date To"
            value={moment(dateRange.to)}
            onChange={e => handleDates({ to: e })}
            {...datePickerOptions}
          />
        </Col>
      </Row>

      <Row>
        <div id="request-filter">
          <Col xs={8} md={4}>
            {/* <PagerPageSize
              size={PERPAGE}
              onChangeHandler={handlePageSize}
              options={[50, 100]}
              showAll={true}
            /> */}
          </Col>
        </div>
      </Row>

      <SpinContainer spinning={payload.loading}>
        <Row>
          <Col xs={10} md={10}></Col>
          <Col xs={2} md={2}>
            {data && (
              <div className="text-right">
                <DownloadButton
                  size="large"
                  action={() =>
                    downloadReport(
                      dateRange.from,
                      dateRange.to,
                      `${sort.field} ${sort.order}`
                    )
                  }
                />
              </div>
            )}
          </Col>
        </Row>

        {/* <Divider /> */}

        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>
                {renderSortButton("Request / Invoice ID", "transactionnumber")}
              </th>
              <th>
                {/* Manufacturer */}
                {/* Sorting by vendor is breaking with pagination */}
                {renderSortButton("Vendor", "vendorname")}
              </th>
              <th>
                {renderSortButton("Model Name / Number", "vendorproductname")}
              </th>
              <th>{renderSortButton("Date Invoiced", "createdat")}</th>
              <th>{renderSortButton("Date Certified", "completedat")}</th>
              <th>Request Type</th>
              <th>{renderSortButton("Date Paid", "paidat")}</th>
              <th>{renderSortButton("Payment Method", "paymentmethod")}</th>
              <th>
                {/* Invoice Amount */}
                {/* Sorting by totalamount is breaking with pagination */}
                {renderSortButton("Invoice Amount", "totalamount")}
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, i) => {
                return (
                  <tr key={`ivc-reports-row-${i}`}>
                    <td>{item.formatedtransactionnumber}</td>
                    <td>{item.vendorname} </td>
                    <td>{item.vendorproductname}</td>
                    <td>{item.createdat && formatDate(item.createdat)}</td>
                    <td>{item.completedat && formatDate(item.completedat)}</td>
                    <td>{item.name === "Non-IEV" ? "" : item.name}</td>
                    <td>{item.paidat && formatDate(item.paidat)}</td>
                    <td>{item.paymentmethod}</td>
                    <td>{formatCurrency(item.totalamount)}</td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
        <Divider />
        {/* <Pager
          moveTo={handlePageMove}
          totalPages={TOTALPAGES}
          page={CURRENTPAGE}
        /> */}
      </SpinContainer>
    </div>
  );
};

export default connect(null, { fetchDetails, downloadReport })(
  InvoiceReportContainer
);
