import _ from "lodash";
import { restClient, restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { getOTARequestBackbone } from "helpers/RequestBackbone";
import * as prepareArgs from "helpers/ArgsHelper";
import { otaTabs } from "helpers/WizardHelper";
import * as call from "helpers/ActionHelper";
import * as perm from "helpers/PermissionHelper";
import * as actions from "actions/types";
import * as ota from "actions/ota";
import * as wizard from "actions/wizard";
import * as tests from "actions/tests";
import { updateDeviceModel } from "actions/model";
import { updateRequest } from "actions/request";
import { fetchRequestTests } from "actions/tests";

export const withdrawRequest = (id, reason = "") => async dispatch => {
  try {
    const args = prepareArgs.prepareWithdrawnArgs(reason);
    const payload = await restClient.put(
      `/requests/${id}`,
      JSON.stringify(args)
    );

    dispatch({ type: actions.WITHDRAW_REQUEST, payload });
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.requestWithdrawnSuccessfully
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantWithdrawRequest));

    return Promise.reject(e);
  }
};

export const unwithdrawRequest = id => async dispatch => {
  try {
    const args = prepareArgs.prepareUnwithdrawnArgs();
    const payload = await restClient.put(
      `/requests/${id}`,
      JSON.stringify(args)
    );

    dispatch({ type: actions.UNWITHDRAW_REQUEST, payload });
    dispatch(call.showNotice(notifyMessages.requestUnwithdrawnSuccessfully));

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUnwithdrawRequest));

    return Promise.reject(e);
  }
};

export function createOTARequest(manufacturer = "") {
  const requestBackbone = getOTARequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      model: { vendor: { name: manufacturer } }
    }
  };
}

export const getRequestDetails = id => async dispatch => {
  try {
    const url = `/requests/${id}/details`;
    const response = await restClient.get(url);

    dispatch({ type: actions.FETCH_REQUEST_OTA, payload: response.data.data });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const getRequestBilling = id => async dispatch => {
  try {
    const url = `/billings/${id}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_BILLING_INFO,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

function fetchRequestBilling(request, user) {
  return dispatch => {
    // skip lab user - don't need to provide billing data
    if (user.isLab) return Promise.resolve("Skip - User is Lab");

    return dispatch(getRequestBilling(request.billingid));
  };
}

export const fetchOtaRequestData = (requestId, user) => async dispatch => {
  const request = {
    id: requestId,
    details: undefined,
    tests: []
  };

  // run preloader
  dispatch(call.startPreloader());

  dispatch(getRequestDetails(request.id)) // get basic request fields
    .then(res => {
      const details = res.data.data;
      request.details = details;

      // get billing data
      return dispatch(fetchRequestBilling(details, user));
    })
    .then(() => dispatch(fetchRequestTests(request.id))) // get test plans selected for the request
    .then(res => {
      request.tests = res.data.data;

      return dispatch(
        call.makeMultiAsyncAction(request.tests, tests.fetchTestReportsOta)
      );
    })
    .then(() => dispatch(ota.fetchInterfaces())) // get avalilable test plans from server
    .then(res => dispatch(ota.fetchCosts(res.data.data))) // fetch test plan costs
    .then(() => dispatch(ota.fetchOtaLabs())) // fetch lab list to map with test plans
    .then(() => {
      dispatch(setOtaRequestPermissions(request, user));
      dispatch(call.stopPreloader());
    })
    .catch(() => dispatch(call.stopPreloader()));
};

export function searchRequest(transactionNumber) {
  const url = `/requests?where=transactionnumber LIKE ${transactionNumber}`;
  const payload = restClient
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.SEARCH_REQUEST,
    payload
  };
}

export function setOtaRequestPermissions(request, user) {
  const payload = {
    canEditDeviceInfo: perm.checkDeviceInfoPermission(request, user),
    canEditRequest: perm.checkDeviceInfoPermission(request, user),
    canEditPoc: perm.checkPocPermission(request, user),
    canEditBilling: perm.checkBillingPermission(request, user),
    canEditTests: perm.checkTestsPermission(request, user),
    canEditPaymentInfo: perm.checkPaymentPermission(request, user)
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function startNewOtaSubmission(user) {
  return dispatch => {
    const requestBackbone = getOTARequestBackbone();

    dispatch(createOTARequest(user.company));
    dispatch(
      wizard.initWizard({
        steps: otaTabs,
        totalSteps: _.size(otaTabs)
      })
    );
    dispatch(setOtaRequestPermissions({ ...requestBackbone }, user));
  };
}

export function updateDeviceInfo(request) {
  const deviceModel = prepareArgs.prepareOtaDeviceModelArgs(request);
  const hwSwArgs = prepareArgs.prepareHwSwArgs(request);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(updateRequest(hwSwArgs)))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}
