import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";
import { requestFields } from "helpers/AdapterSupplierHelper";
import { setValue } from "actions/request";
import {
  fetchCertifiedAdapters,
  fetchRecognizedAdapters
} from "actions/suppliers";

class SelectAdapterSupplier extends React.Component {
  requestField = requestFields.supplier;

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
  }

  onChange = event => {
    const supplierId = Number(event.target.value);
    const {
      setValue,
      request,
      suppliers,
      isRecognized,
      fetchCertifiedAdapters,
      fetchRecognizedAdapters
    } = this.props;

    // clear revisions
    // fetchAdapterRevisions(0);

    // reset adapterModel value
    setValue(requestFields.model, "");

    // reset adapterModelOption value
    setValue(requestFields.modelOption, undefined);

    // reset adapterRevision value
    setValue(requestFields.revision, "");

    const supList = isRecognized
      ? suppliers.approvededRecognitionSuppliers
      : suppliers.approvededAdapterSuppliers;

    const supplier = _.find(supList, adapter => adapter.id === supplierId);

    if (supplier) {
      // set adapterSupplier value
      setValue(this.requestField, supplierId);
      setValue(requestFields.supplierId, supplierId);
      setValue(requestFields.supplierName, supplier.name);

      // fetch related models
      // based on what selected as Adapter Type (Certified or Recognized)
      if (isRecognized) {
        fetchRecognizedAdapters(supplierId, request.ieee);
      } else {
        fetchCertifiedAdapters(supplierId, request.ieee);
      }
    } else {
      setValue(this.requestField, null);
      setValue(requestFields.supplierId, null);
      setValue(requestFields.supplierName, null);
    }
  };

  render() {
    const { list } = this.props;

    if (!list || !list.length) {
      return (
        <NoDataMessage message="No available suppliers for selected type" />
      );
    }

    return (
      <FormElement
        type="select"
        id="adapterSupplier"
        // label={title}
        label="Adapter Supplier"
        value={this.props.request[this.requestField] || 0}
        onChange={this.onChange}
        options={list}
      />
    );
  }
}

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  setValue,
  fetchCertifiedAdapters,
  fetchRecognizedAdapters
})(SelectAdapterSupplier);
