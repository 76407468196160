import React from "react";
import { Bubble, Divider } from "ctia-ui";
import { Link } from "react-router-dom";
import ReturnLink from "components/ReturnHome";

export default ({ resetPage }) => (
  <div className="block-top-offset text-center">
    <Bubble type="danger">
      Your email was not found in the system. Please try again.
    </Bubble>
    <Divider />
    <h3>
      <Link to="/user/recover" onClick={resetPage}>
        &larr; Try again
      </Link>
    </h3>
    <Divider />
    <ReturnLink />
  </div>
);
