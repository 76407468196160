import * as actions from "actions/types";

//===== Wizard (stepper) form actions
export function initWizard(params = {}) {
  return {
    type: actions.INIT_WIZARD,
    payload: {
      step: 1,
      progressStep: 1,
      totalSteps: 1,
      isSaved: false,
      isSubmitted: false,
      ...params
    }
  };
}

// when user clicks a step link to move between submitted steps
export function changeWizardStep(step) {
  const numStep = Number(step);
  return {
    type: actions.CHANGE_WIZARD_STEP,
    payload: { step: numStep, progressStep: numStep }
  };
}

// when user clicks `continue` at wizard form
export function submitWizardStep() {
  return {
    type: actions.SUBMIT_WIZARD_STEP
  };
}

// when wizard form is submitted
export function submitWizard() {
  const updatedWizard = { isSubmitted: true };

  return {
    type: actions.SUBMIT_WIZARD,
    payload: updatedWizard
  };
}

// when wizard form is saved
export function saveWizard() {
  const updatedWizard = { isSaved: true };

  return {
    type: actions.SAVE_WIZARD,
    payload: updatedWizard
  };
}

export function clearWizard() {
  return { type: actions.CLEAR_WIZARD };
}

export function updateWizard({ ...newData }) {
  return { type: actions.UPDATE_WIZARD, payload: newData };
}
