import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { getTestPassedTitle } from "helpers/RequestTestsHelper";
import { formatDate } from "helpers/DateHelper";
import UploadedResults from "containers/testResult/UploadedResults";
import { downloadReportBLC } from "actions/battery/blcRequest";

const UnderReviewViewBLC = ({ show, testModule, downloadReportBLC }) => {
  if (!show) return null;

  const startDate = formatDate(testModule.actualstartdate);
  const endDate = formatDate(testModule.actualenddate);

  return (
    <div>
      <Table responsive className="ctia-table">
        <thead>
          <tr>
            <th>Test Result</th>
            <th>Test Plan Version</th>
            <th>Test Start Date</th>
            <th>Test End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getTestPassedTitle(testModule.requesttestpassedstatusid)}</td>
            <td>{testModule.planversion}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
          </tr>
        </tbody>
      </Table>
      <UploadedResults
        reports={testModule.reports}
        downloadHandler={downloadReportBLC}
      />
    </div>
  );
};

export default connect(null, { downloadReportBLC })(UnderReviewViewBLC);
