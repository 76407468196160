import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { formatDate } from "helpers/DateHelper";

const BatteryParentDetails = ({ app, request }) => {
  if (!request || !request.parent) return null;

  const isACR = request.isACR === true;
  const isBSC = request.isBSC === true;
  const isSAR = request.isSAR === true;
  const isBLC = app.isBLCCert;
  const { revision, completedat } = request.parent;
  const approvedLabel = isBSC || isACR || isBLC ? "Certified" : "Recognized";
  const approvedDate =
    completedat === ""
      ? `<not ${isSAR ? `authorized` : approvedLabel.toLowerCase()} yet>`
      : formatDate(completedat);

  return (
    <>
      <FormElement
        id="parentRevision"
        value={revision}
        label="Parent Revision Number"
        show={!isBSC && !isSAR && !isBLC}
      />
      <FormElement
        id="parentApprovedDate"
        value={approvedDate}
        label={`Parent Date ${approvedLabel}`}
        show={(isBSC || !isACR || !isBLC) && !isSAR}
      />
      <FormElement
        id="parentApprovedDate"
        value={approvedDate}
        label="Parent Date Authorized"
        show={isSAR && !isBLC}
      />
    </>
  );
};

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps)(BatteryParentDetails);
