import "core-js/es/string/starts-with";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import unregister from "app/registerServiceWorker";
import { Provider } from "react-redux";
import ReduxPromise from "redux-promise";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import reducers from "reducers";
// import App from "app/Main";
import App from "app/index";
import { setInitialState } from "helpers/UsersHelper";
import { appInitState } from "helpers/AppHelper";
import { sitesInitState } from "helpers/ManufSiteHelper";
import { initialState } from "helpers/BudgetReportHelper";

import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import "styles/b4.css";
import "styles/index.css";
import "styles/colors.css";

const store = createStore(
  reducers,
  {
    user: setInitialState(),
    app: appInitState,
    sites: sitesInitState,
    budgetReport: initialState
  },
  applyMiddleware(ReduxPromise, reduxThunk)
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

unregister();
