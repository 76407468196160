import React from "react";
import { connect } from "react-redux";

const SelectUserCompany = ({ org }) => {
  if (!org || !org.userOrgs) return null;

  return org.userOrgs.map(({ id, ctiaid, name }) => {
    return (
      <option key={`${ctiaid}-${name}`} value={ctiaid} data-labid={id}>
        {name}
      </option>
    );
  });
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps)(SelectUserCompany);
