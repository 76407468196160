import React from 'react';
import { connect } from 'react-redux';
import AdapterForm from 'containers/request/battery/AdapterSupplier/UnrecognizedAdapterForm';

const SelectUnrecognizedAdapter = ({ suppliers }) => {
  if (!suppliers) return null;

  return <AdapterForm />;
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(SelectUnrecognizedAdapter);
