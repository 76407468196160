import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import AddAddress from "containers/request/battery/ManufSite/SiteAddressAdd";
import AuditLinks from "containers/request/battery/TestResults/AuditLinks";
import { auditResults } from "dictionaries/TestResults";
import {
  // isResultNoTesting,
  getTestResultTitle
} from "helpers/RequestTestsHelper";
import { toMomentDate } from "helpers/DateHelper";
import { makeEvent } from "libs/form";
import { changeTestField, changeTestDate } from "actions/tests";
import { handleInputChange } from "actions/request";
import { getManufSites, mapCurrentAddress } from "actions/sitesSar";
import { getAddressDetails } from "actions/battery/sarAddresses";
import SiteAddressDetailsReadonly from "../ManufSite/SiteAddressDetailsReadonly";
import ReqTypes from "dictionaries/ReqTypes";

class AuditResultForm extends Component {
  componentDidMount() {
    const { testModule, changeTestField } = this.props;

    // replace planversion value with alias
    const planversion = testModule.planversion.trim();
    const syntEvent = makeEvent("planversion", planversion);
    changeTestField(testModule.id, syntEvent);

    // map manuf site address fields
    const {
      request,
      // getManufSites,
      // getAddressDetails,
      mapCurrentAddress
    } = this.props;
    mapCurrentAddress(request.manufSite.address);
    // fetch existed sites and addresses
    // getManufSites(request.ieee, request.manufSite.companyid, false);

    // map address details to sites.current
    // getAddressDetails(request.manufSite.addressid);
  }

  // onResultChange = (event) => {
  //   // const result = Number(event.target.value);
  //   // const hideUploads = isResultNoTesting(result);

  //   // this.props.handleTestResult(hideUploads);
  //   this.props.changeTestField(this.props.testModule.id, event);
  // };

  render() {
    const {
      show,
      request,
      testModule,
      changeTestDate,
      changeTestField,
      handleInputChange
      // changeTestMultiField,
    } = this.props;

    if (!show) return null;

    const { vendorNote, testStatus, crslversion, manufSite } = request;

    const {
      lab,
      actualenddate,
      actualstartdate,
      requesttestpassedstatusid
    } = testModule;

    // const noTesting = isResultNoTesting(requesttestpassedstatusid);
    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);
    const addressApproved =
      manufSite && (manufSite.ieee1625approved || manufSite.ieee1725approved);

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="auditStatus"
            label="Audit Status"
            value={getTestResultTitle(testStatus)}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
            // onChange={changeTestMultiField.bind(this, testModule.id)}
          />
          <FormElement
            type="select"
            id="requesttestpassedstatusid"
            label="Audit Result"
            value={requesttestpassedstatusid}
            // onChange={this.onResultChange.bind(this)}
            onChange={changeTestField.bind(this, testModule.id)}
            options={auditResults}
          />
          <FormElement
            type="text"
            id="crslversion"
            label="CRSL Version"
            value={crslversion}
            // onChange={changeTestField.bind(this, testModule.id)}
            onChange={handleInputChange}
            placeholder="Enter version number"
          />
          <FormElement
            type="textarea"
            id="vendorNote"
            label="Optional Comments"
            value={vendorNote}
            onChange={handleInputChange}
            style={{ height: "auto" }}
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Audit Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              "actualstartdate"
            )}
            show={true}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Audit End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, "actualenddate")}
            show={true}
            minDate={startDate}
          />
          <hr />
          <AuditLinks />
          <hr />
          {!addressApproved && request.type !== ReqTypes.renewal ? (
            <AddAddress show={true} />
          ) : (
            <SiteAddressDetailsReadonly
              siteAddress={request.manufSite.address}
              showAuditLinks={false}
            />
          )}
          <hr />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  changeTestDate,
  changeTestField,
  handleInputChange,
  getManufSites,
  getAddressDetails,
  mapCurrentAddress
})(AuditResultForm);
