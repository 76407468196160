import React from 'react';
import PropTypes from 'prop-types';

export const StepTitle = ({ show, title, handleLinkClick }) => {
  if (!show) return title;

  return <span onClick={handleLinkClick}>{title}</span>;
};

StepTitle.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string.isRequired,
  handleLinkClick: PropTypes.func.isRequired
};

export default StepTitle;
