import { getWirelessAsString } from 'helpers/Wireless';
import { getCategoryTitle } from 'helpers/CertCategories';
import { getStatusTitle, getModelName, isExisted } from 'helpers/RequestHelper';

export const getEcoBarFields = (request, model) => {
  if (isExisted(request)) {
    return {
      reqStatus: getStatusTitle(request.status),
      reqVendor: request.vendor,
      reqModel: getModelName(request),
      reqCertLevel: request.certCategoryName,
      reqWireless: getWirelessAsString(request.wirelessToggled)
    };
  }

  const {
    modelnumber,
    vendor,
    certcategoryid,
    wirelesscapabilties
  } = model.info;

  return {
    reqStatus: null,
    reqVendor: vendor.name,
    reqModel: modelnumber,
    reqCertLevel: getCategoryTitle(certcategoryid),
    reqWireless: wirelesscapabilties
  };
};
