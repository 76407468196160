export default {
  staff: 1,
  vendor: 2,
  lab: 3,
  accountant: 4,
  operator: 5,
  supplier: 6,
  serviceCenter: 7,
  unassigned: 8
};
