import React from "react";

const OtaFees = () => {
  return (
    <div>
      <p>
        A use fee for each test plan, as shown below, will be invoiced by CTIA
        once the request is submitted. Testing fees are independently determined
        and billed by the test labs.
      </p>
      <ul className="ctia-list">
        <li>SISO OTA Performance: $2,500</li>
        <li>MIMO OTA Performance: $2,500</li>
        <li>Converged Device OTA Performance: $2,500</li>
      </ul>
    </div>
  );
};

export default OtaFees;
