import _ from "lodash";

/** Formats an array of objects to a simple array
 *  which contains only values from array objects by passed object field name.
 *  return null if there exists no specified key
 * 
 * E.g.:
 *        const arr = [{ type: "car", color: "blue" }, { type: "train", color: "red" }];
 *        getSimpleArrayByKey(arr, "color"); // => ["blue", "red"]
 *        getSimpleArrayByKey(arr, "type"); // => ["car", "train"]
 */
export const getSimpleArrayByKey = (arr, objKey) => {
  if (!arr || !objKey || arr.length === 0) return [];
  if (_.isUndefined(arr[0][objKey])) return null;

  let simpleArr = [];

  arr.forEach(arrObj => simpleArr.push(arrObj[objKey]));

  return simpleArr;
};

/**
 * Returns a list of simple objects with passed key (`resField`) and value (`resValue`)
 *
 * @param {Array} list
 * @param {String} resField Field name to be used as a key of returned object per each element
 * @param {String} resValue Field name of result object to be used in return value
 * @returns Array of Objects
 */
export const filterCheckedOnlyValues = (list, resField, resValue) => {
  if (!list || _.isEmpty(list)) return [];
  const filtered = _.filter(list, "checked");
  return filtered.map(res => {
    return {
      [resField]: res[resValue]
    };
  });
};
