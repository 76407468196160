import _ from "lodash";
import * as actions from "actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_COUNTRIES:
      return { ...state, list: action.payload };
    case actions.FETCH_COUNTRY_STATES:
      const states = _.isEmpty(action.payload) ? null : action.payload;
      return { ...state, states };
    case actions.CLEAR_COUNTRY_STATES:
      return { ...state, states: null };
    case actions.SET_SELECTED_COUNTRY:
      return { ...state, selectedCountry: action.payload };
    case actions.SET_SELECTED_STATE:
      return { ...state, selectedState: action.payload };
    default:
      return state;
  }
}
