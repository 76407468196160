import React from "react";
import { connect } from "react-redux";
import TabInstructions from "components/request/TabInstructions";
import instructions from "dictionaries/TabInstructions";

const SiteBubble = ({ request }) => {
  if (!request) return null;

  return <TabInstructions messages={instructions.manufSite} />;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(SiteBubble);
