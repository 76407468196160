import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  fetchLabs,
  fetchManufacturers,
  fetchOperators, fetchSuppliers,
} from "actions/companies";
import FormElement from "components/FormElement";
import {
  getOptionWithCompanyName,
  selectionOptions
} from "components/CompanySelectors/CompanySelector/CompanyNameSelector/CompanyNameHelper";

const CompanyCombobox = ({
  companyType,
  editable = true,
  formElementProperties,
  isPtcrb = false,
  isRequired,
  onChange,
  value,
  // Redux
  companies
}) => {
  const [localCompanyType, setLocalCompanyType] = useState(companyType);
  const options = selectionOptions({ companies, companyType, isPtcrb });

  const handleChange = event => {
    const { value } = event.target;

    if (_.isUndefined(value)) {
      onChange({
        companyId: -1,
        companyName: ""
      });
    }
    else {
      const companyName = value;
      const selectedOption = getOptionWithCompanyName({
        companyName,
        options
      });
      onChange({
        companyId: selectedOption.companyId,
        companyName
      });
    }
  };

  /*
   * Handle company type change -- this should re-run the check against the list
   * of companies of the current company type to see if the user provided/selected
   * matches one in the current list.
   *
   * Using solution from -> https://betterprogramming.pub/updating-state-from-properties-with-react-hooks-5d48693a4af8
   */
  const previousValueRef = useRef();
  const previousValue = previousValueRef.current;
  if (companyType !== previousValue && companyType !== localCompanyType) {
    setLocalCompanyType(companyType);
    const selectedOption = getOptionWithCompanyName({
      companyName: value,
      options
    });
    onChange({
      companyId: selectedOption.companyId
    });
  }

  useEffect(() => {
    previousValueRef.current = companyType;
  });

  const optionsFormattedForRender = _.uniqBy(options,
      company => company.companyName.trim().toLowerCase())
  .map(option => { return {
    id: option.id,
    title: option.companyName,
    value: option.companyId
  }});

  const validationState = _.isEmpty(value)
    ? "error"
    : null;

  return (
    <FormElement
      {...formElementProperties}
      editable={editable}
      id="companyName"
      isRequired={isRequired}
      label="Company Name"
      onChange={handleChange}
      options={optionsFormattedForRender}
      type="comboBox"
      validationState={validationState}
      value={value}
    />
  );
};

function mapStateToProps({ companies }) {
  return { companies };
};

export default connect(mapStateToProps, {
  fetchLabs,
  fetchManufacturers,
  fetchOperators,
  fetchSuppliers
})(CompanyCombobox);