import _ from "lodash";
import { connect } from "react-redux";
import React from "react";
import FormElement from "components/FormElement";
import { handleInputChange, setValue } from "actions/request";
import {
  getDeviceInfoPermission,
  formatOpSysSelectList
} from "helpers/RequestHelper";

const RequestOperatingSystem = ({
  app,
  request,
  user,
  handleInputChange,
  setValue
}) => {
  if (!request.opSysList) return null;

  const onChange = event => {
    const opSysId = _.toNumber(event.target.value);
    setValue("operatingSystem", opSysId);
  };

  const isEditable = !request.isEco && getDeviceInfoPermission(app, user);
  const inputType = isEditable ? "select" : "";
  const inputValue = isEditable
    ? request.operatingSystem
    : request.operatingSystemName;
  const list = request.id
    ? request.opSysList
    : formatOpSysSelectList(request.opSysList);

  return (
    <FormElement
      id="operatingSystem"
      label="Operating System"
      type={inputType}
      value={inputValue}
      options={list}
      editable={isEditable}
      onChange={onChange}
    />
  );
};

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, { handleInputChange, setValue })(
  RequestOperatingSystem
);
