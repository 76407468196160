import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Glyphicon } from "react-bootstrap";
import PropTypes from "prop-types";
import UploadQueue from "components/UploadQueue/index";
import ReqLink from "components/RequestsLink";
// import { IconLabel } from 'ctia-ui';
import {
  hasDocsForUpload,
  getUploadQueue,
  hasTitlesForChange,
  getUploadedTitlesQueue,
  manageQueue,
  prepareUsersManualOptions,
  prepareSupDocOptions
} from "helpers/UploadsHelper";
import { isSaved } from "helpers/RequestHelper";
import {
  uploadUsersManual,
  uploadSupportDocument,
  addToQueue,
  removeFromQueue,
  clearQueue,
  deleteSupportDocument,
  updateSupDocTitle
} from "actions/uploads";
import { invoiceDownload, fetchRequest } from "actions/requestCyber";

class ResultPanel extends Component {
  componentDidMount() {
    const {
      request,
      isUpdate,
      addToQueue,
      uploadUsersManual,
      uploadSupportDocument,
      removeFromQueue,
      fetchRequest
    } = this.props;
    const { supportDocs, newId, id, usersManual } = request;
    const requestId = newId || id;
    const modelId = request.deviceModelId || request.model.id;

    // uploading users manual
    if (usersManual && usersManual.file) {
      const uploadOptions = prepareUsersManualOptions(usersManual, modelId);
      manageQueue(
        uploadOptions,
        uploadUsersManual,
        addToQueue,
        removeFromQueue
      );
    }

    // uploading support documents
    if (hasDocsForUpload(supportDocs)) {
      const queue = getUploadQueue(supportDocs);
      _.map(queue, doc => {
        const { file, title, slot } = doc;
        const uploadOptions = prepareSupDocOptions({
          slot,
          title,
          requestId,
          file,
          isUpdate,
          request
        });
        manageQueue(
          uploadOptions,
          uploadSupportDocument,
          addToQueue,
          removeFromQueue
        );
      });
    }

    // update sup docs' titles
    if (hasTitlesForChange(supportDocs)) {
      const queue = getUploadedTitlesQueue(supportDocs);
      _.map(queue, doc => {
        this.props.updateSupDocTitle(requestId, doc.slot, doc.title);
      });
    }

    // fetch request invoice info to generate a notice message
    if (_.has(request, "newId")) {
      fetchRequest(request.newId);
    }
  }

  componentWillUnmount() {
    this.props.clearQueue();
  }

  getResultTitle = () => {
    const { isSubmit, isUpdate } = this.props;

    if (isSubmit) return "submitted";
    if (isUpdate) return "updated";

    return "saved";
  };

  renderInvoiceNotice = () => {
    const { isSave, request } = this.props;

    // don't show if request saved
    if (isSave) return null;

    // request status shouldn't be `saved`
    if (isSaved(request.requeststatusid) || isSaved(request.status))
      return null;

    // show only for new submissions -- TODO: uncomment this if need to show invoice message
    // if (_.has(request, 'newId') && !_.isEmpty(String(request.newId))) {
    //   // if invoice generated
    //   if (_.has(request, 'invoice')) {
    //     return (
    //       <p>
    //         <IconLabel awesomeIcon="file-invoice-dollar" />
    //         Please{' '}
    //         <a
    //           onClick={() => this.props.invoiceDownload(request)}
    //           className="bold"
    //         >
    //           click here
    //         </a>{' '}
    //         to download the CTIA certification fee invoice.
    //       </p>
    //     );
    //   }

    //   // show animation while generating invoice
    //   return (
    //     <p>
    //       <Glyphicon glyph="hourglass" className="rotateMe icon-right-offset" />
    //       Generating invoice, please wait...
    //     </p>
    //   );
    // }
    return null;
  };

  render() {
    const { request } = this.props;
    const resTitle = this.getResultTitle();
    const reqNum = () => {
      if (!request.tn) return null;

      return <b>{request.tn}</b>;
    };

    return (
      <Alert>
        <p>
          <Glyphicon glyph="ok" className="icon-right-offset" />
          Request {reqNum()} has been successfully {resTitle}. You can review{" "}
          <b>
            <ReqLink linkTitle="all your requests here" />
          </b>
          .
        </p>
        {this.renderInvoiceNotice()}
        <UploadQueue />
      </Alert>
    );
  }
}

ResultPanel.propTypes = {
  isSubmit: PropTypes.bool,
  isUpdate: PropTypes.bool,
  isSave: PropTypes.bool
};

ResultPanel.defaultProps = {
  isSubmit: false,
  isUpdate: false,
  isSave: false
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  uploadUsersManual,
  uploadSupportDocument,
  addToQueue,
  removeFromQueue,
  clearQueue,
  deleteSupportDocument,
  updateSupDocTitle,
  invoiceDownload,
  fetchRequest
})(ResultPanel);
