import { restClient } from "libs/restClient";
import * as actions from "actions/types";

export const fetchOperators = () => async (dispatch) => {
  try {
    const response = await restClient.get("companies/operators?page=0&includeSoftDeletes=false");
    dispatch({ type: actions.FETCH_OPERATORS, payload: response.data.data });

    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export function clearOperators() {
  return {
    type: actions.CLEAR_OPERATORS,
  };
}
