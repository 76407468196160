import { SHOW_NOTICE, HIDE_NOTICE } from 'actions/types';

export function showNotice(message) {
  return {
    type: SHOW_NOTICE,
    payload: message
  };
}

export function hideNotice() {
  return {
    type: HIDE_NOTICE,
    payload: false
  };
}
