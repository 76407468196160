import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";

const RequestManufacturer = ({ request, user }) => {
  const { id, vendor, isBattery, isBSC, isEPRR } = request;
  const { company } = user;
  const hasRequestId = !_.isUndefined(id);
  const vendorName = hasRequestId ? vendor : company;
  const vendorTitle = () => {
    if (isBattery && !isBSC && !isEPRR) return "Supplier";
    else if (isBattery && isEPRR) return "Vendor/Supplier";
    else return "Vendor";
  };

  return (
    <FormElement id="manufTitle" label={vendorTitle()} value={vendorName} />
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(RequestManufacturer);
