import _ from "lodash";
import reqTypes from "dictionaries/ReqTypes";

export const handleDelistOrRelist = ({
  certTypeId = null,
  deviceModelId = null,
  requestId = null,
  requestIdsOfChildren = [],
  requestTypeId = null,
  selectedCertType = null,
  shouldRelist = false,
  // Callbacks
  successCallback = () => {},
  errorCallback = () => {},
  // Redux Actions
  delistDevice,
  delistRequest,
  relistDevice,
  relistRequest
}) => {
  const shouldDelistAsRequest =
    !_.isNull(requestId) && !_.isNull(selectedCertType);

  shouldDelistAsRequest
    ? handleDelistOrRelistRequestAndChildrenRequests({
        parentRequestId: requestId,
        parentRequestTypeId: requestTypeId,
        requestIdsOfChildren: requestIdsOfChildren,
        selectedCertType: selectedCertType,
        shouldRelist: shouldRelist,
        successCallback: successCallback,
        errorCallback: errorCallback,
        delistRequest: delistRequest,
        relistRequest: relistRequest
      })
    : handleDelistOrRelistDeviceModel({
        certTypeId: certTypeId,
        deviceModelId: deviceModelId,
        shouldRelist: shouldRelist,
        successCallback: successCallback,
        errorCallback: errorCallback,
        delistDevice: delistDevice,
        relistDevice: relistDevice
      });
};

const handleDelistOrRelistDeviceModel = ({
  certTypeId,
  deviceModelId,
  shouldRelist,
  successCallback,
  errorCallback,
  delistDevice,
  relistDevice
}) => {
  const callParams = {
    certTypeId: certTypeId,
    deviceId: deviceModelId
  };
  const call = shouldRelist
    ? relistDevice(callParams)
    : delistDevice(callParams);

  call.then(successCallback).catch(errorCallback);
};

const handleDelistOrRelistRequestAndChildrenRequests = ({
  parentRequestId,
  parentRequestTypeId,
  requestIdsOfChildren,
  selectedCertType,
  shouldRelist,
  successCallback,
  errorCallback,
  delistRequest,
  relistRequest
}) => {
  let requestIds = [parentRequestId];

  const shouldDelistOrRelistChildren =
    _.isNumber(parentRequestTypeId) &&
    parentRequestTypeId === reqTypes.initial &&
    !_.isEmpty(requestIdsOfChildren);

  if (shouldDelistOrRelistChildren) {
    requestIds = requestIds.concat(requestIdsOfChildren);
  }

  requestIds
    .reduce((seq, requestId) => {
      return seq.then(() => {
        const callParams = {
          id: requestId,
          prefix: selectedCertType
        };
        return shouldRelist
          ? relistRequest(callParams)
          : delistRequest(callParams);
      });
    }, Promise.resolve())
    .then(successCallback)
    .catch(errorCallback);
};
