import axios from "axios";
import { generalApiUrl } from "dictionaries/Env";
import { GET_SYSTEM_INFO } from "actions/types";

export async function getSystemInfo() {
  // WARNING: must use clear axios w/o token
  const url = `${generalApiUrl}/system/info`;
  const payload = await axios
    .get(url)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });

  return {
    type: GET_SYSTEM_INFO,
    payload
  };
}
