import React from "react";
import { InfoBar } from "ctia-ui";
import { types } from "helpers/BatteryCertHelper";
import { formatBillingAddress as formatAddress } from "helpers/BillingHelper";
import { mapSiteAddress } from "helpers/ManufSiteHelper";
import { concatStr } from "helpers/StringHelper";
import OneLineText from "components/form/OneLineText";

const BatteryInfoBar = ({ certType = null, modelInfo, deviceInfo }) => {
  const { modelnumber, companyname, vendorname, name, vendorproductname } =
    deviceInfo;
  let reqDataInfo = null;

  if (certType === types.SAR) {
    const address = formatAddress(modelInfo);
    const addressContent = mapSiteAddress(modelInfo);
    const siteAddress = () => {
      return (
        <OneLineText
          value={concatStr(address)}
          content={addressContent}
          inTable={false}
          title={vendorproductname}
          titleId="site-address"
        />
      );
    };

    reqDataInfo = [
      {
        key: "Supplier",
        value: companyname || vendorname
      },
      {
        key: "Manufacturing Site",
        value: name || vendorproductname
      },
      {
        key: "Site Address",
        value: siteAddress,
        isFunc: true
      }
    ];
  } else if (certType === types.BSC) {
    reqDataInfo = [
      {
        key: "Vendor",
        value: name
      },
      {
        key: "Model Name/Number",
        value: modelnumber
      }
    ];
  } else {
    const { devicemodel } = modelInfo;
    const capacityAlias =
      devicemodel && devicemodel.capacityAlias
        ? devicemodel.capacityAlias
        : "n/a";
    const vendorLabel =
      certType === types.EPRR ? "Supplier/Vendor" : "Supplier";

    reqDataInfo = [
      {
        key: vendorLabel,
        value: name
      },
      {
        key: "Model Name/Number",
        value: modelnumber
      },
      {
        key: "Capacity",
        value: capacityAlias
      }
    ];
  }
  return <InfoBar show={true} data={reqDataInfo} emptyLabel="n/a" />;
};

export default BatteryInfoBar;
