import React from "react";
import moment from "moment";
import { connect } from "react-redux";

const BudgetDateRangeDisplay = ({ budgetReport }) => {
  const { dateFrom, dateTo } = budgetReport.settings;
  const dFormat = "MMM Do YYYY";

  return (
    <div>
      {moment(dateFrom).format(dFormat)} - {moment(dateTo).format(dFormat)}
    </div>
  );
}

function mapStateToProps({ budgetReport }) {
  return { budgetReport };
}

export default connect(mapStateToProps)(BudgetDateRangeDisplay);
