import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { batteryOptions } from "containers/request/RequestList/statusOptions";
import RenderOptions from "containers/request/RequestList/RenderOptions";
import BatteryAllTable from "containers/request/RequestList/battery/BatteryAllTable";
import {
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel
} from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import { clearRequests } from "actions/requests";
import {
  getBatteryRequests,
  getVendorsBatteryRequests
} from "actions/battery/batteryRequests";
import { setPageSize, changeBatteryRequestStatus } from "actions/app";
import CyberTable from "containers/CyberTable";
import RequestListTitle from "containers/request/RequestList/RequestListTitle";

class BatteryAll extends CyberTable {
  componentDidMount() {
    const { pageSize, batteryRequestStatus } = this.props.app;
    this.startPage = pageSize === 0 ? 0 : 1;

    if (!_.has(batteryOptions, batteryRequestStatus.id)) {
      this.props.changeBatteryRequestStatus();
      setTimeout(this.fetchTableData, 150);
    } else this.fetchTableData();
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  onStatusChange = event => {
    const newStatus = Number(event.target.value);

    // update app filter settings
    this.props.changeBatteryRequestStatus(newStatus);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  fetchTableData = (
    pageNum = this.startPage,
    statusFilter = this.props.app.batteryRequestStatus.filter,
    pageSize = this.props.app.pageSize
  ) => {
    const { user, getVendorsBatteryRequests, getBatteryRequests } = this.props;

    const fetchHandler = user.isVendor
      ? getVendorsBatteryRequests
      : getBatteryRequests;

    return fetchHandler(pageNum, pageSize, statusFilter);
  };

  setPagerSize = size => this.props.setPageSize(size);

  render() {
    const { requests, app } = this.props;
    const { batteryRequestStatus } = app;
    const tableData = this.setTableData(requests);
    const { page, pages, records } = this.setPagerData(requests);
    const counter = this.setCounter(tableData, records);
    const sortedBatteryOptions = _.sortBy(batteryOptions, ["title", "id"]);

    return (
      <div>
        <RequestListTitle listLabel={batteryRequestStatus.title} />
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={batteryRequestStatus.id}
                statusOptions={<RenderOptions options={sortedBatteryOptions} />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        <BatteryAllTable data={tableData} fetchHandler={this.fetchTableData} />
        <Divider />
        <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
      </div>
    );
  }
}

function mapStateToProps({ requests, app }) {
  return { requests, app };
}

export default connect(mapStateToProps, {
  setPageSize,
  changeBatteryRequestStatus,
  clearRequests,
  getBatteryRequests,
  getVendorsBatteryRequests
})(BatteryAll);
