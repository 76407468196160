import React, { useState } from "react";
import { connect } from "react-redux";
import { LoaderButton, IconLabel } from "ctia-ui";
import { updateInvoiceDate } from "actions/payments";
import { toMomentDate } from "helpers/DateHelper";

const UpdateInvoiceDate = ({ request, updateInvoiceDate }) => {
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    const ivcDate = toMomentDate(request.ivcDate);
    return ivcDate.isValid();
  };

  const onSubmit = () => {
    setLoading(true);
    updateInvoiceDate(request)
      .catch(() => setLoading(false))
      .then(() => setLoading(false));
  };

  return (
    <LoaderButton
      bsStyle="default"
      bsSize="small"
      text={<IconLabel label="Update Invoice Date" awesomeIcon="clock" />}
      onClick={onSubmit}
      isLoading={loading}
      loadingText="Updating..."
      disabled={!validateForm()}
      className="button-right-offset"
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  updateInvoiceDate
})(UpdateInvoiceDate);
