// React/Redux imports
import { IconLabel } from "ctia-ui";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Table } from "react-bootstrap";

// Helper imports
import { toggleOrder } from "helpers/SortHelper";
import UserRequestDispositions
  from "dictionaries/UserRequestDispositions";

// Components imports
import NewUserRequestTableRows from "containers/users/UserRequest/UserRequestMenu/NewUserRequestTable/TableRows.js";

const NewUserRequestTable = ({
  onRowClick = () => {},
  selectedUserRole = null,
  tableData = [],
}) => {
  const [sort, setSort] = useState({
    field: "dateSubmitted",
    order: "DESC",
  });

  const setSortColumnAndDirection = (field) => {
    const order = sort.field === field
      ? toggleOrder(sort.order)
      : "ASC";

    setSort({ field, order });
  };

  const sortableColumn = (columnName, fieldname) => {
    const showCaret = sort.field === fieldname;

    return (
      <button className="button-no-border" onClick={() => setSortColumnAndDirection(fieldname)}>
        { !showCaret &&
          columnName
        }
        { showCaret &&
          <IconLabel
            awesomeIcon={
              sort.order.toUpperCase() === "ASC" ? "caret-up" : "caret-down"
            }
            label={columnName}
            iconFirst={false}
          />
        }
      </button>
    );
  };

  const showNeedsMoreInfoColumn = selectedUserRole === UserRequestDispositions.pending;

  return (
    <>
      <Table striped condensed hover className="button-top-offset ctia-table">
        <thead>
          <tr>
            <th>{sortableColumn("Name", "fullName")}</th>
            <th>{sortableColumn("Title", "title")}</th>
            <th>{sortableColumn("Company", "company")}</th>
            <th>{sortableColumn("E-mail", "email")}</th>
            <th>{sortableColumn("Date Submitted", "dateSubmitted")}</th>
            <th>{sortableColumn("Program", "program")}</th>
            { showNeedsMoreInfoColumn &&
              <th>{sortableColumn("Needs More Info", "status")}</th>
            }
          </tr>
        </thead>
        <tbody>
          <NewUserRequestTableRows
            onRowClick={onRowClick}
            showNeedsMoreInfo={showNeedsMoreInfoColumn}
            sortDirection={sort.order}
            sortField={sort.field}
            tableData={tableData}
          />
        </tbody>
      </Table>
    </>
  );
};

NewUserRequestTable.propTypes = {
  tableData: PropTypes.array
};

export default NewUserRequestTable;
