import React from "react";
import { connect } from "react-redux";
import CyberComponent from 'containers/CyberComponent';
import { getAllCyber } from "actions/requestCyber";
import { clearRequest } from "actions/request";
import Certificate from "components/Certificate/index";
import { Link } from "react-router-dom";
import "components/Certificate/style.css";
import ReactToPrint from 'react-to-print';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

class CyberCertificate extends CyberComponent {

  constructor(props) {
    super(props)
    this.printRef = React.createRef();
  }

  fetchAll = id => {
    const { match, user, getAllCyber } = this.props;
    const reqId = id ? id : match.params.id;

    return getAllCyber(reqId, user);
  };

  componentDidMount() {
    return this.fetchAll();
  }

  componentDidUpdate(prevProps) {
    const { match, preloader, request } = this.props;

    // don't touch anything in case of active loading state
    if (preloader) return;

    // get new request id
    const newId = match.params.id;

    // if id is different with current, than fetch new request data
    if (newId !== prevProps.match.params.id) {
      return this.fetchAll(newId);
    }

    // need update request data due to server changes
    if (
      prevProps.request &&
      !prevProps.request.needUpdate &&
      request &&
      request.needUpdate
    ) {
      return this.fetchAll(newId);
    }

    if (
      request &&
      prevProps.request &&
      request.isTogglerEnabled !== prevProps.request.isTogglerEnabled &&
      request.isTogglerEnabled === false
    ) {
      this.fetchAll(match.params.id);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  handleDownloadPdf = async (request) => {
    const element =  this.componentRef.children[0];
    element.style.setProperty('border','0px white');
    const canvas = await html2canvas(element, {scale: 3});
    element.style.setProperty('border','1px solid #707070');
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF({orientation: 'l'});
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight =
      (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('Cybersecurity Certificate - ' + request.modelName + '.pdf');
  };

  render() {
      const { request, history } = this.props;

      if(request === null) return null;

      return (
        <div>
          <h1 className="pageHeader">Certificate for Request {request.tn}</h1>
          <Certificate request={request} ref={el => {(this.componentRef = el)}}></Certificate>
          <Link className="backButton link" onClick={() => history.go(-2)}>Back</Link>
          <div className="button-section">
            <button className="download-button" onClick={() => this.handleDownloadPdf(request)}>Download</button>
            <ReactToPrint
              trigger={() => {
                return <button className="print-button">Print</button> ;
              }}
              content={() => this.componentRef}
            />
          </div>
        </div>
      );
    }
};

function mapStateToProps({ request, user, preloader }) {
  return { request, user, preloader };
}

export default connect(mapStateToProps, {
  clearRequest,
  getAllCyber
})(CyberCertificate);

