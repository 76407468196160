import React from "react";
import { connect } from "react-redux";
import { DeleteButton } from "ctia-ui";
import ModelBadge from "containers/request/battery/BatterySupplier/PendingRecognitionBadge";
import { removeBatterySupplier } from "actions/suppliers";

const BatterySupplierListRow = ({
  supplier,
  suppliers,
  removeBatterySupplier
}) => {
  if (!supplier) return null;

  const {
    linkedrequestvendorname: supplierName,
    linkedrequestvendorid: vendorId,
    linkedrequestcapacity: capacity,
    linkedrequestrevision: revision,
    linkedrequestid: reqId,
    hasPendingRecognition
  } = supplier;

  const batteryCapacity = hasPendingRecognition ? "—" : capacity;
  const batteryRevision = hasPendingRecognition ? "—" : revision;

  const idToBeRemoved = {
    requestId: reqId || null,
    vendorId: reqId ? null : vendorId
  };

  return (
    <tr>
      <td>{supplierName}</td>
      <td>
        <ModelBadge row={supplier} />
      </td>
      <td>{batteryRevision}</td>
      <td>{batteryCapacity}</td>
      <td>
        <DeleteButton
          size="xs"
          label="Remove"
          action={() => removeBatterySupplier(idToBeRemoved)}
        />
      </td>
    </tr>
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  removeBatterySupplier
})(BatterySupplierListRow);
