import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "ctia-react-bootstrap-v4";
import { setInvoicingYear, fetchAscList, fetchAscInvoices } from "actions/asc";
import { startPreloader, finishPreloader } from "actions/preloader";
import {
  getInvoicingYears,
  getCurrentYear,
  getCurrATLYear
} from "helpers/DateHelper";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

const InvoiceYearSelector = ({
  asc,
  setInvoicingYear,
  fetchAscList,
  fetchAscInvoices,
  startPreloader,
  finishPreloader,
  showNotice
}) => {
  useEffect(() => {
    if (asc && !asc.invoicingYear) {
      setInvoicingYear(getCurrATLYear());
    }
  }, [setInvoicingYear, asc]);

  const YearToggle = React.forwardRef(({ children, onClick }, ref) => {
    return (
      <span
        className="dashed cursor-pointer"
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </span>
    );
  });

  const currYear = getCurrentYear();
  const years = getInvoicingYears();
  const selectedYear =
    asc && asc.invoicingYear ? asc.invoicingYear : currYear;

  return (
    <Dropdown
      onSelect={year => {
        // not allow re-fetching while there's an active background job
        if (asc.isBackgroundJobRunning) {
          return showNotice(notifyMessages.backgroundJobInActionWarning);
        }

        if (Number(year) !== Number(asc.invoicingYear)) {
          startPreloader();
          setInvoicingYear(year);
          fetchAscList(year)
            .then(() => {
              fetchAscInvoices(year);
              finishPreloader();
            })
            .catch(finishPreloader);
        }
      }}
    >
      <Dropdown.Toggle as={YearToggle} id="dropdown-custom-components">
        {selectedYear}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Header>Select a year:</Dropdown.Header>
        {years.map(year => {
          const isActive =
            asc && asc.invoicingYear
              ? year === asc.invoicingYear
              : year === currYear;
          return (
            <Dropdown.Item key={year} eventKey={year} active={isActive}>
              {year}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, {
  setInvoicingYear,
  fetchAscList,
  fetchAscInvoices,
  startPreloader,
  finishPreloader,
  showNotice
})(InvoiceYearSelector);
