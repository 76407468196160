import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import { addCompanyVendor } from "actions/orgAdmin/vendor";

import "containers/ControlPanel/Payments/payments.css";

const formId = "add-vendor-form";

const AddVendorModal = ({ show, modalHandler, addCompanyVendor }) => {
  const [name, setName] = useState("");
  const [isSupplier, setSupplier] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleSupplierCheckbox = () => setSupplier(!isSupplier);

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Save" awesomeIcon="check" />}
        onClick={() => {
          setLoading(true);
          addCompanyVendor(formId)
            .then(() => {
              setLoading(false);
              modalHandler();
            })
            .catch(err => {
              setLoading(false);
              modalHandler();
            });
        }}
        disabled={name === ""}
        isLoading={loading}
      />
    </span>
  );

  return (
    <Modal show={show} onHide={modalHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Add Vendor/Supplier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id={formId} className="ctia-form">
          <Form.Group controlId="vendorName">
            <Form.Label>Name:</Form.Label>
            <Form.Control
              placeholder="Enter organization name"
              onChange={e => setName(e.target.value)}
              maxLength={100}
            />
          </Form.Group>
          <Form.Check
            id="supplierCheckbox"
            type="checkbox"
            label="Battery/Cell/Adapter Supplier"
            onChange={toggleSupplierCheckbox}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AddVendorModal.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func
};

export default connect(null, {
  addCompanyVendor
})(AddVendorModal);
