import { formatNumberString } from 'helpers/StringHelper';

/**
 * Creates a mask object for passed zip code.
 *
 * Returns an empty mask object for all countries but USA.
 *
 * @param {number|string} zip ZIP or Postal code
 * @param {string} countryCode Two letters of country
 * @returns object
 */
export const getZipCodeMask = (zip, countryCode) => {
  const defaultMask = { mask: null, maskChar: null };

  if (!zip) return defaultMask;

  if (countryCode === 'US') {
    const zipOnly = formatNumberString(zip);
    const isFullZip = zipOnly.length > 5;
    return {
      mask: isFullZip ? '99999-9999' : '999999',
      maskChar: isFullZip ? '_' : null
    };
  }
  return defaultMask;
};
