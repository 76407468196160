import React, { Component } from "react";
import { connect } from "react-redux";
import RequestControls from "containers/request/RequestControls";
import { submitOtaRequest } from "actions/ota";
import { submitBillingCyber, updateBillingEntry } from "actions/billing";
import {
  validateBillingPoc,
  validateBillingAddress
} from "helpers/ValidatorHelper";
import { getBillingPermissions } from "helpers/BillingHelper";

class BillingSubmit extends Component {
  validate() {
    return this.validatePoc() && this.validateAddress();
  }

  validatePoc() {
    const { request } = this.props;
    const { billingPocId } = request;
    return billingPocId > 0 ? true : validateBillingPoc(request);
  }

  validateAddress() {
    const { request } = this.props;
    const { billingAddressId } = request;
    return billingAddressId > 0 ? true : validateBillingAddress(request);
  }

  onSaveClick = () => {
    const { request, user, updateBillingEntry } = this.props;
    return updateBillingEntry(request, user);
  };

  onContinueClick = () => {
    const { request, user, submitBillingCyber } = this.props;
    submitBillingCyber(request, user);
  };

  onSubmit = () => {
    const { request, user, submitOtaRequest } = this.props;
    return submitOtaRequest(request, user);
  };

  render() {
    const { app, request, user } = this.props;
    const isEditable = getBillingPermissions(app, request, user);
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <RequestControls
        isValid={this.validate()}
        onSave={saveHandler}
        onContinue={this.onContinueClick}
        onSubmit={this.onSubmit}
      />
    );
  }
}

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, {
  submitOtaRequest,
  submitBillingCyber,
  updateBillingEntry
})(BillingSubmit);
