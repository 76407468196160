import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CyberComponent from "containers/CyberComponent";
import LabTestInfoBLC from "containers/request/BLC/LabTestInfoBLC";
import LabTestResultBLC from "containers/request/BLC/LabTestResultBLC";
import RequestControls from "containers/request/RequestControls";
import { Bubble } from "ctia-ui";
import { updateRequestLab } from "actions/requestCyber";

class LabInfoBLC extends CyberComponent {

  validate() {
    return this.props.request.cdmaLab > 0;
  }

  onSaveClick = () => {
    this.props.updateRequestLab(this.props.request);
  };

  render() {
    const { show, tests, user, canSubmit } = this.props;

    if (!show) return null;

    const testModule = tests ? tests[0] : null;
    const isEditable = user.canEditRequest || user.isStaff;
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        <Bubble show={canSubmit}>Please select a lab for the testing.</Bubble>
        <LabTestInfoBLC editable={isEditable} />
        <LabTestResultBLC testModule={testModule} />
        <RequestControls isValid={this.validate()} onSave={saveHandler} />
      </div>
    );
  }
}

LabInfoBLC.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

LabInfoBLC.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  updateRequestLab
})(LabInfoBLC);
