import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import ApproveButton from "components/request/ApproveButton";
import ReqLink from "components/RequestsLink";
import { getRequestTypeTitle, getStatusTitle } from "helpers/RequestHelper";
import { getReadyToApproveRequests, clearRequests } from "actions/requests";

class ApproveList extends Component {
  componentDidMount() {
    this.props.getReadyToApproveRequests();
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  renderRows() {
    return _.map(this.props.requests.approveList, req => {
      const type = getRequestTypeTitle(req.requesttypeid);
      const status = getStatusTitle(req.requeststatusid);
      return (
        <tr key={req.id}>
          <td>
            <ReqLink
              linkTitle={req.formatedtransactionnumber}
              requestId={req.id}
            />
          </td>
          <td>{req.name}</td>
          <td>{req.modelnumber}</td>
          <td>{type}</td>
          <td>{status}</td>
          <td>
            <ApproveButton id={req.id} request={req} />
            <ApproveButton id={req.id} request={req} withdraw={true} />
          </td>
        </tr>
      );
    });
  }

  render() {
    const { requests } = this.props;

    if (_.isEmpty(requests)) return null;

    if (_.isEmpty(requests.approveList)) {
      return <Bubble type="info">No requests waiting for approval.</Bubble>;
    }

    return (
      <div>
        <Bubble>
          Below is a list of the requests that are complete and waiting for
          approval.
        </Bubble>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Request ID</th>
              <th>Vendor</th>
              <th>Model Name/Number</th>
              <th>Request Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </Table>
      </div>
    );
  }
}

function mapStateToProps({ requests }) {
  return { requests };
}

export default connect(mapStateToProps, {
  getReadyToApproveRequests,
  clearRequests
})(ApproveList);
