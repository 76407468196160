import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { changeCertProgram } from "actions/app";
import { certProgramms } from "helpers/AppHelper";

const getPrefix = path => {
  const pathArr = path.split("/");
  return pathArr[1];
};

const Portal = ({ to, tn, changeCertProgram }) => {
  let history = useHistory();
  const programPrefix = getPrefix(to);
  const programId = certProgramms[programPrefix];

  const redirectToRequest = e => {
    e.preventDefault();
    changeCertProgram(programId);
    history.push(to);
  };

  return (
    <a className="glow-link" onClick={redirectToRequest} href={to}>
      {tn}
    </a>
  );
};

export default connect(null, { changeCertProgram })(Portal);
