import React from "react";
import { connect } from "react-redux";
import {
  formatAdapterSuppliersList,
  supplierTypes
} from "helpers/SupplierHelper";
import { adapterTypes } from "helpers/AdapterSupplierHelper";
import SelectSupplier from "containers/request/battery/AdapterSupplier/SelectAdapterSupplier";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";

const SelectCertifiedAdapter = ({ suppliers }) => {
  if (!suppliers) return null;
  if (!suppliers.approvededAdapterSuppliers) {
    return <NoDataMessage message="No adapter suppliers available" />;
  }

  const list = formatAdapterSuppliersList(
    suppliers.approvededAdapterSuppliers,
    supplierTypes.adapter
  );

  return <SelectSupplier list={list} title={adapterTypes.certified} />;
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(SelectCertifiedAdapter);
