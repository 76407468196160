import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { TooltipBase, LoaderButton, IconLabel } from "ctia-ui";
import ReqLink from "components/RequestsLink";
import ActionIcons from "components/request/StaffActionIcons";
import NoTableData from "components/NoTableData";
import {
  isRequestWithdrawn,
  getRequestTypeShortTitle,
  needHideActions,
  getStatusTitle,
  getTransPrefix
} from "helpers/RequestHelper";
import { formatDate } from "helpers/DateHelper";

const BatteryAllTableRow = ({
  tableData,
  isStaffUser,
  isVendorUser,
  openWithdrawConfirm,
  openUnwithdrawConfirm
}) => {
  if (!tableData) return null;

  if (_.isEmpty(tableData)) {
    return <NoTableData colSpan={isStaffUser ? 6 : 5} />;
  }

  const renderWithdrawLink = row => {
    if (!isStaffUser && !isVendorUser) return null;

    const withdrawned = isRequestWithdrawn(row.requeststatusid);
    if (withdrawned) return null;
    return (
      <LoaderButton
        bsStyle="danger"
        bsSize="xsmall"
        text={<IconLabel label="Withdraw" awesomeIcon="ban" />}
        onClick={() => openWithdrawConfirm(row)}
      />
    );
  };

  const renderWithdrawInfo = row => {
    const withdrawned = isRequestWithdrawn(row.requeststatusid);
    if (!withdrawned) return null;

    return (
      <div>
        <div>Request Withdrawn on {formatDate(row.withdrawnat)}</div>
        <div className="button-top-offset">
          <TooltipBase tipText={row.withdrawreason} tipPlacement="left">
            <LoaderButton
              bsStyle="info"
              bsSize="xsmall"
              text={
                <IconLabel label="Withdrawal Reason" awesomeIcon="question" />
              }
              onClick={() => null}
              className="icon-right-offset"
            />
          </TooltipBase>
          {isStaffUser && (
            <LoaderButton
              bsStyle="success"
              bsSize="xsmall"
              text={<IconLabel label="Unwithdraw" awesomeIcon="undo" />}
              onClick={() => openUnwithdrawConfirm(row)}
            />
          )}
        </div>
      </div>
    );
  };

  return _.map(tableData, row => {
    const tn = row.formatedtransactionnumber;
    const hasPendingDocs = row.ismanualpending > 0;
    // TODO: provide calc prop from backend:
    // const hasPendingReports = row.nreportspending > 0;
    const hasPendingReports = row.testingstatus === "Under Review"; // <-- repace with calc prop from API
    const hasUploadedInvoice = row.paid ? row.paid > 0 : false;
    const requestType = getRequestTypeShortTitle(row.requesttypeid);
    const statusTitle = getStatusTitle(row.requeststatusid);
    const showActions = isStaffUser && !needHideActions(tableData);
    return (
      <tr key={row.id}>
        <ActionIcons
          show={showActions}
          hasPendingDocs={hasPendingDocs}
          hasPendingReports={hasPendingReports}
          hasUploadedInvoice={hasUploadedInvoice}
        />
        <td>
          <ReqLink
            linkTitle={tn}
            requestId={row.id}
            subType={getTransPrefix(tn)}
          />
        </td>
        {isStaffUser && <td>{row.vendorname}</td>}
        <td>{row.vendorproductname}</td>
        <td>{requestType}</td>
        <td>{statusTitle}</td>
        <td>
          {renderWithdrawLink(row)}
          {renderWithdrawInfo(row)}
        </td>
      </tr>
    );
  });
};

BatteryAllTableRow.propTypes = {
  tableData: PropTypes.array,
  isStaffUser: PropTypes.bool,
  isVendorUser: PropTypes.bool,
  openWithdrawConfirm: PropTypes.func,
  openUnwithdrawConfirm: PropTypes.func
};

export default BatteryAllTableRow;
