import _ from "lodash";

import React, { useState } from "react";
import { connect } from "react-redux";

import { addLegacyUserViaNewUserId } from "actions/user";
import { showNotice } from "actions/notifier";

import { notifyMessages } from "helpers/NotifyHelper";

import { IconLabel, LoaderButton } from "ctia-ui";

import {
  formatErrorsForDisplay,
  prepareFormForUpdateUserRequest,
} from "containers/users/UserRequest/UserRequestForm/FormHelpers";

const NewUserRequestAcceptUserButton = ({
  // props
  formIsValid = false,
  onError = () => {},
  onSubmit = () => {},
  onUserAccepted = () => {},
  // Redux Stores
  companies,
  newUserRequest,
  // Redux Actions
  addLegacyUserViaNewUserId,
  showNotice
}) => {
  const [
    waitingForSubmissionResponse,
    setWaitingForSubmissionResponse
  ] = useState(false);

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isUndefined(formValues)) return null;

  const handleSubmit = () => {
    onSubmit();
    setWaitingForSubmissionResponse(true);

    // Handle submit
    const { program, updateFields, userId } =
      prepareFormForUpdateUserRequest({ companies, formValues });

    addLegacyUserViaNewUserId({
      args: updateFields,
      newUserId: userId,
      program
    })
    .then(() => {
      setWaitingForSubmissionResponse(false);
      showNotice(notifyMessages.userRequestAccepted);
      onUserAccepted(true);
    })
    .catch(error => {
      setWaitingForSubmissionResponse(false);

      const errorMessage = formatErrorsForDisplay(error);
      showNotice(errorMessage);
      onError(errorMessage.text);
    });
  };

  const canSubmit = !waitingForSubmissionResponse && formIsValid;

  return (
    <LoaderButton
      bsStyle="success"
      disabled={!canSubmit}
      isLoading={waitingForSubmissionResponse}
      onClick={handleSubmit}
      style={{ marginBottom: 15 }}
      text={<IconLabel label="Save" awesomeIcon="check" />}
    />
  );
};

function mapPropsToState({ companies, newUserRequest }) {
  return { companies, newUserRequest };
}

export default connect(mapPropsToState, {
  addLegacyUserViaNewUserId,
  showNotice
})(NewUserRequestAcceptUserButton);