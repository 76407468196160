import React from "react";
import { connect } from "react-redux";
// import { Switcher } from "ctia-ui";
import ViewToggle from "components/ViewToggle";
import { getRequestTypeTitle } from "helpers/RequestHelper";
// import { toggleEditMode } from "actions/user";
import "components/RequestPageTitle/style.css";
import CyberCertificateButton from "components/CyberCertificateButton"

const RequestPageTitle = ({ request }) => {
  const { tn, type, isAuditRequest } = request;
  const typeTitle = isAuditRequest
    ? "Site Audit Request"
    : getRequestTypeTitle(type);
  // const checked = user.togglerEnabled;
  const isCyber = tn.includes("CYBER");
  var route = "";
  var show = false;
  if(isCyber) {
    route = '/cyber/certificate/' + request.id;
    show = request.status === 2 ? true : false;
  }
  return (
    <div className="block-bottom-offset">
      <div className="page-title-button-container">
        <div className="page-title-container">
          <h1>{`${typeTitle} ${tn}`}</h1>
          <ViewToggle />
        </div>
        <CyberCertificateButton show={show} to={route} request={request}/>
      </div>
      {/* <Switcher
        show={user.isStaff}
        labelText="Enable Modify Mode"
        onChange={() => toggleEditMode(request, user)}
        disabled={!request.isValid}
        checked={checked}
        showWarning={!request.isValid}
      /> */}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(RequestPageTitle);
