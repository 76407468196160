import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import {
  fetchCountries,
  fetchCountryStates,
  setSelectedCountry
} from "actions/countries";

import "containers/ControlPanel/Payments/payments.css";

const SelectCountry = ({
  countries,
  fetchCountries,
  fetchCountryStates,
  setSelectedCountry,
  defaultValue = "none"
}) => {
  useEffect(() => {
    fetchCountries();

    // only USA and Canada
    if (
      defaultValue !== "none" &&
      (defaultValue === "US" || defaultValue === "CA")
    ) {
      fetchCountryStates(defaultValue);
      setSelectedCountry(defaultValue);
    } else {
      setSelectedCountry();
    }
  }, [fetchCountries, fetchCountryStates, defaultValue, setSelectedCountry]);

  const hasCountries = countries && countries.list;

  return (
    <Form.Control
      as="select"
      disabled={!hasCountries}
      onChange={e => {
        const countryCode = e.target.value;
        fetchCountryStates(countryCode);
        setSelectedCountry(countryCode);
      }}
    >
      <option value="none">--- Select One ---</option>
      {hasCountries &&
        countries.list.map(country => {
          return (
            <option
              key={country.code}
              value={country.code}
              selected={country.code === defaultValue}
            >
              {country.name}
            </option>
          );
        })}
    </Form.Control>
  );
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps, {
  fetchCountries,
  fetchCountryStates,
  setSelectedCountry
})(SelectCountry);
