import React, { useState } from "react";
import { Drawer } from "ctia-ui";
import Badge from "components/Badges/RecognitionBadge";
import RecognizedSubsystem from "containers/request/battery/PackCells/RecognizedSubsystem";

const ListOfLinksCRR = ({ list, emptyMsg = "Empty list" }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  if (!list || list.length === 0) return <span>{emptyMsg}</span>;

  return (
    <ul className="ctia-list form-field-list">
      {list.map((el, i) => {
        // if it's not a link
        if (el.asText) {
          return (
            <li key={`list-of-links-el-${i}`}>
              <span>
                {el.linkTitle}&nbsp;—&nbsp;
                <Badge />
              </span>
            </li>
          );
        }

        return (
          <li key={`list-of-links-el-${i}`} onClick={toggleDrawer}>
            <span onClick={toggleDrawer} className="cursor-pointer glow-link">
              {el.linkTitle}
            </span>
            <Drawer
              position="bottom"
              show={showDrawer}
              title="Recognized Subsystem Detail"
              toggleDrawer={toggleDrawer}
              content={
                <RecognizedSubsystem modelId={el.modelId} certPrefix="crr" />
              }
              height="auto"
            />
          </li>
        );
      })}
    </ul>
  );
};

export default ListOfLinksCRR;
