import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "ctia-react-bootstrap-v4";
import AscLogo from "components/AscLogo";
import AscInvoice from "components/ProgramSelector/ServiceCenter/AscInvoice";
import AscLogoPreview from "components/ProgramSelector/ServiceCenter/AscLogoPreview";
import AscAgreement from "components/ProgramSelector/ServiceCenter/AscAgreement";

const AscContainer = ({ user, downloadAgreementATL }) => {
  if (!user.isServiceCenter) return null;

  return (
    <Row>
      <Col md={6}>
        <h3>Downloadable Files</h3>
        <ul className="ctia-list">
          <AscLogoPreview />
          <AscAgreement />
          <AscInvoice />
        </ul>
      </Col>
      <Col md={6}>
        <h3>&nbsp;</h3>
        <AscLogo />
      </Col>
    </Row>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(AscContainer);
