import React from "react";
import { connect } from "react-redux";
import { Bubble } from "ctia-ui";
import FormElement from "components/FormElement";
import {
  formatTestPlansList,
  requestFields,
  getTestPlan
} from "helpers/OTATestPlanHelper";
import { getDeviceModelId } from "helpers/ModelHelper";
import { setValue } from "actions/request";
import { filterTestPlans } from "actions/ota";
import { fetchModelInterfaces, clearModelInterfaces } from "actions/model";

class SelectTestPlan extends React.Component {
  requestField = requestFields.otaPlan;

  markTestsInUse() {
    const { request, fetchModelInterfaces, clearModelInterfaces } = this.props;

    // allow to re-use existing model and reset all pre-selected test plans
    if (request.noTestPlansInUse) return clearModelInterfaces();

    const modelId = getDeviceModelId(request);

    // fetch interfaces already in use for existed model:
    return modelId ? fetchModelInterfaces(modelId) : clearModelInterfaces();
  }

  componentDidMount() {
    const { filterTestPlans, tests, request } = this.props;

    if (request.id > 0) {
      filterTestPlans(tests);
    }

    this.markTestsInUse();
  }

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
    this.props.clearModelInterfaces();
  }

  onChange = event => {
    const { setValue, ota } = this.props;
    const otaPlan = event.target.value;

    // reset otaLab value
    setValue(requestFields.otaLab, "");

    const testPlanInfo = getTestPlan(otaPlan, ota.interfaces);

    // set otaPlan value
    setValue(this.requestField, testPlanInfo);
  };

  render() {
    const { request, ota } = this.props;
    const { reqInterfaces, inUse } = ota;

    if (!reqInterfaces || !reqInterfaces.length) return null;

    if (ota.allInUse) {
      return (
        <Bubble type="danger">
          All tests have already been submitted for this model name/number.
          Please enter a unique model name/number.
        </Bubble>
      );
    }

    // TODO: replace this tricky logic when server will be ready
    const requestOTAInterface = request[this.requestField];
    const selectedInterface = requestOTAInterface
      ? requestOTAInterface.value
      : 0;

    return (
      <FormElement
        type="select"
        id={requestFields.otaPlan}
        label="Test Plan"
        value={selectedInterface}
        onChange={this.onChange}
        options={formatTestPlansList(reqInterfaces, inUse)}
        disabledOptionLabel="in use"
      />
    );
  }
}

function mapStateToProps({ request, ota, tests }) {
  return { request, ota, tests };
}

export default connect(mapStateToProps, {
  setValue,
  filterTestPlans,
  fetchModelInterfaces,
  clearModelInterfaces
})(SelectTestPlan);
