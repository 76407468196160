import _ from "lodash";
import { types } from "helpers/BatteryCertHelper";
import { bscAndSarOptions } from "containers/request/RequestList/statusOptions";
import { requestStatuses } from "helpers/RequestHelper";
import { formatDate } from "helpers/DateHelper";

export const filterModelFields = models => {
  if (!models) return [];

  return models.map(model => {
    const { capacity, capacityrateid, voltage, voltagerateid } = model;
    return {
      value: model.id,
      title: String(model.modelnumber),
      devicemodelbatterycomponent: model.devicemodelbatterycomponent,
      capacity: [capacityrateid, capacity],
      voltage: [voltagerateid, voltage]
    };
  });
};

export const filterModelFieldsBLC = models => {
  if (!models) return [];

  return models.map(model => {
    const modelName = model.vendorproductname || model.modelnumber;

    return {
      value: model.devicemodelid || model.id,
      title: String(modelName),
      devicetype: model.devicetype
    };
  });
};

export const findExistedModelByName = (modelName, modelList) => {
  // no legacy models
  if (_.isEmpty(modelList)) return null;

  // first need to lowercase all model names
  const lowercasedList = modelList.map(model => {
    return { ...model, titleKey: _.lowerCase(model.title), value: model.value, devicetype: model.devicetype };
  });

  // then apply lowerCase to passed modelName too
  const lcModelName = _.lowerCase(modelName);

  return _.find(lowercasedList, ["titleKey", lcModelName]);
};

export const getDeviceModelId = request => {
  if (!request) return null;

  const { existedModelId, model } = request;

  return existedModelId || model.id || null;
};

export const getCertifiedStatusFilter = certTypes => {
  let statuses = _.cloneDeep(bscAndSarOptions);

  const isBSC = _.includes(certTypes, types.BSC);
  const isACR = _.includes(certTypes, types.ACR);
  const isSAR = _.includes(certTypes, types.SAR);

  if (isBSC || isACR) {
    _.set(statuses, "2.title", "Certified");
    _.set(statuses, "8.title", "Certification Withdrawn");
  } else if (isSAR) {
    _.set(statuses, "2.title", "Active");
  } else {
    _.set(statuses, "2.title", "Recognized");
  }

  return statuses;
};

export const getDeviceRowStatus = (certType, statusid = 2, delistedat = "") => {
  if (certType === types.ACR) {
    return statusid === requestStatuses.approved ? "Certified" : "Withdrawn";
  } else if (certType === types.SAR) {
    return statusid === requestStatuses.approved ? "Active" : "Withdrawn";
  } else {
    const listStatus =
      delistedat === "" ? "Listed" : `De-listed on ${formatDate(delistedat)}`;
    const withdrawnStatus =
      statusid === requestStatuses.approved ? "" : "Recognition Withdrawn";
    return [withdrawnStatus, listStatus].filter(Boolean).join(", ");
  }
};

export const isAutocompleteModelEditable = (request, user) => {
  if (request && request.model && request.model.isModelNumberLocked === 1)
    return false;
  return user.canEditDeviceInfo;
};
