import React from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from "react-bootstrap";
import { getRequestTypeTitle } from "helpers/RequestHelper";
import { renderReadonlyHorizontalForm } from "helpers/FormHelper";
import reqTypeTitles from "dictionaries/ReqTypesTitles";

export const getPrerenderValues = (type, request) => {
  switch (type) {
    case "approve":
      return setApproveVals(request);
    case "approveSAR":
      return setApproveValsSAR(request);
    case "withdraw":
      return setWithdrawVals(request);
    case "withdrawReason":
      return setWithdrawReasonVals(request);
    case "withdrawReasonOta":
      return setWithdrawReasonValsOta(request);
    case "withdrawReasonSAR":
      return setWithdrawReasonBatterySarVals(request);
    case "unwithdraw":
      return setUnwithdrawVals(request);
    case "unwithdrawSAR":
      return setUnwithdrawValsSAR(request);
    default:
      return null;
  }
};

const renderBody = (request, actionString) => {
  const {
    formatedtransactionnumber: tn,
    modelnumber,
    vendorproductname
  } = request;
  const name = modelnumber || vendorproductname;
  return (
    <div>
      <p>
        Are you sure you want to {actionString} request {tn}?
      </p>
      <p>
        Model Name/Number: <b>{name}</b>
      </p>
    </div>
  );
};

const renderBodyList = (request, actionString, formFields) => {
  const { formatedtransactionnumber: tn } = request;
  return (
    <div>
      <p>
        Are you sure you want to {actionString} request {tn}?
      </p>
      {renderReadonlyHorizontalForm(formFields)}
    </div>
  );
};

const getTn = request => request.formatedtransactionnumber;

const setApproveVals = request => {
  const tn = getTn(request);
  const body = renderBody.bind(this, request, "approve");

  return {
    body,
    button: "success",
    buttonText: "Yes, Approve.",
    buttonLoadingText: "Approving...",
    title: `Request ${tn} Approval`
  };
};

const setApproveValsSAR = request => {
  const tn = getTn(request);
  const auditFields = getAuditFields(request);
  const body = renderBodyList.bind(this, request, "approve", auditFields);

  return {
    body,
    button: "success",
    buttonText: "Yes, Approve.",
    buttonLoadingText: "Approving...",
    title: `Request ${tn} Approval`
  };
};

const setWithdrawVals = request => {
  const tn = getTn(request);
  const body = renderBody.bind(this, request, "withdraw");

  return {
    body,
    button: "danger",
    buttonText: "Yes, Withdraw.",
    buttonLoadingText: "Withdrawing...",
    title: `Request ${tn} Withdrawal`
  };
};

const setUnwithdrawVals = request => {
  const tn = getTn(request);
  const body = renderBody.bind(this, request, "unwithdraw");

  return {
    body,
    button: "success",
    buttonText: "Yes, Unwithdraw.",
    buttonLoadingText: "Unwithdrawing...",
    title: `Request ${tn} Unwithdrawal`
  };
};

const getAuditFields = request => {
  const { manufsite, requesttypeid, ieeetypeid } = request;
  const typeWithIeee = `IEEE ${ieeetypeid} — ${reqTypeTitles[requesttypeid]}`;

  return [
    {
      title: "Request Type",
      value: typeWithIeee
    },
    {
      title: "Manufacturing Site",
      value: manufsite.name
    }
  ];
};

const setUnwithdrawValsSAR = request => {
  const tn = getTn(request);
  const auditFields = getAuditFields(request);
  const body = renderBodyList.bind(this, request, "unwithdraw", auditFields);

  return {
    body,
    button: "success",
    buttonText: "Yes, Unwithdraw.",
    buttonLoadingText: "Unwithdrawing...",
    title: `Request ${tn} Unwithdrawal`
  };
};

const renderReasonBody = (isFormValid, onValidate, formFields) => {
  const style = { height: 50 }; // Must be hardcoded
  const onChange = event => onValidate(event.target.value);
  return (
    <div>
      {renderReadonlyHorizontalForm(formFields)}
      <hr />
      <FormGroup validationState={getValidationState(isFormValid)}>
        <ControlLabel>
          Please enter the reason for withdrawing this request:
        </ControlLabel>
        <FormControl
          componentClass="textarea"
          id="reasonRef"
          style={style}
          onChange={onChange}
        />
        {isFormValid ? null : (
          <HelpBlock>
            The reason must be at least 5 characters or longer.
          </HelpBlock>
        )}
      </FormGroup>
    </div>
  );
};

const setWithdrawReasonVals = request => {
  const {
    formatedtransactionnumber,
    modelnumber,
    name,
    vendorname,
    requesttypeid,
    vendorproductname
  } = request;

  // TODO: check if it's enough for battery fields
  const formFields = [
    {
      title: "Request Type",
      value: getRequestTypeTitle(requesttypeid)
    },
    {
      title: "Vendor",
      value: name || vendorname
    },
    {
      title: "Model Name/Number",
      value: modelnumber || vendorproductname
    }
  ];

  const setReasonBody = (isFormValid, onValidate) =>
    renderReasonBody(isFormValid, onValidate, formFields);

  return {
    title: `Request ${formatedtransactionnumber} Withdrawal`,
    body: setReasonBody,
    button: "danger",
    buttonText: "Withdraw",
    buttonLoadingText: "Withdrawing..."
  };
};

const setWithdrawReasonValsOta = request => {
  const { formatedtransactionnumber, modelnumber, name } = request;

  const formFields = [
    {
      title: "Vendor",
      value: name
    },
    {
      title: "Model Name/Number",
      value: modelnumber
    }
  ];

  const setReasonBody = (isFormValid, onValidate) =>
    renderReasonBody(isFormValid, onValidate, formFields);

  return {
    title: `Request ${formatedtransactionnumber} Withdrawal`,
    body: setReasonBody,
    button: "danger",
    buttonText: "Withdraw",
    buttonLoadingText: "Withdrawing..."
  };
};

const setWithdrawReasonBatterySarVals = request => {
  const auditFields = getAuditFields(request);
  const setReasonBody = (isFormValid, onValidate) =>
    renderReasonBody(isFormValid, onValidate, auditFields);

  return {
    title: `Audit Request ${request.formatedtransactionnumber} Withdrawal`,
    body: setReasonBody,
    button: "danger",
    buttonText: "Withdraw",
    buttonLoadingText: "Withdrawing..."
  };
};

const getValidationState = isValid => {
  return isValid ? null : "error";
};
