import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import SaveButton from "components/form/buttons/SaveButton";
import { FormGroup, FormControl, HelpBlock } from "react-bootstrap";

const siteExistedMsg = "Manufacturing site name already exists";

const DrawerSiteForm = ({
  user,
  sites,
  targetId = null,
  toggleDrawer,
  actionOnSubmit
}) => {
  const initialState = targetId
    ? _.find(sites.nonSarSites, ["value", targetId]).label
    : "";
  const [siteName, setSiteName] = useState(initialState);
  const [isLoading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [validationState, setValidationState] = useState(null);

  const toggleLoader = () => {
    setLoading(!isLoading);
  };

  const validateSite = site => {
    if (site.length === 0) {
      setValidationState(null);
      setValidationMessage("");
    } else {
      if (
        _.find(sites.nonSarSites, s => {
          return s.title.toUpperCase() === site.toUpperCase().trim();
        })
      ) {
        setValidationState("error");
        setValidationMessage(siteExistedMsg);
      } else {
        setValidationState(null);
        setValidationMessage("");
      }
    }
  };

  const onChange = e => {
    setSiteName(e.target.value);
    validateSite(e.target.value);
  };

  const onSubmit = e => {
    toggleLoader();
    e.preventDefault();

    actionOnSubmit(siteName, user.companyid, targetId)
      .then(() => {
        toggleLoader();
        setSiteName("");
        toggleDrawer();
      })
      .catch(() => toggleLoader());
  };

  return (
    <FormGroup validationState={validationState}>
      <FormControl
        id="siteName"
        type="text"
        onChange={onChange}
        value={siteName}
      />
      <FormControl.Feedback />
      <HelpBlock>{validationMessage}</HelpBlock>
      <SaveButton
        onClick={onSubmit}
        disabled={siteName.length === 0 || validationState}
        isLoading={isLoading}
        loadingText="Saving"
      />
    </FormGroup>
  );
};

function mapStateToProps({ user, sites }) {
  return { user, sites };
}

export default connect(mapStateToProps)(DrawerSiteForm);
