import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { InlinePreloader } from "ctia-ui";
import { Table } from "ctia-react-bootstrap-v4";
import { fetchVersionsModel, fetchRecognizedVersions } from "actions/suppliers";
import VersionList from "containers/request/battery/PackCells/RecognizedVersionsList";
import InfoBarRecognizedDetails from "containers/request/InfoBar/battery/InfoBarRecognizedDetails";

const RecognizedSubsystem = ({
  modelId,
  fetchVersionsModel,
  fetchRecognizedVersions,
  certPrefix
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchVersionsModel(modelId, certPrefix)
      .then(() => fetchRecognizedVersions(modelId, certPrefix))
      .then(() => setLoading(false));
  }, [fetchVersionsModel, fetchRecognizedVersions, modelId, certPrefix]);

  if (!modelId) return null;

  const renderTable = () => {
    return (
      <div className="block-bottom-offset">
        <InfoBarRecognizedDetails show={certPrefix !== "crr"} />
        <span>Below are all of the recognized versions of this subsystem.</span>
        <Table responsive striped hover size="sm" className="ctia-table">
          <thead>
            <tr>
              <th>Request#</th>
              <th>Authorization Type</th>
              <th>Revision</th>
              <th>Manufacturing Site(s)</th>
              <th>CRSL Version</th>
              <th>Date Recognized</th>
              {/* <th>Status</th> */}
            </tr>
          </thead>
          <tbody>
            <VersionList certPrefix={certPrefix} />
          </tbody>
        </Table>
      </div>
    );
  };

  return loading ? <InlinePreloader /> : renderTable();
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  fetchVersionsModel,
  fetchRecognizedVersions
})(RecognizedSubsystem);
