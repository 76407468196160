import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import preloader from "reducers/preloader";
import user from "reducers/user";
import requests from "reducers/requests";
import request from "reducers/request";
import wizard from "reducers/wizard";
import model from "reducers/model";
import devices from "reducers/devices";
import labs from "reducers/labs";
import uploads from "reducers/uploads";
import agreement from "reducers/agreement";
import notifier from "reducers/notifier";
import tests from "reducers/tests";
import system from "reducers/system";
import comments from "reducers/comments";
import badges from "reducers/badges";
import app from "reducers/app";
import operators from "reducers/operators";
import countries from "reducers/countries";
import graph from "reducers/graph";
import users from "reducers/users";
import suppliers from "reducers/suppliers";
import ota from "reducers/ota";
import sites from "reducers/sites";
import addresses from "reducers/addresses";
import budgetReport from "reducers/budgetReport";
import certifiedPendingReport from "reducers/Reports/certifiedPendingReport";
import dhr from "reducers/dhr";
import catl from "reducers/catl";
import org from "reducers/org";
import catlInvoice from "reducers/catlInvoice";
import asc from "reducers/asc";
import ascInvoice from "reducers/ascInvoice";
import newUserRequest from "reducers/newUserRequest";
import companies from "reducers/companies";
import users2labs from "reducers/users2labs";

export default combineReducers({
  user,
  wizard,
  request,
  requests,
  preloader,
  model,
  devices,
  labs,
  uploads,
  agreement,
  notifier,
  tests,
  system,
  comments,
  badges,
  app,
  operators,
  countries,
  graph,
  users,
  suppliers,
  ota,
  sites,
  addresses,
  budgetReport,
  certifiedPendingReport,
  dhr,
  catl,
  org,
  catlInvoice,
  asc,
  ascInvoice,
  newUserRequest,
  companies,
  users2labs,
  form: formReducer
});
