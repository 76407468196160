import React from "react";
import { connect } from "react-redux";
import InfoBar from "containers/request/InfoBar/blc/InfoBarBLC";

const BLCInfoBarContainer = ({ request, wizard }) => {
  if (!request) return null;
  if (request.formSaved) return null;

  // ota submission requires to not render it on 1st step
  if (wizard && wizard.step === 1) return null;

  return <InfoBar />;
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(BLCInfoBarContainer);
