import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import LabResultList from "containers/testResult/LabResultList";
import LabResultListOta from "containers/testResult/LabResultListOta";
import { Bubble } from "ctia-ui";
import {
  clearRequests,
  fetchApprovedRequestsForTesting
} from "actions/requests";
import { startPreloader, finishPreloader } from "actions/preloader";

class TestResultsList extends Component {
  componentDidMount() {
    const {
      startPreloader,
      finishPreloader,
      clearRequests,
      fetchApprovedRequestsForTesting
    } = this.props;

    startPreloader();
    clearRequests();
    fetchApprovedRequestsForTesting().then(finishPreloader);
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  selectListComponent() {
    const { isOTACert } = this.props.app;

    if (isOTACert) return LabResultListOta;

    return LabResultList;
  }

  render() {
    const { requests } = this.props;

    if (_.isNull(requests)) {
      return null;
    }

    const ListComponent = this.selectListComponent();

    const renderResults = () => {
      if (_.isEmpty(requests.list))
        return (
          <Bubble type="warning">
            No requests waiting for entering results yet.
          </Bubble>
        );
      return <ListComponent list={requests.list} />;
    };

    return (
      <div>
        <h1 className="block-bottom-offset">Test Results</h1>
        {renderResults()}
      </div>
    );
  }
}

function mapStateToProps({ app, requests }) {
  return { app, requests };
}

export default connect(mapStateToProps, {
  fetchApprovedRequestsForTesting,
  clearRequests,
  startPreloader,
  finishPreloader
})(TestResultsList);
