import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoaderButton, IconLabel } from "ctia-ui";

const LoginAsPTCRB = ({ show, userData, loginAs, getUserInfo, user, app }) => {
  if (!show) return null;

  const { id, role, ctiaid } = userData;

  // oh, it's me!
  if (user && user.id === id) return null;

  // exclude Suppliers and Service Center Users
  if (role === "SERVICECENTER" || role === "SUPPLIER") return null;

  const link = `${app.ptcrbURL}/ctiastaff/user/admin_redirect.cfm?userID=${ctiaid}`;

  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <LoaderButton
        bsSize="xsmall"
        text={<IconLabel label="Log In As (PTCRB)" awesomeIcon="user" />}
        onClick={() => {}}
      />
    </a>
  );
};

LoginAsPTCRB.propTypes = {
  show: PropTypes.bool,
  userData: PropTypes.object
};

function mapStateToProps({ user, app }) {
  return { user, app };
}

export default connect(mapStateToProps)(LoginAsPTCRB);
