import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { SummaryBlock } from "ctia-ui";
import certTypesEnum from "dictionaries/CertTypesEnum";
import { fetchSummary, switchView } from "actions/reports/budgetReport";

const SummaryContainer = ({ switchView, budgetReport, fetchSummary }) => {
  const [payload, setPayload] = useState({
    meta: null,
    data: null,
    loading: false
  });

  useEffect(() => {
    setPayload(payload => ({
      meta: payload.meta && {},
      data: payload.data,
      loading: true
    }));

    const { dateFrom, dateTo } = budgetReport.settings;

    fetchSummary(dateFrom, dateTo).then(res => {
      setPayload({ meta: res.meta && {}, data: res.data, loading: false });
    });
  }, [budgetReport.activeView, budgetReport.settings, fetchSummary]);

  return payload.data === null || payload.loading ? (
    "Loading..."
  ) : (
    <div>
      {Object.keys(payload.data).map((keyName, i) => {
        const {
          certified,
          certified_percentage,
          pending,
          pending_percentage
        } = payload.data[keyName];
        const { title } = certTypesEnum[keyName];

        return (
          <SummaryBlock
            title={title}
            certType={keyName}
            certifiedValue={certified}
            certifiedPercentage={certified_percentage}
            pendingValue={pending}
            pendingPercentage={pending_percentage}
            showPendingPercentage={false}
            handleDetails={switchView}
            key={i}
          />
        );
      })}
    </div>
  );
};

function mapStateToProps({ budgetReport }) {
  return { budgetReport };
}

export default connect(mapStateToProps, { switchView, fetchSummary })(
  SummaryContainer
);
