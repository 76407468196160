import {formatDate} from "./DateHelper";
import _ from "lodash";

export const searchTextInMoment = (date, searchText) => {
  if (date === null) return false;
  else if (typeof(date) === "object" && date._isAMomentObject) {
    const dateText = formatDate(date).toLowerCase();
    const search = searchText.toLowerCase();

    if (_.includes(dateText, search)) {
      return true;
    } else if (_.includes(date._i.toLowerCase(), search)) {
      return true;
    }
  }

  return false;
}