import _ from "lodash";
import React from "react";
import {
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
} from "react-bootstrap";

export const emptyMsg = "The field can not be empty";
export const modelNameExists = "Model name/number already exists";
export const mustBeNumberMsg = "The field must be a numeric value";

/**
 * Renders horizontal form with passed readonly fields (key-value pairs).
 *
 * @param {array} values Fields list to be rendered
 * @returns JSX layout
 */
export const renderReadonlyHorizontalForm = values => {
  if (!values || values.length === 0) return null;

  return (
    <Form horizontal>
      {values.map((elem, i) => {
        const id = String(i);
        return (
          <FormGroup controlId={id} key={i}>
            <Col componentClass={ControlLabel} sm={4}>
              {elem.title}:
            </Col>
            <Col sm={8}>
              <FormControl.Static>{elem.value}</FormControl.Static>
            </Col>
          </FormGroup>
        );
      })}
    </Form>
  );
};

/**
 * Prepares options to be rendered as a selector for legacy models list.
 *
 * @param {array} list PTCRB legacy models list
 * @param {object} legacyModel Object with model info
 * @returns array
 */
export const getPTCRBModelsOptions = (list, legacyModel) => {
  if (list && list.length) {
    const options = _.map(list, option => {
      const { id, modelnumber } = option;
      return {
        title: modelnumber,
        value: id
      };
    });

    // add legacy model if exists
    if (legacyModel) {
      options.unshift({ ...legacyModel });
    }

    options.unshift({ title: "--- Select one ---", value: 0 });
    return options;
  }
  return [];
};

/**
 * Renders labeled form field.
 *
 * @param {object} Object with field options
 * @returns JSX layout
 */
export const renderField = ({
  input,
  label,
  type,
  autoFocus,
  meta: { touched, error }
}) => (
  <FormGroup controlId={input.name} bsSize="large">
    <ControlLabel>{label}</ControlLabel>
    <FormControl {...input} type={type} autoFocus={autoFocus} />
  </FormGroup>
);

/**
 * Decides whenever component should be updated.
 *
 * Uses user's flag that shows if user able to edit current request.
 *
 * @param {object} props Component properties object
 * @returns boolean
 */
export const shouldUpdate = props => {
  if (!props || !props.user) return false;
  return _.isUndefined(props.user.canEditRequest) ? false : true;
};

export const defineEcoModelFieldType = (isEditable, request) => {
  if (!isEditable) return "";
  if (!request.id) return "comboBox";

  return "text";
};

export const defineDhrModelFieldType = (isEditable, request) => {
  if (!isEditable) return "";
  if (!request.id) return "comboBox";

  return "text";
};

export const defineOtaModelName = (isEditable, request) => {
  const { existedModel, modelName } = request;

  if (!isEditable) return existedModel || modelName;

  return request.id ? modelName : existedModel;
};

export const defineDhrModelName = (isEditable, request) => {
  const { existedModel, existedModelId, modelName } = request;

  if (request.id) return request.model.modelnumber;

  if (!isEditable) return existedModelId || modelName;

  return existedModel;
};

export const defineBatteryModelName = (isEditable, request) => {
  const { existedModel, modelName } = request;

  return !request.id ? existedModel : modelName;
};

export const defineModelFieldId = request => {
  return request.id ? "modelName" : "existedModel";
};

export const defineEcoModelName = (isEditable, request) => {
  const { existedModel, existedModelId, modelName } = request;

  if(isEditable) return modelName;

  if (request.id) return request.model.modelnumber;

  if (!isEditable) return existedModelId || modelName;

  return existedModel;
};