import React, { useEffect } from "react";
import { IconLabel } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeletedUsersRow from "containers/ControlPanel/Users/DeletedUsersRow";
import SortableTable from "containers/SortableTable";
import DeletedUsersSelector from "containers/ControlPanel/Users/DeletedUsersSelector";
import DeletedUsersSearch from "containers/ControlPanel/Users/DeletedUsersSearch";
import GoBackButton from "components/form/buttons/GoBackButton";
import { unmountView } from "actions/users";
import pluralize from "pluralize";

const DeletedUsers = ({ users, unmountView }) => {
  useEffect(() => {
    return () => {
      // clean up to get rid of issues
      unmountView();
    };
  }, [unmountView]);

  const headerFields = [
    { title: "Name", fieldname: "lastname" },
    { title: "Title", fieldname: "title" },
    { title: "Lab/Company", fieldname: "companyname" },
    { title: "E-mail", fieldname: "email" },
    { title: "Last Login", fieldname: "deletedat", dataType: "date" },
    { title: "", fieldname: "" }
  ];

  const handleSort = (item, sortField) => {
    if (sortField && sortField === "active") {
      return !item.active;
    }

    return String(item[sortField]).trim().toLowerCase();
  };

  const renderList = () => {
    if (!users || !users.deletedUsers) {
      return "Loading...";
    }

    return (
      <SortableTable
        list={users.deletedUsers}
        header={headerFields}
        colNum={headerFields.length}
        handleSort={handleSort}
        rowRenderer={DeletedUsersRow}
      />
    );
  };

  const renderCounter = () => {
    if (!users || !users.deletedUsers || !users.deletedUsers.length)
      return null;

    const pluralizedLabel = pluralize(
      "deleted user",
      users.deletedUsers.length,
      true
    );

    return <IconLabel awesomeIcon="user" label={pluralizedLabel} />;
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div style={{ display: "flex" }} className="h1-div">
            <div>Deleted Users</div>
          </div>
          <GoBackButton dest="/admin/cp/users/" label="Return to User Admin" />
          <DeletedUsersSearch />
          <p>
            Below is a list of the users that currently have deleted status in
            the system.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={9}>{renderCounter()}</Col>
        <Col xs={3} className="text-right">
          <DeletedUsersSelector />
        </Col>
      </Row>
      {renderList()}
    </div>
  );
};

function mapStateToProps({ users }) {
  return { users };
}

export default connect(mapStateToProps, { unmountView })(DeletedUsers);
