import React from "react";
import { WithModal } from "ctia-ui";
import AgreementModalATL from "containers/AuthorizedLabs/AgreementModalATL";

class AgreementSignATL extends WithModal {
  render() {
    const onClickHandler = this.props.agreementHandler || this.handleModal;
    return (
      <>
        <span className="glow-link cursor-pointer" onClick={onClickHandler}>
          Annual License and Service Agreement
        </span>
        <AgreementModalATL
          show={this.state.showModal}
          modalHandler={this.handleModal}
        />
      </>
    );
  }
}

export default AgreementSignATL;
