import React from "react";
import "./canvas.css";

const AscLogoCanvas = ({ facilityCode }) => {
  return (
    <div className="canvasLogoContainer canvasLogoContainer__ASC">
      <img
        src="/assets/CTIA_ASC_Logo_New.png"
        alt="Service Center Logo"
        height={100}
      />
      <div className="canvasText canvasText_ASC">
        Facility Code: <b>{facilityCode}</b>
      </div>
    </div>
  );
};

export default AscLogoCanvas;
