import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PocSelector from "containers/request/Initial/Steps/Vendor/PocSelector";
import PocAdd from "containers/request/Initial/Steps/Vendor/PocAdd";
import { fetchPocs } from "actions/requestCyber";

class PointOfContact extends Component {
  state = { isNewRequest: true, showSelector: true };

  componentDidMount() {
    const isNewRequest = _.isUndefined(this.props.request.id);
    this.setState({ isNewRequest });
  }

  componentWillReceiveProps(nextProps) {
    const { pocs, pocId } = this.props.request;
    const hasNoPocs = _.isEmpty(pocs);

    // update state only one time
    if (hasNoPocs && nextProps.request.pocs) {
      const pocListExists = !_.isEmpty(nextProps.request.pocs);
      this.setState({ showSelector: pocListExists });
    }

    // fix for return to prev step issue
    if (
      _.isEmpty(String(pocId)) &&
      !_.isEmpty(String(nextProps.request.pocId))
    ) {
      const { fetchPocs, user } = this.props;
      fetchPocs(user.requestOwnerCompanyId).then(this.returnCallback);
    }
  }

  returnCallback = () => {
    // set state only in case if we editing selected request
    if (_.isNull(this.props.wizard)) {
      this.setState({ showSelector: true });
    }
  };

  toggleSelector = value => {
    this.setState({ showSelector: value });
  };

  render() {
    if (!this.props.show) return null;

    const { showSelector, isNewRequest } = this.state;

    return (
      <div>
        <PocAdd
          show={!showSelector}
          isNew={isNewRequest}
          toggleSelector={this.toggleSelector}
        />
        <PocSelector
          show={showSelector}
          isNew={isNewRequest}
          toggleSelector={this.toggleSelector}
        />
      </div>
    );
  }
}

PointOfContact.propTypes = {
  show: PropTypes.bool
};

PointOfContact.defaultProps = {
  show: true
};

function mapStateToProps({ request, user, wizard }) {
  return { request, user, wizard };
}

export default connect(mapStateToProps, { fetchPocs })(PointOfContact);
