import * as actions from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case actions.SIGN_AGREEMENT:
      return { ...state, agreementAccepted: action.payload };
    case actions.GET_AGREEMENT_INFO:
      const { payload } = action;
      return payload ? { ...state, ...payload } : state;
    case actions.CLEAN_AGREEMENT_INFO:
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
