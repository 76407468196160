import _ from "lodash";
import axios from "axios";
import { restClientGeneral, restClientAdmin } from "libs/restClient";
import { apiUrl } from "dictionaries/Env";
import types from "dictionaries/ReqTypes";
import * as actions from "actions/types";
import * as ota from "actions/requestOta";
import * as req from "actions/request";
import { setRequestPermissions } from "actions/battery/blcRequest";
import { setEditManufsitePermissions } from "actions/battery/sar";
import { notifyMessages } from "helpers/NotifyHelper";
import { sayGoodbye, getUserListPostfix } from "helpers/UsersHelper";
import {
  makeSimpleArgs,
  getPageArgsAsQueryString,
  prepareNewUserArgs,
  prepareEditUserArgs
} from "helpers/ArgsHelper";
import * as call from "helpers/ActionHelper";

export const signup =
  (signupFields, successCallback, errorCallback) => async () => {
    try {
      const url = "/legacy/ctianewusers";
      const body = makeSimpleArgs(signupFields);
      const response = await restClientGeneral.post(url, body);
      const payload = response.data.data.id;

      successCallback(payload);

      return response;
    } catch (error) {
      errorCallback(error.response.data.errors);

      return error;
    }
  };

export const signin = (formProps, callback, errCallback) => async dispatch => {
  try {
    const { username, password } = formProps;
    const url = `${apiUrl}/login`;
    const response = await axios({
      method: "get",
      url,
      auth: {
        username,
        password
      }
    });

    dispatch({
      type: actions.SAVE_USER_TOKEN,
      payload: response.data.data,
      meta: response.data.meta
    });
    dispatch({ type: actions.AUTH_ERROR, payload: "" });

    callback();
  } catch (e) {
    dispatch({
      type: actions.AUTH_ERROR,
      payload: e.response.data.exception
    });

    errCallback();
  }
};

export const logout =
  ({ force }) =>
  async dispatch => {
    dispatch({
      type: actions.LOGOUT,
      payload: null
    });

    if (force) {
      dispatch(call.showNotice(notifyMessages.sessionTimeout));
    }
  };

export const forgotPassword = (email, callback) => async dispatch => {
  try {
    const url = `/resetpassword?useremail=${email}`;
    const response = await restClientGeneral.post(url);

    callback(true);

    return response;
  } catch (error) {
    callback(false);

    return error;
  }
};

export const fetchUserProfile =
  ({ userId, callback, errorCallback }) =>
  async dispatch => {
    try {
      const response = await dispatch(getUser(userId));

      dispatch({ type: actions.GET_USER_PROFILE, payload: response.data.data });

      if (callback) {
        callback(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: actions.AUTH_ERROR,
        payload: "Unable to fetch user profile"
      });

      if (errorCallback) {
        errorCallback(error);
      }
    }
  };

export const getUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}?include=ptcrbUserDocGroupsEmailNotifications,legacyoperator`;
    return await restClientGeneral.get(url);
  } catch (error) {
    return error;
  }
};

export const updateUserProfile =
  ({ userId, params, callback, errorCallback }) =>
  async dispatch => {
    try {
      const bodyParams = params;

      const url = `/legacy/users/${userId}`;
      const body = makeSimpleArgs(bodyParams);
      const response = await restClientGeneral.put(url, body);

      dispatch({
        type: actions.UPDATE_USER_PROFILE,
        payload: response.data.data
      });
      if (callback) {
        callback(response.data.data);
      }
    } catch (error) {
      dispatch({
        type: actions.AUTH_ERROR,
        payload: "Failed to update user profile"
      });

      if (errorCallback) {
        errorCallback(error);
      }
    };
  };

export const refreshToken = callback => async dispatch => {
  try {
    const response = await restClientGeneral.get("/refresh");
    dispatch({ type: actions.SAVE_USER_TOKEN, payload: response.data.data });
    callback();
  } catch (e) {
    // remove client token and redirect to home
    sayGoodbye();

    // show notice
    dispatch(call.showNotice(notifyMessages.authError));
  }
};

export const agreeTerms = agreed => async dispatch => {
  try {
    const url = `/agreed?answer=${agreed}`;
    const response = await restClientGeneral.put(url);

    dispatch({ type: actions.AGREE_TERMS, payload: response.data.data });
  } catch (e) {
    // dispatch({ type: actions.AUTH_ERROR, payload: 'Invalid login credentials' });
  }
};

export function toggleEditMode(request, user) {
  const updatedUser = { ...user, togglerEnabled: !user.togglerEnabled };

  // OTA way
  if (Number(request.type) === types.ota) {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_OTA_REQUEST_EDIT_MODE });
      dispatch(ota.setOtaRequestPermissions(request, updatedUser));
    };
  }

  // Battery way
  if (request.isBattery) {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_EDIT_MODE });
      if (request.isSAR)
        dispatch(setEditManufsitePermissions(updatedUser, request));
      else dispatch(req.setBatteryRequestPermissions(request, updatedUser));
    };
  }

  // BLC way
  if (request.isBLC) {
    return dispatch => {
      dispatch({ type: actions.TOGGLE_EDIT_MODE });
      dispatch(setRequestPermissions(request, updatedUser));
    };
  }

  // Cyber way
  return dispatch => {
    dispatch({
      type: actions.TOGGLE_REQUEST_EDIT_MODE
    });
  };
}

export const getUserInfo = () => async dispatch => {
  try {
    const response = await restClientGeneral.get("/me");
    dispatch({ type: actions.GET_USER_INFO, payload: response.data.data });
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.authError));
  }
};

export function updateUserLocation(location = "/") {
  return {
    type: actions.UPDATE_USER_LOCATION,
    payload: location
  };
}

export const fetchUsers =
  (page, size, userType, activeOnly) => async dispatch => {
    // start preloader
    dispatch(call.startPreloader());

    try {
      const userRole = getUserListPostfix(userType);
      const fields =
        "firstname, lastname, role, companyname, email, lastloginat, active, deletedat, id, ctiaid, locked";
      const orders = "companyname ASC, firstname ASC, lastname ASC";

      let selectClause = `&select=${fields}`;
      let orderClause = `&orderBy=${orders}`;

      // filter out all deleted users by default since they only displayed at Deleted Users view
      let whereClause = "&where=deletedat IS NULL";

      if (userRole) {
        whereClause += ` AND role='${userRole}'`;
      }

      whereClause += activeOnly ? " AND active=1" : " AND active IN (-1,0,1)";

      const pagerArgs = getPageArgsAsQueryString(page, size);
      const url = `/users?${pagerArgs}${orderClause}${whereClause}${selectClause}`;
      const response = await restClientAdmin.get(url);

      dispatch({
        type: actions.FETCH_USERS,
        payload: response.data
      });
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    }

    // stop preloader
    dispatch(call.stopPreloader());
  };

export const fetchCompanyUsers =
  (companyId, withPreloader) => async dispatch => {
    // start preloader
    if (withPreloader) dispatch(call.startPreloader());

    try {
      const url = `/users?page=0&showDisabled=true&where=companyid=${companyId} AND deletedat IS NULL&orderby=lastname ASC, firstname ASC`;
      const response = await restClientAdmin.get(url);

      dispatch({
        type: actions.FETCH_COMPANY_USERS,
        payload: response.data
      });

      if (!withPreloader) return response;
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    }

    // stop preloader
    if (withPreloader) dispatch(call.stopPreloader());
  };

export const loginAs = userId => async dispatch => {
  // start preloader
  dispatch(call.startPreloader());

  try {
    const url = `/admin/login/${userId}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.LOGOUT,
      payload: null
    });

    dispatch({
      type: actions.SAVE_USER_TOKEN,
      payload: response.data.data
    });
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
  }

  // stop preloader
  dispatch(call.stopPreloader());
};

export const searchUser = searchTerm => async dispatch => {
  // start preloader
  dispatch(call.startPreloader());

  try {
    const fields =
      "firstname, lastname, role, companyname, email, lastloginat, active, deletedat, id, ctiaid";
    const orders = "companyname ASC, firstname ASC, lastname ASC";
    const url = `/users?page=0&select=${fields}&orderBy=${orders}`;
    const body = {
      params: {
        where: `(fullname LIKE '%${searchTerm}%' OR companyname LIKE '%${searchTerm}%' OR email LIKE '%${searchTerm}%'  AND active IN (-1,0,1))`
      }
    };
    const response = await restClientAdmin.get(url, body);

    dispatch({
      type: actions.FETCH_USERS,
      payload: response.data
    });
  } catch (e) {
    dispatch(call.stopPreloader());
  }

  // stop preloader
  dispatch(call.stopPreloader());
};

export function clearUserList() {
  return {
    type: actions.CLEAR_USER_LIST,
    payload: null
  };
}

export const addLegacyUser = () => async dispatch => {
  try {
    const form = document.getElementById("add-user-form");
    const args = prepareNewUserArgs(form);
    const body = makeSimpleArgs(args);
    const response = await restClientGeneral.post("/legacy/users", body);

    dispatch(call.showNotice(notifyMessages.userAddedSuccessfully));

    return response;
  } catch (error) {
    const { data } = error.response;

    if (data && data.errors) {
      throw data.errors[0];
    } else {
      throw data.exception;
    }
  }
};

/**
 * Create a legacy user account using a given new user request id and args.
 *
 * @param {Object} args Pass user fields in here. Required to provide a company's
 * ctia id through vendorid || labid || operatorid || servicecenterid, depending
 * on their company type.
 * @param {Number} newUserId Primary key for the new user record.
 * @param {String} program If using a newUserId, define for which program:
 * "ctia" || "ptcrb"
 * @returns
 */
export const addLegacyUserViaNewUserId =
  ({ args = {}, newUserId = 0, program = "ctia" }) =>
  async dispatch => {
    const programLcase = program.toLowerCase();
    if (programLcase !== "ctia" && programLcase !== "ptcrb")
      throw new Error(
        "This endpoint requires a valid program to be specified" +
          `${program} is not one of ['ctia', 'ptcrb']`
      );
    else if (!_.isNumber(newUserId) || newUserId <= 0)
      throw new Error(
        "This endpoint requires a positive integer to be specified"
      );

    try {
      const newUserIdParam = `${programLcase}newuserid=${newUserId}`;
      const url = `/legacy/users?${newUserIdParam}`;

      const body = { args: { ...args } };
      const requestBody = JSON.stringify(body);

      const response = await restClientGeneral.post(url, requestBody);

      dispatch({
        type: actions.CREATE_LEGACY_USER,
        payload: response.data
      });

      return response;
    } catch (error) {
      throw error;
    }
  };

export const editLegacyUser = userData => async dispatch => {
  try {
    const form = document.getElementById("edit-user-form");
    const args = prepareEditUserArgs(form);
    const body = makeSimpleArgs(args);
    const url = `/legacy/users/${userData.ctiaid}`;
    const response = await restClientGeneral.put(url, body);

    dispatch(call.showNotice(notifyMessages.userUpdatedSuccessfully));

    // dispatch({
    //   type: actions.EDIT_LEGACY_USER,
    //   payload: response.data.data
    // })

    const url2 = `/users/update/${userData.id}`;
    const response2 = await restClientGeneral.put(url2, body);


    dispatch({
      type: actions.EDIT_LEGACY_USER,
      payload: response2.data.data
    })


  } catch (error) {
    const { data } = error.response || {};
    if (data && data.errors) {
      throw data.errors[0];
    } else if (data && data.exception) {
      throw data.exception;
    } else {
        console.log(error);
    }
  }
};

export const activateDeletedUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}`;
    const body = makeSimpleArgs({ active: 1, deleted: 0 });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.ACTIVATE_DELETED_USER,
      userId
    });

    dispatch(call.showNotice(notifyMessages.userActivatedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return error;
  }
};

export const activateInactiveUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}`;
    const body = makeSimpleArgs({ active: 1 });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.ACTIVATE_INACTIVE_USER,
      userId
    });

    dispatch(call.showNotice(notifyMessages.userActivatedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return error;
  }
};

export const deleteUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}`;
    const body = makeSimpleArgs({ active: 0, deleted: 1 });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.DELETE_USER,
      userId
    });

    dispatch(call.showNotice(notifyMessages.userDeletedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return error;
  }
};

export const checkUserNameExists =
  ({ userName = "" }) =>
  async () => {
    try {
      const url = `/users/username/${userName}`;
      const response = await restClientGeneral.get(url);

      return response.data.data["userNameTaken"];
    } catch (error) {
      throw error;
    }
  };

export const getUnusedUserName =
  ({ firstName = "", lastName = "" }) =>
  async () => {
    try {
      const queryParams = `?firstName=${firstName}&lastName=${lastName}`;
      const url = `/users/username${queryParams}`;
      const response = await restClientGeneral.get(url);

      return response.data.data["suggestedUsername"];
    } catch (error) {
      throw error;
    }
  };

export const setNewPassword = (password, ctiaId) => async dispatch => {
  try {
    const url = `/legacy/users/${ctiaId}`;
    const body = makeSimpleArgs({ password, confirmpassword: password });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.SET_NEW_PASSWORD,
      payload: response.data.data
    });

    dispatch(call.showNotice(notifyMessages.passwordChangedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.passwordChangeError));
  }
};

export const lockUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}`;
    const body = makeSimpleArgs({ locked: 1 });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.LOCK_USER,
      userId
    });

    dispatch(call.showNotice(notifyMessages.userUpdatedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return error;
  }
};

export const unlockUser = userId => async dispatch => {
  try {
    const url = `/legacy/users/${userId}`;
    const body = makeSimpleArgs({ locked: 0 });
    const response = await restClientGeneral.put(url, body);

    dispatch({
      type: actions.UNLOCK_USER,
      userId
    });

    dispatch(call.showNotice(notifyMessages.userUpdatedSuccessfully));

    return response;
  } catch (error) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));
    return error;
  }
};
