import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { setBudgetSetting } from "actions/reports/budgetReport";
import { datePickerOptions } from "helpers/BudgetReportHelper";

const BudgetDateRange = ({ budgetReport, setBudgetSetting }) =>  {
  const onDateChange = (momentDate, fieldId) => {
      setBudgetSetting(fieldId, momentDate);
    }

  const { dateFrom, dateTo } = budgetReport.settings;

  return (
    <div>
      <FormElement
        type="date"
        id="dateFrom"
        label="Date From"
        value={moment(dateFrom)}
        onChange={(e) => onDateChange(e, "dateFrom")}
        {...datePickerOptions}
      />
      <FormElement
        type="date"
        id="dateTo"
        label="Date To"
        value={moment(dateTo)}
        onChange={(e) => onDateChange(e, "dateTo")}
        {...datePickerOptions}
      />
    </div>
  );
}

function mapStateToProps({ budgetReport }) {
  return { budgetReport };
}

export default connect(mapStateToProps, {
  setBudgetSetting,
})(BudgetDateRange);
