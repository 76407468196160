import _ from "lodash";
import { checkValue } from "helpers/ValidatorHelper";
import { formatDate } from "helpers/DateHelper";
import * as ls from "libs/localStorage";

export const requestFields = {
  supplier: "batterySupplier",
  supplierId: "batterySupplierId",
  supplierName: "batterySupplierName",
  model: "batteryModel",
  modelId: "batteryModelId",
  modelName: "batteryModelName",
  modelOption: "batteryModelOption",
  revision: "batteryRevision",
  cellRequestId: "cellRequestId",
  linkedRequestId: "linkedRequestId",
  linkedRequestCapacity: "linkedRequestCapacity",
  linkedRequestRevision: "linkedRequestRevision"
};

export const validateSupplierFields = request => {
  if (!request) return false;

  const {
    batterySupplier,
    batteryModel,
    batteryModelOption,
    batteryRevision
  } = request;

  // not valid if supplier has not selected
  if (!checkValue(batterySupplier)) return false;

  // supplier selected and model has pending recognition
  if (checkValue(batteryModelOption === 2)) return true;

  // otherwise - just check that both model and revision selected
  return checkValue(batteryModel) && checkValue(batteryRevision);
};

export const validateCellSupplierFields = request => {
  if (!request) return false;

  const { batterySupplier, batteryModel, batteryModelOption } = request;

  // not valid if supplier has not selected
  if (!checkValue(batterySupplier)) return false;

  // supplier selected and model has pending recognition
  if (checkValue(batteryModelOption === 2)) return true;

  // otherwise - just check that model was selected
  return checkValue(batteryModel);
};

export const formatSupplierList = suppliers => {
  if (!suppliers || suppliers.length === 0) return [];

  return suppliers.map(supplier => {
    return {
      value: supplier.id,
      title: supplier.name
    };
  });
};

export const formatCellModelsList = modelsList => {
  if (!modelsList || modelsList.length === 0) return [];

  return modelsList.map(model => {
    return {
      value: model.id,
      title: model.modelnumber,
      reqId: model.requestid,
      certTypeId: model.certtypeid
    };
  });
};

export const formatRecognizedVersionsList = versions => {
  if (!versions || versions.length === 0) return [];

  return versions.map(version => {
    const completedat = version.completedat
      ? formatDate(version.completedat)
      : "";

    return {
      id: version.id,
      tn: version.formatedtransactionnumber,
      authtype: version.ieeetypeid,
      revision: version.revision,
      manufsites: version.requestmanufsites,
      crsl: version.crslversion,
      recognizeddat: completedat,
      status: version.testingstatus,
      labId: version.primarylabid,
      submitterId: version.submitteruserid
    };
  });
};

export const setAsSelectedByDefault = list => {
  if (!list || !list.length) return [];

  return list.map(el => {
    el.checked = true;
    el.value = el.manufsiteid;

    return el;
  });
};

export const isPack = request => {
  if (!request || !request.deviceTypes) return false;

  const [pack] = request.deviceTypes;

  return pack && pack.checked;
};

export const isAdapter = request => {
  if (!request || !request.deviceTypes) return false;

  const [, adapter] = request.deviceTypes;

  return adapter && adapter.checked;
};

export const setCellSuppliers = request => {
  if (
    !request ||
    (!request.linkedrequests && !request.requestpendingrecognitions) ||
    (request.linkedrequests.length === 0 &&
      request.requestpendingrecognitions.length === 0)
  )
    return [];

  const { requestpendingrecognitions: pr } = request;
  const hasPendingRecognitions = pr && pr.length > 0;
  const recognitions = hasPendingRecognitions ? formatPendingCells(pr) : [];

  return [...request.linkedrequests, ...recognitions];
};

export const mapCellListValues = list => {
  return list.map(cell => {
    const {
      linkedrequestvendorname: vendor,
      linkedrequestvendorproductname: modelName,
      linkedrequestdevicemodelid: modelId
    } = cell;

    const hasModelName = modelName !== undefined;
    const linkTitle = hasModelName ? `${vendor} — ${modelName}` : vendor;

    return {
      certType: "crr",
      modelId,
      linkTitle,
      asText: !hasModelName
    };
  });
};

export const setBatterySuppliers = request => {
  // TODO change this for battery supplier, include revision
  if (
    !request ||
    (!request.linkedrequests && !request.requestpendingrecognitions) ||
    (request.linkedrequests.length === 0 &&
      request.requestpendingrecognitions.length === 0)
  )
    return [];

  const { requestpendingrecognitions: pr } = request;
  const hasPendingRecognitions = pr && pr.length > 0;
  const recognitions = hasPendingRecognitions ? formatPendingCells(pr) : [];

  return [...request.linkedrequests, ...recognitions];
};

export const mapBatteryListValues = list => {
  // TODO change this for battery supplier, include revision
  // EX : Manu - modelname - Rev
  //        B&K - BTR209 - Revision: 00
  return list.map(cell => {
    const {
      linkedrequestvendorname: vendor,
      linkedrequestvendorproductname: modelName,
      linkedrequestdevicemodelid: modelId
    } = cell;

    const hasModelName = modelName !== undefined;
    const linkTitle = hasModelName ? `${vendor} — ${modelName}` : vendor;

    return {
      certType: "prr",
      modelId,
      linkTitle,
      asText: !hasModelName
    };
  });
};

export const formatPendingCells = list => {
  if (!list || !list.length) return [];

  return list.map(cell => {
    return {
      hasPendingRecognition: true,
      linkedrequestid: cell.requestid,
      linkedrequestvendorname: cell.companyname,
      linkedrequestvendorid: cell.companyid
    };
  });
};

export const mapBatteryPacksValues = list => {
  return list.map(pack => {
    const {
      hasPendingRecognition,
      linkedrequestid: requestId,
      linkedrequestvendorname: vendor,
      linkedrequestvendorproductname: modelName,
      linkedrequestdevicemodelid: modelId,
      linkedrequestcerttypeid: typeId,
      linkedrequestrevision: revision
    } = pack;

    const isEmbedded = ls.getValue("idEPRR") === typeId;
    const certType = isEmbedded ? "eprr" : "prr";

    const hasModelName = modelName !== null;
    const linkTitle = hasModelName
      ? `${vendor} — ${modelName} — Revision: ${revision}`
      : `${vendor}`;

    return {
      certType,
      modelId,
      linkTitle,
      asText: !hasModelName,
      isEmbedded,
      hasPendingRecognition,
      value: requestId
    };
  });
};

export const mapAdapterValues = list => {
  return list.map(adapter => {
    const {
      hasPendingRecognition,
      linkedrequestvendorname: vendor,
      linkedrequestvendorproductname: modelName,
      linkedrequestdevicemodelid: modelId,
      linkedrequestcerttypeid: typeId,
      linkedrequestrevision: revision,
      type
    } = adapter;

    // unrecognized embedded adapter, no model id
    if (parseInt(type) === 3) {
      return {
        linkTitle: `${vendor} — ${modelName}`,
        asText: true,
        type
      };
    }

    const isACR = ls.getValue("idACR") === typeId;
    const certType = isACR ? "acr" : "arr";

    const hasModelName = modelName !== null;
    const hasParentModel = modelId !== null;

    const linkTitle = hasModelName
      ? `${vendor} — ${modelName} — Revision: ${revision}`
      : `${vendor}`;

    return {
      type,
      certType,
      modelId,
      linkTitle,
      asText: !hasModelName || !hasParentModel,
      hasPendingRecognition
    };
  });
};

export const mapBSCBatteryList = batteries => {
  if (!batteries || batteries.length === 0) return [];

  const filteredBatt = _.uniqBy(batteries, "linkedrequestid");

  return mapBatteryPacksValues(filteredBatt);
};
