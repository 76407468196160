import { connect } from "react-redux";
import { InlinePreloader } from "ctia-ui";
import React, { useState, useEffect } from "react";
import SupplierList from "containers/request/battery/PackCells/PackCellsList";
import { fetchCellSuppliers } from "actions/suppliers";
import SupplierSelector from "containers/request/battery/PackCells/PackCellsSelector";

const PackCellsContainer = ({ request, fetchCellSuppliers }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchCellSuppliers(request.ieee).then(() => setLoading(false));
  }, [fetchCellSuppliers, request.ieee]);

  return (
    <>
      <SupplierList />
      {loading ? <InlinePreloader /> : <SupplierSelector />}
    </>
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  fetchCellSuppliers
})(PackCellsContainer);
