import _ from "lodash";
import * as actions from "actions/types";

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_USERS:
    case actions.FETCH_COMPANY_USERS:
      const { data, meta } = action.payload;
      return {
        ...state,
        list: _.sortBy(data, [
          function (user) {
            return user.reversedfullname;
          }
        ]),
        pages: meta
      };
    case actions.CLEAR_USER_LIST:
      return null;
    case actions.FETCH_DELETED_USERS:
      return { ...state, deletedUsers: action.payload };
    case actions.ACTIVATE_INACTIVE_USER:
      const userIdx = _.findIndex(state.list, { ctiaid: action.userId });
      const updatedUser = {
        ...state.list[userIdx],
        active: 1
      };

      _.set(state, `list[${userIdx}]`, updatedUser);

      return { ...state };
    case actions.DELETE_USER:
      //find deleted user and remove it from user admin list
      const list = _.filter(state.list, el => el.ctiaid !== action.userId);

      return { ...state, list };
    case actions.ACTIVATE_DELETED_USER:
      // find activated user
      // and remove it from the list
      const deletedUsers = _.filter(
        state.deletedUsers,
        el => el.ctiaid !== action.userId
      );

      return { ...state, deletedUsers };
    case actions.CLEAN_DELETED_USERS:
      return { ...state, deletedUsers: null };
    case actions.EDIT_LEGACY_USER:
      const idx = _.findIndex(state.list, { ctiaid: action.payload.id });
      const usr = {
        ...state.list[idx],
        ...action.payload
      };

      _.set(state, `list[${idx}]`, usr);

      return { ...state };
    default:
      return state;
  }
}
