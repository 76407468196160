// import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import BatteryModelAutocomplete from "containers/request/battery/BatteryModelAutocomplete";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import BatteryPacks from "containers/request/battery/BatterySupplier/BatteryPacks";
import Adapters from "containers/request/battery/AdapterSupplier/Adapters";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import { initBSC, updateDeviceInfoBSC } from "actions/battery/bscRequest";
import { submitWizardStep } from "actions/wizard";
import { ifModelExists } from "actions/model";
import { hasStringValue } from "helpers/RequestHelper";
import { findExistedModelByName } from "helpers/ModelHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestContainerBSC extends React.Component {
  updatePermissions = () => {
    const { request, user, setBatteryRequestPermissions } = this.props;
    setBatteryRequestPermissions(request, user);
  };

  componentDidMount() {
    const { request, user, initBSC } = this.props;
    const ownerId = getRequestOwner(request, user);

    initBSC(ownerId);
    this.updatePermissions();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.togglerEnabled !== this.props.user.togglerEnabled) {
      return this.props.setBatteryRequestPermissions(
        this.props.request,
        this.props.user
      );
    }
  }

  onSaveClick = () => {
    const { request, requests, setValue, updateDeviceInfoBSC } = this.props;

    // if modelname not changed - just update device info
    const modelNameUntouched =
      request.modelName === request.__orig.vendorproductname;

    // modelname should be unique
    const existedModel = findExistedModelByName(
      request.modelName,
      requests.vendorBatteryModels
    );

    if (existedModel && !modelNameUntouched) {
      setValue("modelNameAlreadyInUse", true);

      // return nothing, which means - no device update due to failed validation
      return;
    } else {
      setValue("modelNameAlreadyInUse", false);
      updateDeviceInfoBSC(request);
    }
  };

  onContinueClick = () => {
    const {
      request,
      requests,
      user,
      setValue,
      ifModelExists,
      submitWizardStep
    } = this.props;

    // check if entered model name exists
    const existedModel = findExistedModelByName(
      request.existedModel,
      requests.vendorBatteryModels
    );

    // if so - use existed model id
    const existedModelId = existedModel ? existedModel.value : null;
    const isExistedModelLegacy = existedModel ? existedModel.isLegacy : false;

    // update request field in redux
    setValue("existedModelId", existedModelId);
    setValue("isExistedModelLegacy", isExistedModelLegacy);

    // check modelname uniqueness
    if (!existedModel) {
      ifModelExists(request.existedModel, user.companyid).then(isUnique => {
        if (isUnique) {
          return submitWizardStep();
        }
      });
    } else {
      return submitWizardStep();
    }
  };

  validateModel() {
    const { request, requests } = this.props;
    if (!request || !requests) return false;

    const fieldName = request.id ? "modelName" : "existedModel";

    return hasStringValue(request, fieldName);
  }

  validateHw() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateSw() {
    return hasStringValue(this.props.request, "swVersion");
  }

  validateBatterySuppliers() {
    const { batterySuppliers } = this.props.request;

    return batterySuppliers ? batterySuppliers.length > 0 : false;
  }

  validateAdapterSuppliers() {
    const { adapterSuppliers, adapterType } = this.props.request;

    // can proceed if "No Adapter" selected
    const noAdapter = adapterType && Number(adapterType) === 4;

    if (noAdapter) return true;

    return adapterSuppliers ? adapterSuppliers.length > 0 : false;
  }

  isValidToSubmit() {
    // don't need to validate if form is non-editable
    if (this.props.user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateHw() &&
      this.validateSw() &&
      this.validateBatterySuppliers() &&
      this.validateAdapterSuppliers()
    );
  }

  isValidToUpdate() {
    const { user } = this.props;

    if (user.isVendor) return this.isValidToSubmit();
    if (user.isStaff && !user.canEditLabFields) return this.isValidToSubmit();

    return this.isValidToSubmit();
  }

  render() {
    const { request, user } = this.props;
    const saveHandler = user.canEditDeviceInfo ? this.onSaveClick : null;
    const isValid =
      request.id > 0 ? this.isValidToUpdate() : this.isValidToSubmit();

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <BatteryModelAutocomplete />
          <RequestHWVersion />
          <RequestSWVersion />
          <SubmissionDetails />
          <hr />
          <BatteryPacks />
          <hr />
          <Adapters />
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
            onContinue={this.onContinueClick}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, requests, user }) {
  return { request, requests, user };
}

export default connect(mapStateToProps, {
  submitWizardStep,
  updateDeviceInfoBSC,
  setValue,
  setBatteryRequestPermissions,
  initBSC,
  ifModelExists
})(RequestContainerBSC);
