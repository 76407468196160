import React from "react";
import { connect } from "react-redux";
import RequestControls from "containers/request/RequestControls";
import { countCheckedAgreements } from "containers/request/LicenseAgreement/BatteryAgreement/agreementHelper";
import {
  submitInitialBLC,
  signSavedInitialBLC
} from "actions/battery/blcRequest";
import { BLC_AGREEMENTS } from "dictionaries/BatteryAgreementTypes";

const BatteryAgreementControlsBLC = ({
  request,
  user,
  wizard,
  submitInitialBLC,
  signSavedInitialBLC
}) => {
  if (!request) return null;
  const wizardSubmitHandler = () => submitInitialBLC(request);

  let onSaveHandler;

  let isValid;
  let validToSave;
  let validToSubmit;
  let agreedAmount = 0;

  const totalAgreements = BLC_AGREEMENTS.length || 0;
  // logic for submission
  if (wizard) {
    // let's count how many checkboxes selected on agreement page
    agreedAmount = countCheckedAgreements(request);

    // can request be saved
    if (request.canBeSaved && agreedAmount === 0) {
      validToSave = true;
    }

    // can request be submitted
    else if (agreedAmount === totalAgreements) {
      validToSubmit = true;
    }
  } else {
    // logic for existed request
    if (user.isVendor && !request.agreementAccepted) {
      onSaveHandler = () => {
        return signSavedInitialBLC(request.id, request.ieee);
      };

      // let's count how many checkboxes selected on agreement page
      agreedAmount = countCheckedAgreements(request);

      // can request be saved
      if (agreedAmount === totalAgreements) isValid = true;
    }
  }

  return (
    <RequestControls
      show={true}
      isValid={isValid}
      isValidToSave={validToSave}
      isValidToSubmit={validToSubmit}
      onSave={onSaveHandler}
      onSubmit={wizardSubmitHandler}
      onWizardSave={wizardSubmitHandler}
    />
  );
};

function mapStateToProps({ request, wizard, user }) {
  return { request, wizard, user };
}

export default connect(mapStateToProps, {
  submitInitialBLC,
  signSavedInitialBLC
})(BatteryAgreementControlsBLC);
