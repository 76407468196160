import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Bubble } from "ctia-ui";
import CyberComponent from "containers/CyberComponent";
import LabTestInfo from "containers/request/battery/BatteryLabInfo/LabTestInfo";
import LabTestResult from "containers/request/battery/BatteryLabInfo/LabTestResult";
import RequestControls from "containers/request/RequestControls";
import { updateRequestLab } from "actions/requestCyber";

class BatteryLabInfo extends CyberComponent {
  validate() {
    return this.props.request.cdmaLab > 0;
  }

  onSaveClick = () => {
    this.props.updateRequestLab(this.props.request);
  };

  render() {
    const { request, show, tests, user, canSubmit } = this.props;

    if (!show) return null;

    const testModule = tests ? tests[0] : null;
    const isEditable = user.canEditTests || user.isStaff;
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        {!request.id && (
          <Bubble show={canSubmit}>Please select a lab for the audit.</Bubble>
        )}
        <LabTestInfo editable={isEditable} />
        <LabTestResult testModule={testModule} onSaveClick={saveHandler} />
        <RequestControls isValid={this.validate()} onSave={saveHandler} />
      </div>
    );
  }
}

BatteryLabInfo.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

BatteryLabInfo.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  updateRequestLab
})(BatteryLabInfo);
