import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import FormElement from "components/FormElement";
import { handleInputChange, setValue } from "actions/request";
import { setRequestVoltagePermissions } from "actions/battery/crrRequest";
import { hasStringValue } from "helpers/RequestHelper";
import * as formHelper from "helpers/FormHelper";
import * as modelHelper from "helpers/ModelHelper";

const BatteryModelAutocomplete = ({
  request,
  requests,
  user,
  handleInputChange,
  setValue,
  setRequestVoltagePermissions
}) => {
  const [touched, setTouched] = useState(false);
  const [{ validationState, validationMessage }, setValidationError] = useState(
    {
      validationState: null,
      validationMessage: ""
    }
  );

  const fieldName = formHelper.defineModelFieldId(request);
  const isEditable = modelHelper.isAutocompleteModelEditable(request, user);

  const onFieldChanged = event => {
    if (!touched) {
      setTouched(true);
    }

    if (request.isCRR && !request.id) {
      // check is selected/entered modelname is existing one
      const existingModel = modelHelper.findExistedModelByName(
        event.target.value,
        requests.vendorBatteryModels
      );

      if (existingModel) {
        const { capacity, voltage } = existingModel;
        setValue("capacity", capacity);
        setValue("voltage", voltage);
        setRequestVoltagePermissions(false);
      } else {
        setValue("capacity", [1, ""]);
        setValue("voltage", [1, ""]);
        setRequestVoltagePermissions(true);
      }
    }

    handleInputChange(event);
  };

  const inputType = formHelper.defineEcoModelFieldType(isEditable, request);
  const modelName = formHelper.defineBatteryModelName(isEditable, request);
  const vendorModels = requests ? requests.vendorBatteryModels : [];

  // TODO: prob get rid of it
  useEffect(() => {
    let validationState = null;
    let validationMessage = "";

    if (isEditable && touched) {
      if (!hasStringValue(request, fieldName)) {
        validationState = "error";
        validationMessage = formHelper.emptyMsg;
      } else if (request.modelNameAlreadyInUse) {
        validationState = "error";
        validationMessage = formHelper.modelNameExists;
      }
    }

    setValidationError({
      validationState,
      validationMessage
    });
  }, [modelName, fieldName, request, touched, isEditable]);

  return (
    <FormElement
      id={fieldName}
      type={inputType}
      label="Model Name/Number"
      value={modelName}
      options={vendorModels}
      editable={isEditable}
      onChange={onFieldChanged}
      validationState={validationState}
      validationMessage={validationMessage}
    />
  );
};

function mapStateToProps({ request, requests, user }) {
  return { request, requests, user };
}

export default connect(mapStateToProps, {
  handleInputChange,
  setValue,
  setRequestVoltagePermissions
})(BatteryModelAutocomplete);
