import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { LoaderButton, IconLabel } from "ctia-ui";
import { loginAs, getUserInfo } from "actions/user";

const LoginAs = ({ show, userData, loginAs, getUserInfo, user }) => {
  if (!show) return null;

  const { id, role } = userData;

  // oh, it's me!
  if (user && user.id === id) return null;

  // exclude Unassigned Users
  if (role === "UNASSIGNED") return null;

  return (
    <LoaderButton
      bsStyle="info"
      bsSize="xsmall"
      className="icon-right-offset"
      text={<IconLabel label="Log In As (CTIA)" awesomeIcon="user" />}
      onClick={() => loginAs(id).then(() => getUserInfo())}
    />
  );
};

LoginAs.propTypes = {
  show: PropTypes.bool,
  userId: PropTypes.number
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  loginAs,
  getUserInfo
})(LoginAs);
