import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { InlinePreloader, LoaderButton } from "ctia-ui";
import LabResultUpload from "containers/testResult/LabResultUpload";
import TestResultPanel from "components/TestResultPanel";
import { hasPreparedResults } from "helpers/RequestTestsHelper";
import {
  fetchTestReports,
  testReportDownload,
  sendTestResult
} from "actions/tests";

const EditTestResultForm = ({
  toggleDrawer,
  toggleLockDrawer,
  request,
  testModule,
  uploads,
  fetchTestReports,
  testReportDownload,
  sendTestResult,
  closeDrawer
}) => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [refetchedResults, setRefetchedResults] = useState(false);

  useEffect(() => {
    if (
      !loading &&
      submitted &&
      !refetchedResults &&
      uploads &&
      _.every(uploads, ["pending", false])
    ) {
      setRefetchedResults(true);
      fetchTestReports({ id: testModule.id })
        .then(() => toggleLockDrawer())
        .catch(() => toggleLockDrawer());
    }
  }, [
    fetchTestReports,
    toggleLockDrawer,
    closeDrawer,
    loading,
    submitted,
    refetchedResults,
    uploads,
    testModule.id
  ]);

  const submitTest = () => {
    setLoading(true);
    sendTestResult(testModule).then(() => setLoading(false));
  };

  const onValidate = () => {
    const { testResults } = request;
    const { reports } = testModule;

    return hasPreparedResults(testResults) || reports.length;
  };

  const onSave = () => {
    setSubmitted(true);
    toggleLockDrawer();
    submitTest();
  };

  const closeTheDrawer = () => {
    closeDrawer();
    if(window.location.href.slice(-2) === '/1')
      window.location.reload()
    else
      window.location.href += '/1';
  }

  if (_.isNull(request) || _.isNull(testModule) || !request.id) {
    return null;
  }

  if (submitted) {
    return loading ? (
      <InlinePreloader />
    ) : (
      <div>
        <h1 className="block-bottom-offset">Uploading Test Results</h1>
        <TestResultPanel />
        <LoaderButton text="Done" onClick={()=>closeTheDrawer()} disabled={false} />
      </div>
    );
  }

  return (
    <div>
      <LabResultUpload show={true} downloadHandler={testReportDownload} />
      <Row>
        <Col className="text-center">
          <LoaderButton
            bsStyle="info"
            bsSize="large"
            text="Save changes"
            loadingText="Uploading..."
            onClick={onSave}
            isLoading={request.isLoading}
            disabled={!onValidate()}
            show={true}
          />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps({ request, uploads }) {
  return { request, uploads };
}

export default connect(mapStateToProps, {
  fetchTestReports,
  testReportDownload,
  sendTestResult
})(EditTestResultForm);
