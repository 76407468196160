import React, { Component } from "react";
import { connect } from "react-redux";
import DevicesSearchBattery from "containers/Devices/Battery/DeviceSearchBattery/DevicesSearchBattery";
import BatteryDeviceList from "containers/Devices/Battery/BatteryDevices/BatteryDeviceTable/BatteryDeviceList";
import BatteryManufSiteList from "containers/Devices/Battery/BatteryDevices/BatteryManufSiteTable/BatteryManufSiteList";
import { keepDeviceList, clearDeviceList } from "actions/devices";
import { clearModel } from "actions/model";
import { updateUserLocation } from "actions/user";

class BatteryDevices extends Component {
  componentDidMount() {
    const { devices } = this.props;

    // clean only if tab was changed completely
    if (devices && devices.list && !devices.keepList) {
      this.props.clearDeviceList();
      this.props.clearModel();
    }

    this.props.keepDeviceList();
    this.props.updateUserLocation(this.props.history.location.pathname);
  }

  render() {
    const { devices } = this.props;
    const showHeader = !devices || !devices.list;

    return (
      <div>
        {showHeader && <h1>Approved Requests</h1>}
        <DevicesSearchBattery history={this.props.history} />
        <BatteryDeviceList />
        <BatteryManufSiteList />
      </div>
    );
  }
}

function mapStateToProps({ devices }) {
  return { devices };
}

export default connect(mapStateToProps, {
  keepDeviceList,
  clearDeviceList,
  clearModel,
  updateUserLocation
})(BatteryDevices);
