import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Field from "containers/request/Payments/LabeledField";
import { formatCurrency } from "helpers/StringHelper";
import { formatDate } from "helpers/DateHelper";
import { getInvoiceNumber } from "helpers/BillingHelper";

const RequestDetailsBattery = ({ app, request }) => {
  if (!app || !app.isBatteryCert || _.isEmpty(_.pickBy(request, _.identity)))
    return null;

  const {
    id,
    tn,
    vendor,
    modelName,
    invoice,
    typeTitle,
    certPrefix,
    billingPocName,
    billingPocPhone,
    billingPocEmail,
    billingAddress,
    billingCompanyName,
    ieee
  } = request;
  const vendorTitle = request.isEPRR ? "Supplier/Vendor" : "Supplier";

  const details = [
    {
      id: "requestId",
      label: "Request ID",
      content: (
        <Link to={`/battery/accountant/requests/${certPrefix}/${id}`}>
          {tn}
        </Link>
      )
    },
    {
      id: "vendor",
      label: vendorTitle,
      content: vendor
    },
    {
      id: "modelName",
      label: "Model Name/Number",
      content: modelName
    },
    {
      id: "requestType",
      label: "Request Type",
      content: typeTitle
    },
    {
      id: "ieeeType",
      label: "IEEE Type",
      content: ieee
    },
    {
      id: "billingCompany",
      label: "Billing Company",
      labelStyle: "primary",
      content: billingCompanyName
    },
    {
      id: "billingAddress",
      label: "Billing Address",
      labelStyle: "primary",
      content: billingAddress
    },
    {
      id: "billingPOC",
      label: "Billing PoC",
      labelStyle: "primary",
      content: billingPocName
    },
    {
      id: "billingPhone",
      label: "Billing Phone",
      labelStyle: "primary",
      content: billingPocPhone
    },
    {
      id: "billingEmail",
      label: "Billing Email",
      labelStyle: "primary",
      content: billingPocEmail
    },
    {
      id: "invoiceId",
      label: "Invoice Number",
      content: getInvoiceNumber(request)
    },
    {
      id: "invoiceDate",
      label: "Invoice Date",
      content: formatDate(invoice.createdat)
    },
    {
      id: "invoiceAmount",
      label: "Invoice Amount",
      content: formatCurrency(invoice.totalamount)
    }
  ];

  const renderDetails = () =>
    details.map(field => {
      return <Field {...field} key={field.id} />;
    });

  return (
    <div>
      <h1 className="block-bottom-offset">Request Details</h1>
      <Form horizontal>{renderDetails()}</Form>
    </div>
  );
};

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps)(RequestDetailsBattery);
