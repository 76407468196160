import _ from "lodash";
import React, { useState } from "react";
import { fetchLabInterfaces } from "actions/interfaces";
import { Form } from "ctia-react-bootstrap-v4";
import CompanyInterfaces from "dictionaries/CompanyInterfaces";
import {
  formatInterfaceForUIComponent,
} from "helpers/InterfaceHelper";

const LabAuthorizedTestingCapabilitySelectors = ({
  checkedIdsOnInit = [],
  onChange = () => {},
  onComponentReady = () => {},
  onRequiresIEEEType = () => {},
}) => {
  const [isInit, setIsInit] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedIdsRequiringIEEE] = useState([]);

  const interfaces = [
    {
      type: 'interface',
      defaultChecked: _.includes(checkedIdsOnInit, CompanyInterfaces.batteryCompliance.id),
      id: CompanyInterfaces.batteryCompliance.id,
      isRequired: true,
      value: CompanyInterfaces.batteryCompliance.label
    },
    {
      type: 'interface',
      defaultChecked: _.includes(checkedIdsOnInit, CompanyInterfaces.batterySiteAudit.id),
      id: CompanyInterfaces.batterySiteAudit.id,
      isRequired: true,
      value: CompanyInterfaces.batterySiteAudit.label
    },
  ];

  if(!isInit) {
    const requiredInterfaceIds = [
      CompanyInterfaces.batteryCompliance.id,
      CompanyInterfaces.batterySiteAudit.id
    ];

    fetchLabInterfaces()
    .then(fetchedInterfaces => {
      const fetchedOptions = fetchedInterfaces.map(interfaceItem => formatInterfaceForUIComponent({
        interfaceItem,
        isRequired: _.includes(requiredInterfaceIds, interfaceItem.id),
        precheckedIds: checkedIdsOnInit
      }));

      const orderedOptions = _.orderBy([...interfaces, ...fetchedOptions], a => a.value.toLowerCase());
      setOptions(orderedOptions);

      onComponentReady();
    })
    .catch(() => setOptions(interfaces));

    setIsInit(true);
  }

  const handleFieldChange = (event, isRequired) => {
    const { target } = event;
    if (isRequired) {
      target.checked
        ? selectedIdsRequiringIEEE.push(target.value)
        : selectedIdsRequiringIEEE.pop(target.value);

      onRequiresIEEEType(selectedIdsRequiringIEEE.length > 0);
    }
    onChange(event);
  };

  return options.map((testingCapability, index) => {
    const {id, defaultChecked, isRequired, type, value} = testingCapability;
    return (
      <Form.Check
        defaultChecked={defaultChecked}
        id={`${type}${index}`}
        key={index}
        label={value}
        onChange={event => handleFieldChange(event, isRequired)}
        type="checkbox"
        value={id}
      />
    );
  });
};

export default LabAuthorizedTestingCapabilitySelectors;