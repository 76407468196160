import React from "react";
import { connect } from "react-redux";
import { WithModal } from "ctia-ui";
import AgreementModal from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementModal";
import AgreementCheckbox from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementCheckbox";
import ModalContent from "containers/request/LicenseAgreement/BatteryAgreement/Modals/RecognitionContent";
import { RECOGNITION_AGREEMENT } from "containers/request/LicenseAgreement/BatteryAgreement/agreementHelper";
import { downloadRecognition } from "actions/agreementsBattery";

import "containers/request/LicenseAgreement/style.css";

class RecognitionAgreement extends WithModal {
  onPrintClick = () => {
    const { request, certType, downloadRecognition } = this.props;
    return downloadRecognition(request, certType);
  };

  render() {
    const title = "License Agreement";
    const agreementTerm = `I accept the ${title} terms and conditions`;
    return (
      <div>
        <h3>
          <strong>{title}</strong>
        </h3>
        <p className="agr-description">
          Once the device is recognized, the license agreement becomes
          effective. Please review the agreement{" "}
          <span
            onClick={this.handleModal}
            className="agr-link cursor-pointer glow-link"
          >
            here
          </span>{" "}
          and click the accept box below.
        </p>
        <p className="agr-description">
          I understand and acknowledge that my company agrees to the Terms and
          Conditions for the specified device set forth in the link above that
          will become effective immediately upon the date of device recognition.
        </p>
        <div className="text-center agr-description">
          <AgreementCheckbox
            togglerId={RECOGNITION_AGREEMENT}
            title={title}
            agreementTerm={agreementTerm}
          />
        </div>
        <AgreementModal
          show={this.state.showModal}
          modalTitle={title}
          modalContent={ModalContent}
          modalHandler={this.handleModal}
          downloadHandler={this.onPrintClick}
          version={this.props.request.licenseagreementversionid}
        />
      </div>
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  downloadRecognition
})(RecognitionAgreement);
