import _ from "lodash";
import React from "react";
import FormElement from "components/FormElement";
import {
  ctiaSelectionOptions,
  ptcrbSelectionOptions,
} from "components/CompanySelectors/CompanyType/CompanyTypeOptions";

const CompanyTypeSelector = ({
  editable = true,
  formElementProperties,
  isPTCRB = false,
  isRequired,
  onChange = () => {},
  value
}) => {
  const localOnChange = event => {
    const {id, value} = event.target;
    onChange(value, id);
  }

  const options = isPTCRB
    ? ptcrbSelectionOptions
    : ctiaSelectionOptions;

  const validationState =
    !_.includes(options.map(option => option.value), value)
      ? "error"
      : null;

  return (
    <FormElement
      {...formElementProperties}
      editable={editable}
      id="companyType"
      isRequired={isRequired}
      label="Company Type"
      onChange={localOnChange}
      options={options}
      type={"select"}
      validationState={validationState}
      validationMessage={"Select a Company Type"}
      value={value}
    />
  );
};

export default CompanyTypeSelector;
