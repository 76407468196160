import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { InlinePreloader, LoaderButton } from "ctia-ui";
import LabResultUpload from "containers/testResult/LabResultUpload";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import { hasPreparedResults } from "helpers/RequestTestsHelper";
import {
  getCertTestHandler,
  getCertShorthand
} from "actions/battery/multitool";
import {
  fetchTestReportsCRR,
  downloadReportCRR,
  sendTestResultsCRR
} from "actions/battery/crrRequest";
import {
  fetchTestReportsHRR,
  downloadReportHRR,
  sendTestResultsHRR
} from "actions/battery/hrrRequest";
import {
  fetchTestReportsPRR,
  downloadReportPRR,
  sendTestResultsPRR
} from "actions/battery/prrRequest";
import {
  fetchTestReportsEPR,
  downloadReportEPR,
  sendTestResultsEPR
} from "actions/battery/eprRequest";
import {
  fetchTestReportsARR,
  sendTestResultsARR,
  downloadReportARR
} from "actions/battery/arrRequest";
import {
  fetchTestReportsACR,
  sendTestResultsACR,
  downloadReportACR
} from "actions/battery/acrRequest";
import {
  fetchTestReportsBSC,
  sendTestResultsBSC,
  downloadReportBSC
} from "actions/battery/bscRequest";
import { fetchTestReports, sendAuditResults } from "actions/tests";
import { auditReportDownload } from "actions/battery/sar";
import { fetchRequest } from "actions/requestCyber";

const EditTestResultFormBattery = props => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [refetchedResults, setRefetchedResults] = useState(false);

  const { toggleLockDrawer, request, testModule, uploads, setLockDrawer, setShowEditDrawer } =
    props;
  const {
    fetchTestReportHandler,
    downloadReportHandler,
    prepTestResultArg,
    sendTestResultsHandler
  } = getCertTestHandler(request, props);
  const certSH = getCertShorthand(request);

  useEffect(() => {
    if (
      !loading &&
      submitted &&
      !refetchedResults &&
      uploads &&
      _.every(uploads, ["pending", false])
    ) {
      setRefetchedResults(true);
      fetchTestReportHandler({ id: testModule.id })
        .then(() => toggleLockDrawer())
        .catch(() => toggleLockDrawer());
    }
  }, [
    fetchTestReportHandler,
    toggleLockDrawer,
    setLockDrawer,
    setShowEditDrawer,
    loading,
    submitted,
    refetchedResults,
    uploads,
    testModule.id
  ]);

  const submitTest = () => {
    setLoading(true);
    if (request.isCRR || request.isSAR)
      sendTestResultsHandler(testModule).then(() => setLoading(false));
    else {
      const testResArgs = prepTestResultArg(request, testModule);

      sendTestResultsHandler(testResArgs, request.id).then(() =>
        setLoading(false)
      );
    }
  };

  const onValidate = () => {
    const { testResults } = request;
    const { reports } = testModule;

    return hasPreparedResults(testResults) || reports.length;
  };

  const onSave = () => {
    setSubmitted(true);
    toggleLockDrawer();
    submitTest();
  };

  const closeDrawer = () => {
    fetchRequest(request.id);
    setLockDrawer(false);
    setShowEditDrawer(false);
    if(window.location.href.slice(-2) === '/1')
      window.location.reload()
    else
      window.location.href += '/1';
  }

  if (_.isNull(request) || _.isNull(testModule) || !request.id) {
    return null;
  }

  if (submitted) {
    return loading ? (
      <InlinePreloader />
    ) : (
      <div>
        <h1 className="block-bottom-offset">Uploading Test Results</h1>
        <TestResultPanelBattery
          clientType={certSH ? "general" : ""}
          certType={certSH}
        />
        <LoaderButton text="Done" onClick={ ()=> { closeDrawer() } } disabled={false} />
      </div>
    );
  }

  return (
    <div>
      <LabResultUpload show={true} downloadHandler={downloadReportHandler} />
      <Row>
        <Col className="text-center">
          <LoaderButton
            bsStyle="info"
            bsSize="large"
            text="Save changes"
            loadingText="Uploading..."
            onClick={onSave}
            isLoading={request.isLoading}
            disabled={!onValidate()}
            show={true}
          />
        </Col>
      </Row>
    </div>
  );
};

function mapStateToProps({ request, uploads }) {
  return { request, uploads };
}

export default connect(mapStateToProps, {
  fetchTestReportsCRR,
  sendTestResultsCRR,
  downloadReportCRR,
  fetchTestReportsHRR,
  sendTestResultsHRR,
  downloadReportHRR,
  fetchTestReportsPRR,
  sendTestResultsPRR,
  downloadReportPRR,
  fetchTestReportsEPR,
  sendTestResultsEPR,
  downloadReportEPR,
  fetchTestReportsARR,
  sendTestResultsARR,
  downloadReportARR,
  fetchTestReportsACR,
  sendTestResultsACR,
  downloadReportACR,
  fetchTestReportsBSC,
  sendTestResultsBSC,
  downloadReportBSC,
  fetchTestReports,
  sendAuditResults,
  auditReportDownload
})(EditTestResultFormBattery);
