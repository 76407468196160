import _ from "lodash";
import { checkValue } from "helpers/ValidatorHelper";
import { sortByFieldname } from "helpers/SortHelper";

export const requestFields = {
  otaPlan: "otaPlan",
  otaLab: "otaLab"
};

export const formatTestPlansList = (list, inUse) => {
  let testPlans = [];

  if (_.isArray(list) && _.size(list) > 0) {
    testPlans = list.map(testPlan => {
      const testEntry = { ...testPlan };
      testEntry.disabled = _.indexOf(inUse, testEntry.value) > -1;
      
      return testEntry;
    });
  }

  // "2x2 Downlink MIMO" removed as an option
  testPlans = testPlans.filter(plan => plan.value !== 50)

  return [
    {
      value: 0,
      title: "Select a Test Plan ↴"
    },
    ...testPlans
  ];
};

export const formatLabList = list => {
  let testLabs = [];

  if (_.isArray(list) && _.size(list) > 0) {
    testLabs = list.map(lab => {
      return { value: lab.id, title: lab.name };
    });
    testLabs = sortByFieldname(testLabs, "title");
  }

  return [
    {
      value: 0,
      title: "Select a Test Lab for selected Test Plan ↴"
    },
    ...testLabs
  ];
};

export const validateTestPlanFields = request => {
  if (!request) return false;
  return checkValue(request.otaPlan) && checkValue(request.otaLab);
};

export const getTestPlan = (testPlanId, interfaces) => {
  if (!testPlanId) return null;
  if (!interfaces || !_.size(interfaces) > 0) return null;

  return _.find(interfaces, ["value", Number(testPlanId)]) || null;
};

export const getCATLInfo = (catlId, CATLs) => {
  if (!catlId) return null;
  if (!CATLs || !_.size(CATLs) > 0) return null;

  return _.find(CATLs, ["id", Number(catlId)]) || null;
};

export const getOTACostsIds = otaInterfaces => {
  if (!otaInterfaces) return null;

  // return just an array of existed costs ids
  return otaInterfaces.map(otaPlan => {
    return otaPlan.interfacecosts[0].costid;
  });
};

export const getOtaCost = (costs, costId) => {
  if (!costs || !costId) return null;
  if (_.isEmpty(costs)) return null;

  return _.find(costs, ["id", costId]);
};

// Filter OTA Labs, value is the selected Test Plan
export const filterOtaLabs = (requestLabs, otaLabs) => {
  return _.filter({ ...otaLabs }, lab => {
    switch(requestLabs.value) {
      case 7:   //CTIA Test Plan for Wireless Device Over-the-Air Performance
          if(lab.assignedOTAInterfaces.includes('SISO OTA Performance')) {
            return _.includes(requestLabs.labs, lab.id) && lab.active === 1;
          }
          break;
      case 18:  //CTIA/Wi-Fi Alliance Test Plan for RF Performance
          if(lab.assignedOTAInterfaces.includes('Converged Device OTA Performance')) {
            return _.includes(requestLabs.labs, lab.id) && lab.active === 1;
          }
          break;
      case 50: //CTIA Test Plan for 2x2 Downlink MIMO and Transmit Diversity Over-the-Air Performance
          if(lab.assignedOTAInterfaces.includes('MIMO OTA Performance')) {
            return _.includes(requestLabs.labs, lab.id) && lab.active === 1;
          }
          break;
      default:
          break;
    }});
};
