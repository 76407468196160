import React, { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import PropTypes from "prop-types";
import UserManual from "components/UserManual";
import SupportDocs from "components/SupportDocs";
import StaffDecision from "containers/request/Initial/StaffDecision";
import RequestControls from "containers/request/RequestControls";
import { hasDocsForUpload } from "helpers/UploadsHelper";
import { saveRequest } from "actions/request";
import { deleteUsersManual } from "actions/uploads";
import {
  hasManualFile,
  hasUploadedManual,
  isExisted
} from "helpers/RequestHelper";

const tabStyle = {
  paddingTop: 15
};

class Documents extends Component {
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = { isManualTab: true };
  }

  handleSelect(key) {
    this.setState({ isManualTab: key === 1 });
  }

  // TODO: move to action creator
  onSaveClick = () => {
    const { request, deleteUsersManual, saveRequest } = this.props;

    // check if user set brand new file for users manual
    if (hasManualFile(request) && hasUploadedManual(request)) {
      deleteUsersManual(request.model.id).then(() => saveRequest());
    } else {
      saveRequest();
    }
  };

  render() {
    const { show, request, canSubmit, user } = this.props;

    if (!show) return null;

    const hasDocsToUpload = hasDocsForUpload(request.supportDocs);
    const hasPermissions = user.canUploadRequestDocs;

    const isValid = isExisted(request)
      ? hasPermissions && (hasManualFile(request) || hasDocsToUpload)
      : true;

    const saveHandler = hasPermissions ? this.onSaveClick : null;

    return (
      <div>
        <StaffDecision isActiveTab={this.state.isManualTab} />
        <Tabs
          defaultActiveKey={1}
          id="documents-tabs"
          onSelect={this.handleSelect}
        >
          <Tab eventKey={1} title="Product Description" style={tabStyle}>
            <UserManual canSubmit={canSubmit} />
          </Tab>
          <Tab
            eventKey={2}
            title="Optional Supporting Documentation"
            style={tabStyle}
          >
            <SupportDocs canSubmit={canSubmit} />
          </Tab>
        </Tabs>
        <RequestControls isValid={isValid} onSave={saveHandler} />
      </div>
    );
  }
}

Documents.propTypes = {
  show: PropTypes.bool,
  canSave: PropTypes.bool,
  canSubmit: PropTypes.bool
};

Documents.defaultProps = {
  show: true,
  canSave: false,
  canSubmit: false
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  deleteUsersManual,
  saveRequest
})(Documents);
