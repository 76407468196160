import React from "react";
import PropTypes from "prop-types";
import { Badge } from "ctia-react-bootstrap-v4";
import badges from "containers/users/UsersList/BadgeTypes";

const UserRoleBadge = ({ textRole }) => {
  if (!textRole) return "N/A";

  const badgeType = badges[textRole]
    ? badges[textRole].badgeType
    : badges.UNKNOWN.badgeType;

  return <Badge variant={badgeType}>{textRole}</Badge>;
};

UserRoleBadge.propTypes = {
  textRole: PropTypes.string
};

export default UserRoleBadge;
