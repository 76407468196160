import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

/* Create Router Link component to request list or request entry if request id presents.
  Also add prefix for staff users (`/admin`).
  
  Examples:
    --- `/requests` (link to request list page for vendors)
    --- `/admin/requests` (link to request list page for staff users)
    --- `/requests/123` (vendor link to request page, request id=123)
    --- `/admin/requests/123` (staff link to request page, request id=123)
*/
class RequestsLink extends Component {
  render() {
    const {
      app,
      requestId,
      linkTitle = "link",
      category = "requests",
      subType = "",
      action = ""
    } = this.props;
    const subTypePart = subType ? `/${subType}` : "";
    const reqDetail = requestId ? `${subTypePart}/${requestId}` : "";
    const postfix = action ? `${reqDetail}/${action}` : reqDetail;

    return (
      <Link to={`${app.urlPrefix}${category}${postfix}`} className="glow-link">
        {linkTitle}
      </Link>
    );
  }
}

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(RequestsLink);
