import React, { useState } from "react";
import { connect } from "react-redux";
import { IconBase, IconLabel } from "ctia-ui";
import { downloadInvoiceATL } from "actions/catl";
import "styles/Preloader.css";
import { showNotice } from "actions/notifier";
import { notifyMessages } from "helpers/NotifyHelper";

const DownloadInvoiceATL = ({
  downloadInvoiceATL,
  companyId,
  ivcNum,
  show,
  id,
  isMissingAddress,
  isBackgroundJobRunning,
  showNotice
}) => {
  const [loading, setLoading] = useState(false);

  if (!show) return null;

  const atlData = {
    companyid: companyId,
    atlInvoiceNumber: ivcNum
  };

  async function downloadInvoice() {
	if (isBackgroundJobRunning) {
		return showNotice(notifyMessages.backgroundJobInActionWarning);
	}

    if(isMissingAddress) {
		return showNotice(notifyMessages.invoiceMissingAddress);
    }

    setLoading(true);
    downloadInvoiceATL({ ...atlData, invoiceId: id }).then(() => setLoading(false));
  }

  return (
    <div className="Loaderlink">
      {loading ? (
        <IconBase glyph="refresh" className="spinning" />
      ) : (
        <IconLabel awesomeIcon="download" />
      )}{" "}
      <span
        className="glow-link cursor-pointer"
        onClick={() => {
          downloadInvoice()
        }}
      >
        {loading ? "Downloading..." : "Download Invoice"}
      </span>
    </div>
  );
};

function mapStateToProps({ catlInvoice }) {
    return { catlInvoice };
  }

export default connect(mapStateToProps, { downloadInvoiceATL, showNotice })(DownloadInvoiceATL);