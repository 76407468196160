import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import StateFormElement from "containers/request/battery/ManufSite/SiteAddressState";
import CountryFormElement from "containers/request/battery/ManufSite/SiteAddressCountry";
import ZipFormElement from "containers/request/battery/ManufSite/SiteAddressZip";
import { handleInputChange } from "actions/sitesSar";

const SiteAddressAdd = ({ user, sites, handleInputChange, show }) => {
  if (!show) return null;

  const isEditable = user.canEditManufsite;

  return (
    <div>
      <FormElement
        type="text"
        id="manufSiteName"
        label="Site Name"
        value={sites.current.manufSiteName}
        onChange={handleInputChange}
        editable={isEditable}
      />
      <FormElement
        type="text"
        id="manufSiteLine1"
        label="Site Address (Line 1)"
        value={sites.current.manufSiteLine1}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={250}
      />
      <FormElement
        type="text"
        id="manufSiteLine2"
        label="Site Address (Line 2)"
        value={sites.current.manufSiteLine2}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={100}
        placeholder="If applicable"
      />
      <CountryFormElement />
      <FormElement
        type="text"
        id="manufSiteCity"
        label="City"
        value={sites.current.manufSiteCity}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={50}
      />
      <StateFormElement />
      <ZipFormElement />
    </div>
  );
};

function mapStateToProps({ user, sites }) {
  return { user, sites };
}

export default connect(mapStateToProps, {
  handleInputChange,
})(SiteAddressAdd);
