import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import VendorContent from "containers/request/RequestContent/VendorContent";
import reqTypes from "dictionaries/ReqTypes";
import {
  getVendorInitialTabs,
  getVendorECOTabs
} from "helpers/RequestTabsHelper";
import { getChecklist } from "helpers/ChecklistHelper";

const VendorRequestContent = ({ hideBillingPay = false, request, focusLab }) => {
  const checkList = getChecklist(request);

  switch (request.type) {
    case reqTypes.initial:
      const tabsInitial = getVendorInitialTabs(request);
      const tabsCyberInitial = hideBillingPay
        ? _.filter(tabsInitial, tab => {
            return tab.key !== "billing";
          })
        : tabsInitial;

      return (
        <VendorContent
          tabs={tabsCyberInitial}
          checkList={checkList}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    case reqTypes.eco:
      const tabsEco = getVendorECOTabs(request);
      const tabsCyberECO = hideBillingPay
        ? _.filter(tabsEco, tab => {
            return tab.key !== "billing";
          })
        : tabsEco;

      return (
        <VendorContent
          tabs={tabsCyberECO}
          checkList={checkList}
          defaultTab={focusLab ? "labInfo" : "deviceInfo"}
        />
      );
    default:
      return null;
  }
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(VendorRequestContent);
