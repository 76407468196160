import _ from "lodash";
import { Component } from "react";

class CyberTable extends Component {
  startPage = 1;

  componentDidMount() {
    this.fetchTableData();
  }

  // default data fetch method
  fetchTableData = () => null;

  // default pager setter
  setPagerSize = () => null;

  setTableData = data => {
    if (_.isNull(data) || _.isUndefined(data.list)) {
      return [];
    }

    return data.list;
  };

  setPagerData = data => {
    if (!data || !data.pages) return { page: 0, pages: 0, records: 0 };

    const { CURRENTPAGE, TOTALPAGES, TOTALRECORDS } = data.pages;

    return {
      page: CURRENTPAGE,
      pages: TOTALPAGES,
      records: TOTALRECORDS
    };
  };

  setCounter = (data, records) => {
    if (records > 0) return records;
    if (data && data.length > 0) return data.length;
    return 0;
  };

  onChangePageSize = size => {
    let pageSize = Number(size);

    // use pagination
    this.setPagerSize(pageSize);
    this.startPage = pageSize > 0 ? 1 : 0;

    // fetch updated data
    setTimeout(
      () => this.fetchTableData(this.startPage, undefined, pageSize),
      150
    );
  };
}

export default CyberTable;
