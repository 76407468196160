import React from 'react';
import PropTypes from 'prop-types';
import { TooltipBase } from 'ctia-ui';
import { concatStr } from 'helpers/StringHelper';

/**
 * Wrapping a long text string with tooltip
 * which contains the full length string value.
 *
 * Will return the source string if its length less than 50 chars.
 *
 * @param {string} string Text string to be displayed
 * @returns string | jsx
 */
const Text = ({ string, placement }) => {
  if (!string) return null;
  if (string.length <= 50) return string;

  return (
    <TooltipBase tipText={string} tipPlacement={placement}>
      <span>{concatStr(string)}</span>
    </TooltipBase>
  );
};

Text.propTypes = {
  string: PropTypes.string.isRequired,
  placement: PropTypes.oneOf(['right', 'top', 'left', 'bottom'])
};

Text.defaultProps = {
  string: '',
  placement: 'bottom'
};

export default Text;
