import React from "react";
import { connect } from "react-redux";
import Checkbox from "components/form/Checkbox";
import { IconLabel, Bubble } from "ctia-ui";
import { toggleCheckbox } from "actions/request";
import { isExisted, shouldDisableAgreement } from "helpers/RequestHelper";

const BatteryAgreementCheckbox = ({
  request,
  user,
  toggleCheckbox,
  app,
  title,
  agreementTerm = `I accept the ${title}`,
  togglerId
}) => {
  const { isSaved } = request;

  if (isExisted(request) && !isSaved && request.agreementAccepted) {
    return (
      <h3 className="green">
        <IconLabel awesomeIcon="check-circle" />
        {title} Accepted
      </h3>
    );
  }

  if (user.isStaff) {
    return <Bubble type="warning">Only vendor can accept the {title}.</Bubble>;
  }

  const hasAccepted = request[togglerId] === true;
  const isDisabled = shouldDisableAgreement(app, request);

  return (
    <Checkbox
      id={togglerId}
      onChange={toggleCheckbox}
      value={hasAccepted}
      editable={!isDisabled}
      label={agreementTerm}
      labelClass="bold"
    />
  );
};

function mapStateToProps({ request, user, app }) {
  return { request, user, app };
}

export default connect(mapStateToProps, {
  toggleCheckbox
})(BatteryAgreementCheckbox);
