import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import {
  getManufSites,
  updateManufSite,
  clearCurrSite
} from "actions/sitesSar";
import { getAddressDetails } from "actions/battery/sarAddresses";
import {
  setEditManufsitePermissions,
  updateRequest
} from "actions/battery/sar";
import CyberComponent from "containers/CyberComponent";
import RequestControls from "containers/request/RequestControls";
import SiteEdit from "containers/request/battery/ManufSite/ManufSiteEdit";
import Instructions from "containers/request/battery/ManufSite/SiteBubble";
import { validateAddress } from "helpers/ManufSiteHelper";
import AuditLinks from "containers/request/battery/TestResults/AuditLinks";
import SiteEditRenewal from "containers/request/battery/ManufSite/ManufSiteEditRenewal";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { isRenewal } from "helpers/RequestHelper";

class ManufSiteInfo extends CyberComponent {
  fetchAndHatch = request => {
    const {
      user,
      clearCurrSite,
      getManufSites,
      getAddressDetails
    } = this.props;

    // get rid off previous values
    clearCurrSite();

    // fetch existed sites and addresses
    getManufSites(request.ieee, user.requestOwnerCompanyId);

    // map address details to sites.current
    getAddressDetails(request.manufSite.addressid);

    // set default permission to edit site info
    this.props.setEditManufsitePermissions(user, request);
  };

  componentDidMount() {
    this.fetchAndHatch(this.props.request);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.request.id !== nextProps.request.id) {
      this.fetchAndHatch(nextProps.request);
    }
  }

  componentWillUnmount() {
    this.props.clearCurrSite();
  }

  onSaveClickInitial = () => {
    const { updateManufSite, sites, request } = this.props;
    const addressInfo = { ...sites.current, id: request.manufSite.addressid };

    updateManufSite(addressInfo, request);
  };

  onSaveClickRenewal = () => {
    const { updateRequest, request } = this.props;

    updateRequest(request);
  };

  validateInitial() {
    const { request, sites, user } = this.props;

    // don't need to validate if form is non-editable
    if (!user.canEditRequest) return true;

    return validateAddress(request, sites.current);
  }

  validateRenewal() {
    const { request, user } = this.props;

    // don't need to validate if form is non-editable
    if (!user.canEditRequest) return true;

    return request.manufSiteId && request.manufSiteId > 0;
  }

  getSaveHandlerInitial() {
    const { user } = this.props;

    return user.canEditManufsite && user.canEditRequest
      ? this.onSaveClickInitial
      : null;
  }

  getSaveHandlerRenewal() {
    const { user } = this.props;

    return user.canEditManufsite && user.canEditRequest
      ? this.onSaveClickRenewal
      : null;
  }

  renderInitialBody() {
    return (
      <div>
        <SiteEdit />
        <AuditLinks />
        <SubmissionDetails />
        <RequestControls
          isValid={this.validateInitial()}
          onSave={this.getSaveHandlerInitial()}
        />
      </div>
    );
  }

  renderRenewalBody() {
    return (
      <div>
        <SiteEditRenewal />
        <AuditLinks />
        <SubmissionDetails />
        <RequestControls
          isValid={this.validateRenewal()}
          onSave={this.getSaveHandlerRenewal()}
        />
      </div>
    );
  }

  render() {
    const hasRenewalType = isRenewal(this.props.request.type);

    return (
      <div>
        <Form horizontal>
          <Instructions />
          {hasRenewalType ? this.renderRenewalBody() : this.renderInitialBody()}
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user, sites, addresses }) {
  return { request, user, sites, addresses };
}

export default connect(mapStateToProps, {
  getManufSites,
  getAddressDetails,
  updateManufSite,
  clearCurrSite,
  setEditManufsitePermissions,
  updateRequest
})(ManufSiteInfo);
