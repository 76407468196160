import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { DownloadButton, IconLabel, SpinContainer } from "ctia-ui";
import certTypesEnum from "dictionaries/CertTypesEnum";
import {
  fetchDetails,
  downloadBudgetReport
} from "actions/reports/budgetReport";
import { formatCurrency } from "helpers/StringHelper";
import { formatDate } from "helpers/DateHelper";
import { toggleOrder } from "helpers/SortHelper";
import { scrollTo } from "helpers/AppHelper";

const EntriesList = ({
  app,
  budgetReport,
  fetchDetails,
  downloadBudgetReport
}) => {
  const [payload, setPayload] = useState({
    meta: null,
    data: null,
    loading: false
  });

  /*
    You'll notice that the results are now ordered alphabetically from a to z
    based on the content in the artist column. This is referred to as ascending
    order, and it's SQL's default. If you order a numerical column in ascending
    order, it will start with smaller (or most negative) numbers, with each
  */
  const [sort, setSort] = useState({
    field: "id",
    order: "ASC"
  });

  // Some program consist of more than one cert type. So need a way to gather them.
  const getCertsPerReport = (report, certtypes) => {
    return certtypes
      .filter(cert => cert.budgetreport)
      .reduce(
        (pV, cV) => (cV.budgetreport === report ? pV.concat(cV.id) : pV),
        []
      );
  };

  useEffect(() => {
    if (!budgetReport.activeView) return;

    setPayload(payload => ({
      meta: payload.meta && {},
      data: payload.data,
      loading: true
    }));

    const { certTypes } = app;
    const { dateFrom, dateTo } = budgetReport.settings;
    const { certType, viewType } = budgetReport.activeView;
    const { field, order } = sort;
    fetchDetails(
      getCertsPerReport(certType, certTypes),
      dateFrom,
      dateTo,
      viewType,
      0,
      50,
      `${field} ${order}`
    ).then(res => {
      setPayload({ meta: res.meta && {}, data: res.data, loading: false });

      // scroll to the view where it starts
      scrollTo("budget-report__details");
    });
  }, [budgetReport.activeView, budgetReport.settings, fetchDetails, sort, app]);

  const handleSort = (field, order) => {
    if (payload.loading) return;

    setSort({
      field: field,
      order: sort.field === field ? toggleOrder(sort.order) : sort.order
    });
  };

  if (budgetReport && budgetReport.activeView) {
    const { certType, viewType } = budgetReport.activeView;
    const { dateFrom, dateTo } = budgetReport.settings;
    const { data } = payload;
    const { field, order } = sort;
    const { certTypes } = app;
    const { title } = certTypesEnum[certType];
    const renderRequestType = certType !== "ota";
    const vendorOrSupplierText = certType !== "battery" ? "Vendor" : "Supplier";
    const renderIeeeType = certType === "battery";
    const renderDateCertified = viewType === "certified";
    const renderEntries = data && data.length > 0;
    const isBLC = certType === "blc";

    const renderSortButton = (title, fieldname) => (
      <button
        className="button-no-border"
        onClick={() => handleSort(fieldname, order)}
      >
        {field === fieldname ? (
          <IconLabel
            awesomeIcon={
              order.toUpperCase() === "ASC" ? "caret-up" : "caret-down"
            }
            label={title}
            iconFirst={false}
          />
        ) : (
          title
        )}
      </button>
    );

    return (
      <div>
        <SpinContainer spinning={payload.loading}>
          <h3>
            <div className="row">
              <div className="col-md-10 col-xs-10">
                {title} — {viewType}
              </div>

              <div className="col-md-2 col-xs-2 text-center">
                {renderEntries && (
                  <div className="label">
                    <DownloadButton
                      size="large"
                      action={() =>
                        downloadBudgetReport(
                          getCertsPerReport(certType, certTypes),
                          dateFrom,
                          dateTo,
                          viewType,
                          true,
                          `${field} ${order}`
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </h3>

          <Table striped condensed hover className="ctia-table">
            <thead>
              <tr>
                <th>
                  {renderSortButton(
                    "Request / Invoice ID",
                    "formatedtransactionnumber"
                  )}
                </th>
                <th>{renderSortButton(vendorOrSupplierText, "vendorname")}</th>
                <th>{renderSortButton("Model Name/Number", "modelnumber")}</th>
                {isBLC && <th>{renderSortButton("Device Type", "devicetype")}</th>}
                <th>{renderSortButton("Date Submitted", "pendingat")}</th>
                {renderDateCertified && (
                  <th>{renderSortButton("Date Certified", "completedat")}</th>
                )}
                {/* Seems redundant to render Certification, unless its battery */}
                {renderIeeeType && <th>Certification Requested</th>}
                {renderRequestType && <th>Request Type</th>}
                <th>{renderSortButton("Invoice Date", "createdat")}</th>
                <th>{renderSortButton("Payment Date", "paidat")}</th>
                <th>{renderSortButton("Payment Method", "paymentmethod")}</th>
                <th>{renderSortButton("Invoice Amount", "totalamount")}</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map(item => {
                  return (
                    <tr key={item.id}>
                      <td>{item.formatedtransactionnumber}</td>
                      <td>{item.vendorname} </td>
                      <td>{item.modelnumber}</td>
                      {isBLC && <td>{item.devicetype}</td>}
                      <td>{item.pendingat && formatDate(item.pendingat)}</td>
                      {renderDateCertified && (
                        <td>
                          {item.completedat && formatDate(item.completedat)}
                        </td>
                      )}
                      {renderIeeeType && (
                        <td>{item.formatedieeecertification}</td>
                      )}
                      {renderRequestType && <td>{item.requesttype}</td>}
                      <td>{item.createdat && formatDate(item.createdat)}</td>
                      <td>{item.paidat && formatDate(item.paidat)}</td>
                      <td>{item.paymentmethod}</td>
                      <td>{formatCurrency(item.totalamount)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </SpinContainer>
      </div>
    );
  }

  return null;
};

function mapStateToProps({ app, budgetReport }) {
  return { app, budgetReport };
}

export default connect(mapStateToProps, { fetchDetails, downloadBudgetReport })(
  EntriesList
);
