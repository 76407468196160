import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IconLabel, TooltipBase } from "ctia-ui";
import { Nav, Navbar } from "ctia-react-bootstrap-v4";
import SwitchToUser from "components/BrandNav/SwitchToUser";
import { logout, updateUserLocation } from "actions/user";

const UserInfo = ({ user, logout, updateUserLocation }) => {
  const tipText = (
    <div>
      Company: <strong>{user.company}</strong>
    </div>
  );

  return (
    <Nav>
      <SwitchToUser />
      <TooltipBase tipText={tipText} tipPlacement="bottom">
        <Navbar.Text>
          <Link
            to="/user/profile"
            className="profile-link cursor-pointer"
            onClick={() => updateUserLocation("/user/profile")}
          >
            <strong>
              <IconLabel awesomeIcon="user" label={user.name} />
            </strong>
          </Link>
        </Navbar.Text>
      </TooltipBase>
      <Navbar.Text>
        <Nav.Link onClick={logout}>
          <IconLabel awesomeIcon="sign-out-alt" label="Logout" />
        </Nav.Link>
      </Navbar.Text>
    </Nav>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { logout, updateUserLocation })(
  UserInfo
);
