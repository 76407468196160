export default {
  lab: {
    roleId: 3,
    roleName: "LAB",
    title: "Lab"
  },
  ptcrbLab: {
    roleId: 3,
    roleName: "LAB",
    title: "Laboratory"
  },
  manufacturer: {
    roleId: 2,
    roleName: "VENDOR",
    title: "Manufacturer" // This is meant to be the title used in the DB!
    // Not for display -- if displaying, please add a property called 'displayTitle'
    // And add value "Vendor"
  },
  networkOperator: {
    roleId: 7,
    roleName: "OPERATOR",
    title: "Operator"
  },
  supplier: {
    roleId: 9,
    roleName: "VENDOR",
    title: "Supplier"
  },
  staff: {
    roleId: 5,
    roleName: "ADMIN",
    title: "CTIA Staff"
  },
  accounting: {
    roleId: 8,
    roleName: "ACCOUNTING",
    title: "CTIA Accounting"
  },
  asc: {
    roleId: 8,
    roleName: "SERVICECENTER",
    title: "Service Center"
  },
  unassigned: {
    roleId: null,
    roleName: "UNASSIGNED",
    title: "unassigned"
  }
};
