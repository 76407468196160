import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsHelperPRR";
import * as th from "helpers/RequestTestsHelper";
import * as sh from "helpers/ManufSiteHelper";
import * as actions from "actions/types";
import * as sites from "actions/sites";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as breq from "actions/battery/batteryRequests";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";

export function initPRR(companyid = null) {
  return call.dispatchWithPreloader(sites.fetchNonSarSites, companyid);
}

export const fetchEcoModelsPRR = (ieee, companyid) => async dispatch => {
  try {
    const url = `/prr/devicemodels?where=ieee${ieee}prr=1 AND vendorid=${companyid}&include=devicemodelbatterycomponent&select=id,modelnumber,capacity,voltage,capacityrateid,voltagerateid&orderBY=modelnumber ASC&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_PRR_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoPRR = (ieee, companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(fetchEcoModelsPRR(ieee, companyid))
    .then(() => dispatch(sites.fetchNonSarSites(companyid)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const createRequestPRR = request => async dispatch => {
  try {
    const prrArgs = args.prepareInitialArgsPRR(request);
    const response = await restClientGeneral.post(
      prrArgs.url,
      JSON.stringify(prrArgs.body)
    );

    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        isPRR: true
      }
    });

    const agreementSigned =
      request.complianceDeclarationToggler &&
      request.recognitionAgreementToggler;

    if (agreementSigned) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialPRR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createRequestPRR(request))
      .then(res => {
        const { id, devicemodelid } = res.data.data;
        const modelId = devicemodelid || id;
        const needAgreementSign =
          request.complianceDeclarationToggler &&
          request.recognitionAgreementToggler;

        if (needAgreementSign) {
          return dispatch(
            sign.signDeclarationAgreement(modelId, request.ieee, "prr")
          ).then(() =>
            dispatch(
              sign.signRecognitionAgreement(modelId, request.ieee, "prr")
            )
          );
        } else return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const createEcoRequestPRR = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsPRR(request);
    const response = await restClientGeneral.post(
      "prr/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid } = response.data.data;

    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        newId: id,
        deviceModelId: devicemodelid,
        isPRR: true
      }
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoPRR(request) {
  return call.dispatchWithPreloader(createEcoRequestPRR, request);
}

export const getRequestDetailsPRR = id => async dispatch => {
  try {
    const url = `/prr/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_PRR, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

// export const getParentRequestDetailsCRR = id => async dispatch => {
//   try {
//     const url = `/crr/requests/${id}/details`;
//     const response = await restClientGeneral.get(url);
//     const payload = response.data.data;

//     dispatch({ type: actions.FETCH_PARENT_REQUEST_CRR, payload });

//     return response;
//   } catch (e) {
//     dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

//     return Promise.reject(e);
//   }
// };

export const fetchRequestTestsPRR = requestId => async dispatch => {
  try {
    const url = `/prr/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsPRR = testModule => async dispatch => {
  try {
    const url = `/prr/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsPRR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsPRR(requestId))
      .then(res => {
        const reqData = res.data.data;

        // ECO initially has just parent's sites, but after update might have its own
        const nonSarSites = sh.selectNonSarSitesInitialOrEco(reqData);

        return dispatch(
          sites.fetchNonSarSites(reqData.submitter.companyid)
        ).then(() => dispatch(breq.mapNonSarManufSites(nonSarSites)));
      })
      .then(() => dispatch(fetchRequestTestsPRR(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsPRR({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function submitTestResultsPRR(request, testModule) {
  const testResArgs = args.prepareTestResultsArgs(request, testModule);
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsPRR(testResArgs, request.id))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsPRR = (args, reqId) => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/prr/requests/${reqId}`,
      JSON.stringify(args)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateModelPRR = modelArgs => async dispatch => {
  try {
    const url = `/prr/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialPRR(modelId, requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signDeclarationAgreement(modelId, ieee, "prr"))
      .then(() => dispatch(sign.signRecognitionAgreement(modelId, ieee, "prr")))
      .then(() => dispatch(getRequestDetailsPRR(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export function mapSelectedModelFields(modelFields) {
  return {
    type: actions.MAP_PRR_MODEL_FIELDS,
    payload: modelFields
  };
}

export function mapManufSitesPRR(linkedSites) {
  return {
    type: actions.MAP_NON_SAR_SITES,
    payload: linkedSites
  };
}

export const getModelDetailsPRR = (modelId, ieee) => async dispatch => {
  try {
    const url = `/prr/requests?include=requestmanufsites,linkedrequests,requestpendingrecognitions&where=devicemodelid=${modelId} AND requeststatusid <> 8 AND ieeetypeid=${ieee}&orderBY=requestid DESC&perPage=1`;
    return await restClientGeneral.get(url);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelPRR(modelId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getModelDetailsPRR(modelId, ieee))
      .then(res => {
        const payload = res.data.data[0];

        dispatch({
          type: actions.FETCH_PRR_MODEL,
          payload
        });

        return res;
      })
      .then(res =>
        dispatch(mapManufSitesPRR(res.data.data[0].requestmanufsites))
      )
      .then(quit)
      .catch(quit);
  };
}

export const updateDeviceModel = deviceModel => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/prr/devicemodels/${deviceModel.args.modelId}`,
      JSON.stringify(deviceModel)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateDeviceInfoPRR(request, sites) {
  const deviceModel = args.prepareModelArgsPRR(request);
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(req.updateRequest(reqArgs)))
      .then(res => {
        const { requeststatusid } = res.data.data;

        // update request status if any:
        dispatch({
          type: actions.CHANGE_REQUEST_FIELD,
          payload: { field: "status", value: requeststatusid }
        });

        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function makeLabDecisionPRR(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `prr/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export function setRequestPermissionsForLabReadonlyPRR() {
  const payload = {
    canEditVoltageCapacity: false
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function setLabTestPermissionsPRR() {
  const payload = {
    canEditVoltageCapacity: true,
    canEditDeviceInfo: true
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export const updateTestReportStatusPRR = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "prr"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsPRR(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportPRR(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: PRR<000000>_REPORT_<Slot>.<extension>
  // e.g. PRR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/prr/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsPRR(request) {
  // file name: PRR<000000>_REPORTS.zip
  // e.g. PRR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/prr/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
