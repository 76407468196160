import React from "react";
import TestStatusTableRow from "containers/request/Initial/TestStatusTableRow";
import LabTestInfoContainerPRR from "containers/request/PRR/LabTestInfoContainerPRR";

const customTitles = {
  col1: "Test",
  col2: "Lab",
  col3: "Status",
  col1Row: "Battery Compliance"
};

const LabTestInfoPRR = ({ editable }) => {
  return (
    <LabTestInfoContainerPRR
      editable={editable}
      customTitles={customTitles}
      statusComponent={TestStatusTableRow}
    />
  );
};

export default LabTestInfoPRR;
