import React from "react";
import { connect } from "react-redux";
import RequestVoltage from "components/request/RequestVoltage";
import PackCells from "containers/request/battery/PackCells/PackCellsContainer";
import PackCellsReadonly from "components/request/PackCellsReadonly";

const PackDetailsHRR = ({ request, user }) => {
  if (request.id > 0) {
    const [pack] = request.deviceTypes;

    if (request.isPack || pack.checked) {
      return user.canEditDeviceInfo ? (
        <>
          <PackCells />
          <RequestVoltage
            fieldId="capacity"
            fieldLabel="Capacity (mAh)"
            shouldBeNumber={!request.isLegacy}
            editable={true}
          />
        </>
      ) : (
        <>
          <PackCellsReadonly />
          <RequestVoltage
            fieldId="capacity"
            fieldLabel="Capacity (mAh)"
            shouldBeNumber={!request.isLegacy}
            editable={false}
          />
        </>
      );
    }
  }

  return null;
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(PackDetailsHRR);
