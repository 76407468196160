import React from "react";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestInitialModelBLC from "containers/request/BLC/RequestInitialModelBLC";
import RequestHWVersion from "components/request/RequestHWVersion";
import RequestSWVersion from "components/request/RequestSWVersion";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import { setValue } from "actions/request";
import {
  initBLC,
  updateDeviceInfoBLC,
  setRequestPermissions
} from "actions/battery/blcRequest";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import RequestOperatingSystem from "components/request/RequestOperatingSystem";
import BatteryPack from "containers/request/battery/BatterySupplier/BatteryPacksBLC";
import SelectDeviceType from "containers/request/BLC/RequestDeviceTypeBLC";

class RequestContainerBLC extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const { request, user, initBLC, setRequestPermissions } = this.props;

    // fetch only for the first time on submissions
    if (!this.isValidToSubmit() && !request.id) {
      initBLC();
    }

    // fetch for existed requests
    if (request.id) {
      initBLC(request.devicemodelid);
      setRequestPermissions(request, user);
    }

  }

  onSaveClick = () => {
    const { request, updateDeviceInfoBLC } = this.props;
    updateDeviceInfoBLC(request);
  };

  validateModel() {
    return hasStringValue(this.props.request, this.modelField);
  }

  validateBatteryPack() {
    const { batteryPackId } = this.props.request;
    return batteryPackId && batteryPackId > 0;
  }

  validateHwVersion() {
    return hasStringValue(this.props.request, "hwVersion");
  }

  validateSwVersion() {
    return hasStringValue(this.props.request, "swVersion");
  }

  validateOpSys() {
    const { operatingSystem } = this.props.request;
    return operatingSystem && operatingSystem > 0;
  }

  isValidToUpdate() {
    const { user } = this.props;

    if (user.isSupplier) return this.isValidToSubmit();

    // until lab accepts and provided results:
    if (user.isStaff && !user.canEditLabFields) return this.isValidToSubmit();

    return this.isValidToSubmit();
  }

  isValidToSubmit() {
    const { canEditDeviceInfo } = this.props.user;

    // don't need to validate if form is non-editable
    if (canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateModel() &&
      this.validateBatteryPack() &&
      this.validateHwVersion() &&
      this.validateSwVersion() &&
      this.validateOpSys()
    );
  }

  render() {
    const { request, user } = this.props;
    const { canEditDeviceInfo, canEditDeviceType } = user;
    const saveHandler = (canEditDeviceInfo || canEditDeviceType) ? this.onSaveClick : null;
    const isValid =
      request.id > 0 ? this.isValidToUpdate() : this.isValidToSubmit();

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestManufacturer />
          <RequestInitialModelBLC />
          {request[this.modelField] && (
            <>
              <SelectDeviceType />
              <hr />
              <BatteryPack />
              <RequestHWVersion />
              <RequestSWVersion />
              <RequestOperatingSystem />
              <SubmissionDetails />
            </>
          )}
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(mapStateToProps, {
  initBLC,
  submitWizardStep,
  updateDeviceInfoBLC,
  setRequestPermissions,
  setValue
})(RequestContainerBLC);
