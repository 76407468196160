import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import TestPlanList from "containers/request/OTA/TestPlans/OTATestPlanList";
import TestPlanSelector from "containers/request/OTA/TestPlans/OTATestPlanSelector";
import Instructions from "containers/request/OTA/TestPlans/OTATestPlansBubble";
import { isExisted } from "helpers/RequestHelper";

class EditTestPlans extends CyberComponent {
  validateInterfaces() {
    return _.size(this.props.request.otaInterfaces) > 0;
  }

  validate() {
    // don't need to validate if form is non-editable
    if (!this.props.user.canEditRequest) return true;

    // check if at least 1 test plan was added
    return this.validateInterfaces();
  }

  render() {
    return (
      <div>
        <Form horizontal>
          <TestPlanList user={this.props.user} />
          <Instructions />
          <TestPlanSelector />
          <RequestControls
            show={!isExisted(this.props.request)}
            isValid={this.validate()}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(EditTestPlans);
