import React from "react";
import { connect } from "react-redux";
import SelectAdapterType from "containers/request/battery/AdapterSupplier/SelectAdapterType";
import SelectModel from "containers/request/battery/AdapterSupplier/SelectAdapterModel";
import SelectRevision from "containers/request/battery/AdapterSupplier/SelectAdapterRevision";
import AddButton from "containers/request/battery/AdapterSupplier/AdapterSupplierAdd";
import SelectContainer from "containers/request/battery/AdapterSupplier/SelectSupplierContainer";
import NoDataMessage from "containers/request/battery/BatterySupplier/NoDataMessage";

const AdapterSupplierSelector = ({ suppliers }) => {
  if (!suppliers) return null;

  const {
    approvededAdapterSuppliers: asup,
    approvededRecognitionSuppliers: rsup
  } = suppliers;

  if (!asup && !rsup) {
    return <NoDataMessage message="No adapter suppliers available" />;
  }

  return (
    <div>
      <SelectAdapterType />
      <SelectContainer />
      <SelectModel />
      <SelectRevision />
      <AddButton />
    </div>
  );
};

function mapStateToProps({ suppliers }) {
  return { suppliers };
}

export default connect(mapStateToProps)(AdapterSupplierSelector);
