import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import LogoPng from "containers/Logo/LogoPng";
import CtiaButton from "components/form/buttons/CtiaButton";
import { ctiaSupportEmail } from "helpers/Emails";
import "containers/Footer/style.css";

const Footer = () => {
  const currYear = moment().format("YYYY");

  return (
    <footer className="app-footer">
      <div className="footer__wrap">
        <div className="footer__logo">
          <LogoPng cssClass="footer__logo--inverted" inverted={true} />
        </div>
        <div className="footer__content">
          <div className="footer__flex">
            <p className="footer__paragraph">
              CTIA Certification™ is the global leader in certification for the
              mobile wireless industry. Since 1991, our trusted certification
              programs have set the standard for devices and test laboratories,
              playing a crucial role in advancing the wireless industry.{" "}
            </p>
            <a href={`mailto:${ctiaSupportEmail}`}>
              <CtiaButton label="Contact Us" />
            </a>
          </div>
          <div className="footer__menus">
            <nav className="nav nav--footer footer__menu">
              <ul className="nav__menu nav__menu--level-0">
                <li className="nav__menu-item nav__menu-item--type-custom nav__menu-item--object-custom nav__menu-item--101 nav__menu-item--level-0">
                  <Link className="nav__link" to="/terms">
                    Terms of Use
                  </Link>
                </li>
                <li className="nav__menu-item nav__menu-item--type-custom nav__menu-item--object-custom nav__menu-item--102 nav__menu-item--level-0">
                  <Link className="nav__link" to="/policy">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </nav>
            <div className="footer__flex">
              <nav
                className="nav nav--social footer__social"
                aria-label="Follow Us"
              >
                <ul className="nav__menu nav__menu--level-0">
                  <li className="nav__menu-item">
                    <a
                      className="nav__link"
                      href="https://twitter.com/CTIACert"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        role="img"
                        aria-label="twitter"
                        className="svg--symbol svg--twitter"
                        height="24"
                        width="24"
                        focusable="false"
                      >
                        <svg id="icon-twitter" viewBox="0 0 32 32">
                          <path d="M32 7.075a12.941 12.941 0 0 1-3.769 1.031 6.601 6.601 0 0 0 2.887-3.631 13.21 13.21 0 0 1-4.169 1.594A6.565 6.565 0 0 0 22.155 4a6.563 6.563 0 0 0-6.563 6.563c0 .512.056 1.012.169 1.494A18.635 18.635 0 0 1 2.23 5.195a6.56 6.56 0 0 0-.887 3.3 6.557 6.557 0 0 0 2.919 5.463 6.565 6.565 0 0 1-2.975-.819v.081a6.565 6.565 0 0 0 5.269 6.437 6.574 6.574 0 0 1-2.968.112 6.588 6.588 0 0 0 6.131 4.563 13.17 13.17 0 0 1-9.725 2.719 18.568 18.568 0 0 0 10.069 2.95c12.075 0 18.681-10.006 18.681-18.681 0-.287-.006-.569-.019-.85A13.216 13.216 0 0 0 32 7.076z"></path>
                        </svg>
                      </svg>
                    </a>
                  </li>
                  {/* <li className="nav__menu-item">
                    <a
                      className="nav__link"
                      href="//ctia.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        role="img"
                        aria-label="facebook"
                        className="svg--symbol svg--facebook"
                        height="24"
                        width="24"
                        focusable="false"
                      >
                        <svg id="icon-facebook" viewBox="0 0 11 22">
                          <path
                            d="M6.847 22V11.677h3.965L11 7.96H6.847V5.242c0-.566.107-.98.32-1.246.214-.265.641-.397 1.282-.397.847 0 1.66.003 2.44.01l.089-3.47-.331-.032a26.83 26.83 0 0 0-1.005-.07C9.192.014 8.74 0 8.283 0c-.92 0-1.74.13-2.457.392-.718.261-1.292.618-1.723 1.069-.43.451-.755.958-.972 1.52a4.949 4.949 0 0 0-.326 1.8V7.96H0v3.717h2.805V22h4.042z"
                            fill="#FFF"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </svg>
                    </a>
                  </li> */}
                  <li className="nav__menu-item">
                    <a
                      className="nav__link"
                      href="https://www.linkedin.com/company/ctia-certification/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <svg
                        role="img"
                        aria-label="linkedin"
                        className="svg--symbol svg--linkedin"
                        height="24"
                        width="24"
                        focusable="false"
                      >
                        <svg id="icon-linkedin" viewBox="0 0 32 32">
                          <path d="M12 12h5.535v2.837h.079c.77-1.381 2.655-2.837 5.464-2.837C28.92 12 30 15.637 30 20.367V30h-5.769v-8.54c0-2.037-.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509V30H12V12zM2 12h6v18H2V12zM8 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                        </svg>
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
              <p className="footer__copyright">
                Copyright © CTIA Certification {currYear}. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
