import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CyberComponent from "containers/CyberComponent";
import { Bubble } from "ctia-ui";
import LabTestInfo from "containers/request/Initial/LabTestInfo";
import LabTestResult from "containers/request/Initial/LabTestResult";
import RequestControls from "containers/request/RequestControls";
import { saveRequest } from "actions/request";

class LabInfo extends CyberComponent {
  validate() {
    return this.props.request.cdmaLab > 0;
  }

  render() {
    const { show, isEditable, saveRequest, canSubmit } = this.props;

    if (!show) return null;

    const saveHandler = isEditable ? saveRequest : null;

    return (
      <div>
        <Bubble show={canSubmit}>Please select a lab for the testing.</Bubble>
        <LabTestInfo editable={isEditable} />
        <hr />
        <LabTestResult testTitle="Cybersecurity" />
        <RequestControls isValid={this.validate()} onSave={saveHandler} />
      </div>
    );
  }
}

LabInfo.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

LabInfo.defaultProps = {
  show: true,
  canSubmit: false
};

// NOTICE: need to keep `user` since it's required for CyberComponent lifecycle
function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  saveRequest
})(LabInfo);
