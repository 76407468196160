import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SupportDocs from "components/SupportDocs";
import RequestControls from "containers/request/RequestControls";
import { submitEcoBLC } from "actions/battery/blcRequest";

class DocumentsBLC extends Component {
  onSubmit = () => {
    const { request, submitEcoBLC } = this.props;
    return submitEcoBLC(request);
  };

  render() {
    if (!this.props.show) return null;

    return (
      <div>
        <SupportDocs />
        <RequestControls isValid={true} onSubmit={this.onSubmit} />
      </div>
    );
  }
}

DocumentsBLC.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

DocumentsBLC.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ request, requests }) {
  return { request, requests };
}

export default connect(mapStateToProps, {
  submitEcoBLC
})(DocumentsBLC);
