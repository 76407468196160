import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import BatteryTestResultsInfoBar from "containers/request/battery/TestResults/BatteryTestResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import PageTitle from "components/RequestPageTitle";
import LabResultFormARR from "containers/testResult/LabResultFormARR";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewView from "containers/testResult/UnderReviewViewCommon";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import { clearRequest, setBatteryRequestPermissions } from "actions/request";
import {
  submitTestResultsARR,
  getDetailsARR,
  downloadReportARR
} from "actions/battery/arrRequest";

const backURL = "/battery/lab/results";

class TestResultsItemARR extends Component {
  state = { showContent: false };

  fetchHandler = reqId => {
    return this.props.getDetailsARR(reqId).then(() => {
      const { request, user } = this.props;
      this.props.setBatteryRequestPermissions(request, user);
      this.activateRender();
    });
  };

  componentDidMount() {
    this.resetRender();
    this.fetchHandler(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    const newId = nextProps.match.params.id;
    if (newId !== this.props.match.params.id) {
      this.fetchHandler(newId);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, revision, crslversion, linkedManufsites } = request;
    const { reports, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 && revision !== "" && crslversion !== "";

    if (!commonFieldsValidate) return false;

    // at least 1 non SAR sites is required
    const validateSites =
      linkedManufsites.length && _.some(linkedManufsites, "checked");

    if (!validateSites) return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { request, tests, submitTestResultsARR } = this.props;
    return submitTestResultsARR(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportARR } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBattery clientType="general" certType="arr" />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <Link to={backURL}>
          <LoaderButton text="&larr; Return to list" />
        </Link>
        <PageTitle />
        <BatteryTestResultsInfoBar />
        <UnderReviewView
          show={completed}
          testModule={testModule}
          request={request}
        />
        <LabResultFormARR show={!completed} testModule={testModule} />
        <LabResultUpload
          show={!completed}
          downloadHandler={downloadReportARR}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  getDetailsARR,
  submitTestResultsARR,
  downloadReportARR,
  setBatteryRequestPermissions
})(TestResultsItemARR);
