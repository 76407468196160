import React from "react";
import { WithModal } from "ctia-ui";
import EditVendorModal from "containers/ControlPanel/Orgs/Vendors/EditVendorModal";

class EditVendor extends WithModal {
  render() {
    const { id, name } = this.props;

    return (
      <div className="glow-link cursor-pointer">
        <span onClick={this.handleModal}>{name}</span>
        {this.state.showModal && (
          <EditVendorModal id={id} modalHandler={this.handleModal} />
        )}
      </div>
    );
  }
}

export default EditVendor;
