import _ from "lodash";
import { signup } from "actions/user";
import React, { Component } from "react";
import { Col, Form, Grid, Row, Well } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Bubble, Divider, LoaderButton } from "ctia-ui";
import ErrorForm from "components/form/ErrorForm";
import Success from "containers/Signup/SignupSuccess";
import LogoPng from "containers/Logo/LogoPng";
import {
  userInfoFields,
  userInfoFormFields
} from "components/signup/FormConfiguration/userInfoFields";
import FormField from "components/signup/FormElements/FormField";
import { validateEmail, validatePhone } from "helpers/ValidatorHelper";
import { scrollTo } from "helpers/AppHelper";
import { mergeExtPhone } from "helpers/UsersHelper";
import "./style.css";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Page params
      isLoading: false,
      signupSuccessful: false,
      errorMessages: null,
      showForgotPasswordMessage: false,

      // Form fields
      firstName: "",
      lastName: "",
      email: "",
      businessTitle: "",
      workPhone: "",
      workPhoneExt: "",
      mobilePhone: "",
      address1: "",
      address2: "",
      address3: "",
      city: "",
      adrState: "",
      zipCode: "",
      country: "",
      description: "",
      companyType: "",
      companyName: "",
      companyOther: "",
      website: ""
    };
  }

  validateForm() {
    // remove `adrState` from list to be checked in generic func
    const requiredFields = _.pickBy(userInfoFormFields, field => {
      return (
        field.required === true && field.type !== "stateAndProvinceSelector"
      );
    });

    const requiredFieldNames = Object.keys(requiredFields).concat(
      "companyName",
      "website"
    );

    // make sure that if "Enter Other" selected for test labs -
    // we aslo require the value to be provided
    if (this.state.companyName === "other") {
      requiredFieldNames.push("companyOther");
    }

    for (let fieldName of requiredFieldNames) {
      if (!this.state[fieldName] || this.state[fieldName].length === 0) {
        return false;
      }
    }

    // make sure we skip state value for non US/Canada countries
    const { country, adrState, email, workPhone, mobilePhone } = this.state;
    const isStateRequired = Boolean(country === "US" || country === "CA");

    if (isStateRequired && adrState === "") return false;

    // validate email
    if (!validateEmail(email)) return false;

    // validate phones
    if (!validatePhone(workPhone)) return false;
    if (mobilePhone !== "" && !validatePhone(mobilePhone)) return false;

    return true;
  }

  handleChange = event => {
    let fieldValue = event.target.value;
    const fieldChanged = event.target.id;

    // Limit number of characters to max length of field
    const fieldChangedProp = userInfoFormFields[fieldChanged];
    if (
      !_.isUndefined(fieldChangedProp) &&
      fieldValue.length >= fieldChangedProp.maxLength
    ) {
      fieldValue = fieldValue.substr(0, fieldChangedProp.maxLength);
    }

    // Clean up the state selector value in case we selected another country
    if (fieldChanged === "country") {
      this.setState({ adrState: "" });
    }

    this.setState({
      [fieldChanged]: fieldValue
    });
  };

  // Mark: Submission functions

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const { country, adrState } = this.state;
    const stateid = country === "US" || country === "CA" ? adrState : "";

    const signupFields = {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      email: this.state.email,
      company:
        this.state.companyName !== "other"
          ? this.state.companyName
          : this.state.companyOther,
      companytype: this.state.companyType,
      title: this.state.businessTitle,
      address: this.state.address1,
      address2: this.state.address2,
      address3: this.state.address3,
      city: this.state.city,
      countryid: this.state.country,
      stateid,
      zipcode: this.state.zipCode,
      workphone: mergeExtPhone(this.state.workPhone, this.state.workPhoneExt),
      mobilephone: this.state.mobilePhone,
      description: this.state.description,
      website: this.state.website
    };

    this.props.signup(
      signupFields,
      this.successfulSubmission,
      this.failedSubmission
    );
  };

  successfulSubmission = () => {
    this.setState({
      isLoading: false,
      signupSuccessful: true
    });
  };

  failedSubmission = errors => {
    const errorMessages = [];

    if (errors[0]) {
      errors[0].map(err => {
        errorMessages.push(err);

        // We can't rely this only by email field
        // Cuz another related to email errors may occur
        if (err.MESSAGE === "Email has already been taken.") {
          this.setState({
            showForgotPasswordMessage: true
          });
        }

        return err;
      });

      scrollTo("signup-form-top");
    }

    this.setState({
      isLoading: false,
      signupSuccessful: false,
      errorMessages
    });
  };

  // Mark: Form functions

  getFormField(fieldKey, formFieldDefinition) {
    // Add any necessary parameters needed to the form
    switch (fieldKey) {
      case "adrState":
        formFieldDefinition.selectedCountryCode = this.state.country;
        break;

      default:
        break;
    }

    return (
      <FormField
        formFieldDefinition={formFieldDefinition}
        fieldKey={fieldKey}
        fieldValue={this.state[fieldKey]}
        changeHandler={this.handleChange}
      />
    );
  }

  getRowColumns(rowData) {
    return _.entries(rowData).map(([fieldKey, formFieldData], index) => {
      const column = this.getFormField(fieldKey, formFieldData);
      const columnWidth = formFieldData.columnWidth;
      const { customClass } = formFieldData;

      return (
        <Col
          className={customClass ? customClass : "FormColumns"}
          md={columnWidth}
          key={index}
        >
          {column}
        </Col>
      );
    });
  }

  getFormFields() {
    return _.values(userInfoFields).map((row, index) => {
      const rowData = this.getRowColumns(row);
      const id = row.id || `form-row-${index}`;

      return (
        <Row id={id} key={id}>
          {rowData}
        </Row>
      );
    });
  }

  render() {
    const {
      firstName,
      lastName,
      signupSuccessful,
      errorMessages,
      showForgotPasswordMessage
    } = this.state;
    if (!_.isUndefined(signupSuccessful) && signupSuccessful) {
      return <Success name={`${firstName} ${lastName}`} />;
    }

    return (
      <div className="Signup">
        <Grid>
          {/* Page Top */}
          <div className="logo text-left">
            <LogoPng linkTo="/" />
          </div>
          <Row id="signup-form-top">
            <Col xs={9}>
              <h1 className="block-bottom-offset">User Registration Request</h1>
            </Col>
            <Col xs={2}>
              <Well bsSize="small">
                <span className="text-danger">* </span> Required fields
              </Well>
            </Col>
          </Row>

          {/* Error Messages */}
          <ErrorForm errors={errorMessages} />
          {showForgotPasswordMessage && (
            <Bubble type="warning" show={true}>
              If you forgot your password,&nbsp;
              <Link to="/user/recover" className="glow-link InlineText">
                click here
              </Link>
              &nbsp;to have your password reset.
            </Bubble>
          )}

          {/* Signup Form */}
          <Form onSubmit={this.handleSubmit} horizontal>
            {this.getFormFields()}
            <Divider />
            <Row className="show-grid">
              <Col md={4} xs={1} />
              <Col md={4} xs={10} className="text-center">
                <LoaderButton
                  disabled={!this.validateForm()}
                  type="submit"
                  bsStyle="info"
                  bsSize="large"
                  isLoading={this.state.isLoading}
                  text="Submit"
                  loadingText="Submitting…"
                />
              </Col>
              <Col md={4} xs={1} />
            </Row>
          </Form>
        </Grid>
      </div>
    );
  }
}

export default connect(null, { signup })(Signup);
