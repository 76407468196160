// ES6 / Helper
import _ from "lodash";

// React / Redux
import { Link } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";

// Components
import DelistDeviceRecognitionButton from "components/DeviceRecognition/DelistButton/DelistButton";
import WithdrawButton from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawButton";

// Dictionaries and Helpers
import { getCertTypeFromCertTypeId, types } from "helpers/BatteryCertHelper";
import { getDeviceRowStatus } from "helpers/ModelHelper";

const BatteryDeviceRow = ({
  requestReloadTable,
  row,
  showDelistColumn,
  showStatusColumn,
  showWithdrawColumn,
  app,
  devices
}) => {
  const {
    certtypeid: certTypeId,
    delistedat: delistedAt,
    id: modelId,
    modelnumber: modelNumber,
    name: modelName,
    requesttypeid: requestTypeId,
    withdrawnat: withdrawnAt,
    withdrawreason: withdrawnReason
  } = row;

  const statusId = app.batteryRequestStatus.id;
  const userSelectedCertType = devices.searchTerms.certTypes[0];
  const status = getDeviceRowStatus(userSelectedCertType, statusId, delistedAt);

  const requestCertType = getCertTypeFromCertTypeId({
    certTypeId: certTypeId,
    certTypeIds: app.certTypes
  });
  const certAbbreviation = requestCertType ? requestCertType.name : "";

  return (
    <tr key={`battery-device-${modelId}`}>
      <td>{modelName}</td>
      <td>
        <Link to={`/battery/devices/model/${modelId}`} className="glow-link">
          {modelNumber}
        </Link>
      </td>
      <td>{certAbbreviation}</td>
      {showStatusColumn && <td>{status}</td>}
      {showWithdrawColumn && (
        <td>
          <WithdrawButton
            deviceModelId={modelId}
            modalInfo={row}
            onSuccess={requestReloadTable}
            requestStatusId={statusId}
            requestTypeId={requestTypeId}
            shouldDelistOrRelistOnSuccess={userSelectedCertType !== types.BSC}
            withdrawnAt={withdrawnAt}
            withdrawReason={withdrawnReason}
          />
        </td>
      )}
      {showDelistColumn && (
        <td>
          <DelistDeviceRecognitionButton
            isDelisted={!_.isEmpty(delistedAt)}
            certTypeId={certTypeId}
            deviceModelId={modelId}
            onSuccess={requestReloadTable}
          />
        </td>

      )}
    </tr>
  );
};

function mapStateToProps({ app, devices }) {
  return { app, devices };
}

export default connect(mapStateToProps)(BatteryDeviceRow);
