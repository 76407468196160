import * as actions from "actions/types";
import { restClientGeneral } from "libs/restClient";

export function resizeWindow() {
  return {
    type: actions.RESIZE_WINDOW,
    payload: null
  };
}

export function toggleViewMode() {
  return {
    type: actions.TOGGLE_VIEW_MODE,
    payload: null
  };
}

export function resetCertProgramStatus() {
  return {
    type: actions.RESET_CERT_PROGRAM_STATUS,
    payload: null
  };
}
export function changeCertProgram(id) {
  return {
    type: actions.CHANGE_CERT_PROGRAM,
    payload: id
  };
}

export function setPageSize(size = 50) {
  return {
    type: actions.SET_PAGE_SIZE,
    payload: size
  };
}

export function changeCyberAdminUserType(userType = 0) {
  return {
    type: actions.CHANGE_CYBER_ADMIN_USER_TYPE,
    payload: userType
  };
}

/* Clearing stored value if nothing passed */
export function setRequestViewActiveTab(tab = null) {
  return {
    type: actions.SET_REQUEST_VIEW_ACTIVE_TAB,
    payload: tab
  };
}

/* Clearing stored value if nothing passed */
export function setRequestListActiveTab(tab = null) {
  return {
    type: actions.SET_REQUEST_LIST_ACTIVE_TAB,
    payload: tab
  };
}

// *********************************************************************
// =========== Request Table filters (Cyber, OTA, Battery) =============
// *********************************************************************
export function changeCyberRequestStatus(status = 1) {
  return {
    type: actions.CHANGE_CYBER_REQUEST_STATUS,
    payload: status
  };
}

export function changeCyberRequestTestStatus(status = "Pending") {
  return {
    type: actions.CHANGE_CYBER_REQUEST_TEST_STATUS,
    payload: status
  };
}

export function changeOtaRequestStatus(status = 1) {
  return {
    type: actions.CHANGE_OTA_REQUEST_STATUS,
    payload: status
  };
}

export function changeOtaRequestTestStatus(status = "All") {
  return {
    type: actions.CHANGE_OTA_REQUEST_TEST_STATUS,
    payload: status
  };
}

export function changeBatteryRequestStatus(status = 1) {
  return {
    type: actions.CHANGE_BATTERY_REQUEST_STATUS,
    payload: status
  };
}

export function changeBatteryRequestTestStatus(status = "Pending") {
  return {
    type: actions.CHANGE_BATTERY_REQUEST_TEST_STATUS,
    payload: status
  };
}

export function changeRequestTestStatusDHR(status = "Pending") {
  return {
    type: actions.CHANGE_DHR_REQUEST_TEST_STATUS,
    payload: status
  };
}

export const getCertTypes = () => async dispatch => {
  try {
    const response = await restClientGeneral.get("certtypes?perPage=25");

    dispatch({
      type: actions.GET_CERT_TYPES,
      payload: response.data.data
    });
  } catch (e) {
    console.error(e);
  }
};

export function toggleUserStatus() {
  return {
    type: actions.TOGGLE_USER_STATUS
  };
}
