import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { IconLabel } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import AddOrg from "containers/ControlPanel/Orgs/AddOrg";
import OrgTypeSelector from "containers/ControlPanel/Orgs/OrgTypeSelector";
import SortableTable from "containers/SortableTable";
import ListFilter from "components/ListFilter";
import pluralize from "pluralize";

const OrgListGeneric = ({ org, headerFields, rowRenderer }) => {
  const [filterTerm, setFilterTerm] = useState("");

  const handleSort = (item, sortField) => {
    if (sortField && sortField === "active") {
      return !item.active;
    }

    return String(item[sortField]).toLowerCase();
  };

  const renderList = list => {
    if (!list) return "Loading...";

    return (
      <SortableTable
        list={list}
        header={headerFields}
        colNum={headerFields.length}
        handleSort={handleSort}
        rowRenderer={rowRenderer}
      />
    );
  };

  const renderCounter = list => {
    if (!list) return null;

    const pluralizedLabel = pluralize("record", list.length, true);

    return <IconLabel awesomeIcon="book" label={pluralizedLabel} />;
  };

  const applyFilter = list => {
    if (!list) return null;
    if (!filterTerm) return list;

    return _.filter(list, org => {
      // convert UPPER CASE to lower case for case insensitive search
      const orgName = String(org.name).toLowerCase();
      const contact = String(org.contactname).toLowerCase();
      const city = String(org.citydisplay).toLowerCase();
      const country = String(org.countrydisplay).toLowerCase();

      return (
        orgName.indexOf(filterTerm.toLowerCase()) !== -1 ||
        contact.indexOf(filterTerm.toLowerCase()) !== -1 ||
        city.indexOf(filterTerm.toLowerCase()) !== -1 ||
        country.indexOf(filterTerm.toLowerCase()) !== -1
      );
    });
  };

  const filteredList =
    org && org.orgList && org.orgList.length ? applyFilter(org.orgList) : null;

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div style={{ display: "flex" }} className="h1-div">
            <div>Organization Administration,&nbsp;</div>
            <OrgTypeSelector />
          </div>
          <ListFilter
            label="Search Organization"
            onChange={value => setFilterTerm(value)}
          />
          <p>
            Below is a list of the organizations that currently reside in the
            system.
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>{renderCounter(filteredList)}</Col>
        <Col xs={6} className="text-right">
          <AddOrg />
        </Col>
      </Row>
      {renderList(filteredList)}
    </div>
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps)(OrgListGeneric);
