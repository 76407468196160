import _ from "lodash";
import React from "react";

const UserProfilePasswordRules = ({
  newPassword = "",
  confirmPassword = ""
}) => {
  const passwordIsEmpty = _.isEmpty(newPassword) && _.isEmpty(confirmPassword);
  const passwordIsEightOrMoreCharacters = newPassword.length >= 8;

  const hasLetters = newPassword.match("[a-zA-Z]+");
  const hasDigits = newPassword.match("[0-9]+");
  const hasSpecChars = newPassword.match("[^0-9a-zA-Z]+");

  const regexRulesMet = _.every(
    [hasLetters, hasDigits, hasSpecChars],
    rule => rule !== null
  );

  const ruleColor = ruleIsMet => {
    if (ruleIsMet) {
      return "green";
    }
    return passwordIsEmpty || regexRulesMet ? "gray" : "red";
  };

  return (
    <>
      <div>
        <h4>Password Requirements:</h4>
        <li className={ruleColor(passwordIsEightOrMoreCharacters)}>
          Must be 8 or more characters in length
        </li>
        <li className={ruleColor(regexRulesMet)}>
          Must contain at least one character from all of the following
          categories:
          <ul>
            <li className={ruleColor(hasLetters)}>
              English alphabetic characters (upper/lower case)
            </li>
            <li className={ruleColor(hasDigits)}>Base 10 digits (0-9)</li>
            <li className={ruleColor(hasSpecChars)}>
              Special characters (like: <b>!@#$%^&*</b>)
            </li>
          </ul>
        </li>
      </div>
    </>
  );
};

export default UserProfilePasswordRules;
