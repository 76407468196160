import _ from "lodash";

// React/Redux
import React, { useState } from "react";
import { connect } from "react-redux";

// Dictionaries/Helpers
import CompanyTypes from "dictionaries/CompanyTypes";
import UserRequestDispositions
  from "dictionaries/UserRequestDispositions";
import { selectedCompanyIsValid }
  from "containers/users/UserRequest/UserRequestForm/FormHelpers";

// User Request Components
import NewUserRequestAcceptUserButton
  from "containers/users/UserRequest/UserRequestForm/components/FormControls/Buttons/AcceptUserButton";
import NewUserRequestAddCompanyButton
  from "containers/users/UserRequest/UserRequestForm/components/FormControls/Buttons/AddCompanyButton";
import NewUserRequestSaveButton
  from "containers/users/UserRequest/UserRequestForm/components/FormControls/Buttons/SaveRequestButton";
import GoBackButton
  from "components/form/buttons/GoBackButton";


const NewUserRequestFormActions = ({
  // Props
  validated = false,
  onError = () => {},
  onSubmit = () => {},
  // Redux Stores
  newUserRequest
}) => {
  const [showReturnButton, setShowReturnButton] = useState(false);

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isUndefined(formValues) || _.isEmpty(formValues))
    return null;

  const { unassigned } = CompanyTypes;
  const { company, companyId, companyType, disposition } = formValues;

  const userAccepted = disposition === UserRequestDispositions.accepted;
  const validCompany = selectedCompanyIsValid(company, companyId);
  const isUnassignedUser = companyType.toLowerCase() === unassigned.title.toLowerCase();

  const showSaveButton = showReturnButton
    ? false
    : disposition !== UserRequestDispositions.accepted;

  const showAcceptButton = showReturnButton
    ? false
    : userAccepted && (validCompany || isUnassignedUser);

  const showAddCompanyButton = showReturnButton
    ? false
    : userAccepted && !validCompany && !isUnassignedUser;

  const onUserUpdated = () => {
    setShowReturnButton(true);
  };

  return (
    <div className="text-center">
      <div className="btn-group">
        { showReturnButton &&
          <GoBackButton
            dest="/admin/cp/users/newuserrequests"
            label="Return to Menu"
          />
        }
        { showSaveButton &&
          <NewUserRequestSaveButton
            formIsValid={validated}
            onError={onError}
            onSubmit={onSubmit}
            onUserUpdated={onUserUpdated}
          />
        }
        { showAcceptButton &&
          <NewUserRequestAcceptUserButton
            formIsValid={validated}
            onError={onError}
            onSubmit={onSubmit}
            onUserAccepted={onUserUpdated}
          />
        }
        { showAddCompanyButton &&
          <NewUserRequestAddCompanyButton />
        }
      </div>
    </div>
  );
};

function mapPropsToState({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapPropsToState)(NewUserRequestFormActions);