import { connect } from 'react-redux';
import React from 'react';
import FormElement from 'components/FormElement';
import CyberComponent from 'containers/CyberComponent';
import { handleInputChange } from 'actions/request';
import { hasStringValue, getDeviceInfoPermission } from 'helpers/RequestHelper';
import { emptyMsg } from 'helpers/FormHelper';

class RequestHWVersion extends CyberComponent {
  state = { show: false, touched: false };

  componentDidMount() {
    this.setState({
      show: this.hasHwVersion()
    });
  }

  isValidationRequired = () => {
    return this.props.user.canEditRequest;
  };

  hasHwVersion() {
    const { app, request } = this.props;

    // check for cyber program
    if (app.isCyberCert) {
      return hasStringValue(request, 'hwVersion');
    }

    // check for battery program
    if (app.isBatteryCert) {
      return true;
    }

    if (app.isBLCCert) {
      return true;
    }

    // check for OTA
    if (app.isOTACert) {
      return true;
    }

    if (app.isDHRCert) {
      return true;
    }

    return false;
  }

  validateHwVersion() {
    if (!this.isValidationRequired() || !this.state.touched) return null;

    return this.state.show && hasStringValue(this.props.request, 'hwVersion')
      ? null
      : 'error';
  }

  onFieldChanged = event => {
    if (!this.state.touched) {
      this.setState({ touched: true});
    }
    
    this.props.handleInputChange(event);
  }

  render() {
    const { app, request, user } = this.props;
    const isEditable = getDeviceInfoPermission(app, user);
    const inputType = isEditable ? 'text' : '';

    return (
      <FormElement
        id="hwVersion"
        label="HW Version"
        value={request.hwVersion}
        show={this.state.show}
        editable={isEditable}
        type={inputType}
        onChange={this.onFieldChanged}
        validationState={this.validateHwVersion()}
        validationMessage={emptyMsg}
        maxLength={50}
      />
    );
  }
}

function mapStateToProps({ app, request, user }) {
  return { app, request, user };
}

export default connect(
  mapStateToProps,
  { handleInputChange }
)(RequestHWVersion);
