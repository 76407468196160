import _ from "lodash";
import CompanyTypes from "dictionaries/CompanyTypes";
import UserTypesLegacy from "helpers/UserTypesLegacy";

export const getCompanyTypeFromRoleId = (roleId, isSupplier) => {
  const { lab, manufacturer, networkOperator, ptcrbLab, supplier } = CompanyTypes;

  if (isSupplier) {
    return supplier;
  }

  switch (roleId) {
  case lab.roleId:
    return lab;
  case networkOperator.roleId:
    return networkOperator;
  case manufacturer.roleId:
    return manufacturer;
  case ptcrbLab.roleId:
    return ptcrbLab;
  default:
    return null;
  }
};

export const getCompaniesFromCompanyType = ({
  companies,
  companyType = ""
}) => {
  if (_.isUndefined(companies)) return [];

  switch (companyType.toLowerCase()) {
  case "lab":
    return companies.labs;
  case "laboratory":
    return companies.ptcrbLabs;
  case "manufacturer":
    return companies.manufacturers;
  case "operator":
    return companies.operators;
  case "supplier":
    return companies.suppliers;
  default:
    return [];
  }
};

export const getCompanyFromCompanyName = ({
  companies = {},
  company = "",
  companyType = ""
}) => {
  const validCompanies = getCompaniesFromCompanyType({
    companies,
    companyType
  });
  const companyMatch = _.find(validCompanies, companyEntry => {
    return (
      companyEntry.name.trim().toLowerCase() === company.trim().toLowerCase()
    );
  });

  return _.isUndefined(companyMatch) ? null : companyMatch;
};

export const getCompanyIdFromCompany = company => {
  if (_.isNull(company) || _.isUndefined(company)) return -1;

  return company.id;
};

export const getCompanyCtiaIdFromCompany = (company, companyType) => {
  if (_.isNull(company) || _.isUndefined(company)) return -1;

  return company.ctiaid;
};

export const getRoleIdFromCompanyTypeTitle = companyType => {
  const result = _.find(CompanyTypes,
      cType => cType.title.toLowerCase() === companyType.toLowerCase());

  if (!result) return null;

  return result.roleId;
}

export const getUserTypeLegacyFromCompanyTypeTitle = companyType => {
  const match = _.find(CompanyTypes,
    cType => cType.title.toLowerCase() === companyType.toLowerCase());

  const userType = UserTypesLegacy[match.roleName];
  if (!userType) return null;

  return userType;
}