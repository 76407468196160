import React from 'react';
import DefaultContent from 'containers/request/OTA/OtaRequestDetails';
import LabContent from 'containers/request/RequestContent/LabViewOta';
import roles from 'dictionaries/UserRoles';

const ContentOta = ({ role }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.accountant:
    case roles.operator:
      return <DefaultContent />;
    case roles.lab:
      return <LabContent />;
    default:
      return null;
  }
};

export default ContentOta;
