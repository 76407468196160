import React from "react";
import { Col, Row } from "ctia-react-bootstrap-v4";
import SelectLab from "containers/users/UsersOfLabs/SelectLab";
import UsersOfLab from "containers/users/UsersOfLabs/UsersOfLab";

const UsersOfLabs = () => {
  return (
    <Row>
      <Col md={4}>
        <h1>View 'Log In As' Users of Labs</h1>
        <h4>Select a Lab:</h4>
        <SelectLab />
      </Col>
      <Col md={8}>
        <h1>&nbsp;</h1>
        <UsersOfLab />
      </Col>
    </Row>
  );
};

export default UsersOfLabs;
