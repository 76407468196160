import React from "react";
import { connect } from "react-redux";
import {
  adapterComponents,
  requestFields
} from "helpers/AdapterSupplierHelper";

const SelectSupplierContainer = ({ request }) => {
  const adapterType = request[requestFields.type];
  const typeNum = Number(adapterType);

  // if type equals 4 that means "No Adapter" selected
  // no need to render selector in that case
  if (adapterType && typeNum > 0 && typeNum < 4) {
    const SupComponent = adapterComponents[adapterType];
    return <SupComponent />;
  }

  return null;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(SelectSupplierContainer);
