import _ from 'lodash';

/**
 * Prepares and sorts operators list before rendering.
 *
 * @param {array} operators Operators list
 * @returns array
 */
export const getOperatorsList = operators => {
  if (!operators || !operators.length) return [];

  const list = _.mapValues(operators, value => {
    // sorting algorithm sucks when letters are in DiFfereNt CAseS
    const sortLabel = String(value.name).toLowerCase();

    return {
      ...value,
      label: value.name,
      sortLabel,
      checked: false,
      disabled: false
    };
  });

  // sort and return, please
  return _.values(_.sortBy(list, ['sortLabel']));
};

/**
 * Iterates operator's list and sets up `checked` status for pre-selected values.
 *
 * @param {array} list Operator's list
 * @param {array} requestOperators Operator's selected for current request
 * @returns array The same list but with pre-selected checkboxes
 */
export const formatOperatorsList = (list, requestOperators) => {
  // get only pre-selected ids for the request
  const selectedIds = _.map(requestOperators, 'companyid');

  // iterate operators list and set checked status
  return _.forEach(list, value => {
    value.checked = _.indexOf(selectedIds, value.id) > -1;
  });
};

/**
 * Returns array of copmany ids that includes as operator.
 *
 * @param {array} list
 * @returns array of company ids
 */
export const getSelectedOperatorIds = list => {
  let ids = [];

  if (!list || !list.length) return ids;

  _.forEach(list, elem => {
    if (elem.checked) ids.push({ companyid: elem.id });
  });

  return ids;
};
