import React from 'react';
import { connect } from 'react-redux';
import TabInstructions from 'components/request/TabInstructions';
import instructions from 'dictionaries/TabInstructions';

const OTADeviceInfoBubble = ({ request }) => {
  if (!request) return null;
  return <TabInstructions messages={instructions.deviceInfoOTA} />;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(OTADeviceInfoBubble);
