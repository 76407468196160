import React from "react";
import { IconLabel } from "ctia-ui";

const ContactLink = ({ name, email }) => {
  if (!name) return "N/A";

  return <IconLabel awesomeIcon="user" label={name} />;
};

export default ContactLink;
