import React, { useEffect } from "react";
import { connect } from "react-redux";
import VendorRow from "containers/ControlPanel/Orgs/Vendors/VendorRow";
import OrgListGeneric from "containers/ControlPanel/Orgs/OrgListGeneric";
import { setOrgType, fetchCompaniesVendor, clearOrgList } from "actions/org";
import { VENDOR } from "dictionaries/Roles";

const OrgsVendors = ({
  org,
  setOrgType,
  fetchCompaniesVendor,
  clearOrgList
}) => {
  useEffect(() => {
    if (!org || !org.type || org.type !== VENDOR) {
      setOrgType(VENDOR);
    }
  }, [org, setOrgType]);

  useEffect(() => {
    if (org && org.type === VENDOR && !org.orgList) {
      fetchCompaniesVendor();
    }
  }, [org, fetchCompaniesVendor]);

  useEffect(() => {
    return () => {
      clearOrgList();
    };
  }, [clearOrgList]);

  const headerFields = [
    { title: "Name", fieldname: "name" },
    { title: "Contact", fieldname: "contact" },
    { title: "Status", fieldname: "active" },
    { title: "", fieldname: "" }
  ];

  return (
    <OrgListGeneric
      org={org}
      headerFields={headerFields}
      rowRenderer={VendorRow}
    />
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps, {
  setOrgType,
  fetchCompaniesVendor,
  clearOrgList
})(OrgsVendors);
