import React, { useState } from "react";
import { Drawer } from "ctia-ui";
import DeviceHistory from "components/DeviceHistory/DeviceHistory";
import "./style.css";

const DeviceHistoryDrawer = ({
  certificationType,
  modelId,
  modelName,
  requestStatusId
}) => {
  const [state, setState] = useState({
    showDrawer: false
  });

  const toggleDrawer = () => {
    setState({ showDrawer: !state.showDrawer });
  };

  return (
    <div>
      <span onClick={toggleDrawer} className="cursor-pointer glow-link">
        {modelName}
      </span>
      <Drawer
        className="LongText"
        position="bottom"
        show={state.showDrawer}
        title={modelName}
        toggleDrawer={toggleDrawer}
        content={
          <DeviceHistory
            certificationType={certificationType}
            modelId={modelId}
            requestStatusId={requestStatusId}
          />
        }
        height="auto"
      />
    </div>
  );
};

export default DeviceHistoryDrawer;
