import React from "react";
import TestStatusTableRow from "containers/request/Initial/TestStatusTableRow";
import LabTestInfoContainer from "containers/request/Initial/LabTestInfoContainer";

const customTitles = {
  col1: "Test Type",
  col2: "Lab",
  col3: "Status",
  col1Row: "Battery Life Certification"
};

const LabTestInfoBLC = ({ editable }) => {
  return (
    <LabTestInfoContainer
      editable={editable}
      customTitles={customTitles}
      statusComponent={TestStatusTableRow}
    />
  );
};

export default LabTestInfoBLC;
