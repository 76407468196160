import React from "react";
import RequestContentARR from "containers/request/battery/RequestContent/RequestContentARR";
import LabContentARR from "containers/request/battery/RequestContent/RequestContentLabARR";
import roles from "dictionaries/UserRoles";

const ContentBatteryARR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentARR focusLab={focusLab}/>;
    case roles.lab:
      return <LabContentARR />;
    default:
      return null;
  }
};

export default ContentBatteryARR;
