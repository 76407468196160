import React from "react";
import RequestContentBLC from "containers/request/battery/RequestContent/RequestContentBLC";
import LabContentBLC from "containers/request/battery/RequestContent/RequestContentLabBLC";
import roles from "dictionaries/UserRoles";

const ContentBatteryBLC = ({ role, focusLab }) => {

  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
      return <RequestContentBLC  focusLab={focusLab} />;
    case roles.operator:
      return <RequestContentBLC hideBillingPay={true}  focusLab={focusLab} />;
    case roles.lab:
      return <LabContentBLC focusLab={focusLab} />;
    default:
      return null;
  }
};

export default ContentBatteryBLC;
