import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "ctia-react-bootstrap-v4";
import {
  getOrganizationsByUserRole,
  clearUserOrgList,
  setUserTypeOrg,
  getUserAssignableOrgs
} from "actions/org";
import { fillUserTypeSelector } from "helpers/OrgHelper";
import * as roles from "dictionaries/Roles";

const SelectUserType = ({
  getOrganizationsByUserRole,
  clearUserOrgList,
  setUserTypeOrg,
  getUserAssignableOrgs,
  userRole,
  userId
}) => {
  useEffect(() => {
    if (userId) {
      getUserAssignableOrgs(userRole, userId);
    }
  }, [getUserAssignableOrgs, userRole, userId]);

  return (
    <Form.Control
      as="select"
      defaultValue={userRole}
      onChange={event => {
        const role = event.target.value;

        if (role !== "none") {
          // fetch selected type related companies
          // excluding some roles though
          const excludedRoles = [
            roles.DB_ACCOUNTING,
            roles.DB_STAFF,
            roles.DB_UNASSIGNED
          ];

          if (!_.includes(excludedRoles, role)) {
            // fetch assignable only in case current role is the same as selected
            if (role === userRole) {
              getUserAssignableOrgs(userRole, userId);
            } else {
              // fetch by org/user type otherwise
              getOrganizationsByUserRole(role);
            }
          } else {
            clearUserOrgList();
          }

          // keep selected type for a reference
          setUserTypeOrg(role);
        } else clearUserOrgList();
      }}
    >
      <option value="none">--- Select One ---</option>
      {fillUserTypeSelector().map(opt => {
        return (
          <option key={opt.title} value={opt.value}>
            {opt.title}
          </option>
        );
      })}
    </Form.Control>
  );
};

export default connect(null, {
  getOrganizationsByUserRole,
  clearUserOrgList,
  setUserTypeOrg,
  getUserAssignableOrgs
})(SelectUserType);
