import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { toMomentDate } from "helpers/DateHelper";
import { handleInputChange } from "actions/request";

const PaymentFormIvcDate = ({ request, handleInputChange }) => {
  const onIvcDateChange = momentDate => {
    handleInputChange({
      target: { id: "ivcDate", value: momentDate }
    });
  };

  const ivcDate = toMomentDate(request.ivcDate);

  return (
    <FormElement
      type="date"
      id="ivcDate"
      label="Invoice Date"
      value={ivcDate}
      onChange={onIvcDateChange}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  handleInputChange
})(PaymentFormIvcDate);
