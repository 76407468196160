import React from 'react';
import PropTypes from 'prop-types';
import { TooltipIcon } from 'ctia-ui';

const StaffActionIcons = ({
  show,
  hasPendingDocs,
  hasPendingReports,
  hasUploadedInvoice
}) => {
  if (!show) return null;

  const renderDocIcon = () => {
    if (!hasPendingDocs) return null;
    return <TooltipIcon awesomeIcon="book" tipText="D" iconColor="#724622" />;
  };

  const renderLabIcon = () => {
    if (!hasPendingReports) return null;
    return <TooltipIcon awesomeIcon="flask" tipText="L" iconColor="#593c81" />;
  };

  const renderInvoiceIcon = () => {
    if (!hasUploadedInvoice) return null;
    return (
      <TooltipIcon awesomeIcon="dollar-sign" tipText="I" iconColor="#ee7700" />
    );
  };

  return (
    <td className="text-center">
      {renderDocIcon()} {renderLabIcon()} {renderInvoiceIcon()}
    </td>
  );
};

StaffActionIcons.propTypes = {
  show: PropTypes.bool,
  hasPendingDocs: PropTypes.bool,
  hasPendingReports: PropTypes.bool,
  hasUploadedInvoice: PropTypes.bool
};

StaffActionIcons.defaultProps = {
  show: false,
  hasPendingDocs: false,
  hasPendingReports: false,
  hasUploadedInvoice: false
};

export default StaffActionIcons;
