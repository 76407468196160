import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";

// Components imports
import NoTableData from "components/NoTableData";
import BatteryModelDetailsOtherCertsRow from "containers/Devices/Battery/BatteryDeviceDetails/BatteryModelDetailsTables/BatteryModelDetailsOtherCerts/BatteryModelDetailsOtherCertsRow";

// Helper imports
import { types } from "helpers/BatteryCertHelper";
import { requestStatuses } from "helpers/RequestHelper";

const BatteryModelDetailsOtherCerts = ({
  // Props
  certType,
  reloadRows = () => {},
  // Redux stores
  app,
  model,
  user
}) => {
  // Case(s): Component shouldn't process or render
  if (!model || !model.versions) return null;

  const tableData = model.versions;
  if (!tableData || tableData.length === 0) {
    return <NoTableData colSpan={5} />;
  }

  // Case(s): Valid inputs

  const showDateRecognized =
    user.isStaff ||
    user.isLab ||
    !_.isEqual(app.batteryRequestStatus.filter, [
      requestStatuses.requestWithdrawn
    ]);

  const showWithdrawAndDelistColumns = user.isStaff;

  const renderRows = () => {
    return tableData.map((row, rowIndex) => {
      const childrenRequestIds = _.filter(tableData, childRow => {
        return childRow.parentid === row.id;
      }).map(childRow => childRow.id);

      return (
        <BatteryModelDetailsOtherCertsRow
          deviceModel={model}
          modalInfo={row}
          key={rowIndex}
          reloadRow={reloadRows}
          requestIdsOfChildren={childrenRequestIds}
          rowData={row}
          rowIndex={rowIndex}
          showDateRecognized={showDateRecognized}
          showWithdrawAndDelistColumns={showWithdrawAndDelistColumns}
          userSelectedCertType={certType}
        />
      );
    });
  };

  return (
    <div>
      <Table responsive striped className="ctia-table">
        <thead>
          <tr>
            <th>Request ID</th>
            <th>Authorization Type</th>
            <th>Revision Number</th>
            <th>Manufacturing Site(s)</th>
            <th>CRSL Version</th>
            {showDateRecognized &&
              (certType === types.ACR ? (
                <th>Date Certified</th>
              ) : (
                <th>Date Recognized</th>
              ))}
            <th>Status</th>
            {showWithdrawAndDelistColumns && (
              <>
                <th />
                <th />
              </>
            )}
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </Table>
    </div>
  );
};

function mapStateToProps({ app, model, user }) {
  return { app, model, user };
}

export default connect(mapStateToProps)(BatteryModelDetailsOtherCerts);
