import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SupportDocs from "components/SupportDocs";
import RequestControls from "containers/request/RequestControls";
import { submitEcoRequestDHR } from "actions/dhr/dhrRequest";

const getIdByModelname = (models, modelname) => {
  const model = _.find(models, ["title", modelname]);

  return model.value;
};

class DocumentsDHR extends Component {
  onSubmit = () => {
    const { dhr, request, submitEcoRequestDHR } = this.props;

    // @HACK: need to find id by selected model name
    const { ecoModels } = dhr || [];
    const existedModelId = getIdByModelname(ecoModels, request.existedModel);

    return submitEcoRequestDHR({ ...request, existedModelId });
  };

  render() {
    if (!this.props.show) return null;

    return (
      <div>
        <SupportDocs />
        <RequestControls isValid={true} onSubmit={this.onSubmit} />
      </div>
    );
  }
}

DocumentsDHR.propTypes = {
  show: PropTypes.bool,
  canSubmit: PropTypes.bool
};

DocumentsDHR.defaultProps = {
  show: true,
  canSubmit: false
};

function mapStateToProps({ dhr, request }) {
  return { dhr, request };
}

export default connect(mapStateToProps, {
  submitEcoRequestDHR
})(DocumentsDHR);
