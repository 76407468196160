import React from "react";
import { connect } from "react-redux";
import { Table } from "react-bootstrap";
import { formatDate } from "helpers/DateHelper";
import { getTestPassedTitle } from "helpers/RequestTestsHelper";
import UploadedResults from "containers/testResult/UploadedResults";
import StatusBadge from "components/Badges/TestStatusBadge";
import { downloadReportCRR } from "actions/battery/crrRequest";

const UnderReviewViewCRR = ({
  show,
  request,
  testModule,
  downloadReportCRR
}) => {
  if (!show) return null;

  const startDate = formatDate(testModule.actualstartdate);
  const endDate = formatDate(testModule.actualenddate);

  return (
    <div>
      <Table responsive className="ctia-table">
        <thead>
          <tr>
            <th>Test Result</th>
            <th>Test Status</th>
            <th>CRSL Version</th>
            <th>Test Start Date</th>
            <th>Test End Date</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getTestPassedTitle(testModule.requesttestpassedstatusid)}</td>
            <td>
              <StatusBadge statusId={testModule.requestteststatusid} />
            </td>
            <td>{request.crslversion}</td>
            <td>{startDate}</td>
            <td>{endDate}</td>
          </tr>
        </tbody>
      </Table>
      <UploadedResults
        reports={testModule.reports}
        downloadHandler={downloadReportCRR}
      />
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  downloadReportCRR
})(UnderReviewViewCRR);
