import React from "react";
import PropTypes from "prop-types";

const NoTableData = ({ colSpan, message }) => {
  return (
    <tr>
      <td colSpan={colSpan}>{message}</td>
    </tr>
  );
};

NoTableData.propTypes = {
  colSpan: PropTypes.number,
  message: PropTypes.string
};

NoTableData.defaultProps = {
  colSpan: 1,
  message: "No entries to show"
};

export default NoTableData;
