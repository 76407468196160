import React from "react";
import { connect } from "react-redux";
import CyberInfoBar from "containers/request/InfoBar/cyber/index";
import OtaInfoBar from "containers/request/InfoBar/ota/index";
import BatteryInfoBar from "containers/request/InfoBar/battery/index";
import BLCInfoBar from "containers/request/InfoBar/blc/index";
import DHRInfoBar from "containers/request/InfoBar/dhr/index";

const RequestInfoBar = ({ app }) => {
  if (!app) return null;

  if (app.isCyberCert) {
    return <CyberInfoBar />;
  }

  if (app.isBatteryCert) {
    return <BatteryInfoBar />;
  }

  if (app.isBLCCert) {
    return <BLCInfoBar />;
  }

  if (app.isOTACert) {
    return <OtaInfoBar />;
  }

  if (app.isDHRCert) {
    return <DHRInfoBar />;
  }

  return null;
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(RequestInfoBar);
