import React from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";

const InputText = ({
  value,
  onChange,
  editable,
  placeholder,
  maxLength,
  isPassword = false
}) => {
  return (
    <FormControl
      type={isPassword ? "password" : "text"}
      value={value}
      onChange={onChange}
      disabled={!editable}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
};

InputText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  editable: PropTypes.bool,
  maxLength: PropTypes.number,
  isPassword: PropTypes.bool
};

export default InputText;
