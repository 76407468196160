import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ResultPanel from "components/ResultPanel";
import PageHeader from "components/request/RequestPageHeader";
import CtiaButton from "components/form/buttons/CtiaButton";

class RequestResultPage extends Component {
  onDoneClick = () => {
    const { app, history } = this.props;
    history.push(`${app.urlPrefix}requests`);
  };

  render() {
    const { isSubmit, isUpdate, isSave } = this.props;

    return (
      <div>
        <PageHeader />
        <ResultPanel isSubmit={isSubmit} isUpdate={isUpdate} isSave={isSave} />
        <CtiaButton label="Done" onClick={this.onDoneClick} />
      </div>
    );
  }
}

RequestResultPage.propTypes = {
  isSubmit: PropTypes.bool,
  isUpdate: PropTypes.bool,
  isSave: PropTypes.bool
};

RequestResultPage.defaultProps = {
  isSubmit: false,
  isUpdate: false,
  isSave: false
};

function mapStateToProps({ app, user, request }) {
  return { app, user, request };
}

export default connect(mapStateToProps)(RequestResultPage);
