import { getSelectedManufSitesIds } from "helpers/ManufSiteHelper";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import { filterCheckedOnlyValues } from "helpers/ArrayHelper";

export const prepareInitialArgsACR = request => {
  const basicFields = {
    billingid: Number(request.billingId),
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    revision: request.revision,
    requestmanufsites: getSelectedManufSitesIds(request.manufSites),
    ieeetypeid: request.ieee
  };

  if (request.existedModelId) {
    return {
      body: makeSimpleArgs({
        ...basicFields,
        devicemodelid: request.existedModelId
      }),
      url: "acr/requests"
    };
  }

  return {
    body: makeSimpleArgs({
      requests: [basicFields],
      modelnumber: request.modelName
    }),
    url: "acr/devicemodels"
  };
};

export const prepareEcoArgsACR = request => {
  // if non SAR sites list goes untouched - it should be inherited from parent instead
  const requestmanufsites =
    request.linkedManufsites && request.linkedManufsites.length > 0
      ? getSelectedManufSitesIds(request.linkedManufsites)
      : request.parentRequest.requestmanufsites.map(site => {
          return { manufsiteid: site.manufsiteid };
        });

  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    revision: request.revision,
    descriptionofchange: request.descriptionOfChange,
    requestmanufsites,
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee
  });
};

export const getRequestFieldsForTestingACR = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsACR = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsACR = request => {
  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName
  });
};

export const getDeviceFieldsForTestingACR = request => {
  return makeSimpleArgs({
    modelId: request.model.id
  });
};

export const prepareRequestArgs = (request, sites) => {
  const { id, revision, descriptionOfChange, crslversion } = request;
  const requestmanufsites = getSelectedManufSitesIds(sites);

  return {
    id,
    revision,
    crslversion,
    descriptionOfChange,
    requestmanufsites,
    urlPrefix: "acr"
  };
};

const getLinkedNonSarSites = request => {
  if (request && request.linkedManufsites) {
    return filterCheckedOnlyValues(
      request.linkedManufsites,
      "manufsiteid",
      "value"
    );
  }

  return [];
};

export const prepareTestResultsArgs = (request, testModule, certTypes) => {
  const requestmanufsites = getLinkedNonSarSites(request);
  const testArgs = getTestFieldsACR(testModule);

  const { model, crslversion, revision } = request;
  const devicemodel = {
    id: model.id
  };

  const args = {
    devicemodel,
    crslversion,
    revision,
    requesttests: [
      {
        ...testArgs
      }
    ],
    requestmanufsites // linked non SAR sites
  };

  return makeSimpleArgs(args);
};
