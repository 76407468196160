// ES6 Helpers
import _ from "lodash";

// React/Redux
import React from "react";
import { connect } from "react-redux";
import { setUserRequestFormValues } from "actions/newUserRequest";

// Business logic helpers
import validation from "dictionaries/Validation";
import {
  validateEmail,
  validatePhone,
} from "helpers/ValidatorHelper";

// UI
import { Col, Form } from "ctia-react-bootstrap-v4";
import FormFieldLabel from "components/form/FormFieldLabel";

const UserInfoFormFields = ({
  newUserRequest,
  setUserRequestFormValues
}) => {
  if (_.isEmpty(newUserRequest) || _.isEmpty(newUserRequest.selectedUserRequest))
    return null;

  const { formValues } = newUserRequest.selectedUserRequest;
  if (_.isEmpty(formValues)) return null;

  const {
    email,
    firstName,
    lastName,
    mobilePhone,
    title,
    workPhone,
    workPhoneExtension
  } = formValues;

  const hasValidEmail = email !== "" && validateEmail(email);
  const hasValidWorkPhone = workPhone !== "" && validatePhone(workPhone);
  const hasValidMobilePhone = mobilePhone === "" || validatePhone(mobilePhone);
  const hasValidPhoneExt = workPhoneExtension !== ""
    ? validatePhone(workPhoneExtension) :
    true;

  return (
    <>
      <Form.Group controlId="firstName">
        <Form.Label>
          <FormFieldLabel label="First Name" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={_.isEmpty(firstName)}
          onChange={event => setUserRequestFormValues({
            firstName: event.target.value
          })}
          placeholder="Enter first name"
          value={firstName}
        />
      </Form.Group>
      <Form.Group controlId="lastName">
        <Form.Label>
          <FormFieldLabel label="Last Name" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={_.isEmpty(lastName)}
          onChange={event => setUserRequestFormValues({
            lastName: event.target.value
          })}
          placeholder="Enter last name"
          value={lastName}
        />
      </Form.Group>
      <Form.Group controlId="title">
        <Form.Label>
          <FormFieldLabel label="Business Title" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={_.isEmpty(title)}
          onChange={event => setUserRequestFormValues({
            title: event.target.value
          })}
          placeholder="Enter business title"
          value={title}
        />
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>
          <FormFieldLabel label="Email" isRequired={true} />
        </Form.Label>
        <Form.Control
          required
          isInvalid={!hasValidEmail}
          onChange={event => setUserRequestFormValues({
            email: event.target.value
          })}
          value={email}
        />
        <Form.Control.Feedback type="invalid">
          {validation.invalidEmailFormat}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Row>
        <Col>
          <Form.Group controlId="workPhone">
            <Form.Label>
              <FormFieldLabel label="Work Phone" isRequired={true} />
            </Form.Label>
            <Form.Control
              required
              placeholder="Enter work phone number"
              onChange={event => {
                setUserRequestFormValues({
                  workPhone: event.target.value
                })
              }}
              isInvalid={!hasValidWorkPhone}
              value={workPhone}
            />
            <Form.Control.Feedback type="invalid">
              {validation.invalidPhoneFormat}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs="auto" className="icon-left-offset">
          <Form.Group controlId="workPhoneExt">
            <Form.Label>
              <FormFieldLabel label="Ext." />
            </Form.Label>
            <Form.Control
              placeholder="Phone Number Ext."
              onChange={event => {
                setUserRequestFormValues({
                  workPhoneExtension: event.target.value
                })
              }}
              isInvalid={!hasValidPhoneExt}
              value={workPhoneExtension}
            />
            <Form.Control.Feedback type="invalid">
              {validation.invalidPhoneFormat}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Form.Row>
      <Form.Group controlId="mobilePhone">
        <Form.Label>
          <FormFieldLabel label="Mobile Phone" />
        </Form.Label>
        <Form.Control
          isInvalid={!hasValidMobilePhone}
          onChange={event => setUserRequestFormValues({
            mobilePhone: event.target.value
          })}
          defaultValue={mobilePhone}
        />
        <Form.Control.Feedback type="invalid">
          {validation.invalidPhoneFormat}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapStateToProps, {
  setUserRequestFormValues
})(UserInfoFormFields);
