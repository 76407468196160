import _ from "lodash";
import {
  USER_INITIAL_STATE,
  setUserInfo,
  getRequestOwner,
  separatePhoneExt
} from "helpers/UsersHelper";
import { isIdPending } from "helpers/RequestTestsHelper";
import {
  isPending as isRequestPending,
  isSaved,
  isRequestWithdrawn
} from "helpers/RequestHelper";
import { setUserTimestamp } from "helpers/DateHelper";
import { setSession, clearSession } from "libs/sessionStorage";
import * as actions from "actions/types";

const setRequestPermissions = (action, state) => {
  const request = action.payload;

  if (!request) return state;

  const { id, requeststatusid } = request;

  let canEditRequest = _.isUndefined(state.canEditRequest)
    ? false
    : state.canEditRequest;
  let canUploadRequestDocs = state.isStaff
    ? true
    : _.isUndefined(state.canUploadRequestDocs)
    ? false
    : state.canUploadRequestDocs;
  let togglerEnabled = _.isUndefined(state.togglerEnabled)
    ? false
    : state.togglerEnabled;
  let selectedRequestId = id;

  // Vendor user able to edit request data only if
  // request tests have Pending status, but
  // this checks we able to do only when have
  // test statuses (see `setRequestTestPermissions` method)
  // Also should not be able to edit request if its status is `Withdrawn`
  if (state.isVendor) {
    canEditRequest = isRequestWithdrawn(request.requeststatusid)
      ? false
      : undefined;
    canUploadRequestDocs =
      isRequestPending(requeststatusid) || isSaved(requeststatusid);
  }

  // check if Staff user already enabled Modify mode before
  if (
    state.isStaff &&
    // TODO: uncomment the following in case we'd need to use Modify mode again
    // state.togglerEnabled === true &&
    state.selectedRequestId !== selectedRequestId
  ) {
    canEditRequest = false;
    canUploadRequestDocs = true;
    togglerEnabled = false;
  }

  // if not owner trying to edit someone's request
  // we have to change owner company id in that case
  const requestOwnerCompanyId = getRequestOwner(request, state);

  return {
    ...state,
    canEditRequest,
    canUploadRequestDocs,
    togglerEnabled,
    selectedRequestId,
    requestOwnerCompanyId
  };
};

const setRequestTestPermissions = (action, state) => {
  if (!action.payload.data) return state;

  let canEditRequest = state.canEditRequest;

  // Vendor user able to edit request data only if
  // request tests have Pending status
  // If flag is not already set (undefined), don't need to check that
  if (state.isVendor && _.isUndefined(canEditRequest)) {
    const testStatus = action.payload.data.data.length
      ? action.payload.data.data[0].requestteststatusid
      : null;
    canEditRequest = isIdPending(testStatus);
  }

  return { ...state, canEditRequest };
};

const setSubmitPermissions = state => {
  return {
    ...state,
    canEditRequest: true,
    canUploadRequestDocs: true,
    togglerEnabled: undefined
  };
};

const dropRequestPermissions = (action, state) => {
  // if (!action.payload.data) return state;
  if (!action.payload) return state;

  // const request = action.payload.data.data;
  const request = action.payload;

  if (request.id !== state.selectedRequestId) {
    return dropAllRequestPermissions(state);
  }

  return state;
};

const dropAllRequestPermissions = state => {
  return {
    ...state,
    canEditRequest: undefined,
    togglerEnabled: undefined,
    canUploadRequestDocs: undefined
  };
};

export default function (state = USER_INITIAL_STATE, action) {
  switch (action.type) {
    case actions.AUTH_ERROR:
      return { ...state, errorMessage: action.payload };
    case actions.LOGOUT:
      localStorage.removeItem("token");
      clearSession();
      return USER_INITIAL_STATE;
    case actions.AGREE_TERMS:
      const terms = action.payload;
      localStorage.setItem("terms", terms);
      const flag = terms === true ? 1 : 0;
      return { ...state, terms, ctiaagreementflag: flag };
    case actions.FETCH_REQUEST_CYBER:
    case actions.FETCH_REQUEST_OTA:
    case actions.FETCH_REQUEST_SAR:
    case actions.FETCH_REQUEST_DHR:
    case actions.FETCH_REQUEST_CRR:
    case actions.FETCH_REQUEST_HRR:
    case actions.FETCH_REQUEST_PRR:
    case actions.FETCH_REQUEST_EPR:
    case actions.FETCH_REQUEST_ARR:
    case actions.FETCH_REQUEST_ACR:
    case actions.FETCH_REQUEST_BSC:
    case actions.FETCH_REQUEST_BLC:
      // need first to drop current settings if fetching new one request
      const droppedState = dropRequestPermissions(action, state);
      return setRequestPermissions(action, droppedState);
    case actions.INIT_NEW_REQUEST:
      return setSubmitPermissions(state);
    case actions.TOGGLE_REQUEST_EDIT_MODE:
      const currentEditState = state.canEditRequest;
      const currentEditDocsState = state.canUploadRequestDocs;
      const currentTogglerState = state.togglerEnabled;
      return {
        ...state,
        togglerEnabled: !currentTogglerState,
        canEditRequest: !currentEditState,
        canUploadRequestDocs: !currentEditDocsState
      };
    case actions.FETCH_REQUEST_TESTS:
      return setRequestTestPermissions(action, state);
    case actions.FETCH_CERTIFICATION_REQUESTS:
    case actions.RETURN_TO_EDIT_REQUEST:
      return dropAllRequestPermissions(state);
    case actions.GET_USER_INFO:
      const updatedUserData = setUserInfo({ ...state, ...action.payload });

      // update client stored user data
      setSession("user", updatedUserData);

      return updatedUserData;
    case actions.SAVE_USER_TOKEN:
      const token = action.payload;

      // meta might have a flag to restrict site access
      // due to required change for a temporary password
      const meta = action.meta;
      const forceTempPass = meta
        ? meta.temporypasswordused === 1
        : localStorage.getItem("forceTempPass") === "true";

      const userData = { ...state, token, forceTempPass };

      // update client stored token
      localStorage.setItem("token", token);

      // update client stored temp password flag
      localStorage.setItem("forceTempPass", forceTempPass);

      // update session user token
      setSession("user", userData);

      // update timestamp
      setUserTimestamp();

      return userData;
    case actions.UPDATE_USER_LOCATION:
      return { ...state, location: action.payload };
    // new way to handle request permissions in a much flexible way
    case actions.SET_PERMISSIONS:
      return { ...state, ...action.payload };
    case actions.TOGGLE_OTA_REQUEST_EDIT_MODE:
    case actions.TOGGLE_EDIT_MODE:
      return {
        ...state,
        togglerEnabled: !state.togglerEnabled
      };
    case actions.GET_USER_PROFILE:
      if (!action.payload) return { ...state, workphoneext: "" };

      const phones = separatePhoneExt(action.payload.workphone);

      return {
        ...state,
        ...action.payload,
        ...phones
      };
    case actions.SIGN_ATL_AGREEMENT:
      // TODO: update ATL agreement flag to `signed`
      return { ...state, ...action.payload };
    case actions.SET_NEW_PASSWORD:
      localStorage.removeItem("forceTempPass");

      return { ...state, forceTempPass: false };
    default:
      return state;
  }
}
