import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import {
  Bubble,
  // NoData,
  InlineDatepicker,
  LoaderButton,
  IconLabel
} from "ctia-ui";
import {
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import {
  getApprovedBatteryDevices,
  setSearchTerm,
  clearDeviceList
} from "actions/devices";
import { toggleOptions } from "helpers/AuthorizationTypeHelper";
import { AuthorizationTypeSelector } from "containers/Devices/Battery/DeviceSearchBattery/AuthorizorationTypeSelector";
import { CertificationTypeSelector } from "containers/Devices/Battery/DeviceSearchBattery/CertificationTypeSelector";
import {
  getCertTypesFromBatteryRequestIds,
  types
} from "helpers/BatteryCertHelper";
import { requestStatuses } from "helpers/RequestHelper";
import { changeBatteryRequestStatus } from "actions/app";
import SubsystemTypeSelector from "containers/Devices/Battery/DeviceSearchBattery/SubsystemTypeSelector";

class DevicesSearchBattery extends Component {
  constructor(props) {
    super(props);

    // January 2000
    this.minDate = moment(props.app.certMinDateLegacy);

    // Default selected: Jan 1st <current_year>
    this.year = new Date().getFullYear();
    this.currYearBeginning = moment(`${this.year}-01-01`);
    const { user } = this.props;

    const batteryCertTypes = _.filter(_.toPairs(types), ([k, v]) => {
      if (user.isSupplier) return v !== types.BSC;
      else if (user.isVendor || user.isOperator) return v !== types.SAR;
      else return true;
    });

    const certTypeList = batteryCertTypes.map(([certKey, certValue]) => {
      return {
        id: certValue,
        label: certKey,
        disabled: false
      };
    });

    this.state = {
      searchTerm: "",
      certTypes: [],
      certList: certTypeList,
      authType: 0,
      authOptions: toggleOptions,
      selectedSubsystem: user.companyid,
      dateFrom: this.minDate,
      dateTo: moment(),
      validationTrigger: false
    };
  }

  changeHandler = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  changeDate = (id, date) => {
    this.setState({
      [id]: date
    });
  };

  checkboxHandler = event => {
    this.setState({
      [event.target.id]: event.target.checked
    });
  };

  disableOtherCerts = (list, value, disable) => {
    return list.map(option => {
      if (!disable && this.state.authType === 1625 && option.id === types.ACR)
        option.disabled = true;
      else if (option.id !== value) option.disabled = disable;
      else option.disabled = false;

      return option;
    });
  };

  onCertChangeHandler = values => {
    const { authOptions, certTypes, certList, validationTrigger } = this.state;
    const [newSelectOpt] = _.difference(values, certTypes);

    if (!validationTrigger && !newSelectOpt)
      this.setState({ validationTrigger: true });

    if (!newSelectOpt) {
      const [unselectOpt] = _.difference(certTypes, values);
      this.setState({ certTypes: values });

      if (unselectOpt === types.BSC || unselectOpt === types.SAR)
        this.setState({
          certList: this.disableOtherCerts(certList, unselectOpt, false)
        });
      else if (unselectOpt === types.ACR) {
        const authDisableChecked = authOptions.map(option => {
          option.disabled = false;
          return option;
        });

        this.setState({
          authOptions: authDisableChecked,
          selectedSubsystem: null,
          certList: this.disableOtherCerts(certList, unselectOpt, false)
        });
      }
    } else if (newSelectOpt === types.BSC || newSelectOpt === types.SAR) {
      this.setState({
        certTypes: [newSelectOpt],
        certList: this.disableOtherCerts(certList, newSelectOpt, true)
      });
    } else if (newSelectOpt === types.ACR) {
      const authDisableChecked = authOptions.map(option => {
        option.disabled = option.id !== 1725;
        return option;
      });

      this.setState({
        authOptions: authDisableChecked,
        selectedSubsystem: null,
        authType: 1725,
        certTypes: [newSelectOpt],
        certList: this.disableOtherCerts(certList, newSelectOpt, true)
      });
    } else this.setState({ certTypes: values });
  };

  onAuthChangeHandler = value => {
    const { certTypes, certList } = this.state;

    if (value === 1625) {
      if (_.includes(certTypes, types.ACR))
        this.setState({
          certTypes: _.filter(certTypes, cert => {
            return cert !== types.ACR;
          })
        });

      this.setState({
        certList: certList.map(cert => {
          return cert.id === types.ACR ? _.set(cert, "disabled", true) : cert;
        })
      });
    } else if (
      !_.isEqual(certTypes, [types.BSC]) &&
      !_.isEqual(certTypes, [types.SAR])
    ) {
      this.setState({
        certList: certList.map(cert => {
          return cert.id === types.ACR ? _.set(cert, "disabled", false) : cert;
        })
      });
    }

    this.setState({ authType: value });
  };

  onSubsystemChangeHandler = value => {
    this.setState({ selectedSubsystem: value });
  };

  onSubmit = () => {
    const { certTypes, validationTrigger } = this.state;
    const { app, user } = this.props;
    if (!validationTrigger) this.setState({ validationTrigger: true });

    if (_.isEmpty(certTypes)) return;

    // set search term to use then in list component as a default value and filter

    const selectedCerts = !_.isEmpty(certTypes)
      ? getCertTypesFromBatteryRequestIds(app.certTypes, certTypes)
      : [];
    const selectedCertsIds = _.map(selectedCerts, (cert, _) => cert.id);
    const data = {
      authType: this.state.authType,
      certTypes: this.state.certTypes,
      certTypeIds: selectedCertsIds,
      searchTerm: this.state.searchTerm,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      selectedSubsystem: this.state.selectedSubsystem
    };

    this.props.setSearchTerm(data);
    this.props.changeBatteryRequestStatus(requestStatuses.approved);

    if (_.isEqual(certTypes, [types.SAR]))
      this.props.getApprovedBatteryDevices(
        {
          pageNum: 1,
          pageSize: app.pageSize,
          statusFilter: [requestStatuses.approved],
          isLab: user.isLab,
          isSupplier: user.isSupplier,
          currVendorId: user.companyid,
          fetchAdditionalWithdrawInfo: true,
          ...data
        },
        "sar"
      );
    else {
      // fetch device list, also use callback to stop loading after fetching
      this.props.getApprovedBatteryDevices({
        pageNum: 1,
        pageSize: app.pageSize,
        statusFilter: [requestStatuses.approved],
        isLab: user.isLab,
        isVendor: user.isVendor || user.isSupplier,
        fetchAdditionalWithdrawInfo: true,
        ...data
      });
    }
  };

  onFormSubmit = event => {
    event.preventDefault();
    this.onSubmit();
  };

  showSubsystemSelector = () => {
    const { user } = this.props;

    if (user.isLab || user.isSupplier || user.isVendor) {
      const selectedCertTypes = this.state.certTypes;

      if (_.isEmpty(selectedCertTypes)) {
        return false;
      } else if (_.includes(selectedCertTypes, types.ACR)) {
        return false;
      } else if (_.includes(selectedCertTypes, types.BSC)) {
        return false;
      } else if (_.includes(selectedCertTypes, types.SAR)) {
        return false;
      }

      return true;
    }

    return false;
  };

  render() {
    const { devices } = this.props;

    // don't render component if device list is non-empty
    if (devices && devices.list) return null;

    const {
      // isLoading,
      dateFrom,
      dateTo,
      certTypes,
      certList,
      authType,
      authOptions,
      searchTerm
    } = this.state;

    const showSubsystemSelector = this.showSubsystemSelector();

    return (
      <div>
        <Bubble>
          Enter the Authorization Type, Certification Type, Dates, and any
          optional Search Terms, then click <strong>Search</strong>.
        </Bubble>
        {/* <NoData show={devices && devices.noData === true} /> */}
        <Form horizontal onSubmit={this.onFormSubmit}>
          <AuthorizationTypeSelector
            toggleOptions={authOptions}
            selectedAuthType={authType}
            onChangeHandler={this.onAuthChangeHandler}
          />
          <CertificationTypeSelector
            list={certList}
            selectedCertTypes={certTypes}
            onChangeHandler={this.onCertChangeHandler}
            validationTrigger={this.state.validationTrigger}
          />
          {showSubsystemSelector && (
            <SubsystemTypeSelector
              onChangeHandler={this.onSubsystemChangeHandler}
              selectedSubsystem={this.state.selectedSubsystem}
            />
          )}
          <FormGroup controlId="searchTerm">
            <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
              Search Terms:
            </Col>
            <Col xs={12} sm={5} md={5}>
              <FormControl
                type="text"
                value={searchTerm}
                onChange={this.changeHandler}
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col componentClass={ControlLabel} xs={12} sm={3} md={3}>
              Approval Date:
            </Col>
            <Col className="datepicker-block" xs={12} sm={1} md={1}>
              <DatePicker
                value={dateFrom}
                selected={dateFrom}
                customInput={<InlineDatepicker label="From: " />}
                onChange={this.changeDate.bind(this, "dateFrom")}
                minDate={this.minDate}
                maxDate={dateTo}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Col>
            <Col className="datepicker-block" xs={12} sm={1} md={1}>
              <DatePicker
                value={dateTo}
                selected={dateTo}
                customInput={<InlineDatepicker label="To: " />}
                onChange={this.changeDate.bind(this, "dateTo")}
                minDate={dateFrom}
                maxDate={moment()}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Col>
          </FormGroup>
          <FormGroup>
            <Col xs={12} sm={9} md={9} mdOffset={3} smOffset={3}>
              <LoaderButton
                bsStyle="info"
                text={<IconLabel label="Search" awesomeIcon="search" />}
                onClick={this.onSubmit}
                // isLoading={isLoading}
                className="btn-shadow"
              />
            </Col>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ devices, user, app }) {
  return { devices, user, app };
}

export default connect(mapStateToProps, {
  changeBatteryRequestStatus,
  getApprovedBatteryDevices,
  setSearchTerm,
  clearDeviceList
})(DevicesSearchBattery);
