// es6 Helpers
import _ from "lodash";

// React / Redux
import React from "react";
import { Form } from "ctia-react-bootstrap-v4";
import { connect } from "react-redux";

// Actions
import { setUserRequestFormValues } from "actions/newUserRequest";

// UI
import { Bubble, Divider } from "ctia-ui";

// Helpers
import UserRequestDispositions from "dictionaries/UserRequestDispositions";

// Containers and Components
import NewUserRequestDispositionAccepted from "containers/users/UserRequest/UserRequestForm/components/RequestDispositions/DispositionAccepted";
import FormFieldLabel from "components/form/FormFieldLabel";

const DispositionSelector = ({
  // Props
  openEmailClientOnMoreInfoSelected = true,
  // Redux
  newUserRequest,
  setUserRequestFormValues
}) => {
  if (_.isUndefined(newUserRequest))
    return null;

  const {
    formValues
  } = newUserRequest.selectedUserRequest;
  const {
    dispositionReason = "",
    disposition = 0,
    email = ""
  } = formValues;

  const emailUserLink = `mailto:${email}`;

  const { accepted, moreInfo, pending, rejected } = UserRequestDispositions;

  const subfieldSelector = () => {
    switch (Number(disposition)) {
    case accepted:
      return (
        <NewUserRequestDispositionAccepted />
      );
    case moreInfo:
      return (
        <Form.Row>
          <Bubble>
            If your mail client didn't automatically open on selection -{" "}
            <a className="cursor-pointer glow-link" href={emailUserLink}>
              click here
            </a>{" "}
            and request the missing information.
          </Bubble>
        </Form.Row>
      );
    case rejected:
      return (
        <Form.Group controlId="dispositionReason">
          <Form.Label>
            <FormFieldLabel label="Reason for Rejection" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            as="textarea"
            isInvalid={_.isNull(dispositionReason) || _.isEmpty(dispositionReason)}
            onChange={event => setUserRequestFormValues({ dispositionReason: event.target.value })}
            style={{ height: 150 }}
            value={!_.isNull(dispositionReason) ? dispositionReason : ""}
          />
        </Form.Group>
      );
    default:
      return null;
    }
  };

  const localHandleChange = (event) => {
    const { value } = event.target;
    const disposition = Number(value);

    if (disposition === moreInfo && openEmailClientOnMoreInfoSelected) {
      document.location = emailUserLink;
    }

    setUserRequestFormValues({ disposition });
  };

  const requestDispositions = [
    { id: pending, title: " -- Select One -- ", value: pending },
    { id: accepted, title: "Accepted", value: accepted },
    { id: moreInfo, title: "Need more Info", value: moreInfo },
    { id: rejected, title: "Rejected", value: rejected }
  ];

  const showSubselector = (
    !_.isUndefined(disposition) &&
    !_.isNull(disposition) &&
    disposition !== pending);

  return (
    <Form.Group controlId="disposition">
      <Form.Label>
        <FormFieldLabel label="Disposition" isRequired={true} />
      </Form.Label>
      <Form.Control
        required
        isInvalid={disposition === pending}
        onChange={localHandleChange}
        as="select"
        value={disposition}
      >
        {requestDispositions.map(option => {
          return (
            <option key={option.id} value={option.value}>{option.title}</option>
          );
        })}
      </Form.Control>
      { showSubselector &&
        <>
          <Divider />
          { subfieldSelector() }
        </>
      }
    </Form.Group>
  );
};

function mapStateToProps({ newUserRequest }) {
  return { newUserRequest };
}

export default connect(mapStateToProps, { setUserRequestFormValues })(DispositionSelector);
