export default [
  {
    value: 1,
    title: "Nominal",
  },
  {
    value: 2,
    title: "Rated",
  },
  {
    value: 3,
    title: "Typical",
  },
];
