import React from "react";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";

const BatteryLifeInfoContent = ({ bldata }) => {
  if (!bldata) return null;

  const {
    hours_of_operation,
    brightness_setting,
    nits_reading,
    total_available_energy,
    gaming,
    message,
    email,
    audio_streaming,
    music_playback,
    video_streaming,
    talk_voice_call,
    web_browsing,
    standby
  } = bldata;

  const lifeInfoData = [
    {
      id: "batterylifeinhours",
      label: "Battery Life in Hours",
      value: hours_of_operation
    },
    {
      id: "brightnesssetting",
      label: "Brightness Setting",
      value: brightness_setting
    },
    {
      id: "nitsreading",
      label: "NITS Reading",
      value: nits_reading
    },
    {
      id: "taenv",
      label:
        "Total Available Energy, TAE, (mWh) and average voltage (V) for ALL cell and pack combinations. Detail the vendor, model information",
      value: total_available_energy
    },
    {
      id: "gaming",
      label: "Gaming",
      value: gaming
    },
    {
      id: "message",
      label: "Message",
      value: message
    },
    {
      id: "email",
      label: "Email",
      value: email
    },
    {
      id: "audiostreaming",
      label: "Audio Streaming",
      value: audio_streaming
    },
    {
      id: "musicplayback",
      label: "Music Playback",
      value: music_playback
    },
    {
      id: "videostreaming",
      label: "Video Streaming",
      value: video_streaming
    },
    {
      id: "talkvoicecall",
      label: "Talk Voice Call",
      value: talk_voice_call
    },
    {
      id: "webbrowsing",
      label: "Web Browsing",
      value: web_browsing
    },
    {
      id: "standby",
      label: "Standby",
      value: standby
    }
  ];

  return (
    <div>
      <Form horizontal>
        {lifeInfoData.map(el => {
          return <FormElement id={el.id} label={el.label} value={el.value} />;
        })}
      </Form>
    </div>
  );
};

export default BatteryLifeInfoContent;
