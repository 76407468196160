import React from "react";

const LinkableColumn = ({
  className = "",
  children = ""
}) => {
  const classNames = `cursor-pointer glow-link ${className}`

  return (
    <td className={classNames}>
      {children}
    </td>
  )
};

export default LinkableColumn;