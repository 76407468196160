import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { requestStatuses } from "helpers/RequestHelper";
import * as call from "helpers/ActionHelper";
import * as args from "helpers/ArgsHelperHRR";
import * as th from "helpers/RequestTestsHelper";
import * as actions from "actions/types";
import * as sites from "actions/sites";
import * as sign from "actions/agreementsBattery";
import * as req from "actions/request";
import * as breq from "actions/battery/batteryRequests";
import * as tests from "actions/tests";
import { fileDownload } from "actions/uploads";

export const initHRR = (companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(sites.fetchNonSarSites(companyid))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const fetchEcoModelsHRR = (ieee, companyid) => async dispatch => {
  try {
    const url = `/hrr/devicemodels?where=ieeetypeid=${ieee} AND vendorid=${companyid} AND requeststatusid <> ${requestStatuses.requestWithdrawn}&include=requests&select=id,modelnumber&orderBY=modelnumber ASC&page=0`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_HRR_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const initEcoHRR = (ieee, companyid = null) => async dispatch => {
  dispatch(call.startPreloader());

  return dispatch(fetchEcoModelsHRR(ieee, companyid))
    .then(() => dispatch(sites.fetchNonSarSites(companyid)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const createRequestHRR = request => async dispatch => {
  try {
    const hrrArgs = args.prepareInitialArgsHRR(request);
    const response = await restClientGeneral.post(
      hrrArgs.url,
      JSON.stringify(hrrArgs.body)
    );

    const { id, devicemodelid, requests } = response.data.data;
    const newId = _.isArray(requests) ? requests[0].id : id;
    const deviceModelId = _.isArray(requests) ? id : devicemodelid;

    dispatch({
      type: actions.SUBMIT_INITIAL_REQUEST,
      payload: {
        newId,
        deviceModelId,
        isHRR: true
      }
    });

    const agreementSigned =
      request.complianceDeclarationToggler &&
      request.recognitionAgreementToggler;

    if (agreementSigned) {
      dispatch({
        type: actions.SUBMIT_WIZARD,
        payload: { isSubmitted: true }
      });
    } else {
      dispatch({
        type: actions.SAVE_WIZARD,
        payload: { isSaved: true }
      });
    }

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitInitialHRR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createRequestHRR(request))
      .then(res => {
        const { id, devicemodelid } = res.data.data;
        const modelId = devicemodelid || id;
        const needAgreementSign =
          request.complianceDeclarationToggler &&
          request.recognitionAgreementToggler;

        if (needAgreementSign) {
          return dispatch(
            sign.signDeclarationAgreement(modelId, request.ieee, "hrr")
          ).then(() =>
            dispatch(
              sign.signRecognitionAgreement(modelId, request.ieee, "hrr")
            )
          );
        } else return res;
      })
      .then(quit)
      .catch(quit);
  };
}

export const createEcoRequestHRR = request => async dispatch => {
  try {
    const reqBody = args.prepareEcoArgsHRR(request);
    const response = await restClientGeneral.post(
      "hrr/requests",
      JSON.stringify(reqBody)
    );
    const { id, devicemodelid } = response.data.data;

    dispatch({
      type: actions.SUBMIT_ECO_REQUEST,
      payload: {
        newId: id,
        deviceModelId: devicemodelid,
        isHRR: true
      }
    });

    dispatch({
      type: actions.SUBMIT_WIZARD,
      payload: { isSubmitted: true }
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoHRR(request) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(createEcoRequestHRR(request)).then(quit).catch(quit);
  };
}

export const getRequestDetailsHRR = id => async dispatch => {
  try {
    const url = `/hrr/requests/${id}/details`;
    const res = await restClientGeneral.get(url);
    const payload = res.data.data;

    dispatch({ type: actions.FETCH_REQUEST_HRR, payload });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

// export const getParentRequestDetailsCRR = id => async dispatch => {
//   try {
//     const url = `/crr/requests/${id}/details`;
//     const response = await restClientGeneral.get(url);
//     const payload = response.data.data;

//     dispatch({ type: actions.FETCH_PARENT_REQUEST_CRR, payload });

//     return response;
//   } catch (e) {
//     dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

//     return Promise.reject(e);
//   }
// };

export const fetchRequestTestsHRR = requestId => async dispatch => {
  try {
    const url = `/hrr/requesttests?include=reports,requesttestconductinglabs,lab,interface&where=requestid=${requestId}`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUEST_TESTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchTestReportsHRR = testModule => async dispatch => {
  try {
    const url = `/hrr/requesttests/${testModule.id}/reports`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_TEST_REPORTS,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getDetailsHRR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsHRR(requestId))
      .then(res => {
        const { requestmanufsites, submitter } = res.data.data;
        dispatch(sites.fetchNonSarSites(submitter.companyid)).then(() => {
          dispatch(breq.mapNonSarManufSites(requestmanufsites));
        });
      })
      .then(() => dispatch(fetchRequestTestsHRR(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(fetchTestReportsHRR({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function submitTestResultsHRR(request, testModule, certTypes) {
  const testResArgs = args.prepareTestResultsArgs(
    request,
    testModule,
    certTypes
  );
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(sendTestResultsHRR(testResArgs, request.id))
      .then(() => {
        dispatch(call.stopPreloader());
        dispatch({
          type: actions.SAVE_REQUEST,
          payload: { formSaved: true, isLoading: true }
        });
      })
      .catch(() => {
        dispatch(call.stopPreloader());
        dispatch(call.showNotice(notifyMessages.errorAsyncAction));
      });
  };
}

export const sendTestResultsHRR = (args, reqId) => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/hrr/requests/${reqId}`,
      JSON.stringify(args)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSendTestResult));

    return Promise.reject(e);
  }
};

export const updateRequestByArgs = reqArgs => async dispatch => {
  try {
    const url = `/hrr/requests/${reqArgs.args.requestId}`;

    return await restClientGeneral.put(url, JSON.stringify(reqArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export const updateModelHRR = modelArgs => async dispatch => {
  try {
    const url = `/hrr/devicemodels/${modelArgs.args.modelId}`;
    return await restClientGeneral.put(url, JSON.stringify(modelArgs));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function signSavedInitialHRR(modelId, requestId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(sign.signDeclarationAgreement(modelId, ieee, "hrr"))
      .then(() => dispatch(sign.signRecognitionAgreement(modelId, ieee, "hrr")))
      .then(() => dispatch(getRequestDetailsHRR(requestId)))
      .then(quit)
      .catch(quit);
  };
}

export function mapManufSitesHRR(linkedSites) {
  return {
    type: actions.MAP_NON_SAR_SITES,
    payload: linkedSites
  };
}

export const getModelDetailsHRR = (modelId, ieee) => async dispatch => {
  try {
    const url = `/hrr/requests?include=requestmanufsites&where=devicemodelid=${modelId} AND requeststatusid <> 8 AND ieeetypeid=${ieee}&orderBY=requestid DESC&perPage=1`;
    return await restClientGeneral.get(url);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function fetchModelHRR(modelId, ieee) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getModelDetailsHRR(modelId, ieee))
      .then(res => {
        const payload = res.data.data[0];

        dispatch({
          type: actions.FETCH_HRR_MODEL,
          payload
        });

        return res;
      })
      .then(res =>
        dispatch(mapManufSitesHRR(res.data.data[0].requestmanufsites))
      )
      .then(quit)
      .catch(quit);
  };
}

export const updateDeviceModel = deviceModel => async dispatch => {
  try {
    return await restClientGeneral.put(
      `/hrr/devicemodels/${deviceModel.args.modelId}`,
      JSON.stringify(deviceModel)
    );
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

    return Promise.reject(e);
  }
};

export function updateDeviceInfoHRR(request, sites) {
  const deviceModel = args.prepareModelArgsHRR(request);
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(updateDeviceModel(deviceModel))
      .then(() => dispatch(req.updateRequest(reqArgs)))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function updateECODeviceInfoHRR(request, sites) {
  const reqArgs = args.prepareRequestArgs(request, sites);
  const successMsg = notifyMessages.requestUpdatedSuccessfully;
  const failureMsg = notifyMessages.cantUpdateRequest;

  return dispatch => {
    const quit = () => {
      dispatch(call.showNotice(failureMsg));
      dispatch(call.stopPreloader());
    };

    dispatch(call.startPreloader());
    dispatch(req.updateRequest(reqArgs))
      .then(() => {
        dispatch(call.showNotice(successMsg));
        dispatch(call.stopPreloader());
      })
      .catch(quit);
  };
}

export function makeLabDecisionHRR(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const url = `hrr/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export const updateTestReportStatusHRR = ({
  requestId,
  testId,
  reportSlot,
  reportStatus
}) => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(tests.changeReportStatus(testId, reportSlot, reportStatus, "hrr"))
    .then(res => {
      const { documentstatusid, report } = res.data.data;

      dispatch({
        type: actions.UPDATE_REPORT_STATUS,
        payload: {
          slot: report.slot,
          status: documentstatusid,
          testId
        }
      });

      return res;
    })
    .then(() => dispatch(getDetailsHRR(requestId)))
    .then(() => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
    })
    .catch(err => {
      dispatch(call.stopPreloader());
      dispatch(call.showNotice(notifyMessages.cantUpdateRequest));

      return Promise.reject(err);
    });
};

export function downloadReportHRR(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: HRR<000000>_REPORT_<Slot>.<extension>
  // e.g. HRR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/hrr/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsHRR(request) {
  // file name: HRR<000000>_REPORTS.zip
  // e.g. HRR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/hrr/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
