import { restClientGeneral } from "libs/restClient";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";

export function fetchRequestComments(requestId) {
  const url = `/requestcomments?where=requestid=${requestId}&include=submitter`;
  const payload = restClientGeneral
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_REQUEST_COMMENTS,
    payload
  };
}

export function postComment(requestid, comment) {
  const reqBody = makeSimpleArgs({
    requestid,
    comment
  });

  const payload = restClientGeneral
    .post("/requestcomments", JSON.stringify(reqBody))
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.POST_COMMENT,
    payload
  };
}

export function fetchATLComments(id, year) {
  const url = `/admin/atlcomments?where=year=${year} AND companyid=${id}&include=user&page=0`;
  const payload = restClientGeneral
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_ATL_COMMENTS,
    payload
  };
}

export const postATLComment = (companyId, year, comment) => async dispatch => {
  try {
    const reqBody = makeSimpleArgs({
      companyid: companyId,
      year,
      comment
    });

    const payload = await restClientGeneral.post(
      "/admin/atlcomments",
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.POST_ATL_COMMENT,
      payload,
      year,
      companyId
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantPostComment));

    return Promise.reject(e);
  }
};

export function fetchASCComments(id, year) {
  const url = `/admin/asccomments?where=year=${year} AND companyid=${id}&include=user&page=0`;
  const payload = restClientGeneral
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.FETCH_ASC_COMMENTS,
    payload
  };
}

export const postASCComment = (companyId, year, comment) => async dispatch => {
  try {
    const reqBody = makeSimpleArgs({
      companyid: companyId,
      year,
      comment
    });

    const payload = await restClientGeneral.post(
      "/admin/asccomments",
      JSON.stringify(reqBody)
    );

    dispatch({
      type: actions.POST_ASC_COMMENT,
      payload,
      year,
      companyId
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantPostComment));

    return Promise.reject(e);
  }
};

export function clearComments(year) {
  return {
    type: actions.CLEAR_COMMENTS
  };
}
