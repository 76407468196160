import React from 'react';
import { connect } from 'react-redux';
import PageHeader from 'components/request/RequestPageHeader';
import RequestCompletion from 'components/request/RequestCompletion';
import SubmissionContainer from 'containers/submission/SubmissionContainer';

const SubmissionRender = ({ history, request, wizard }) => {
  if (request === null || wizard === null) {
    return 'Loading...';
  }

  return (
    <div>
      <PageHeader />
      <SubmissionContainer history={history} />
      <RequestCompletion history={history} />
    </div>
  );
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(SubmissionRender);
