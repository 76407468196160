import React from "react";
import { connect } from "react-redux";
import { Col, Label, Table, Row } from "react-bootstrap";
import {
  Bubble,
  Pager,
  PagerPageSize,
  PagerStatusFilter,
  Divider,
  CounterLabel
} from "ctia-ui";
import ListRow from "components/labRequest/LabListRow";
import NoTableData from "components/NoTableData";
import FilterOptionsDHR from "containers/request/TestRequestList/FilterOptionsDHR";
import CyberTable from "containers/CyberTable";
import { getRequestsForTesting, clearRequests } from "actions/requests";
import { setPageSize, changeRequestTestStatusDHR } from "actions/app";

class TestRequestListDHR extends CyberTable {
  componentWillUnmount() {
    this.props.clearRequests();
  }

  onStatusChange = event => {
    // update app filter settings
    this.props.changeRequestTestStatusDHR(event.target.value);

    // fetch new list (waiting for redux storage update first)
    setTimeout(this.fetchTableData, 150);
  };

  setPagerSize = size => this.props.setPageSize(size);

  fetchTableData = () => {
    const settings = {
      page: this.startPage,
      size: this.props.app.pageSize,
      statusFilter: this.props.app.dhrRequestTestStatus.filter
    };

    return this.props.getRequestsForTesting(settings);
  };

  render() {
    const { requests, app } = this.props;
    const { dhrRequestTestStatus: status } = app;
    const tableData = this.setTableData(requests);
    const { page, pages, records } = this.setPagerData(requests);
    const counter = this.setCounter(tableData, records);

    const renderBubble = () => {
      if (tableData.length > 0) {
        return (
          <Bubble>
            Click on the <strong>Request ID</strong> to view the request. You
            may sort by <strong>Status</strong> type.
          </Bubble>
        );
      }
    };

    const renderTableBody = () => {
      if (tableData.length > 0) {
        return <ListRow tableRows={tableData} />;
      }

      return <NoTableData colSpan={5} />;
    };

    const renderTable = () => {
      return (
        <div>
          <Table striped condensed hover className="ctia-table">
            <thead>
              <tr>
                <th>Request ID</th>
                <th>Vendor</th>
                <th>Model Name/Number</th>
                <th>Request Type</th>
                <th>Testing Status</th>
              </tr>
            </thead>
            <tbody>{renderTableBody()}</tbody>
          </Table>
          <Divider />
          <Pager moveTo={this.fetchTableData} totalPages={pages} page={page} />
        </div>
      );
    };

    return (
      <div>
        <h1 className="block-bottom-offset">
          Requests for Testing <Label>{status.title}</Label>
        </h1>
        {renderBubble()}
        <Row>
          <div id="request-filter">
            <Col xs={4} md={4}>
              <CounterLabel counter={counter} />
            </Col>
            <Col xs={8} md={4}>
              <PagerPageSize
                size={app.pageSize}
                onChangeHandler={this.onChangePageSize}
                options={[50, 100]}
                showAll={true}
              />
            </Col>
            <Col xs={12} md={4}>
              <PagerStatusFilter
                status={status.title}
                statusOptions={<FilterOptionsDHR />}
                onStatusChange={this.onStatusChange}
              />
            </Col>
          </div>
        </Row>
        {renderTable()}
      </div>
    );
  }
}

function mapStateToProps({ requests, app }) {
  return { requests, app };
}

export default connect(mapStateToProps, {
  getRequestsForTesting,
  clearRequests,
  changeRequestTestStatusDHR,
  setPageSize
})(TestRequestListDHR);
