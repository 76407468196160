import _ from "lodash";
import * as actions from "actions/types";
import {
  fillUploadedDocsArray,
  fillUploadedDocsArrayBLC,
  testReportsCount
} from "helpers/UploadsHelper";
import { approved } from "helpers/DocumentStatus";
import { testStatusIds } from "helpers/RequestTestsHelper";

const fetchTests = (action, state) => {
  if (!action.payload.data) return state;

  return [...action.payload.data.data];
};

const changeField = (action, state) => {
  if (!action.payload) return state;

  const { field, value, testId } = action.payload;
  const ind = _.findIndex(state, ["id", testId]);

  if (ind > -1) {
    const newState = [...state];
    newState[ind][field] = value;
    return newState;
  }

  return state;
};

const fetchReports = (action, state) => {
  if (!action.payload.data) return state;

  const data = action.payload.data.data;

  if (data.length) {
    const testId = data[0].requesttestid;
    const ind = _.findIndex(state, ["id", testId]);

    if (ind > -1) {
      const newState = [...state];
      newState[ind].reports = fillUploadedDocsArray(testReportsCount, data);
      return newState;
    }
  }

  return state;
};

const fetchReportsOta = (action, state) => {
  if (!action.payload.data) return state;

  const moduleReports = action.payload.data.data || [];
  const newState = [...state];
  const moduleToUpdate = _.find(state, ["id", action.testModule.id]);

  moduleToUpdate.reports = fillUploadedDocsArray(
    testReportsCount,
    moduleReports
  );

  return newState;
};

const fetchReportsBLC = (action, state) => {
  if (!action.payload.data) return state;
  const data = action.payload.data.data;
  if (data.length) {
    const testId = data[0].requesttestid;
    const ind = _.findIndex(state, ["id", testId]);

    if (ind > -1) {
      const newState = [...state];
      newState[ind].reports = fillUploadedDocsArrayBLC(data);
      return newState;
    }
  }
  else if(!data) {
      const newState = [...state];
      newState[0].reports = fillUploadedDocsArrayBLC();
      return newState
  }
};

const removeTestReport = (action, state) => {
  const { testId, slot } = action.payload;
  const newState = [...state];
  const testModule = _.find(newState, ["id", testId]);

  if (!testModule) return state;

  const reportIndex = _.findIndex(testModule.reports, ["slot", slot]);

  if (reportIndex > -1) {
    testModule.reports[reportIndex] = {
      docId: 0,
      file: null,
      slot,
      title: ""
    };
  }

  return newState;
};

const updateReportStatus = (action, state) => {
  // payload shortcuts
  const { slot, status, testId } = action.payload;
  const newState = [...state];

  // grab testModule first
  const testModule = _.find(newState, ["id", testId]);

  // ¯\_(ツ)_/¯ \\
  if (!testModule) return [...state];

  // find report entry for passed slot id:
  const report = _.find(testModule.reports, ["slot", slot]);

  // update report status
  report.document.documentstatusid = status;

  // now change testModule status if needed
  const isCompleted = _.every(testModule.reports, report => {
    if (report.docId === 0) return true;
    return report.document.documentstatusid === approved;
  });

  testModule.requestteststatusid = isCompleted
    ? testStatusIds.complete
    : testStatusIds.underReview;

  // need to create immutable brand new copy of state
  // to be able to update redux related components
  return [...newState];
};

const addOtaPlan = (action, state) => {
  const { details } = action.payload;
  const newState = state ? [...state] : [];

  if (!details) {
    return [...newState, { ...action.payload }];
  }

  return [...newState, action.payload.details];
};

const removeOtaPlan = (action, state) => {
  const curState = [...state];
  const removedPlan = action.payload;

  if (_.has(removedPlan, "details")) {
    _.remove(curState, plan => plan.id === removedPlan.details.id);
  } else {
    _.remove(
      curState,
      plan => plan.planInfo.value === removedPlan.planInfo.value
    );
  }

  return curState;
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_REQUEST_TESTS:
      return fetchTests(action, state);
    case actions.CHANGE_TEST_FIELD:
      return changeField(action, state);
    case actions.FETCH_TEST_REPORTS:
      return fetchReports(action, state);
    case actions.FETCH_TEST_REPORTS_OTA:
      return fetchReportsOta(action, state);
    case actions.FETCH_TEST_REPORTS_BLC:
      return fetchReportsBLC(action, state);
    case actions.UPDATE_REPORT_STATUS:
      return updateReportStatus(action, state);
    case actions.CLEAR_TESTS:
    case actions.INIT_NEW_REQUEST:
    case actions.LOGOUT:
      return null;
    case actions.ADD_OTA_TEST_PLAN:
      return addOtaPlan(action, state);
    case actions.REMOVE_OTA_TEST_PLAN:
      return removeOtaPlan(action, state);
    case actions.DELETE_TEST_REPORT_OTA:
      return removeTestReport(action, state);
    default:
      return state;
  }
}
