import _ from "lodash";
import { restClientGeneral } from "libs/restClient";
import { testStatuses } from "helpers/RequestTestsHelper";
import {
  requestStatuses,
  getBasicRequestStatusList
} from "helpers/RequestHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";
import * as prepareArgs from "helpers/ArgsHelper";
import { getOppositeIEEE } from "helpers/BatteryCertHelper";
import * as ls from "libs/localStorage";
import { fileDownload } from "actions/uploads";
import { types } from "helpers/BatteryCertHelper";

export const fetchApprovedBatteryRequestsForTesting = () => async dispatch => {
  try {
    const { accepted, underReview } = testStatuses;
    const { requestWithdrawn } = requestStatuses;
    const testStatusList = `'${accepted}', '${underReview}'`;
    const blcId = ls.getValue("idBLC");

    const url = `/requests?where=client='battery' AND requeststatusid <> ${requestWithdrawn} AND testingstatus IN (${testStatusList}) AND certtypeid <> ${blcId}&page=0&orderBy=transactionnumber DESC&include=devicemodel`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_REQUESTS_FOR_AUDITING,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export function getRequestsForTesting() {
  return call.dispatchWithPreloader(fetchApprovedBatteryRequestsForTesting);
}

// using General API to fetch and filter battery related stuff only!
export const fetchBatteryRequests = ({
  page,
  size,
  statusFilter
}) => async dispatch => {
  try {
    const clientName = "battery";
    const filterList = statusFilter.join();
    const blcId = ls.getValue("idBLC");
    const pagerArgs = prepareArgs.getPageArgsAsQueryString(page, size);

    const urlBase = `/requests?where=client='${clientName}' AND requeststatusid IN (${filterList}) AND certtypeid <> ${blcId}&${pagerArgs}&orderBy=transactionnumber DESC`;
    const urlTail =
      "&include=devicemodel,manufsite&select=id,devicemodelid,manufsiteid,formatedtransactionnumber,transactionnumber,vendorname,vendorproductname,testingstatus,requeststatusid,requesttypeid,withdrawnat,withdrawreason";
    const url = `${urlBase}${urlTail}`;

    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

// action creator for using inside components
export function getBatteryRequests(page, size, statusFilter) {
  return call.dispatchWithPreloader(fetchBatteryRequests, {
    page,
    size,
    statusFilter
  });
}

// fetch ready for approve (to be certified) request list
export const fetchBatteryForApproveRequests = () => async dispatch => {
  try {
    const clientName = "battery";
    const blcId = ls.getValue("idBLC");
    const url = `/requests?where=client='${clientName}' AND certtypeid <> ${blcId} AND requeststatusid=5&page=0&orderBy=transactionnumber DESC&include=devicemodel,manufsite`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_APPROVE_WAITING_REQUESTS,
      payload: response.data
    });

    dispatch({
      type: actions.COUNT_APPROVE_WAITING_REQUESTS,
      payload: response.data.data.length
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

// fetch certified audit requests versions
export const fetchCertifiedAuditVersions = (
  id,
  statusFilter = [requestStatuses.approved]
) => async dispatch => {
  dispatch({
    type: actions.START_PRELOADER
  });

  const selectFields = [
    "address1",
    "address2",
    "city",
    "state",
    "countrycode",
    "zipcode",
    "requesttypeid",
    "completedat",
    "crslversion",
    "id",
    "ieeetypeid",
    "formatedtransactionnumber",
    "manufsite",
    "manufsiteid",
    "modelnumber",
    "name",
    "primarylabid",
    "requeststatusid",
    "vendorname",
    "vendornote",
    "vendorproductname",
    "withdrawnat",
    "withdrawreason"
  ].join(",");
  const statuses = statusFilter.join();

  try {
    const url =
      `/requests?` +
      `select=${selectFields}` +
      `&orderBy=completedat DESC` +
      `&where=manufsiteid=${id} AND requeststatusid IN (${statuses})` +
      `&include=manufsite(address)` +
      `&page=0`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_MODEL_VERSIONS,
      payload: response
    });

    dispatch({
      type: actions.STOP_PRELOADER
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    dispatch({
      type: actions.STOP_PRELOADER
    });

    return Promise.reject(e);
  }
};

// fetch certified device versions
export const fetchCertifiedDeviceVersions = (
  id,
  certTypes,
  statusFilter = [requestStatuses.approved],
  useGlobalPreloader = true
) => async dispatch => {
  if (useGlobalPreloader) {
    dispatch({
      type: actions.START_PRELOADER
    });
  }

  const isBSC = certTypes && _.includes(certTypes, types.BSC);

  const select = isBSC
    ? `select=${[
        "completedat",
        "formatedtransactionnumber",
        "id",
        "hwversion",
        "hasPendingRecognition",
        "linkedrequestcerttypeid",
        "linkedformatedtransactionnumber",
        "linkedrequestdevicemodelid",
        "linkedrequestid",
        "linkedrequestvendorname",
        "linkedrequestvendorproductname",
        "linkedrequestrevision",
        "type",
        "modelnumber",
        "primarylabid",
        "requeststatusid",
        "requesttypeid",
        "requestid",
        "swversion",
        "vendorid",
        "vendorname",
        "vendorproductname",
        "withdrawnat",
        "withdrawreason"
      ].join(",")}&`
    : "";
  const urlPrefix = isBSC ? "/bsc" : "";
  const include = isBSC
    ? `&include=devicemodel,linkedrequests`
    : `&include=devicemodel,requestmanufsites`;
  const statuses = statusFilter.join();
  const where = `where=devicemodelid=${id} AND requeststatusid IN (${statuses})`;
  const orderBy = `&orderBy=completedat DESC`;
  const url = `${urlPrefix}/requests?${select}${where}${include}${orderBy}&page=0`;

  try {
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_MODEL_VERSIONS,
      payload: response
    });

    dispatch({
      type: actions.STOP_PRELOADER
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    dispatch({
      type: actions.STOP_PRELOADER
    });

    return Promise.reject(e);
  }
};

export const fetchBatteryRequestsForTesting = ({
  page,
  size,
  statusFilter,
  labid
}) => async dispatch => {
  try {
    const clientName = "battery";
    const blcId = ls.getValue("idBLC");
    const filterList = `'${statusFilter.join("','")}'`;
    const statusList = getBasicRequestStatusList();
    const selectList =
      "id,transactionnumber,formatedtransactionnumber,vendorproductname,vendorname,ieeetypeid,requesttypeid,testingstatus,modelnumber,name";
    const pagerArgs = prepareArgs.getPageArgsAsQueryString(page, size);

    const url = `/requests?${pagerArgs}&select=${selectList}&orderBy=transactionnumber DESC&include=devicemodel,manufsite&where=client='${clientName}' AND testingstatus IN (${filterList}) AND requeststatusid IN (${statusList}) AND certtypeid <> ${blcId} AND primarylabid = ${labid}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getBatteryRequestsForTesting(page, size, statusFilter, labid) {
  return call.dispatchWithPreloader(fetchBatteryRequestsForTesting, {
    page,
    size,
    statusFilter,
    labid
  });
}

export function batteryTestReportDownload(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: <BatteryType><000000>_REPORT_<slot>.<extension>
  // e.g. CRR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/requesttests/${test}/reports/${slot}?download=1`;
  const restClient = "general";

  return fileDownload(fileName, url, restClient);
}

export function mapNonSarManufSites(linkedSites) {
  return {
    type: actions.MAP_NON_SAR_SITES,
    payload: linkedSites
  };
}

export const approveBatteryRequest = (
  request,
  approved,
  urlPrefix
) => async dispatch => {
  const newReqStatus =
    approved === true
      ? requestStatuses.approved
      : approved === false
      ? requestStatuses.requestWithdrawn
      : null;

  if (!newReqStatus) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantSubmitRequest
    });
  }

  try {
    const url = `${urlPrefix}/requests/${request.id}`;
    const body = prepareArgs.prepareRequestApproveArgs(newReqStatus);
    const response = await restClientGeneral.put(url, body);

    dispatch({ type: actions.UPDATE_REQUEST_STATUS, payload: response });

    const message = approved
      ? notifyMessages.requestApprovedSuccessfully
      : notifyMessages.requestWithdrawnSuccessfully;

    dispatch({ type: actions.SHOW_NOTICE, payload: message });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });
  }
};

export function mapBatteryDeviceType(certTypes) {
  return {
    type: actions.MAP_BATTERY_DEVICE_TYPE,
    payload: certTypes
  };
}

// Devicemodels for a new approach using in BatteryModelAutocomplete component
export const fetchBatteryModelsCRR = (ieee, companyId) => async dispatch => {
  const oppositeIeee = getOppositeIEEE(ieee);
  try {
    const url = `/crr/devicemodels?where=ieee${oppositeIeee}crr=1 AND ieee${ieee}crr=0 AND vendorid=${companyId}&include=devicemodelbatterycomponent&select=id,modelnumber,voltage,voltagerateid,capacity,capacityrateid&returnAs=Query&page=0&orderBy=modelnumber`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_VENDOR_BATTERY_MODELS,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export const fetchBatteryModelsARR = (ieee, companyId) => async dispatch => {
  const oppositeIeee = getOppositeIEEE(ieee);
  try {
    const url = `/arr/devicemodels?where=ieee${oppositeIeee}arr=1 AND ieee${ieee}arr=0 AND vendorid=${companyId}&select=id,modelnumber&returnAs=Query&page=0&orderBy=modelnumber`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_VENDOR_BATTERY_MODELS,
      payload: response.data.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export function getVendorsBatteryRequests(page, size, statusFilter) {
  return call.dispatchWithPreloader(fetchVendorsBatteryRequests, {
    page,
    size,
    statusFilter
  });
}

export const fetchVendorsBatteryRequests = ({
  page,
  size,
  statusFilter
}) => async dispatch => {
  try {
    const clientName = "battery";
    const filterList = statusFilter.join();
    const blcId = ls.getValue("idBLC");
    const bscId = ls.getValue("idBSC");
    const eprrId = ls.getValue("idEPRR");
    const pagerArgs = prepareArgs.getPageArgsAsQueryString(page, size);

    const urlBase = `/requests?where=client='${clientName}' AND requeststatusid IN (${filterList}) AND certtypeid <> ${blcId} AND certtypeid IN (${eprrId},${bscId})&${pagerArgs}&orderBy=transactionnumber DESC`;
    const urlTail =
      "&include=devicemodel&select=id,devicemodelid,formatedtransactionnumber,transactionnumber,vendorname,vendorproductname,testingstatus,requeststatusid,requesttypeid,withdrawnat,withdrawreason";
    const url = `${urlBase}${urlTail}`;

    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });

    return response;
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });

    return Promise.reject(e);
  }
};

export async function withdrawRequest({ id, reason = "", prefix = "" }) {
  try {
    const args = prepareArgs.prepareWithdrawnArgs(reason);
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    return payload;
  } catch (error) {
    throw error;
  }
}

export async function unwithdrawRequest({
  id,
  prefix = "",
  setApproved = false
}) {
  try {
    const args = prepareArgs.prepareUnwithdrawnArgs(setApproved);
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    return payload;
  } catch (error) {
    throw error;
  }
}

export async function delistRequest({ id, prefix = "" }) {
  try {
    const args = prepareArgs.prepareDelistArgs();
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    return payload;
  } catch (error) {
    throw error;
  }
}

export async function relistRequest({ id, prefix = "" }) {
  try {
    const args = prepareArgs.prepareRelistArgs();
    const apiPrefix = prefix ? `/${prefix}` : "";

    const payload = await restClientGeneral.put(
      `${apiPrefix}/requests/${id}`,
      JSON.stringify(args)
    );

    return payload;
  } catch (error) {
    throw error;
  }
}
