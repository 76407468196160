import React from "react";
import { connect } from "react-redux";
import { WithModal } from "ctia-ui";
import AgreementModal from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementModal";
import AgreementCheckbox from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementCheckbox";
import LicenseModalContent from "containers/request/LicenseAgreement/BatteryAgreement/Modals/LicenseContent";
import RecognitionModalContent from "containers/request/LicenseAgreement/BatteryAgreement/Modals/RecognitionContent";
import { BATTERY_LICENSE_AGREEMENT } from "containers/request/LicenseAgreement/BatteryAgreement/agreementHelper";
import {
  downloadRecognition,
  downloadAgreementBLC
} from "actions/agreementsBattery";

import "containers/request/LicenseAgreement/style.css";

class BatteryLicenseAgreement extends WithModal {
  onPrintClick = () => {
    const { request, certType, downloadRecognition, downloadAgreementBLC } = this.props;
    if (certType === "blc") return downloadAgreementBLC(request);
    else return downloadRecognition(request, certType);
  };

  render() {
    const title = "License Agreement";
    const modalContent = this.props.request.isBSC
      ? LicenseModalContent
      : RecognitionModalContent;

    return (
      <div>
        <h3>
          <strong>{title}</strong>
        </h3>
        <p className="agr-description">
          Once the device is certified, the certification license agreement
          becomes effective. Please review the agreement{" "}
          <span
            onClick={this.handleModal}
            className="agr-link cursor-pointer glow-link"
          >
            here
          </span>{" "}
          and click the accept box below.
        </p>
        <p className="agr-description">
          I understand and acknowledge that my company agrees to the Terms and
          Conditions for the specified device set forth in the link above that
          will become effective immediately upon the date of device
          certification.
        </p>
        <div className="text-center agr-description">
          <AgreementCheckbox
            togglerId={BATTERY_LICENSE_AGREEMENT}
            title={title}
          />
        </div>
        <AgreementModal
          show={this.state.showModal}
          modalTitle={title}
          modalContent={modalContent}
          modalHandler={this.handleModal}
          downloadHandler={this.onPrintClick}
          isBLC={this.props.certType === "blc"}
          version={this.props.request.licenseagreementversionid}
        />
      </div>
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  	downloadRecognition,
	downloadAgreementBLC
})(BatteryLicenseAgreement);
