import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import InvoiceYearSelector from "containers/ControlPanel/Payments/ASC/InvoiceYearSelector";
import ASCInvoicingStat from "containers/ControlPanel/Payments/ASC/ASCInvoicingStat";
import CenterListASC from "containers/ControlPanel/Payments/ASC/CenterListASC";
import { getCurrATLYear } from "helpers/DateHelper";
import { setInvoicingYear } from "actions/asc";
import "containers/ControlPanel/Payments/payments.css";

const ASCInvoicing = ({ asc, setInvoicingYear }) => {
  useEffect(() => {
    if (asc && !asc.invoicingYear) {
      setInvoicingYear(getCurrATLYear());
    }
  }, [setInvoicingYear, asc]);

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div style={{ display: "flex" }} className="h1-div">
            <div>Service Center Invoicing,&nbsp;</div>
            <InvoiceYearSelector />
          </div>
        </Col>
      </Row>
      <ASCInvoicingStat />
      <CenterListASC />
    </div>
  );
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps, {
  setInvoicingYear
})(ASCInvoicing);
