import _ from "lodash";
import * as actions from "actions/types";
import { sortByFieldname } from "helpers/SortHelper";

const fetchModel = (action, state) => {
  if (!action.payload.data) return state;
  return {
    ...state,
    info: action.payload.data.data
    // versions: null,
    // selectedVersion: null
  };
};

const fetchVersions = (action, state) => {
  if (!action.payload.data) return state;
  return { ...state, versions: action.payload.data.data };
};

const checkForDuplicates = (action, state) => {
  if (!action.payload.data) return state;
  return { ...state, isDuplicate: action.payload.data.meta.TOTALRECORDS > 0 };
};

const setPtcrbModels = (action, state) => {
  const rawList = _.isEmpty(action.payload) ? [] : action.payload;

  if (rawList.length) {
    // map values first
    const mappedModels = rawList.map(model => {
      return { title: String(model.modelnumber), value: String(model.id) };
    });

    // filter out non unique model titles and ids
    const uniqueByTitle = _.uniqBy(mappedModels, "title");
    const uniqueByValue = _.uniqBy(uniqueByTitle, "value");

    // finally sort the list
    const sortedModels = sortByFieldname(uniqueByValue, "title");

    return { ...state, ptcrbModels: sortedModels };
  }

  return state;
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_MODEL:
      return fetchModel(action, state);
    case actions.FETCH_MODEL_VERSIONS:
      return fetchVersions(action, state);
    case actions.UPDATE_MODEL_FIELD:
      return { ...state, ...action.payload };
    case actions.CLEAR_MODEL:
    case actions.LOGOUT:
      return null;
    case actions.CHECK_PTCRB_MODELS:
      return setPtcrbModels(action, state);
    case actions.CHECK_EXISTED_MODEL:
      return checkForDuplicates(action, state);
    default:
      return state;
  }
}
