import { connect } from 'react-redux';
import React from 'react';
import FormElement from 'components/FormElement';
import CyberComponent from 'containers/CyberComponent';
import { handleInputChange } from 'actions/request';
import { hasStringValue } from 'helpers/RequestHelper';
import { emptyMsg } from 'helpers/FormHelper';

class DeviceInfoExtended extends CyberComponent {
  constructor(props) {
    super(props);

    this.state = {
      showHwVersion: false,
      showSwVersion: false,
      showDescChange: false
    };
  }

  componentDidMount() {
    this.setState({
      showHwVersion: this.hasHwVersion(),
      showSwVersion: this.hasSwVersion(),
      showDescChange: this.hasDescChange()
    });
  }

  isValidationRequired = () => {
    return this.props.user.canEditRequest;
  };

  hasHwVersion() {
    return hasStringValue(this.props.request, 'hwVersion');
  }

  hasSwVersion() {
    return hasStringValue(this.props.request, 'swVersion');
  }

  hasDescChange() {
    return hasStringValue(this.props.request, 'descriptionOfChange');
  }

  validateHwVersion() {
    if (!this.isValidationRequired()) return null;
    return this.state.showHwVersion &&
      hasStringValue(this.props.request, 'hwVersion')
      ? null
      : 'error';
  }

  validateSwVersion() {
    if (!this.isValidationRequired()) return null;
    return this.state.showSwVersion &&
      hasStringValue(this.props.request, 'swVersion')
      ? null
      : 'error';
  }

  validateDescChange() {
    if (!this.isValidationRequired()) return null;
    return this.state.showDescChange &&
      hasStringValue(this.props.request, 'descriptionOfChange')
      ? null
      : 'error';
  }

  render() {
    const { request, handleInputChange, user } = this.props;
    const { hwVersion, swVersion, descriptionOfChange } = request;
    const isEditable = user.canEditRequest;
    const inputType = isEditable ? 'text' : '';

    const { showHwVersion, showSwVersion, showDescChange } = this.state;

    if (request.id > 0) {
      return [
        <FormElement
          key={1}
          id="hwVersion"
          label="HW Version"
          value={hwVersion}
          show={showHwVersion}
          editable={isEditable}
          type={inputType}
          onChange={handleInputChange}
          validationState={this.validateHwVersion()}
          validationMessage={emptyMsg}
          maxLength="50"
        />,
        <FormElement
          key={2}
          id="swVersion"
          label="SW Version"
          value={swVersion}
          show={showSwVersion}
          editable={isEditable}
          type={inputType}
          onChange={handleInputChange}
          validationState={this.validateSwVersion()}
          validationMessage={emptyMsg}
          maxLength="50"
        />,
        <FormElement
          key={3}
          id="descriptionOfChange"
          label="Description of Change"
          value={descriptionOfChange}
          show={showDescChange}
          editable={isEditable}
          type={inputType}
          onChange={handleInputChange}
          validationState={this.validateDescChange()}
          validationMessage={emptyMsg}
        />
      ];
    }

    return null;
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(
  mapStateToProps,
  { handleInputChange }
)(DeviceInfoExtended);
