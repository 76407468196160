import React from 'react';
import { connect } from 'react-redux';
import SubmitButton from 'components/form/buttons/SubmitButton';
import { submitWizard } from 'actions/wizard';

const WizardSubmitButton = ({
  app,
  wizard,
  submitWizard,
  onSubmit,
  isValid
}) => {
  const onClick = () => {
    // if handler is defined
    if (onSubmit) {
      onSubmit();
      return; // stop here
    }

    // otherwise just call submit wizard action:
    submitWizard();
  };

  if (wizard && wizard.isFinalStep) {
    return (
      <SubmitButton
        onClick={onClick}
        disabled={!isValid}
        isLoading={app.isSubmitting}
        loadingText="Submitting..."
        label="Submit Request"
      />
    );
  }

  return null;
};

function mapStateToProps({ app, wizard }) {
  return { app, wizard };
}

export default connect(
  mapStateToProps,
  { submitWizard }
)(WizardSubmitButton);
