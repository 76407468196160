import _ from "lodash";
import * as actions from "actions/types";
import { restClient } from "libs/restClient";
import { notifyMessages } from "helpers/NotifyHelper";
import { resolvePromise, rejectPromise } from "helpers/PromiseHelper";
import { getOTACostsIds } from "helpers/OTATestPlanHelper";
import * as call from "helpers/ActionHelper";
import * as otaHelper from "helpers/OTATestPlanHelper";
import * as otaArgs from "helpers/ArgsOtaHelper";
import { createNewBillingEntry } from "actions/billing";
import { submitWizard } from "actions/wizard";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import * as model from "actions/model";
import * as reqOta from "actions/requestOta";

export const fetchInterfaces = () => async dispatch => {
  try {
    const url = "/interfaces?include=companyinterfaces,interfacecosts&page=0";
    const response = await restClient.get(url);
    const payload = response.data.data;

    dispatch({
      type: actions.FETCH_OTA_INTERFACES,
      payload
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export const fetchCosts = idList => async dispatch => {
  // prepare: get costs ids for fetched test plans
  const costsIds = getOTACostsIds(idList);

  if (costsIds && _.size(costsIds) > 0) {
    try {
      // format ids array (e.g. [7, 40, 89]) to simple string (e.g. '7,40,89')
      const idsStr = costsIds.toString();

      // 3 - get existed OTA costs for fetched test plans only:
      const costsUrl = `/costs?where=id IN (${idsStr})&page=0`;
      const costsRes = await restClient.get(costsUrl);

      dispatch({
        type: actions.GET_OTA_COSTS,
        payload: costsRes.data.data
      });

      return costsRes;
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));

      return Promise.reject(e);
    }
  } else {
    return Promise.reject("fetchCosts - passed list is empty");
  }
};

export const fetchOtaLabs = () => async dispatch => {
  try {
    const labsResponse = await restClient.get("/companies/labs?page=0");

    dispatch({
      type: actions.FETCH_OTA_LABS,
      payload: labsResponse.data.data
    });

    return labsResponse;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

// attempt to combine some logic inside just one action creator
export const fetchOTAInterfaces = vendorId => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(fetchInterfaces())
    .then(res => dispatch(fetchCosts(res.data.data)))
    .then(() => dispatch(fetchOtaLabs()))
    .then(() => dispatch(model.fetchExistedOtaModels(vendorId)))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export function addTestPlan(testPlan) {
  return resolvePromise({
    type: actions.ADD_OTA_TEST_PLAN,
    payload: testPlan
  });
}

export function removeTestPlan(testPlan) {
  if (testPlan && testPlan.planInfo.value > 0) {
    return resolvePromise({
      type: actions.REMOVE_OTA_TEST_PLAN,
      payload: testPlan
    });
  }

  return rejectPromise("Interface (Test Plan) ID not provided");
}

export function clearOtaStore() {
  return {
    type: actions.CLEAR_OTA_STORE,
    payload: null
  };
}

export const submitRequestData = request => async dispatch => {
  try {
    // if request uses existed model - we should use another endpoint and args
    const reqParams = otaArgs.defineSubmissionParams(request);
    const response = await restClient.post(
      reqParams.url,
      JSON.stringify(reqParams.body)
    );

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitOtaRequest(request, user) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(createNewBillingEntry(request, user))
      .then(res => {
        // add billing id + submitter id
        const reqData = {
          ...request,
          billingId: res.id,
          submitterId: user.id
        };

        return dispatch(submitRequestData(reqData));
      })
      .then(() => {
        dispatch(submitWizard());
        dispatch(call.stopPreloader());
      })
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const defineOtaInterfaces = (tests, ota) => {
  const { interfaces, costs, otaLabs } = ota;
  const list = tests || [];

  const payload = list.map(test => {
    const planInfo = otaHelper.getTestPlan(test.interfaceid, interfaces);
    const costAmount = otaHelper.getOtaCost(costs, planInfo.costId).amount;
    const catlInfo = otaHelper.getCATLInfo(test.labid, otaLabs);
    return {
      details: { ...test },
      planInfo: { ...planInfo, costAmount },
      catlInfo
    };
  });

  return {
    type: actions.SET_OTA_INTERFACES,
    payload
  };
};

export function clearOtaInterfaces() {
  return {
    type: actions.CLEAR_OTA_INTERFACES
  };
}

export function filterTestPlans(selectedPlans) {
  return {
    type: actions.FILTER_OTA_TEST_PLANS,
    payload: selectedPlans
  };
}

export const updateOtaModel = (modelId, modelArgs) => async dispatch => {
  try {
    const url = `/devicemodels/${modelId}`;

    return await restClient.put(url, modelArgs);
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function updateRequestTests(request) {
  // get model id
  const modelId = request.model.id;
  // prepare args
  const requesttests = otaArgs.formatTestsToArgs(request.otaInterfaces);
  const modelArgs = makeSimpleArgs({ requesttests });

  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(updateOtaModel(modelId, modelArgs))
      .then(() => {
        dispatch(call.showNotice(notifyMessages.requestUpdatedSuccessfully));
        dispatch(call.stopPreloader());
      })
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const deleteOtaTest = testPlan => async dispatch => {
  try {
    const testId = testPlan.details.id;

    await restClient.delete(`/requesttests/${testId}`);

    // update interface list on client
    dispatch(removeTestPlan(testPlan));

    // also need to update invoice info
    dispatch(reqOta.getRequestDetails(testPlan.details.requestid));

    // show warning message if invoice amount was affected by this action
    dispatch(call.showNotice(notifyMessages.invoiceAmountAffectedTestRemoved));
  } catch (e) {
    dispatch(
      call.showError(
        "At least one test must exist for an OTA certification request. Please add a test before deleting this one."
      )
    );

    return Promise.reject(e);
  }
};

export const addOtaTest = testPlan => async dispatch => {
  try {
    const { requestId, catlInfo, planInfo } = testPlan;
    const body = {
      args: {
        requestid: requestId,
        labid: catlInfo.id,
        interfaceid: planInfo.value
      }
    };

    const res = await restClient.post("requesttests", body);

    // provide new test id and other details
    testPlan.details = res.data.data;

    // update interface list on client
    dispatch(addTestPlan(testPlan));

    // also need to update invoice info
    dispatch(reqOta.getRequestDetails(requestId));

    // show warning message if invoice amount was affected by this action
    dispatch(call.showNotice(notifyMessages.invoiceAmountAffectedTestAdded));
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};
