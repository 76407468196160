const isAcceptedByLab = request => {
  return request.testStatus !== "Pending" && request.testStatus !== "Rejected";
};

export function checkDeviceInfoPermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (!isAcceptedByLab(request) && request.isBLC) return true;

    return false;
  }

  return false;
}

export function checkPocPermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (!isAcceptedByLab(request) && request.isBLC) return true;

    // disabled by default
    return false;
  }

  return false;
}

export function checkBillingPermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    if (!isAcceptedByLab(request) && request.isBLC) return true;

    // disabled by default
    return false;
  }

  return false;
}

export function checkTestsPermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return false;
  }

  if (!isAcceptedByLab(request) && request.isBLC) return true;

  return false;
}

export function checkPaymentPermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) return false;

  return false;
}

export function checkModelnamePermission(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled && request.isInitial ? true : false;
    return request.isInitial ? true : false;
  }

  // Vendor
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    // not allowed to edit submitted
    if (request.isEco) return false;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}

export function checkLabFieldsPermission(request, user) {
  // TODO: uncomment to allow use Modify mode switcher
  // return user.isStaff && user.togglerEnabled && isAcceptedByLab(request);
  return user.isStaff && isAcceptedByLab(request);
}

export function checkPermissionsDHR(request, user) {
  // CTIA Staff
  if (user.isStaff) {
    // TODO: uncomment to allow use Modify mode switcher
    // return user.togglerEnabled ? true : false;
    return true;
  }

  // Vendor (Manufacturer)
  if (user.isVendor) {
    // allow to edit on submissions
    if (!request.id) return true;

    return isAcceptedByLab(request) ? false : true;
  }

  return false;
}
