import _ from "lodash";

export const selectOneOption = {
  id: 0,
  companyName: "--- Select One ---",
  companyId: ""
};
export const selectOtherOption = {
  id: -1,
  companyName: "--- Enter Other ---",
  companyId: 0
};

export const dropdownOptions = (
  companyOptions = []
) => {
  return !_.isEmpty(companyOptions)
    ? [
      selectOneOption,
      ...companyOptions,
      selectOtherOption
    ]
    : [
      selectOtherOption
    ];
};

export const selectionOptions = ({
  companies,
  companyType,
  isPtcrb = false,
  addSelectOneAndOtherOptions = false
}) => {
  const { labs, manufacturers, operators, ptcrbLabs, suppliers } = companies;
  let companiesList;
  switch(companyType.toLowerCase()) {
  case "lab": companiesList = isPtcrb ? ptcrbLabs : labs; break;
  case "laboratory": companiesList = ptcrbLabs; break;
  case "manufacturer": companiesList = manufacturers; break;
  case "operator": companiesList = operators; break;
  case "supplier": companiesList = suppliers; break;
  default: companiesList = [];
  }
  const companyOptions = _.map(companiesList, (company, index) => {
    const { id, name } = company;
    return {
      id   : index + 1,
      companyName: name,
      companyId: id,
    };
  });
  const orderedCompanyOptions = _.orderBy(companyOptions,
                                 option => option.companyName.toLowerCase());

  return addSelectOneAndOtherOptions
    ? dropdownOptions(orderedCompanyOptions)
    : orderedCompanyOptions;
};

export const getSingleOption = ({
  companyName = ""
}) => {
  return {
    id: -1,
    companyName,
    companyId: -1
  };
};

export const getOptionWithCompanyName = ({
  companyName = "",
  options = []
}) => {
  if (_.isUndefined(companyName) ||
      _.isNull(companyName) ||
      _.isEmpty(companyName))
    return selectOneOption;
  else {
    const lcCompanyName = companyName.trim().toLowerCase();
    const option = _.find(options,
      option => option.companyName.trim().toLowerCase() === lcCompanyName) || null;
    return !_.isNull(option)
      ? option
      : selectOtherOption;
  }
};

export const getOptionWithCompanyId = ({
  companyId = -1,
  options = []
}) => {
  if (_.isUndefined(companyId) ||
      _.isNull(companyId) ||
      _.isEmpty(companyId))
    return selectOneOption;
  else {
    const option = _.find(options,
      option => option.companyId === Number(companyId)) || null;
    return !_.isNull(option)
      ? option
      : selectOtherOption;
  }
};
