import React from "react";
import { connect } from "react-redux";
import Comments from "components/Comments/Comments";
import CommentsGeneral from "components/Comments/CommentsGeneral";

const CommentsFactory = ({ request }) => {
  if (!request) return null;

  if (request.isCRR) {
    return <CommentsGeneral />;
  }

  return <Comments />;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(CommentsFactory);
