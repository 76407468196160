import _ from "lodash";
import { restClient } from "libs/restClient";
import * as bb from "helpers/RequestBackbone";
import { notifyMessages } from "helpers/NotifyHelper";
import { dhrTabs } from "helpers/WizardHelper";
import * as call from "helpers/ActionHelper";
import * as perm from "helpers/PermissionHelper";
import * as dhrArgs from "helpers/ArgsHelperDHR";
import * as actions from "actions/types";
import * as wizard from "actions/wizard";
import * as model from "actions/model";
import * as tests from "actions/tests";
import { sign } from "actions/agreement";
import { fileDownload } from "actions/uploads";

export function initInitialDHR(manufacturer = "", requestBackbone) {
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      model: { vendor: { name: manufacturer } }
    }
  };
}

export function initEcoDHR(manufacturer = "", requestBackbone) {
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      manufacturer
    }
  };
}

export function setRequestPermissions(request, user) {
  const payload = {
    canEditDeviceInfo: perm.checkDeviceInfoPermission(request, user),
    canEditRequest: perm.checkDeviceInfoPermission(request, user),
    canEditPoc: perm.checkPocPermission(request, user),
    canEditBilling: perm.checkBillingPermission(request, user),
    canEditTests: perm.checkTestsPermission(request, user),
    canEditPaymentInfo: perm.checkPaymentPermission(request, user)
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function runInitialWizardDHR(user) {
  return dispatch => {
    const requestBackbone = bb.getDHRRequestBackbone();

    dispatch(initInitialDHR(user.company, requestBackbone));
    dispatch(
      wizard.initWizard({
        steps: dhrTabs.initial,
        totalSteps: _.size(dhrTabs.initial)
      })
    );
    dispatch(setRequestPermissions({ ...requestBackbone }, user));
  };
}

export function runEcoWizardDHR(user) {
  return dispatch => {
    const requestBackbone = bb.getDHRRequestBackboneECO();

    dispatch(initEcoDHR(user.company, requestBackbone));
    dispatch(
      wizard.initWizard({
        steps: dhrTabs.eco,
        totalSteps: _.size(dhrTabs.eco)
      })
    );
    dispatch(setRequestPermissions({ ...requestBackbone }, user));
  };
}

export const fetchExistedModels = vendorId => async dispatch => {
  try {
    const url = `/devicemodels?where=vendorid=${vendorId} AND dhrverified=0 AND ctiaverified=1&select=id,modelnumber&page=0&allcerts=true&returnAs=query&distinct=true`;
    const payload = await restClient.get(url);

    dispatch({
      type: actions.FETCH_DHR_EXISTED_MODELS,
      payload
    });

    return payload;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchDhrLabs = () => async dispatch => {
  try {
    const labsResponse = await restClient.get("/companies/labs?page=0");

    dispatch({
      type: actions.FETCH_DHR_LABS,
      payload: labsResponse.data.data
    });

    return labsResponse;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchDHRModelsAndLabs = vendorId => async dispatch => {
  dispatch(call.startPreloader());
  dispatch(fetchExistedModels(vendorId)) // <--- fetch modern devices (Battery, Cyber, OTA, excluding DHR and CBRS)
    .then(() => dispatch(model.checkPTCRBModels(vendorId))) // <--- fetch PTCRB legacy devices
    .then(() => dispatch(fetchDhrLabs()))
    .then(() => dispatch(call.stopPreloader()))
    .catch(() => dispatch(call.stopPreloader()));
};

export const submitRequestData = request => async dispatch => {
  try {
    // if request uses existed model - we should use another endpoint and args
    const reqParams = dhrArgs.defineSubmissionParams(request);
    const response = await restClient.post(
      reqParams.url,
      JSON.stringify(reqParams.body)
    );

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitRequestDHR(request) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(submitRequestData(request))
      .then(res => {
        const { id, devicemodelid, requests } = res.data.data;

        let newId;
        let deviceModelId;

        // submitted legacy model
        if (res.config.url === "requests") {
          newId = id;
          deviceModelId = devicemodelid;
        } else {
          // otherwise this is a brand new model
          newId = requests[0].id;
          deviceModelId = id;
        }

        request = { ...request, newId, deviceModelId };

        // go to Sign Agreement or skip it
        return request.agreementAccepted ? dispatch(sign(deviceModelId)) : res;
      })
      .then(() => {
        const { newId, deviceModelId, agreementAccepted } = request;

        // Update request redux store with new id and agreement flag
        dispatch({
          type: actions.SUBMIT_INITIAL_REQUEST,
          payload: {
            newId,
            deviceModelId,
            agreementAccepted
          }
        });

        dispatch(
          agreementAccepted ? wizard.submitWizard() : wizard.saveWizard()
        );
        dispatch(call.stopPreloader());
      })
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const fetchDhrEcoModels = vendorId => async dispatch => {
  try {
    const url = `/devicemodels?page=0&select=id,modelnumber&where=dhrverified=1 AND vendorid=${vendorId}&orderBy=modelnumber`;
    const res = await restClient.get(url);

    dispatch({
      type: actions.FETCH_DHR_ECO_MODELS,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getEcoModelsDHR(vendorId) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(fetchDhrEcoModels(vendorId))
      .then(() => dispatch(call.stopPreloader()))
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const submitEcoRequestData = request => async dispatch => {
  try {
    const args = dhrArgs.prepareEcoArgsDHR(request);
    const response = await restClient.post("requests", JSON.stringify(args));

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantSubmitRequest));

    return Promise.reject(e);
  }
};

export function submitEcoRequestDHR(request) {
  return dispatch => {
    dispatch(call.startPreloader());
    dispatch(model.fetchModelVersions(request.existedModelId))
      .then(res => {
        const parentId = res.payload.data.data[0].id;
        const reqData = { ...request, parentId };

        return dispatch(submitEcoRequestData(reqData));
      })
      .then(res => {
        const { id, devicemodelid } = res.data.data;

        dispatch({
          type: actions.SUBMIT_INITIAL_REQUEST,
          payload: {
            newId: id,
            deviceModelId: devicemodelid
          }
        });

        return res;
      })
      .then(() => {
        dispatch(wizard.submitWizard());
        dispatch(call.stopPreloader());
      })
      .catch(() => dispatch(call.stopPreloader()));
  };
}

export const getRequestDetailsDHR = id => async dispatch => {
  try {
    const url = `/requests/${id}/details`;
    const response = await restClient.get(url);

    dispatch({ type: actions.FETCH_REQUEST_DHR, payload: response.data.data });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorFetchRequestAction));

    return Promise.reject(e);
  }
};

export function getDetailsDHR(requestId) {
  return dispatch => {
    const quit = () => dispatch(call.stopPreloader());

    dispatch(call.startPreloader());

    return dispatch(getRequestDetailsDHR(requestId))
      .then(() => dispatch(tests.fetchRequestTests(requestId)))
      .then(res => {
        const resData = res.data;
        if (!resData) return Promise.resolve(null);

        const testId = resData.data[0].id;
        return dispatch(tests.fetchTestReports({ id: testId }));
      })
      .then(quit)
      .catch(quit);
  };
}

export function downloadReportDHR(document, request) {
  const { testId, requesttestid, slot } = document;
  const test = testId || requesttestid; // TODO: get rid of this

  // file name pattern: DHR<000000>_REPORT_<Slot>.<extension>
  // e.g. DHR000345_REPORT_3.pdf
  const fileName = `${request.tn}_REPORT_${slot}.${document.document.extension}`;
  const url = `/dhr/requesttests/${test}/reports/${slot}?download=1`;

  return fileDownload(fileName, url, "general");
}

export function downloadAllReportsDHR(request) {
  // file name: DHR<000000>_REPORTS.zip
  // e.g. DHR000345_REPORTS.zip
  const fileName = `${request.tn}_REPORTS.zip`;
  const url = `/dhr/requests/${request.id}/reports?download=true`;

  return fileDownload(fileName, url, "general");
}
