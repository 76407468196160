import React from "react";
import { connect } from "react-redux";
import FormFields from "containers/request/Payments/PaymentFormFields";
import PaymentFormIvcDate from "containers/request/Payments/PaymentFormIvcDate";

const PaymentFormStaff = ({ user, request }) => {
  if (!user.isStaff || !user.canEditRequest) return null;

  return request.isPaid ? <FormFields /> : <PaymentFormIvcDate />;
};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps)(PaymentFormStaff);
