// ES6 Helpers
import _ from "lodash";

// React/Redux imports
import React, { useState } from "react";
import { connect } from "react-redux";

// Redux action imports
import {
  delistDevice,
  relistDevice,
  unwithdrawDevice,
  withdrawDevice
} from "actions/devices";
import {
  delistRequest,
  relistRequest,
  unwithdrawRequest,
  withdrawRequest
} from "actions/battery/batteryRequests";
import { unwithdrawSite, withdrawSite } from "actions/sitesSar";

// Component imports
import ModalConfirm from "components/ModalConfirm";
import { handleDelistOrRelist } from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawHelper";

const WithdrawDeviceRecognitionModal = ({
  deviceModelId = null,
  isCurrentlyWithdrawn = false,
  isRequest = false,
  onClose = () => {},
  onError = () => {},
  onSuccess = () => {},
  requestId = null,
  requestIdsOfChildren = [],
  selectedCertType = null,
  shouldDelistOrRelistOnSuccess = true,
  showModal = true,
  requestData = null
}) => {
  const [componentState, setComponentState] = useState({
    errorMessage: null,
    isCurrentlyWithdrawn: isCurrentlyWithdrawn,
    isFormValid: true,
    isLoading: false,
    withdrawType: getWithdrawType(requestData, isCurrentlyWithdrawn)
  });

  function getWithdrawType(requestData, isWithdrawn) {
    if (!requestData) return null;

    // if SAR
    if (requestData.manufsiteid > 0)
      return isWithdrawn ? "unwithdrawSAR" : "withdrawReasonSAR";

    return isWithdrawn ? "unwithdraw" : "withdrawReason";
  }

  function withdrawTypeIsSAR() {
    if (!_.isNull(selectedCertType))
      return selectedCertType.toLowerCase() === "sar";
    else
      return !_.isUndefined(requestData.manufsiteid);
  }

  function formIsValid(withdrawReason) {
    return withdrawReason.length >= 5;
  }

  const handleError = error => {
    let errorMessage = "";

    if (error.response && error.response.data && error.response.data.errors) {
      const errors = error.response.data.errors;
      errorMessage = errors.map(error => error.MESSAGE).join(", ");
    } else if (error.message) errorMessage = error.message;

    setComponentState(state => {
      return {
        ...state,
        isLoading: false,
        errorMessage: errorMessage
      };
    });
    onError(error);
  };

  const onHandle = () => {
    const action = componentState.withdrawType;

    const { certtypeid: certTypeId, id } = requestData;

    if (action === "withdrawReasonSAR" || action === "withdrawReason") {
      // This feels like a hack and could be easy to break, but will leave this
      // until ModalConfirm can be modified to give withdraw reason.
      const reason = document.getElementById("reasonRef").value;

      const isValid = formIsValid(reason);

      setComponentState(state => {
        return {
          ...state,
          errorMessage: null,
          isFormValid: isValid,
          isLoading: isValid
        };
      });

      if (isValid) {
        let withdrawFunc;
        if (!isRequest && withdrawTypeIsSAR()) {
          withdrawFunc = withdrawSite({
            certTypeId,
            manufSiteId: id,
            withdrawReason: reason
          });
        } else if (!isRequest) {
          withdrawFunc = withdrawDevice({
            certTypeId,
            deviceId: id,
            withdrawReason: reason
          });
        } else {
          withdrawFunc = withdrawRequest({
            id,
            reason,
            prefix: selectedCertType
          });
        }

        withdrawFunc
          .then(() => {
            handleDelistingOrRelisting({ shouldRelist: false });
          })
          .catch(error => handleError(error));
      }
    } else if (action === "unwithdraw" || action === "unwithdrawSAR") {
      setComponentState(state => {
        return {
          ...state,
          errorMessage: null,
          isLoading: true
        };
      });

      let unwithdrawFunc;
      if(!isRequest && withdrawTypeIsSAR()) {
        unwithdrawFunc = unwithdrawSite({
          certTypeId,
          manufSiteId: id
        });
      } else if (!isRequest) {
        unwithdrawFunc = unwithdrawDevice({
          certTypeId,
          deviceId: id
        });
      } else {
        unwithdrawFunc = unwithdrawRequest({
          id,
          prefix: selectedCertType,
          setApproved: true
          // This is fine for now, unless this work needs to be reused later on
          // This logic should be passed in.
        });
      }

      unwithdrawFunc
        .then(() => {
          handleDelistingOrRelisting({ shouldRelist: true });
        })
        .catch(error => handleError(error));
    }
  };

  const handleDelistingOrRelisting = ({ shouldRelist = false }) => {
    if (shouldDelistOrRelistOnSuccess) {
      const {
        certtypeid: certTypeId,
        requesttypeid: requestTypeId
      } = requestData;

      handleDelistOrRelist({
        certTypeId: certTypeId,
        deviceModelId: deviceModelId,
        requestId: requestId,
        requestIdsOfChildren: requestIdsOfChildren,
        requestTypeId: requestTypeId,
        selectedCertType: selectedCertType,
        shouldRelist: shouldRelist,
        successCallback: onSuccess,
        errorCallback: handleError,
        delistDevice: delistDevice,
        delistRequest: delistRequest,
        relistDevice: relistDevice,
        relistRequest: relistRequest
      });
    } else {
      onSuccess();
    }
  };

  const onValidate = value => {
    setComponentState(state => {
      return {
        ...state,
        isFormValid: formIsValid(value)
      };
    });
  };

  let formattedRequestData;
  if (withdrawTypeIsSAR()) {
    const {
      name: siteName,
      vendorproductname: vendorProductName
    } = requestData;

    formattedRequestData = {
      ...requestData,
      manufsite: {
        name: siteName || vendorProductName,
      }
    };
  } else {
    formattedRequestData = requestData;
  }

  return (
    <ModalConfirm
      errorMessage={componentState.errorMessage}
      isLoading={componentState.isLoading}
      isFormValid={componentState.isFormValid}
      onHandle={onHandle}
      onHide={onClose}
      onValidate={onValidate}
      requestData={formattedRequestData}
      show={showModal}
      type={componentState.withdrawType}
    />
  );
};

export default connect(null, {
  delistDevice,
  delistRequest,
  relistDevice,
  relistRequest,
  withdrawDevice,
  withdrawRequest,
  withdrawSite,
  unwithdrawDevice,
  unwithdrawRequest,
  unwithdrawSite
})(WithdrawDeviceRecognitionModal);
