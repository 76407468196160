export default [
  {
    id: "users",
    title: "Users",
    description: "Manage user accounts and database access.",
    actions: [
      {
        icon: "users",
        title: "User Admin",
        route: "/admin/cp/users"
      },
      {
        icon: "user-plus",
        title: "New User Requests",
        route: "/admin/cp/users/newuserrequests"
      },
      {
        icon: "search",
        title: "User Search",
        route: "/admin/cp/users/search"
      },
      {
        icon: "user-slash",
        title: "Deleted Users",
        route: "/admin/cp/users/deleted"
      },
      {
        icon: "flask",
        title: "Assign Users to Labs",
        route: "/admin/cp/users2labs"
      },
      {
        icon: "user-tag",
        title: "View 'Log In As' Users of Labs",
        route: "/admin/cp/users-of-labs"
      }
    ]
  },
  {
    id: "reports",
    title: "Reports",
    description: "Generate and download reports with selected data.",
    actions: [
      {
        icon: "dollar-sign",
        title: "Budget Report",
        route: "/admin/cp/reports/budget"
      },
      {
        icon: "hourglass-half",
        title: "Certified/Pending Report",
        route: "/admin/cp/reports/certifiedpending"
      },
      {
        icon: "file-invoice-dollar",
        title: "Invoices Report",
        route: "/admin/cp/reports/invoice"
      }
    ]
  },
  {
    id: "org",
    title: "Organization Administration",
    description:
      "Manage vendors, labs and operators on the organization level.",
    actions: [
      {
        icon: "cogs",
        title: "Vendor",
        route: "/admin/cp/organizations/vendors"
      },
      {
        icon: "flask",
        title: "Laboratory",
        route: "/admin/cp/organizations/labs"
      },
      {
        icon: "broadcast-tower",
        title: "Operator",
        route: "/admin/cp/organizations/operators"
      },
      {
        icon: "building",
        title: "Service Center",
        route: "/admin/cp/organizations/asc"
      }
    ]
  },
  {
    id: "payments",
    title: "Payments",
    description: "Manage invoices and payments.",
    actions: [
      {
        icon: "flask",
        title: "Lab Invoicing",
        route: "/admin/cp/payments/invoice/lab"
      },
      {
        icon: "building",
        title: "Service Center Invoicing",
        route: "/admin/cp/payments/invoice/asc"
      }
      // {
      //   icon: "receipt",
      //   title: "Enter Invoice",
      //   route: "/admin/cp/payments/invoice/add"
      // },
      // {
      //   icon: "dollar-sign",
      //   title: "Enter Payment",
      //   route: "/admin/cp/users/search"
      // }
    ]
  }
  // ,
  // {
  //   icon: "ordered-list",
  //   title: "Bands and Technologies",
  //   description:
  //     "Manage bands and technologies supported by different certification programms.",
  //   actions: [
  //     {
  //       icon: "tags",
  //       title: "Band Management",
  //       route: "/admin/cp/bands"
  //     }
  //   ]
  // }
];
