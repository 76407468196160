import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel } from "ctia-ui";
import { Modal, Button } from "react-bootstrap";
import { disableOrganization } from "actions/org";
import { deleteATLInvoice } from "actions/catl";
import { deleteASCInvoice } from "actions/asc";

import "containers/ControlPanel/Payments/payments.css";

const DisableOrgModal = ({ show, modalHandler, org, disableOrganization, deleteATLInvoice, deleteASCInvoice }) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="danger"
        bsSize="small"
        text={<IconLabel label="Disable" awesomeIcon="ban" />}
        onClick={() => {
          setLoading(true);
          disableOrganization(org, reason)
            .then(() => {
              if(org.isactiveatl) {
                deleteATLInvoice(org).then(() => {
                  setLoading(false);
                  modalHandler();
                })
              }
              else if(org.isactiveasc) {
                deleteASCInvoice(org).then(() => {
                  setLoading(false);
                  modalHandler();
                })
              } else {
                setLoading(false);
                modalHandler();
              }
            })
            .catch(e => {
              setLoading(false);
              console.error(e);
              modalHandler();
            });
        }}
        disabled={reason === ""}
        isLoading={loading}
        loadingText="Disabling..."
      />
    </span>
  );

  return (
    <Modal show={show} onHide={modalHandler}>
      <Modal.Header closeButton>
        <Modal.Title>Disable organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="ctia-form">
          <Form.Group controlId="vendorFormName">
            Please enter the reason for disabling "<b>{org.name}</b>":
            <Form.Control
              as="textarea"
              placeholder="Enter the reason for disabling"
              onChange={e => setReason(e.target.value)}
              style={{ height: 75, marginTop: 15 }}
              disabled={loading}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button
          onClick={modalHandler}
          className="agr-close-btn"
          disabled={loading}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DisableOrgModal.propTypes = {
  show: PropTypes.bool,
  modalHandler: PropTypes.func
};

export default connect(null, {
  disableOrganization,
  deleteATLInvoice,
  deleteASCInvoice
})(DisableOrgModal);
