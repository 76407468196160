import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import FormElement from "components/FormElement";
import RequestVoltage from "components/request/RequestVoltage";
import BatteryDeviceType from "components/request/BatteryDeviceType";
import BatteryPartsComponents from "components/request/BatteryPartsComponents";
import NonSarSites from "containers/request/battery/NonSarSites/NonSarSites";
import PackCells from "containers/request/battery/PackCells/PackCellsContainer";
import { testResultsExtended } from "dictionaries/TestResults";
import { getTestResultTitle } from "helpers/RequestTestsHelper";
import { toMomentDate } from "helpers/DateHelper";
import { makeEvent } from "libs/form";
import { changeTestField, changeTestDate } from "actions/tests";
import { handleInputChange } from "actions/request";

class LabResultFormHRR extends Component {
  componentDidMount() {
    const { testModule, changeTestField } = this.props;

    // replace planversion value with alias
    const planversion = testModule.planversion.trim();
    const syntEvent = makeEvent("planversion", planversion);
    changeTestField(testModule.id, syntEvent);
  }

  onResultChange = event => {
    this.props.changeTestField(this.props.testModule.id, event);
  };

  render() {
    const {
      show,
      request,
      testModule,
      changeTestDate,
      handleInputChange
    } = this.props;

    if (!show) return null;

    const {
      testStatus,
      revision,
      crslversion,
      isInitial,
      deviceTypes
    } = request;

    const {
      lab,
      actualenddate,
      actualstartdate,
      requesttestpassedstatusid
    } = testModule;

    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);
    const isPack = isInitial
      ? deviceTypes && deviceTypes[0].checked
      : request.isPack;

    const renderPackChildren = () => {
      if (!isPack) return null;

      return (
        <>
          <PackCells />
          <RequestVoltage
            fieldId="capacity"
            fieldLabel="Capacity (mAh)"
            shouldBeNumber={true}
            editable={request.isInitial}
          />
        </>
      );
    };

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="testStatus"
            label="Test Status"
            value={getTestResultTitle(testStatus)}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
          />
          <FormElement
            type="select"
            id="requesttestpassedstatusid"
            label="Test Result"
            value={requesttestpassedstatusid}
            onChange={this.onResultChange.bind(this)}
            options={testResultsExtended}
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Test Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              "actualstartdate"
            )}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Test End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, "actualenddate")}
            minDate={startDate}
          />
          <hr />
          <BatteryDeviceType editable={request.isInitial} />
          {renderPackChildren()}
          <hr />
          <FormElement
            id="revision"
            label="Revision Number"
            value={revision}
            type="textarea"
            onChange={handleInputChange}
          />
          <FormElement
            type="text"
            id="crslversion"
            label="CRSL Version"
            value={crslversion}
            onChange={handleInputChange}
            placeholder="Enter version number"
          />
          <BatteryPartsComponents />
          <hr />
          <NonSarSites />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  changeTestDate,
  changeTestField,
  handleInputChange
})(LabResultFormHRR);
