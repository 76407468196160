import _ from "lodash";
import * as actions from "actions/types";
import * as sites from "helpers/ManufSiteHelper";

const handleField = (action, state) => {
  const { field, value } = action.payload;
  const cloned = { ...state.current };

  if (field && state[field] !== value) {
    cloned[field] = value;
    return { ...state, current: cloned };
  }

  return state;
};

const mapAddress = (action, state) => {
  const details = action.payload;
  const current = sites.mapManufAddress(details);
  return { ...state, current };
};

const mapLinkedSites = (action, state) => {
  if (!state || !state.list) return state;

  const linkedSites = action.payload;

  // if already mapped - need just to return it
  const alreadyMapped = _.every(
    linkedSites,
    site => !_.has(site, "manufsiteid")
  );

  if (alreadyMapped) {
    return { ...state, list: linkedSites };
  }

  const list = state.list.map(manufSite => {
    const checked =
      _.findIndex(linkedSites, ["manufsiteid", manufSite.value]) > -1;
    return {
      ...manufSite,
      checked
    };
  });

  return { ...state, list };
};

const addNonSarSite = (action, state) => {
  const nonSarSites = state.nonSarSites || [];
  const { id, name, addressid } = action.payload;
  const siteToBeAdded = {
    value: id,
    title: name,
    label: name,
    addressId: addressid,
    checked: false
  };
  const sortedNewSites = _.sortBy([...nonSarSites, siteToBeAdded], s => {
    return s.title.toLowerCase();
  });

  return { ...state, nonSarSites: sortedNewSites };
};

export const mapLinkedNonSarSites = (action, state) => {
  const siteList = action.payload;
  const { nonSarSites } = state;

  if (!siteList || !siteList.length || !nonSarSites || !nonSarSites.length)
    return { ...state };

  const mappedSites = nonSarSites.map(manufSite => {
    // need to reset all by default
    // in case we re-select another model during ECO submissions
    manufSite.checked = false;

    // now check if we need to select it
    if (_.find(siteList, ["manufsiteid", manufSite.value])) {
      manufSite.checked = true;
    }

    return manufSite;
  });

  return { ...state, nonSarSites: mappedSites };
};

const updateNonSarSite = (action, state) => {
  const { id, name } = action;

  if (!id) return state;

  const currentSites = state.nonSarSites || [];
  const nonSarSites = currentSites.map(site => {
    return site.value === id ? { ...site, label: name, title: name } : site;
  });

  return { ...state, nonSarSites };
};

const deleteNonSarSite = (id, state) => {
  if (!id) return state;

  const nonSarSites = state.nonSarSites || [];

  // find site with passed id and delete it from list
  _.remove(nonSarSites, site => site.value === id);

  return { ...state, nonSarSites };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_MANUF_SITES:
      const list = sites.mapManufSites(action.payload.data);
      return { ...state, list };
    case actions.HANDLE_SITE_FIELD:
      return handleField(action, state);
    case actions.CLEAR_CURR_SITE:
      return { ...state, ...sites.sitesInitState };
    case actions.GET_ADDRESS_DETAILS:
      return mapAddress(action, state);
    case actions.CLEAR_COUNTRY_STATE:
      return { ...state, current: { ...state.current, manufSiteState: "" } };
    case actions.MAP_CRR_MANUF_SITES:
      return mapLinkedSites(action, state);
    case actions.ADD_NON_SAR_SITE:
      return addNonSarSite(action, state);
    case actions.FETCH_NON_SAR_SITES:
      const nonSarSites = sites.mapManufSites(action.payload);
      return { ...state, nonSarSites };
    case actions.MAP_NON_SAR_SITES:
      return mapLinkedNonSarSites(action, state);
    case actions.UPDATE_NON_SAR_SITE_NAME:
      return updateNonSarSite(action.payload, state);
    case actions.DELETE_NON_SAR_SITE:
      return deleteNonSarSite(action.payload, state);
    case actions.CLEAR_NON_SAR_SITES:
      return { ...state, nonSarSites: [] };
    default:
      return state;
  }
}
