import React, { useState } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import AddButton from "components/form/buttons/AddButton";
import DrawerSiteForm from "containers/request/battery/NonSarSites/DrawerSiteForm";
import { addNewSite } from "actions/sites";
import { Drawer } from "ctia-ui";

const AddNewSite = ({ user, addNewSite }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  return (
    <>
      <Drawer
        position="right"
        show={showDrawer}
        title="Add New Site"
        toggleDrawer={toggleDrawer}
        content={
          <DrawerSiteForm
            toggleDrawer={toggleDrawer}
            actionOnSubmit={addNewSite}
          />
        }
        width={350}
      />
      <FormElement
        type="actionButton"
        id="addSiteButton"
        buttonComponent={AddButton}
        buttonLabel="Add New Site"
        onClick={toggleDrawer}
      />
    </>
  );
};

function mapStateToProps({ user, sites }) {
  return { user, sites };
}

export default connect(mapStateToProps, {
  addNewSite
})(AddNewSite);
