import React from "react";
import { WithModal } from "ctia-ui";
import EditUserModal from "containers/ControlPanel/Users/EditUser/EditUserModal";

class EditUserLink extends WithModal {
  render() {
    const { firstname, lastname } = this.props.userData;
    const fullName = `${lastname}, ${firstname}`;

    return (
      <div className="glow-link cursor-pointer">
        <span onClick={this.handleModal}>{fullName}</span>
        <EditUserModal
          userData={this.props.userData}
          modalHandler={this.handleModal}
          show={this.state.showModal}
        />
      </div>
    );
  }
}

export default EditUserLink;
