import React, { Component } from "react";
import { connect } from "react-redux";
import ReqLink from "components/RequestsLink";
import { Label } from "react-bootstrap";
import { fetchUnpaidRequestsByProgram, clearRequests } from "actions/requests";
import { startPreloader, finishPreloader } from "actions/preloader";
import { getTransPrefix } from "helpers/RequestHelper";
import { getInvoiceNumber } from "helpers/BillingHelper";
import SortableTable from "containers/SortableTable";
import RequestListRow from "containers/RequestListRow";

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = { sortField: null, sortOrder: null };
  }

  componentDidMount() {
    const { startPreloader, finishPreloader, fetchUnpaidRequestsByProgram } =
      this.props;
    const { programPrefix } = this.props.app;
    startPreloader();

    fetchUnpaidRequestsByProgram(programPrefix).then(finishPreloader);
  }

  componentWillUnmount() {
    this.props.clearRequests();
  }

  renderRequestLink(request, isBattery, isBLC) {
    const { id, formatedtransactionnumber: tn } = request;
    const batteryPrefix = isBattery ? getTransPrefix(tn) : "";
    const linkTitle =
      isBattery || isBLC ? getInvoiceNumber({ ...request, tn }) : tn;

    return (
      <ReqLink
        linkTitle={linkTitle}
        requestId={id}
        subType={batteryPrefix}
        category="payments"
        action="add"
      />
    );
  }

  handleSort(item, sortField) {
    if (sortField && sortField === "formatedtransactionnumber") {
      return item.invoice && item.invoice.number
        ? String(item.invoice.number).toLowerCase()
        : String(item.formatedtransactionnumber).toLowerCase();
    }

    return String(item[sortField]).trim().toLowerCase();
  }

  render() {
    const { app, requests } = this.props;

    if (!requests || !requests.unpaid) return null;

    const { unpaid } = requests;

    const headerFields = [
      {
        title: "Invoice Number",
        fieldname: "formatedtransactionnumber",
        function: "reqlink"
      },
      {
        title: "Request Type",
        fieldname: "requesttypeid",
        function: "reqtype"
      },
      { title: "Vendor", fieldname: "vendorname" },
      { title: "Model Name/Number", fieldname: "vendorproductname" },
      { title: "Billing Company", fieldname: "name" }
    ];

    return (
      <div>
        <h1>
          Unpaid Certification Requests <Label>{unpaid.length}</Label>
        </h1>
        <SortableTable
          list={unpaid}
          header={headerFields}
          colNum={headerFields.length}
          handleSort={this.handleSort}
          rowRenderer={RequestListRow}
          renderLink={this.renderRequestLink}
          isBattery={app.isBatteryCert}
          isBLC={app.isBLCCert}
        />
      </div>
    );
  }
}

function mapStateToProps({ app, requests }) {
  return { app, requests };
}

export default connect(mapStateToProps, {
  fetchUnpaidRequestsByProgram,
  clearRequests,
  startPreloader,
  finishPreloader
})(Payments);
