import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { fetchCountryStates } from "actions/countries";
import StateStaticReadonly from "components/form/StateStaticReadonly";
import { selectedCountryShouldRequireStateOrProvince } from "helpers/Countries";

const StateAndProvinceSelector = ({
  id="",
  value,
  selectedCountryCode = "",
  onChange,
  countries,
  fetchCountryStates
}) => {
  const [componentParams, setParams] = useState({
    states: []
  });

  const requiredState = selectedCountryShouldRequireStateOrProvince(selectedCountryCode);

  useEffect(() => {
    if (!_.isEmpty(selectedCountryCode) && requiredState) {
      fetchCountryStates(selectedCountryCode);
    }
    setParams({ states: [] });
  }, [fetchCountryStates, selectedCountryCode, requiredState]);

  useEffect(() => {
    let stateValues = [];
    if (countries !== null && countries.states !== null) {
      stateValues = countries.states;
    }
    setParams({ states: stateValues });
  }, [countries]);

  const renderOptions = () => {
    if (requiredState && !_.isEmpty(componentParams.states)) {
      const formOptions = componentParams.states.map(
        (currentOption, currentIndex) => {
          const optionLabel = currentOption.name;
          const optionValue = !_.isEmpty(currentOption.short)
            ? currentOption.short
            : currentOption.name;

          return (
            <option value={optionValue} key={currentIndex}>
              {optionLabel}
            </option>
          );
        }
      );

      return formOptions;
    }

    return null;
  };

  const selectorIsDisabled = () => {
    return !_.isEmpty(selectedCountryCode) && !requiredState;
  };

  return requiredState ? (
    <FormControl
      id={id}
      componentClass="select"
      value={value}
      onChange={onChange}
      disabled={selectorIsDisabled()}
    >
      <option value="" disabled={selectorIsDisabled()}>
        --- Select One ---
      </option>
      {renderOptions()}
    </FormControl>
  ) : (
    <StateStaticReadonly />
  );
};

function mapStateToProps({ countries }) {
  return { countries };
}

export default connect(mapStateToProps, { fetchCountryStates })(
  StateAndProvinceSelector
);
