import { getSelectedManufSitesIds } from "helpers/ManufSiteHelper";
import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs, prepareVoltageCapacityArgs } from "helpers/ArgsHelper";

/*** Autocomplete-based solution ***/

const getCommonRequestFields = request => {
  return {
    billingid: Number(request.billingId),
    pocid: Number(request.pocId),
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    primarylabid: request.cdmaLab,
    revision: request.revision,
    requestmanufsites: getSelectedManufSitesIds(request.manufSites),
    ieeetypeid: request.ieee
  };
};

// Prepare args in case new model was entered
// (not selected from a list of existed models)
export const prepareNewModelArgsCRR = request => {
  const commonFields = getCommonRequestFields(request);
  const voltageCapacity = prepareVoltageCapacityArgs(request);
  return makeSimpleArgs({
    requests: [{ ...commonFields }],
    modelnumber: request.existedModel,
    devicemodelbatterycomponent: {
      ...voltageCapacity
    }
  });
};

// Prepare args in case model selected from existing models
export const prepareExistedModelArgsCRR = request => {
  const commonFields = getCommonRequestFields(request);

  return makeSimpleArgs({
    devicemodelid: request.existedModelId,
    ...commonFields
  });
};

export const defineInitialArgsCRR = request => {
  const body = request.existedModelId
    ? prepareExistedModelArgsCRR(request)
    : prepareNewModelArgsCRR(request);

  const url = request.existedModelId ? "crr/requests" : "crr/devicemodels";

  return { body, url };
};

export const prepareEcoArgsCRR = request => {
  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    revision: request.revision,
    descriptionofchange: request.descriptionOfChange,
    requestmanufsites: getSelectedManufSitesIds(request.linkedManufsites),
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee
  });
};

//========================================\\
/*** End of Autocomplete-based solution ***/
//=======================================//

export const getRequestFieldsForTestingCRR = ({
  id,
  revision,
  crslversion,
  linkedManufsites
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion,
    requestmanufsites: getSelectedManufSitesIds(linkedManufsites)
  });
};

export const getTestFieldsCRR = testModule => {
  const {
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    args: {
      ...dates,
      requesttestpassedstatusid
    }
  };
};

export const prepareModelArgsCRR = request => {
  const voltageCapacity = prepareVoltageCapacityArgs(request);

  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName,
    devicemodelbatterycomponent: {
      ...voltageCapacity
    }
  });
};

export const getDeviceFieldsForTestingCRR = request => {
  const voltageCapacity = prepareVoltageCapacityArgs(request);

  return makeSimpleArgs({
    modelId: request.model.id,
    devicemodelbatterycomponent: {
      ...voltageCapacity
    }
  });
};

export const prepareRequestArgs = (request, sites) => {
  const { id, revision, descriptionOfChange, crslversion } = request;
  const requestmanufsites = getSelectedManufSitesIds(sites);
  return {
    id,
    revision,
    crslversion,
    descriptionOfChange,
    requestmanufsites,
    urlPrefix: "crr"
  };
};
