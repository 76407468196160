import React from "react";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import { Bubble } from "ctia-ui";

const PocAddBubble = ({ stateHandler, user, request }) => {
  if (!stateHandler || !user) return null;

  const renderMessage = () => (
    <span>
      Please enter the <strong>Point of Contact (PoC)</strong> for this request.
    </span>
  );

  const renderButton = () => {
    if (request.pocs.length > 0) {
      return (
        <span>
          or{" "}
          <Button bsSize="small" bsStyle="primary" onClick={stateHandler}>
            Select from PoC list
          </Button>
        </span>
      );
    }

    return null;
  };

  if (user.canEditRequest) {
    return (
      <Bubble show={true}>
        {renderMessage()} {renderButton()}
      </Bubble>
    );
  }

  return <Bubble show={true}>{renderMessage()}</Bubble>;
};

function mapStateToProps({ user, request }) {
  return { user, request };
}

export default connect(mapStateToProps)(PocAddBubble);
