import { restClientAdmin } from "libs/restClient";
import * as actions from "actions/types";
import * as call from "helpers/ActionHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { formatDateRangeForFileExport, toAPIString } from "helpers/DateHelper";
import { fileDownload } from "actions/uploads";

const excelMappings = {
  vendorname: "Vendor",
  vendorproductname: "Model Name / Number",
  totalamount: "Invoice Amount",
  formatedtransactionnumber: "Request / Invoice Number",
  paidat: "Date Paid",
  paymentmethod: "Payment Method",
  createdat: "Date Invoiced",
  completedat: "Date Certified",
  name: "Request Type"
};

const url = (
  dateFrom,
  dateTo,
  order = "id ASC",
  perPage = 100,
  page = 1,
  download = false,
  mappings = false
) =>
  `/invoices?include=request(requesttype)&perPage=${perPage}&page=${page}&select=formatedtransactionnumber, vendorname, vendorproductname, createdat, totalamount, paidat, paymentmethod, completedat, name&where=totalamount > 0 AND (requeststatusid <> 8 OR paidat IS NOT NULL) AND (createdat >= '${dateFrom}' AND createdat <= '${dateTo}')&orderBy=${order}&returnAs=query&asExcel=${download}`;

export function downloadReport(dateFrom, dateTo, order = "id ASC") {
  const dateFromM = toAPIString(dateFrom);
  const dateToM = toAPIString(dateTo);
  const urlM = url(dateFromM, dateToM, order, 0, 0, true, true);

  const dates = formatDateRangeForFileExport(dateFrom, dateTo);
  const fileName = `Invoice_Report_${dates}.zip`;

  return fileDownload(fileName, urlM, "admin", { excelMappings });
}

export const fetchDetails =
  (dateFrom, dateTo, order = "id ASC", perPage = 100, page = 1) =>
  async dispatch => {
    try {
      const dateFromM = toAPIString(dateFrom);
      const dateToM = toAPIString(dateTo);
      const urlM = url(dateFromM, dateToM, order, perPage, page);

      const response = await restClientAdmin.get(urlM);
      const payload = response.data;

      dispatch({
        type: actions.FETCH_INVOICE_REPORT_DETAILS,
        payload
      });

      return payload;
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));

      return Promise.reject(e);
    }
  };
