import React from "react";
import { connect } from "react-redux";
import { Statistic, IconLabel } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import SaveInvoices from "containers/ControlPanel/Payments/SaveInvoices";
import { mapMetaATL } from "helpers/AtlHelper";

const LabInvoicingStat = ({ card, catl }) => {
  if (!catl || !catl.meta) return null;
  const atlMeta = mapMetaATL(catl.meta);

  return (
    <Row>
      <Col>
        {atlMeta.map(({ icon, label, value, total }) => {
          const suffix = total ? `/ ${total}` : null;
          return (
            <Col xs={2} key={icon}>
              <Statistic
                title={<IconLabel awesomeIcon={icon} label={label} />}
                value={value}
                suffix={suffix}
              />
            </Col>
          );
        })}
        <Col xs={2}>
          <SaveInvoices />
        </Col>
      </Col>
    </Row>
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps)(LabInvoicingStat);
