import React from 'react';
import { HelpBlock } from 'react-bootstrap';

const NoData = ({ show }) => {
  if (!show) return null;
  return (
    <div className="quick-search__help-block">
      <HelpBlock bsClass="red">
        No results found. Please enter less search terms.
      </HelpBlock>
    </div>
  );
};

export default NoData;
