import { restClientGeneral } from "libs/restClient";

export const fetchInterfaces = async () => {
  try {
    const url = "/interfaces?&page=0";
    const response = await restClientGeneral.get(url);
    const { data } = response.data;

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchLabInterfaces = async () => {
  try {
    const url = "/labinterfaces?";
    const response = await restClientGeneral.get(url);
    const { data } = response.data;

    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};