import React from "react";
import { connect } from "react-redux";
import AgreementControls from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementControls";
import { getAgreementList } from "dictionaries/BatteryAgreementTypes";

const BatteryAgreementContainer = ({ request, wizard }) => {
  const agrts = wizard
    ? wizard.batteryCert.certInfo.requiredAgreements
    : getAgreementList(request);
  const certType = wizard ? wizard.batteryCert.certInfo.urlPrefix : "";

  return (
    <div>
      {agrts.map((agreement, i) => {
        const AgrComponent = agreement;
        return <AgrComponent key={i} certType={certType} />;
      })}
      <AgreementControls />
    </div>
  );
};

function mapStateToProps({ request, wizard }) {
  return { request, wizard };
}

export default connect(mapStateToProps)(BatteryAgreementContainer);
