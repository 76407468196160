import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Bubble, LoaderButton } from 'ctia-ui';
import { hasUploadedManual } from 'helpers/RequestHelper';
import { notifyMessages } from 'helpers/NotifyHelper';
import { isPendingDoc, isApprovedDoc } from 'helpers/DocumentStatus';
import { approveUserManual, fetchRequest } from 'actions/requestCyber';
import { showNotice } from 'actions/notifier';

class StaffDecision extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccepted: false,
      isRejected: false
    };
  }

  showFinalNotify = accept => {
    const message =
      accept === true
        ? notifyMessages.documentApprovedSuccessfully
        : notifyMessages.documentRejectedSuccessfully;

    this.props.showNotice(message);
  };

  dropLoaders = () => {
    this.setState({
      isAccepted: false,
      isRejected: false
    });
  };

  clickHandler = accept => {
    this.setState({
      isAccepted: accept === true,
      isRejected: accept === false
    });

    const { approveUserManual, request, fetchRequest } = this.props;

    approveUserManual(request.model.id, accept)
      .then(() => {
        fetchRequest(request.id);
      })
      .then(() => {
        // show notify
        this.showFinalNotify(accept);

        // remove loading status
        this.dropLoaders();
      });
  };

  renderCheck() {
    const { request, user, isActiveTab } = this.props;

    // if current Documents tab is not active (not Users Manual tab)
    if (!isActiveTab) return null;

    // show only for staff users
    if (!user.isStaff) return null;

    // check if edit mode enabled for the request
    if (!user.canEditRequest) return null;

    // check if request has uploaded users manual
    if (!hasUploadedManual(request)) return null;

    return true;
  }

  renderApproveBtn = isDisabled => {
    return (
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text="Approve"
        isLoading={this.state.isAccepted}
        onClick={this.clickHandler.bind(this, true)}
        disabled={isDisabled}
        className="icon-left-offset icon-right-offset"
      />
    );
  };

  renderRejectBtn = isDisabled => {
    return (
      <LoaderButton
        bsStyle="danger"
        bsSize="small"
        text="Reject"
        isLoading={this.state.isRejected}
        onClick={this.clickHandler.bind(this, false)}
        disabled={isDisabled}
        className="icon-left-offset icon-right-offset"
      />
    );
  };

  render() {
    if (this.renderCheck()) {
      const btnDisabled = this.state.isAccepted || this.state.isRejected;
      const docStatus = this.props.request.usersManual.document
        .documentstatusid;
      const isPending = isPendingDoc(docStatus);
      const isApproved = isApprovedDoc(docStatus);

      if (isPending) {
        return (
          <Bubble type="warning">
            Please review uploaded <strong>Product Description</strong> and
            click the {this.renderApproveBtn(btnDisabled)} or{' '}
            {this.renderRejectBtn(btnDisabled)} button.
          </Bubble>
        );
      }

      if (isApproved) {
        return (
          <Bubble type="warning">
            <strong>Product Description</strong> for this request has been
            successfully <b>Approved</b>. Click{' '}
            {this.renderRejectBtn(btnDisabled)} if you want to <b>Reject</b> it.
          </Bubble>
        );
      }

      // if rejected
      return (
        <Bubble type="warning">
          <strong>Product Description</strong> for this request has been
          successfully <b>Rejected</b>. Click{' '}
          {this.renderApproveBtn(btnDisabled)} if you want to <b>Approve</b> it.
        </Bubble>
      );
    }

    return null;
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(
  mapStateToProps,
  {
    approveUserManual,
    showNotice,
    fetchRequest
  }
)(StaffDecision);
