import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/sitesSar";
import { getZipCodeMask } from "helpers/MaskHelper";
import { makeZipValidation } from "helpers/ValidatorHelper";
import { formatNumberString } from "helpers/StringHelper";

const SiteAddressZip = ({ sites, user, handleInputChange }) => {
  const { manufSiteZip, manufSiteCountry } = sites.current;
  const isEditable = user.canEditManufsite;
  const maskObj = getZipCodeMask(manufSiteZip, manufSiteCountry);
  const zipValidation = makeZipValidation(manufSiteZip, manufSiteCountry);

  const onZipChange = event => {
    // allow numbers only for US
    if (manufSiteCountry === "US") {
      event.target.value = formatNumberString(event.target.value);
    }
    handleInputChange(event);
  };

  return (
    <FormElement
      type="maskedInput"
      id="manufSiteZip"
      label="Postal Code"
      value={manufSiteZip}
      onChange={onZipChange}
      disabled={!isEditable}
      validationState={zipValidation.state}
      validationMessage={zipValidation.message}
      {...maskObj}
    />
  );
};

function mapStateToProps({ sites, user }) {
  return { sites, user };
}

export default connect(mapStateToProps, { handleInputChange })(SiteAddressZip);
