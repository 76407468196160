import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import {
  requestFields,
  getAddressData,
  addressFields,
  mapAddressFields,
} from "helpers/ManufSiteHelper";
import { setValue } from "actions/request";
import { clearCurrSite } from "actions/sitesSar";

class SiteAddressDetails extends React.Component {
  requestField = requestFields.siteId;

  render() {
    const { request, sites, addresses } = this.props;

    let addressData;

    if (request && request.manufSite && request.manufSite.id) {
      addressData = mapAddressFields(request.manufSite.address);
    } else {
      if (!addresses || !addresses.list) return null;

      const siteId = Number(request[this.requestField]);

      if (!siteId || siteId === 0) return null;

      addressData = getAddressData(siteId, sites.list, addresses.list);
    }

    if (!addressData) return null;

    const addressKeys = Object.keys(addressData);

    return (
      <div>
        {addressKeys.map((field) => {
          return (
            <FormElement
              id={field}
              key={field}
              value={addressData[field]}
              label={addressFields[field]}
            />
          );
        })}
      </div>
    );
  }
}

function mapStateToProps({ addresses, request, sites }) {
  return { addresses, request, sites };
}

export default connect(mapStateToProps, {
  setValue,
  clearCurrSite,
})(SiteAddressDetails);
