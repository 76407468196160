import _ from "lodash";
import React from "react";
import {
  FormControl,
  Popover,
  OverlayTrigger,
  Glyphicon
} from "react-bootstrap";

const OneLineText = ({
  inTable = true,
  style = {},
  title,
  titleId,
  value,
  content
}) => {
  if (!value || _.isEmpty(content))
    return (
      <FormControl.Static className="dark-teal bold">n/a</FormControl.Static>
    );

  const popover = () => {
    return (
      <Popover title={title} id={`popover-${titleId}`}>
        <div>
          {content.map((element, elementIndex) => {
            if (!element.show) return null;

            return (
              <p key={elementIndex}>
                <strong>{element.label}:</strong> {element.value}
              </p>
            );
          })}
        </div>
      </Popover>
    );
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={popover()}
      rootClose={true}
    >
      {inTable ? (
        <span style={style} className="cursor-pointer glow-link">
          {value} <Glyphicon glyph="info-sign" />
        </span>
      ) : (
        <span style={style} className="dark-teal bold">
          <span className=" cursor-pointer glow-link">
            {value} <Glyphicon glyph="info-sign" />
          </span>
        </span>
      )}
    </OverlayTrigger>
  );
};

export default OneLineText;
