import React from "react";
import { connect } from "react-redux";
import ControlsCRR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsCRR";
import ControlsHRR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsHRR";
import ControlsPRR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsPRR";
import ControlsEPR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsEPR";
import ControlsARR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsARR";
import ControlsACR from "containers/request/LicenseAgreement/BatteryAgreement/ControlsACR";
import ControlsBLC from "containers/request/LicenseAgreement/BatteryAgreement/ControlsBLC";
import ControlsBSC from "containers/request/LicenseAgreement/BatteryAgreement/ControlsBSC";

const BatteryAgreementControls = ({ request }) => {
  if (!request) return null;
  if (request.isCRR) return <ControlsCRR />;
  if (request.isHRR) return <ControlsHRR />;
  if (request.isPRR) return <ControlsPRR />;
  if (request.isEPRR) return <ControlsEPR />;
  if (request.isARR) return <ControlsARR />;
  if (request.isACR) return <ControlsACR />;
  if (request.isBLC) return <ControlsBLC />;
  if (request.isBSC) return <ControlsBSC />;
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(BatteryAgreementControls);
