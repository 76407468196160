import React from "react";
import { connect } from "react-redux";
import { DeleteButton } from "ctia-ui";
import AdapterBadge from "containers/request/battery/AdapterSupplier/AdapterTypeBadge";
import ModelBadge from "containers/request/battery/BatterySupplier/PendingRecognitionBadge";
import { removeAdapterSupplier } from "actions/suppliers";

const AdapterSupplierListRow = ({ supplier, removeAdapterSupplier }) => {
  if (!supplier) return null;

  const {
    linkedrequestvendorid: vendorId,
    linkedrequestrevision: revision,
    linkedrequestid: reqId,
    linkedrequestvendorproductname: modelName,
    hasPendingRecognition,
    type
  } = supplier;

  const unrecognized = Number(type) === 3;

  const adapterRevision =
    unrecognized || hasPendingRecognition ? "—" : revision;

  const idToBeRemoved = {
    requestId: reqId || null,
    vendorId: reqId ? null : vendorId,
    modelName: unrecognized ? modelName : null
  };

  return (
    <tr>
      <td>
        <AdapterBadge type={type} />
      </td>
      <td>{supplier.linkedrequestvendorname}</td>
      <td>
        <ModelBadge row={supplier} />
      </td>
      <td>{adapterRevision}</td>
      <td>
        <DeleteButton
          size="xs"
          label="Remove"
          action={() => removeAdapterSupplier(idToBeRemoved)}
        />
      </td>
    </tr>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  removeAdapterSupplier
})(AdapterSupplierListRow);
