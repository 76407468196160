import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import {
  getStatusTitle,
  getRequestSupplier,
  getRequestVoltage,
  isExisted
} from "helpers/RequestHelper";
import { getManufSiteName } from "helpers/ManufSiteHelper";
import { getRequestTypeWithIeeeTitle } from "helpers/AuditHelper";

class SiteAuditInfoBar extends Component {
  state = {
    status: "",
    vendor: "",
    revision: "",
    voltage: "",
    capacity: "",
    manufSite: "",
    reqType: ""
  };

  componentDidMount() {
    this.updateRequestInfo();
  }

  updateRequestInfo = () => {
    const { request, sites, user } = this.props;
    const status = isExisted(request) ? getStatusTitle(request.status) : null;
    const vendor = getRequestSupplier(request, user);
    const revision = user.isStaff ? request.revision : null;
    const voltage = user.isStaff ? getRequestVoltage(request.voltage) : null;
    const capacity = user.isStaff ? getRequestVoltage(request.capacity) : null;
    const manufSite = getManufSiteName(request, sites);
    const reqType = getRequestTypeWithIeeeTitle(request);

    this.setState({
      status,
      vendor,
      revision,
      voltage,
      capacity,
      manufSite,
      reqType
    });
  };

  componentDidUpdate(prevProps) {
    const { request } = this.props;

    if (request && request.status !== prevProps.request.status) {
      this.updateRequestInfo();
    }
  }

  getValues = () => {
    const {
      status,
      vendor,
      revision,
      voltage,
      capacity,
      manufSite,
      reqType
    } = this.state;
    const reqDataInfo = [
      {
        key: "Status",
        value: status
      },
      {
        key: "Request Type",
        value: reqType
      },
      {
        key: "Supplier",
        value: vendor
      },
      {
        key: "Revision Number",
        value: revision
      },
      {
        key: "Voltage (V)",
        value: voltage
      },
      {
        key: "Capacity (mAh)",
        value: capacity
      },
      {
        key: "Manufacturing Site",
        value: manufSite
      }
    ];

    // remove fields with no values:
    return _.filter(reqDataInfo, field => !_.isEmpty(field.value));
  };

  render() {
    return <InfoBar show={true} data={this.getValues()} />;
  }
}

function mapStateToProps({ request, sites, user }) {
  return { request, sites, user };
}

export default connect(mapStateToProps)(SiteAuditInfoBar);
