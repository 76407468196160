import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import RequestRevision from "components/request/RequestRevision";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import { initACR } from "actions/battery/acrRequest";
import { mapNonSarManufSites } from "actions/battery/batteryRequests";

const DeviceInfoLabACR = ({
  request,
  user,
  initACR,
  mapNonSarManufSites,
  mapBatteryDeviceType
}) => {
  useEffect(() => {
    const companyid = user.isStaff ? request.submitter.companyid : null;

    initACR(companyid).then(mapNonSarManufSites(request.linkedManufsites));
  }, [
    initACR,
    mapNonSarManufSites,
    request.linkedManufsites,
    request.submitter.companyid,
    user.isStaff
  ]);

  return (
    <div>
      <Instructions />
      <Form horizontal>
        {request.isEco && (
          <>
            <RequestParentLink certPrefix="arr" />
            <BatteryParentDetails />
            <hr />
          </>
        )}
        <RequestManufacturer />
        <RequestModelName />
        <RequestRevision fieldId="revision" editable={false} />
        <SubmissionDetails />
        <hr />
        <NonSarSitesReadonly />
      </Form>
    </div>
  );
};

function mapStateToProps({ request, user, sites }) {
  return { request, user, sites };
}

export default connect(mapStateToProps, {
  initACR,
  mapNonSarManufSites
})(DeviceInfoLabACR);
