import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form } from "react-bootstrap";
import RequestControls from "containers/request/RequestControls";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestDescChanges from "components/request/RequestDescChanges";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import BatteryPartsComponents from "components/request/BatteryPartsComponents";
import BatteryDeviceType from "components/request/BatteryDeviceType";
import RequestEcoModel from "containers/request/HRR/RequestEcoModelHRR";
import RequestRevision from "components/request/RequestRevision";
import PackECODetailsHRR from "containers/request/HRR/PackECODetailsHRR";
import NonSarSites from "containers/request/battery/NonSarSites/NonSarSites";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import { setValue, setBatteryRequestPermissions } from "actions/request";
import { initEcoHRR, updateECODeviceInfoHRR } from "actions/battery/hrrRequest";
import {
  mapNonSarManufSites,
  mapBatteryDeviceType
} from "actions/battery/batteryRequests";
import { submitWizardStep } from "actions/wizard";
import { hasStringValue } from "helpers/RequestHelper";
import { getRequestOwner } from "helpers/UsersHelper";

class RequestECOContainerHRR extends CyberComponent {
  modelField = this.props.request.id ? "modelName" : "existedModel";

  componentDidMount() {
    const { app, request, user } = this.props;
    const ownerId = getRequestOwner(request, user);

    // fetch only for the first time on submissions
    if (!this.isValidToSubmit() && !request.id) {
      this.props.initEcoHRR(request.ieee, ownerId);
    }

    // fetch for existed requests
    if (request.id) {
      this.props.initEcoHRR(request.ieee, ownerId).then(() => {
        this.props.mapNonSarManufSites(request.linkedManufsites);
        this.props.mapBatteryDeviceType(app.certTypes);
      });
    }

    // set permissions for existed request
    this.props.setBatteryRequestPermissions(request, user);
  }

  onSaveClick = () => {
    const { request, sites, updateECODeviceInfoHRR } = this.props;
    updateECODeviceInfoHRR(request, sites.nonSarSites);
  };

  validateRevision() {
    return hasStringValue(this.props.request, "revision");
  }

  validateDesc() {
    return hasStringValue(this.props.request, "descriptionOfChange");
  }

  validateManufSites() {
    const { sites } = this.props;
    return sites && sites.nonSarSites && _.some(sites.nonSarSites, "checked");
  }

  isValidToUpdate() {
    const { request, user } = this.props;

    // at least 1 cell is required if device is a Pack
    if (request.isPack && user.canEditLabFields) {
      if (!request.cellSuppliers.length) return false;
    }

    return this.isValidToSubmit();
  }

  isValidToSubmit() {
    const { user } = this.props;

    // don't need to validate if form is non-editable
    if (user.canEditDeviceInfo === false) return true;

    // check if all required fields are valid
    return (
      this.validateRevision() &&
      this.validateManufSites() &&
      this.validateDesc()
    );
  }

  render() {
    const { request, user } = this.props;
    const { canEditDeviceInfo, canEditLabFields } = user;
    const saveHandler = canEditDeviceInfo ? this.onSaveClick : null;
    const isValid =
      request.id > 0 ? this.isValidToUpdate() : this.isValidToSubmit();

    return (
      <div>
        <Instructions />
        <Form horizontal>
          <RequestParentLink certPrefix="hrr" />
          <BatteryParentDetails />
          <hr />
          <RequestManufacturer />
          <RequestEcoModel />
          <hr />
          <BatteryDeviceType editable={false} />
          <PackECODetailsHRR />
          {request[this.modelField] && (
            <>
              <RequestRevision
                fieldId="revision"
                editable={canEditDeviceInfo}
              />
              <BatteryPartsComponents editable={canEditLabFields} />
              <RequestDescChanges />
              <SubmissionDetails />
              <hr />
              {canEditDeviceInfo ? <NonSarSites /> : <NonSarSitesReadonly />}
            </>
          )}
          <RequestControls
            isValidToSubmit={this.isValidToSubmit()}
            isValid={isValid}
            onSave={saveHandler}
          />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ app, request, user, sites }) {
  return { app, request, user, sites };
}

export default connect(mapStateToProps, {
  initEcoHRR,
  submitWizardStep,
  updateECODeviceInfoHRR,
  setValue,
  setBatteryRequestPermissions,
  mapNonSarManufSites,
  mapBatteryDeviceType
})(RequestECOContainerHRR);
