import React from "react";
import { connect } from "react-redux";
import { DownloadButton } from "ctia-ui";
import { downloadOtaReport } from "actions/tests";
import { findTestModule } from "helpers/RequestTestsHelper";

const ButtonDownloadReport = ({
  report,
  request,
  tests,
  downloadOtaReport
}) => {
  if (!report) return null;

  const testModule = findTestModule(tests, report.requesttestid);

  if (!testModule) return null;

  return (
    <DownloadButton
      size="xs"
      action={() => downloadOtaReport(report, request, testModule)}
    />
  );
};

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, { downloadOtaReport })(
  ButtonDownloadReport
);
