

export const toggleOptions = [
  {
    id: 0,
    label: "All",
    disabled: false
  },
  {
    id: 1625,
    label: "IEEE 1625",
    disabled: false
  },
  {
    id: 1725,
    label: "IEEE 1725",
    disabled: false
  }
];