import React from "react";
import { IconLabel } from "ctia-ui";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Container } from "ctia-react-bootstrap-v4";
import reports from "containers/AccountingReports/reports";

const ControlPanelCards = ({ app, user, history, changeCertProgram }) => {
  const fluid = app.sm ? "fluid" : "";

  const rowSettings = {
    xl: 3,
    lg: 3,
    md: 3,
    sm: 12
  };

  return (
    <Container {...fluid}>
      <Row {...rowSettings} style={{ marginTop: 0 }}>
        <div className="home-description">
          <h1>CTIA Accounting Reports</h1>
          <p>Select a report from the list below.</p>
        </div>
      </Row>
      <Row {...rowSettings} style={{ marginTop: 25 }}>
        <ul className="ctia-list">
          {reports.map(report => {
            return (
              <li>
                <IconLabel
                  key={report.icon}
                  awesomeIcon={report.icon}
                  label={
                    <Link to={report.route} className="glow-link">
                      {report.title}
                    </Link>
                  }
                />
              </li>
            );
          })}
        </ul>
      </Row>
    </Container>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps)(ControlPanelCards);
