import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { getZipCodeMask } from "helpers/MaskHelper";
import { makeZipValidation } from "helpers/ValidatorHelper";
import { formatNumberString } from "helpers/StringHelper";

const BillingAddressZip = ({ request, user, handleInputChange }) => {
  const { isPaid, billingAddressZip, billingAddressCountry } = request;
  const isEditable = user.canEditBilling || (user.canEditRequest && !isPaid);
  const maskObj = getZipCodeMask(billingAddressZip, billingAddressCountry);
  const zipValidation = makeZipValidation(
    billingAddressZip,
    billingAddressCountry
  );

  const onZipChange = event => {
    // allow numbers only for US
    if (billingAddressCountry === "US") {
      event.target.value = formatNumberString(event.target.value);
    }
    handleInputChange(event);
  };

  return (
    <FormElement
      type="maskedInput"
      id="billingAddressZip"
      label="Postal Code"
      value={billingAddressZip}
      onChange={onZipChange}
      disabled={!isEditable}
      validationState={zipValidation.state}
      validationMessage={zipValidation.message}
      {...maskObj}
    />
  );
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, { handleInputChange })(
  BillingAddressZip
);
