import React from 'react';
import { Col, FormGroup } from 'react-bootstrap';
import { LoaderButton, IconLabel } from 'ctia-ui';

const BillingReturnLink = ({ show, title, linkHandler }) => {
  if (!show) return null;

  return (
    <FormGroup>
      <Col sm={3} />
      <Col sm={4}>
        <LoaderButton
          bsStyle="link"
          bsSize="large"
          text={<IconLabel label={title} awesomeIcon="undo-alt" />}
          disabled={false}
          onClick={linkHandler}
          show={true}
        />
      </Col>
      <Col sm={5} />
    </FormGroup>
  );
};

export default BillingReturnLink;
