import React from "react";
import { WithModal } from "ctia-ui";
import ConfirmDeleteModal from "containers/users/UsersList/UserStatusActions/ConfirmDeleteModal";
import ActivateLink from "containers/users/UsersList/UserStatusActions/ActivateUserLink";
import DeleteLink from "containers/users/UsersList/UserStatusActions/DeleteUserLink";

class StatusAction extends WithModal {
  render() {
    const { user } = this.props;

    return (
      <>
        <div className="text-right link-group">
          <ActivateLink
            user={user}
            currStatus="inactive"
            show={user.active === -1}
          />
          <DeleteLink user={user} toggleModal={this.handleModal} />
        </div>
        <ConfirmDeleteModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
          user={user}
        />
      </>
    );
  }
}

export default StatusAction;
