import moment from "moment";
import _ from "lodash";

import React, { useState } from "react";
import { Col, Form, Row } from "ctia-react-bootstrap-v4";
import { Divider } from "ctia-ui";
import { selectedCountryShouldRequireStateOrProvince } from "helpers/Countries";
import { formatDate } from "helpers/DateHelper";

import DatePicker from "components/form/Date";
import FormFieldLabel from "components/form/FormFieldLabel";

import CountrySelector from "components/CountrySelector/CountrySelector";
import StateAndProvinceSelector from "components/StateAndProvinceSelector";
import CertificationsSelectors from "components/Companies/AddCompanyModal/components/LabCertificationsListSelector";
import TestingCapabilitySelectors from "components/Companies/AddCompanyModal/components/LabAuthorizedTestingCapabilitySelectors";
import LabIEEETypeSelectors from "components/Companies/AddCompanyModal/components/LabIEEETypeSelectors";

const LabForm = ({ values = {} }) => {
  const [componentState, setComponentState] = useState({
    authDate: "",
    country: "",
    requiresIEEE: false,
    state: "",
    status: "",
    showAuthDate: false
  });

  const handleFieldChange = event => {
    const { id, value } = event.target;
    setComponentState(cState => {
      switch (id) {
        case "country":
          return { ...cState, country: value, state: "" };
        case "state":
          return { ...cState, state: value };
        case "status":
          const showAuthDate = value === "Active";
          return {
            ...cState,
            authDate: showAuthDate ? moment() : "",
            status: value,
            showAuthDate
          };
        default:
          return cState;
      }
    });
  };

  const authDate = !_.isEmpty(componentState.authDate)
    ? formatDate(componentState.authDate)
    : "";

  return (
    <Row>
      <Col md={6} xs={12}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormFieldLabel label="Name" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            placeholder="Enter organization name"
            defaultValue={values.company}
          />
          <Form.Control.Feedback type="invalid">
            Please choose an organization name.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>
            <FormFieldLabel label="Address 1" isRequired={true} />
          </Form.Label>
          <Form.Control required placeholder="Enter Address line 1" />
        </Form.Group>
        <Form.Group controlId="address2">
          <Form.Label>
            <FormFieldLabel label="Address 2" />
          </Form.Label>
          <Form.Control placeholder="Enter Address line 2" />
        </Form.Group>
        <Form.Group controlId="address3">
          <Form.Label>
            <FormFieldLabel label="Address 3" />
          </Form.Label>
          <Form.Control placeholder="Enter Address line 3" />
        </Form.Group>
        <Form.Group controlId="country">
          <Form.Label>
            <FormFieldLabel label="Country" isRequired={true} />
          </Form.Label>
          <CountrySelector
            id="country"
            onChange={handleFieldChange}
            value={componentState.country}
          />
        </Form.Group>
        <Form.Group controlId="city">
          <Form.Label>
            <FormFieldLabel label="City" isRequired={true} />
          </Form.Label>
          <Form.Control required placeholder="Enter City" />
        </Form.Group>
        <Form.Group controlId="state">
          <Form.Label>
            <FormFieldLabel
              label="State"
              isRequired={selectedCountryShouldRequireStateOrProvince(
                componentState.country
              )}
            />
          </Form.Label>
          <StateAndProvinceSelector
            id="state"
            onChange={handleFieldChange}
            selectedCountryCode={componentState.country}
            value={componentState.state}
          />
        </Form.Group>
        <Form.Group controlId="zipCode">
          <Form.Label>
            <FormFieldLabel label="Zip/Postal Code" />
          </Form.Label>
          <Form.Control placeholder="Enter Zip or Postal Code" />
        </Form.Group>
        <hr />
        <Form.Group controlId="status">
          <Form.Label>
            <FormFieldLabel label="ATL Status" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            as="select"
            onChange={handleFieldChange}
            value={componentState.status}
          >
            <option>--- Select One ---</option>
            <option>Active</option>
            <option>Expired</option>
            <option>N/A</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="authDate">
          {componentState.showAuthDate && (
            <>
              <Form.Label>
                <FormFieldLabel label="ATL Authorization Date" />
              </Form.Label>
              <DatePicker
                id="authDatePicker"
                editable={true}
                onChange={value =>
                  setComponentState(state => {
                    return { ...state, authDate: value };
                  })
                }
                value={componentState.authDate}
              />
            </>
          )}
          {/*Hide this on the form to store the date for form submission.*/}
          {/*Date picker is not recognized as a valid form control.*/}
          <input type="hidden" id="authDate" value={authDate} />
        </Form.Group>
      </Col>
      <Col md={6} xs={12}>
        <Form.Group controlId="testingCapabilities">
          <Form.Label>
            <FormFieldLabel label="Certification" isRequired={true} />
          </Form.Label>
          <CertificationsSelectors />
          <Divider />
          <Form.Label>
            <FormFieldLabel
              label="IEEE Type"
              isRequired={componentState.requiresIEEE}
            />
          </Form.Label>
          <LabIEEETypeSelectors requiresIEEE={componentState.requiresIEEE} />
          <Divider />
          <Form.Label>
            <FormFieldLabel
              label="Authorized Testing Capability"
              isRequired={true}
            />
          </Form.Label>
          <TestingCapabilitySelectors
            onRequiresIEEEType={isRequired =>
              setComponentState(state => {
                return {
                  ...state,
                  requiresIEEE: isRequired
                };
              })
            }
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default LabForm;
