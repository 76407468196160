import { restClient, restClientGeneral } from "libs/restClient";
import reqTypes from "dictionaries/ReqTypes";
import {
  getCyberRequestBackbone,
  getBatteryRequestBackbone
} from "helpers/RequestBackbone";
import { formatOperatorsList } from "helpers/Operators";
import { notifyMessages } from "helpers/NotifyHelper";
import * as perm from "helpers/PermissionHelperBattery";
import * as ph from "helpers/PermissionHelper";
import * as th from "helpers/RequestTestsHelper";
import * as rh from "helpers/RequestHelper";
import * as form from "libs/form";
import * as actions from "actions/types";

export function handleInputChange(event) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: form.getInputValue(event)
  };
}

export function toggleCheckbox(event) {
  const { id, checked } = event.target;
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field: id,
      value: checked
    }
  };
}

export function handleCheckboxGroupChange(values, index, event) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: form.getCheckboxGroupValue(index, event, values)
  };
}

export function handleCheckboxGroupCheckAll(values, event) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: form.setCheckboxGroupValues(event, values)
  };
}

export function handleSelectGroupChange(id, values, event) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: form.setSelectedGroupValue(id, event, values)
  };
}

export function handleFileChange(fileData, event) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field: "usersManual",
      value: form.getFileData(event, fileData)
    }
  };
}

export function handleFileUpload(fileData) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: form.setManualData(fileData)
  };
}

export function handleDocTitleChange(index, documents, event) {
  const { id, name, value } = event.target;
  const field = id || name;

  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field,
      value: form.updateDocumentTitle(index, value, documents)
    }
  };
}

export function handleDocFileChange(index, documents, event) {
  const { id, name, files } = event.target;
  const field = id || name;
  const fileContent = files[0];

  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: {
      field,
      value: form.updateDocumentFile(index, fileContent, documents)
    }
  };
}

export function saveRequest() {
  return {
    type: actions.SAVE_REQUEST,
    payload: { formSaved: true, isLoading: true }
  };
}

export function returnToEditRequest() {
  return {
    type: actions.RETURN_TO_EDIT_REQUEST,
    payload: { formSaved: false, isLoading: false }
  };
}

export function clearRequest() {
  return { type: actions.CLEAR_REQUEST };
}

export function createInitialCyberRequest(manufacturer = "") {
  const requestBackbone = getCyberRequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      model: { vendor: { name: manufacturer } },
      type: reqTypes.initial,
      isInitial: true,
      canBeSaved: true
    }
  };
}

export function createEcoCyberRequest(manufacturer = "") {
  const requestBackbone = getCyberRequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      manufacturer,
      type: reqTypes.eco,
      isEco: true
    }
  };
}

export function cleanPocInfo() {
  return {
    type: actions.CLEAN_POC_INFO,
    payload: {
      pocFirstName: "",
      pocLastName: "",
      pocEmail: "",
      pocPhone: "",
      pocPhoneExt: ""
    }
  };
}

export function cleanBillingPocInfo(billingPocAdd = false) {
  return {
    type: actions.CLEAN_POC_INFO,
    payload: {
      billingPocAdd: !billingPocAdd,
      billingPocFirstName: "",
      billingPocLastName: "",
      billingPocEmail: "",
      billingPocPhone: ""
    }
  };
}

export function cleanBillingAddressInfo(billingAddressAdd = false) {
  return {
    type: actions.CLEAN_POC_INFO,
    payload: {
      billingAddressAdd: !billingAddressAdd,
      billingAddressName: "",
      billingAddressLine1: "",
      billingAddressLine2: "",
      billingOrderNum: ""
    }
  };
}

export function setValidationStatus(validationStatus) {
  return {
    type: actions.SET_VALIDATION_STATUS,
    payload: validationStatus
  };
}

export function toggleEditMode() {
  return {
    type: actions.TOGGLE_REQUEST_EDIT_MODE,
    payload: null
  };
}

export function mapOperators(operatorsList, requestOperators) {
  return {
    type: actions.MAP_REQUEST_OPERATORS,
    payload: formatOperatorsList(operatorsList, requestOperators)
  };
}

export function setValue(field, value) {
  return {
    type: actions.CHANGE_REQUEST_FIELD,
    payload: { field, value }
  };
}

export function setLteWireless(hasLte) {
  return {
    type: actions.SET_LTE_WIRELESS,
    payload: hasLte
  };
}

export function createInitialBatteryRequest(manufacturer = "", params = {}) {
  const requestBackbone = getBatteryRequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      model: { vendor: { name: manufacturer } },
      type: reqTypes.initial,
      isInitial: true,
      isEco: false,
      ...params
    }
  };
}

export function createEcoBatteryRequest(manufacturer = "", params) {
  const requestBackbone = getCyberRequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      manufacturer,
      type: reqTypes.eco,
      isEco: true,
      ...params
    }
  };
}

export function createRenewalBatteryRequest(params) {
  const requestBackbone = getBatteryRequestBackbone();
  return {
    type: actions.INIT_NEW_REQUEST,
    payload: {
      ...requestBackbone,
      type: reqTypes.renewal,
      isInitial: false,
      isEco: false,
      isRenewal: true,
      ...params
    }
  };
}

export const updateRequest = ({
  id,
  urlPrefix = "",
  ...rest
}) => async dispatch => {
  try {
    const reqBody = {
      args: { ...rest }
    };

    let rc = restClient;

    if (urlPrefix.length > 0) {
      rc = restClientGeneral;
    }

    return await rc.put(`${urlPrefix}/requests/${id}`, JSON.stringify(reqBody));
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.cantUpdateRequest
    });

    return Promise.reject(e);
  }
};

export function setBatteryRequestPermissions(request, user) {
  let payload;

  if (request.isCRR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionCRR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionCRR(request, user),
      canEditPoc: perm.checkPocPermissionCRR(request, user),
      canEditBilling: perm.checkBillingPermissionCRR(request, user),
      canEditTests: perm.checkTestsPermissionCRR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionCRR(request, user),
      canEditModelname: perm.checkModelnamePermissionCRR(request, user),
      displayCapacity: perm.displayCapacityPermissionCRR(request),
      displayVoltage: perm.displayVoltagePermissionCRR(request),
      canEditVoltageCapacity: perm.checkVoltageCapacityPermissionCRR(
        request,
        user
      )
    };
  }

  if (request.isHRR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionHRR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionHRR(request, user),
      canEditPoc: perm.checkPocPermissionHRR(request, user),
      canEditBilling: perm.checkBillingPermissionHRR(request, user),
      canEditTests: perm.checkTestsPermissionHRR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionHRR(request, user),
      canEditModelname: perm.checkModelnamePermissionHRR(request, user),
      canEditVoltageCapacity: perm.checkVoltageCapacityPermissionHRR(
        request,
        user
      ),
      displayCapacity: perm.displayCapacityPermissionHRR(request),
      displayVoltage: perm.displayVoltagePermissionHRR(request),
      canEditLabFields: perm.checkLabFieldsPermissionHRR(request, user)
    };
  }

  if (request.isPRR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionPRR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionPRR(request, user),
      canEditPoc: perm.checkPocPermissionPRR(request, user),
      canEditBilling: perm.checkBillingPermissionPRR(request, user),
      canEditTests: perm.checkTestsPermissionPRR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionPRR(request, user),
      canEditModelname: perm.checkModelnamePermissionPRR(request, user),
      canEditVoltageCapacity: perm.checkVoltageCapacityPermissionPRR(
        request,
        user
      ),
      canEditLabFields: perm.checkLabFieldsPermissionPRR(request, user)
    };
  }

  if (request.isEPRR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionEPR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionEPR(request, user),
      canEditPoc: perm.checkPocPermissionEPR(request, user),
      canEditBilling: perm.checkBillingPermissionEPR(request, user),
      canEditTests: perm.checkTestsPermissionEPR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionEPR(request, user),
      canEditModelname: perm.checkModelnamePermissionEPR(request, user),
      canEditVoltageCapacity: perm.checkVoltageCapacityPermissionEPR(
        request,
        user
      ),
      canEditLabFields: perm.checkLabFieldsPermissionEPR(request, user)
    };
  }

  if (request.isARR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionARR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionARR(request, user),
      canEditPoc: perm.checkPocPermissionARR(request, user),
      canEditBilling: perm.checkBillingPermissionARR(request, user),
      canEditTests: perm.checkTestsPermissionARR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionARR(request, user),
      canEditModelname: perm.checkModelnamePermissionARR(request, user),
      canEditLabFields: perm.checkLabFieldsPermissionARR(request, user)
    };
  }

  if (request.isACR) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionACR(request, user),
      canEditRequest: perm.checkDeviceInfoPermissionACR(request, user),
      canEditPoc: perm.checkPocPermissionACR(request, user),
      canEditBilling: perm.checkBillingPermissionACR(request, user),
      canEditTests: perm.checkTestsPermissionACR(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionACR(request, user),
      canEditModelname: perm.checkModelnamePermissionACR(request, user),
      canEditLabFields: perm.checkLabFieldsPermissionACR(request, user)
    };
  }

  if (request.isBSC) {
    payload = {
      canEditDeviceInfo: perm.checkDeviceInfoPermissionBSC(request, user),
      // uses for HW/SW fields only, so can copy logic from `canEditDeviceInfo`
      canEditRequest: perm.checkDeviceInfoPermissionBSC(request, user),
      canEditPoc: perm.checkPocPermissionBSC(request, user),
      canEditBilling: perm.checkBillingPermissionBSC(request, user),
      canEditTests: perm.checkTestsPermissionBSC(request, user),
      canEditPaymentInfo: perm.checkPaymentPermissionBSC(request, user),
      canEditModelname: perm.checkModelnamePermissionBSC(request, user),
      canEditLabFields: perm.checkLabFieldsPermissionBSC(request, user)
    };
  }

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}

export function makeLabDecisionGeneral(decision, request) {
  const statusId = th.getLabDecisionStatus(decision);
  const urlPrefix = rh.getTransPrefix(request.tn);
  const url = `${urlPrefix}/requests/${request.id}/requesttests?statusid=${statusId}`;

  return {
    type: actions.MAKE_LAB_DECISION,
    payload: restClientGeneral.put(url)
  };
}

export function attachAppCertTypes(payload) {
  return {
    type: actions.ATTACH_APP_CERT_TYPES,
    payload
  };
}

export function setCyberRequestPermissions(request, user) {
  const payload = {
    canEditDeviceInfo: ph.checkModelnamePermission(request, user),
    // TODO: comment next line in order to use Modify mode switcher
    canEditRequest: ph.checkDeviceInfoPermission(request, user)
    // canEditPoc: ph.checkPocPermission(request, user)
    // canEditBilling: perm.checkBillingPermissionCRR(request, user),
    // canEditTests: perm.checkTestsPermissionCRR(request, user),
    // canEditPaymentInfo: perm.checkPaymentPermissionCRR(request, user),
    // canEditModelname: perm.checkModelnamePermissionCRR(request, user)
  };

  return {
    type: actions.SET_PERMISSIONS,
    payload
  };
}
