import _ from "lodash";
import * as actions from "actions/types";

const fetchInvoice = (action, state) => {
  const data = action.payload;
  const {
    totalamount,
    invoicenumber,
    checknumber,
    targetyear,
    id,
    createdat,
    atlinvoiceaddress,
    atlinvoicecosts,
    company,
    purchaseorder
  } = data;

  const invoiceData = {
    totalAmount: totalamount,
    invoiceNumber: invoicenumber,
    checkNumber: checknumber,
    targetYear: targetyear,
    invoiceId: id,
    invoiceDate: createdat,
    customerid: company.customerid,
    costs: atlinvoicecosts,
    purchaseorder,
    ...atlinvoiceaddress
  };

  return { ...invoiceData };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_ATL_INVOICE:
      return fetchInvoice(action, state);
    case actions.CHANGE_INVOICE_FIELD:
      _.set(state, [action.payload.field], action.payload.value);
      return { ...state };
    case actions.CHANGE_COST_FIELD:
      const { key, field, value } = action.payload;
      _.set(state, `costs[${key}][${field}]`, value);
      return { ...state };
    case actions.CLEAR_INVOICE_ATL:
      return null;
    default:
      return state;
  }
}
