import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import {
  getStatusTitle,
  getModelName,
  getRequestVendor,
  isExisted
} from "helpers/RequestHelper";
import { getWirelessAsString } from "helpers/Wireless";

class CyberInitialInfoBar extends Component {
  state = {
    status: "",
    vendor: "",
    modelName: "",
    certLevel: null,
    wireless: null
  };

  componentDidMount() {
    this.updateRequestInfo();
  }

  updateRequestInfo = () => {
    const { request, user } = this.props;
    const { certCategories, certCategoryName, certCategory, wirelessToggled } = request;
    const status = isExisted(request) ? getStatusTitle(request.status) : null;
    const vendor = getRequestVendor(request, user);
    const modelName = getModelName(request);
    const certLevel = _.isEmpty(certCategoryName) ? _.find(certCategories, category => category.id === parseInt(certCategory)).name : certCategoryName;
    const wireless = getWirelessAsString(wirelessToggled);

    this.setState({ status, vendor, modelName, certLevel, wireless });
  };

  componentDidUpdate(prevProps) {
    const { request } = this.props;

    if (request && request.status !== prevProps.request.status) {
      this.updateRequestInfo();
    }
  }

  getValues = () => {
    const { status, vendor, modelName, certLevel, wireless } = this.state;
    const reqDataInfo = [
      {
        key: "Status",
        value: status
      },
      {
        key: "Vendor",
        value: vendor
      },
      {
        key: "Model Name/Number",
        value: modelName
      },
      {
        key: "Certification Level",
        value: certLevel
      },
      {
        key: "Wireless Technologies",
        value: wireless
      }
    ];

    // remove fields with no values:
    return _.filter(reqDataInfo, field => !_.isNull(field.value));
  };

  render() {
    return <InfoBar show={true} data={this.getValues()} />;
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps)(CyberInitialInfoBar);
