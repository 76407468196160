import FormElement from "components/FormElement";
import React from "react";
import { Form, Col } from "ctia-react-bootstrap-v4";
import { selectedCountryRequiredState } from "containers/UserProfile/UserProfileHelper";
import { validatePhone } from "helpers/ValidatorHelper";
import validation from "dictionaries/Validation";

const UserProfileFormRows = ({ onChangeHandler, formFieldValues }) => {
  const commonFields = {
    fullWidthColumns: true,
    onChange: onChangeHandler,
    type: "text"
  };

  const validatePhoneNumber = phoneNum => {
    return !phoneNum || validatePhone(phoneNum) ? null : "error";
  };

  const { workPhone, workPhoneExt, mobilePhone } = formFieldValues;

  return (
    <>
      <FormElement
        {...commonFields}
        id="firstName"
        isRequired={true}
        label="First Name"
        value={formFieldValues.firstName}
      />
      <FormElement
        {...commonFields}
        id="lastName"
        isRequired={true}
        label="Last Name"
        value={formFieldValues.lastName}
      />
      <FormElement
        {...commonFields}
        type={null}
        id="userName"
        label="User Name"
        value={formFieldValues.userName}
      />
      <FormElement
        {...commonFields}
        id="title"
        isRequired={true}
        label="Business Title"
        value={formFieldValues.title}
      />
      <FormElement
        {...commonFields}
        id="email"
        type={null}
        label="Email"
        value={formFieldValues.email}
      />
      <Form.Row>
        <Col>
          <FormElement
            {...commonFields}
            id="workPhone"
            isRequired={true}
            label="Work Phone"
            value={workPhone}
            validationState={validatePhoneNumber(workPhone)}
            validationMessage={validation.invalidPhoneFormat}
          />
        </Col>
        <Col xs="auto" className="icon-left-offset">
          <FormElement
            {...commonFields}
            id="workPhoneExt"
            label="Ext."
            value={workPhoneExt}
            placeholder="Phone Number Ext."
            validationState={validatePhoneNumber(workPhoneExt)}
            validationMessage={validation.invalidPhoneFormat}
          />
        </Col>
      </Form.Row>
      <FormElement
        {...commonFields}
        id="mobilePhone"
        label="Mobile Phone"
        value={mobilePhone}
        validationState={
          mobilePhone !== "" ? validatePhoneNumber(mobilePhone) : null
        }
        validationMessage={validation.invalidPhoneFormat}
      />
      <FormElement
        {...commonFields}
        id="address1"
        label="Address 1"
        isRequired={true}
        value={formFieldValues.address1}
      />
      <FormElement
        {...commonFields}
        id="address2"
        label="Address 2"
        value={formFieldValues.address2}
      />
      <FormElement
        {...commonFields}
        id="address3"
        label="Address 3"
        value={formFieldValues.address3}
      />
      <FormElement
        {...commonFields}
        id="country"
        isRequired={true}
        label="Country"
        type="country"
        value={formFieldValues.country}
      />
      <FormElement
        {...commonFields}
        id="city"
        isRequired={true}
        label="City"
        value={formFieldValues.city}
      />
      <FormElement
        {...commonFields}
        id="state"
        isRequired={selectedCountryRequiredState(formFieldValues.country)}
        label="State/Province"
        selectedCountryCode={formFieldValues.country}
        type="stateAndProvince"
        value={formFieldValues.state}
      />
      <FormElement
        {...commonFields}
        id="zipCode"
        isRequired={true}
        label="Zip/Postal Code"
        value={formFieldValues.zipCode}
      />
      <FormElement
        {...commonFields}
        id="company"
        type={null}
        label="Company"
        value={formFieldValues.company}
      />
      <FormElement
        {...commonFields}
        id="website"
        isRequired={true}
        label="Company Website"
        value={formFieldValues.website}
      />
    </>
  );
};

export default UserProfileFormRows;
