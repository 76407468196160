import React, { Component } from "react";
import { connect } from "react-redux";
import EditTestPlans from "containers/request/OTA/TestPlans/TestPlans--Edit";
import TestPlansReadonly from "containers/request/OTA/TestPlans/TestPlans--Readonly";
import { getTestPermissions } from "helpers/RequestTestsHelper";
import { defineOtaInterfaces, clearOtaInterfaces } from "actions/ota";

class OTATestPlans extends Component {
  setInterfaces() {
    const {
      request,
      tests,
      ota,
      defineOtaInterfaces,
      clearOtaInterfaces
    } = this.props;

    return request.id > 0
      ? defineOtaInterfaces(tests, ota)
      : clearOtaInterfaces();
  }

  componentDidMount() {
    this.setInterfaces();
  }

  render() {
    const { app, user } = this.props;
    const isEditable = getTestPermissions(app, user);

    return isEditable ? <EditTestPlans /> : <TestPlansReadonly />;
  }
}

function mapStateToProps({ app, ota, request, tests, user }) {
  return { app, ota, request, tests, user };
}

export default connect(mapStateToProps, {
  defineOtaInterfaces,
  clearOtaInterfaces
})(OTATestPlans);
