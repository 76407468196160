import React, { useState } from "react";
import { IconLabel, Drawer } from "ctia-ui";
import BipolarBadge from "components/Badges/BipolarBadge";
import CompanyUsers from "containers/ControlPanel/CompanyUsers";
import EditServiceCenter from "containers/ControlPanel/Orgs/ServiceCenter/EditServiceCenter";
import ContactLink from "containers/ControlPanel/Orgs/ServiceCenter/ContactLink";
import DisableOrg from "containers/ControlPanel/Orgs/DisableOrg/DisableOrg";
import { renderNameWithFlag } from "containers/ControlPanel/Orgs/ServiceCenter/RenderHelperASC";

const ServiceCenterRow = ({ item }) => {
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const toggleBottomDrawer = () => setShowBottomDrawer(!showBottomDrawer);
  const status = item.active ? "ACTIVE" : "DISABLED";

  return (
    <tr>
      {/* Org Name */}
      <td>
        <div>
          <EditServiceCenter id={item.id} name={item.name} />
        </div>
        <div>
          <small className="block-left-offset1">
            <strong>
              <span
                className="glow-link cursor-pointer"
                onClick={toggleBottomDrawer}
              >
                <IconLabel awesomeIcon="users" label="View All Users" />
              </span>
            </strong>
          </small>
        </div>
      </td>
      {/* City */}
      <td>{item.citydisplay}</td>
      {/* Country */}
      <td>{renderNameWithFlag(item.countrydisplay, item.country)}</td>
      {/* Contact */}
      <td>
        <ContactLink name={item.contactname} email={item.email} />
      </td>
      {/* ASC Status */}
      <td>{item.ascstatus}</td>
      {/* Status */}
      <td>
        <BipolarBadge label={status} positive={item.active === 1} />
        <Drawer
          position="bottom"
          show={showBottomDrawer}
          title="View ASC Users"
          toggleDrawer={toggleBottomDrawer}
          content={
            <CompanyUsers
              companyId={item.id}
              companyName={item.name}
              flagCode={item.country}
            />
          }
          height="400"
        />
      </td>
      <td>
        <DisableOrg org={item} />
      </td>
    </tr>
  );
};

export default ServiceCenterRow;
