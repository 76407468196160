import React from "react";
import { Badge } from "ctia-react-bootstrap-v4";

const BipolarBadge = ({ label, positive = true }) => {
  if (!label || label === "") return "Unknown";

  const variant = positive ? "ctia-green" : "danger";

  return <Badge variant={variant}>{label}</Badge>;
};

export default BipolarBadge;
