import _ from 'lodash';
import React from 'react';
import { testStatuses, getTestResultTitle } from 'helpers/RequestTestsHelper';

const FilterOptionsDHR = () => {
  return _.map(testStatuses, id => {
    const title = getTestResultTitle(id).toLowerCase();
    return (
      <option value={id} key={id}>
        {title}
      </option>
    );
  });
};

export default FilterOptionsDHR;
