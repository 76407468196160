export default {
  systemHost: {
    submissionText:
      "Please enter the system host by selecting an existing Model Name/Number in the database or by entering a new Model Name/Number.",
    updatingText: "The following is a summary of your request."
  },
  deviceInfo: {
    submissionText:
      "Please provide the following information about the device.",
    updatingText: "Below is the device information entered for this request."
  },
  batterySupplier: {
    selectFirst: {
      submissionText: "Enter the first battery in the system.",
      updatingText: "zzz"
    },
    selectMore: {
      submissionText: "You can enter another battery if needed.",
      updatingText: "Enter another battery or click Save if you are finished."
    }
  },
  adapterSupplier: {
    selectFirst: {
      submissionText:
        "Enter the first adapter in the system. Please choose one of the following options:",
      updatingText: "zzz"
    },
    selectMore: {
      submissionText:
        "You can enter another adapter or click Continue if you are finished.",
      updatingText: "Enter another adapter or click Save if you are finished."
    }
  },
  cellSupplier: {
    selectFirst: {
      submissionText: "Enter the first cell supplier in the system.",
      updatingText: "zzz"
    },
    selectMore: {
      submissionText:
        "Enter another cell supplier or click Continue if you are finished.",
      updatingText:
        "Enter another cell supplier or click Save if you are finished."
    }
  },
  deviceInfoOTA: {
    submissionText:
      "Please provide the following information about the device.",
    updatingText: "Below is the device information entered for this request."
  },
  testPlansOTA: {
    selectFirst: {
      submissionText: "Select the first test plan you wish to use.",
      updatingText: "Select the first test plan you wish to use."
    },
    selectMore: {
      submissionText:
        "Add another test plan or click Continue if you are finished.",
      updatingText: "Add another test plan if needed."
    }
  },
  manufSite: {
    submissionText:
      "Please provide the following information about the manufacturing site.",
    updatingText:
      "Below is the manufacturing site information entered for this request."
  },
  renewalSite: {
    submissionText: "Please select a manufacturing site.",
    updatingText:
      "Below is the manufacturing site information selected for this request."
  },
  batteryLifeInfo: {
    updatingText: "Below is the battery life information for this request."
  }
};
