import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "ctia-react-bootstrap-v4";
import { Bubble, Divider, LoaderButton } from "ctia-ui";
import { fetchUserProfile, updateUserProfile } from "actions/user";
import { Col, Form, Row } from "react-bootstrap";
import "./style.css";
import _ from "lodash";
import FormElement from "components/FormElement";
import {
  getFormFieldValues,
  getUpdatedState,
  mapComponentStateToApiParams,
  selectedCountryRequiredState,
  setFormFieldValuesFromUser,
  validatePassword
} from "containers/UserProfile/UserProfileHelper";
import { scrollTo } from "helpers/AppHelper";
import { validatePhone } from "helpers/ValidatorHelper";
import UserProfileFormRows from "containers/UserProfile/UserProfileFormRows";
import UserProfilePasswordRules from "containers/UserProfile/UserProfilePasswordRules";

const viewId = "user-profile-header";

class UserProfile extends Component {
  /// MARK: Setup component state / values

  state = {
    errorMessage: "",
    formIsValid: false,
    hasRequestedUserFromAPI: false,
    isLoading: false,
    successMessage: "",
    userAccountId: this.props.user.ctiaid,
    validationState: null,
    validationMessage: "",
    // FormField values
    firstName: this.props.user.firstname,
    lastName: this.props.user.lastname,
    userName: this.props.user.username,
    company: this.props.user.company,
    website: this.props.user.website,
    title: this.props.user.title,
    address1: this.props.user.address1,
    address2: this.props.user.address2,
    address3: this.props.user.address3,
    city: this.props.user.city,
    state: this.props.user.stateid,
    zipCode: this.props.user.zipcode,
    country: this.props.user.countryid,
    email: this.props.user.email,
    workPhone: this.props.user.workphone,
    workPhoneExt: this.props.user.workphoneext,
    mobilePhone: this.props.user.mobilephone,
    newPassword: "",
    confirmNewPassword: ""
  };

  /// MARK: Setup form -- fetch the user profile when component first loads
  componentDidMount() {
    this.props.fetchUserProfile({
      userId: this.state.userAccountId,
      callback: () => {
        const mixedValues = { ...this.state, ...this.props.user };
        const updatedFormFields = setFormFieldValuesFromUser(mixedValues);
        this.setState({ ...updatedFormFields });
      },
      errorCallback: () => {
        this.setState({
          errorMessage:
            "There was a problem getting your user profile. Please try and refresh your browser."
        });
      }
    });
  }

  componentDidUpdate() {
    this.validateForm();
  };

  onChangeHandler = event => {
    const { id, value } = event.target;
    const formFieldValues = getFormFieldValues(this.state);

    const newState = getUpdatedState({
      eventId: id,
      eventValue: value,
      formFieldValues
    });

    this.setState(newState);
  };

  /// MARK: Functions to handle validation form input
  validateFormFields = () => {
    const {
      firstName,
      lastName,
      email,
      address1,
      workPhone,
      workPhoneExt,
      mobilePhone,
      city,
      country,
      zipCode,
      state,
      website
    } = this.state;
    let requiredFields = [
      firstName,
      lastName,
      email,
      address1,
      workPhone,
      city,
      country,
      zipCode,
      website
    ];

    if (selectedCountryRequiredState(country)) {
      requiredFields.push(state);
    }

    // convert values to Strings to be able to use isEmpty helper
    const strVals = requiredFields.map(fieldVal => _.toString(fieldVal));
    if (_.some(strVals, _.isEmpty)) return false;

    if (!_.isEmpty(workPhone) && !validatePhone(workPhone)) return false;
    if (!_.isEmpty(workPhoneExt) && !validatePhone(workPhoneExt)) return false;
    if (!_.isEmpty(mobilePhone) && !validatePhone(mobilePhone)) return false;

    return true;
  };

  validatePw = () => {
    const {
      newPassword,
      confirmNewPassword,
      validationMessage,
      validationState
    } = this.state;
    const { isValid, message } = validatePassword(
      newPassword,
      confirmNewPassword
    );

    const newValidationState = isValid ? null : "error";
    if (
      validationState !== newValidationState ||
      validationMessage !== message
    ) {
      this.setState({
        validationState: newValidationState,
        validationMessage: message
      });
    }

    return isValid;
  };

  validateForm = () => {
    const passwordsAreValid = this.validatePw();
    const formFieldsAreValid = this.validateFormFields();
    const userCanSubmit = passwordsAreValid && formFieldsAreValid;

    if (this.state.formIsValid !== userCanSubmit) {
      this.setState({ formIsValid: userCanSubmit });
    }
  };

  /// MARK: Functions to handle form submission
  onSubmitForm = () => {
    this.setState({ isLoading: true, errorMessage: "" });

    const params = mapComponentStateToApiParams(this.state);
    const containsPw =
      !_.isNull(params["password"]) && !_.isEmpty(params["password"]);

    this.props.updateUserProfile({
      userId: this.state.userAccountId,
      params,
      callback: () => {
        this.setState({
          isLoading: false,
          successMessage: containsPw
            ? "Your profile and password have been updated!"
            : "Your profile has been updated!"
        });

        scrollTo(viewId);
      },
      errorCallback: () => {
        this.setState({
          errorMessage:
            "There was a problem updating your user profile. Please try again.",
          isLoading: false
        });

        scrollTo(viewId);
      }
    });
  };

  render() {
    const { user } = this.props;

    const { errorMessage, successMessage } = this.state;
    const { validationMessage, validationState } = this.state;

    const formFieldValues = getFormFieldValues(this.state);
    const { newPassword, confirmNewPassword } = formFieldValues;

    return (
      <div className="UserProfile">
        <div className="UserProfileForm" id={viewId}>
          <h1>
            User Profile&nbsp;
            <Badge className="icon-right-offset">{user.name}</Badge>
            <Badge variant="ctia-blue">{user.company}</Badge>
          </h1>
          <Bubble type="danger" show={errorMessage !== ""}>
            {errorMessage}
          </Bubble>
          <Bubble type="success" show={successMessage !== ""}>
            {successMessage}
          </Bubble>
          <Form>
            <UserProfileFormRows
              onChangeHandler={this.onChangeHandler}
              formFieldValues={formFieldValues}
            />
            <Divider />
            <FormElement
              fullWidthColumns={true}
              id="newPassword"
              editable={true}
              isRequired={false}
              label={"New Password"}
              onChange={this.onChangeHandler}
              type={"password"}
              value={newPassword}
            />
            <FormElement
              fullWidthColumns={true}
              id="confirmNewPassword"
              editable={true}
              isRequired={false}
              label={"Confirm New Password"}
              onChange={this.onChangeHandler}
              type={"password"}
              validationMessage={validationMessage}
              validationState={validationState}
              value={confirmNewPassword}
            />
            <UserProfilePasswordRules
              newPassword={newPassword}
              confirmPassword={confirmNewPassword}
            />
            <Divider />
            <Row className="show-grid">
              <Col md={4} xs={1} />
              <Col md={4} xs={10} className="text-center">
                <LoaderButton
                  bsStyle="info"
                  bsSize="large"
                  disabled={!this.state.formIsValid}
                  isLoading={this.state.isLoading}
                  loadingText="Submitting…"
                  onClick={this.onSubmitForm}
                  text="Update"
                  type="submit"
                />
              </Col>
              <Col md={4} xs={1} />
            </Row>
          </Form>
        </div>
      </div>
    );
  };
};

function mapStateToProps({ user }) {
  return { user };
};

export default connect(mapStateToProps, {
  fetchUserProfile,
  updateUserProfile
})(UserProfile);
