import React from "react";
import { connect } from "react-redux";
import { switchLabCompany } from "actions/labs";
import { getUserInfo } from "actions/user";

const LabsToSwitch = ({
  catl,
  user,
  switchLabCompany,
  getUserInfo,
  toggleDrawer
}) => {
  return (
    <div>
      <p>
        If you would like to log in as a different lab, please choose from the
        following:
      </p>
      <ul className="ctia-list">
        {catl.labsToSwitch.map(lab => {
          const key = `labs-to-switch-${lab.id}`;

          if (user.companyid === lab.id) {
            return (
              <li key={key} className="bold">
                {lab.name}
              </li>
            );
          }

          return (
            <li
              key={key}
              onClick={() => {
                // close drawer first
                toggleDrawer();

                // switch and update user token
                return switchLabCompany(lab.id).then(() => getUserInfo());
              }}
              className="cursor-pointer glow-link"
            >
              {lab.name}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

function mapStateToProps({ user, catl }) {
  return { user, catl };
}

export default connect(mapStateToProps, {
  switchLabCompany,
  getUserInfo
})(LabsToSwitch);
