import { Col, FormGroup } from "react-bootstrap";
import { IconLabel, LoaderButton } from "ctia-ui";
import React from "react";

const SubmitButton = ({ isLoading, onSubmit }) => {
  return (
    <FormGroup>
      <Col xs={12} sm={9} md={9} mdOffset={3} smOffset={3}>
        <LoaderButton
          bsStyle="info"
          className="btn-shadow"
          isLoading={isLoading}
          onClick={onSubmit}
          text={<IconLabel label="Submit" awesomeIcon="search" />}
        />
      </Col>
    </FormGroup>
  );
};

export default SubmitButton;
