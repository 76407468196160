import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Glyphicon, Label } from "react-bootstrap";

class UploadQueue extends Component {
  setElementInfo = elem => {

    if (elem.error) {
      return {
        icon: "remove",
        iconClass: "red",
        message: () => {
          return (
            <span className="red">
              File «{elem.title}» wasn't successfully uploaded:{" "}
              <Label bsStyle="danger">{elem.errorMessage}</Label>
            </span>
          );
        }
      };
    }

    if (elem.pending) {
      return {
        icon: "hourglass",
        iconClass: "rotateMe",
        message: () => {
          return <span>Uploading «{elem.title}» file.</span>;
        }
      };
    }

    return {
      icon: "ok",
      iconClass: "",
      message: () => {
        return <span>File «{elem.title}» has been successfully uploaded.</span>;
      }
    };
  };

  render() {
    const { uploads } = this.props;

    if (!uploads) return null;

    const queue = _.map(uploads);

    const Queue = () => {
      return queue.map((elem, i) => {
        const elemInfo = this.setElementInfo(elem);
        return (
          <p key={i}>
            <Glyphicon
              glyph={elemInfo.icon}
              className={`icon-right-offset ${elemInfo.iconClass}`}
            />
            {elemInfo.message()}
          </p>
        );
      });
    };
    return <Queue />;
  }
}

function mapStateToProps({ uploads }) {
  return { uploads };
}

export default connect(mapStateToProps)(UploadQueue);
