import _ from "lodash";
import CompanyInterfaces from "dictionaries/CompanyInterfaces";
import CompanyTypes from "dictionaries/CompanyTypes";
import { selectedCountryShouldRequireStateOrProvince } from "helpers/Countries";

export const labFormRequiresIEEEType = form => {
  const { batteryCompliance, batterySiteAudit } = CompanyInterfaces;
  const requiredIds = [batteryCompliance.id, batterySiteAudit.id];

  const interfaces = _.filter(form, formItem => _.includes(requiredIds, Number(formItem.value)));
  if (_.isUndefined(interfaces) || _.isEmpty(interfaces)) return false;

  return _.some(interfaces, interfaceItem => interfaceItem.checked === true);
};

export const labFormHasIeeeTypeSelected = form => {
  // 1625 / 1725
  const { ieee0, ieee1 } = form;
  if (_.isUndefined(ieee0) || _.isUndefined(ieee1)) { return false; }

  return ieee0.checked || ieee1.checked;
};

export const validateLabForm = form => {
  // validate required selector fields
  const defaultSelect = "--- Select One ---";
  const {
    address, city, country, name, state, status
  } = form;

  if (_.isEmpty(country.value) || country.value === defaultSelect || status.value === defaultSelect)
    return false;

  if (selectedCountryShouldRequireStateOrProvince(country.value) &&
     (_.isUndefined(state) || _.isEmpty(state.value)))
    return false;

  if (_.isEmpty(address) || _.isEmpty(city) || _.isEmpty(name))
    return false;

  if (labFormRequiresIEEEType(form) && !labFormHasIeeeTypeSelected(form))
    return false;

  return (
    getCheckboxIdsFromForm(form, 'certification').length > 0 &&
    getCheckboxIdsFromForm(form, 'interface').length > 0);
};

export const validateOperatorForm = form => {
  return getCheckboxIdsFromForm(form, 'certification').length > 0;
};

export const getCheckboxIdsFromForm = (form, capabilityType) => {
  let testingCapabilities = [];
  _.entries(form)
  .forEach(formEntry => {
    const { checked, id, value } = formEntry[1];
    if (_.isUndefined(id))
      return;

    const matchTestingCapability = id.match(`${capabilityType}[0-9]+`);
    const isMatch = !_.isNull(matchTestingCapability);

    if (isMatch && checked)
      value.split(",").forEach(capabilityId => testingCapabilities.push(capabilityId));
  });

  return testingCapabilities;
};

export const formatFormForAPISubmission = ({
  companyType = "",
  form = {}
}) => {
  const companyT = companyType.toLowerCase();

  let argsBody;
  switch(companyT) {
  case "lab":
  case "laboratory":
    argsBody = {
      address1: form.address.value,
      address2: form.address2.value,
      address3: form.address3.value,
      catllicensedate: form.authDate.value,
      catlstatus: form.status.value,
      companyinterfaces: getCheckboxIdsFromForm(form, "interface"),
      companyieeetypes: getCheckboxIdsFromForm(form, "ieee"),
      companycerttypes: getCheckboxIdsFromForm(form, 'certification'),
      countrycode: form.country.value,
      city: form.city.value,
      name: form.name.value,
      roleid: CompanyTypes.lab.roleId,
      stateid: !_.isUndefined(form.state)
        ? form.state.value
        : "",
      zipcode: form.zipCode.value
    };
    break;

  case "operator":
    argsBody = {
      companycerttypes: getCheckboxIdsFromForm(form, 'certification'),
      name: form.name.value,
      roleid: CompanyTypes.networkOperator.roleId
    };
    break;

  case "manufacturer":
    argsBody = {
      name: form.name.value,
      roleid: CompanyTypes.manufacturer.roleId
    };
    break;

  case "supplier":
    argsBody = {
      isSupplier: true,
      name: form.name.value,
      roleid: CompanyTypes.manufacturer.roleId
    };
    break;

  default:
    argsBody = {};
    break;
  }

  return {
    "args": argsBody
  };
};
