// import { api } from "dictionaries/RestAPI";
import { restClient, restClientGeneral } from "libs/restClient";
import { testStatuses } from "helpers/RequestTestsHelper";
import {
  requestStatuses,
  getBasicRequestStatusList
} from "helpers/RequestHelper";
import { getPageArgsAsQueryString } from "helpers/ArgsHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import * as call from "helpers/ActionHelper";
import * as actions from "actions/types";
import * as ls from "libs/localStorage";

export function searchRequest(transactionNumber, client = "") {
  const clientClause = client
    ? `AND client = ${client.toLowerCase()}`
    : "AND client <> 'cbrs'";
  const whereClause = `transactionnumber LIKE ${transactionNumber} ${clientClause}`;
  const url = `/requests?where=${whereClause}`;
  const payload = restClientGeneral
    .get(url)
    .then(response => {
      return response;
    })
    .catch(err => {
      return err;
    });

  return {
    type: actions.SEARCH_REQUEST,
    payload
  };
}

export const fetchCertificationRequests =
  (page, size, statusFilter) => async dispatch => {
    // start preloader
    dispatch({
      type: actions.START_PRELOADER,
      payload: null
    });

    try {
      const filterList = statusFilter.join();
      const pagerArgs = getPageArgsAsQueryString(page, size);
      const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=requeststatusid IN (${filterList})`;
      const response = await restClient.get(url);

      dispatch({
        type: actions.FETCH_CERTIFICATION_REQUESTS,
        payload: response.data
      });
    } catch (e) {
      dispatch({
        type: actions.SHOW_NOTICE,
        payload: notifyMessages.errorAsyncAction
      });
    }

    // stop preloader
    dispatch({
      type: actions.STOP_PRELOADER,
      payload: null
    });
  };

export function clearRequests() {
  return { type: actions.CLEAR_REQUESTS };
}

export const fetchApproveWaitingRequests = () => async dispatch => {
  try {
    const url = `/requests/table?page=0&orderBy=transactionnumber DESC&where=requeststatusid=${requestStatuses.underReview}`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_APPROVE_WAITING_REQUESTS,
      payload: response.data
    });

    dispatch({
      type: actions.COUNT_APPROVE_WAITING_REQUESTS,
      payload: response.data.data.length
    });

    return response;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function getReadyToApproveRequests() {
  return call.dispatchWithPreloader(fetchApproveWaitingRequests);
}

export const fetchRequestsForTesting =
  ({ page, size, statusFilter }) =>
  async dispatch => {
    try {
      const filterList = `'${statusFilter.join("','")}'`;
      const statusList = getBasicRequestStatusList();
      const pagerArgs = getPageArgsAsQueryString(page, size);
      const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=testingstatus IN (${filterList}) AND requeststatusid IN (${statusList})`;
      const response = await restClient.get(url);

      dispatch({
        type: actions.FETCH_CERTIFICATION_REQUESTS,
        payload: response.data
      });

      return response;
    } catch (e) {
      dispatch(call.showNotice(notifyMessages.errorAsyncAction));

      return Promise.reject(e);
    }
  };

export function getRequestsForTesting(tableSettings) {
  return call.dispatchWithPreloader(fetchRequestsForTesting, tableSettings);
}

export const fetchApprovedRequestsForTesting = () => async dispatch => {
  try {
    const { accepted, underReview } = testStatuses;
    const { requestWithdrawn } = requestStatuses;
    const testStatusList = `'${accepted}', '${underReview}'`;
    const url = `/requests/table?page=0&orderBy=transactionnumber DESC&where=requeststatusid <> ${requestWithdrawn} AND testingstatus IN (${testStatusList})`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_REQUESTS_FOR_TESTING,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });
  }
};

export const fetchUnpaidRequestsByProgram = programPrefix => async dispatch => {
  try {
    const { saved, requestWithdrawn } = requestStatuses;
    const includeClause = "invoice,billing(address)";
    const blcId = ls.getValue("idBLC");
    const clientClause =
      programPrefix === "blc"
        ? `client='blc' AND certtypeid = ${blcId}`
        : `client='${programPrefix}'`;
    const programClause =
      programPrefix === "battery"
        ? `${clientClause} AND certtypeid <> ${blcId}`
        : clientClause;
    const whereClause = `${programClause} AND paid=0 AND totalamount > 0 AND requeststatusid NOT IN (${saved},${requestWithdrawn}) AND testingstatus IN ('Accepted','Under Review','Complete')`;
    const url = `/requests?page=0&orderBy=transactionnumber DESC&include=${includeClause}&where=${whereClause}`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_UNPAID_REQUESTS,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });
  }
};

// operators can see only pending (1) and under review (5) requests
export const fetchOperatorRequests = (page, size) => async dispatch => {
  try {
    const pagerArgs = getPageArgsAsQueryString(page, size);
    const url = `/requests/table?${pagerArgs}&orderBy=transactionnumber DESC&where=requeststatusid IN (1,5)`;
    const response = await restClient.get(url);

    dispatch({
      type: actions.FETCH_CERTIFICATION_REQUESTS,
      payload: response.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorAsyncAction
    });
  }
};
