import React from 'react';
import PropTypes from 'prop-types';
import Button from './FormButton';
import { IconLabel } from 'ctia-ui';

const SubmitButton = ({ label, ...rest }) => {
  return (
    <Button
      bsStyle="success"
      text={<IconLabel label={label} awesomeIcon="check-double" />}
      {...rest}
    />
  );
};

SubmitButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string
};

SubmitButton.defaultProps = {
  show: true,
  label: 'Submit'
};

export default SubmitButton;
