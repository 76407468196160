import React, { useEffect } from "react";
import { connect } from "react-redux";
import OrgOperatorRow from "containers/ControlPanel/Orgs/Operators/OrgOperatorRow";
import OrgListGeneric from "containers/ControlPanel/Orgs/OrgListGeneric";
import { setOrgType, fetchCompaniesOperator, clearOrgList } from "actions/org";
import { OPERATOR } from "dictionaries/Roles";

const OrgsOperators = ({
  org,
  setOrgType,
  fetchCompaniesOperator,
  clearOrgList
}) => {
  useEffect(() => {
    if (!org || !org.type || org.type !== OPERATOR) {
      setOrgType(OPERATOR);
    }
  }, [org, setOrgType]);

  useEffect(() => {
    if (org && org.type === OPERATOR && !org.orgList) {
      fetchCompaniesOperator();
    }
  }, [org, fetchCompaniesOperator]);

  useEffect(() => {
    return () => {
      clearOrgList();
    };
  }, [clearOrgList]);

  const headerFields = [
    { title: "Name", fieldname: "name" },
    { title: "Certification Programs", fieldname: "name" }, // TBD: remove the sorting or find a rule to sort this array
    { title: "Contact", fieldname: "contact" },
    { title: "Status", fieldname: "active" },
    { title: "", fieldname: "" }
  ];

  return (
    <OrgListGeneric
      org={org}
      headerFields={headerFields}
      rowRenderer={OrgOperatorRow}
    />
  );
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps, {
  setOrgType,
  fetchCompaniesOperator,
  clearOrgList
})(OrgsOperators);
