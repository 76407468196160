import React from "react";
import { connect } from "react-redux";
import Checkbox from "components/form/Checkbox";
import { IconLabel, Bubble } from "ctia-ui";
import { toggleCheckbox } from "actions/request";
import { isExisted, shouldDisableAgreement } from "helpers/RequestHelper";

const AgreementCheckbox = ({ request, user, toggleCheckbox, app }) => {
  const { isSaved, agreementAccepted } = request;

  if (isExisted(request) && !isSaved && agreementAccepted) {
    return (
      <h3 className="green">
        <IconLabel awesomeIcon="check-circle" />
        License Agreement Accepted
      </h3>
    );
  }

  if (user.isStaff) {
    return (
      <Bubble type="warning">Only vendor can accept the agreement.</Bubble>
    );
  }

  const isDisabled = shouldDisableAgreement(app, request);

  return (
    <Checkbox
      id="agreementAccepted"
      onChange={toggleCheckbox}
      value={agreementAccepted}
      editable={!isDisabled}
      label="I accept the license agreement terms and conditions"
    />
  );
};

function mapStateToProps({ request, user, app }) {
  return { request, user, app };
}

export default connect(mapStateToProps, {
  toggleCheckbox
})(AgreementCheckbox);
