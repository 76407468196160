import React from "react";
import PropTypes from "prop-types";

const CtiaButtonArrow = ({ direction }) => {
  const directionClass = direction === "left" ? "reflect-arrow" : "";

  return (
    <span className={`arrow-button__svg ${directionClass}`} aria-hidden="true">
      <svg
        width="30px"
        height="16px"
        viewBox="0 0 30 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        className={directionClass}
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-275.000000, -705.000000)">
            <g transform="translate(54.000000, 678.000000)">
              <g transform="translate(221.000000, 27.000000)">
                <polygon
                  className="fill"
                  fillRule="nonzero"
                  points="22.4419479 15.1560431 29.5999982 7.95604348 22.4419479 0.756043911 21.0592539 2.14874456 25.8530279 6.97064208 13.9566417 6.97064208 13.8881593 6.97064208 11.9999993 6.97064208 11.9999993 8.94144488 13.8881593 8.94144488 13.9566417 8.94144488 25.8530279 8.94144488 21.0592539 13.7633424"
                ></polygon>
                <rect
                  className="fill"
                  fillRule="nonzero"
                  x="7.99999952"
                  y="7.15604353"
                  width="1.5999999"
                  height="1.5999999"
                ></rect>
                <rect
                  className="fill"
                  fillRule="nonzero"
                  x="0"
                  y="7.15604353"
                  width="1.5999999"
                  height="1.5999999"
                ></rect>
                <rect
                  className="fill"
                  fillRule="nonzero"
                  x="3.99999976"
                  y="7.15604353"
                  width="1.5999999"
                  height="1.5999999"
                ></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};

CtiaButtonArrow.propTypes = {
  color: PropTypes.oneOf(["left", "right"])
};

CtiaButtonArrow.defaultProps = {
  direction: "right"
};

export default CtiaButtonArrow;
