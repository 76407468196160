import React from "react";
import PropTypes from "prop-types";
import { DownloadButton } from "ctia-ui";

const DownloadAllButton = ({ show, label, type, size, action }) => {
  if (!show) return null;

  return (
    <div className="text-center block-bottom-offset block-top-offset">
      <DownloadButton size={size} type={type} label={label} action={action} />
    </div>
  );
};

DownloadAllButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.any,
  type: PropTypes.string,
  size: PropTypes.string,
  action: PropTypes.func
};

DownloadAllButton.defaultProps = {
  show: false,
  label: "Download All",
  type: "danger",
  size: "large",
  action: () => {}
};

export default DownloadAllButton;
