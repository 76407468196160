import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import Steps, { Step } from "rc-steps";
import StepTitle from "./StepTitle";
import { changeWizardStep } from "actions/wizard";

import "./style.css";
import "./iconfont.css";

class Stepper extends Component {
  state = { size: "", direction: "vertical" };

  componentDidUpdate(prevProps) {
    const { app } = this.props;
    if (app.width !== prevProps.app.width) {
      this.updateState();
    }
  }

  updateState = () => {
    const { sm } = this.props.app;
    this.setState({
      size: sm ? "small" : ""
      // direction: lg ? 'vertical' : 'horizontal'
    });
  };

  validateWizard = () => {
    const { isSubmitted, isSaved } = this.props.wizard;
    return !isSubmitted && !isSaved;
  };

  renderSteps = () => {
    const { wizard, changeWizardStep } = this.props;
    const steps = wizard.steps;
    return _.keysIn(steps).map(stepId => {
      return (
        <Step
          key={stepId}
          title={
            <StepTitle
              title={steps[stepId].header}
              show={wizard.step >= Number(stepId)}
              handleLinkClick={() => changeWizardStep(stepId)}
            />
          }
        />
      );
    });
  };

  render() {
    // do not render stepper in case if wizard has been submitted/saved
    if (!this.validateWizard()) return null;

    /* 
      Should use wizard.progressStep to return back
      to old stepper logic and for use param inside
      StepTitle use this expression:
      wizard.progressStep >= stepElem.id
    */

    const { size, direction } = this.state;
    const { step } = this.props.wizard;

    return (
      <Steps
        current={step - 1}
        className="stepper-list"
        size={size}
        direction={direction}
      >
        {this.renderSteps()}
      </Steps>
    );
  }
}

function mapStateToProps({ wizard, app, request }) {
  return { wizard, app, request };
}

export default connect(mapStateToProps, { changeWizardStep })(Stepper);
