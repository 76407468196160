import * as actions from "actions/types";

// go to next step
const submitWizardStep = wizard => {
  const step = wizard.step + 1;
  const wProgressStep = wizard.progressStep;
  const progressStep =
    wizard.step === wProgressStep ? wProgressStep + 1 : wProgressStep;
  const isFinalStep = progressStep === wizard.totalSteps;

  return { ...wizard, step, progressStep, isFinalStep };
};

// common logic to update redux store
const updateWizard = (state, payload) => {
  const isFinalStep = payload.progressStep === state.totalSteps;
  return { ...state, ...payload, isFinalStep };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.INIT_WIZARD:
      return action.payload;
    case actions.SUBMIT_WIZARD_STEP:
      return submitWizardStep(state);
    case actions.SAVE_WIZARD:
    case actions.SUBMIT_WIZARD:
    case actions.CHANGE_WIZARD_STEP:
    case actions.UPDATE_WIZARD:
      return updateWizard(state, action.payload);
    case actions.CLEAR_WIZARD:
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
