import _ from "lodash";
import { formatZipCode } from "helpers/StringHelper";

export const prepareManufSiteAddressArgs = manufSite => {
  const { manufSiteCountry, manufSiteZip } = manufSite;
  const zipcode = formatZipCode(manufSiteCountry, manufSiteZip);

  return {
    args: {
      name: manufSite.manufSiteName,
      countrycode: manufSiteCountry,
      state: manufSite.manufSiteState,
      zipcode,
      city: manufSite.manufSiteCity,
      address1: manufSite.manufSiteLine1,
      address2: manufSite.manufSiteLine2
    }
  };
};

export const prepareManufSiteArgs = ({ addressId, name, ieee }) => {
  return {
    args: {
      name,
      description: name, // required by now, just clone name value ¯\_(ツ)_/¯
      addressid: addressId,
      manufsiteieeetypes: [{ ieeetypeid: ieee }]
    }
  };
};

export const prepareSARArgs = ({
  cdmaLab,
  pocId,
  type,
  manufSiteId,
  vendorNote,
  ieee,
  auditLinkedRequests,
  crslversion
}) => {
  const sarArgs = {
    args: {
      primarylabid: cdmaLab,
      pocid: pocId,
      requesttypeid: type,
      manufsiteid: Number(manufSiteId),
      ieeetypeid: ieee,
      vendornote: vendorNote,
      crslversion
    }
  };

  const checkedOnly = _.filter(auditLinkedRequests, ["checked", true]);

  sarArgs.args.linkedrequests = _.isEmpty(checkedOnly)
    ? []
    : checkedOnly.map(req => {
        return { linkedrequestid: req.value };
      });

  return sarArgs;
};

export const getRequestAuditFields = request => {
  const {
    id,
    crslversion,
    vendorNote,
    auditLinkedRequests,
    manufSiteId
  } = request;
  return {
    id,
    crslversion,
    vendorNote,
    auditLinkedRequests,
    manufSiteId
  };
};
