import React from "react";
import { connect } from "react-redux";
import { Col, Form, FormGroup } from "react-bootstrap";
import FormFields from "containers/request/Payments/PaymentFormFields";
import SubmitPayment from "containers/request/Payments/SubmitPayment";
import DownloadInvoice from "containers/request/Payments/DownloadInvoice";

const PaymentForm = ({ app, history }) => {
  const submitCallback = () => {
    history.push(`${app.urlPrefix}payments`);
  };

  return (
    <Form horizontal>
      <FormFields />
      <hr />
      <FormGroup>
        <Col xs={12} sm={9} md={9} mdOffset={3} smOffset={3}>
          <SubmitPayment submitCallback={submitCallback} />
          <DownloadInvoice />
        </Col>
      </FormGroup>
    </Form>
  );
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(PaymentForm);
