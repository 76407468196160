import * as actions from "actions/types";

const companiesInitialState = {
  labs: [],
  manufacturers: [],
  operators: [],
  suppliers: [],
  ptcrbLabs: []
};

const appendCompanyToStore = (state, payload) => {
  const { companyObject, companyType } = payload;
  let { labs, manufacturers, operators, ptcrbLabs, suppliers } = state;

  switch (companyType) {
    case "Lab":
      return {
        ...state,
        labs: [...labs, companyObject],
        ptcrbLabs: [...ptcrbLabs, companyObject]
      };
    case "Manufacturer":
      return { ...state, manufacturers: [...manufacturers, companyObject] };
    case "Operator":
      return { ...state, operators: [...operators, companyObject] };
    case "Supplier":
      return { ...state, suppliers: [...suppliers, companyObject] };
    default:
      return state;
  }
};

export default function (state = companiesInitialState, action) {
  switch (action.type) {
    case actions.CLEAR_ALL_COMPANIES:
      return companiesInitialState;
    case actions.APPEND_COMPANY_TO_STORE:
      return appendCompanyToStore(state, action.payload);
    case actions.FETCH_AUTHORIZED_LABS:
      return { ...state, labs: action.payload };
    case actions.FETCH_AUTHORIZED_MANUFACTURERS:
      return { ...state, manufacturers: action.payload };
    case actions.FETCH_AUTHORIZED_OPERATORS:
      return { ...state, operators: action.payload };
    case actions.FETCH_AUTHORIZED_PTCRB_LABS:
      return { ...state, ptcrbLabs: action.payload };
    case actions.FETCH_AUTHORIZED_SUPPLIERS:
      return { ...state, suppliers: action.payload };
    default:
      return state;
  }
}
