import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import { Form, FormGroup, Col } from "react-bootstrap";
import FormElement from "components/FormElement";
import RequestControls from "containers/request/RequestControls";
import PocAddBubble from "containers/request/Initial/Steps/Vendor/PocAddBubble";
import {
  pocFormValidate,
  makeEmailValidationPOC,
  makePhoneValidation
} from "helpers/ValidatorHelper";
import { getPocPermissions } from "helpers/PocHelper";
import { handleInputChange } from "actions/request";
import { createNewPoc } from "actions/requestCyber";

class PocAdd extends CyberComponent {
  pocHandler = (isUpdating = false) => {
    const { request, user, createNewPoc } = this.props;
    return createNewPoc(request, user, isUpdating);
  };

  onSaveClick = () => {
    return this.pocHandler(true);
  };

  onContinueClick = () => {
    return this.pocHandler();
  };

  render() {
    const { app, handleInputChange, request, show, user } = this.props;

    if (!show) return null;

    const emailValidation = makeEmailValidationPOC(request);
    const phoneValidation = makePhoneValidation(request.pocPhone);
    const extValidation = makePhoneValidation(request.pocPhoneExt);
    const isEditable = getPocPermissions(app, user);
    const saveHandler = isEditable ? this.onSaveClick : null;

    return (
      <div>
        <PocAddBubble stateHandler={() => this.props.toggleSelector(true)} />
        <Form horizontal>
          <FormElement
            type="text"
            id="pocFirstName"
            label="First Name"
            value={request.pocFirstName}
            onChange={handleInputChange}
            placeholder="First name for PoC"
            editable={isEditable}
            maxLength={50}
          />
          <FormElement
            type="text"
            id="pocLastName"
            label="Last Name"
            value={request.pocLastName}
            onChange={handleInputChange}
            placeholder="Last Name for PoC"
            editable={isEditable}
            maxLength={50}
          />
          <FormElement
            type="text"
            id="pocEmail"
            label="PoC Email"
            value={request.pocEmail}
            onChange={handleInputChange}
            placeholder="Please enter a valid email address"
            validationState={emailValidation.state}
            validationMessage={emailValidation.message}
            editable={isEditable}
            maxLength={50}
          />
          <FormGroup>
            <Col xs={7} sm={9} md={9} style={{ display: "inline-block"}}>
              <FormElement
                type="text"
                id="pocPhone"
                label="PoC Phone"
                value={request.pocPhone}
                onChange={handleInputChange}
                placeholder="Please enter a contact phone number"
                validationState={phoneValidation.state}
                validationMessage={phoneValidation.message}
                editable={isEditable}
                maxLength={18}
                labelColSizeSm={4}
                labelColSizeMd={4}
                inputColSizeSm={8}
                inputColSizeMd={8}
              />
            </Col>
            <Col xs={5} sm={3} md={3} style={{ display: "inline-block"}}>
              <FormElement
                type="text"
                id="pocPhoneExt"
                label="Ext."
                value={request.pocPhoneExt}
                onChange={handleInputChange}
                placeholder="Extension Number"
                editable={isEditable}
                maxLength={10}
                validationState={extValidation.state}
                validationMessage={extValidation.message}
              />
            </Col>
          </FormGroup>
        </Form>
        <RequestControls
          isValid={pocFormValidate(request)}
          onSave={saveHandler}
          onContinue={this.onContinueClick}
        />
      </div>
    );
  }
}

function mapStateToProps({ app, request, user, wizard }) {
  return { app, request, user, wizard };
}

export default connect(mapStateToProps, {
  handleInputChange,
  createNewPoc
})(PocAdd);
