import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { requestFields } from "helpers/BatterySupplierHelper";
import {
  formatRevisionsList,
  getPackRequestById
} from "helpers/SupplierHelper";
import { setValue } from "actions/request";

const SelectBatteryRevision = ({ request, suppliers, setValue }) => {
  const [show, setShow] = useState(false);
  const requestField = requestFields.revision;
  const packModel = request[requestFields.modelId];
  const { certifiedPackRequests: packs } = suppliers;

  useEffect(() => {
    if (packModel && packModel > 0) {
      setShow(true);
    } else {
      setShow(false);
      setValue(requestField, "");
    }
  }, [packModel, requestField, setValue]);

  const onChange = event => {
    const reqId = Number(event.target.value);

    setValue(requestField, reqId);

    const reqDetails = getPackRequestById(reqId, packs);

    if (reqDetails) {
      setValue(requestFields.linkedRequestId, reqDetails.id);
      setValue(requestFields.linkedRequestCapacity, reqDetails.capacity);
      setValue(requestFields.linkedRequestRevision, reqDetails.revision);
    } else {
      setValue(requestFields.linkedRequestId, null);
      setValue(requestFields.linkedRequestCapacity, null);
      setValue(requestFields.linkedRequestRevision, null);
    }
  };

  if (!show || !packs || !packs.length) return null;

  // do not render untill model be selected:
  if (!request[requestFields.model]) return null;

  return (
    <div>
      <FormElement
        type="select"
        id={requestField}
        onChange={onChange}
        label="Battery Revision"
        show={Number(request[requestFields.modelOption]) !== 2}
        options={formatRevisionsList(packs, request.batterySuppliers)}
        value={request[requestField] || 0}
      />
    </div>
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, { setValue })(SelectBatteryRevision);
