import _ from "lodash";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { emptyMsg, mustBeNumberMsg } from "helpers/FormHelper";
import { validateNumber } from "helpers/ValidatorHelper";

const RequestVoltage = ({
  isRequired = true,
  fieldId,
  fieldLabel,
  request,
  handleInputChange,
  shouldBeNumber = false,
  editable = true
}) => {
  const [touched, setTouched] = useState(false);
  const [validationMessage, setValidationMessage] = useState(emptyMsg);
  const [validationState, setValidationState] = useState(null);

  const inputValue = request && request[fieldId] ? request[fieldId][1] : "0";

  useEffect(() => {
    if (!touched && !_.isEmpty(inputValue)) {
      setTouched(true);
    }
  }, [touched, inputValue]);

  useEffect(() => {
    if (!editable || !touched || !isRequired) {
      setValidationState(null);
      return;
    }
    if (!_.isEmpty(inputValue)) {
      if (shouldBeNumber) {
        if (validateNumber(inputValue)) {
          setValidationState(null);
        } else {
          setValidationState("error");
          setValidationMessage(mustBeNumberMsg);
        }
        return;
      }
      setValidationState(null);
    } else {
      setValidationMessage(emptyMsg);
      setValidationState("error");
    }
  }, [inputValue, isRequired, shouldBeNumber, touched, editable]);

  return (
    <FormElement
      id={fieldId}
      label={fieldLabel}
      values={request[fieldId]}
      type="voltage"
      editable={editable && !request.isEco}
      onChange={handleInputChange}
      validationState={validationState}
      validationMessage={validationMessage}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { handleInputChange })(RequestVoltage);
