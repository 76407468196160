// put some common phrases to a dictionary
export const nav = {
  home: { id: "home", glyphIcon: "home", title: "Home", url: "/" },
  admin: { id: "admin", awesomeIcon: "cogs", title: "Admin", url: "/admin/cp" },
  pending: {
    id: "pending",
    glyphIcon: "hourglass",
    title: "Pending Requests"
  },
  submitNew: {
    id: "submit-new",
    glyphIcon: "pencil",
    title: "Submit New Request"
  },
  certified: {
    id: "certified",
    glyphIcon: "hdd",
    title: "Approved Requests"
  },
  labs: {
    id: "labs",
    awesomeIcon: "building",
    title: "Authorized Test Labs"
  },
  forTesting: {
    id: "for-testing",
    glyphIcon: "hourglass",
    title: "Requests for Testing"
  },
  labReports: {
    id: "lab-reports",
    glyphIcon: "paste",
    title: "Enter Test Results"
  },
  approve: {
    id: "approve",
    glyphIcon: "tasks",
    title: "Approve Request",
    badgeType: "approveCount"
  },
  approveBattery: {
    id: "approveBattery",
    glyphIcon: "tasks",
    title: "Approve Request",
    badgeType: "approveCountBattery"
  },
  viewBatterySubsystems: {
    id: "viewBatterySubsystems",
    glyphIcon: "tasks",
    title: "View Battery Subsystems",
    badgeType: "approveCountBattery"
  },
  viewAuthorizedSite: {
    id: "viewAuthorizedSite",
    glyphIcon: "tasks",
    title: "View Authorized Battery Cell Manufacturing Site",
    badgeType: "approveCountBattery"
  },
  viewRecognizedSubsystems: {
    id: "viewRecognizedSubsystems",
    glyphIcon: "tasks",
    title: "View Recognized Battery Subsystems",
    badgeType: "approveCountBattery"
  },
  payments: {
    id: "payments",
    awesomeIcon: "money-bill-alt",
    title: "Payments"
  },
  accountingReports: {
    id: "acc-reports",
    awesomeIcon: "paste",
    title: "Reports",
    url: "/accountant/reports"
  },
  accountingATL: {
    id: "acc-atl",
    awesomeIcon: "flask",
    title: "Lab Invoicing",
    url: "/accountant/atl"
  },
  accountingASC: {
    id: "acc-asc",
    awesomeIcon: "building",
    title: "ASC Invoicing",
    url: "/accountant/asc"
  },
  newUserRequests: {
    id: "new-user-requests",
    awesomeIcon: "user-plus",
    title: "New User Requests"
  }
};

/*************************************************
 * default homepage navigation (program selector homepage)
 */
export const homeNav = [nav.home];
export const homeNavStaff = [nav.home, nav.admin];
export const homeNavAccounting = [
  nav.home,
  nav.accountingReports,
  nav.accountingATL,
  nav.accountingASC
];

/*************************************************
 * cybersecurity certification navigation
 */
export const cyberVendorNav = [
  nav.home,
  {
    url: "/cyber/vendor/requests",
    ...nav.pending
  },
  {
    url: "/cyber/vendor/request/new",
    ...nav.submitNew
  },
  {
    url: "/cyber/devices",
    ...nav.certified
  },
  {
    url: "/cyber/labs",
    ...nav.labs
  }
];

export const cyberLabNav = [
  nav.home,
  {
    url: "/cyber/lab/requests",
    ...nav.forTesting
  },
  {
    url: "/cyber/lab/results",
    ...nav.labReports
  },
  {
    url: "/cyber/devices",
    ...nav.certified
  }
];

export const cyberStaffNav = [
  nav.home,
  {
    url: "/cyber/admin/requests",
    ...nav.pending
  },
  {
    url: "/cyber/admin/approval",
    ...nav.approve
  },
  {
    url: "/cyber/devices",
    ...nav.certified
  },
  {
    url: "/cyber/labs",
    ...nav.labs
  },
  nav.admin
];

export const cyberAccountantNav = [
  nav.home,
  {
    url: "/cyber/accountant/payments",
    ...nav.payments
  }
];

export const cyberOperatorNav = [
  nav.home,
  {
    url: "/cyber/devices",
    ...nav.certified
  },
  {
    url: "/cyber/labs",
    ...nav.labs
  }
];

/*************************************************
 * battery program navigation
 */
export const batteryStaffNav = [
  nav.home,
  {
    url: "/battery/admin/requests",
    ...nav.pending
  },
  {
    url: "/battery/admin/approval",
    ...nav.approveBattery
  },
  {
    url: "/battery/devices",
    ...nav.certified
  },
  {
    url: "/battery/labs",
    ...nav.labs
  },
  nav.admin
];

export const batteryVendorNav = [
  nav.home,
  {
    url: "/battery/vendor/requests",
    ...nav.pending
  },
  {
    url: "/battery/vendor/request/new",
    ...nav.submitNew
  },
  {
    url: "/battery/devices",
    ...nav.certified
  },
  {
    url: "/battery/labs",
    ...nav.labs
  }
];

export const batteryLabNav = [
  nav.home,
  {
    url: "/battery/lab/requests",
    ...nav.forTesting
  },
  {
    url: "/battery/lab/results",
    ...nav.labReports
  },
  {
    url: "/battery/devices",
    ...nav.certified
  },
  {
    url: "/battery/labs",
    ...nav.labs
  }
];

export const batteryAccountantNav = [
  nav.home,
  {
    url: "/battery/accountant/payments",
    ...nav.payments
  }
];

export const batteryOperatorNav = [
  nav.home,
  {
    url: "/battery/devices",
    ...nav.certified
  },
  {
    url: "/battery/labs",
    ...nav.labs
  }
];
export const batterySupplierNav = [
  nav.home,
  {
    url: "/battery/supplier/requests",
    ...nav.pending
  },
  {
    url: "/battery/supplier/request/new",
    ...nav.submitNew
  },
  {
    url: "/battery/devices",
    ...nav.certified
  },
  {
    url: "/battery/labs",
    ...nav.labs
  }
];

/*************************************************
 * OTA navigation
 */
export const otaVendorNav = [
  nav.home,
  {
    url: "/ota/vendor/requests",
    ...nav.pending
  },
  {
    url: "/ota/vendor/request/new",
    ...nav.submitNew
  },
  {
    url: "/ota/devices",
    ...nav.certified
  },
  {
    url: "/ota/labs",
    ...nav.labs
  }
];

export const otaLabNav = [
  nav.home,
  {
    url: "/ota/lab/requests",
    ...nav.forTesting
  },
  {
    url: "/ota/lab/results",
    ...nav.labReports
  },
  {
    url: "/ota/devices",
    ...nav.certified
  }
];

export const otaStaffNav = [
  nav.home,
  {
    url: "/ota/admin/requests",
    ...nav.pending
  },
  {
    url: "/ota/admin/approval",
    ...nav.approve
  },
  {
    url: "/ota/devices",
    ...nav.certified
  },
  {
    url: "/ota/labs",
    ...nav.labs
  },
  nav.admin
];

export const otaAccountantNav = [
  nav.home,
  {
    url: "/ota/accountant/payments",
    ...nav.payments
  }
];

export const otaOperatorNav = [nav.home];

/*************************************************
 * hardware reliability certification navigation
 */
export const dhrVendorNav = [
  nav.home,
  {
    url: "/dhr/vendor/requests",
    ...nav.pending
  },
  {
    url: "/dhr/vendor/request/new",
    ...nav.submitNew
  },
  {
    url: "/dhr/devices",
    ...nav.certified
  },
  {
    url: "/dhr/labs",
    ...nav.labs
  }
];

export const dhrLabNav = [
  nav.home,
  {
    url: "/dhr/lab/requests",
    ...nav.forTesting
  },
  {
    url: "/dhr/lab/results",
    ...nav.labReports
  },
  {
    url: "/dhr/devices",
    ...nav.certified
  }
];

export const dhrStaffNav = [
  nav.home,
  {
    url: "/dhr/admin/requests",
    ...nav.pending
  },
  {
    url: "/dhr/admin/approval",
    ...nav.approve
  },
  {
    url: "/dhr/devices",
    ...nav.certified
  },
  {
    url: "/dhr/labs",
    ...nav.labs
  },
  nav.admin
];

export const dhrAccountantNav = [
  nav.home,
  {
    url: "/dhr/accountant/payments",
    ...nav.payments
  }
];

export const dhrOperatorNav = [
  nav.home,
  {
    url: "/dhr/devices",
    ...nav.certified
  },
  {
    url: "/dhr/labs",
    ...nav.labs
  }
];

/*************************************************
 * Battery Life Certification navigation
 */
export const blcVendorNav = [
  nav.home,
  {
    url: "/blc/vendor/requests",
    ...nav.pending
  },
  {
    url: "/blc/vendor/request/new",
    ...nav.submitNew
  },
  {
    url: "/blc/devices",
    ...nav.certified
  },
  {
    url: "/blc/labs",
    ...nav.labs
  }
];

export const blcLabNav = [
  nav.home,
  {
    url: "/blc/lab/requests",
    ...nav.forTesting
  },
  {
    url: "/blc/lab/results",
    ...nav.labReports
  },
  {
    url: "/blc/devices",
    ...nav.certified
  }
];

export const blcStaffNav = [
  nav.home,
  {
    url: "/blc/admin/requests",
    ...nav.pending
  },
  {
    url: "/blc/admin/approval",
    ...nav.approve
  },
  {
    url: "/blc/devices",
    ...nav.certified
  },
  {
    url: "/blc/labs",
    ...nav.labs
  },
  nav.admin
];

export const blcAccountantNav = [
  nav.home,
  {
    url: "/blc/accountant/payments",
    ...nav.payments
  }
];

export const blcOperatorNav = [
  nav.home,
  {
    url: "/blc/devices",
    ...nav.certified
  },
  {
    url: "/blc/labs",
    ...nav.labs
  }
];
