import React from "react";
import PropTypes from "prop-types";
import Button from "./FormButton";
import { IconLabel } from "ctia-ui";

const AddButton = ({ label, ...rest }) => {
  return (
    <Button
      bsStyle="warning"
      text={<IconLabel label={label} awesomeIcon="plus" />}
      {...rest}
    />
  );
};

AddButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string
};

AddButton.defaultProps = {
  show: true,
  label: "Add"
};

export default AddButton;
