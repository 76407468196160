import React from "react";
import { connect } from "react-redux";
import OtaDefaultContent from "containers/request/OTA/OtaDefaultContent";
import { getOtaDefaultTabs } from "helpers/RequestTabsHelper";
import { getOtaChecklist } from "helpers/ChecklistHelper";

const OtaRequestDetails = ({ request, tests }) => {
  const checkList = getOtaChecklist(request, tests);

  return <OtaDefaultContent tabs={getOtaDefaultTabs()} checkList={checkList} />;
};

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps)(OtaRequestDetails);
