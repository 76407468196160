import React from "react";
import { connect } from "react-redux";
import CatlLogoCanvas from "components/Canvas/CatlLogoCanvas";

const CatlLogo = ({ user, atlCode }) => {
  const facilityCode = user && user.isLab && user.CATLCode ? user.CATLCode : atlCode;

  if (facilityCode) {
    return <CatlLogoCanvas labCode={facilityCode} />;
  }

  return null;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(CatlLogo);
