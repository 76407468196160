import { formatDate } from "helpers/DateHelper";
import { makeSimpleArgs, prepareVoltageCapacityArgs } from "helpers/ArgsHelper";
import { filterCheckedOnlyValues } from "helpers/ArrayHelper";
import { getSelectedManufSitesIds } from "helpers/ManufSiteHelper";
import {
  getRecognitionOnly,
  getNonRecognitionOnly
} from "helpers/PendingRecognition";

export const prepareInitialArgsEPR = request => {
  const voltageCapacity = prepareVoltageCapacityArgs(request);
  const basicFields = {
    billingid: Number(request.billingId),
    ieeetypeid: request.ieee,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    purchaseorder: request.billingOrderNum,
    requesttypeid: 1,
    revision: request.revision,
    // non SAR manuf sites:
    requestmanufsites: getSelectedManufSitesIds(request.manufSites),
    // Pack Cells with pending recognition:
    requestpendingrecognitions: getRecognitionOnly(request.cellSuppliers),
    // Pack Cells with approved device:
    linkedrequests: getNonRecognitionOnly(request.cellSuppliers)
  };

  if (request.existedModelId) {
    return {
      body: makeSimpleArgs({
        ...basicFields,
        devicemodelid: request.existedModelId,
        devicemodel: {
          id: request.existedModelId,
          devicemodelbatterycomponent: {
            ...voltageCapacity
          }
        }
      }),
      url: "eprr/requests"
    };
  }

  return {
    body: makeSimpleArgs({
      requests: [basicFields],
      modelnumber: request.modelName,
      devicemodelbatterycomponent: {
        ...voltageCapacity
      }
    }),
    url: "eprr/devicemodels"
  };
};

export const prepareEcoArgsEPR = request => {
  return makeSimpleArgs({
    requesttypeid: 2,
    pocid: Number(request.pocId),
    primarylabid: request.cdmaLab,
    revision: request.revision,
    descriptionofchange: request.descriptionOfChange,
    requestmanufsites: getSelectedManufSitesIds(request.linkedManufsites),
    parentid: request.parentRequest.id,
    devicemodelid: request.parentRequest.devicemodelid,
    ieeetypeid: request.ieee,
    // Pack Cells with pending recognition:
    requestpendingrecognitions: getRecognitionOnly(request.cellSuppliers),
    // Pack Cells with approved device:
    linkedrequests: getNonRecognitionOnly(request.cellSuppliers)
  });
};

export const getRequestFieldsForTestingEPR = ({
  id,
  revision,
  crslversion
}) => {
  return makeSimpleArgs({
    requestId: id,
    revision,
    crslversion
  });
};

export const getTestFieldsEPR = testModule => {
  const {
    id,
    actualstartdate,
    actualenddate,
    requesttestpassedstatusid
  } = testModule;

  // @NOTE: All battery types require testing dates range
  const dates = {
    actualstartdate: formatDate(actualstartdate),
    actualenddate: formatDate(actualenddate)
  };

  return {
    id,
    ...dates,
    requesttestpassedstatusid
  };
};

export const prepareModelArgsEPR = request => {
  const voltageCapacity = prepareVoltageCapacityArgs(request);

  return makeSimpleArgs({
    modelId: request.model.id,
    modelnumber: request.modelName,
    devicemodelbatterycomponent: {
      ...voltageCapacity
    }
  });
};

export const getDeviceFieldsForTestingEPR = request => {
  const voltageCapacity = prepareVoltageCapacityArgs(request);

  return makeSimpleArgs({
    modelId: request.model.id,
    devicemodelbatterycomponent: { ...voltageCapacity }
  });
};

export const prepareRequestArgs = (request, sites) => {
  const { id, revision, descriptionOfChange, crslversion } = request;

  return {
    id,
    revision,
    crslversion,
    descriptionOfChange,
    urlPrefix: "eprr",
    // non SAR manuf sites:
    requestmanufsites: getSelectedManufSitesIds(sites),
    // Pack Cells with pending recognition:
    requestpendingrecognitions: getRecognitionOnly(request.cellSuppliers),
    // Pack Cells with approved device:
    linkedrequests: getNonRecognitionOnly(request.cellSuppliers)
  };
};

const getLinkedNonSarSites = request => {
  if (request && request.linkedManufsites) {
    return filterCheckedOnlyValues(
      request.linkedManufsites,
      "manufsiteid",
      "value"
    );
  }

  return [];
};

export const prepareTestResultsArgs = (request, testModule, certTypes) => {
  const requestmanufsites = getLinkedNonSarSites(request);
  const testArgs = getTestFieldsEPR(testModule);

  const voltageCapacity = prepareVoltageCapacityArgs(request);
  const devicemodelbatterycomponent = { ...voltageCapacity };

  const { model, crslversion, revision } = request;

  const args = {
    devicemodel: {
      id: model.id,
      devicemodelbatterycomponent
    },
    crslversion,
    revision,
    // non SAR manuf sites:
    requestmanufsites,
    // Pack Cells with pending recognition:
    requestpendingrecognitions: getRecognitionOnly(request.cellSuppliers),
    // Pack Cells with approved device:
    linkedrequests: getNonRecognitionOnly(request.cellSuppliers),
    requesttests: [
      {
        ...testArgs
      }
    ]
  };

  return makeSimpleArgs(args);
};
