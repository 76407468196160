import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import TestResultsInfoBarDHR from "containers/request/DHR/TestResultsInfoBarDHR";
import { LoaderButton } from "ctia-ui";
import TestResultPanelBattery from "components/TestResultPanelBattery";
import PageTitle from "components/RequestPageTitle";
import LabResultFormDHR from "containers/testResult/LabResultFormDHR";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewViewDHR from "containers/testResult/UnderReviewViewDHR";
import GoBackButton from "components/form/buttons/GoBackButton";
import { hasPreparedResults, isComplete } from "helpers/RequestTestsHelper";
import { clearRequest } from "actions/request";
import { downloadReportDHR } from "actions/dhr/dhrRequest";
import { loadTestResults, submitTestResultsDHR } from "actions/tests";

const backURL = "/dhr/lab/results";

class TestResultsItemDHR extends Component {
  state = { showContent: false };

  componentDidMount() {
    const { match, loadTestResults } = this.props;
    this.resetRender();
    loadTestResults(match.params.id).then(this.activateRender);
  }

  componentWillReceiveProps(nextProps) {
    const { match, loadTestResults } = this.props;
    const newId = nextProps.match.params.id;
    if (newId !== match.params.id) {
      loadTestResults(newId).then(this.activateRender);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, hwVersion } = request;
    const { reports, planversion, requesttestpassedstatusid } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 && planversion !== "" && hwVersion !== "";

    if (!commonFieldsValidate) return false;

    return hasPreparedResults(testResults) || reports.length;
  }

  onSubmit = () => {
    const { request, tests, submitTestResultsDHR } = this.props;
    return submitTestResultsDHR(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push(backURL);
  };

  render() {
    const { request, tests, downloadReportDHR } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanelBattery />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest={backURL} label="Return to list" />
        <TestResultsInfoBarDHR />
        <UnderReviewViewDHR show={completed} testModule={testModule} />
        <LabResultFormDHR show={!completed} testModule={testModule} />
        <LabResultUpload
          show={!completed}
          downloadHandler={downloadReportDHR}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  loadTestResults,
  submitTestResultsDHR,
  downloadReportDHR
})(TestResultsItemDHR);
