import _ from "lodash";
import * as actions from "actions/types";
import * as app from "helpers/AppHelper";
import { getUrlPrefix } from "helpers/UrlHelper";
import { setUserInfo, getUserTypeTitle } from "helpers/UsersHelper";
import {
  getRequestStatusGroup,
  getStatusTitle,
  getBatteryStatusGroup
} from "helpers/RequestHelper";
import { getTestStatusGroup } from "helpers/RequestTestsHelper";
import { getSession } from "libs/sessionStorage";
import * as ls from "libs/localStorage";

const resize = () => {
  const width = window.innerWidth;
  const xl = width > 1499;
  const lg = width < 1500;
  const sm = width < 1024;
  const xs = width < 900;
  const xss = width < 700;
  return {
    height: window.innerHeight,
    width,
    xl,
    lg,
    sm,
    xs,
    xss
  };
};

const setRequestStatus = status => {
  const title = getStatusTitle(status);
  const filter = getRequestStatusGroup(status);

  return { id: status, title, filter };
};

const setTestStatus = status => {
  return {
    title: status,
    filter: getTestStatusGroup(status)
  };
};

const setBatteryRequestStatus = status => {
  const title = getStatusTitle(status);
  const filter = getBatteryStatusGroup(status);

  return { id: status, title, filter };
};

const setUrlPrefix = (action, state) => {
  if (!action.payload) return state;

  const certProgram = app.defineCertProgram(state);
  const user = setUserInfo(action.payload);
  const urlPrefix = getUrlPrefix(user, certProgram);
  const programPrefix = app.getProgramPrefix(certProgram);

  return { ...state, urlPrefix, programPrefix };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.RESIZE_WINDOW:
      const windowProps = resize();
      return { ...state, ...windowProps };
    case actions.TOGGLE_VIEW_MODE:
      return { ...state, compactView: !state.compactView };
    case actions.RESET_CERT_PROGRAM_STATUS:
      ls.setValue("certProgram", 0);
      ls.setValue("prefixProgram", "");

      return {
        ...state,
        certProgram: 0,
        isNoneCert: true,
        isCyberCert: false,
        isBatteryCert: false,
        isBLCCert: false,
        isOTACert: false,
        isDHRCert: false
      };
    case actions.CHANGE_CERT_PROGRAM:
      const programId = action.payload;

      // empty program id passed:
      if (!programId) return state;

      const id = Number(programId);

      // unknown program id passed:
      if (!_.includes(app.certProgramms, id)) return state;

      // update url prefix
      const sessionUser = getSession("user");
      const newPrefix = getUrlPrefix(sessionUser, id);
      const programPrefix = app.getProgramPrefix(id);

      // update program id at browser storage
      ls.setValue("certProgram", id);
      localStorage.setItem("prefixProgram", programPrefix);

      return {
        ...state,
        certProgram: id,
        isNoneCert: false,
        isCyberCert: app.isCyberCert(id),
        isBatteryCert: app.isBatteryCert(id),
        isBLCCert: app.isBLCCert(id),
        isOTACert: app.isOTACert(id),
        isDHRCert: app.isDHRCert(id),
        urlPrefix: newPrefix,
        programPrefix: programPrefix
      };
    case actions.GET_USER_INFO:
      return setUrlPrefix(action, state);
    case actions.SET_PAGE_SIZE:
      const pageSize = action.payload;

      // save a default value to restore next time
      ls.setValue("defaultPageSize", pageSize);

      return { ...state, pageSize };
    case actions.CHANGE_CYBER_REQUEST_STATUS:
      const cyberRequestStatus = setRequestStatus(action.payload);
      return {
        ...state,
        cyberRequestStatus
      };
    case actions.CHANGE_OTA_REQUEST_STATUS:
      const otaRequestStatus = setRequestStatus(action.payload);
      return {
        ...state,
        otaRequestStatus
      };
    case actions.CHANGE_BATTERY_REQUEST_STATUS:
      const batteryRequestStatus = setBatteryRequestStatus(action.payload);
      return {
        ...state,
        batteryRequestStatus
      };
    case actions.CHANGE_CYBER_REQUEST_TEST_STATUS:
      const cyberRequestTestStatus = setTestStatus(action.payload);
      return {
        ...state,
        cyberRequestTestStatus
      };
    case actions.CHANGE_OTA_REQUEST_TEST_STATUS:
      const otaRequestTestStatus = setTestStatus(action.payload);
      return {
        ...state,
        otaRequestTestStatus
      };
    case actions.CHANGE_BATTERY_REQUEST_TEST_STATUS:
      const batteryRequestTestStatus = setTestStatus(action.payload);
      return {
        ...state,
        batteryRequestTestStatus
      };
    case actions.CHANGE_DHR_REQUEST_TEST_STATUS:
      const dhrRequestTestStatus = setTestStatus(action.payload);
      return {
        ...state,
        dhrRequestTestStatus
      };
    case actions.CHANGE_CYBER_ADMIN_USER_TYPE:
      const userType = action.payload;
      const userTypeTitle = getUserTypeTitle(userType);
      return {
        ...state,
        cyberAdminUsers: { userType, title: userTypeTitle }
      };
    case actions.SET_REQUEST_VIEW_ACTIVE_TAB:
      const requestView = { ...state.requestView, activeTab: action.payload };
      return { ...state, requestView };
    case actions.SET_REQUEST_LIST_ACTIVE_TAB:
      const requestListView = {
        ...state.requestListView,
        activeTab: action.payload
      };
      return { ...state, requestListView };
    case actions.START_SUBMITTING:
      return { ...state, isSubmitting: true };
    case actions.STOP_SUBMITTING:
      return { ...state, isSubmitting: false };
    case actions.START_SAVING:
      return { ...state, isSaving: true };
    case actions.STOP_SAVING:
      return { ...state, isSaving: false };
    case actions.GET_CERT_TYPES:
      const certTypes = action.payload;
      // set value as Local Storage to be able to restore it later
      ls.setValue("certTypes", certTypes);

      const certTypesNamed = app.getNamedCertTypes(certTypes);

      // shorthands for cert type ids
      const idACR = certTypesNamed["ACR"].id;
      const idARR = certTypesNamed["ARR"].id;
      const idBLC = certTypesNamed["BLC"].id;
      const idBSC = certTypesNamed["BSC"].id;
      const idBSR = certTypesNamed["BSR"].id;
      const idCBRS = certTypesNamed["CBRS"].id;
      const idCRR = certTypesNamed["CRR"].id;
      const idCYBER = certTypesNamed["CYBER"].id;
      const idDHR = certTypesNamed["DHR"].id;
      const idEPRR = certTypesNamed["EPRR"].id;
      const idHRR = certTypesNamed["HRR"].id;
      const idOTA = certTypesNamed["OTA"].id;
      const idPRR = certTypesNamed["PRR"].id;
      const idSAR = certTypesNamed["SAR"].id;

      //
      ls.setValue("idACR", idACR);
      ls.setValue("idARR", idARR);
      ls.setValue("idBLC", idBLC);
      ls.setValue("idBSC", idBSC);
      ls.setValue("idBSR", idBSR);
      ls.setValue("idCBRS", idCBRS);
      ls.setValue("idCRR", idCRR);
      ls.setValue("idCYBER", idCYBER);
      ls.setValue("idEPRR", idEPRR);
      ls.setValue("idHRR", idHRR);
      ls.setValue("idOTA", idOTA);
      ls.setValue("idPRR", idPRR);
      ls.setValue("idSAR", idSAR);

      // shorthand for PTCRB site URL
      const ptcrbURL = certTypesNamed["PTCRB"].url;
      ls.setValue("ptcrbURL", ptcrbURL);

      return {
        ...state,
        certTypes,
        certTypesNamed,
        ptcrbURL,
        idACR,
        idARR,
        idBLC,
        idBSC,
        idBSR,
        idCBRS,
        idCRR,
        idCYBER,
        idDHR,
        idEPRR,
        idHRR,
        idOTA,
        idPRR,
        idSAR
      };
    case actions.TOGGLE_USER_STATUS:
      const showOnlyActiveUsers = !state.showOnlyActiveUsers;
      return { ...state, showOnlyActiveUsers };
    default:
      return state;
  }
}
