export default [
  {
    title: '--- Select One ---',
    value: ''
  },
  {
    title: 'ACH/Wire',
    value: 'ACH/Wire'
  },
  {
    title: 'Check',
    value: 'Check'
  },
  {
    title: 'Credit Card',
    value: 'Credit Card'
  },
  {
    title: 'Credit/Transfer',
    value: 'Credit/Transfer'
  },
  {
    title: 'Prepayment',
    value: 'Prepayment'
  },
  {
    title: 'Other',
    value: 'Other'
  }
];
