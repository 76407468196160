import _ from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";

import { Col, Form, Row } from "ctia-react-bootstrap-v4";
import { Divider } from "ctia-ui";

import CompanyInterfaces from "dictionaries/CompanyInterfaces";
import { selectedCountryShouldRequireStateOrProvince } from "helpers/Countries";
import { formatDate } from "helpers/DateHelper";
import { toTitleCase } from "helpers/StringHelper";

import CatlLogo from "components/CatlLogo";
import CatlLogoPreview from "components/ProgramSelector/CatlLogoPreview";
import DatePicker from "components/form/Date";
import FormFieldLabel from "components/form/FormFieldLabel";
import CountrySelector from "components/CountrySelector/CountrySelector";
import StateAndProvinceSelector from "components/StateAndProvinceSelector";
import CertificationsSelectors from "components/Companies/AddCompanyModal/components/LabCertificationsListSelector";
import TestingCapabilitySelectors from "components/Companies/AddCompanyModal/components/LabAuthorizedTestingCapabilitySelectors";
import LabIEEETypeSelectors from "components/Companies/AddCompanyModal/components/LabIEEETypeSelectors";
import PTCRBLogo from "components/PTCRBLogo";
import PTCRBaLogo from "components/PTCRBaLogo";
import IoTLogo from "components/IoTLogo";

const EditLabForm = ({ data = {}, validateForm = () => {}, users }) => {
  const {
    address1 = "",
    address2 = "",
    address3 = "",
    ATLCode = "",
    citydisplay = "",
    comments = "",
    companycerttypes = [],
    companyinterfaces = [],
    contactid = "",
    id: companyId = "",
    ieee1625Authorized = false,
    ieee1725Authorized = false,
    name = "",
    zipcode = ""
  } = data;

  const certTypeIds = companycerttypes.map(entry => entry.certtypeid);
  const interfaceIds = companyinterfaces.map(entry => entry.interfaceid);
  const requiresIEEE =
    _.includes(interfaceIds, CompanyInterfaces.batteryCompliance.id) ||
    _.includes(interfaceIds, CompanyInterfaces.batterySiteAudit.id);

    const [formState, setFormState] = useState({
    country: data.country,
    authDate: data.ATLlicensedate ? moment(data.ATLlicensedate) : null,
    requiresIEEE,
    state: data.state,
    status:
      data.ATLstatus && data.ATLstatus.toUpperCase() === "N/A"
        ? data.ATLstatus.toUpperCase()
        : toTitleCase(data.ATLstatus)
  });

  const handleFieldChangeRequiringState = event => {
    const { id, value } = event.target;
    setFormState(currData => {
      switch (id) {
        case "authDate":
          return { ...currData, authDate: value };
        case "country":
          return { ...currData, country: value, state: "" };
        case "requiresIEEE":
          return { ...currData, requiresIEEE: value };
        case "state":
          return { ...currData, state: value };
        case "status":
          const showAuthDate = value === "Active";
          return {
            ...currData,
            authDate: showAuthDate ? moment() : "",
            status: value,
            showAuthDate
          };
        default:
          return currData;
      }
    });
  };

  const renderAuthDate = () => {
    if (formState.status === "Active") {
      return (
        <>
          <Form.Label>
            <FormFieldLabel label="ATL Authorization Date" isRequired />
          </Form.Label>
          <DatePicker
            id="authDatePicker"
            editable={true}
            onChange={momentDate =>
              handleFieldChangeRequiringState({
                target: {
                  id: "authDate",
                  value: momentDate
                }
              })
            }
            value={formState.authDate}
          />
          {/*Hide this on the form to store the date for form submission.*/}
          {/*Date picker is not recognized as a valid form control.*/}
          <input
            type="hidden"
            id="authDate"
            value={formatDate(formState.authDate)}
          />
        </>
      );
    } else if (!_.isEmpty(formState.authDate)) {
      return (
        <Form.Group controlId="authDate">
          <Form.Label>
            <FormFieldLabel label="ATL Authorization Date" isRequired={false} />
          </Form.Label>
          <p className="dark-teal bold form-control-static">
            {formatDate(formState.authDate)}
          </p>
        </Form.Group>
      );
    }

    return null;
  };

  const renderPocs = () => {
    // @TODO: set the flag value based on POC list length (`false` if list is empty)
    const hasContacts = users && users.list && users.list.length > 0;

    if (hasContacts) {
      return (
        <Form.Control as="select" defaultValue={contactid}>
          {users.list.map(user => {
            return (
              <option
                key={user.ctiaid}
                value={user.ctiaid}
                data-name={user.fullname}
                data-email={user.email}
              >
                {user.fullname}
              </option>
            );
          })}
        </Form.Control>
      );
    }

    return (
      <span className="icon-left-offset form-warn">
        No contacts assigned to the company yet.
      </span>
    );
  };

  return (
    <Row>
      <Col md={6} xs={12}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormFieldLabel label="Name" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            placeholder="Enter organization name"
            maxLength={100}
            defaultValue={name}
          />
          <Form.Control.Feedback type="invalid">
            Organization name is required.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="address">
          <Form.Label>
            <FormFieldLabel label="Address 1" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            placeholder="Enter Address line 1"
            defaultValue={address1}
          />
        </Form.Group>
        <Form.Group controlId="address2">
          <Form.Label>
            <FormFieldLabel label="Address 2" />
          </Form.Label>
          <Form.Control
            placeholder="Enter Address line 2"
            defaultValue={address2}
          />
        </Form.Group>
        <Form.Group controlId="address3">
          <Form.Label>
            <FormFieldLabel label="Address 3" />
          </Form.Label>
          <Form.Control
            placeholder="Enter Address line 3"
            defaultValue={address3}
          />
        </Form.Group>
        <Form.Group controlId="country">
          <Form.Label>
            <FormFieldLabel label="Country" isRequired={true} />
          </Form.Label>
          <CountrySelector
            id="country"
            onChange={handleFieldChangeRequiringState}
            value={formState.country}
          />
        </Form.Group>
        <Form.Group controlId="city">
          <Form.Label>
            <FormFieldLabel label="City" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            placeholder="Enter City"
            defaultValue={citydisplay}
          />
        </Form.Group>
        <Form.Group controlId="state">
          <Form.Label>
            <FormFieldLabel
              label="State"
              isRequired={selectedCountryShouldRequireStateOrProvince(
                formState.country
              )}
            />
          </Form.Label>
          <StateAndProvinceSelector
            id="state"
            onChange={handleFieldChangeRequiringState}
            selectedCountryCode={formState.country}
            value={formState.state}
          />
        </Form.Group>
        <Form.Group controlId="zipCode">
          <Form.Label>
            <FormFieldLabel label="Zip/Postal Code" />
          </Form.Label>
          <Form.Control
            placeholder="Enter Zip or Postal Code"
            defaultValue={zipcode}
          />
        </Form.Group>
        <Form.Group controlId="contact">
          <Form.Label>
            <FormFieldLabel label="Contact" />
          </Form.Label>
          {renderPocs()}
        </Form.Group>
        <Form.Group controlId="disableReason">
          <Form.Label>Reason for Disabling</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter the reason for disabling"
            defaultValue={comments}
            style={{ height: 225 }}
          />
        </Form.Group>
        <hr />
        <Form.Group controlId="status">
          <Form.Label>
            <FormFieldLabel label="ATL Status" isRequired={true} />
          </Form.Label>
          <Form.Control
            required
            as="select"
            isInvalid={
              _.isEmpty(formState.status) ||
              _.isNull(formState.status) ||
              formState.status === "--- Select One ---"
            }
            onChange={handleFieldChangeRequiringState}
            value={formState.status}
          >
            <option value="">--- Select One ---</option>
            <option value="Active">Active</option>
            <option value="Expired">Expired</option>
            <option value="N/A">N/A</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="authDate">{renderAuthDate()}</Form.Group>
        <Row>
            <Col md={6}>
                <CatlLogoPreview companyId={companyId} certTypes={certTypeIds}/>
            </Col>
            <Col md={6}></Col>
        </Row>
        <Row>
            <hr />
        </Row>
        {ATLCode && _.includes(certTypeIds, 1) && (
          <>
            <Row>
              <Col md={6}>
                <CatlLogo atlCode={ATLCode} />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
                <hr />
            </Row>
          </>
        )}
        {ATLCode && _.includes(certTypeIds, 41) && (
          <>
            <Row>
              <Col md={6}>
                <IoTLogo atlCode={ATLCode} />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
                <hr />
            </Row>
          </>
        )}
        {ATLCode && _.includes(certTypeIds, 2) && (
          <>
            <Row>
              <Col md={6}>
                <PTCRBLogo atlCode={ATLCode} />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
                <hr />
            </Row>
          </>
        )}
        {ATLCode &&  _.includes(certTypeIds, 3) && (
          <>
            <Row>
              <Col md={6}>
                <PTCRBaLogo atlCode={ATLCode} />
              </Col>
              <Col md={6}></Col>
            </Row>
            <Row>
                <hr />
            </Row>
          </>
        )}
      </Col>
      <Col md={6} xs={12}>
        <Form.Group controlId="testingCapabilities">
          <Form.Label>
            <FormFieldLabel label="Certification" isRequired={true} />
          </Form.Label>
          <CertificationsSelectors checkedIdsOnInit={certTypeIds} />
          <Divider />
          <Form.Label>
            <FormFieldLabel
              label="IEEE Type"
              isRequired={formState.requiresIEEE}
            />
          </Form.Label>
          <LabIEEETypeSelectors
            default1625Checked={ieee1625Authorized}
            default1725Checked={ieee1725Authorized}
            onChange={handleFieldChangeRequiringState}
            requiresIEEE={formState.requiresIEEE}
          />
          <Divider />
          <Form.Label>
            <FormFieldLabel
              label="Authorized Testing Capability"
              isRequired={true}
            />
          </Form.Label>
          <TestingCapabilitySelectors
            checkedIdsOnInit={interfaceIds}
            onComponentReady={() => validateForm()}
            onRequiresIEEEType={isRequired =>
              handleFieldChangeRequiringState({
                target: {
                  id: "requiresIEEE",
                  value: isRequired
                }
              })
            }
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

EditLabForm.propTypes = {
  data: PropTypes.object,
  handleFieldChange: PropTypes.func,
  users: PropTypes.object
};

function mapStateToProps({ users }) {
  return { users };
}

export default connect(mapStateToProps, null)(EditLabForm);
