import React from "react";
import { connect } from "react-redux";
import { downloadAllOtaReports } from "actions/tests";
import DownloadAllButton from "components/form/buttons/DownloadAllButton";

const OtaReportDownloadAll = ({
  show,
  request,
  testModule,
  downloadAllOtaReports
}) => {
  if (!show) return null;

  return (
    <DownloadAllButton
      show={true}
      action={() => downloadAllOtaReports(request, testModule)}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, {
  downloadAllOtaReports
})(OtaReportDownloadAll);
