import FormElement from "components/FormElement";
import React from "react";
import { requestStatuses } from "helpers/RequestHelper";

const RequestStatusSelector = ({ onChange, value }) => {
  const buttonValues = [
    {
      label: "Certified",
      id: requestStatuses.approved
    },
    {
      label: "Pending",
      id: requestStatuses.pending
    }
  ];

  return (
    <FormElement
      id="requestStatusSelector"
      label="Request Status"
      list={buttonValues}
      onChange={onChange}
      type="toggleRadioGroup"
      value={value}
    />
  );
};

export default RequestStatusSelector;
