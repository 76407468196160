import React from "react";
import { connect } from "react-redux";
import CyberComponent from "containers/CyberComponent";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";

class BillingPurchaseOrder extends CyberComponent {
  render() {
    const { request, handleInputChange, isEditable } = this.props;
    const elemType = isEditable ? "text" : "";

    return (
      <FormElement
        type={elemType}
        id="billingOrderNum"
        label="Purchase Order Number"
        value={request.billingOrderNum}
        onChange={handleInputChange}
        placeholder="If applicable"
        editable={isEditable}
        maxLength={50}
      />
    );
  }
}

// keep user for component re-render logic
function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleInputChange
})(BillingPurchaseOrder);
