import React, { Component } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/sitesSar";
import { clearCountryState, fetchCountries } from "actions/countries";
import { getCountriesList } from "helpers/Countries";

class SiteAddressCountry extends Component {
  componentDidMount() {
    this.props.fetchCountries();
  }

  onCountryChange = event => {
    // need to clear country state when country changed
    this.props.clearCountryState();

    // now we can handle country as well
    this.props.handleInputChange(event);
  };

  render() {
    const { user, countries, sites } = this.props;

    const disabled = !countries || !countries.list;
    const list = disabled ? [] : getCountriesList(countries.list);
    const isEditable = user.canEditManufsite && !disabled;

    if (disabled) {
      return (
        <FormElement
          type="text"
          label="Country"
          editable={false}
          placeholder="loading..."
        />
      );
    }

    return (
      <FormElement
        type="select"
        id="manufSiteCountry"
        label="Country"
        value={sites.current.manufSiteCountry}
        onChange={this.onCountryChange}
        options={list}
        editable={isEditable}
      />
    );
  }
}

function mapStateToProps({ sites, user, countries }) {
  return { sites, user, countries };
}

export default connect(mapStateToProps, {
  handleInputChange,
  clearCountryState,
  fetchCountries
})(SiteAddressCountry);
