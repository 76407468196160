import _ from "lodash";
import React from "react";
import ReqLink from "components/RequestsLink";
import PropTypes from "prop-types";
import { getTestResultTitle } from "helpers/RequestTestsHelper";

const LabListRowOta = ({ tableRows }) => {
  if (!tableRows) return null;

  return _.map(tableRows, row => {
    const tn = row.formatedtransactionnumber;
    const testStatus = getTestResultTitle(row.testingstatus);

    return (
      <tr key={row.id}>
        <td>
          <ReqLink linkTitle={tn} requestId={row.id} />
        </td>
        <td>{row.name}</td>
        <td>{row.modelnumber}</td>
        <td>{testStatus}</td>
      </tr>
    );
  });
};

LabListRowOta.propTypes = {
  tableRows: PropTypes.array
};

export default LabListRowOta;
