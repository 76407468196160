import React, { useState } from "react";
import { connect } from "react-redux";
import { activateInactiveUser } from "actions/user";

const ActivateUserLink = ({
  user,
  currStatus = "deleted",
  show,
  activateInactiveUser
}) => {
  const [loading, setLoading] = useState(false);

  // only inactive orgs will be managed by this component
  if (!show) return null;

  if (loading) {
    return <span>Activating...</span>;
  }

  const onClickHandler = () => {
    setLoading(true);
    activateInactiveUser(user.ctiaid)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  };

  return (
    <span className="cursor-pointer glow-link" onClick={onClickHandler}>
      Activate
    </span>
  );
};

export default connect(null, { activateInactiveUser })(ActivateUserLink);
