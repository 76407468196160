import { connect } from "react-redux";
import React, { Component } from "react";
import { Row } from "react-bootstrap";
import { Bubble } from "ctia-ui";
import AgreementPreview from "containers/request/AgreementPreview";
import Cards from "containers/request/battery/BatteryRequestCards";
import BatteryWizard from "containers/request/battery/BatteryWizardContainer";
import { initWizard, clearWizard } from "actions/wizard";
import { clearNonSarSites } from "actions/sites";

class BatterySubmitSelector extends Component {
  init() {
    // init wizard with additional param
    this.props.initWizard({
      batteryCert: null
    });

    // clear non SAR sites if non empty
    this.props.clearNonSarSites();
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (
      user.location !== prevProps.user.location &&
      (user.location === "/battery/supplier/request/new" ||
        user.location === "/battery/vendor/request/new")
    ) {
      this.init();
    }
  }

  componentWillUnmount() {
    this.props.clearWizard();

    // clear non SAR sites if non empty
    this.props.clearNonSarSites();
  }

  render() {
    const { history, wizard } = this.props;

    if (wizard && wizard.batteryCert) {
      return <BatteryWizard history={history} />;
    }

    return (
      <div>
        <h1>Submit New Request</h1>
        <Bubble>Please choose the request type.</Bubble>
        <Row>
          <Cards history={history} />
        </Row>
        <AgreementPreview certProgram="Battery Compliance Certification Program" />
      </div>
    );
  }
}

function mapStateToProps({ wizard }) {
  return { wizard };
}

export default connect(mapStateToProps, {
  initWizard,
  clearWizard,
  clearNonSarSites
})(BatterySubmitSelector);
