import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import NoTableData from "components/NoTableData";
import ReqLink from "components/RequestsLink";
import IeeeBadge from "components/Badges/IeeeBadge";
import {
  getRequestTypeTitle,
  getRequestTransPrefix
} from "helpers/RequestHelper";
import { getTestResultTitle } from "helpers/RequestTestsHelper";

const LabListRowBattery = ({ tableRows }) => {
  if (!tableRows) return null;

  if (_.isEmpty(tableRows)) {
    return <NoTableData colSpan={5} />;
  }

  return _.map(tableRows, row => {
    const tn = row.formatedtransactionnumber;
    const requestType = getRequestTypeTitle(row.requesttypeid);
    const testStatus = getTestResultTitle(row.testingstatus);
    const name = row.vendorproductname;

    return (
      <tr key={row.id}>
        <td>
          <ReqLink
            linkTitle={tn}
            requestId={row.id}
            subType={getRequestTransPrefix(row).toLowerCase()}
          />
        </td>
        <td>{row.vendorname}</td>
        <td>{name}</td>
        <td>
          <IeeeBadge ieeeType={row.ieeetypeid} />
        </td>
        <td>{requestType}</td>
        <td>{testStatus}</td>
      </tr>
    );
  });
};

LabListRowBattery.propTypes = {
  tableRows: PropTypes.array
};

export default LabListRowBattery;
