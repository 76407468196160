import React from "react";
import { Col, Row } from "ctia-react-bootstrap-v4";
import SelectUser from "containers/users/Users2Labs/SelectUser";
import SelectedUserLab from "containers/users/Users2Labs/SelectedUserLab";
import TransferLabs from "containers/users/Users2Labs/TransferLabs";

const Users2Labs = () => {
  return (
    <Row>
      <Col md={4}>
        <h1>Assign Users to Labs</h1>
        <h4>Please select a user:</h4>
        <SelectUser />
        <SelectedUserLab />
      </Col>
      <Col md={8}>
        <h1>&nbsp;</h1>
        <TransferLabs />
      </Col>
    </Row>
  );
};

export default Users2Labs;
