import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { Bubble } from "ctia-ui";
import { formatBatteryList } from "helpers/SupplierHelper";
import { setValue } from "actions/request";

const validationMsg =
  "No battery available for this model. Please select a different model name/number.";

const SelectBattery = ({ request, suppliers, setValue }) => {
  if (!suppliers) return null;

  const { certifiedBSCPackRequests: list } = suppliers;

  if (!list || !list.length)
    return (
      <Bubble icon="alert" type="warning">
        {validationMsg}
      </Bubble>
    );

  const onChange = event => {
    setValue("batteryPackId", parseInt(event.target.value));
  };

  const batteryList = request.id ? list : formatBatteryList(list);

  return (
    <FormElement
      type="select"
      id="battery"
      label="Battery"
      value={request.batteryPackId || 0}
      onChange={onChange}
      options={batteryList}
    />
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps, {
  setValue
})(SelectBattery);
