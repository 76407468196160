import React from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import { getManufSites } from "actions/sitesSar";
import CyberComponent from "containers/CyberComponent";
import RequestControls from "containers/request/RequestControls";
import SiteContainer from "containers/request/battery/ManufSite/SiteContainer";
import Instructions from "containers/request/battery/ManufSite/SiteBubble";
import { validateAddress } from "helpers/ManufSiteHelper";

class ManufSiteContainer extends CyberComponent {
  fetchSiteInfo() {
    const { request, user, getManufSites } = this.props;

    // fetch existed sites and addresses
    return getManufSites(request.ieee, user.requestOwnerCompanyId, true);
  }

  componentDidMount() {
    this.fetchSiteInfo();
  }

  validate() {
    const { request, sites, user } = this.props;

    // don't need to validate if form is non-editable
    if (!user.canEditRequest) return true;

    return validateAddress(request, sites.current);
  }

  render() {
    return (
      <div>
        <Form horizontal>
          <Instructions />
          <SiteContainer />
          <RequestControls isValid={this.validate()} />
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, user, sites }) {
  return { request, user, sites };
}

export default connect(mapStateToProps, {
  getManufSites,
})(ManufSiteContainer);
