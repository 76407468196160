import * as actions from 'actions/types';

export default function(state = null, action) {
  switch (action.type) {
    case actions.PRELOADER_START:
    case actions.PRELOADER_FINISH:
      return action.payload;
    case actions.START_PRELOADER:
      return true;
    case actions.STOP_PRELOADER:
      return false;
    default:
      return state;
  }
}
