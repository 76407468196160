import { Form } from "ctia-react-bootstrap-v4";
import React from "react";
import FormFieldLabel from "components/form/FormFieldLabel";

const ManufacturerForm = ({
  isSupplier = false,
  values = {}
}) => {
  return (
    <>
      <Form.Group controlId="name">
        <Form.Label>
          <FormFieldLabel label="Name" isRequired={true} />
        </Form.Label>
        <Form.Control required
          placeholder="Enter organization name"
          defaultValue={values.company}
        />
        <Form.Control.Feedback type="invalid">
          Please choose an organization name.
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="isSupplier">
        <Form.Check
          label="Battery/Cell/Adapter Supplier"
          type="checkbox"
          defaultChecked={isSupplier}
        />
      </Form.Group>
    </>
  );
}

export default ManufacturerForm;