import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Switcher } from "ctia-ui";
import { toggleMembershipAll } from "actions/catl";

const SwitcherMemberFee = ({ catl, toggleMembershipAll }) => {
  const checked = catl && catl.labs && _.every(catl.labs, "membershipPaid");

  return (
    <Switcher
      show={true}
      labelText="Select All"
      onChange={() => {
        toggleMembershipAll(checked);
      }}
      disabled={catl.readOnly}
      checked={checked}
      showWarning={false}
    />
  );
};

function mapStateToProps({ catl }) {
  return { catl };
}

export default connect(mapStateToProps, { toggleMembershipAll })(
  SwitcherMemberFee
);
