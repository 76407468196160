// React / Redux
import React from "react";
import { connect } from "react-redux";

// Dictionaries and Helpers
import { formatDate } from "helpers/DateHelper";
import { mapAdapterValues } from "helpers/BatterySupplierHelper";
import { mapBatteryPacksValues } from "helpers/BatterySupplierHelper";
import { getUserPrefix } from "helpers/UsersHelper";

// Components and Containers
import WithdrawButton from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawButton";
import ListOfLinksBSC from "components/form/ListOfLinksBSC";
import Portal from "components/Portal";

const BatteryModelDetailsBSCRow = ({
  reloadRow,
  requestIdsOfChildren = [],
  row,
  rowIndex,
  showDateCertified = true,
  showWithdrawColumn = false,
  // Redux stores
  app,
  user
}) => {
  const {
    id: requestId,
    completedat: completedAt,
    devicemodel: deviceModel,
    formatedtransactionnumber: formattedTransactionNumber,
    hwversion: hwVersion,
    linkedrequests: linkedRequests,
    primarylabid: primaryLabId,
    requeststatusid: requestStatusId,
    requesttypeid: requestTypeId,
    swversion: swVersion,
    withdrawnat: withdrawnAt,
    withdrawreason: withdrawReason
  } = row;

  const adaptersAndBatteries = linkedRequests;
  const deviceModelId = deviceModel ? deviceModel.id : null;
  const { vendorid: vendorId } = deviceModel;

  const userCanReviewRequestId =
    user.isStaff ||
    user.isOperator ||
    (user.isLab && primaryLabId === user.companyid) ||
    ((user.isSupplier || user.isVendor) && vendorId === user.companyid);

  const certIsAdapter = certType => {
    return certType === app.idACR || certType === app.idARR;
  };

  const certIsBattery = certType => {
    return certType === app.idPRR || certType === app.idEPRR;
  };

  const adapters = adaptersAndBatteries.filter(device => {
    return certIsAdapter(device.linkedrequestcerttypeid);
  });
  const batteries = adaptersAndBatteries.filter(device => {
    return certIsBattery(device.linkedrequestcerttypeid);
  });
  const certDate = completedAt ? formatDate(completedAt) : "";

  const linkToRequest = () => {
    if (!userCanReviewRequestId) return formattedTransactionNumber;

    const role = getUserPrefix(user);
    return (
      <Portal
        changeCertProgram={"battery"}
        to={`/battery/${role}/requests/bsc/${requestId}`}
        tn={formattedTransactionNumber}
      />
    );
  };

  return (
    <tr key={rowIndex}>
      <td>{linkToRequest()}</td>
      <td>{hwVersion}</td>
      <td>{swVersion}</td>
      <td>
        {batteries.length > 0 && (
          <ListOfLinksBSC list={mapBatteryPacksValues(batteries)} />
        )}
      </td>
      <td>
        {adapters.length > 0 && (
          <ListOfLinksBSC list={mapAdapterValues(adapters)} />
        )}
      </td>
      {showDateCertified && <td>{certDate}</td>}
      {showWithdrawColumn && (
        <td>
          <td>
            <WithdrawButton
              deviceModelId={deviceModelId}
              modalInfo={row}
              requestId={requestId}
              requestIdsOfChildren={requestIdsOfChildren}
              requestStatusId={requestStatusId}
              requestTypeId={requestTypeId}
              shouldDelistOrRelistOnSuccess={false}
              selectedCertType={"BSC"}
              withdrawnAt={withdrawnAt}
              withdrawReason={withdrawReason}
              onError={error => {
                reloadRow();
              }}
              onSuccess={() => {
                reloadRow();
              }}
            />
          </td>
        </td>
      )}
    </tr>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps)(BatteryModelDetailsBSCRow);
