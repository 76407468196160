import React, { useState } from "react";
import { IconLabel, Drawer } from "ctia-ui";
import BipolarBadge from "components/Badges/BipolarBadge";
import CompanyUsers from "containers/ControlPanel/CompanyUsers";
import ContactLink from "containers/ControlPanel/Orgs/ServiceCenter/ContactLink";
import EditVendor from "containers/ControlPanel/Orgs/Vendors/EditVendor";
import DisableOrg from "containers/ControlPanel/Orgs/DisableOrg/DisableOrg";

const VendorRow = ({ item }) => {
  const [showBottomDrawer, setShowBottomDrawer] = useState(false);
  const toggleBottomDrawer = () => setShowBottomDrawer(!showBottomDrawer);
  const status = item.active ? "ACTIVE" : "DISABLED";

  return (
    <tr>
      <td>
        <div>
          <EditVendor id={item.id} name={item.name} />
        </div>
        <div>
          <small className="block-left-offset1">
            <strong>
              <span
                className="glow-link cursor-pointer"
                onClick={toggleBottomDrawer}
              >
                <IconLabel awesomeIcon="users" label="View All Users" />
              </span>
            </strong>
          </small>
        </div>
      </td>
      {/* Contact */}
      <td>
        <ContactLink name={item.contactname} email={item.email} />
      </td>
      <td>
        <BipolarBadge label={status} positive={item.active === 1} />
      </td>
      <td>
        <DisableOrg org={item} />
        <Drawer
          position="bottom"
          show={showBottomDrawer}
          title="View Vendor Users"
          toggleDrawer={toggleBottomDrawer}
          content={
            <CompanyUsers
              companyId={item.id}
              companyName={item.name}
              flagCode={item.country}
            />
          }
          height="400"
        />
      </td>
    </tr>
  );
};

export default VendorRow;
