import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import SaveButton from "components/form/buttons/SaveButton";
import { isExisted } from "helpers/RequestHelper";

const RequestSaveButton = ({ app, request, isValid, onSave }) => {
  if (isExisted(request) && _.isFunction(onSave)) {
    return (
      <SaveButton
        label="Save Changes"
        onClick={onSave}
        disabled={!isValid}
        isLoading={app.isSaving}
      />
    );
  }

  return null;
};

function mapStateToProps({ app, request }) {
  return { app, request };
}

export default connect(mapStateToProps)(RequestSaveButton);
