import DeclarationOfCompliance from "containers/request/LicenseAgreement/BatteryAgreement/Containers/DeclarationOfCompliance";
import LicenseAgreement from "containers/request/LicenseAgreement/BatteryAgreement/Containers/BatteryLicenseAgreement";
import RecognitionAgreement from "containers/request/LicenseAgreement/BatteryAgreement/Containers/RecognitionAgreement";

export const BSC_AGREEMENTS = [DeclarationOfCompliance, LicenseAgreement];
export const BLC_AGREEMENTS = [LicenseAgreement];
export const EPR_AGREEMENTS = [DeclarationOfCompliance, RecognitionAgreement];

export const getAgreementList = (request) => {
  if (request.isBLC) return BLC_AGREEMENTS;
  if (request.isACR || request.isBSC) return BSC_AGREEMENTS;

  return EPR_AGREEMENTS;
};
