import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SubmissionRender from 'containers/submission/SubmissionRender';
import { cyberTabs } from 'helpers/WizardHelper';
import { clearRequest, createInitialCyberRequest } from 'actions/request';
import { initWizard, clearWizard } from 'actions/wizard';

class InitialSubmitPage extends Component {
  // init redux storage with default values for a request and a wizard
  componentDidMount() {
    this.props.createInitialCyberRequest(this.props.user.company);
    this.props.initWizard({
      steps: cyberTabs.initial,
      totalSteps: _.size(cyberTabs.initial)
    });
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearWizard();
  }

  render() {
    return <SubmissionRender history={this.props.history} />;
  }
}

export default connect(
  null,
  {
    createInitialCyberRequest,
    initWizard,
    clearRequest,
    clearWizard
  }
)(InitialSubmitPage);
