import React from "react";
import { connect } from "react-redux";
import AdaptersEditable from "containers/request/battery/AdapterSupplier/AdapterSupplierContainer";
import AdaptersReadonly from "containers/request/battery/AdapterSupplier/AdaptersReadonly";

const Adapters = ({ user }) => {
  const AdaptersRenderer = user.canEditDeviceInfo
    ? AdaptersEditable
    : AdaptersReadonly;
  return <AdaptersRenderer />;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Adapters);
