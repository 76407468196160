import React from "react";
import { connect } from "react-redux";
import ManufSiteName from "containers/request/battery/ManufSite/ManufSiteName";
import SiteSelectorRenewal from "containers/request/battery/ManufSite/SiteSelectorRenewal";
import AddressDetails from "containers/request/battery/ManufSite/SiteAddressDetails";
import ManufSiteParentLink from "containers/request/battery/ManufSite/ManufSiteParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";

const ManufSiteEditRenewal = ({ request }) => (
  <div>
    <ManufSiteParentLink />
    <BatteryParentDetails />
    <hr />
    <ManufSiteName />
    {!request.id && <SiteSelectorRenewal />}
    <AddressDetails />
  </div>
);

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(ManufSiteEditRenewal);
