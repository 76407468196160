import React from "react";
import { connect } from "react-redux";
import { IconLabel } from "ctia-ui";
import { removePaymentASC } from "actions/asc";

const RemovePaymentASC = ({ showLink, ivcId, removePaymentASC }) => {
  return showLink ? (
    <div>
      <IconLabel awesomeIcon="trash-alt" />{" "}
      <span
        className="glow-link cursor-pointer"
        onClick={() => removePaymentASC(ivcId)}
      >
        Remove Payment
      </span>
    </div>
  ) : null;
};

export default connect(null, { removePaymentASC })(RemovePaymentASC);
