import PropTypes from "prop-types";

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

import { Form } from "ctia-react-bootstrap-v4";
import { LoaderButton, IconLabel, InlinePreloader } from "ctia-ui";

import { fetchCompanyUsers } from "actions/user";
import { getOrganization } from "actions/org";
import { updateCompanyLab } from "actions/orgAdmin/lab";

import { validateLabForm } from "components/Companies/AddCompanyModal/components/FormHelper";
import "containers/ControlPanel/Payments/payments.css";
import EditLabForm from "containers/ControlPanel/Orgs/Labs/EditLabForm";

const formId = "edit-center-form";

const EditLabModal = ({
  id,
  modalHandler,
  getOrganization,
  fetchCompanyUsers,
  updateCompanyLab
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (!data)
      fetchCompanyUsers(id)
        .then(() => getOrganization(id, "lab"))
        .then(res => {
          if (res && res.data) {
            const data = res.data.data;
            setData({ ...data });

            // force validation since initially we set form as invalid
            // @NOTE: using setTimeout to allow the form renders first
            setTimeout(() => validateForm(), 500);
          } else {
            throw new Error("Server responded with an error");
          }
        });
  }, [id, data, getOrganization, fetchCompanyUsers]);

  const renderSubmitButton = () => (
    <span className="icon-left-offset">
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Save" awesomeIcon="check" />}
        onClick={() => {
          setLoading(true);

          updateCompanyLab(formId, id)
            .then(() => {
              setLoading(false);
              modalHandler();
            })
            .catch(err => {
              setLoading(false);
              modalHandler(err);
            });
        }}
        disabled={!validated}
        isLoading={loading}
      />
    </span>
  );

  const validateForm = () => {
    const form = document.getElementById(formId);
    if (!form) return false;
    let isValid = form.checkValidity() && validateLabForm(form);
    setValidated(isValid);
  };

  return (
    <Modal show={true} bsSize="lg" className="ctia-modal">
      <Modal.Header closeButton onHide={modalHandler}>
        <Modal.Title>Edit Lab Organization</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data ? (
          <Form
            noValidate
            validated={validated}
            id={formId}
            className="ctia-form"
            onChange={validateForm}
          >
            <EditLabForm data={data} validateForm={validateForm} />
          </Form>
        ) : (
          <InlinePreloader />
        )}
      </Modal.Body>
      <Modal.Footer>
        {renderSubmitButton()}
        <Button onClick={modalHandler} className="agr-close-btn">
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

EditLabModal.propTypes = {
  id: PropTypes.number,
  modalHandler: PropTypes.func
};

export default connect(null, {
  getOrganization,
  fetchCompanyUsers,
  updateCompanyLab
})(EditLabModal);
