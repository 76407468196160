import * as actions from "actions/types";
import { getToggledWireless } from "helpers/Wireless";
import { getSelectedOperatorIds } from "helpers/Operators";
import { makeSimpleArgs } from "helpers/ArgsHelper";

const preloaderSubmissionStates = {
  start: actions.START_SUBMITTING,
  stop: actions.STOP_SUBMITTING
};

const preloaderSavingStates = {
  start: actions.START_SAVING,
  stop: actions.STOP_SAVING
};

export const prepareExistingLegacyModelArgsCyber = request => {
  return makeSimpleArgs({
    legacydevicemodelid: request.existedModelId,
    modelnumber: request.existedModel,
    certcategoryid: request.certCategory,
    devicemodelwirelesscapabilities: getToggledWireless(
      request.wirelessToggled
    ),
    requests: [
      {
        certtypeid: 26, // TODO: move to helper/dictionary
        primarylabid: request.cdmaLab,
        pocid: request.pocId,
        requesttypeid: request.type,
        billingid: request.billingId,
        purchaseorder: request.billingOrderNum,
        devicemodeloperators: getSelectedOperatorIds(request.operators)
      }
    ]
  });
};

export const prepareExistingModelArgsCyber = request => {
  return makeSimpleArgs({
    devicemodelid: request.existedModelId,
    certtypeid: 26, // TODO: move to helper/dictionary
    primarylabid: request.cdmaLab,
    pocid: request.pocId,
    requesttypeid: request.type,
    billingid: request.billingId,
    purchaseorder: request.billingOrderNum,
    devicemodel: {
      id: request.existedModelId,
      devicemodeloperators: getSelectedOperatorIds(request.operators),
      certcategoryid: request.certCategory,
      devicemodelwirelesscapabilities: getToggledWireless(
        request.wirelessToggled
      )
    }
  });
};

export const prepareNewModelArgsCyber = request => {
  return makeSimpleArgs({
    requests: [
      {
        certtypeid: 26, // TODO: move to helper/dictionary
        primarylabid: request.cdmaLab,
        pocid: request.pocId,
        requesttypeid: request.type,
        billingid: request.billingId,
        purchaseorder: request.billingOrderNum
      }
    ],
    modelnumber: request.existedModel,
    devicemodeloperators: getSelectedOperatorIds(request.operators),
    certcategoryid: request.certCategory,
    devicemodelwirelesscapabilities: getToggledWireless(request.wirelessToggled)
  });
};

export const prepareCyberInitialArgs = request => {
  if (request.isExistedModelLegacy) {
    return {
      body: prepareExistingLegacyModelArgsCyber(request),
      url: "/devicemodels"
    };
  }

  const body = request.existedModelId
    ? prepareExistingModelArgsCyber(request)
    : prepareNewModelArgsCyber(request);

  const url = request.existedModelId ? "/requests" : "/devicemodels";

  return { body, url };
};

export const getPreloaderStates = request => {
  return request.agreementAccepted
    ? preloaderSubmissionStates
    : preloaderSavingStates;
};

export const prepareEcoRequestArgs = request => {
  return {
    args: {
      requesttypeid: request.type,
      devicemodelid: request.parentModelId,
      primarylabid: request.cdmaLab,
      hwversion: request.hwVersion,
      swversion: request.swVersion,
      pocid: request.pocId,
      parentid: request.parentId,
      descriptionofchange: request.descriptionOfChange,
      billingid: request.billingId,
      purchaseorder: request.billingOrderNum
    }
  };
};
