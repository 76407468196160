import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import { DeleteButton, DownloadButton } from "ctia-ui";
import InputFile from "components/form/InputFile";
import UploadInstructions from "components/UploadInstructions";
import { handleDocTitleChange, handleDocFileChange } from "actions/request";
import { fetchTestReports } from "actions/tests";
import { fetchTestReportsCRR } from "actions/battery/crrRequest";
import { fetchTestReportsHRR } from "actions/battery/hrrRequest";
import { fetchTestReportsPRR } from "actions/battery/prrRequest";
import { fetchTestReportsEPR } from "actions/battery/eprRequest";
import { fetchTestReportsARR } from "actions/battery/arrRequest";
import { fetchTestReportsACR } from "actions/battery/acrRequest";
import { fetchTestReportsBLC } from "actions/battery/blcRequest";
import { fetchTestReportsBSC } from "actions/battery/bscRequest";
import { deleteTestReport } from "actions/uploads";
import { hasReportDocuments, countReports } from "helpers/RequestTestsHelper";
import { cutFileExtension } from "helpers/StringHelper";
import {
  getCertTestHandler,
  getCertShorthand
} from "actions/battery/multitool";

const actionsColStyle = { width: 300 };

class LabResultUpload extends Component {
  onDelete = (testId, slot) => {
    const { deleteTestReport, fetchTestReports, request } = this.props;
    const { fetchTestReportHandler } = getCertTestHandler(request, this.props);
    const certSH = getCertShorthand(request);

    return _.isEmpty(certSH)
      ? deleteTestReport(testId, slot).then(() => {
          if(slot > 1)
            fetchTestReports({ id: testId })
        })
      : deleteTestReport(testId, slot, "general", certSH).then(() => {
          if(slot > 1)
            fetchTestReportHandler({ id: testId })
      });
  };

  onSelectFile = (index, event) => {
    // handle file change action
    const { handleDocFileChange, handleDocTitleChange, request } = this.props;
    handleDocFileChange(index, request.testResults, event);

    // set file title if it is empty
    const currentTitle = String(request.testResults[index].title);
    if (_.isEmpty(currentTitle)) {
      // set file description as file name by default (w/o file extension)
      const fileName = cutFileExtension(event.target.files[0].name);

      // create event for changing description action manually
      const titleChangeEvent = {
        id: "testResults",
        target: { value: fileName }
      };

      handleDocTitleChange(index, request.testResults, titleChangeEvent);
    }
  };

  render() {
    const { show, request, handleDocTitleChange, downloadHandler, testModule } = this.props;

    if (!show) return null;

    const results = "otaInterfaces" in request ? testModule.reports : request.testResults;
    const hasReports = hasReportDocuments(results);
    const reportsCount = countReports(results);

    return (
      <div>
        <h3>Upload Results</h3>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Document Title</th>
              <th>Select Document</th>
              {hasReports && <th style={actionsColStyle}>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {results.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <FormGroup controlId="testResults" bsSize="small">
                      <FormControl
                        type="text"
                        value={item.title}
                        onChange={handleDocTitleChange.bind(this, i, results)}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup controlId="testResults">
                      <InputFile
                        id={`report${i}`}
                        name="testResults"
                        onChange={this.onSelectFile.bind(this, i)}
                        file={item.file}
                        disabled={false}
                      />
                    </FormGroup>
                  </td>
                  {hasReports && (
                    <td>
                      {item.docId > 0 && (
                        <DeleteButton
                          size="small"
                          action={() => {
                            this.onDelete(item.requesttestid, item.slot)
                          }}
                          customClassName="button-right-offset"
                        />
                      )}
                      {item.docId > 0 && (
                        <DownloadButton
                          size="small"
                          action={() => downloadHandler(item, request)}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <UploadInstructions />
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  handleDocTitleChange,
  handleDocFileChange,
  deleteTestReport,
  fetchTestReports,
  fetchTestReportsCRR,
  fetchTestReportsHRR,
  fetchTestReportsPRR,
  fetchTestReportsEPR,
  fetchTestReportsARR,
  fetchTestReportsACR,
  fetchTestReportsBSC,
  fetchTestReportsBLC
})(LabResultUpload);
