import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import { handleInputChange } from "actions/request";
import { getAuthorizedLabsByIEEE } from "actions/labs";

class LabTestInfoContainerCRR extends Component {
  state = { selectedLab: 0 };

  componentDidMount() {
    this.props
      .getAuthorizedLabsByIEEE(this.props.request.ieee, "crr")
      .then(this.fetchCallback);
  }

  fetchCallback = () => {
    const { cdmaLab } = this.props.request;

    // fetch user lab list, if user in edit mode
    if (cdmaLab > 0) {
      this.setState({ selectedLab: cdmaLab });
    }
  };

  renderLabList() {
    // sort labs by their names before iterating
    const list = _.sortBy(this.props.labs, ["name"]);

    return _.map(list, lab => {
      return (
        <option value={lab.id} key={lab.id}>
          {lab.name}
        </option>
      );
    });
  }

  onLabSelect = event => {
    const labId = Number(event.target.value);
    this.setState({ selectedLab: labId });
    this.props.handleInputChange(event);
  };

  renderSelector = () => {
    const { editable, request } = this.props;

    if (!editable) return request.cdmaLabName;

    return (
      <FormGroup controlId="cdmaLab">
        <FormControl
          componentClass="select"
          value={request.cdmaLab}
          onChange={this.onLabSelect}
          disabled={!editable}
        >
          <option value="0">--- Select a lab ---</option>
          {this.renderLabList()}
        </FormControl>
      </FormGroup>
    );
  };

  render() {
    const { labs, tests, customTitles, statusComponent } = this.props;

    if (_.isEmpty(labs)) {
      return null;
    }

    const showStatus = !_.isEmpty(tests);
    const { col1, col2, col3, col1Row } = customTitles;
    const StatusContainer = statusComponent;

    return (
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>{col1}</th>
            <th>{col2}</th>
            {showStatus && <th>{col3}</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="col-xs-3 col-sm-3 col-md-3">{col1Row}</td>
            <td className="col-xs-6 col-sm-6 col-md-6">
              {this.renderSelector()}
            </td>
            <StatusContainer />
          </tr>
        </tbody>
      </Table>
    );
  }
}

function mapStateToProps({ request, labs, tests }) {
  return { request, labs, tests };
}

export default connect(mapStateToProps, {
  handleInputChange,
  getAuthorizedLabsByIEEE
})(LabTestInfoContainerCRR);
