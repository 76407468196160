import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { updateUserLocation } from "actions/user";

const ReturnHome = ({ updateUserLocation }) => {
  const goHome = () => {
    updateUserLocation();
  };

  return (
    <h3>
      <Link to="/" onClick={goHome}>
        &larr; Return to home
      </Link>
    </h3>
  );
};

export default connect(null, { updateUserLocation })(ReturnHome);
