import React from "react";
import { connect } from "react-redux";
import { Card, Table } from "ctia-react-bootstrap-v4";
import TestPlanRow from "containers/request/OTA/TestPlans/OTATestPlanListRow";
// import PreEstimationLabel from "containers/request/OTA/TestPlans/PreEstimationLabel";
import ApproveReports from "containers/request/OTA/TestPlans/ApproveReports/OtaTestReportsApprove";

const OTATestPlanList = ({ request, user }) => {
  if (!request || !request.otaInterfaces) return null;

  const renderReportsApproval = () => {
    if (request.id > 0) {
      return <ApproveReports user={user} request={request}/>;
    }

    return null;
  };

  return (
    <div>
      <div className="block-bottom-offset">
        <Card border="light">
          <Card.Body>
            <h3>Selected Test Plans ({request.otaInterfaces.length})</h3>
            <Table responsive striped hover size="sm" className="ctia-table">
              <thead>
                <tr>
                  <th>Test Plan</th>
                  {/* <th>CATL Fee</th> */}
                  <th>Selected CATL</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {request.otaInterfaces.map((testPlan, i) => {
                  return <TestPlanRow key={i} testPlan={testPlan} />;
                })}
                {/* <PreEstimationLabel /> */}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      {renderReportsApproval()}
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(OTATestPlanList);
