import React from "react";
import { connect } from "react-redux";
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import PropTypes from "prop-types";
import { setValue } from "actions/request";

const ToggleButtonGroupComponent = ({
  id,
  list,
  listToggled,
  setValue,
  onChange = null
}) => {
  if (!list) return null;

  const buttonList = list.map((option, i) => {
    return (
      <ToggleButton key={i} value={option.id} disabled={option.disabled}>
        {option.label}
      </ToggleButton>
    );
  });

  const onToggleChange = values => {
    setValue(id, values);
  };

  return (
    <ButtonToolbar>
      <ToggleButtonGroup
        type="checkbox"
        value={listToggled}
        onChange={onChange || onToggleChange}
      >
        {buttonList}
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

ToggleButtonGroupComponent.propTypes = {
  list: PropTypes.array.isRequired,
  listToggled: PropTypes.array,
  id: PropTypes.string.isRequired
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { setValue })(
  ToggleButtonGroupComponent
);
