import React from "react";
import FormElement from "components/FormElement";

export const AuthorizationTypeSelector = ({
  toggleOptions,
  selectedAuthType,
  onChangeHandler = null
}) => {
  return (
    <FormElement
      type="toggleRadioGroup"
      id="authorizationType"
      label="Select Authorization Type"
      value={selectedAuthType}
      list={toggleOptions}
      onChange={onChangeHandler}
    />
  );
};
