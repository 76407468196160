import React from "react";
import { connect } from "react-redux";
import AddVendor from "containers/ControlPanel/Orgs/Vendors/AddVendor";
import AddLabOrg from "containers/ControlPanel/Orgs/Labs/AddLabOrg";
import AddOperatorOrg from "containers/ControlPanel/Orgs/Operators/AddOperatorOrg";
import AddServiceCenter from "containers/ControlPanel/Orgs/ServiceCenter/AddServiceCenter";
import * as roles from "dictionaries/Roles";

const AddOrg = ({ org }) => {
  if (!org || !org.type) return null;

  switch (org.type) {
    case roles.VENDOR:
      return <AddVendor />;
    case roles.ASC:
      return <AddServiceCenter />;
    case roles.LAB:
      return <AddLabOrg />;
    case roles.OPERATOR:
      return <AddOperatorOrg />;
    default:
      console.error("Unsupported type passed: ", org.type);
      return null;
  }
};

function mapStateToProps({ org }) {
  return { org };
}

export default connect(mapStateToProps)(AddOrg);
