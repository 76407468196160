import React, { Component } from "react";
import { connect } from "react-redux";
import AuditResultsItem from "containers/request/battery/TestResults/AuditResultsItem";
import { setLabAuditPermissions } from "actions/battery/sar";

class EnterResultsContainerSAR extends Component {
  componentDidMount() {
    this.props.setLabAuditPermissions();
  }

  render() {
    return (
      <AuditResultsItem
        testId={this.props.match.params.id}
        history={this.props.history}
      />
    );
  }
}

export default connect(null, {
  setLabAuditPermissions
})(EnterResultsContainerSAR);
