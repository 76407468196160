import _ from "lodash";
import React from "react";
import roles from "dictionaries/UserRoles";

const {
  vendor,
  lab,
  operator,
  staff,
  accountant,
  supplier,
  serviceCenter,
  unassigned
} = roles;

const userTypes = {
  0: { id: 0, title: "All" },
  accountant: { id: accountant, title: "Accountant" },
  admin: { id: staff, title: "CTIA Staff" },
  lab: { id: lab, title: "Laboratory" },
  operator: { id: operator, title: "Operator" },
  serviceCenter: { id: serviceCenter, title: "Service Center" },
  supplier: { id: supplier, title: "Supplier" },
  vendor: { id: vendor, title: "Vendor" },
  unassigned: { id: unassigned, title: "Unassigned" }
};

const RenderOptions = () => {
  return _.map(userTypes, option => {
    return (
      <option value={option.id} key={option.id}>
        {option.title}
      </option>
    );
  });
};

export default RenderOptions;
