import React from "react";
import { connect } from "react-redux";
import BipolarBadge from "components/Badges/BipolarBadge";
import AgreementSignASC from "components/ProgramSelector/ServiceCenter/AgreementSignASC";
import { downloadAgreementASC } from "actions/asc";

const AscAgreement = ({ user, downloadAgreementASC }) => {
  const downloadHandler = () => downloadAgreementASC(user.companyid);
  const agreementHandler = user.isASCAgreementSigned ? downloadHandler : null;

  const agreementBadgeProps = user.isASCAgreementSigned
    ? { label: "Signed" }
    : { label: "Pending", positive: false };

  return (
    <li>
      <AgreementSignASC agreementHandler={agreementHandler} />
      <span className="icon-left-offset">
        <BipolarBadge {...agreementBadgeProps} />
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  downloadAgreementASC
})(AscAgreement);
