import React from "react";
import { Col, Row } from "react-bootstrap";
import InvoiceYearSelector from "containers/ControlPanel/Payments/InvoiceYearSelector";
import LabInvoicingStat from "containers/ControlPanel/Payments/LabInvoicingStat";
import LabListATL from "containers/ControlPanel/Payments/LabListATL";
import "containers/ControlPanel/Payments/payments.css";

const LabInvoicing = () => {
  return (
    <div>
      <Row>
        <Col xs={12}>
          <div style={{ display: "flex" }} className="h1-div">
            <div>Lab Invoicing,&nbsp;</div>
            <InvoiceYearSelector />
          </div>
        </Col>
      </Row>
      <LabInvoicingStat />
      <LabListATL />
    </div>
  );
};

export default LabInvoicing;
