import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleCheckboxGroupChange } from "actions/request";
import { sortByFieldname } from "helpers/SortHelper";

class AuditLinks extends Component {
  hasEmptyResult = () => {
    const { linkedAudits } = this.props.request;

    if (!linkedAudits || _.isEmpty(linkedAudits)) return true;

    return false;
  };

  renderMappedForm = () => {
    const { request, user } = this.props;
    const list = sortByFieldname(request.linkedAudits || [], "label");

    if (!list.length) return null;

    // TODO: use it in case Modify mode comes back to life
    // return (user.togglerEnabled && user.isStaff) ||
    return user.isStaff ||
      (user.isSupplier && !request.id) ||
      (user.isLab && user.canEditManufsite)
      ? this.renderEditable(list)
      : this.renderReadonly(list);
  };

  renderEditable = list => (
    <FormElement
      type="checkboxList"
      id="auditLinkedRequests"
      label="Link to additional site audit(s)"
      onChange={this.props.handleCheckboxGroupChange.bind(this, list)}
      list={list}
      editable={true}
      inline={false}
    />
  );

  renderReadonly = list => {
    const filteredList = _.filter(list, ["checked", true]);
    if (_.size(filteredList)) {
      return (
        <FormElement
          id="linkedAudit"
          label="Linked Audit Information"
          list={filteredList}
          subtype="sar"
          type="listOfReqLinks"
        />
      );
    }

    return null;
  };

  render() {
    return this.hasEmptyResult() ? null : this.renderMappedForm();
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleCheckboxGroupChange
})(AuditLinks);
