import React from "react";
import "./canvas.css";

const CatlLogoCanvas = ({ labCode }) => {
  return (
    <div className="canvasLogoContainer canvasLogoContainer__CATL">
      <img src="/assets/ATL_Logo.svg" alt="ATL Logo" height={78} />
      <div className="canvasText__CATL">
        Lab Code: <b>{labCode}</b>
      </div>
    </div>
  );
};

export default CatlLogoCanvas;
