import React from "react";

const defaultTypes = [
  "TXT",
  "HTM",
  "HTML",
  "DOC",
  "DOCX",
  "XLS",
  "XLSX",
  "PPT",
  "PPTX",
  "RTF",
  "PDF",
  "XPS",
  "ZIP",
  "JPG",
  "GIF"
];

export default () => {
  return (
    <div className="block-bottom-offset">
      <h3>File Upload Instructions</h3>
      <ul className="ctia-list">
        <li>
          Only alpha-numeric characters &amp; underscores can be used in the
          file name (no spaces or special characters).
        </li>
        <li>Allowable File Types: {defaultTypes.join("; ")}</li>
      </ul>
    </div>
  );
};
