import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import InfoBar from "containers/TestResultsInfoBar";
import { LoaderButton } from "ctia-ui";
import TestResultPanel from "components/TestResultPanel";
import PageTitle from "components/RequestPageTitle";
import LabResultForm from "containers/testResult/LabResultForm";
import LabResultUpload from "containers/testResult/LabResultUpload";
import UnderReviewView from "containers/testResult/UnderReviewViewCommon";
import GoBackButton from "components/form/buttons/GoBackButton";
import {
  hasPreparedResults,
  isResultNoTesting,
  isComplete
} from "helpers/RequestTestsHelper";
import { validateWireless } from "helpers/Wireless";
import { clearRequest } from "actions/request";
import {
  loadTestResults,
  submitTestResults,
  testReportDownload
} from "actions/tests";

class TestResultsItem extends Component {
  state = { showContent: false, noTesting: false };

  componentDidMount() {
    const { match, loadTestResults } = this.props;
    this.resetRender();
    loadTestResults(match.params.id).then(this.activateRender);
  }

  componentWillReceiveProps(nextProps) {
    const { match, loadTestResults } = this.props;
    const newId = nextProps.match.params.id;
    if (newId !== match.params.id) {
      loadTestResults(newId).then(this.activateRender);
    }
  }

  componentWillUnmount() {
    this.props.clearRequest();
  }

  resetRender = () => {
    this.setState({ showContent: false });
  };

  activateRender = () => {
    this.setState({ showContent: true });
  };

  onValidate() {
    const { request, tests } = this.props;
    const { testResults, hwVersion, swVersion } = request;
    const {
      reports,
      planversion,
      requesttestpassedstatusid
      // requesttestconductinglabs
    } = tests[0];

    const commonFieldsValidate =
      requesttestpassedstatusid > 0 &&
      planversion !== "" &&
      hwVersion !== "" &&
      swVersion !== "";

    if (!commonFieldsValidate) return false;

    // validate wireless technologies
    if (!validateWireless(request)) return false;

    // validate cert category
    if (Number(request.certCategory) === 0) return false;

    if (isResultNoTesting(requesttestpassedstatusid)) return true;

    return (
      // requesttestconductinglabs.length > 0 &&
      hasPreparedResults(testResults) || reports.length
    );
  }

  onSubmit = () => {
    const { request, tests, submitTestResults } = this.props;
    return submitTestResults(request, tests[0]);
  };

  onDone = () => {
    this.props.history.push("/cyber/lab/results");
  };

  onHandleTestingResult = value => {
    this.setState({ noTesting: value });
  };

  render() {
    const { request, tests, testReportDownload } = this.props;

    if (_.isNull(request) || _.isNull(tests) || !request.id) {
      return null;
    }

    if (!this.state.showContent) return null;

    if (request.formSaved) {
      return (
        <div>
          <h1 className="block-bottom-offset">Uploading Test Results</h1>
          <TestResultPanel />
          <LoaderButton text="Done" onClick={this.onDone} disabled={false} />
        </div>
      );
    }

    const testModule = { ...tests[0] };
    const completed = isComplete(request.testStatus);

    return (
      <div>
        <PageTitle />
        <GoBackButton dest="/cyber/lab/results" label="Return to list" />
        <InfoBar />
        <UnderReviewView show={completed} testModule={testModule} />
        <LabResultForm
          show={!completed}
          testModule={testModule}
          handleTestResult={this.onHandleTestingResult}
        />
        <LabResultUpload
          show={!completed && !this.state.noTesting}
          downloadHandler={testReportDownload}
        />
        <Row>
          <Col className="text-center">
            <LoaderButton
              bsStyle="info"
              bsSize="large"
              text="Save changes"
              loadingText="Uploading..."
              onClick={this.onSubmit}
              isLoading={request.isLoading}
              disabled={!this.onValidate()}
              show={!completed}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, {
  clearRequest,
  loadTestResults,
  submitTestResults,
  testReportDownload
})(TestResultsItem);
