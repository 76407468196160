import _ from "lodash";
import React from "react";
import { Badge } from "ctia-react-bootstrap-v4";
import { getToggledWireless } from "helpers/Wireless";

export const testResultStatuses = {
  pending: "",
  pass: 1,
  noTesting: 2,
  infoOnly: 3
};

/**
 * Check if test status is `passed`.
 *
 * @param {number | string} status Test status ID
 * @returns boolean
 */
export const isResultPass = status =>
  Number(status) === testResultStatuses.pass;

/**
 * Check if test status is `no testing`.
 *
 * @param {number | string} status Test status ID
 * @returns boolean
 */
export const isResultNoTesting = status =>
  Number(status) === testResultStatuses.noTesting;

/**
 * Check if test status is `informational only`.
 *
 * @param {number | string} status Test status ID
 * @returns boolean
 */
export const isResultInfoOnly = status =>
  Number(status) === testResultStatuses.infoOnly;

/**
 * Return test result title as a string.
 *
 * @param {string} status Status string code (passed from server)
 * @returns string
 */
export function getTestResultTitle(status) {
  switch (status) {
    case testStatuses.pending:
      return "Pending";
    case testStatuses.accepted:
      return "Accepted";
    case testStatuses.rejected:
      return "Rejected";
    case testStatuses.complete:
      return "Complete";
    case testStatuses.underReview:
      return "Under Review";
    default:
      return "All";
  }
}

/**
 * Return string title for test passed results.
 *
 * @param {number} status Status ID
 * @returns string
 */
export function getTestPassedTitle(status, isAudit = false) {
  const statusCode = Number(status);
  switch (statusCode) {
    case testResultStatuses.pass:
      return "Pass";
    case testResultStatuses.noTesting:
      const title = isAudit ? "No Auditing" : "No Testing";
      return title;
    case testResultStatuses.infoOnly:
      return "Informational Only";
    default:
      return "Wrong Status ID";
  }
}

export const testTypes = {
  winnfTs0122: {
    title: "Cybersecurity",
    id: 63
  },
  siteAudit: {
    title: "Battery Compliance",
    id: 19
  },
  dhr: {
    title: "Device Hardware Reliability",
    id: 45
  }
};

/**
 * Search test info by passed code.
 *
 * @param {number} id Test code (ID)
 * @returns object | undefined
 */
export const findTestById = id => {
  return _.find(testTypes, ["id", Number(id)]);
};

/**
 * Find an object key by passed test code.
 *
 * @param {number} id Test code (ID)
 * @returns string Test type code (object key)
 */
export const findTestCodeById = id => {
  return _.findKey(testTypes, ["id", Number(id)]);
};

export const testStatuses = {
  accepted: "Accepted",
  all: "All",
  complete: "Complete",
  pending: "Pending",
  rejected: "Rejected",
  underReview: "Under Review"
};

export const testStatusesOta = {
  accepted: "Accepted",
  all: "All",
  complete: "Complete"
};

export const testStatusIds = {
  pending: 1,
  accepted: 2,
  rejected: 3,
  underReview: 4,
  complete: 5
};

export const isAccepted = status => status === testStatuses.accepted;
export const isRejected = status => status === testStatuses.rejected;
export const isPending = status => status === testStatuses.pending;
export const isComplete = status => status === testStatuses.complete;
export const isUnderReview = status => status === testStatuses.underReview;

export const isIdAccepted = id => id === testStatusIds.accepted;
export const isIdRejected = id => id === testStatusIds.rejected;
export const isIdPending = id => id === testStatusIds.pending;
export const isIdComplete = id => id === testStatusIds.complete;
export const isIdUnderReview = id => id === testStatusIds.underReview;

export const canBeAcceptedForTesting = status => {
  return isPending(status) || isAccepted(status) || isRejected(status);
};

export const getTestStatusGroup = (status = testStatuses.pending) => {
  const { pending, accepted, rejected, underReview, complete } = testStatuses;
  switch (status) {
    case pending:
      return [pending];
    case accepted:
      return [accepted];
    case rejected:
      return [rejected];
    case underReview:
      return [underReview];
    case complete:
      return [complete];
    default:
      return [pending, accepted, rejected, underReview, complete];
  }
};

export const getTestStatusGroupOta = (status = testStatuses.all) => {
  const { accepted, complete } = testStatuses;
  switch (status) {
    case accepted:
      return [accepted];
    case complete:
      return [complete];
    default:
      return [accepted, complete];
  }
};

export function hasPreparedResults(results) {
  if (!results.length) return false;

  return results.some(element => element.file && element.title);
}

export function hasUploadedReports(reports) {
  if (!reports.length) return false;

  return reports.some(report => report.documentid > 0);
}

export const getConductLabsArgs = labList => {
  if (!labList || !labList.length) return [];

  return _.map(labList, lab => {
    return {
      labid: _.every(labList, _.isObject) ? lab.labid : lab
    };
  });
};

export function hasReportDocuments(reports) {

  if (!reports.length) return false;

  return reports.some(report => _.isObject(report.document));
}

export function isReportApproved(report) {
  return report.approved === 1;
}

export function countReports(reports) {
  if (!reports.length) return 0;

  const wef = _.filter(reports, "document");
  return wef.length;
}

export const getReports = list => {
  if (!list.length) return null;

  return _.filter(list, "document");
};

export const getRequestReportFields = request => {
  const { id, hwVersion, swVersion } = request;
  return {
    id,
    hwVersion,
    swVersion
  };
};

export const getModelReportFields = request => {
  const {
    model,
    wirelessToggled,
    certCategory
    // certGroups,
    // certGroupsToggled,
    // verifiedGcf,
    // verifiedWifi
  } = request;

  // WARNING: keep the following logic for possible updates later
  // const gcf = _.find(certGroups, { id: 2 });
  // const wifi = _.find(certGroups, { id: 3 });
  // const gcfToggled = _.includes(certGroupsToggled, certProgramms.gcf);
  // const wifiToggled = _.includes(certGroupsToggled, certProgramms.wifi);

  // prepare device model data to change
  return {
    id: model.id,
    // gcfcertified: gcf ? gcfToggled : verifiedGcf,
    // wifialliancecertified: wifi ? wifiToggled : verifiedWifi,
    devicemodelwirelesscapabilities: getToggledWireless(wirelessToggled),
    certcategoryid: certCategory
  };
};

export const testStatusBadges = {
  [testStatusIds.pending]: {
    badgeTitle: testStatuses.pending,
    badgeType: "secondary"
  },
  [testStatusIds.accepted]: {
    badgeTitle: testStatuses.accepted,
    badgeType: "warning"
  },
  [testStatusIds.rejected]: {
    badgeTitle: testStatuses.rejected,
    badgeType: "danger"
  },
  [testStatusIds.underReview]: {
    badgeTitle: testStatuses.underReview,
    badgeType: "info"
  },
  [testStatusIds.complete]: {
    badgeTitle: testStatuses.complete,
    badgeType: "success"
  },
  0: {
    badgeTitle: "Unknown",
    badgeType: "info"
  }
};

export const renderTestStatusBadge = statusId => {
  if (!statusId) return null;

  const badgeInfo = _.has(testStatusBadges, statusId)
    ? testStatusBadges[statusId]
    : testStatusBadges[0];

  return <Badge variant={badgeInfo.badgeType}>{badgeInfo.badgeTitle}</Badge>;
};

export const getTestPermissions = (app, user) => {
  if (app.isCyberCert) return user.canEditRequest;
  if (app.isOTACert) return user.canEditTests;
  if (app.isDHRCert) return user.canEditTests;

  return false;
};

export const filterOtaTestsByLab = (tests, user) => {
  if (!tests || _.size(tests) === 0) return [];

  return _.filter(tests, test => test.labid === user.companyid);
};

export function hasOtaReports(reports) {
  if (!reports.length) return false;

  return reports.some(report => _.has(report, "documentid"));
}

export function countOtaReports(reports) {
  if (!reports.length) return 0;

  const filtered = _.filter(reports, "documentid");
  return filtered.length;
}

export function getTestPlanStatusId(details) {
  if (!details) return testStatusIds.pending;
  if (_.has(details, "requestteststatusid")) return details.requestteststatusid;
  if (_.has(details, "REQUESTTESTSTATUSID")) return details.REQUESTTESTSTATUSID;

  return testStatusIds.pending;
}

export const findTestModule = (testList, testId) => {
  return _.find(testList, ["id", Number(testId)]);
};

export const getOtaPlanFilePrefix = testModule => {
  const unknownPrefix = "Unknown-OTA-Type";

  if (!testModule || !_.has(testModule, "interface")) return unknownPrefix;

  return testModule.interface.name.split(" ").join("_") || unknownPrefix;
};

export const getLabDecisionStatus = decision => {
  if (decision === true) return testStatusIds.accepted;
  if (decision === false) return testStatusIds.rejected;

  return testStatusIds.pending;
};

export const mapLabContactInfo = lab => {
  if (!lab) return [];

  return [
    {
      value: lab.countrydisplay,
      label: "Country",
      show: true
    },
    {
      value: lab.contactname || "n/a",
      label: "Contact Name",
      show: true
    },
    {
      value: lab.contactemail || "n/a",
      label: "Contact Email",
      show: true
    }
  ];
};

export function hasTestReportsBLC(reports, results) {
  // reports - previously uploaded documents
  // results - files from current form state

  // BLTR = Battery Life Test Report
  let bltrReportValid = false;
  let bltrResultValid = false;

  // DUT = DUT ¯\_(ツ)_/¯
  let dutReportValid = false;
  let dutResultValid = false;

  // in case of BLC we need to worry about slots 1&2
  // since they're mandatory for test results submission

  // 10/27/2022: They've said they want to be able to save without having uploaded the required reports,
  // so the below validation for those fields is being commented out, and this will simply return true

  // if (reports && reports.length) {
  //   const bltrReport = reports[0];
  //   bltrReportValid = bltrReport.docId > 0;

  //   const dutReport = reports[1];
  //   dutReportValid = dutReport.docId > 0;
  // }

  // if (results.length) {
  //   const blcRequiredReports = _.slice(results, 0, 2);
  //   const bltrResult = blcRequiredReports[0];
  //   const dutResult = blcRequiredReports[1];

  //   bltrResultValid = Boolean(bltrResult.file && bltrResult.title);
  //   dutResultValid = Boolean(dutResult.file && dutResult.title);
  // }

  // const bltrValid = bltrReportValid || bltrResultValid;
  // const dutValid = dutReportValid || dutResultValid;

  // return bltrValid && dutValid;

  return true;
}
