export const validateOrgFormASC = form => {
  // validate required selector fields
  const { country, state, status, level } = form;

  if (country.value === "none") return false;
  if (country.value === "US" || country.value === "CA") {
    if (!!state === false || state.value === "none") return false;
  }

  if (status.value === "none" || level.value === "none") return false;

  return true;
};
