// React/Redux imports
import React, { useEffect, useState } from "react";

// Helper/Internal UI imports
import _ from "lodash";
import NoTableData from "components/NoTableData";

// Components imports
import CertifiedPendingReportTableRow from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Table/Components/TableRow";

const CertifiedPendingReportTableBody = ({
  selectedCertType = "",
  showDateCertified,
  showRequestType,
  tableData,
}) => {
  const defaultTableSectionSize = 50;
  const delayInMSBetweenGroups = 10; // 10ms between rendering groups

  const [tableBody, setTableBody] = useState([]);

  useEffect(() => {
    // Clear loaded rows when the table data passed in has changed.
    setTableBody(() => {
      return [];
    });
    let isMounted = true;

    const getTableRow = (row, rowIndex) => {
      return (
        <CertifiedPendingReportTableRow
          certificationType={selectedCertType}
          dateCertified={row.completedat}
          dateSubmitted={row.createdat}
          formattedTransactionNumber={row.formatedtransactionnumber}
          key={rowIndex}
          manufacturer={row.vendorname}
          modelId={row.devicemodelid}
          modelName={row.vendorproductname}
          deviceType={row.devicetype}
          requestNumber={row.id}
          requestStatus={row.requeststatusid}
          requestType={row.name}
          showDateCertified={showDateCertified}
          showRequestType={showRequestType}
        />
      );
    };

    const loadTableSection = ({
      tableSection = 0,
      tableSectionSize = defaultTableSectionSize,
    }) => {
      const startingIndex = tableSection * tableSectionSize;
      const endingIndex =
        Math.min(startingIndex + tableSectionSize, _.size(tableData));

      const newTableSection = tableData
        .slice(startingIndex, endingIndex)
        .map((row, rowInSectionIndex) =>
          getTableRow(row, startingIndex + rowInSectionIndex)
        );

      const loadTableSection = () => {
        // Case: Component has unmounted, ignore call to add body section.
        if (!isMounted) return;

        setTableBody((tableBodyState) => {
          return [...tableBodyState, ...newTableSection];
        });
      };

      // Case: Load the first section immediately
      if (tableSection === 0) {
        loadTableSection();
      }
      // Case: Lazy load the rest of the table sections
      else {
        const delayTime = delayInMSBetweenGroups * tableSection;
        setTimeout(loadTableSection, delayTime);
      }
    };

    const loadTableBody = () => {
      if (_.isEmpty(tableData)) return;

      const numberOfTableSections = Math.ceil(
        _.size(tableData) / defaultTableSectionSize
      );

      for (let currentTableSection of _.range(0, numberOfTableSections)) {
        loadTableSection({ tableSection: currentTableSection });
      }
    };

    loadTableBody();

    return function cleanup() {
      isMounted = false;
    };
  }, [selectedCertType, showDateCertified, showRequestType, tableData]);

  const getFormattedTableBody = (tableData) => {
    let numColumns = 5;
    if (showDateCertified) numColumns += 1;
    if (showRequestType) numColumns += 1;

    if (!tableData || _.isEmpty(tableData)) {
      return <NoTableData colSpan={numColumns} />;
    }

    return tableBody;
  };

  const data = tableData ? tableData : [];
  return getFormattedTableBody(data);
};

export default CertifiedPendingReportTableBody;
