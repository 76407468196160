import React from "react";
import { Bubble } from "ctia-ui";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import FormElement from "components/FormElement";
import RequestControls from "containers/request/RequestControls";
import { handleInputChange } from "actions/request";
import { submitSAR, submitRenewal, updateRequest } from "actions/battery/sar";
import { isRenewal } from "helpers/RequestHelper";

class RequestComment extends React.Component {
  requestField = "vendorNote";

  onSave = () => {
    this.props.updateRequest(this.props.request);
  };

  onSubmit = () => {
    const { request, sites, submitSAR, submitRenewal } = this.props;

    if (isRenewal(request.type)) {
      return submitRenewal(request);
    }

    return submitSAR(request, sites.current);
  };

  render() {
    const { request, handleInputChange } = this.props;

    return (
      <div>
        <div>
          <Bubble type="warning" show={true}>
            You can add any comments, but this is optional.
          </Bubble>
        </div>
        <div>
          <Row>
            <FormElement
              type="textarea"
              id={this.requestField}
              label="Optional Comments"
              value={request[this.requestField]}
              onChange={handleInputChange}
              style={{ height: "auto" }}
            />
          </Row>
        </div>
        <div>
          <RequestControls
            isValid={true}
            onSave={this.onSave}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps, {
  handleInputChange,
  submitSAR,
  submitRenewal,
  updateRequest
})(RequestComment);
