import { Bubble, Divider, LoaderButton } from "ctia-ui";
import { Col, ControlLabel, FormGroup, FormControl, Row } from "react-bootstrap";
import { validateEmail } from "helpers/ValidatorHelper";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPassword } from "actions/user";
import SuccessAlert from "./ForgotSuccess";
import FailureAlert from "./ForgotFailure";
import LogoPng from "containers/Logo/LogoPng";
import ReturnHome from "components/ReturnHome";

const ForgotPasswordForm = ({ forgotPassword, handleSubmit }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const isValidEmail = validateEmail(email);

  const submissionCallback = wasSuccessful => {
    setIsLoading(false);
    setValidationResult(wasSuccessful);
  };

  const handleSubmission = e => {
    e.preventDefault();
    setIsLoading(true);
    forgotPassword(email, submissionCallback);
  };

  const resetPage = () => {
    setEmail("");
    setValidationResult(null);
  };

  const forgotPasswordForm = (
    <div>
      <div>
        <Bubble>
          To have a new temporary password sent to you, please enter your email
          address below.
        </Bubble>
        <Divider />
      </div>
      <form onSubmit={handleSubmission}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email:</ControlLabel>
          <FormControl
            type="text"
            name="email"
            value={email}
            placeholder="Enter your email"
            onChange={e => setEmail(e.target.value)}
            autoFocus={true}
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          bsStyle="info"
          type="submit"
          disabled={!isValidEmail}
          isLoading={isLoading}
          text="Submit"
          loadingText="Loading…"
        />
      </form>
      <Divider />
      <Row>
        <Col md={4}/>
        <Col md={4}>
          <ReturnHome/>
        </Col>
        <Col md={4}/>
      </Row>
    </div>
  );

  function childComponent() {
    switch (validationResult) {
      case true:
        return <SuccessAlert />;
      case false:
        return <FailureAlert resetPage={resetPage} />;
      default:
        return forgotPasswordForm;
    }
  }

  return (
    <div>
      <div className="logo text-center">
        <Link to="/">
          <LogoPng />
        </Link>
      </div>
      <div className="block-top-offset">{childComponent()}</div>
    </div>
  );
};

export default connect(null, { forgotPassword })(ForgotPasswordForm);
