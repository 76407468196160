import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { AddButton, Divider } from "ctia-ui";
import { addTestPlan, addOtaTest } from "actions/ota";
import {
  validateTestPlanFields,
  requestFields,
  getOtaCost
} from "helpers/OTATestPlanHelper";
import { isExisted } from "helpers/RequestHelper";

const TestPlanAdd = ({ request, ota, addTestPlan, addOtaTest }) => {
  // do not render a button if test plan info has not ready yet
  if (!validateTestPlanFields(request)) return null;

  const getPlan = () => {
    const planInfo = request[requestFields.otaPlan];
    const costAmount = getOtaCost(ota.costs, planInfo.costId).amount;

    return {
      planInfo: { ...planInfo, costAmount },
      catlInfo: request[requestFields.otaLab],
      requestId: request.id
    };
  };

  const addHandler = () => {
    const testPlan = getPlan();

    return isExisted(request) ? addOtaTest(testPlan) : addTestPlan(testPlan);
  };

  return (
    <div>
      <Divider />
      <FormElement
        type="actionButton"
        id="addTestPlanButton"
        buttonComponent={AddButton}
        buttonLabel="Add Test Plan"
        onClick={addHandler}
        customClassName="btn-shadow"
      />
    </div>
  );
};

function mapStateToProps({ request, ota }) {
  return { request, ota };
}

export default connect(mapStateToProps, {
  addTestPlan,
  addOtaTest
})(TestPlanAdd);
