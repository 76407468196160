import React from "react";
import _ from "lodash";
import roles from "dictionaries/UserRoles";
import delims from "dictionaries/phoneExtDelim";
import { getSession, clearSession } from "libs/sessionStorage";
import { haveDaysPassed } from "helpers/DateHelper";
import { certProgramms } from "helpers/AppHelper";
import {
  checkPasswordRequirements,
  validateEmail,
  validatePhone
} from "helpers/ValidatorHelper";

import * as dbRoles from "dictionaries/Roles";

const setPortalsAccess = typesList => {
  const portals = typesList.split(",");
  return portals.map(type => certProgramms[type]);
};

export function isVendor(role) {
  return role === roles.vendor;
}

export function isStaff(role) {
  return role === roles.staff;
}

export function isLab(role) {
  return role === roles.lab;
}

export function isAccountant(role) {
  return role === roles.accountant;
}

export function isOperator(role) {
  return role === roles.operator;
}

export function isSupplier(role) {
  return role === roles.supplier;
}

export function isServiceCenter(role) {
  return role === roles.serviceCenter;
}

/**
 * Returns user role code from dictionary or null.
 *
 * @param {object} user
 * @returns number || null
 */
export const defineUserRole = user => {
  if (!user || !user.role) return null;

  switch (user.role) {
    case "ADMIN":
    case "Staff":
      return roles.staff;
    case "VENDOR":
    case "Full Member":
      return roles.vendor;
    case "LAB":
    case "Test Lab":
      return roles.lab;
    case "ACCOUNTING":
      return roles.accountant;
    case "OPERATOR":
      return roles.operator;
    case "SUPPLIER":
      return roles.supplier;
    case "SERVICECENTER":
      return roles.serviceCenter;
    default:
      return null;
  }
};

/**
 * Creates formatted users' first and last name.
 *
 * @param {object} user
 * @returns string
 */
export function getFullName(user) {
  if (!user) return "n/a";
  if (_.has(user, "fullname")) return user.fullname;

  const hasFirstName = _.has(user, "firstname") && user.firstname !== "";
  const hasLastName = _.has(user, "lastname") && user.lastname !== "";

  if (hasFirstName) {
    return hasLastName ? `${user.firstname} ${user.lastname}` : user.firstname;
  } else if (hasLastName) return user.lastname;

  return "n/a";
}

/**
 * Defines string prefix based on user`s role.
 *
 * @param {object} user
 * @returns string || null
 */
export const getUserPrefix = user => {
  if (!user || !user.role) return null;

  // TODO: use real backend flag to define if supplier
  if (user.isSupplier) return "supplier";

  switch (user.role) {
    case roles.staff:
      return "admin";
    case roles.vendor:
      return "vendor";
    case roles.lab:
      return "lab";
    case roles.operator:
      return "operator";
    case roles.accountant:
      return "accountant";
    default:
      return null;
  }
};

/**
 * Remove token from localStorage,
 * clear user browser session and force
 * redirect user to homepage.
 */
export function sayGoodbye() {
  localStorage.removeItem("token");
  clearSession();
  window.location = "/";
  return null;
}

export const USER_INITIAL_STATE = {
  token: null,
  errorMessage: "",
  location: "/"
};

/**
 * Set initial state for user`s reducer.
 */
export const setInitialState = () => {
  const user = getSession("user");
  return _.has(user, "token") ? user : USER_INITIAL_STATE;
};

export const setUserInfo = user => {
  const role = defineUserRole(user);
  return {
    ...user,
    name: getFullName(user),
    role,
    accessError: false,
    isVendor: isVendor(role),
    isLab: isLab(role),
    isStaff: isStaff(role),
    isAccountant: isAccountant(role),
    isOperator: isOperator(role),
    isSupplier: isSupplier(role),
    isServiceCenter: isServiceCenter(role),
    accessiblePortals: setPortalsAccess(user.accessibleportals),
    errorMessage: "",
    // CATL
    isCATL: user.isActiveCATL === 1,
    isCATLAgreementSigned: user.CATLAnualLicenseAgreementSigned === 1,
    isCATLFeePaid: user.CATLAnualLicenseFeePaid === 1,
    isCATLFeeExists: user.CATLAnualLicenseFeeExists === 1,
    atlInvoiceNumber: user.CATLAnualLicenseFeeNum,
    // ASC
    isASC: user.isActiveASC === 1,
    isASCAgreementSigned: user.ASCAnualLicenseAgreementSigned === 1,
    isASCFeePaid: user.ASCAnualLicenseFeePaid === 1,
    isASCFeeExists: user.ASCAnualLicenseFeeExists === 1,
    ascInvoiceNumber: user.ASCAnualLicenseFeeNum,
    test: 'here'
  };
};

/**
 * Check whether user accepted Terms of Use.
 *
 * @param {object} user
 * @returns boolean
 */
export const hasTermsAccepted = user => {
  if (!user || !user.id || !user.token) return true;

  return (
    !_.isEmpty(String(user.ctiaagreementflag)) && user.ctiaagreementflag > 0
  );
};

export const getUserListPostfix = userType => {
  switch (userType) {
    case roles.vendor:
      return "VENDOR";
    case roles.lab:
      return "LAB";
    case roles.operator:
      return "OPERATOR";
    case roles.accountant:
      return "ACCOUNTING";
    case roles.staff:
      return "ADMIN";
    case roles.supplier:
      return "SUPPLIER";
    case roles.serviceCenter:
      return "SERVICECENTER";
    case roles.unassigned:
      return "UNASSIGNED";
    default:
      return "";
  }
};

export const getUserTypeTitle = userType => {
  switch (userType) {
    case roles.vendor:
      return "Vendor";
    case roles.lab:
      return "Laboratory";
    case roles.operator:
      return "Operator";
    case roles.accountant:
      return "Accountant";
    case roles.staff:
      return "CTIA Staff";
    case roles.supplier:
      return "Supplier";
    case roles.serviceCenter:
      return "Service Center";
    default:
      return "All";
  }
};

export const isUserActive = timeStamp => {
  return !haveDaysPassed(timeStamp, 180);
};

export const getRequestOwner = (request, user) => {
  if (user.isVendor || user.isSupplier) return user.companyid;
  if (user.isStaff || user.isLab || user.isAccountant || user.isOperator)
    return request.submitter.companyid;
  return null;
};

export const generateUserNameFromFullName = (firstname, lastname) => {
  if (!_.isString(firstname) || !_.isString(lastname)) return "";

  const firstLetter = firstname.charAt(0).toUpperCase();
  const formattedLastName = _.startCase(_.toLower(lastname));

  return `${firstLetter}${formattedLastName}`;
};

export const validateUserMainFormFields = form => {
  // validate required selector fields
  const {
    country,
    company,
    email,
    state,
    userType,
    workPhone,
    workPhoneExt,
    mobilePhone
  } = form;

  if (country.value === "none") return false;
  if (country.value === "US" || country.value === "CA") {
    if (!!state === false || state.value === "none") return false;
  }

  if (userType.selectedIndex === 0) return false;
  if (company && company.value === "none") return false;

  if (!validateEmail(email.value)) return false;

  if (!validatePhone(workPhone.value)) return false;
  if (workPhoneExt && workPhoneExt.value && !validatePhone(workPhoneExt.value))
    return false;
  if (mobilePhone && mobilePhone.value && !validatePhone(mobilePhone.value))
    return false;

  return true;
};

export const awaitingTempPassChange = user => {
  if (!user) return false;

  const forceTempPass = localStorage.getItem("forceTempPass");

  return Boolean(forceTempPass === "true" || user.forceTempPass);
};

export const validateUserAddForm = form => {
  if (!validateUserMainFormFields(form)) return false;

  const { password } = form;
  if (password && !checkPasswordRequirements(password.value)) return false;

  return true;
};

export const mergeExtPhone = (workPhone, workPhoneExt) => {
  return workPhoneExt ? `${workPhone} Ext. ${workPhoneExt}` : workPhone;
};

export const separatePhoneExt = workphone => {
  if (!workphone) return { workphone, workphoneext: "" };

  const delimRegExp = new RegExp(delims.join("|"), "y");
  const digits = String(workphone).split(delimRegExp);
  const residueRE = /[a-zA-Z\\.()]/;

  return digits &&
    digits.length > 1 &&
    digits[0] &&
    digits[1] &&
    !residueRE.test(digits[0]) &&
    !residueRE.test(digits[1])
    ? { workphone: digits[0].trim(), workphoneext: digits[1].trim() }
    : { workphone, workphoneext: "" };
};

export const getUserCompanyId = (userData, role) => {
  switch (role) {
    case dbRoles.DB_VENDOR:
    case dbRoles.DB_SUPPLIER:
      return userData.vendorid;
    case dbRoles.DB_LAB:
      return userData.labid;
    case dbRoles.DB_OPERATOR:
      return userData.operatorid;
    case dbRoles.DB_ASC:
      return userData.servicecenterid;
    default:
      return "none";
  }
};

/**
  There are 4 different status for users in order of precedence:
  1) Deleted - check `deletedat` is empty
  2) Inactive - check Active: -1
  3) Locked - check Locked : 1
  4) Active = Active: 1
  
  If user has inactive and locked status then inactive takes precedence. 

 * @param {object} userData User related details
 * @returns JSX
 */
export const renderUserStatus = userData => {
  const status = { title: "ACTIVE", color: "green" };

  if (userData.deletedat !== "") {
    status.title = "DELETED";
    status.color = "red";
  } else if (userData.active === -1) {
    status.title = "INACTIVE";
    status.color = "teal";
  } else if (userData.locked === 1) {
    status.title = "LOCKED";
    status.color = "orange";
  }

  return <span className={status.color}>{status.title}</span>;
};
