import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";

const BatteryPackReadonly = ({ request, suppliers }) => {
  if (!suppliers || !request.batteryPackId) return null;

  const { certifiedBSCPackRequests: list } = suppliers;
  const selectedBattery = _.filter(list, ["value", request.batteryPackId]);

  const renderEmptyMsg = () => (
    <p className="dark-teal bold form-control-static">No battery selected</p>
  );

  return (
    <FormElement
      label="Battery"
      list={selectedBattery}
      id="battery"
      type="listOfLinksBSC"
      emptyMsg={renderEmptyMsg()}
    />
  );
};

function mapStateToProps({ request, suppliers }) {
  return { request, suppliers };
}

export default connect(mapStateToProps)(BatteryPackReadonly);
