import React, { useEffect } from "react";
import { connect } from "react-redux";
import { resetCertProgramStatus } from "actions/app";
import BackHome from "containers/BackHome";
import TermsContent from "containers/TermsOfUse/TermsContent";
import { scrollTo } from "helpers/AppHelper";
import "containers/TermsOfUse/terms.css";

const Terms = ({ app, user, resetCertProgramStatus }) => {
  useEffect(() => {
    if (app.certProgram) {
      // cleanup selected program
      resetCertProgramStatus();
    }

    scrollTo("terms-tag");
  }, [app, resetCertProgramStatus]);

  const wrapperClass = user && user.token ? "" : "terms";

  return (
    <div className={wrapperClass}>
      <BackHome />
      <h1 id="terms-tag">Terms of Use</h1>
      <TermsContent />
    </div>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, {
  resetCertProgramStatus
})(Terms);
