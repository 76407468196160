import React, { Component } from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/sitesSar";
import { fetchCountryStates } from "actions/countries";
import { getCountryStatesList } from "helpers/Countries";

class SiteAddressState extends Component {
  componentDidMount() {
    const { sites } = this.props;

    if (sites && sites.current && sites.current.manufSiteCountry) {
      this.getStates();
    }
  }

  componentDidUpdate(prevProps) {
    const country = this.props.sites.current.manufSiteCountry;
    const prevCountry = prevProps.sites.current.manufSiteCountry;

    // if selected new country (non empty)
    if (country !== "" && prevCountry === "") {
      return this.getStates();
    }

    // check if prev (non empty) country was changed
    if (country !== "" && country !== prevCountry) {
      return this.getStates();
    }
  }

  getStates = () => {
    this.setLoadingState(true);

    return this.props.fetchCountryStates(
      this.props.sites.current.manufSiteCountry,
      () => this.setLoadingState(false)
    );
  };

  setLoadingState = value => this.setState({ isLoading: value });

  render() {
    const { sites, user, countries, app, handleInputChange } = this.props;
    const { manufSiteCountry, manufSiteState } = sites.current;
    const isEditable = user.canEditManufsite;

    const elementParams = { type: "text" };

    // if selected country has states need to show states list
    if (
      manufSiteCountry &&
      manufSiteCountry.length > 0 &&
      countries &&
      countries.states
    ) {
      elementParams.type = "select";
      elementParams.options = getCountryStatesList(countries.states);
    }

    return (
      <FormElement
        {...elementParams}
        label="State"
        editable={isEditable && !app.isSubmitting}
        id="manufSiteState"
        onChange={handleInputChange}
        value={manufSiteState}
        placeholder={app.isSubmitting ? "loading..." : ""}
      />
    );
  }
}

function mapStateToProps({ sites, user, countries, app }) {
  return { sites, user, countries, app };
}

export default connect(mapStateToProps, {
  handleInputChange,
  fetchCountryStates
})(SiteAddressState);
