import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormElement from 'components/FormElement';
import { handleInputChange } from 'actions/request';
import { fetchCountryStates } from 'actions/countries';
import { getCountryStatesList } from 'helpers/Countries';

class BillingAddressState extends Component {
  componentDidUpdate(prevProps) {
    const country = this.props.request.billingAddressCountry;
    const prevCountry = prevProps.request.billingAddressCountry;

    // if selected new country (non empty)
    if (country > 0 && prevCountry === 0) {
      return this.getStates();
    }

    // check if prev (non empty) country was changed
    if (country !== 0 && country !== prevCountry) {
      return this.getStates();
    }
  }

  getStates = () => {
    this.setLoadingState(true);

    return this.props.fetchCountryStates(
      this.props.request.billingAddressCountry,
      () => this.setLoadingState(false)
    );
  };

  setLoadingState = value => this.setState({ isLoading: value });

  render() {
    const { request, user, countries, app, handleInputChange } = this.props;
    const { billingAddressCountry } = request;
    const isEditable = user.canEditRequest || user.canEditBilling;

    const elementParams = { type: 'text' };

    // if selected country has states need to show states list
    if (
      billingAddressCountry &&
      billingAddressCountry.length > 0 &&
      countries.states
    ) {
      elementParams.type = 'select';
      elementParams.options = getCountryStatesList(countries.states);
    }

    return (
      <FormElement
        {...elementParams}
        label="State"
        editable={isEditable && !app.isSubmitting}
        id="billingAddressState"
        onChange={handleInputChange}
        value={request.billingAddressState}
        placeholder={app.isSubmitting ? 'loading...' : ''}
      />
    );
  }
}

function mapStateToProps({ request, user, countries, app }) {
  return { request, user, countries, app };
}

export default connect(
  mapStateToProps,
  {
    handleInputChange,
    fetchCountryStates
  }
)(BillingAddressState);
