import React, { useState, useCallback } from "react";
import { Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import Clear from "components/form/Clear";
import _ from "lodash";

const ListFilter = ({
  label,
  onChange,
  inputStyle = { width: 250, margin: 15 }
}) => {
  const [term, setTerm] = useState("");

  const debounceFilter = useCallback(
    _.debounce(value => onChange(value), 1000),
    []
  );

  const debounceClear = useCallback(
    _.debounce(value => onChange(value), 100),
    []
  );

  const onType = (value, debounce) => {
    setTerm(value);
    if (debounce) debounceFilter(value);
    else debounceClear(value);
  };

  return (
    <Form inline bsSize="small" onSubmit={e => e.preventDefault()}>
      <FormGroup controlId="filterTerm" bsSize="small">
        <ControlLabel bsSize="small">{label}:</ControlLabel>
        <FormControl
          type="text"
          value={term}
          onChange={e => onType(e.target.value, true)}
          style={inputStyle}
        />
        <Clear handler={() => onType("", false)} />
      </FormGroup>
    </Form>
  );
};

export default ListFilter;
