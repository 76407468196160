import _ from "lodash";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import FormElement from "components/FormElement";
import { handleInputChange } from "actions/request";
import { emptyMsg } from "helpers/FormHelper";

const RequestRevision = ({
  isRequired = true,
  fieldId,
  request,
  handleInputChange,
  editable
}) => {
  const [touched, setTouched] = useState(false);
  const inputValue = String(request[fieldId]);

  const validate = () => {
    if (!touched) return null;

    return isRequired && _.isEmpty(inputValue) ? "error" : null;
  };

  useEffect(() => {
    if (!touched && !_.isEmpty(inputValue)) {
      setTouched(true);
    }
  }, [inputValue, touched]);

  return (
    <FormElement
      id={fieldId}
      label="Revision Number"
      value={request[fieldId]}
      type="textarea"
      onChange={handleInputChange}
      validationState={validate()}
      validationMessage={emptyMsg}
      editable={editable}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { handleInputChange })(RequestRevision);
