import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { setValue } from "actions/request";
import {
  getAvailableTypes,
  requestFields
} from "helpers/AdapterSupplierHelper";

class SelectAdapterType extends React.Component {
  requestField = requestFields.type;

  onChange = event => {
    const { setValue } = this.props;
    const adapterType = event.target.value;

    // reset batteryModel value
    setValue(requestFields.model, "");

    // reset batteryModelOption value
    setValue(requestFields.modelOption, undefined);

    // reset batteryRevision value
    setValue(requestFields.revision, "");

    // set adapterType value
    setValue(this.requestField, adapterType);
  };

  render() {
    const { request } = this.props;
    const canSkipSelection = _.isEmpty(request.adapterSuppliers);

    // need to remove 'No Adapter' option if at least 1 was added:
    const options = getAvailableTypes(canSkipSelection);

    return (
      <FormElement
        type="select"
        id={this.requestField}
        label="Adapter Type"
        value={request[this.requestField] || 0}
        options={options}
        onChange={this.onChange}
      />
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { setValue })(SelectAdapterType);
