import React from "react";
import { WithModal } from "ctia-ui";
import AddButton from "components/form/buttons/AddButton";
import AddCompanyModal from "components/Companies/AddCompanyModal/AddCompanyModal";

class AddLabOrg extends WithModal {
  render() {
    return (
      <div>
        <AddButton onClick={this.handleModal} />
        <AddCompanyModal
          companyType="lab"
          modalHandler={this.handleModal}
          show={this.state.showModal}
        />
      </div>
    );
  }
}

export default AddLabOrg;
