import _ from "lodash";
import React from "react";
import { Alert } from "react-bootstrap";

const FormSuccess = ({ message }) => {
  if (_.isUndefined(message) || _.isNull(message) || _.isEmpty(message))
    return null;

  return (
    <Alert bsStyle="success">
      { message }
    </Alert>
  );
};

export default FormSuccess;