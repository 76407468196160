import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { FormGroup, Col } from "react-bootstrap";
import {
  makeEmaiBillinglValidation,
  makePhoneValidation
} from "helpers/ValidatorHelper";
import { handleInputChange } from "actions/request";

const BillingPocAdd = ({ handleInputChange, isEditable, request, show }) => {
  if (!show) return null;

  const emailValidation = makeEmaiBillinglValidation(request);
  const phoneValidation = makePhoneValidation(request.billingPocPhone);
  const extValidation = makePhoneValidation(request.billingPocPhoneExt);

  return (
    <div>
      <FormElement
        type="text"
        id="billingPocFirstName"
        label="First Name"
        value={request.billingPocFirstName}
        onChange={handleInputChange}
        placeholder="First name for Billing PoC"
        editable={isEditable}
        maxLength={50}
      />
      <FormElement
        type="text"
        id="billingPocLastName"
        label="Last Name"
        value={request.billingPocLastName}
        onChange={handleInputChange}
        placeholder="Last Name for Billing PoC"
        editable={isEditable}
        maxLength={50}
      />
      <FormElement
        type="text"
        id="billingPocEmail"
        label="Billing PoC Email"
        value={request.billingPocEmail}
        onChange={handleInputChange}
        placeholder="Please enter a valid email address"
        validationState={emailValidation.state}
        validationMessage={emailValidation.message}
        editable={isEditable}
        maxLength={50}
      />
      <FormGroup>
        <Col xs={7} sm={9} md={9} style={{ display: "inline-block" }}>
          <FormElement
            type="text"
            id="billingPocPhone"
            label="Billing PoC Phone"
            value={request.billingPocPhone}
            onChange={handleInputChange}
            placeholder="Please enter a contact phone number"
            validationState={phoneValidation.state}
            validationMessage={phoneValidation.message}
            editable={isEditable}
            maxLength={18}
            labelColSizeSm={4}
            labelColSizeMd={4}
            inputColSizeSm={8}
            inputColSizeMd={8}
          />
        </Col>
        <Col xs={5} sm={3} md={3} style={{ display: "inline-block" }}>
          <FormElement
            type="text"
            id="billingPocPhoneExt"
            label="Ext."
            value={request.billingPocPhoneExt}
            onChange={handleInputChange}
            placeholder="Extension Number"
            editable={isEditable}
            maxLength={10}
            validationState={extValidation.state}
            validationMessage={extValidation.message}
          />
        </Col>
      </FormGroup>
    </div>
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { handleInputChange })(BillingPocAdd);
