import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SupportDocs from "components/SupportDocs";
import RequestControls from "containers/request/RequestControls";
import { saveRequest } from "actions/request";

const Documents = ({ saveRequest, show, user }) => {
  if (!show) return null;

  const saveHandler = user.canUploadRequestDocs ? saveRequest : null;
  return (
    <div>
      <SupportDocs />
      <RequestControls isValid={true} onSave={saveHandler} />
    </div>
  );
};

Documents.propTypes = {
  show: PropTypes.bool
};

Documents.defaultProps = {
  show: true
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  saveRequest
})(Documents);
