import React, { Component } from "react";
import { connect } from "react-redux";
import SubmissionRender from "containers/submission/SubmissionRender";
import { runInitialWizardBLC } from "actions/battery/blcRequest";
import { clearRequest } from "actions/request";
import { clearWizard } from "actions/wizard";

class InitialSubmitBLC extends Component {
  componentDidMount() {
    this.props.runInitialWizardBLC(this.props.user);
  }

  componentWillUnmount() {
    this.props.clearRequest();
    this.props.clearWizard();
  }

  render() {
    return <SubmissionRender history={this.props.history} />;
  }
}

export default connect(null, {
  runInitialWizardBLC,
  clearRequest,
  clearWizard
})(InitialSubmitBLC);
