import _ from "lodash";
import React, { Component } from "react";
import Select, { Option } from "rc-select";
import { makeEvent } from "libs/form";
import "styles/rc-select.css";

class Combobox extends Component {
  componentDidUpdate(prevProps) {
    const diff = _.difference(this.props.options, prevProps.options);

    if (_.size(diff) > 0) {
      this.setState({ optionsList: this.renderOptions(this.props.options) });
    }
  }

  dropdownMenuStyle = () => {
    let style = {
      maxHeight: 200,
      overflowX: "hidden"
    };

    if (this.props.zIndex) {
      style['position'] = 'relative';
      style['zIndex'] = this.props.zIndex;
    }

    return style;
  };

  renderOptions = options => {
    if (!options || !options.length) return [];

    return options.map(type => {
      const { title, value } = type;

      // trick to use id (value) as an option's value
      const params = this.props.useIdAsValue
        ? { key: title }
        : { key: value, value: title };

      return <Option {...params}>{title}</Option>;
    });
  };

  // pre-populate options by default
  state = {
    optionsList: this.renderOptions(this.props.options)
  };

  onChangeHandler = value => {
    const syntEvent = makeEvent(this.props.id, value);
    return this.props.onChange(syntEvent);
  };

  render() {
    return (
      <Select
        value={this.props.value}
        onChange={this.onChangeHandler}
        disabled={!this.props.editable}
        filterOption={true}
        style={{ width: "100%" }}
        dropdownMenuStyle={this.dropdownMenuStyle()}
        optionLabelProp="children"
        optionFilterProp="value"
        placeholder=""
        combobox
        backfill
        allowClear
        notFoundContent=""
        {...this.props.customOptions}
      >
        {this.state.optionsList}
      </Select>
    );
  }
}

export default Combobox;
