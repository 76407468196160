import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import FormElement from 'components/FormElement';
// import VerifyCertification from 'containers/testResult/VerifyCertification';
import CertCats from 'containers/testResult/LabCertCats';
import DeviceWireless from 'containers/request/Initial/Steps/Vendor/DeviceInfo/DeviceWireless';
import { testResults } from 'dictionaries/TestResults';
import {
  isResultNoTesting,
  getTestResultTitle
} from 'helpers/RequestTestsHelper';
import { toMomentDate } from 'helpers/DateHelper';
import { makeEvent } from 'libs/form';
// import { hasLteSupport, hasWifiSupport } from 'helpers/Wireless';
import {
  changeTestField,
  changeTestDate,
  changeTestMultiField
} from 'actions/tests';
import { handleInputChange } from 'actions/request';

class LabResultForm extends Component {
  componentDidMount() {
    const { testModule, changeTestField } = this.props;

    // replace planversion value with alias
    const planversion = testModule.planversion.trim();
    const syntEvent = makeEvent('planversion', planversion);
    changeTestField(testModule.id, syntEvent);
  }

  onResultChange = event => {
    const result = Number(event.target.value);
    const hideUploads = isResultNoTesting(result);

    this.props.handleTestResult(hideUploads);
    this.props.changeTestField(this.props.testModule.id, event);
  };

  render() {
    const {
      show,
      request,
      testModule,
      changeTestDate,
      changeTestField,
      handleInputChange,
      changeTestMultiField
    } = this.props;

    if (!show) return null;

    const { hwVersion, swVersion, testStatus } = request;

    const {
      lab,
      planversion,
      actualenddate,
      actualstartdate,
      requesttestpassedstatusid
    } = testModule;

    const noTesting = isResultNoTesting(requesttestpassedstatusid);
    const startDate = toMomentDate(actualstartdate);
    const endDate = toMomentDate(actualenddate);

    return (
      <div>
        <Form horizontal>
          <FormElement
            id="testStatus"
            label="Test Status"
            value={getTestResultTitle(testStatus)}
          />
          <FormElement
            id="requesttestconductinglabs"
            label="Lab"
            value={lab.name}
            onChange={changeTestMultiField.bind(this, testModule.id)}
          />
          <FormElement
            type="select"
            id="requesttestpassedstatusid"
            label="Test Result"
            value={requesttestpassedstatusid}
            onChange={this.onResultChange.bind(this)}
            options={testResults}
          />
          <FormElement
            type="text"
            id="planversion"
            label="Test Plan Version"
            value={planversion}
            onChange={changeTestField.bind(this, testModule.id)}
            placeholder="Enter version number"
          />
          <FormElement
            type="textarea"
            id="hwVersion"
            label="Hardware Version"
            value={hwVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested hardware version"
          />
          <FormElement
            type="textarea"
            id="swVersion"
            label="Software Version"
            value={swVersion}
            onChange={handleInputChange}
            placeholder="Enter the final tested software version"
          />
          <FormElement
            type="date"
            id="actualstartdate"
            label="Test Start Date"
            value={startDate}
            onChange={changeTestDate.bind(
              this,
              testModule.id,
              'actualstartdate'
            )}
            show={!noTesting}
          />
          <FormElement
            type="date"
            id="actualenddate"
            label="Test End Date"
            value={endDate}
            onChange={changeTestDate.bind(this, testModule.id, 'actualenddate')}
            show={!noTesting}
            minDate={startDate}
          />
          <DeviceWireless editable={request.isInitial} />
          <CertCats />
          {/* <VerifyCertification /> */}
        </Form>
      </div>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(
  mapStateToProps,
  {
    changeTestDate,
    changeTestField,
    changeTestMultiField,
    handleInputChange
  }
)(LabResultForm);
