import { makeSimpleArgs } from "helpers/ArgsHelper";

export const prepareCompanyArgs = form => {
  const ascstatus = form.status.value;
  const countrycode = form.country.value;
  const stateid =
    countrycode === "US" || countrycode === "CA" ? form.state.value : "";

  const contactId = form.contactid ? form.contactid.value : null;
  const contactname = form.contactid
    ? form.contactid.options[form.contactid.selectedIndex].getAttribute(
        "data-name"
      )
    : null;
  const contactemail = form.contactid
    ? form.contactid.options[form.contactid.selectedIndex].getAttribute(
        "data-email"
      )
    : null;
  const comments = form.disableReason ? form.disableReason.value : null;

  const args = {
    name: form.orgName.value,
    address1: form.address1.value,
    address2: form.address2.value,
    address3: form.address3.value,
    city: form.city.value,
    stateid,
    zipcode: form.zip.value,
    countrycode,
    contactid: contactId,
    contactname,
    contactemail,
    ascstatus,
    authorizationlevel: Number(form.level.value),
    comments,
    roleid: 10
  };

  // if we pass empty license date - it'll rewrite the value on DB
  if (ascstatus === "Active") {
    args.asclicensedate = form.authDate.value;
  }

  return makeSimpleArgs(args);
};
