import React, { useState } from "react";
import { connect } from "react-redux";
import { enableOrganization } from "actions/org";

const EnableOrgLink = ({ org, show, enableOrganization }) => {
  const [loading, setLoading] = useState(false);

  // only inactive orgs will be managed by this component
  if (!show) return null;

  if (loading) {
    return <span>Enabling...</span>;
  }

  const onClickHandler = () => {
    setLoading(true);
    enableOrganization(org)
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        setLoading(false);
        console.error(e);
      });
  };

  return (
    <span className="cursor-pointer glow-link" onClick={onClickHandler}>
      Enable
    </span>
  );
};

export default connect(null, {
  enableOrganization
})(EnableOrgLink);
