import {
  CLEAR_CERTIFIED_PENDING_REPORT_DETAILS,
  FETCH_CERTIFIED_PENDING_REPORT_DETAILS
} from "actions/types";
import moment from "moment";

export default function (state = null, action) {
  switch (action.type) {
    case CLEAR_CERTIFIED_PENDING_REPORT_DETAILS:
      return { ...state, list: null, meta: null };
    case FETCH_CERTIFIED_PENDING_REPORT_DETAILS:
      if (!action || !action.payload || !action.payload.data) return state;
      const { data, meta } = action.payload.data;

      const convertedData = data
        ? data.map(row => {
            row.completedat = row.completedat ? moment(row.completedat) : null;
            row.createdat = row.createdat ? moment(row.createdat) : null;
            row.vendorproductname = String(row.vendorproductname);
            return row;
          })
        : [];

      return { ...state, list: convertedData, meta };
    default:
      return state;
  }
}
