import { getTestFieldsCRR as prepTestResultArgCRR } from "helpers/ArgsHelperCRR";
import { prepareTestResultsArgs as prepTestResultArgHRR } from "helpers/ArgsHelperHRR";
import { prepareTestResultsArgs as prepTestResultArgPRR } from "helpers/ArgsHelperPRR";
import { prepareTestResultsArgs as prepTestResultArgEPR } from "helpers/ArgsHelperEPR";
import { prepareTestResultsArgs as prepTestResultArgARR } from "helpers/ArgsHelperARR";
import { prepareTestResultsArgs as prepTestResultArgACR } from "helpers/ArgsHelperACR";
import { prepareTestResultsArgs as prepTestResultArgBLC } from "helpers/ArgsHelperBLC";
import { prepareTestResultsArgs as prepTestResultArgBSC } from "helpers/ArgsHelperBSC";
import { prepareTestResultsArgs as prepTestResultArgOTA } from "helpers/ArgsOtaHelper";
import { prepareSARArgs } from "helpers/ArgsBatteryHelper";

export function getCertTestHandler(request, props) {
  if (!request) return null;

  if (request.isCRR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsCRR,
      updateReportStatusHandler: props.updateTestReportStatusCRR,
      downloadReportHandler: props.downloadReportCRR,
      downloadAllReportsHandler: props.downloadAllReportsCRR,
      sendTestResultsHandler: props.sendTestResultsCRR,
      submitTestResultsHandler: props.submitTestResultsCRR,
      prepTestResultArg: prepTestResultArgCRR
    };
  } else if (request.isHRR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsHRR,
      updateReportStatusHandler: props.updateTestReportStatusHRR,
      downloadReportHandler: props.downloadReportHRR,
      downloadAllReportsHandler: props.downloadAllReportsHRR,
      sendTestResultsHandler: props.sendTestResultsHRR,
      submitTestResultsHandler: props.submitTestResultsHRR,
      prepTestResultArg: prepTestResultArgHRR
    };
  } else if (request.isPRR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsPRR,
      updateReportStatusHandler: props.updateTestReportStatusPRR,
      downloadReportHandler: props.downloadReportPRR,
      downloadAllReportsHandler: props.downloadAllReportsPRR,
      sendTestResultsHandler: props.sendTestResultsPRR,
      submitTestResultsHandler: props.submitTestResultsPRR,
      prepTestResultArg: prepTestResultArgPRR
    };
  } else if (request.isEPRR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsEPR,
      updateReportStatusHandler: props.updateTestReportStatusEPR,
      downloadReportHandler: props.downloadReportEPR,
      downloadAllReportsHandler: props.downloadAllReportsEPR,
      sendTestResultsHandler: props.sendTestResultsEPR,
      submitTestResultsHandler: props.submitTestResultsEPR,
      prepTestResultArg: prepTestResultArgEPR
    };
  } else if (request.isARR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsARR,
      updateReportStatusHandler: props.updateTestReportStatusARR,
      downloadReportHandler: props.downloadReportARR,
      downloadAllReportsHandler: props.downloadAllReportsARR,
      sendTestResultsHandler: props.sendTestResultsARR,
      submitTestResultsHandler: props.submitTestResultsARR,
      prepTestResultArg: prepTestResultArgARR
    };
  } else if (request.isACR) {
    return {
      fetchTestReportHandler: props.fetchTestReportsACR,
      updateReportStatusHandler: props.updateTestReportStatusACR,
      downloadReportHandler: props.downloadReportACR,
      downloadAllReportsHandler: props.downloadAllReportsACR,
      sendTestResultsHandler: props.sendTestResultsACR,
      submitTestResultsHandler: props.submitTestResultsACR,
      prepTestResultArg: prepTestResultArgACR
    };
  } else if (request.isBSC) {
    return {
      fetchTestReportHandler: props.fetchTestReportsBSC,
      updateReportStatusHandler: props.updateTestReportStatusBSC,
      downloadReportHandler: props.downloadReportBSC,
      downloadAllReportsHandler: props.downloadAllReportsBSC,
      sendTestResultsHandler: props.sendTestResultsBSC,
      submitTestResultsHandler: props.submitTestResultsBSC,
      prepTestResultArg: prepTestResultArgBSC
    };
  } else if (request.isSAR) {
    return {
      fetchTestReportHandler: props.fetchTestReports,
      updateReportStatusHandler: props.updateAuditReportStatus,
      downloadReportHandler: props.auditReportDownload,
      downloadAllReportsHandler: props.auditReportsDownloadAll,
      sendTestResultsHandler: props.sendAuditResults,
      submitTestResultsHandler: props.submitAuditResults,
      prepTestResultArg: prepareSARArgs
    };
  } else if (request.isBLC) {
    return {
      fetchTestReportHandler: props.fetchTestReports,
      updateReportStatusHandler: props.updateAuditReportStatus,
      downloadReportHandler: props.auditReportDownload,
      downloadAllReportsHandler: props.auditReportsDownloadAll,
      sendTestResultsHandler: props.sendAuditResults,
      submitTestResultsHandler: props.submitAuditResults,
      prepTestResultArg: prepTestResultArgBLC
    };
  }  else if ("otaInterfaces" in request) {
    return {
      fetchTestReportHandler: props.fetchTestReports,
      updateReportStatusHandler: props.updateAuditReportStatus,
      downloadReportHandler: props.auditReportDownload,
      downloadAllReportsHandler: props.auditReportsDownloadAll,
      sendTestResultsHandler: props.sendAuditResults,
      submitTestResultsHandler: props.submitAuditResults,
      prepTestResultArg: prepTestResultArgOTA
    };
  } else {
    console.warn(
      "Unknown request type. Check `multitool` component for details."
    );
    return {
      fetchTestReportHandler: null,
      updateReportStatusHandler: null,
      downloadReportHandler: null,
      downloadAllReportsHandler: null,
      sendTestResultsHandler: null,
      submitTestResultsHandler: null,
      prepTestResultArg: null
    };
  }
}

export function getCertShorthand(request, uppercase = false) {
  if (!request) return null;

  let shorthand = "";
  if (request.isCRR) {
    shorthand = "crr";
  } else if (request.isHRR) {
    shorthand = "hrr";
  } else if (request.isPRR) {
    shorthand = "prr";
  } else if (request.isEPRR) {
    shorthand = "eprr";
  } else if (request.isARR) {
    shorthand = "arr";
  } else if (request.isACR) {
    shorthand = "acr";
  } else if (request.isBLC) {
    shorthand = "blc";
  } else if (request.isBSC) {
    shorthand = "bsc";
  } else if (request.isSAR) {
    shorthand = "sar";
  }

  return uppercase ? shorthand.toUpperCase() : shorthand;
}
