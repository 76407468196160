import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  HelpBlock,
  Button,
  Form,
  Col
} from "react-bootstrap";
import { changeCertProgram } from "actions/app";
import { searchRequest } from "actions/requests";
import { validatePhone } from "helpers/ValidatorHelper";
import { formatTransactionNumber, getTransPrefix } from "helpers/RequestHelper";
import * as qsh from "helpers/QuickSearchHelper";
import "components/QuickSearch/style.css";

const QuickSearch = ({
  app,
  user,
  changeCertProgram,
  searchRequest,
  shortVersion = true
}) => {
  let history = useHistory();

  const [term, setTerm] = useState("");
  const [notFound, setNotFound] = useState(false);

  const redirectToRequest = (id, client, ext = "") => {
    const { blcProg, progs } = qsh;
    const prog = ext === blcProg ? progs[blcProg] : progs[client];
    const end = !ext || ext === blcProg ? `${id}` : `${ext}/${id}`;
    const url = `${prog.urlPrefix[user.role]}requests/${end}`;

    changeCertProgram(prog.id);
    history.push(url);
  };

  const onChange = event => {
    setTerm(event.target.value);
    setNotFound(false);
  };

  const onClick = () => {
    searchRequest(term).then(res => {
      const resData = res.payload.data;
      if (!resData) return null;
      if (resData.data.length) {
        const {
          id,
          formatedtransactionnumber,
          client
        } = res.payload.data.data[0];
        const ext =
          client === qsh.batteryProg
            ? getTransPrefix(formatedtransactionnumber)
            : "";

        redirectToRequest(id, client, ext);
      } else {
        setNotFound(true);
      }
    });
  };

  const renderHelpBlock = message => {
    return (
      <div className="quick-search__help-block">
        <HelpBlock bsClass="red">{message}</HelpBlock>
      </div>
    );
  };

  const renderValidationMessage = () => {
    const termStr = String(term);
    if (termStr.length && !validatePhone(termStr)) {
      const msg = "Invalid Request ID, must be a number.";
      return renderHelpBlock(msg);
    }
    if (notFound) {
      const tn = formatTransactionNumber(termStr);
      const msg = `Request ${tn} not found.`;
      return renderHelpBlock(msg);
    }
    return null;
  };

  const isFormValid = () => {
    const termStr = String(term);
    return termStr.length > 0 && validatePhone(termStr) && !notFound;
  };

  const onFormSubmit = event => {
    // prevent default form submission
    event.preventDefault();

    // validate form and emulate submit button click
    if (isFormValid()) {
      onClick();
    }
  };

  const renderShort = () => {
    return (
      <>
        <InputGroup>
          <FormControl
            type="text"
            value={term}
            onChange={onChange}
            placeholder="Go to Request ID"
          />
          <InputGroup.Button>
            <Button onClick={onClick} disabled={!canSubmit}>
              Go!
            </Button>
          </InputGroup.Button>
        </InputGroup>
        <FormControl.Feedback />
        {renderValidationMessage()}
      </>
    );
  };

  const renderFull = () => {
    return (
      <FormGroup controlId="quickSearchForm">
        <Col componentClass={ControlLabel} sm={12} md={5} className="text-left">
          Go to Request ID:
        </Col>
        <Col xs={12} sm={3} md={3} style={style} id="ctia-textinput">
          <InputGroup>
            <FormControl type="text" value={term} onChange={onChange} />
            <InputGroup.Button>
              <Button onClick={onClick} disabled={!canSubmit}>
                Go!
              </Button>
            </InputGroup.Button>
          </InputGroup>
          <FormControl.Feedback />
          {renderValidationMessage()}
        </Col>
        <Col xs={12} sm={4} md={4} />
      </FormGroup>
    );
  };

  // available for staff and accountant only
  if (!user || (!user.isStaff && !user.isAccountant)) return null;

  const canSubmit = term.length > 0 && validatePhone(term);

  const style = { width: 300 }; // Must be hardcoded

  const containerStyle = shortVersion
    ? ""
    : "text-center quick-search__help-block";

  return (
    <div className={containerStyle}>
      <Form horizontal onSubmit={onFormSubmit}>
        {shortVersion ? renderShort() : renderFull()}
      </Form>
    </div>
  );
};

function mapStateToProps({ app, user }) {
  return { app, user };
}

export default connect(mapStateToProps, {
  searchRequest,
  changeCertProgram
})(QuickSearch);
