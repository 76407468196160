import React from "react";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import RequestTypeBlock from "containers/request/RequestTypeBlock";
import AgreementPreview from "containers/request/AgreementPreview";
import { Bubble } from "ctia-ui";
import { getProgramTitle } from "helpers/AppHelper";

const reqTypes = [
  {
    link: "initial",
    title: "Initial Certification Request",
    hint:
      "The Initial Certification Request is the first certification request for a device"
  },
  {
    link: "eco",
    title: "ECO Certification Request",
    hint:
      "The ECO Certification Request is for HW/SW updates of a device already submitted or certified"
    // locked: true
  }
];

const RequestNew = ({ app }) => {
  const certTitle = getProgramTitle(app.certProgram);

  return (
    <div>
      <h1>Submit New Request</h1>
      <Bubble>Please choose the request type.</Bubble>
      <Row>
        {reqTypes.map((reqItem, i) => {
          return <RequestTypeBlock request={reqItem} key={i} />;
        })}
      </Row>
      <AgreementPreview certProgram={certTitle} />
    </div>
  );
};

function mapStateToProps({ app }) {
  return { app };
}

export default connect(mapStateToProps)(RequestNew);
