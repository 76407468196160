import React from "react";
import { IconLabel } from "ctia-ui";

const UserLockedLabel = ({ userData }) => {
  if (!userData || userData.locked !== 1) return null;

  return (
    <span className="red">
      <IconLabel label="This account is locked" awesomeIcon="lock" />
    </span>
  );
};

export default UserLockedLabel;
