import React from "react";
import { connect } from "react-redux";
import BipolarBadge from "components/Badges/BipolarBadge";
import AgreementSignATL from "containers/AuthorizedLabs/AgreementSignATL";
import { downloadAgreementATL } from "actions/catl";

const CatlAgreement = ({ user, downloadAgreementATL }) => {
  const downloadHandler = () => downloadAgreementATL(user.companyid);
  const agreementHandler = user.isCATLAgreementSigned ? downloadHandler : null;

  const agreementBadgeProps = user.isCATLAgreementSigned
    ? { label: "Signed" }
    : { label: "Pending", positive: false };

  return (
    <li>
      <AgreementSignATL agreementHandler={agreementHandler} />
      <span className="icon-left-offset">
        <BipolarBadge {...agreementBadgeProps} />
      </span>
    </li>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, {
  downloadAgreementATL
})(CatlAgreement);
