import React, { useState } from "react";
import { Drawer } from "ctia-ui";
import RecognitionBadge from "components/Badges/RecognitionBadge";
import AdapterBadge from "containers/request/battery/AdapterSupplier/AdapterTypeBadge";
import RecognizedSubsystem from "containers/request/battery/PackCells/RecognizedSubsystem";

const ListOfLinksBSC = ({ list, emptyMsg = "Empty list" }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const renderBadge = type => {
    // if adapter
    if (type) {
      return <AdapterBadge type={type} />;
    }

    return <RecognitionBadge />;
  };

  if (!list || list.length === 0) return <span>{emptyMsg}</span>;

  return (
    <ul className="ctia-list form-field-list">
      {list.map((el, i) => {
        // if it's not a link
        if (el.asText) {
          return (
            <li key={`list-of-links-el-${i}`}>
              <span>
                {el.linkTitle}&nbsp;—&nbsp;
                {renderBadge(el.type)}
              </span>
            </li>
          );
        }

        return (
          <li key={`list-of-links-el-${i}`} onClick={toggleDrawer}>
            <span onClick={toggleDrawer} className="cursor-pointer glow-link">
              {el.linkTitle}
            </span>
            {el.isEmbedded ? <span>&nbsp;(embedded pack)</span> : null}
            <Drawer
              position="bottom"
              show={showDrawer}
              title="Recognized Subsystem Detail"
              toggleDrawer={toggleDrawer}
              content={
                <RecognizedSubsystem
                  modelId={el.modelId}
                  certPrefix={el.certType}
                />
              }
              height="auto"
            />
          </li>
        );
      })}
    </ul>
  );
};

export default ListOfLinksBSC;
