import _ from "lodash";
// React/Redux imports
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  clearCertifiedPendingReport,
  getCertifiedPendingReport
} from "actions/reports/certifiedPendingReport";

// CTIA-UI imports
import { InlinePreloader } from "ctia-ui";

// Component imports
import CertifiedPendingReportForm from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Form/CertifiedPendingReportForm";
import CertifiedPendingReportTable from "containers/ControlPanel/Reports/StaffReports/CertifiedPendingReport/Table/CertifiedPendingReportTable";
import { requestStatuses } from "helpers/RequestHelper";

const CertifiedPendingReportContainer = ({
  clearCertifiedPendingReport,
  getCertifiedPendingReport
}) => {
  const [reportState, setReportState] = useState({
    authorizationType: null,
    firstLoad: true,
    isLoading: false,
    dateFrom: null,
    dateTo: null,
    requestStatusId: null,
    requestTypeId: null,
    selectedCertificationType: "",
    selectedCertificationTypeId: null,
    showDateCertified: false
  });

  const formWasSubmitted = ({
    authorizationType,
    certificationType,
    certificationTypeId,
    dateFrom,
    dateTo,
    requestStatusId,
    requestTypeId,
    showDate
  }) => {
    clearCertifiedPendingReport();

    setReportState(reportState => {
      return { ...reportState, isLoading: true };
    });

    const useAuthorizationType = !_.includes(
      ["ACR", "BLC", "CYBER", "DHR", "OTA"],
      certificationType.toUpperCase()
    );
    const useSelectedDates = requestStatusId === requestStatuses.approved;

    getCertifiedPendingReport({
      authorizationType: useAuthorizationType ? authorizationType : null,
      certificationTypeId: certificationTypeId,
      endingDate: useSelectedDates ? dateTo : null,
      requestStatusId: requestStatusId,
      requestTypeId: requestTypeId,
      startingDate:
        requestStatusId === requestStatuses.approved ? dateFrom : null
    }).then(() => {
      setReportState({
        ...reportState,
        authorizationType: authorizationType,
        dateFrom: dateFrom,
        dateTo: dateTo,
        isLoading: false,
        requestStatusId: requestStatusId,
        requestTypeId: requestTypeId,
        selectedCertificationType: certificationType,
        selectedCertificationTypeId: certificationTypeId,
        showDateCertified: showDate
      });
    });
  };

  if (reportState.firstLoad) {
    clearCertifiedPendingReport();
    setReportState({ ...reportState, firstLoad: false });
  }

  const tableComponent = () => {
    if (reportState.isLoading) return <InlinePreloader />;

    const {
      authorizationType,
      dateFrom,
      dateTo,
      requestStatusId,
      requestTypeId,
      selectedCertificationType,
      selectedCertificationTypeId,
      showDateCertified
    } = reportState;

    return (
      <CertifiedPendingReportTable
        authorizationType={authorizationType}
        dateFrom={dateFrom}
        dateTo={dateTo}
        requestStatusId={requestStatusId}
        requestTypeId={requestTypeId}
        selectedCertificationType={selectedCertificationType}
        selectedCertificationTypeId={selectedCertificationTypeId}
        showDateCertified={showDateCertified}
      />
    );
  };

  return (
    <div>
      <h1>Certified/Pending Report</h1>
      <CertifiedPendingReportForm onDidSubmitForm={formWasSubmitted} />
      <hr />
      {tableComponent()}
    </div>
  );
};

function mapStateToProps({ certifiedPendingReport }) {
  return { certifiedPendingReport };
}

export default connect(mapStateToProps, {
  clearCertifiedPendingReport,
  getCertifiedPendingReport
})(CertifiedPendingReportContainer);
