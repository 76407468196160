import React from "react";
import { connect } from "react-redux";
import {
  getTestResultTitle,
  hasReportDocuments,
  getTestPassedTitle,
  isComplete,
  isUnderReview
} from "helpers/RequestTestsHelper";
import { formatDate } from "helpers/DateHelper";

const AuditStatus = ({ tests, request }) => {
  if (!tests || !tests.length) return null;

  const testModule = tests[0];
  const {
    actualstartdate,
    actualenddate,
    reportedat,
    reports,
    requesttestpassedstatusid
  } = testModule;

  const { testStatus, crslversion } = request;

  const renderResult = () => {
    const mainTitle = getTestResultTitle(testStatus);
    const tailTitle =
      isComplete(testStatus) || isUnderReview(testStatus)
        ? `— ${getTestPassedTitle(requesttestpassedstatusid, true)}`
        : "";
    return <b>{`${mainTitle} ${tailTitle}`}</b>;
  };

  const renderPassTestInfo = () => {
    if (hasReportDocuments(reports)) {
      return (
        <div>
          CRSL Version: {crslversion}
          <br />
          Audit Start Date: {formatDate(actualstartdate)}
          <br />
          Audit Finish Date: {formatDate(actualenddate)}
          <br />
          Audit Reported Date: {formatDate(reportedat)}
        </div>
      );
    }
  };

  return (
    <td className="col-xs-3 col-sm-3 col-md-3">
      <b>{renderResult()}</b>
      <br />
      {renderPassTestInfo()}
    </td>
  );
};

function mapStateToProps({ tests, request }) {
  return { tests, request };
}

export default connect(mapStateToProps)(AuditStatus);
