import _ from 'lodash';
import payMethods from 'dictionaries/Payment';

/**
 * Checks if payment method is a custom one (not pre-defined).
 *
 * @param {object} request Request object
 * @returns boolean
 */
export const isCustomPayMethod = request => {
  const { payMethod } = request;

  if (!payMethod) return false;

  const nonCustomMethods = _.dropRight(payMethods);

  if (_.find(nonCustomMethods, method => method.value === payMethod))
    return false;

  return true;
};

/**
 * Initialize default selector value for payment method element.
 *
 * @param {object} request Request object
 * @returns string
 */
export const initSelectorValue = request => {
  if (!request || !request.payMethod) return '';
  return isCustomPayMethod(request) ? 'Other' : request.payMethod;
};

/**
 * Initialize default input velue for payment method input element.
 *
 * @param {object} request Request object
 * @returns string
 */
export const initTextInputValue = request => {
  if (!request || !request.payMethod) return '';
  return isCustomPayMethod(request) ? request.payMethod : '';
};

/**
 * Validate payment method field by its value's length.
 *
 * @param {string} method Text input value for payment method form element
 * @returns boolean
 */
export const isPayMethodValid = method => {
  if (!method) return false;

  return String(method).length > 0;
};
