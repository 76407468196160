import _ from "lodash";

// Dictionaries
import CompanyTypes from "dictionaries/CompanyTypes";
import UserRequestDispositions from "dictionaries/UserRequestDispositions";

// Helpers
import {
  getCompanyCtiaIdFromCompany,
  getCompanyFromCompanyName,
  getCompanyIdFromCompany,
  getRoleIdFromCompanyTypeTitle,
  getUserTypeLegacyFromCompanyTypeTitle
} from "helpers/CompanyHelper";
import { selectedCountryShouldRequireStateOrProvince } from "helpers/Countries";
import { notifyMessages } from "helpers/NotifyHelper";
import { mergeExtPhone, separatePhoneExt } from "helpers/UsersHelper";
import { validateEmail, validatePhone } from "helpers/ValidatorHelper";

export const dispositionIsValid = (dispositionValue, dispositionReason) => {
  const { accepted, moreInfo, rejected } = UserRequestDispositions;

  if (!_.isNumber(dispositionValue)) return false;
  if (_.includes([accepted, moreInfo], dispositionValue)) return true;
  if (rejected === dispositionValue) {
    if (!dispositionReason || _.isEmpty(dispositionReason)) return false;

    return true;
  }

  return false;
};

/**
 * This checks the redux store's form values object to make sure all of the
 * redux values have been validated successfully.
 * @param formValues - Redux store value -- NewUserRequest.selectedUserRequest.formValues
 * @returns {boolean} Returns whether or not the forn values state is valid.
 */
export const storeIsValid = (formValues, userNameIsValid) => {
  if (!dispositionIsValid(formValues.disposition, formValues.dispositionReason))
    return false;

  if (
    formValues.disposition === UserRequestDispositions.rejected ||
    formValues.disposition === UserRequestDispositions.moreInfo
  )
    return true;

  // The rest assume user is accepted.
  // if(formValues.disposition === UserRequestDispositions.accepted)

  if (!userNameIsValid) return false;

  const requiredNonEmptyValues = [
    formValues.firstName,
    formValues.lastName,
    formValues.title,
    formValues.email,
    formValues.address,
    formValues.country,
    formValues.zipCode,
    formValues.companyType,
    formValues.company,
    formValues.companyWebsite,
    formValues.companyDescription
  ];

  if (_.some(requiredNonEmptyValues, entry => _.isEmpty(entry))) return false;

  const { unassigned } = CompanyTypes;
  if (
    formValues.companyId <= 0 &&
    formValues.companyType.toLowerCase() !== unassigned.title.toLowerCase()
  )
    return false;

  if (
    selectedCountryShouldRequireStateOrProvince(formValues.country) &&
    formValues.state === ""
  )
    return false;

  const { email, mobilePhone, workPhone, workPhoneExtension } = formValues;
  if (email === "" || !validateEmail(email)) return false;
  if (workPhone === "" || !validatePhone(workPhone)) return false;
  if (workPhoneExtension !== "" && !validatePhone(workPhoneExtension))
    return false;
  if (mobilePhone !== "" && !validatePhone(mobilePhone)) return false;

  return true;
};

/***
 * Accepts an error or errors from the API response and converts it into a
 * string message to be injected into the notice for a specific user action.
 * @param error
 * @returns {{text: string, type: string}}
 */
export const formatErrorsForDisplay = error => {
  let errorMessage = notifyMessages.userRequestUpdateFailed;

  const errorsStack =
    error && error.response && error.response.data && error.response.data.errors
      ? error.response.data.errors
      : null;
  if (
    !_.isNull(errorsStack) &&
    _.isArray(errorsStack) &&
    !_.isEmpty(errorsStack)
  )
    errorMessage.text = _.map(errorsStack, error => {
      const err = error[0];
      return err.MESSAGE ? err.MESSAGE : error;
    }).join("/n");

  return errorMessage;
};

/***
 * Transforms the form values stored in redux into a format suitable for calling
 * API for either of the two current endpoints.
 * @param formValues
 * @param companyId
 * @returns {{firstname: (string|*), website: *, stateid, active: (null|string|*|number), description: *, countryid: (string|string|*), lastname: (string|*), Rejection: (null|string|*), zipcode: (string|*), companytype, mobilephone: (string|*), workphone: (string|*), username: *}}
 */
export const formatFormsForAPISubmission = ({
  formValues = {},
  companyId = 0,
  isAccepted = false
}) => {
  let updateFields = {
    active: formValues.disposition,
    address1: formValues.address,
    address2: formValues.address2,
    address3: formValues.address3,
    city: formValues.city,
    company: formValues.company,
    companytype: formValues.companyType,
    countryid: formValues.country,
    description: formValues.companyDescription,
    email: formValues.email,
    firstname: formValues.firstName,
    lastname: formValues.lastName,
    mobilephone: formValues.mobilePhone,
    Rejection: formValues.dispositionReason,
    roleid: getRoleIdFromCompanyTypeTitle(formValues.companyType),
    stateid: formValues.state,
    title: formValues.title,
    username: formValues.userName,
    website: formValues.companyWebsite,
    workphone: mergeExtPhone(
      formValues.workPhone,
      formValues.workPhoneExtension
    ),
    zipcode: formValues.zipCode
  };

  if (formValues.companyType.toLowerCase() === "unassigned")
    updateFields["unassignedcompanyname"] = formValues.company;

  if (isAccepted) {
    updateFields["hasTemp"] = true;

    const userType = getUserTypeLegacyFromCompanyTypeTitle(
      formValues.companyType
    );
    if (!_.isNull(userType)) {
      const typeid = userType.id;
      updateFields["LegacyUserTypes"] = [{ typeid }];
    }
  }

  // Only update a company-id when a valid company has been set - otherwise API returns a 500.
  if (companyId >= 0) {
    const { lab, manufacturer, networkOperator, ptcrbLab, supplier } =
      CompanyTypes;
    // Use company type to determine what field to use.
    switch (formValues.companyType.toLowerCase()) {
      case lab.title.toLowerCase():
      case ptcrbLab.title.toLowerCase():
        updateFields["labid"] = companyId;
        break;
      case networkOperator.title.toLowerCase():
        updateFields["operatorid"] = companyId;
        break;
      case manufacturer.title.toLowerCase():
      case supplier.title.toLowerCase():
        updateFields["vendorid"] = companyId;
        break;
      default:
        throw new Error(
          `Unable to determine form field name to update with given company type, ${formValues.companyType}.`
        );
    }
  }

  return updateFields;
};

/***
 * Accepts the 'companies' redux store and a companyName and companyType and
 * searches the redux store for a company matching this name in the correct
 * category - [lab, manuf, oper, suppl] and returns it's ctiaId if found.
 * @param companies - redux store
 * @param companyName - String value of company name
 * @param companyType - One of dictionary type
 * @returns {number|*}
 */
export const getCompanyIdFromCompanyName = ({
  companies,
  companyName,
  companyType
}) => {
  const companyObject = getCompanyFromCompanyName({
    companies,
    company: companyName,
    companyType
  });

  const ctiaId = getCompanyCtiaIdFromCompany(companyObject);
  if (ctiaId > 0) return ctiaId;

  return getCompanyIdFromCompany(companyObject);
};

/***
 * Check for if program value is ptcrb / ctia / something incorrect.
 * @param value {string}
 * @returns {boolean}
 */
export const isPtcrb = value => {
  return value.toLowerCase() === "ptcrb";
};

/**
 * Convenience method for preparing the arguments required for submitting API
 * call / redux action for updating a user request (needs more info / rejected)
 * @param companies - redux store
 * @param formValues - values in newUserRequest.selectedUserRequest.formValues
 * @returns {{disposition, updateFields: {firstname: (string|*), website: *, stateid, active: (null|string|*|number), description: *, countryid: (string|*), lastname: (string|*), Rejection: (null|string|*), zipcode: (string|*), companytype, mobilephone: (string|*), workphone: (string|*), username: *}, program, userId}}
 */
export const prepareFormForUpdateUserRequest = ({ companies, formValues }) => {
  const {
    company: companyName,
    companyType,
    disposition,
    program,
    userId
  } = formValues;
  const companyId = getCompanyIdFromCompanyName({
    companies,
    companyName,
    companyType
  });
  const updateFields = formatFormsForAPISubmission({
    formValues,
    companyId,
    isAccepted: disposition === UserRequestDispositions.accepted
  });

  return {
    disposition,
    program,
    updateFields,
    userId
  };
};

/***
 * Accepts a single row stored in the new-user-request redux stores' list param
 * as input for userData as transform the data into a format suitable for the
 * form. The userData should be from the API response when fetching a list of
 * new user requests.
 *
 * @param userData - Should be a single entry from the newUserRequests.list array
 * @param companies - redux store
 * @returns {{formValues: {country: string, lastName: string, zipCode: string, city: string, program, title: string, password: (string|null), company: (string|number), state: string, dispositionReason: (string|null), email: string, address: string, address3: string, address2: string, companyType: string, companyDescription: string, userName: string, userId: number, firstName: string, companyId: (*|number), disposition, mobilePhone: *, workPhone: (*), companyWebsite: string, workPhoneExtension: (string|*), suggestedUserName: string}, userNameIsValid: boolean}}
 */
export const putUserDataIntoFormatForReduxStore = ({
  userData = [],
  companies
}) => {
  // If the company id has not been set (which it probably hasn't) -- find the
  // company id if the user passed in a valid company name.
  const company = userData.company ? String(userData.company) : 0;
  const companyType = String(userData.companytype);
  let companyId = userData.companyId ? userData.companyId : -1;
  if (companyId <= 0 && !_.isUndefined(companies) && !_.isEmpty(companies)) {
    const match = getCompanyFromCompanyName({
      companies,
      company,
      companyType
    });
    companyId = getCompanyIdFromCompany(match);
  }

  const { workphone: workPhone, workphoneext: workPhoneExtension } =
    separatePhoneExt(userData.workphone);

  const formValues = {
    address: String(userData.address),
    address2: String(userData.address2),
    address3: String(userData.address3),
    city: String(userData.city),
    company,
    companyDescription: String(userData.description),
    companyId,
    companyType,
    companyWebsite: String(userData.website),
    country: String(userData.countryid),
    disposition: userData.active,
    dispositionReason: userData.Rejection ? String(userData.Rejection) : null,
    email: String(userData.email),
    firstName: String(userData.firstname),
    lastName: String(userData.lastname),
    mobilePhone: userData.mobilephone,
    password: userData.Password ? String(userData.Password) : null,
    program: userData.program,
    state: String(userData.stateid),
    title: String(userData.title),
    userId: Number(userData.id),
    userName: String(userData.User_Name),
    workPhone: workPhone,
    workPhoneExtension: workPhoneExtension,
    zipCode: String(userData.zipcode)
  };

  return {
    formValues,
    userNameIsValid: !_.isEmpty(userData.User_Name)
  };
};

/**
 * Logic to determine if the user's chosen company is one that is recognized as
 * accepted (assuming the rest of the form is valid).
 * @param company
 * @param companyId
 * @returns {boolean}
 */
export const selectedCompanyIsValid = (company = "", companyId = 0) => {
  return !_.isEmpty(company) && companyId > 0;
};
