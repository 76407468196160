import React from 'react';
import InputMask from 'react-input-mask';

const InputTextWithMask = ({
  id,
  value,
  onChange,
  placeholder,
  disabled,
  mask = null,
  maskChar
}) => (
  <InputMask
    id={id}
    mask={mask}
    maskChar={maskChar}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    disabled={disabled}
    className="form-control"
  />
);

export default InputTextWithMask;
