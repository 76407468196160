import React, { Component } from "react";
import { Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import LogoPng from "containers/Logo/LogoPng";
import TermsContent from "containers/TermsOfUse/TermsContent";
import { logout, agreeTerms } from "actions/user";
import "containers/TermsOfUse/terms.css";

class TermsOfUse extends Component {
  onAgree = () => {
    this.props.agreeTerms("yes");
  };

  onDisagree = () => {
    localStorage.setItem("terms", "yes");
    this.props.logout({ force: false });
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="terms-of-use__container">
        <div className="text-center">
          <LogoPng cssClass="terms-of-use__logo--inverted" inverted={true} />
          <h2>Terms of Use</h2>
        </div>
        <div className="text-justify">
          <TermsContent />
        </div>
        <div>
          <Col xs={6}>
            <div className="text-center" style={{ padding: "25px 0" }}>
              <Button bsStyle="success" bsSize="large" onClick={this.onAgree}>
                I AGREE
              </Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="text-center" style={{ padding: "25px 0" }}>
              <Button bsStyle="danger" bsSize="large" onClick={this.onDisagree}>
                I DISAGREE
              </Button>
            </div>
          </Col>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, { logout, agreeTerms })(TermsOfUse);
