import _ from "lodash";
import { makeSimpleArgs } from "helpers/ArgsHelper";
import types from "dictionaries/CompanyTypes";
import { getCheckboxIdsFromForm } from "components/Companies/AddCompanyModal/components/FormHelper";

export const prepareVendorOrgArgs = form => {
  const pocName = form.contact
    ? {
        contactname:
          form.contact.options[form.contact.selectedIndex].getAttribute(
            "data-name"
          )
      }
    : null;
  const pocId = form.contact ? { contactid: form.contact.value } : null;
  const reason = form.disableReason
    ? { comments: form.disableReason.value }
    : null;
  const args = {
    name: form.vendorName.value,
    ...reason,
    ...pocId,
    ...pocName,
    issupplier: form.supplierCheckbox.checked === true ? 1 : 0,
    roleid: types.manufacturer.roleId
  };

  return makeSimpleArgs(args);
};

export const prepareLabOrgArgs = form => {
  const ATLlicensedate = form.authDate ? form.authDate.value : null;
  const contactname = form.contact
    ? form.contact.options[form.contact.selectedIndex].getAttribute("data-name")
    : null;
  const contactid = form.contact ? form.contact.value : null;
  const comments = form.disableReason ? form.disableReason.value : null;
  const args = {
    address1: form.address.value,
    address2: form.address2.value,
    address3: form.address3.value,
    ATLlicensedate,
    ATLstatus: form.status.value,
    city: form.city.value,
    companyinterfaces: getCheckboxIdsFromForm(form, "interface"),
    companyieeetypes: getCheckboxIdsFromForm(form, "ieee"),
    companycerttypes: getCheckboxIdsFromForm(form, 'certification'),
    countrycode: form.country.value,
    comments,
    contactid,
    contactname,
    name: form.name.value,
    roleid: types.lab.roleId,
    stateid: !_.isUndefined(form.state)
      ? form.state.value
      : "",
    zipcode: form.zipCode.value
  };

  return makeSimpleArgs(args);
};

export const prepareOperatorOrgArgs = form => {
  const contactname = form.contact
    ? form.contact.options[form.contact.selectedIndex].getAttribute("data-name")
    : null;
  const contactid = form.contact ? form.contact.value : null;
  const membership = form.membership
    ? form.membership.options[form.membership.selectedIndex].value
    : null;
  const comments = form.disableReason ? form.disableReason.value : null;
  const selectedCerts = form.operatorCertTypes
    ? _.map(
        _.filter(form.operatorCertTypes, checkbox => {
          return checkbox.checked ? checkbox.value : null;
      }),
      "value"
      )
    : [];
  const responders = form.waiverResponders
    ? _.map(
        _.filter(form.waiverResponders, checkbox => {
          return checkbox.checked ? checkbox.value : null;
        }),
        "value"
      )
    : [];
  const args = {
    name: form.operatorName.value,
    membership: membership,
    comments,
    contactid,
    contactname,
    companycerttypes: selectedCerts,
    ptcrbwaiverresponders: responders,
    roleid: types.networkOperator.roleId
  };

  return makeSimpleArgs(args);
};
