import React from "react";
import RequestContentACR from "containers/request/battery/RequestContent/RequestContentACR";
import LabContentACR from "containers/request/battery/RequestContent/RequestContentLabACR";
import roles from "dictionaries/UserRoles";

const ContentBatteryACR = ({ role, focusLab }) => {
  switch (role) {
    case roles.staff:
    case roles.vendor:
    case roles.supplier:
    case roles.accountant:
    case roles.operator:
      return <RequestContentACR focusLab={focusLab} />;
    case roles.lab:
      return <LabContentACR />;
    default:
      return null;
  }
};

export default ContentBatteryACR;
