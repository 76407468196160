import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Checklist } from "ctia-ui";
import UnderReviewViewEPR from "containers/testResult/UnderReviewViewEPR";
import { isComplete, isUnderReview } from "helpers/RequestTestsHelper";
import PointOfContact from "containers/request/Initial/Steps/Laboratory/PointOfContactLab";
import DeviceInfoLabEPR from "containers/request/EPRR/DeviceInfoLabEPR";
import {
  getChecklistInitialEPR,
  getChecklistEcoEPR
} from "helpers/ChecklistBatteryHelper";
import { setBatteryRequestPermissions } from "actions/request";
import { setRequestPermissionsForLabReadonlyEPR } from "actions/battery/eprRequest";

class RequestContentLabEPR extends Component {
  componentDidMount() {
    this.props.setBatteryRequestPermissions(
      this.props.request,
      this.props.user
    );
    this.props.setRequestPermissionsForLabReadonlyEPR();
  }

  renderChecklist() {
    const { request } = this.props;
    const checkListInitial = getChecklistInitialEPR(request);
    const checkListEco = getChecklistEcoEPR(request);
    const requestChecklist = request.isInitial
      ? checkListInitial
      : checkListEco;

    return <Checklist list={requestChecklist} />;
  }

  render() {
    const { request, tests } = this.props;
    const testModule = tests[0];
    const testStatus = request.testStatus;
    const showTestResults = isComplete(testStatus) || isUnderReview(testStatus);

    return (
      <Row>
        <Col xs={12} sm={8} md={9}>
          <Tabs defaultActiveKey={1} id="request-edit-tabs">
            <Tab eventKey={1} title="Device Info">
              <DeviceInfoLabEPR />
            </Tab>
            <Tab eventKey={2} title="Point of Contact">
              <PointOfContact />
            </Tab>
            {showTestResults && (
              <Tab eventKey={3} title="Test Results">
                <UnderReviewViewEPR
                  show={showTestResults}
                  testModule={testModule}
                />
              </Tab>
            )}
          </Tabs>
        </Col>
        <Col xs={12} sm={4} md={3}>
          {this.renderChecklist()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ request, tests, user }) {
  return { request, tests, user };
}

export default connect(mapStateToProps, {
  setBatteryRequestPermissions,
  setRequestPermissionsForLabReadonlyEPR
})(RequestContentLabEPR);
