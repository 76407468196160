import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { Checklist } from "ctia-ui";
import UnderReviewViewSAR from "containers/testResult/UnderReviewViewSAR";
import { isComplete, isUnderReview } from "helpers/RequestTestsHelper";
import { isRenewal } from "helpers/RequestHelper";
import PointOfContact from "containers/request/Initial/Steps/Laboratory/PointOfContactLab";
import SiteAddressDetailsReadonly from "containers/request/battery/ManufSite/SiteAddressDetailsReadonly";
import RequestCommentReadonly from "containers/request/battery/RequestCommentReadonly";
import ManufSiteParentLink from "containers/request/battery/ManufSite/ManufSiteParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import {
  getChecklistSARInitial,
  getChecklistSARRenewal
} from "helpers/ChecklistBatteryHelper";
import { setLabAuditPermissions } from "actions/battery/sar";

class RequestContentLabSAR extends Component {
  componentDidMount() {
    this.props.setLabAuditPermissions(false);
  }

  renderChecklist() {
    const { request } = this.props;
    const checkListInitial = getChecklistSARInitial(request);
    const checkListRenewal = getChecklistSARRenewal(request);
    const requestChecklist = isRenewal(request.type)
      ? checkListRenewal
      : checkListInitial;

    return <Checklist list={requestChecklist} />;
  }

  render() {
    const { request, tests } = this.props;
    const testModule = tests[0];
    const testStatus = request.testStatus;
    const showTestResults = isComplete(testStatus) || isUnderReview(testStatus);
    const vendorName = request.submitter.companyname || "";
    const hasRenewalType = isRenewal(request.type);

    return (
      <Row>
        <Col xs={12} sm={8} md={9}>
          <Tabs defaultActiveKey={1} id="request-edit-tabs">
            <Tab eventKey={1} title="Manufacturing Site">
              {hasRenewalType && (
                <Form horizontal>
                  <ManufSiteParentLink />
                  <BatteryParentDetails />
                  <hr />
                </Form>
              )}
              <SiteAddressDetailsReadonly
                siteAddress={request.manufSite.address}
                vendor={vendorName}
              />
            </Tab>
            <Tab eventKey={2} title="Point of Contact">
              <PointOfContact />
            </Tab>
            {showTestResults && (
              <Tab eventKey={3} title="Audit Results">
                <UnderReviewViewSAR
                  show={showTestResults}
                  testModule={testModule}
                />
              </Tab>
            )}
            <Tab eventKey={4} title="Optional Comments">
              <RequestCommentReadonly />
            </Tab>
          </Tabs>
        </Col>
        <Col xs={12} sm={4} md={3}>
          {this.renderChecklist()}
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ request, tests }) {
  return { request, tests };
}

export default connect(mapStateToProps, { setLabAuditPermissions })(
  RequestContentLabSAR
);
