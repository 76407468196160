import React from "react";
import { connect } from "react-redux";
import PacksEditable from "containers/request/battery/BatterySupplier/BatterySupplierContainer";
import PacksReadonly from "containers/request/battery/BatterySupplier/BatteryPacksReadonly";

const BatteryPacks = ({ user }) => {
  const PackRenderer = user.canEditDeviceInfo ? PacksEditable : PacksReadonly;
  return <PackRenderer />;
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(BatteryPacks);
