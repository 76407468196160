import _ from "lodash";
import React from "react";
import FormElement from "components/FormElement";
import { formatName } from "helpers/StringHelper";

/**
 * Create POC's list to be rendered as a selector options.
 *
 * @param {array} pocList List of POC's
 * @returns array
 */
export const formatPocList = (pocList, requestId = "") => {
  if (!_.isEmpty(pocList)) {
    let pocOptions = _.map(pocList, poc => {
      return {
        title: formatName(poc.fullname),
        value: Number(poc.id)
      };
    });

    // add `select poc` option to the ending of an option list
    if (!requestId)
      pocOptions.unshift({
        title: "--- Select a PoC ---",
        value: 0
      });

    // add `new poc` option to the beginning of an option list
    pocOptions.push({
      title: "--- Add new PoC entry ---",
      value: "new"
    });

    return pocOptions;
  }
  return [];
};

/**
 * Render selected POC details.
 *
 * @param {object} request Request object
 * @returns JSX layout
 */
export const renderSelectedPocInfo = request => {
  const { pocId, pocs, tn, vendorProductName: modelName, vendor } = request;
  const pocsObj = _.mapKeys(pocs, "id");

  if (pocId > 0 && !_.isEmpty(pocsObj)) {
    const { fullname, phone, email } = pocsObj[pocId];
    const emailData = { tn, email, modelName, vendor };

    return (
      <div>
        <FormElement id="selectedName" label="PoC Name" value={fullname} />
        <FormElement
          id="selectedEmail"
          label="PoC Email"
          value={emailData}
          type="emailLink"
          useSubmitterNameAsTitle={false}
        />
        <FormElement id="selectedPhone" label="PoC Phone" value={phone} />
      </div>
    );
  }

  return null;
};

export const getPocPermissions = (app, user) => {
  if (app.isCyberCert) return user.canEditRequest;
  if (app.isOTACert) return user.canEditPoc;
  if (app.isBatteryCert) return user.canEditPoc;
  if (app.isBLCCert) return user.canEditPoc;
  if (app.isDHRCert) return user.canEditRequest;

  console.error(
    "Unknown certification program, check getPocPermissions() method"
  );
  return false;
};
