import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import { getEcoBarFields } from "helpers/InfoBarHelper";

const CyberEcoInfoBar = ({ request, model }) => {
  const { reqStatus, reqVendor, reqModel, reqCertLevel, reqWireless } =
    getEcoBarFields(request, model);

  const reqDataInfo = [
    {
      key: "Status",
      value: reqStatus
    },
    {
      key: "Vendor",
      value: reqVendor
    },
    {
      key: "Model Name/Number",
      value: reqModel
    },
    {
      key: "Certification Level",
      value: reqCertLevel
    },
    {
      key: "Wireless Technologies",
      value: reqWireless
    }
  ];

  // remove fields with no values:
  const filteredData = _.filter(reqDataInfo, field => !_.isNull(field.value));

  return <InfoBar show={true} data={filteredData} />;
};

function mapStateToProps({ request, model }) {
  return { request, model };
}

export default connect(mapStateToProps)(CyberEcoInfoBar);
