import React from "react";
import packageJson from "../../package.json";
import { getBuildVersion } from "helpers/DateHelper";

class BuildVersion extends React.Component {
  componentDidMount() {
    const { major, minor, build } = getBuildVersion(packageJson.buildDate);
    const msg = `CTIA Certification Web Version: ${major}.${minor}, Build: ${build}`;

    console.info(msg);
  }

  render() {
    return null;
  }
}

export default BuildVersion;
