import React from "react";
import { Table, Row, Col } from "react-bootstrap";
import ListRow from "../UsersList/ListRow";
import { Divider, CounterLabel } from "ctia-ui";

const SearchResult = ({ data }) => {
  if (!data.length) return null;
  return (
    <div>
      <Divider />
      <div id="request-filter">
        <Row>
          <Col xs={4} xsOffset={8} className="text-right block-bottom-offset">
            <CounterLabel counter={data.length} title="Total Users" />
          </Col>
        </Row>
      </div>
      <Table striped condensed hover className="ctia-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Title</th>
            <th>Company</th>
            <th>E-mail</th>
            <th>Last Login</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <ListRow tableData={data} />
        </tbody>
      </Table>
    </div>
  );
};

export default SearchResult;
