import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  value,
  onChange,
  editable,
  id,
  label,
  labelClass = ""
}) => {
  return (
    <div className="ctia-form">
      <ul className="ctia-form__list">
        <li>
          <input
            type="checkbox"
            id={id}
            name={id}
            checked={value}
            disabled={!editable}
            onChange={onChange}
          />
          <label className={labelClass} htmlFor={id}>
            {label}
          </label>
        </li>
      </ul>
    </div>
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClass: PropTypes.string
};

export default Checkbox;
