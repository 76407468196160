import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { requestFields } from "helpers/BatterySupplierHelper";
import { formatSuppliersList } from "helpers/SupplierHelper";
import { setValue } from "actions/request";
import { fetchCertifiedPacks } from "actions/suppliers";

class SelectBatterySupplier extends React.Component {
  requestField = requestFields.supplier;

  componentWillUnmount() {
    this.props.setValue(this.requestField, "");
  }

  onChange = event => {
    const {
      app,
      request,
      setValue,
      fetchCertifiedPacks,
      suppliers
    } = this.props;

    const supplierId = Number(event.target.value);

    // reset batteryModel value
    setValue(requestFields.model, "");
    setValue(requestFields.modelId, "");
    setValue(requestFields.modelName, "");
    setValue(requestFields.cellRequestId, "");

    // reset batteryModelOption value
    setValue(requestFields.modelOption, undefined);

    // reset batteryRevision value
    setValue(requestFields.revision, "");

    const supplier = _.find(
      suppliers.approvedPackSuppliers,
      pack => pack.value === supplierId
    );

    if (supplier) {
      // set batterySupplier value
      setValue(this.requestField, supplierId);
      setValue(requestFields.supplierId, supplierId);
      setValue(requestFields.supplierName, supplier.title);

      // fetch related pack models
      fetchCertifiedPacks(supplierId, request.ieee, app.idPRR, app.idEPRR);
    } else {
      setValue(this.requestField, null);
      setValue(requestFields.supplierId, null);
      setValue(requestFields.supplierName, null);
    }
  };

  render() {
    const { approvedPackSuppliers: list } = this.props.suppliers;

    if (!list || !list.length) return null;

    return (
      <FormElement
        type="select"
        id="batterySupplier"
        label="Battery Supplier"
        value={this.props.request[this.requestField] || 0}
        onChange={this.onChange}
        options={formatSuppliersList(list)}
      />
    );
  }
}

function mapStateToProps({ app, request, suppliers }) {
  return { app, request, suppliers };
}

export default connect(mapStateToProps, {
  setValue,
  fetchCertifiedPacks
})(SelectBatterySupplier);
