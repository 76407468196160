
export const ctiaSelectionOptions = [
  { id: 0, title: "--- Select One ---", value: "" },
  {
    id: 1,
    title: "Vendor/Supplier of wireless devices",
    value: "Manufacturer",
  },
  {
    id: 2,
    title: "Vendor/Supplier of battery cells, packs or adapter",
    value: "Supplier",
  },
  { id: 3, title: "CTIA Authorized Test Lab", value: "Lab" },
  { id: 4, title: "Network Operator", value: "Operator" }
];

export const ptcrbSelectionOptions = [
  { id: 0, title: "--- Select One ---", value: "" },
  { id: 1, title: "Vendor", value: "Manufacturer", },
  { id: 2, title: "Network Operator", value: "Operator" },
  { id: 3, title: "PTCRB Authorized Test Lab", value: "Laboratory" },
  { id: 4, title: "Test Equipment Vendor", value: "Unassigned" }
];