import React from "react";
import { connect } from "react-redux";
import { Statistic, IconLabel } from "ctia-ui";
import { Col, Row } from "react-bootstrap";
import { mapMeta } from "helpers/AscHelper";
import GenerateInvoicesASC from "containers/ControlPanel/Payments/GenerateInvoicesASC";

const ASCInvoicingStat = ({ card, asc }) => {
  if (!asc || !asc.meta) return null;

  const ascMeta = mapMeta(asc.meta);

  return (
    <Row>
      <Col>
        {ascMeta.map(({ icon, label, value, total }) => {
          const suffix = total ? `/ ${total}` : null;
          return (
            <Col xs={3} key={icon}>
              <Statistic
                title={<IconLabel awesomeIcon={icon} label={label} />}
                value={value}
                suffix={suffix}
              />
            </Col>
          );
        })}
        <Col xs={2}>
          <GenerateInvoicesASC />
        </Col>
      </Col>
    </Row>
  );
};

function mapStateToProps({ asc }) {
  return { asc };
}

export default connect(mapStateToProps)(ASCInvoicingStat);
