import React, { useState } from "react";
import { IconLabel } from "ctia-ui";
import { connect } from "react-redux";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  InputGroup,
  Button,
  Form,
  Col
} from "react-bootstrap";
import { searchDeletedUsers } from "actions/users";

const DeletedUsersSearch = ({ searchDeletedUsers }) => {
  const [term, setTerm] = useState("");

  const onFormSubmit = event => {
    // prevent default form submission
    event.preventDefault();

    // emulate button click
    searchDeletedUsers(term);
  };

  return (
    <div className="text-center block-top-offset">
      <Form horizontal onSubmit={onFormSubmit}>
        <FormGroup controlId="quickSearchForm">
          <Col
            componentClass={ControlLabel}
            xs={12}
            sm={2}
            smOffset={2}
            md={2}
            mdOffset={2}
            className="text-left"
          >
            Search User:
          </Col>
          <Col xs={12} sm={6} md={5} id="ctia-textinput">
            <InputGroup>
              <FormControl
                autoFocus
                type="text"
                value={term}
                onChange={e => setTerm(e.target.value)}
              />
              <InputGroup.Button>
                <Button
                  onClick={() => searchDeletedUsers(term)}
                  disabled={term.length < 2}
                >
                  <IconLabel awesomeIcon="search" label="Search" />
                </Button>
              </InputGroup.Button>
            </InputGroup>
            <FormControl.Feedback />
          </Col>
          <Col xs={12} sm={2} md={3} />
        </FormGroup>
      </Form>
    </div>
  );
};

export default connect(null, { searchDeletedUsers })(DeletedUsersSearch);
