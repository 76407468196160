import _ from "lodash";
import React from "react";
import { Col, Tab, Nav, NavItem } from "react-bootstrap";
import DeviceInfo from "containers/request/Initial/Steps/Vendor/DeviceInfo/index";
import PointOfContact from "containers/request/Initial/Steps/Vendor/PointOfContact";
// import Billing from "containers/request/Initial/Steps/Vendor/Billing";
import BillPay from "containers/request/Initial/Steps/Vendor/DeviceInfo/BillPayContainer";
import LabInfo from "containers/request/Initial/Steps/Vendor/LabInfo";
import Documents from "containers/request/Initial/Steps/Vendor/Documents";
import DocumentsECO from "containers/request/ECO/Steps/Vendor/Documents";
import LicenseAgreement from "containers/request/LicenseAgreement";
// import PaymentInfo from "containers/request/Initial/PaymentInfo";
import Operators from "containers/request/Initial/Steps/Vendor/Operators";
import OperatorsECO from "containers/request/Initial/Steps/Vendor/OperatorsECO";
import OtaDeviceInfo from "containers/request/OTA/OTAContainer";
import OTATestPlans from "containers/request/OTA/TestPlans/OTATestPlans";
import ManufSiteInfo from "containers/request/battery/ManufSite/ManufSiteInfo";
import AuditLabInfo from "containers/request/battery/AuditLabInfo/index";
import RequestComment from "containers/request/battery/RequestComment";
import DhrDeviceInfo from "containers/request/DHR/DHRContainer";
import LabInfoDHR from "containers/request/DHR/LabInfoDHR";
import LicenseAgreementDHR from "containers/request/LicenseAgreement/DHR/LicenseAgreementDHR";
import DeviceInfoCRR from "containers/request/CRR/RequestContainerCRR";
import DeviceInfoEcoCRR from "containers/request/CRR/RequestECOContainerCRR";
import DeviceInfoHRR from "containers/request/HRR/RequestContainerHRR";
import DeviceInfoEcoHRR from "containers/request/HRR/RequestECOContainerHRR";
import DeviceInfoPRR from "containers/request/PRR/RequestContainerPRR";
import DeviceInfoEcoPRR from "containers/request/PRR/RequestECOContainerPRR";
import DeviceInfoEPR from "containers/request/EPRR/RequestContainerEPR";
import DeviceInfoEcoEPR from "containers/request/EPRR/RequestECOContainerEPR";
import DeviceInfoARR from "containers/request/ARR/RequestContainerARR";
import DeviceInfoEcoARR from "containers/request/ARR/RequestECOContainerARR";
import DeviceInfoACR from "containers/request/ACR/RequestContainerACR";
import DeviceInfoEcoACR from "containers/request/ACR/RequestECOContainerACR";
import DeviceInfoBLC from "containers/request/BLC/RequestContainerBLC";
import DeviceInfoEcoBLC from "containers/request/BLC/RequestECOContainerBLC";
import LicenseAgreementBLC from "containers/request/LicenseAgreement/BLC/LicenseAgreementBLC";
import LabInfoBLC from "containers/request/BLC/LabInfoBLC";
import BatteryLifeInfo from "containers/request/BLC/BatteryLifeInfoContainer";
import DeviceInfoBSC from "containers/request/BSC/RequestContainerBSC";
import DeviceInfoEcoBSC from "containers/request/BSC/RequestECOContainerBSC";
import BatteryAgreements from "containers/request/LicenseAgreement/BatteryAgreement/BatteryAgreementContainer";
import LabInfoBattery from "containers/request/battery/BatteryLabInfo/index";

const labInfoText = "Lab & Test Results";

export const vendorTabs = [
  // 0
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfo
  },
  // 1
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  // 2
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  // 3
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfo
  },
  // 4
  {
    key: "operators",
    title: "Operators",
    component: Operators
  },
  // 5
  {
    key: "operatorsECO",
    title: "Operators",
    component: OperatorsECO
  },
  // 6
  {
    key: "documents",
    title: "Documents",
    component: Documents
  },
  // 7
  {
    key: "documentsECO",
    title: "Documents",
    component: DocumentsECO
  },
  // 8
  {
    key: "agreement",
    title: "License Agreement",
    component: LicenseAgreement
  }
];

/**
 * Filter tabs with only expected as Initial request type.
 *
 * @param {object} request Request object
 * @returns array
 */
export const getVendorInitialTabs = request => {
  const tabs = _.pick(vendorTabs, [0, 1, 2, 3, 4, 6, 8, 9]);
  return filterVendorOptions(tabs, request);
};

/**
 * Filter tabs with only expected as ECO request type.
 *
 * @param {object} request Request object
 * @returns array
 */
export const getVendorECOTabs = request => {
  const tabs = _.pick(vendorTabs, [0, 1, 2, 3, 5, 7, 9]);
  return filterVendorOptions(tabs, request);
};

const filterVendorOptions = (tabs, request) => {
  return _.filter(tabs, tab => {
    return true;
    // --- Logic for hiding tab ---
    // check if current tab is invoice
    // const isInvoice = tab.key === 'invoice';

    // check if request tests has been accepted by lab
    // const testsAccepted =
    //   request.testStatus !== testStatuses.rejected &&
    //   request.testStatus !== testStatuses.pending;

    // return !isInvoice || (isInvoice && !_.isEmpty(request.invoice) && testsAccepted);
  });
};

/**
 * Render passed components inside nav tabs.
 *
 * @param {array} tabs Tab list
 * @returns JSX layout
 */
export const renderTabs = tabs => {
  return (
    <Col xs={12} sm={12} md={2}>
      <Nav bsStyle="pills" stacked>
        {tabs.map(tab => {
          return (
            <NavItem eventKey={tab.key} key={tab.key}>
              {tab.title}
            </NavItem>
          );
        })}
      </Nav>
    </Col>
  );
};

/**
 * Render active tab's content.
 *
 * @param {array} tabs Tab list
 * @param {*} activeTab ID (key) for current active tab
 * @param {boolean} hasRightColumn flag which shows whenever it also has right column after content block (like Request Checklist)
 * @returns JSX layout | null
 */
export const renderTabsContent = (tabs, activeTab, hasRightColumn = true) => {
  if (!activeTab) return null;

  const tabToRender = _.find(tabs, { key: activeTab });

  // wrong tab key passed - nothing to render
  if (!tabToRender) return null;

  const TabContent = tabToRender.component;
  const mdSize = hasRightColumn ? 8 : 10;

  return (
    <Col xs={12} sm={12} md={mdSize}>
      <Tab.Content animation>
        <Tab.Pane eventKey={tabToRender.key} key={tabToRender.key}>
          <TabContent />
        </Tab.Pane>
      </Tab.Content>
    </Col>
  );
};

export const otaTabs = [
  // 0
  {
    key: "deviceInfo",
    title: "Device Info",
    component: OtaDeviceInfo
  },
  // 1
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  // 2
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  // 3
  {
    key: "testPlans",
    title: "Test Plans",
    component: OTATestPlans
  }
];

/**
 * Returns only OTA related tabs.
 *
 * @returns array
 */
export const getOtaDefaultTabs = () => {
  return otaTabs;
};

export const batteryTabsSAR = [
  {
    key: "manufSite",
    title: "Manufacturing Site",
    component: ManufSiteInfo
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: "Lab & Audit Results",
    component: AuditLabInfo
  },
  {
    key: "comments",
    title: "Optional Comments",
    component: RequestComment
  }
];

export const batteryTabsCRR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoCRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsCRRECO = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoCRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsHRR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoHRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsEcoHRR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoHRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsPRR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoPRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsEcoPRR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoPRR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsEPR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEPR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsEcoEPR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoEPR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsARR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoARR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsARRECO = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoARR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsACR = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoACR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsACRECO = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoACR
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  }
];

export const batteryTabsBLC = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoBLC
  },
  {
    key: "batteryLifeInfo",
    title: "Battery Life Info",
    component: BatteryLifeInfo
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBLC
  },
  {
    key: "operators",
    title: "Operators",
    component: Operators
  },
  {
    key: "documents",
    title: "Documents",
    component: DocumentsECO
  },
  {
    key: "agreement",
    title: "License Agreement",
    component: LicenseAgreementBLC
  }
];

export const batteryTabsBLCECO = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoBLC
  },
  {
    key: "batteryLifeInfo",
    title: "Battery Life Info",
    component: BatteryLifeInfo
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBLC
  },
  {
    key: "operators",
    title: "Operators",
    component: Operators
  },
  {
    key: "documentsECO",
    title: "Documents",
    component: DocumentsECO
  }
];

export const batteryTabsBSC = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoBSC
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "operators",
    title: "Operators",
    component: Operators
  },
  {
    key: "documents",
    title: "Documents",
    component: BatteryAgreements
  }
];

export const batteryTabsEcoBSC = [
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DeviceInfoEcoBSC
  },
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoBattery
  },
  {
    key: "operators",
    title: "Operators",
    component: OperatorsECO
  }
];

export const dhrTabs = [
  // 0
  {
    key: "deviceInfo",
    title: "Device Info",
    component: DhrDeviceInfo
  },
  // 1
  {
    key: "poc",
    title: "Point of Contact",
    component: PointOfContact
  },
  // 2
  {
    key: "billing",
    title: "Billing & Payment",
    component: BillPay
  },
  // 3
  {
    key: "labInfo",
    title: labInfoText,
    component: LabInfoDHR
  },
  // 4
  {
    key: "operators",
    title: "Operators",
    component: Operators
  },
  // 5
  {
    key: "operatorsECO",
    title: "Operators",
    component: OperatorsECO
  },
  // 6
  {
    key: "documents",
    title: "Documents",
    component: Documents
  },
  // 7
  {
    key: "documentsECO",
    title: "Documents",
    component: DocumentsECO
  },
  // 8
  {
    key: "agreement",
    title: "License Agreement",
    component: LicenseAgreementDHR
  }
];

export const getVendorInitialTabsDHR = request => {
  const tabs = _.pick(dhrTabs, [0, 1, 2, 3, 4, 7, 8]);
  return filterVendorOptions(tabs, request);
};

export const getVendorEcoTabsDHR = request => {
  const tabs = _.pick(dhrTabs, [0, 1, 3, 5, 7]);
  return filterVendorOptions(tabs, request);
};
