import _ from "lodash";
import React from "react";
import ReqLink from "components/RequestsLink";
import types from "dictionaries/ReqTypes";
import statuses from "dictionaries/RequestStatuses";
import { isApprovedDoc } from "helpers/DocumentStatus";
import { getVoltageTypeById } from "components/form/Voltage";

export const requestStatuses = {
  pending: 1,
  approved: 2,
  awaitingParent: 4,
  underReview: 5,
  requestWithdrawn: 8,
  awaitingParentApproval: 12,
  saved: 17,
  awaitingApprovalBSC: 18,
  awaitingLinked: 19
};

/**
 * Return request's status.
 *
 * @param {object} request Request object
 * @returns number
 */
export const getStatus = request => {
  if (!request) return null;
  return request.status || request.requeststatusid || null;
};

/**
 * Set boolean flags for requests's possible statuses.
 *
 * @param {object} request Request object
 * @returns object
 */
export const getRequestStatuses = request => {
  const status = getStatus(request);
  return {
    isPending: isPending(status),
    isApproved: isApproved(status),
    isUnderReview: isUnderReview(status),
    isRequestWithdrawn: isRequestWithdrawn(status),
    isSaved: isSaved(status)
  };
};

export const isPending = status => {
  return status === requestStatuses.pending;
};

export const isApproved = status => {
  return status === requestStatuses.approved;
};

export const isUnderReview = status => {
  return status === requestStatuses.underReview;
};

export const isRequestWithdrawn = status => {
  return status === requestStatuses.requestWithdrawn;
};

export const isSaved = status => {
  return status === requestStatuses.saved;
};

/**
 * Return a title for passed status value.
 *
 * @param {number} status Status ID
 * @returns string
 */
export const getStatusTitle = status => {
  if (_.has(statuses, status)) {
    return statuses[status].title;
  }

  return "n/a";
};

/**
 * Filter request by passed status.
 *
 * @param {number} status Status ID
 * @param {array} requests List of requests
 * @returns array
 */
export const getFilteredRequests = (status, requests) => {
  switch (status) {
    case requestStatuses.saved:
      return _.filter(requests, req => {
        if (req.requeststatusid) {
          return isSaved(req.requeststatusid);
        }
      });
    case requestStatuses.requestWithdrawn:
      return _.filter(requests, req => {
        if (req.requeststatusid) {
          return isRequestWithdrawn(req.requeststatusid);
        }
      });
    default:
      return _.filter(requests, req => {
        if (req.requeststatusid) {
          return (
            isPending(req.requeststatusid) || isUnderReview(req.requeststatusid)
          );
        }
      });
  }
};

/**
 * Check if request has `initial` type.
 *
 * @param {number} type Request type
 * @returns boolean
 */
export const isInitial = type => {
  return Number(type) === types.initial;
};

/**
 * Check if request has `ECO` type.
 *
 * @param {number} type Request type
 * @returns boolean
 */
export const isEco = type => {
  return Number(type) === types.eco;
};

export const isOTA = type => {
  return Number(type) === types.ota;
};

export const isRenewal = type => {
  return Number(type) === types.renewal;
};

/**
 * Return request type title as a string.
 *
 * @param {number} type Request type
 * @returns string
 */
export const getRequestTypeTitle = type => {
  const typeId = Number(type);
  switch (typeId) {
    case types.initial:
      return "Initial Certification Request";
    case types.eco:
      return "ECO Certification Request";
    case types.ota:
      return "OTA Test Plan Use Request";
    case types.renewal:
      return "Renewal/Update Request";
    default:
      return "Unknown Request Type";
  }
};

/**
 * Return request type title as a string.
 *
 * @param {number} type Request type
 * @returns string
 */
export const getRequestTypeShortTitle = type => {
  const typeId = Number(type);
  switch (typeId) {
    case types.initial:
      return "Initial";
    case types.eco:
      return "ECO";
    case types.ota:
      return "OTA";
    case types.renewal:
      return "Renewal/Update";
    default:
      return "Unknown";
  }
};

/**
 * Render method for request ID link.
 *
 * @param {number} id Request ID (in database)
 * @param {string} tn Public request code (to be shown)
 * @param {boolean} showAsLink Format as a link to request or redner just a string
 * @param {string} subType Program sub type (e.g. /battery/sar/)
 * @returns string | JSX
 */
export const renderRequestLink = (id, tn, showAsLink, subType) => {
  if (!tn) return null;
  if (!showAsLink) return tn;
  return <ReqLink linkTitle={tn} requestId={id} subType={subType} />;
};

/**
 * Get transaction number prefix for given request.
 *
 * E.G. CBRS00001 => cbrs
 *
 * @param {string} formattedTransactionNumber
 */
export const getRequestTransPrefix = request =>
  request && getTransPrefix(request.formatedtransactionnumber);

/**
 * Get prefix for given transaction number.
 *
 * E.G. CBRS00001 => cbrs
 * E.G. LSAR00001 => sar
 *
 * @param {string} transnumber
 */
export const getTransPrefix = transnumber => {
  if (!transnumber)
    console.error("Passed empty transaction number for the request");

  // 'L' is a reservered first character for legacy requests. Remove it if found.
  let str = transnumber[0] === "L" ? transnumber.substring(1) : transnumber;

  // Some programs have a prefix larger then 3 chars, so just get chars until
  // first digit.
  str = str.split(/(\d)/)[0];

  if (typeof str === "string" && str.length > 0) {
    return str.toLowerCase();
  }

  console.warn("Transnumber prefix is not a String or too short");

  return null;
};

/**
 * Format passed transaction number to string.
 *
 * Adds leading zeroes if value lenght is less than string length.
 *
 * @param {number} value Transaction number
 * @param {number} size Length of returning string
 * @returns string Formatted transaction number as a string
 */
export const formatTransactionNumber = (value, size = 6) => {
  const strVal = String(value);
  const diffLength = size - strVal.length;

  if (diffLength === 0) return strVal;

  const leadingZeroes = _.repeat("0", diffLength);

  return `${leadingZeroes}${strVal}`;
};

/**
 * Return array of statuses based on passed status ID.
 *
 * @param {number} status Request status ID
 * @returns array
 */
export const getRequestStatusGroup = (status = requestStatuses.pending) => {
  const {
    pending,
    underReview,
    requestWithdrawn,
    saved,
    awaitingParent,
    awaitingApprovalBSC
  } = requestStatuses;
  /*
    1 - pending requests
    5 - under review
    8 - withdrawn
    17 - saved
  */
  switch (status) {
    case requestWithdrawn:
      return [requestWithdrawn];
    case saved:
      return [saved];
    case underReview:
      return [underReview];
    case awaitingParent:
      return [awaitingParent];
    case awaitingApprovalBSC:
      return [awaitingApprovalBSC];
    default:
      return [pending];
  }
};

/**
 * Check if request has uploaded user's manual.
 *
 * @param {object} request Request object
 * @returns boolean
 */
export const hasUploadedManual = request => {
  if (!request || !request.usersManual || !request.usersManual.document)
    return false;

  return request.usersManual.document.documentstatusid > 0;
};

/**
 * Check if user's manual uploaded and approved.
 *
 * @param {object} usersManual User's manual object
 * @returns boolean
 */
export const hasUsersManualApproved = usersManual => {
  if (!usersManual || !usersManual.document) return false;
  return isApprovedDoc(usersManual.document.documentstatusid);
};

/**
 * Check if request has user's manual file.
 *
 * @param {object} request Request object
 * @returns boolean
 */
export const hasManualFile = request => {
  if (!request || !request.usersManual) return false;

  return !_.isUndefined(request.usersManual.file);
};

/**
 * Check if request has uploaded support docs.
 *
 * @param {object} request Request object
 * @returns boolean
 */
export const hasSupDocs = request => {
  if (!request || !request.supportDocs) return false;

  const idExists = doc => {
    return !_.isUndefined(doc.id);
  };

  return _.some(request.supportDocs, idExists);
};

/**
 * Check if we can hide action column for admins.
 *
 * @param {array} requestList List of requests
 * @returns boolean
 */
export const needHideActions = requestList => {
  if (!requestList) return true;

  return _.every(requestList, { ismanualpending: 0, nreportspending: 0 });
};

/**
 * Check if request has a string value for passed field name.
 *
 * @param {object} request Request object
 * @param {string} fieldName Name of request field
 * @returns boolean
 */
export const hasStringValue = (request, fieldName) => {
  if (!request || !fieldName) return false;
  if (!_.has(request, fieldName)) return false;

  const strValue = _.isNil(request[fieldName])
    ? ""
    : request[fieldName].toString().trim();

  return strValue.length > 0;
};

/**
 * Check if request has been already created.
 *
 * @param {object} request Request object
 * @returns boolean
 */
export const isExisted = request => {
  if (!request) return false;
  return _.has(request, "id") && request.id > 0;
};

/**
 * Get request model name.
 *
 * @param {object} request Request object
 * @returns string
 */
export const getModelName = (request, encode = false) => {
  const emptyModel = "<Model Name/Number>";
  if (!request) return emptyModel;

  const { modelName, existedModelName, existedModel } = request;
  if (
    _.isUndefined(existedModelName) &&
    _.isEmpty(modelName) &&
    _.isEmpty(existedModel)
  )
    return "";

  const resModelName =
    modelName.length > 0
      ? modelName
      : existedModelName && existedModelName.length > 0
      ? existedModelName
      : existedModel && existedModel.length > 0
      ? existedModel
      : emptyModel;

  return encode ? escape(resModelName) : resModelName;
};

export const getRequestVendor = (request, user) => {
  return isExisted(request) ? request.vendor : user.company;
};

export const getRequestSupplier = (request, user) => {
  return (
    request.vendor ||
    (isExisted(request.submitter)
      ? request.submitter.companyname
      : user.company)
  );
};

export const getRequestVoltage = voltage => {
  if (!voltage) return null;

  const [selectorValue, inputValue] = voltage;

  if (inputValue === "") return "Legacy";

  const val = inputValue || "n/a";

  return selectorValue ? `${getVoltageTypeById(selectorValue)} — ${val}` : `${val}`;
};

export const shouldDisableAgreement = (app, request) => {
  if (!request || !app) return false;
  if (app.isSaving || app.isSubmitting) return true;
  return request.id > 0 && !request.isSaved;
};

export const getVendorId = (request, user) => {
  if (user.isVendor || user.isStaff) {
    return isExisted(request) ? request.model.vendorid : user.companyid;
  }

  return null;
};

export const getDeviceInfoPermission = (app, user) => {
  if (app.isCyberCert) return user.canEditRequest;
  if (app.isOTACert) return user.canEditDeviceInfo;
  if (app.isBatteryCert) return user.canEditRequest;
  if (app.isBLCCert) return user.canEditRequest;
  if (app.isDHRCert) return user.canEditRequest;

  console.error(
    "Unknown certification program, check getDeviceInfoPermission() method"
  );
  return false;
};

/**
 * Check if need to show `Download All` button.
 *
 * It shows in case of the list has more than 1 uploaded docs.
 *
 * @param {object} docList List of documents
 * @returns boolean
 */
export const canDownloadAll = docList => {
  if (!docList) return false;

  const filteredDocs = _.filter(docList, doc => !_.isUndefined(doc.id));

  return _.size(filteredDocs) > 1;
};

export const getBasicRequestStatusList = () => {
  const {
    pending,
    approved,
    awaitingParent,
    underReview,
    awaitingLinked
  } = requestStatuses;
  return `${pending}, ${approved}, ${awaitingParent}, ${underReview}, ${awaitingLinked}`;
};

export const formatLinkedRequests = (list, linkedRequests) => {
  // get only pre-selected ids for the request
  const selectedIds = _.map(linkedRequests, "linkedrequestid");

  // iterate request list and set checked status
  return _.forEach(list, value => {
    value.label = value.manufsitename;
    value.checked = _.indexOf(selectedIds, value.id) > -1;
  });
};

export const formatOpSysSelectList = list => {
  const opSysList = _.isArray(list) && _.size(list) > 0 ? [...list] : [];

  return [
    {
      value: 0,
      title: `Select an Operating System ↴`
    },
    ...opSysList
  ];
};

export const formatOpSysList = oslist => {
  if (!oslist || oslist.length === 0) return [];

  return oslist.map(os => {
    return {
      value: os.id,
      title: os.name
    };
  });
};

export const getBatteryStatusGroup = (status = requestStatuses.pending) => {
  switch (status) {
    case requestStatuses.requestWithdrawn:
      return [requestStatuses.requestWithdrawn];
    case requestStatuses.saved:
      return [requestStatuses.saved];
    case requestStatuses.underReview:
      return [requestStatuses.underReview];
    case requestStatuses.awaitingParent:
      return [requestStatuses.awaitingParent];
    case requestStatuses.awaitingLinked:
      return [requestStatuses.awaitingLinked];
    case requestStatuses.approved:
      return [requestStatuses.approved];
    default:
      return [requestStatuses.pending, requestStatuses.awaitingParentApproval];
  }
};

export const isLegacy = request => request && request.legacyid !== "";
