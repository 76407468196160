import React from 'react';
import { connect } from 'react-redux';
import FormElement from 'components/FormElement';
import { requestFields, formatSitesList } from 'helpers/ManufSiteHelper';
import { setValue } from 'actions/request';
import { clearCurrSite } from 'actions/sitesSar';
import { sortByFieldname } from 'helpers/SortHelper';

class RenewalSelector extends React.Component {
  requestField = requestFields.siteId;

  onChange = (event) => {
    const { setValue } = this.props;
    const siteId = event.target.value;

    // set siteAddress value
    setValue(this.requestField, siteId);
  };

  setFieldValue = () => {
    const { request } = this.props;
    const activeOption = Number(request[requestFields.siteOption]);

    if (activeOption === 1) {
      return request[this.requestField] || 0;
    }

    return 0;
  };

  setListValue = () => {
    const list = [];
    const { sites } = this.props;

    if (!sites) return list;
    if (!sites.list || !sites.list.length) return list;

    return sites.list;
  };

  render() {
    const value = this.props.request[this.requestField] || 0;
    const list = sortByFieldname(this.setListValue(), 'title');    

    return (
      <FormElement
        type="select"
        id={this.requestField}
        show={true}
        value={value}
        onChange={this.onChange}
        options={formatSitesList(list)}
        label="Manufacturing Site"
      />
    );
  }
}

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps, {
  setValue,
  clearCurrSite,
})(RenewalSelector);
