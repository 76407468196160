import _ from "lodash";
import React from "react";
import { Divider } from "ctia-ui";
import ReportDownload from "containers/request/OTA/TestResults/OtaReportDownload";
import ReportDownloadAll from "containers/request/OTA/TestResults/OtaReportDownloadAll";

const OtaTestReports = ({ testModule }) => {
  const { reports } = testModule.details;
  if (!reports || !reports.length) return null;

  const onlyReports = _.filter(reports, report => report.docId !== 0);
  if (_.isEmpty(onlyReports)) return null;

  const renderDownloaders = () => {
    return onlyReports.map(report => {
      return (
        <ReportDownload
          report={report}
          testModule={testModule.details}
          key={report.id}
        />
      );
    });
  };

  return (
    <div>
      <Divider />
      <h4>Uploaded Reports:</h4>
      <div className="clearfix">{renderDownloaders()}</div>
      <ReportDownloadAll
        show={onlyReports.length > 1}
        testModule={testModule.details}
      />
    </div>
  );
};

export default OtaTestReports;
