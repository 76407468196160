import React from "react";
import { connect } from "react-redux";
import { InfoBar } from "ctia-ui";
import { getModelName } from "helpers/RequestHelper";
import testTypes from "dictionaries/TestTypes";

const TestResultsInfoBarDHR = ({ request }) => {
  const reqInfo = [
    {
      key: "Vendor",
      value: request.vendor
    },
    {
      key: "Model Name/Number",
      value: getModelName(request)
    },
    {
      key: "Test",
      value: testTypes.dhr
    }
  ];

  return <InfoBar show={true} data={reqInfo} />;
};

function mapStateToProps({ request }) {
  return { request };
}
export default connect(mapStateToProps)(TestResultsInfoBarDHR);
