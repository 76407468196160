import _ from "lodash";
import React from "react";
import ReqLink from "components/RequestsLink";
import PropTypes from "prop-types";
import NoTableData from "components/NoTableData";
import { getRequestTypeTitle } from "helpers/RequestHelper";
import { getTestResultTitle } from "helpers/RequestTestsHelper";

const LabListRow = ({ tableRows }) => {
  if (!tableRows) return null;

  if (_.isEmpty(tableRows)) {
    return <NoTableData colSpan={5} />;
  }

  return _.map(tableRows, row => {
    const tn = row.formatedtransactionnumber;
    const requestType = getRequestTypeTitle(row.requesttypeid);
    const testStatus = getTestResultTitle(row.testingstatus);

    return (
      <tr key={row.id}>
        <td>
          <ReqLink linkTitle={tn} requestId={row.id} />
        </td>
        <td>{row.name}</td>
        <td>{row.modelnumber}</td>
        <td>{requestType}</td>
        <td>{testStatus}</td>
      </tr>
    );
  });
};

LabListRow.propTypes = {
  tableRows: PropTypes.array
};

export default LabListRow;
