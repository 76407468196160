import React from "react";
import { WithModal } from "ctia-ui";
import AddButton from "components/form/buttons/AddButton";
import AddServiceCenterModal from "containers/ControlPanel/Orgs/ServiceCenter/AddServiceCenterModal";

class AddServiceCenter extends WithModal {
  render() {
    return (
      <div>
        <AddButton onClick={this.handleModal} />
        <AddServiceCenterModal
          show={this.state.showModal}
          modalHandler={this.handleModal}
        />
      </div>
    );
  }
}

export default AddServiceCenter;
