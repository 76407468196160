import _ from "lodash";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CounterLabel, InlinePreloader } from "ctia-ui";
import { fetchCompanyUsers } from "actions/user";
import { Col, Row } from "react-bootstrap";
import SortableTable from "containers/SortableTable";
import CompanyUserRow from "containers/ControlPanel/CompanyUserRow";
import ListFilter from "components/ListFilter";
import { renderNameWithFlag } from "containers/ControlPanel/Payments/RenderHelperATL";

const CompanyUsers = ({
  companyId = "",
  companyName = "",
  flagCode,
  users,
  fetchCompanyUsers
}) => {
  const [loading, setLoading] = useState(false);
  const [filterTerm, setFilterTerm] = useState("");

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      fetchCompanyUsers(companyId).then(() => setLoading(false));
    }
  }, [fetchCompanyUsers, companyId]);

  if (!companyId) return null;
  if (!users) return null;

  const startSearchResult = list => {
    if (!filterTerm) return list;

    return _.filter(list, user => {
      // convert UPPER CASE to lower case for case insensitive search
      const fullname = String(user.fullname).toLowerCase();
      const email = String(user.email).toLowerCase();

      return (
        fullname.indexOf(filterTerm.toLowerCase()) !== -1 ||
        email.indexOf(filterTerm.toLowerCase()) !== -1
      );
    });
  };

  const headerFields = [
    { title: "Name", fieldname: "reversedfullname" },
    { title: "Title", fieldname: "title" },
    { title: "E-mail", fieldname: "email" },
    { title: "Last Login", fieldname: "lastloginat" },
    { title: "Status", fieldname: "active" }
  ];

  const handleSort = (item, sortField) => {
    if (sortField && sortField === "active") {
      return !item.active;
    }

    return String(item[sortField]).toLowerCase();
  };

  const renderTable = () => {
    const filteredList = startSearchResult(users.list);

    return (
      <div className="block-bottom-offset">
        <h3>{renderNameWithFlag(companyName, flagCode)}</h3>
        <Row>
          <Col xs={8} sm={6} md={9}>
            <ListFilter
              label="Search Users"
              onChange={value => setFilterTerm(value)}
            />
          </Col>
          <Col xs={4} sm={6} md={3} className="text-right">
            <CounterLabel counter={filteredList.length} title="Total records" />
          </Col>
        </Row>
        <span>Below is a list of the users at {companyName}.</span>
        <hr />
        <SortableTable
          list={filteredList}
          header={headerFields}
          colNum={headerFields.length}
          handleSort={handleSort}
          rowRenderer={CompanyUserRow}
          noDataMsg="There are no users from this company."
        />
      </div>
    );
  };

  return loading ? <InlinePreloader /> : renderTable();
};

function mapStateToProps({ users }) {
  return { users };
}

export default connect(mapStateToProps, {
  fetchCompanyUsers
})(CompanyUsers);
