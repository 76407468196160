import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Bubble } from "ctia-ui";
import OperatorsList from "containers/OperatorsList";
import RequestControls from "containers/request/RequestControls";
import { startPreloader, finishPreloader } from "actions/preloader";
import { updateOperators } from "actions/model";
import { clearOperators } from "actions/operators";

const infoMsg =
  "Please select the operators allowed to view the certification record once the device is certified.";
const systemInfoMsg =
  "Please select the operators allowed to view this system once its is certified.";

class Operators extends Component {
  // fix for an issue when switching between diff programms
  // the operator list keeps the same and does not update
  componentDidMount() {
    const { operators, clearOperators } = this.props;

    if (operators) {
      clearOperators();
    }
  }

  validate() {
    const { user, operators, request } = this.props;

    // Wait for server response first
    if (!operators) return false;

    if (!user.canEditRequest) return false;

    // need to select at least 1 operator (e.g. DHR)
    if (request.areOperatorsMandatory) {
      return request.operators && _.some(request.operators, op => op.checked);
    }

    return true;
  }

  onSaveClick = () => {
    const { request, updateOperators } = this.props;

    return updateOperators(request);
  };

  renderEmpty = () => {
    return (
      <Bubble type="warning">
        No operators in the database found for this program.
      </Bubble>
    );
  };

  render() {
    const { show, app, user, operators, request } = this.props;

    if (!show) return null;

    const isEditable = app.isBLCCert ? false : user.canEditRequest;
    const saveHandler = isEditable ? this.onSaveClick : null;
    const bubbleContent =
      request.isBSC || app.isBLCCert ? systemInfoMsg : infoMsg;
      
    if (operators && _.isEmpty(operators)) {
      return this.renderEmpty();
    }

    return (
      <div>
        <Bubble show={true}>{bubbleContent}</Bubble>
        <Form horizontal>
          <OperatorsList readonly={!isEditable} />
          <RequestControls isValid={this.validate()} onSave={saveHandler} />
        </Form>
      </div>
    );
  }
}

Operators.propTypes = {
  show: PropTypes.bool
};

Operators.defaultProps = {
  show: true
};

function mapStateToProps({ request, app, user, operators }) {
  return { request, app, user, operators };
}

export default connect(mapStateToProps, {
  startPreloader,
  finishPreloader,
  updateOperators,
  clearOperators
})(Operators);
