import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { requestFields, formatSitesList } from "helpers/ManufSiteHelper";
import { setValue } from "actions/request";
import { clearCurrSite } from "actions/sitesSar";

class SiteSelectorRenewal extends React.Component {
  requestField = requestFields.siteId;

  onChange = event => {
    const { setValue } = this.props;
    const siteId = event.target.value;

    // set siteAddress value
    setValue(this.requestField, siteId);
  };

  setShowOption = () => {
    const { request, sites } = this.props;
    if (!request || !sites) return false;

    const { list } = sites;
    if (!list || !list.length) return false;

    return true;
  };

  setFieldValue = () => {
    const { request } = this.props;

    return request[this.requestField] || 0;
  };

  setListValue = () => {
    const list = [];
    const { sites } = this.props;

    if (!sites) return list;
    if (!sites.list || !sites.list.length) return list;

    return sites.list;
  };

  setRenderOptions = () => {
    return {
      show: this.setShowOption(),
      fieldValue: this.setFieldValue(),
      list: this.setListValue()
    };
  };

  render() {
    const { show, fieldValue, list } = this.setRenderOptions();

    if (!show) return null;

    return (
      <FormElement
        type="select"
        id={this.requestField}
        show={true}
        value={fieldValue}
        onChange={this.onChange}
        options={formatSitesList(list)}
        label="Manufacturing Site"
      />
    );
  }
}

function mapStateToProps({ request, sites }) {
  return { request, sites };
}

export default connect(mapStateToProps, {
  setValue,
  clearCurrSite
})(SiteSelectorRenewal);
