import _ from "lodash";
import * as actions from "actions/types";
import { restClientGeneral, restClientAdmin } from "libs/restClient";
import * as call from "helpers/ActionHelper";
import { downloadFile } from "helpers/UploadsHelper";
import { getCurrATLYear } from "helpers/DateHelper";
import { notifyMessages } from "helpers/NotifyHelper";
import { formatValue, getInputValue } from "libs/form";
import { makeSimpleArgs } from "helpers/ArgsHelper";

export const signAgreementATL = (
  companyId,
  fullName,
  jobTitle
) => async dispatch => {
  try {
    // we can sign for the current year only
    const year = getCurrATLYear();

    const url = `/companies/${companyId}/agreements/atl?year=${year}&name=${fullName}&title=${jobTitle}`;
    const payload = await restClientGeneral.post(url);

    dispatch({
      type: actions.SIGN_ATL_AGREEMENT,
      payload
    });

    return payload;
  } catch (e) {
    const errMsg = call.parseApiException(e);

    dispatch(call.showNotice(errMsg));

    return Promise.reject(e);
  }
};

export function downloadAgreementATL(companyId, year = getCurrATLYear()) {
  const fileName = `${year}_ATL_License_Service_Agreement.pdf`;
  const url = `/companies/${companyId}/agreements/atl?year=${year}`;
  const payload = restClientGeneral
    .get(url, { responseType: "blob" })
    .then(res => {
      downloadFile(res.data, fileName);
      return res;
    })
    .catch(err => err);

  return {
    type: actions.DOWNLOAD_ATL_AGREEMENT,
    payload
  };
}

export function downloadInvoiceATL({ invoiceId, companyid, atlInvoiceNumber }) {
    const fileName = `${atlInvoiceNumber}.pdf`;
    let restClient, url;

    if (invoiceId) {
        url = `/atlinvoices/${invoiceId}?pdf=true`;
        restClient = restClientAdmin;
    } else {
        const year = getCurrATLYear();
        url = `/companies/${companyid}/invoices/atl?year=${year}`;
        restClient = restClientGeneral;
    }

    const payload = restClient
    .get(url, { responseType: "blob" })
    .then(res => {
        downloadFile(res.data, fileName);
        return res;
    })
    .catch(err => {
        const errMsg = call.parseApiException(err);

        call.showNotice(errMsg);

        return err;
    });

    return {
        type: actions.DOWNLOAD_ATL_INVOICE,
        payload
    };
}

export function setInvoicingYear(year) {
  return {
    type: actions.SET_INVOICING_YEAR,
    payload: year
  };
}

export const saveCatlInvoices = (labs, year) => async dispatch => {
  try {
    const url = "/admin/atlinvoices/bulk";

    // first - filter out only labs marked to be involved to bulking
    const filteredLabs = _.filter(labs, "touched");

    // ATL w/o invoices must use POST call to create new ones
    // ATL w/ generated invoices must use PUT call to update existing ones
    const [invoiced, toBeInvoiced] = _.partition(filteredLabs, "hasInvoice");

    // we need to skip labs w/o invoices in case no fees selected
    // the rest will be ready to generate new invoices by using POST call
    const bulkPOST = _.filter(toBeInvoiced, lab => {
      return lab.membershipPaid || lab.atlFeePaid;
    });

    // we also need to mark some labs w/ invoices to be deleted
    // in case admin deselects all fees per lab
    const bulkDELETE = _.remove(invoiced, lab => {
      return !lab.membershipPaid && !lab.atlFeePaid;
    });
    
    const atlFeeCostID = parseInt(year) >= 2024 ? 52 : 2;

    // 1 - update existing invoices (if any)
    if (invoiced.length) {
      const argsPUT = invoiced.map(lab => {
        const atlinvoicecosts = [];

        if (lab.membershipPaid) {
          // reuse existing values if cost presents
          const memberCost = _.find(lab.invoiceCosts, ["costid", 1]);

          if (memberCost) {
            const { amount, item, description } = memberCost;

            atlinvoicecosts.push({
              costid: 1,
              amount,
              description,
              item
            });
          } else {
            // otherwise use default template values on server
            atlinvoicecosts.push({ costid: 1 });
          }
        }

        if (lab.atlFeePaid) {
          const atlCost = _.find(lab.invoiceCosts, ["costid", 2]) || _.find(lab.invoiceCosts, ["costid", 52]);

          // reuse existing values if cost presents
          if (atlCost) {
            const { costid, amount, item, description } = atlCost;

            atlinvoicecosts.push({
              costid,
              amount,
              description,
              item
            });
          } else {
            // otherwise use default template values on server
            atlinvoicecosts.push({ costid: atlFeeCostID });
          }
        }

        const id = lab[`ATLAnualLicenseFeeKey${year}`];

        return {
          id, // PK of the invoice
          atlinvoicecosts
        };
      });

      await restClientGeneral.put(url, {
        args: [...argsPUT]
      });
    }

    // 2 - generate new invoices (if any)
    if (bulkPOST.length) {
      const argsPOST = bulkPOST.map(lab => {
        const atlinvoicecosts = [];

        if (lab.membershipPaid) atlinvoicecosts.push({ costid: 1 });
        if (lab.atlFeePaid) atlinvoicecosts.push({ costid: atlFeeCostID });

        return {
          atlinvoicecosts,
          companyid: lab.id,
          targetyear: year
        };
      });

      await restClientGeneral.post(url, {
        args: [...argsPOST]
      });
    }

    // 3 - delete existing invoices (if any)
    if (bulkDELETE.length) {
      const argsDELETE = bulkDELETE.map(lab => {
        const id = lab[`ATLAnualLicenseFeeKey${year}`];
        return { id };
      });

      await restClientGeneral.delete(url, {
        data: { args: argsDELETE }
      });
    }

    return Promise.resolve("DONE");
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

// Soft-deletes the invoice of a disabled org
export const deleteATLInvoice = org => async dispatch => {
  try {
    const year = getCurrATLYear();
    const url = "/admin/atlinvoices/bulk";

    // Retrieve list of ATL Invoices
    const costsUrl = `/admin/atlinvoices?include=atlinvoicecosts&where=targetyear=${year}&page=0`;
    const costsPayload = await restClientGeneral.get(costsUrl);

    // Find invoice we're deleting
    const invoiceToDelete = _.remove(costsPayload.data.data, invoice => {
      return invoice.companyid === org.id;
    });

    // Get it into the expected format
    const id = [{id: invoiceToDelete[0].id}];

    if(!invoiceToDelete[0].paid) {
      // DELETE
      await restClientGeneral.delete(url, {
        data: { args: id }
      });
    }

    return Promise.resolve("DONE");
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
}

export const fetchAtlList = atlYear => async dispatch => {
  try {
    const year = atlYear || getCurrATLYear();
    const url = `/admin/companies/atlinvoicing?year=${year}&orderBy=name`;
    const payload = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ATL_LIST,
      payload
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export const fetchAtlInvoices = atlYear => async dispatch => {
  try {
    dispatch(toggleBackgroundJobStatus());

    const year = atlYear || getCurrATLYear();

    // map existing costs to labs
    const costsUrl = `/admin/atlinvoices?include=atlinvoicecosts,atlinvoiceaddress&where=targetyear=${year}&page=0`;
    const costsPayload = await restClientGeneral.get(costsUrl);

    dispatch({
      type: actions.FETCH_ATL_INVOICES_COSTS,
      payload: costsPayload
    });

    dispatch(toggleBackgroundJobStatus());

    return costsPayload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function toggleMembershipFee(labId) {
  return {
    type: actions.TOGGLE_ATL_MEMBERSHIP,
    payload: labId
  };
}

export function toggleAtlFee(labId) {
  return {
    type: actions.TOGGLE_ATL_FEE,
    payload: labId
  };
}

export function toggleMembershipAll(currValue) {
  return {
    type: actions.TOGGLE_ATL_MEMBERSHIP_ALL,
    payload: currValue
  };
}

export function toggleAtlFeeAll(currValue) {
  return {
    type: actions.TOGGLE_ATL_FEE_ALL,
    payload: currValue
  };
}
export const fetchInvoiceATL = id => async dispatch => {
  try {
    const url = `/admin/atlinvoices/${id}?include=atlinvoicecosts,atlinvoiceaddress,company`;
    const res = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_ATL_INVOICE,
      payload: res.data.data
    });

    return res;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.errorAsyncAction));

    return Promise.reject(e);
  }
};

export function handleIvcInputChange(event) {
  return {
    type: actions.CHANGE_INVOICE_FIELD,
    payload: getInputValue(event)
  };
}

export function handleIvcCostChange(index, name, event) {
  const { value } = event.target;

  return {
    type: actions.CHANGE_COST_FIELD,
    payload: {
      key: index,
      field: name,
      value: formatValue(name, value)
    }
  };
}

export function clearInvoiceATL() {
  return {
    type: actions.CLEAR_INVOICE_ATL
  };
}

export const updateInvoiceATL = (
    invoiceId,
    invoice,
    costs
) => async dispatch => {
    try {
        const costBody = costs.map(cost => {
            return {
            costid: cost.costid,
            amount: cost.amount,
            description: cost.description,
            item: cost.description
            };
        });

        let atlinvoiceaddress = {
            address1: invoice.address1,
            address2: invoice.address2,
            city: invoice.city,
            state: invoice.state,
            zipcode: invoice.zipcode,
            countrycode: invoice.countrycode,
            name: invoice.name
        };

        
        if(invoice.id && invoice.id !== "") {
            atlinvoiceaddress = {
                ...atlinvoiceaddress,
                id: invoice.id
            }
        }

        const reqBody = makeSimpleArgs({
            id: invoiceId,
            createdat: invoice.invoiceDate,
            purchaseorder: invoice.purchaseorder,
            atlinvoicecosts: costBody,
            atlinvoiceaddress: atlinvoiceaddress
        });

        const url = `/admin/atlinvoices/${invoiceId}`;
        const payload = await restClientGeneral.put(url, reqBody);

        dispatch({
            type: actions.UPDATE_INVOICE_ATL,
            payload: { id: invoiceId, costs: costBody }
        });

        dispatch({
            type: actions.SHOW_NOTICE,
            payload: notifyMessages.invoiceUpdatedSuccessfully
        });

        return payload;
    } catch (e) {
        dispatch(call.showNotice(notifyMessages.cantUpdateATL));

        return Promise.reject(e);
    }
};

export function toggleBackgroundJobStatus() {
  return {
    type: actions.TOGGLE_ATL_BACKGROUND_JOB_STATUS
  };
}

export const removePaymentATL = invoiceId => async dispatch => {
  try {
    const reqBody = makeSimpleArgs({
      paidat: "",
      paymentmethod: "",
      checknumber: "",
      paymentnote: ""
    });
    const url = `/admin/atlinvoices/${invoiceId}`;
    const payload = await restClientGeneral.put(url, reqBody);

    dispatch({
      type: actions.REMOVE_ATL_PAYMENT,
      payload: payload.data.data
    });

    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.paymentRemovedSuccessfully
    });

    return payload;
  } catch (e) {
    dispatch(call.showNotice(notifyMessages.cantUpdateATL));

    return Promise.reject(e);
  }
};
