import React, { useState, useEffect } from "react";
import { Form, Col } from "ctia-react-bootstrap-v4";
import FormFieldLabel from "components/form/FormFieldLabel";
import { mapOperatorNotifications } from "helpers/NotifyHelper";

const OperatorNotifications = ({ userData }) => {
  const [notifications, setNotifications] = useState(null);
  const isLegacyOperator = userData.legacyoperator.ptcrb === 1;
  const isStaff = userData.isAdmin;
  const show = isLegacyOperator || isStaff;

  useEffect(() => {
    // map existing values if they provided
    if (show && userData && userData.ptcrbUserDocGroupsEmailNotifications) {
      const mappedSettings = mapOperatorNotifications(
        userData.ptcrbUserDocGroupsEmailNotifications
      );
      setNotifications(mappedSettings);
    }
  }, [show, userData, isLegacyOperator]);

  if (!isLegacyOperator && !isStaff) return null;
  if (!userData) return null;
  if (!notifications) return null;

  return (
    <>
      <hr />
      <Form.Group controlId="ctiaLabel">
        <Form.Label>
          <FormFieldLabel
            label="PTCRB Certification Notifications"
            isRequired={true}
          />
        </Form.Label>
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} controlId="ctiaNotifRights">
          <Form.Label>Email Rights</Form.Label>
          <Form.Control as="select" defaultValue={notifications.ctia.rights}>
            <option value="0">No Access</option>
            <option value="1">Receive Only</option>
            <option value="3">Receive & Post</option>
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="ctiaNotifEmails"
          className="icon-left-offset"
        >
          <Form.Label>Email Notifications</Form.Label>
          <Form.Control as="select" defaultValue={notifications.ctia.emails}>
            <option value="0">No</option>
            <option value="1">Yes</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <Form.Group controlId="otaLabel">
        <Form.Label>
          <FormFieldLabel
            label="PTCRB OTA No Testing Notifications"
            isRequired={true}
          />
        </Form.Label>
      </Form.Group>
      <Form.Row>
        <Form.Group as={Col} controlId="otaNotifRights">
          <Form.Label>Email Rights</Form.Label>
          <Form.Control as="select" defaultValue={notifications.ota.rights}>
            <option value={0}>No Access</option>
            <option value={1}>Receive Only</option>
            <option value={3}>Receive & Post</option>
          </Form.Control>
        </Form.Group>
        <Form.Group
          as={Col}
          controlId="otaNotifEmails"
          className="icon-left-offset"
        >
          <Form.Label>Email Notifications</Form.Label>
          <Form.Control as="select" defaultValue={notifications.ota.emails}>
            <option value={0}>No</option>
            <option value={1}>Yes</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
      <input type="hidden" id="isLegacyOperator" value={isLegacyOperator} />
    </>
  );
};

export default OperatorNotifications;
