import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table, FormGroup, FormControl } from "react-bootstrap";
import { Bubble, DeleteButton, DownloadButton } from "ctia-ui";
import InputFile from "components/form/InputFile";
import UploadInstructions from "components/UploadInstructions";
import SupportDocsReadonly from "components/SupportDocsReadonly";
import DownloadAllButton from "components/form/buttons/DownloadAllButton";
import { handleDocTitleChange, handleDocFileChange } from "actions/request";
import {
  documentDownload,
  deleteDocument,
  documentDownloadAll
} from "actions/requestCyber";
import { startPreloader, finishPreloader } from "actions/preloader";
import { hasSupDocs, canDownloadAll } from "helpers/RequestHelper";
import { cutFileExtension } from "helpers/StringHelper";

class SupportDocs extends Component {
  onDelete = slot => {
    const { deleteDocument, request } = this.props;
    return deleteDocument(request.id, slot);
  };

  onSelectFile = (index, event) => {
    // handle file change action
    const { handleDocFileChange, handleDocTitleChange, request } = this.props;
    handleDocFileChange(index, request.supportDocs, event);

    // set file title if it is empty
    const currentTitle = String(request.supportDocs[index].title);
    if (_.isEmpty(currentTitle)) {
      // set file description as file name by default (w/o file extension)
      const fileName = cutFileExtension(event.target.files[0].name);

      // create event for changing description action manually
      const titleChangeEvent = {
        id: "supportDocs",
        target: { value: fileName }
      };
      handleDocTitleChange(index, request.supportDocs, titleChangeEvent);
    }
  };

  render() {
    const { user, request } = this.props;
    const { supportDocs } = request;

    // show just links for download in this mode
    if (!user.canUploadRequestDocs) {
      return <SupportDocsReadonly docs={supportDocs} />;
    }

    const isDisabled = !user.canEditRequest && !user.canUploadRequestDocs;
    const showActions = hasSupDocs(request);
    const showDlAll = canDownloadAll(supportDocs);

    return (
      <div>
        <Bubble show={true}>
          If you have supporting documentation you would like to provide with
          this request, please name the documents and upload them here.
        </Bubble>
        <Table striped condensed hover className="ctia-table">
          <thead>
            <tr>
              <th>Document Title</th>
              <th>Select Document</th>
              {showActions && <th className="action-column-width">Action</th>}
            </tr>
          </thead>
          <tbody>
            {supportDocs.map((item, i) => {
              return (
                <tr key={i}>
                  <td>
                    <FormGroup bsSize="small">
                      <FormControl
                        name="supportDocs"
                        type="text"
                        onChange={this.props.handleDocTitleChange.bind(
                          this,
                          i,
                          supportDocs
                        )}
                        value={item.title}
                        disabled={isDisabled}
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <FormGroup>
                      <InputFile
                        id={`supFile${i}`}
                        name="supportDocs"
                        onChange={this.onSelectFile.bind(this, i)}
                        disabled={isDisabled}
                        file={item.file}
                      />
                    </FormGroup>
                  </td>
                  {showActions && (
                    <td>
                      {item.docId > 0 && user.canUploadRequestDocs && (
                        <DeleteButton
                          size="small"
                          action={this.onDelete.bind(this, item.slot)}
                          customClassName="icon-right-offset"
                        />
                      )}
                      {item.docId > 0 && (
                        <DownloadButton
                          size="small"
                          action={this.props.documentDownload.bind(
                            this,
                            item,
                            request
                          )}
                        />
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <DownloadAllButton
          show={showDlAll}
          action={() => this.props.documentDownloadAll(request)}
        />
        {user.canUploadRequestDocs && <UploadInstructions />}
      </div>
    );
  }
}

SupportDocs.propTypes = {
  readonly: PropTypes.bool
};

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  handleDocTitleChange,
  handleDocFileChange,
  documentDownload,
  documentDownloadAll,
  deleteDocument,
  startPreloader,
  finishPreloader
})(SupportDocs);
