import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Form } from "react-bootstrap";
import Instructions from "containers/request/OTA/OTADeviceInfoBubble";
import RequestManufacturer from "components/request/RequestManufacturer";
import RequestModelName from "components/request/RequestModelnameSimple";
import BatteryVoltageCapacity from "components/request/BatteryVoltageCapacity";
import RequestRevision from "components/request/RequestRevision";
import PackCellsReadonly from "components/request/PackCellsReadonly";
import NonSarSitesReadonly from "containers/request/battery/NonSarSites/NonSarSitesReadonly";
import SubmissionDetails from "containers/request/RequestContent/SubmissionDetails";
import RequestParentLink from "components/request/RequestParentLink";
import BatteryParentDetails from "components/request/BatteryParentDetails";
import BatteryDeviceTypeReadonly from "components/request/BatteryDeviceTypeReadonly";
import BatteryPartsComponents from "components/request/BatteryPartsComponents";
import { initHRR } from "actions/battery/hrrRequest";
import {
  mapNonSarManufSites,
  mapBatteryDeviceType
} from "actions/battery/batteryRequests";

const DeviceInfoLabHRR = ({
  app,
  request,
  user,
  initHRR,
  mapNonSarManufSites,
  mapBatteryDeviceType
}) => {
  useEffect(() => {
    const companyid = user.isStaff ? request.submitter.companyid : null;

    initHRR(companyid).then(() => {
      mapNonSarManufSites(request.linkedManufsites);
      mapBatteryDeviceType(app.certTypes);
    });
  }, [
    app.certTypes,
    initHRR,
    mapBatteryDeviceType,
    mapNonSarManufSites,
    request.linkedManufsites,
    request.submitter.companyid,
    user.isStaff
  ]);

  return (
    <div>
      <Instructions />
      <Form horizontal>
        {request.isEco && (
          <>
            <RequestParentLink certPrefix="hrr" />
            <BatteryParentDetails />
            <hr />
          </>
        )}
        <RequestManufacturer />
        <RequestModelName />
        <BatteryDeviceTypeReadonly />
        {request.isPack && <PackCellsReadonly />}
        <RequestRevision fieldId="revision" editable={false} />
        <BatteryPartsComponents editable={user.canEditLabFields} />
        <BatteryVoltageCapacity
          visibleCapacity={user.displayCapacity}
          visibleVoltage={user.displayVoltage}
        />
        <SubmissionDetails />
        <hr />
        <NonSarSitesReadonly />
      </Form>
    </div>
  );
};

function mapStateToProps({ app, request, user, sites }) {
  return { app, request, user, sites };
}

export default connect(mapStateToProps, {
  initHRR,
  mapNonSarManufSites,
  mapBatteryDeviceType
})(DeviceInfoLabHRR);
