import React from "react";
import { Col } from "react-bootstrap";
import DownloadInvoice from "containers/request/Payments/DownloadInvoice";
import StaffSubmitButton from "containers/request/Payments/StaffSubmitButton";
import UpdateInvoiceDateButton from "containers/request/Payments/UpdateInvoiceDateButton";

const PaymentActions = () => {
  return (
    <div>
      <hr />
      <Col xs={12} sm={9} md={9} mdOffset={3} smOffset={3}>
        <UpdateInvoiceDateButton />
        <StaffSubmitButton />
        <DownloadInvoice />
      </Col>
    </div>
  );
};

export default PaymentActions;
