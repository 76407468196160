import React from "react";
import PropTypes from "prop-types";
import ReqLink from "components/RequestsLink";
import NoTableData from "components/NoTableData";
import IeeeBadge from "components/Badges/IeeeBadge";
import { getRequestTypeTitle, getTransPrefix } from "helpers/RequestHelper";
import { getTestResultTitle } from "helpers/RequestTestsHelper";

const getRowData = row => {
  const { formatedtransactionnumber: tn } = row;
  const urlPrefix = getTransPrefix(tn);

  return {
    urlPrefix,
    modelName: row.vendorproductname,
    ieee: row.ieeetypeid,
    tn
  };
};

const LabResultRowBattery = ({ tableRows }) => {
  if (tableRows.length) {
    return tableRows.map(row => {
      const rowData = getRowData(row);

      if (!rowData) {
        console.error("Unexpected row type passed.");
        return null;
      }

      const testStatus = getTestResultTitle(row.testingstatus);

      return (
        <tr key={row.id}>
          <td>
            <ReqLink
              linkTitle={rowData.tn}
              requestId={row.id}
              category="results"
              subType={rowData.urlPrefix}
            />
          </td>
          <td>{row.vendorname}</td>
          <td>{rowData.modelName}</td>
          <td>
            <IeeeBadge ieeeType={rowData.ieee} />
          </td>
          <td>{getRequestTypeTitle(row.requesttypeid)}</td>
          <td>{testStatus}</td>
        </tr>
      );
    });
  }

  return <NoTableData colSpan={6} />;
};

LabResultRowBattery.propTypes = {
  tableRows: PropTypes.array.isRequired
};

export default LabResultRowBattery;
