import React from "react";
import PropTypes from "prop-types";
import { LoaderButton } from "ctia-ui";
import { Link, useHistory } from "react-router-dom";
// import CtiaButton from "components/form/buttons/CtiaButton";

const GoBackButton = ({ label, show, onClickHandler, dest, ...rest }) => {
  let history = useHistory();

  if (!show) return null;

  if (dest)
    return (
      <Link to={dest}>
        <LoaderButton text={`\u{2190} ${label}`} style={{ marginBottom: 15 }} />
      </Link>
    );

  return (
    <Link onClick={onClickHandler || history.goBack}>
      <LoaderButton
        text={`\u{2190} ${label}`}
        style={{ marginBottom: 15 }}
      />
    </Link>
  );

  // return (
  //   <CtiaButton
  //     label={label}
  //     onClick={onClickHandler || history.goBack}
  //     arrowDirection="left"
  //     color="light-blue"
  //     // wrapperClass="button-v-offset"
  //   />
  // );
};

GoBackButton.propTypes = {
  show: PropTypes.bool,
  label: PropTypes.string,
  onClickHandler: PropTypes.func,
  dest: PropTypes.string
};

GoBackButton.defaultProps = {
  show: true,
  label: "Add",
  onClickHandler: null,
  dest: ""
};

export default GoBackButton;
