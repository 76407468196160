import _ from "lodash";
import promiseError from "libs/promiseError";
import * as actions from "actions/types";

const fetchDeviceList = (action, state) => {
  const { payload } = action;
  const { status, request, data } = payload;

  if (status === 200) {
    if (data) {
      const list = _.mapKeys(data.data, "id");
      return {
        ...state,
        list
      };
    }
  }
  if (status === 204) {
    return {
      ...state,
      noData: true
    };
  } else {
    const searchError = promiseError(request);
    return { ...state, searchError };
  }
};

const fetchSarDevices = (action, state) => {
  const payload = action.payload.data ? action.payload.data : null;
  const devicesArePresent = payload && payload.data && payload.data.length > 0;

  const sarDevices = devicesArePresent
    ? { list: payload.data, pages: payload.meta }
    : { list: [], pages: { TOTALRECORDS: 0 } };
  return { ...state, ...sarDevices, isSAR: true, noData: !devicesArePresent };
};

const fetchBatteryDevices = (action, state) => {
  const payload = action.payload.data ? action.payload.data : null;
  const devicesArePresent = payload && payload.data && payload.data.length > 0;

  const batteryDevices = devicesArePresent
    ? { list: payload.data, pages: payload.meta }
    : { list: [], pages: { TOTALRECORDS: 0 } };

  return {
    ...state,
    ...batteryDevices,
    isSAR: false,
    noData: !devicesArePresent
  };
};

export default function (state = null, action) {
  switch (action.type) {
    case actions.FETCH_DEVICE_LIST:
      return fetchDeviceList(action, state);
    case actions.SET_DEVICE_LIST_SEARCH_TERM:
      return { ...state, searchTerms: action.payload };
    case actions.SET_DEVICE_LIST_FILTERED_LIST:
      return { ...state, list: action.payload };
    case actions.SET_KEEP_LIST:
      return { ...state, keepList: action.payload }
    case actions.CLEAR_DEVICE_LIST:
      return { ...state, list: null, noData: false, keepList: false };
    case actions.FETCH_BATTERY_DEVICE_LIST:
      return fetchBatteryDevices(action, state);
    case actions.FETCH_SAR_DEVICE_LIST:
      return fetchSarDevices(action, state);
    case actions.LOGOUT:
      return null;
    default:
      return state;
  }
}
