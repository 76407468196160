import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import StateFormElement from "containers/request/Initial/Steps/Vendor/BillingAddressState";
import CountryFormElement from "containers/request/Initial/Steps/Vendor/BillingAddressCountry";
import ZipFormElement from "containers/request/Initial/Steps/Vendor/BillingAddressZip";
import { handleInputChange } from "actions/request";

const BillingAddressAdd = ({
  show,
  request,
  handleInputChange,
  countries,
  isEditable
}) => {
  if (!show || !countries) return null;

  return (
    <div>
      <FormElement
        type="text"
        id="billingAddressName"
        label="Billing Company Name"
        value={request.billingAddressName}
        onChange={handleInputChange}
        editable={isEditable}
      />
      <FormElement
        type="text"
        id="billingAddressLine1"
        label="Billing Address (Line 1)"
        value={request.billingAddressLine1}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={250}
      />
      <FormElement
        type="text"
        id="billingAddressLine2"
        label="Billing Address (Line 2)"
        value={request.billingAddressLine2}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={100}
        placeholder="If applicable"
      />
      <CountryFormElement />
      <FormElement
        type="text"
        id="billingAddressCity"
        label="City"
        value={request.billingAddressCity}
        onChange={handleInputChange}
        editable={isEditable}
        maxLength={50}
      />
      <StateFormElement />
      <ZipFormElement />
    </div>
  );
};

function mapStateToProps({ request, user, countries }) {
  return { request, user, countries };
}

export default connect(mapStateToProps, {
  handleInputChange
})(BillingAddressAdd);
