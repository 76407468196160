import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import { mapAdapterValues } from "helpers/BatterySupplierHelper";

const AdaptersReadonly = ({ request }) => {
  if (!request || !request.adapterSuppliers) return null;

  const formattedList = mapAdapterValues(request.adapterSuppliers);

  const renderEmptyMsg = () => (
    <p className="dark-teal bold form-control-static">No adapters selected</p>
  );

  return (
    <FormElement
      label="Adapters"
      list={formattedList}
      id="adapters"
      type="listOfLinksBSC"
      emptyMsg={renderEmptyMsg()}
    />
  );
};

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps)(AdaptersReadonly);
