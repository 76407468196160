// React / Redux
import React from "react";
import { connect } from "react-redux";

// Dictionaries and Helpers
import { formatDate } from "helpers/DateHelper";
import { getRequestTypeTitle } from "helpers/RequestHelper";
import { getUserPrefix } from "helpers/UsersHelper";

// Components and Containers
import IeeeBadge from "components/Badges/IeeeBadge";
import Portal from "components/Portal";
import WithdrawButton from "components/DeviceRecognition/WithdrawModalAndButton/WithdrawButton";

const BatteryModelDetailsSARRow = ({
  requestIdsOfChildren = [],
  reloadRow = () => {},
  row,
  rowIndex,
  showDateAuthorized = true,
  showWithdrawColumn = false,
  // Redux Stores
  user
}) => {
  const {
    id: requestId,
    completedat: completedAt,
    crslversion: crslVersion,
    formatedtransactionnumber: formattedTransactionNumber,
    ieeetypeid: ieeeTypeId,
    manufsiteid: manufSiteId,
    primarylabid: primaryLabId,
    requeststatusid: requestStatusId,
    requesttypeid: requestTypeId,
    vendorname: vendorName,
    vendornote: vendorNote,
    withdrawnat: withdrawnAt,
    withdrawreason: withdrawReason
  } = row;

  const userCanReviewRequestId =
    user.isStaff ||
    (user.isLab && primaryLabId === user.companyid) ||
    (user.isSupplier && vendorName === user.company);

  const linkToRequest = () => {
    if (!userCanReviewRequestId) return formattedTransactionNumber;

    const role = getUserPrefix(user);
    return (
      <Portal
        changeCertProgram={"battery"}
        to={`/battery/${role}/requests/sar/${requestId}`}
        tn={formattedTransactionNumber}
      />
    );
  };
  const requestType = getRequestTypeTitle(requestTypeId);
  const certDate = completedAt ? formatDate(completedAt) : "";

  return (
    <tr key={rowIndex}>
      <td>{linkToRequest()}</td>
      <td>
        <IeeeBadge ieeeType={ieeeTypeId} />
      </td>
      <td>{requestType}</td>
      <td>{vendorNote}</td>
      <td>{crslVersion}</td>
      {showDateAuthorized && <td>{certDate}</td>}
      {showWithdrawColumn && (
        <td>
          <WithdrawButton
            deviceModelId={manufSiteId}
            modalInfo={row}
            requestId={requestId}
            requestIdsOfChildren={requestIdsOfChildren}
            requestStatusId={requestStatusId}
            requestTypeId={requestTypeId}
            selectedCertType={"SAR"}
            shouldDelistOrRelistOnSuccess={false}
            withdrawnAt={withdrawnAt}
            withdrawReason={withdrawReason}
            onError={reloadRow}
            onSuccess={reloadRow}
          />
        </td>
      )}
    </tr>
  );
};

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(BatteryModelDetailsSARRow);
