import React from "react";
import { connect } from "react-redux";
import FormElement from "components/FormElement";
import {
  requestFields,
  hasUnrecognizedAdapter
} from "helpers/AdapterSupplierHelper";
import { handleInputChange, setValue } from "actions/request";

class UnrecognizedAdapterForm extends React.Component {
  adapterSupplier = requestFields.supplierName;
  adapterModel = requestFields.modelName;

  componentWillUnmount() {
    this.props.setValue(this.adapterSupplier, "");
    this.props.setValue(this.adapterModel, "");
  }

  render() {
    const { request, handleInputChange } = this.props;

    if (!request || !request.adapterType) return null;

    // must be selected as 'Unrecognized':
    if (!hasUnrecognizedAdapter(request)) return null;

    return (
      <div>
        <FormElement
          type="text"
          id="adapterSupplierName"
          label="Adapter Vendor Name"
          value={request[this.adapterSupplier] || ""}
          onChange={handleInputChange}
        />
        <FormElement
          type="text"
          id="adapterModelName"
          label="Model Name/Number"
          value={request[this.adapterModel] || ""}
          onChange={handleInputChange}
        />
      </div>
    );
  }
}

function mapStateToProps({ request }) {
  return { request };
}

export default connect(mapStateToProps, { handleInputChange, setValue })(
  UnrecognizedAdapterForm
);
