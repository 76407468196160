import { restClientGeneral } from "libs/restClient";
import * as actions from "actions/types";
import { notifyMessages } from "helpers/NotifyHelper";

export const fetchCountries = () => async dispatch => {
  try {
    const url = `/countries?page=0&orderBy=name`;
    const response = await restClientGeneral.get(url);

    dispatch({ type: actions.FETCH_COUNTRIES, payload: response.data.data });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorFetchRequestAction
    });
  }
};

export const fetchCountryStates = countryCode => async dispatch => {
  // set flag to disable input selector:
  dispatch({ type: actions.START_SUBMITTING });

  try {
    const url = `/states?where=country='${countryCode}'&page=0&orderBy=name`;
    const response = await restClientGeneral.get(url);

    dispatch({
      type: actions.FETCH_COUNTRY_STATES,
      payload: response.data.data
    });
  } catch (e) {
    dispatch({
      type: actions.SHOW_NOTICE,
      payload: notifyMessages.errorFetchRequestAction
    });
  }

  // enable input selector back:
  dispatch({ type: actions.STOP_SUBMITTING });
};

export function clearCountryState() {
  return {
    type: actions.CLEAR_COUNTRY_STATES
  };
}

export function setSelectedCountry(countryCode = "none") {
  return {
    type: actions.SET_SELECTED_COUNTRY,
    payload: countryCode
  };
}

export function setSelectedState(stateCode = "none") {
  return {
    type: actions.SET_SELECTED_COUNTRY,
    payload: stateCode
  };
}
