import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { LoaderButton, IconLabel } from "ctia-ui";
import { updatePayment } from "actions/payments";
import { toMomentDate } from "helpers/DateHelper";
import { isCustomPayMethod, isPayMethodValid } from "helpers/Payments";

class SubmitPayment extends Component {
  state = { isLoading: false };

  validatePayMethod = () => {
    const { request } = this.props;
    const { payMethod, customPayMethod } = request;
    const isCustomPayMethodValid =
      customPayMethod === undefined ? true : isPayMethodValid(customPayMethod);
    const isBasicMethodValid = isPayMethodValid(payMethod);
    return isCustomPayMethod(request)
      ? isCustomPayMethodValid
      : isBasicMethodValid;
  };

  validateCheckNumber = () => {
    const { payMethod, payCheckNumber } = this.props.request;
    if (payMethod !== "Check") return true;
    return payCheckNumber && !_.isEmpty(String(payCheckNumber));
  };

  validateForm = () => {
    const { request } = this.props;
    const payDate = toMomentDate(request.payDate);
    return (
      payDate.isValid() &&
      this.validatePayMethod() &&
      this.validateCheckNumber()
    );
  };

  sumissionCallback = () => {
    const { user, submitCallback } = this.props;
    submitCallback();

    // to prevent memory leaking
    if (user.isStaff) this.stopLoader();
  };

  stopLoader = () => this.setState({ isLoading: false });

  onSubmit = () => {
    const { updatePayment, request } = this.props;

    this.setState({ isLoading: true });

    updatePayment(request)
      .catch(() => this.stopLoader())
      .then(this.sumissionCallback);
  };

  render() {
    return (
      <LoaderButton
        bsStyle="success"
        bsSize="small"
        text={<IconLabel label="Submit Payment" awesomeIcon="money-bill-alt" />}
        onClick={this.onSubmit}
        isLoading={this.state.isLoading}
        loadingText="Submitting..."
        disabled={!this.validateForm()}
        className="button-right-offset"
      />
    );
  }
}

function mapStateToProps({ request, user }) {
  return { request, user };
}

export default connect(mapStateToProps, {
  updatePayment
})(SubmitPayment);
